/* tslint:disable:triple-equals */
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppConfig } from '../../../app.config';
import { IDomainInfo } from '../../../models/domaininfo';
import { MaterialType } from '../../../models/material';
import { MaterialInsertViewModel } from '../../../models/materialinsertviewmodel';
import { Roles } from '../../../models/roles';
import { StatementHistoryStatement } from '../../../models/statementhistorystatement';
import { ComponentService } from '../../../services/component/component.service';
import { LoggingLevel } from '../../../services/logging/logging.service';

@Component({
    selector: 'statement-history-statement',
    template: require('./statementhistorystatement.component.html'),
    styles: [require('./statementhistorystatement.component.css')]
})
export class StatementHistoryStatementComponent implements OnInit, OnDestroy {
    @Input() statement: StatementHistoryStatement;
    showPaidColumn = true;
    detailArrowRotateDegrees = 0;
    detailsExpanded: boolean;
    statementCheckboxName: string;
    viewStatementLinkText: string;
    viewMaterialLinkText: string;
    paidText: string;
    domainInfo: IDomainInfo;

    materialInsertViewModels: MaterialInsertViewModel[] = [];

    private ngUnsubscribe: Subject<any> = new Subject();

    constructor(private componentService: ComponentService, private config: AppConfig) { }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit() {
        let itemGUID = '-item';
        if (this.statement.documentGUID != this.componentService.NULL_GUID) {
            itemGUID = this.statement.documentGUID;
        } else if (this.statement.payments != null && this.statement.payments.length > 0) {
            itemGUID = this.statement.payments[0].consumerPaymentGUID;
        }
        this.statementCheckboxName = 'downloadStatement' + itemGUID;
        this.componentService.contentService.content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((content: any) => {
                this.viewStatementLinkText = this.componentService.contentService.tryGetContentItem(content, 'statements', 'pageText', 'statementHistoryViewStatementLinkText').text;
                this.viewMaterialLinkText = this.componentService.contentService.tryGetContentItem(content, 'statements', 'pageText', 'statementHistoryViewMaterialInsertLinkText').text;
                this.paidText = this.componentService.contentService.tryGetContentItem(content, 'statements', 'pageText', 'statementHistoryPaidColumnHeaderText').text;
            });

        this.componentService.domainService.domainInfo$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(domainInfo => {
            if (domainInfo != null) {
                this.domainInfo = domainInfo;
                this.configurePageForRole();
            }
        });
        this.setMaterialInsertLinks();
    }

    hideAccountChars(): number {
        return this.statement.merchantProfile != null ? this.statement.merchantProfile.hideAccountChars : 0;
    }

    private togglePaymentDetails() {
        this.detailsExpanded = !this.detailsExpanded;
        this.detailArrowRotateDegrees = Math.abs(this.detailArrowRotateDegrees - 180);
    }

    private getStatementPdfLink(): string {
        return this.statement.statementPdfUrl;
    }

    private setMaterialInsertLinks(): void {
        const getInsertUrl: string = this.config.getConfig('materialInsertPdfPath');
        const inserts = this.statement.materials.filter((f) => MaterialType[f.type.toString().toLowerCase()] == MaterialType.insert);
        this.componentService.loggingService.log('Statement', LoggingLevel.debug, this.statement);

        this.materialInsertViewModels = inserts.map((insert) => {
            return {
                insertText: this.viewMaterialLinkText,
                insertLink: getInsertUrl + '?t=' + this.componentService.storageService.retrieve('token') + '&mi=' + insert.materialGUID
            } as MaterialInsertViewModel;
        });
    }

    public configurePageForRole(): void {
        if (this.componentService.hasRole(Roles.StatementsOnly)) {
            if (!this.domainInfo.enablePayments && (this.domainInfo.externalPaymentUrl.length == 0)) {
                this.showPaidColumn = false;
            }
        }
    }
}
