import { Component, Input, OnInit } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IConsumer } from '../../../models/consumer';
import { IDomainInfo } from '../../../models/domainInfo';
import { ComponentService } from '../../../services/component/component.service';
import { ConsumerService } from '../../../services/consumer/consumer.service';

@Component({
    selector: 'footer',
    template: require('./footer.component.html'),
    styles: [require('./footer.component.css')]
})

export class FooterComponent implements OnInit {
    loggedIn = false;
    oneTimePayment = false;
    agentAssistedRole = false;
    consumer: IConsumer;
    @Input() showCustomLoggedInFooter = false;
    @Input() showCustomMemPaymentFooter = false;
    memPaymentCustomerFooterHtml: SafeHtml;
    loggedIncustomerFooterHtml: SafeHtml;
    customFooterHTML: string;
    customFooterScript: string;
    customFooterStyle: string;
    isCustomFooter: boolean;
    domainInfo: IDomainInfo = null;

    private ngUnsubscribe: Subject<any> = new Subject();

    constructor(
        private componentService: ComponentService,
        private consumerService: ConsumerService
    ) {
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit() {
        this.loggedIn = this.componentService.userIsLoggedIn();
        this.oneTimePayment = this.componentService.storageService.exists('onetimepayment');
        this.agentAssistedRole = this.componentService.storageService.exists('agentAssistedRole');

        this.componentService.contentService.content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((content: any) => {
                const loggedInFooter = this.componentService.contentService.tryGetContentItem(content, 'footer', 'loggedIn', 'footerLoggedInFooterHtml').text;
                const memPaymentFooter = this.componentService.contentService.tryGetContentItem(content, 'footer', 'memPayment', 'footerMemPaymentFooterHtml').text;
                this.customFooterStyle = this.componentService.contentService.tryGetContentItem(content, 'footer', 'link', 'customFooterStyle').text;
                this.customFooterHTML = this.componentService.contentService.tryGetContentItem(
                    content, 'footer', 'link', 'customFooterHTML').text;
                this.customFooterScript = this.componentService.contentService.tryGetContentItem(content, 'footer', 'link', 'customFooterScript').text;
                this.isCustomFooter = (this.isCustomContentItemValid(this.customFooterHTML)
                    || this.isCustomContentItemValid(this.customFooterScript)
                    || this.isCustomContentItemValid(this.customFooterStyle));
                // we bypass the security feature of the Angular DomSanitizer so that style attributes or style tags can be interpreted
                this.memPaymentCustomerFooterHtml = this.componentService.BypassSecurityTrustHtml(memPaymentFooter);
                this.loggedIncustomerFooterHtml = this.componentService.BypassSecurityTrustHtml(loggedInFooter);
            });

        if (this.loggedIn) {
            this.consumerService.getConsumer()
                .then(consumer => this.setConsumer(consumer));
        }

        this.componentService.domainService.domainInfo$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                domainInfo => {
                    this.domainInfo = domainInfo;
                });
    }

    setConsumer(consumer: IConsumer): void {
        this.consumer = consumer;
    }

    initials() {
        if (!!this.consumer) {
            let initials = this.consumer.firstName.substr(0, 1);
            initials += this.consumer.lastName.substr(0, 1);

            return initials;
        }
        return '';
    }

    private isCustomContentItemValid(contentItem: string): boolean {
        if (contentItem.length > 0) {
            return (contentItem.match('missing_text_header') === null);
        } else {
            return false;
        }
    }
}
