/* tslint:disable:triple-equals */
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AgreementStatus, IAutopayAgreement } from '../../models/autopayagreement';
import { IAutopayPayment } from '../../models/autopaypayment';
import { ComponentService } from '../../services/component/component.service';
import { ConsumerService } from '../../services/consumer/consumer.service';

@Component({
    selector: 'autopay-agreement-list',
    template: require('./autopayagreementlist.component.html'),
    styles: [require('./autopayagreementlist.component.css')]
})

export class AutopayAgreementListComponent implements OnInit, OnDestroy {

    currentList: IAutopayAgreement[] = [];
    pastList: IAutopayAgreement[] = [];
    loading = true;
    currentButtonText: string;
    pastButtonText: string;
    currentOtherSelected = 'primary-white';
    pastOtherSelected = 'white-primary';
    currentAgreementsText: string;
    pastAgreementsText: string;
    currentAgreementsStatus: string;
    pastAgreementsStatus: string;
    showCurrent = true;

    private ngUnsubscribe: Subject<any> = new Subject();
    @Output() public optedOutAgreement: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(private componentService: ComponentService, private consumerService: ConsumerService) {
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit() {
        this.componentService.contentService.content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((content: any) => {
                this.currentButtonText = this.componentService.contentService.tryGetContentItem(content, 'autopay', 'pageText', 'autopayCurrentButtonText').text;
                this.pastButtonText = this.componentService.contentService.tryGetContentItem(content, 'autopay', 'pageText', 'autopayPastButtonText').text;
                this.currentAgreementsText = this.componentService.contentService.tryGetContentItem(content, 'autopay', 'pageText', 'autopayCurrentAgreementsText').text;
                this.pastAgreementsText = this.componentService.contentService.tryGetContentItem(content, 'autopay', 'pageText', 'autopayPastAgreementsText').text;
            });
        this.retrieveAutopayAgreements();
    }

    retrieveAutopayAgreements(): void {
        this.currentList = [];
        this.pastList = [];
        this.consumerService.getAutopayAgreements()
            .then(agreements => {
                for (const agreement of agreements) {
                    if (agreement.status == AgreementStatus.active) {
                        this.currentList.push(agreement);
                    } else if (agreement.status == AgreementStatus.cancelled) {
                        this.pastList.push(agreement);
                    }
                }
                this.consumerService.setHasAutopayAgreements(this.currentList.length > 0);
                this.setAgreementNumbersText();
                this.doneLoading();
            }).catch(err => {
                this.doneLoading();
            });
    }

    setAgreementNumbersText() {
        this.currentAgreementsStatus = this.currentAgreementsText.replace(
            '!NUMAGREEMENTS!', (!this.currentList ? '0' : this.currentList.length.toString()));
        this.currentAgreementsStatus = this.currentAgreementsStatus.replace(
            '!AGREEMENT!', (!this.currentList || this.currentList.length != 1) ? ' agreements.' : ' agreement.');
        this.pastAgreementsStatus = this.pastAgreementsText.replace(
            '!NUMAGREEMENTS!', (!this.pastList ? '0' : this.pastList.length.toString()));
        this.pastAgreementsStatus = this.pastAgreementsStatus.replace('!AGREEMENT!', (!this.pastList || this.pastList.length != 1) ? ' agreements.' : ' agreement.');
    }

    doneLoading(): void {
        this.loading = false;
    }

    optedOut(agreement: IAutopayAgreement): void {
        this.loading = true;
        this.retrieveAutopayAgreements();
        this.showPastList();
        this.optedOutAgreement.emit(true);
    }

    cancelledPayment(payment: IAutopayPayment): void {
        this.loading = true;
        this.retrieveAutopayAgreements();
    }

    showCurrentList(): void {
        this.showCurrent = true;
        this.setAgreementNumbersText();
        this.currentOtherSelected = 'primary-white';
        this.pastOtherSelected = 'white-primary';
    }

    showPastList(): void {
        this.showCurrent = false;
        this.setAgreementNumbersText();
        this.currentOtherSelected = 'white-primary';
        this.pastOtherSelected = 'primary-white';
    }
}
