/* tslint:disable:triple-equals */
import { HttpClient } from '@angular/common/http';
import { Injectable, Injector, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfig } from '../../app.config';
import { GenericResponse, Response } from '../../models/genericresponse';
import { jsonRequestOptions } from '../../util/HttpClientUtility';
import { StorageService } from '../storage/storage.service';

export enum LoggingLevel {
    error = 3,
    warning = 2,
    info = 1,
    debug = 0
}

@Injectable()
export class LoggingService {
    private storageService: StorageService;
    private loggingLevel: LoggingLevel = LoggingLevel.error;
    private consoleExists = true;
    private domainUrl = this.config.getConfig('domainInfo');
    private loggingPath = this.config.getConfig('loggingPath');
    private loggingApiEnabled = true;

    constructor(private router: Router,
                private config: AppConfig,
                injector: Injector,
                private http: HttpClient,
                private ngZone: NgZone) {
        // Hack to get around circular dependencies between logging service and storage service.
        setTimeout(() => this.storageService = injector.get(StorageService));
        const logLevelString: string = this.config.getConfig('loggingLevel');
        if (!window.console) {
            this.consoleExists = false;
        }
        if (this.isValidLoggingLevel(logLevelString)) {
            this.loggingLevel = LoggingLevel[logLevelString];
        }
        const apiEnabledString: string = this.config.getConfig('loggingApiEnabled');
        if (apiEnabledString != null) {
            this.loggingApiEnabled = (apiEnabledString == 'true');
        }
    }

    /**
     * Logs the passed in message and optional params with the passed in type.
     *
     * @param {string} message
     * @param {LoggingLevel} [loggingLevel=0]
     * @param {...any[]} optionalParams
     * @memberof LoggingService
     */
    public log(message: string, loggingLevel: LoggingLevel = 3, ...optionalParams: any[]): void {
        // higher severity than config logging level
        if (loggingLevel >= this.loggingLevel) {
            if (this.loggingApiEnabled) {
                this.callLoggingApi(message, loggingLevel, optionalParams)
                .then(() => {
                        // success!
                        this.writeToConsole(message, loggingLevel, optionalParams);
                    })
                    .catch(error => {
                        this.writeToConsole('Unable to log via the API', LoggingLevel.error, error);
                    });
            } else {
                this.writeToConsole(message, loggingLevel, optionalParams);
            }
        }
    }

    /**
     * Attempts to handle the error conditions of all the asynchronous calling methods
     *
     * @param {(Response | any)} error
     * @returns {*}
     * @memberof LoggingService
     */
    public handleError(error: Response<any> | any): any {
        // in a real world app, we might use a remote logging infrastructure
        let errMsg: string;
        let logLevel = LoggingLevel.error;
        if (error.status != null && error.status == 401) {
            errMsg = 'Unauthorized';
            this.storageService.clear('token');
            this.storageService.clearSession();
            this.ngZone.run(() => this.router.navigateByUrl('/')).then();
            logLevel = LoggingLevel.debug;
        } else if (error.status != null && error.status == 0) {
            errMsg = 'Unknown timeout';
            this.storageService.clear('token');
            this.storageService.clearSession();
            this.ngZone.run(() => this.router.navigateByUrl('/')).then();
            logLevel = LoggingLevel.debug;
        } else {
            if (error.body != null
                && error.body.error != null
                && error.body.error.status != null
                && error.body.error.statusText != null) {
                const body: any = error.body || '';
                const err: any = body.error || JSON.stringify(body);
                errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
            } else {
                // sms API will return an error-response shape that looks like this in certain flows...
                if (error.error && error.error.messages && error.error.messages.length) {
                    errMsg = error.error.messages.join(',');
                } else {
                    errMsg = error.message ? error.message : error.toString();
                }
            }
        }

        this.log(errMsg, logLevel, error);
        throw new Error(errMsg);
    }

    private callLoggingApi(message: string, loggingLevel: LoggingLevel, ...optionalParams: any[]): Promise<boolean > {
        const body = {
            path: this.loggingPath,
            bypass: true,
            LoggingLevel: LoggingLevel[loggingLevel].toString(),
            Message: message,
            OptionalParams: JSON.stringify(optionalParams)
        };
        return this.http.post(this.domainUrl, { body }, jsonRequestOptions())
            .toPromise()
            .then((response: GenericResponse) => response.ok)
            .catch(() => false);
    }

    private writeToConsole(message: string, loggingLevel: LoggingLevel, ...optionalParams: any[]): void {
        if (this.consoleExists) {
            let logParams = JSON.stringify(optionalParams);
            // If it's an empty array, throw it away
            if (logParams == '[[]]') {
                logParams = '';
            } else {
                logParams = null;
                // Optional params is an array of an array of all the parameters you pass in, so we want to log the inner element.
                if (optionalParams != null && optionalParams[0] != null && Array.isArray(optionalParams[0])) {
                    optionalParams = optionalParams[0].map((x: any) => x);
                }
            }
            const clean: string = message.replace('\n', '_').replace('\r', '_');
            switch (loggingLevel) {
                case LoggingLevel.debug:
                    console.log(clean, logParams != null ? logParams : optionalParams);
                    break;
                case LoggingLevel.info:
                    // tslint:disable-next-line: no-console
                    console.info(clean, logParams != null ? logParams : optionalParams);
                    break;
                case LoggingLevel.warning:
                    console.warn(clean, logParams != null ? logParams : optionalParams);
                    break;
                case LoggingLevel.error:
                    console.error(clean, logParams != null ? logParams : optionalParams);
                    break;
                default:
                    console.log(clean, logParams != null ? logParams : optionalParams);
                    break;
            }
        }
    }

    private isValidLoggingLevel(level: string): boolean {
        return level == 'debug' || level == 'info' || level == 'warning' || level == 'error';
    }
}

