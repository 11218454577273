import { Component, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Roles } from '../../../models/roles';
import { ComponentService } from '../../../services/component/component.service';

@Component({
    selector: 'home-button',
    template: require('./homebutton.component.html'),
    styles: [require('./homebutton.component.css')]
})
export class HomeButtonComponent implements OnInit, OnDestroy {

    constructor(
        private componentService: ComponentService,
        private parentRouter: Router,
        private ngZone: NgZone
    ) { }

    private ngUnsubscribe: Subject<any> = new Subject();

    homeButtonText: string;
    profileButtonText: string;
    @Input() homeUrl = '/home';
    @Input() isCustomHeader = false;

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit() {
        this.componentService.contentService.content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((content: any) => {
                this.homeButtonText = this.componentService.contentService.tryGetContentItem(content, 'header', 'link', 'headerAccountHomeLink').text;
                this.profileButtonText = this.componentService.contentService.tryGetContentItem(content, 'header', 'link', 'headerProfileLink').text;
            });
    }

    onHomeClick(): void {
        if (this.componentService.hasRole(Roles.StatementsOnly)) {
            // it's statements only
            this.ngZone.run(() => this.parentRouter.navigateByUrl('/statements')).then();
        } else {
            this.ngZone.run(() => this.parentRouter.navigateByUrl(this.homeUrl)).then();
        }
    }

    // profile/newprofile is determined inside profile.guard.ts
    onProfileClick(): void {
        this.ngZone.run(() => this.parentRouter.navigateByUrl('/profile')).then();
    }
}
