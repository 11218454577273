/* tslint:disable:triple-equals */
import { CurrencyPipe } from '@angular/common';
import { NgModule, Pipe, PipeTransform } from '@angular/core';

/**
 *
 *
 * @export
 * @class CurrencyDisplayPipe
 * @implements {PipeTransform}
 */
@Pipe({
    name: 'currencydisplay'
})
@NgModule({ providers: [CurrencyPipe] })

export class CurrencyDisplayPipe implements PipeTransform {

    constructor(private currencyPipe: CurrencyPipe) {
    }

    /**
     * Same basic function as currency pipe but superscripts the dollarsign and the decimals
     *
     * @param {number} value
     * @param {string} [currencyCode="USD"]
     * @param {boolean} [_useSymbol=true]
     * @param {string} [digitFormat="1.2-2"]
     * @returns {string}
     *
     * @memberOf CurrencyDisplayPipe
     */
    transform(value: number, currencyCode: string = 'USD', _useSymbol: boolean = true, digitFormat: string = '1.2-2'): string {
        if (value == null) {
            return '';
        }
        const currencyPipedVal = this.currencyPipe.transform(value, currencyCode, 'symbol', digitFormat).trim();
        let dollarSign = currencyPipedVal.substring(0, 1);
        const decimals = currencyPipedVal.slice(-3);
        let wholeNumber = currencyPipedVal.substring(1, currencyPipedVal.length - 3);

        // If number is negative, handle that here
        if (currencyPipedVal.includes('-')) {
            dollarSign = '-' + currencyPipedVal.substring(1, 2);
            wholeNumber = currencyPipedVal.substring(2, currencyPipedVal.length - 3);
        }

        return '<sup>' + dollarSign + '</sup>' + wholeNumber + '<sup>' + decimals + '</sup>';
    }
}
