import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import moment from 'moment';
import { IDomainInfo } from '../../models/domaininfo';
import { PaymentMethod } from '../../models/paymentmethod';
import { IRecurringPaymentPlan, RecurringFrequency } from '../../models/recurringpaymentplan';
import { ComponentService } from '../../services/component/component.service';
import { ConsumerService } from '../../services/consumer/consumer.service';

@Component({
    selector: 'recurring-activation',
    template: require('./recurringactivation.component.html'),
    styles: [require('./recurringactivation.component.css')]
})
export class RecurringActivationComponent implements OnInit, OnDestroy {
    accountNumber: string;
    accountNumberLabel: string;
    confirmationEmail: string;
    confirmationEmailLabel: string;
    continueButtonLabel: string;
    domainInfo: IDomainInfo = null;
    finalPaymentDescription: string;
    finalPaymentLabel: string;
    finalPaymentText: string;
    firstPayment: Date;
    firstPaymentLabel: string;
    name = '';
    nameErrorExists = false;
    nameErrorMessage: string;
    numberOfPayments: number;
    paymentAmount: number;
    paymentAmountLabel: string;
    paymentMethodLabel: string;
    plansLoading = true;
    contentLoading = true;
    recurringActivationHeader: string;
    recurringactivationImage: string = null;
    recurringActivationSmallText: string;
    recurringActivationSubHeading: string;
    recurringActivationEmailExpiredText: string;
    recurringPlan: IRecurringPaymentPlan;
    showSuccess = false;
    signatureLabel: string;
    storedPaymentMethod: PaymentMethod;
    submitButtonLabel: string;
    submitErrorExists = false;
    submitErrorMessage: string;
    thankYouHeader: string;
    thankYouMessage: string;
    totalPaymentCountLabel: string;
    expiredErrorMessage: string;
    emailExpiredButtonLabel: string;

    constructor(
        private componentService: ComponentService,
        private consumerService: ConsumerService,
        private datePipe: DatePipe,
        private currencyPipe: CurrencyPipe,
        private parentRouter: Router,
        private ngZone: NgZone
    ) { }

    private ngUnsubscribe: Subject<any> = new Subject();

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit() {
        this.componentService.domainService.domainInfo$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                domainInfo => {
                    this.domainInfo = domainInfo;
                });

        this.componentService.scrollPageToTop();
        this.componentService.contentService.content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((content: any) => {
                if (content !== null) {
                    this.accountNumberLabel = this.componentService.contentService.tryGetContentItem(content, 'recurringactivation', 'pageText', 'recurringActivationAccountNumberLabel').text;
                    this.confirmationEmailLabel = this.componentService.contentService.tryGetContentItem(content, 'recurringactivation', 'pageText', 'recurringActivationConfirmationEmailLabel').text;
                    this.continueButtonLabel = this.componentService.contentService.tryGetContentItem(content, 'recurringactivation', 'pageText', 'recurringActivationContinueButtonLabel').text;
                    this.finalPaymentDescription = this.componentService.contentService.tryGetContentItem(
                        content, 'recurringactivation', 'pageText', 'recurringActivationFinalPaymentDescription').text;

                    this.finalPaymentLabel = this.componentService.contentService.tryGetContentItem(content, 'recurringactivation', 'pageText', 'recurringActivationFinalPaymentLabel').text;
                    this.firstPaymentLabel = this.componentService.contentService.tryGetContentItem(content, 'recurringactivation', 'pageText', 'recurringActivationFirstPaymentLabel').text;
                    this.nameErrorMessage = this.componentService.contentService.tryGetContentItem(content, 'recurringactivation', 'error', 'recurringActivationNameErrorMessage').text;
                    this.paymentAmountLabel = this.componentService.contentService.tryGetContentItem(content, 'recurringactivation', 'pageText', 'recurringActivationPaymentAmountLabel').text;
                    this.paymentMethodLabel = this.componentService.contentService.tryGetContentItem(content, 'recurringactivation', 'pageText', 'recurringActivationPaymentMethodLabel').text;
                    this.recurringActivationHeader = this.componentService.contentService.tryGetContentItem(content, 'recurringactivation', 'pageText', 'recurringActivationHeader').text;
                    this.recurringActivationSmallText = this.componentService.contentService.tryGetContentItem(content, 'recurringactivation', 'pageText', 'recurringActivationSmallHeader').text;
                    this.recurringActivationSubHeading = this.componentService.contentService.tryGetContentItem(content, 'recurringactivation', 'pageText', 'recurringActivationSubHeader').text;
                    this.signatureLabel = this.componentService.contentService.tryGetContentItem(content, 'recurringactivation', 'pageText', 'recurringActivationSignatureLabel').text;
                    this.submitButtonLabel = this.componentService.contentService.tryGetContentItem(content, 'recurringactivation', 'pageText', 'recurringActivationSubmitButtonLabel').text;
                    this.submitErrorMessage = this.componentService.contentService.tryGetContentItem(
                        content, 'recurringactivation', 'error', 'recurringActivationSubmitErrorMessage').text;

                    this.thankYouHeader = this.componentService.contentService.tryGetContentItem(content, 'recurringactivation', 'pageText', 'recurringActivationThankYouHeader').text;
                    this.thankYouMessage = this.componentService.contentService.tryGetContentItem(
                        content, 'recurringactivation', 'pageText', 'recurringActivationThankYouMessage').text;

                    this.totalPaymentCountLabel = this.componentService.contentService.tryGetContentItem(content, 'recurringactivation', 'pageText', 'recurringActivationTotalPaymentCountLabel').text;
                    this.expiredErrorMessage = this.componentService.contentService.tryGetContentItem(content, 'recurringactivation', 'error', 'recurringActivationExpiredErrorMessage').text;
                    this.recurringactivationImage = this.componentService.contentService.tryGetContentItem(content, 'recurringactivation', 'image', 'recurringActivationBackgroundImage').text;
                    this.recurringActivationEmailExpiredText = this.componentService.contentService.tryGetContentItem(content, 'recurringactivation', 'pageText', 'recurringActivationEmailExpiredSubHeader').text;
                    this.emailExpiredButtonLabel = this.componentService.contentService.tryGetContentItem(
                        content, 'recurringactivation', 'pageText', 'recurringActivationEmailExpiredButtonLabel').text;

                    this.contentLoading = false;
                    this.afterLoading();
                }
            });

        // get recurring payment plan by status...
        if (this.componentService.storageService.exists('secureemailguid')) {
            this.consumerService.getRecurringActivationEmailValid().then(recurringPlanGUID => {
                if (recurringPlanGUID) {
                    this.consumerService.getRecurringPaymentPlanByGuid(recurringPlanGUID)
                        .then(recurringPlan => {
                            this.recurringPlan = recurringPlan;
                            this.plansLoading = false;
                            this.afterLoading();
                        });
                } else {
                    this.submitButtonLabel = this.emailExpiredButtonLabel;
                    this.plansLoading = false;
                }
            });
        } else {
            this.ngZone.run(() => this.parentRouter.navigateByUrl('/default')).then();
        }
    }

    afterLoading(): void {
        if (!this.contentLoading && !this.plansLoading && this.recurringPlan !== null) {
            this.populatePlanData();
        }
    }

    isLoading(): boolean {
        return this.plansLoading || this.contentLoading;
    }

    showBackgroundImage(): boolean {
        return !this.isLoading();
    }

    populatePlanData() {
        this.accountNumber = this.recurringPlan.accountNumber;
        this.confirmationEmail = this.recurringPlan.emailAddress;
        this.finalPaymentText = this.finalPaymentDescription.replace(
            '!FINALAMOUNT!', this.currencyPipe.transform(this.recurringPlan.finalPaymentAmount, 'USD', 'symbol'));
        this.finalPaymentText = this.finalPaymentText.replace('!ENDDATE!', this.datePipe.transform(this.recurringPlan.scheduledEndDate, 'M/d/y'));
        this.firstPayment = this.recurringPlan.startDate;
        this.numberOfPayments = this.recurringPlan.numberOfPayments;
        this.paymentAmount = this.recurringPlan.paymentAmount;
        this.paymentAmountLabel = this.paymentAmountLabel.replace('!FREQUENCY!', RecurringFrequency[this.recurringPlan.frequency]);
        this.paymentAmountLabel = this.paymentAmountLabel.charAt(0).toUpperCase() + this.paymentAmountLabel.slice(1);
        this.storedPaymentMethod = this.recurringPlan.storedPaymentMethod;
        let expirationDate: moment.Moment = moment(this.recurringPlan.startDate);
        expirationDate = expirationDate.add(5, 'd'); // 5 days
        if (moment() > expirationDate) {
            this.showErrorMessage(this.expiredErrorMessage);
        }
    }

    savePlanChanges() {
        // save click
        if (this.showSuccess || this.submitErrorExists) {
            this.ngZone.run(() => this.parentRouter.navigateByUrl('/default')).then();
        }
        // If the email has expired, this.recurringPlan will not have been set
        // and we want to send the user to the /faq page:
        if (!this.recurringPlan) {
            this.ngZone.run(() => this.parentRouter.navigateByUrl('/faq')).then();
        }

        this.name = this.name.trim();

        if (this.name === null || this.name.length === 0 || /\d/.test(this.name) || /[!@#\$%\^\&*\)\(+=._-]/.test(this.name)) {
            this.nameErrorExists = true;
        } else {
            // save the plan, switch it to active
            this.plansLoading = true;
            this.recurringPlan.validationSignature = this.name;

            this.consumerService.activateRecurringPaymentPlan(this.recurringPlan)
                .then(success => {
                    success ? this.showSuccessMessage() : this.showErrorMessage('');
                })
                .catch(error => {
                    this.showErrorMessage(error);
                });
        }
    }

    showSuccessMessage() {
        if (this.plansLoading) {
            this.recurringActivationHeader = this.thankYouHeader;
            this.recurringActivationSmallText = this.thankYouHeader;
            this.plansLoading = false;
            this.submitButtonLabel = this.continueButtonLabel;
            this.showSuccess = true;
        }
    }

    showErrorMessage(error: string) {
        this.plansLoading = false;
        this.submitErrorExists = true;
        if (!!error && error.length > 0) {
            this.submitErrorMessage = error;
        }
        this.submitButtonLabel = this.continueButtonLabel;
    }

    clearNameError() {
        this.nameErrorExists = false;
    }

    getPaymentMethodDescription(): string {
        if (this.storedPaymentMethod === null) {
            return '';
        }
        if (this.storedPaymentMethod.description !== null && this.storedPaymentMethod.description.trim() !== '') {
            return this.storedPaymentMethod.description;
        }
        if (this.storedPaymentMethod.account !== null && this.storedPaymentMethod.account.trim() !== '') {
            return this.storedPaymentMethod.account;
        }
        return '';
    }
}
