export class StatementBalance {
    balance: number;
    dueDate: Date;
    dueDateUtc: Date;
    lastPaymentDate: Date;
    balanceAsOf: Date;

    constructor() {
        this.balance = 0;
        this.dueDate = null;
        this.dueDateUtc = null;
        this.lastPaymentDate = null;
        this.balanceAsOf = null;
    }
}

export const TEST_STATEMENT_BALANCE: StatementBalance = {
    balance: 0,
    dueDate: new Date('2017-08-01'),
    dueDateUtc: new Date('2017-08-01'),
    lastPaymentDate: null,
    balanceAsOf: new Date('2017-09-29')
};
