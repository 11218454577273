/* tslint:disable:triple-equals */
//  code from https://gist.github.com/fernandohu/122e88c3bcd210bbe41c608c36306db9

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';


@Injectable()
export class AppConfig {

    private config: any = null;
    private environment: any = null;

    constructor(private http: HttpClient) {

    }

    /**
     * Use to get the data found in the second file (config file)
     */
    public getConfig(key: any) {
        if (this.config[key].includes('+host+')) {
            if (location.host.includes('localhost') || location.host.includes('192.168')) {
                // hard code :64805 for debugging with :3000 b/c hostname does not include port
                this.config[key] = this.config[key].replace('+host+', location.hostname);
            } else {
                // replace with full domain name and port (if any), b/c host includes port
                this.config[key] = this.config[key].replace('+host+', location.host);
            }

        }
        return this.config[key];
    }

    /**
     * Use to get the data found in the first file (env file)
     */
    public getEnv(key: any) {
        return this.environment[key];
    }

    /**
     * This method:
     *   a) Loads "env.json" to get the current working environment (e.g.: 'production', 'development')
     *   b) Loads "config.[env].json" to get all env's variables (e.g.: 'config.development.json')
     *
     * @returns void
     *
     * @memberof AppConfig
     */
    public async load() {
        let envResponse;
        try {
            envResponse = await this.http.get('/app/env.json')
                .toPromise()
                .then((res) => res);
        } catch (e) {
            console.log('Configuration file "env.json" could not be read', e);
            return true;
        }
        this.environment = envResponse;
        let request: any = null;

        if (this.environment.env == 'Release' || this.environment.env == 'Debug') {
            request = this.http.get('/app/config.' + this.environment.env + '.json?' + new Date().getTime()).toPromise();
        } else {
            console.error('Environment file is not set or invalid');
            return true;
        }

        if (request) {
            let responseData;
            try {
                responseData = await request;
            } catch (e) {
                console.error('Error reading ' + this.environment.env + ' configuration file');
                return false;
            }
            this.config = responseData;
            return true;
        } else {
            console.error('Env config file "env.json" is not valid');
            return true;
        }
    }
}
