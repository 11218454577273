import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IConsumer } from '../../../models/consumer';
import { IDomainInfo } from '../../../models/domaininfo';
import { ComponentService } from '../../../services/component/component.service';
import { ConsumerService } from '../../../services/consumer/consumer.service';

@Component({
    selector: 'autopay-widget',
    template: require('./autopaywidget.component.html'),
    styles: [require('./autopaywidget.component.css')]
})
export class AutopayWidgetComponent implements OnInit, OnDestroy {

    autopayHeadlineText: string;
    autopayLinkText: string;
    autopayLinkNotEnrolledText: string;
    autopayResultText = '';
    autopayRoute = '/autopay';
    consumer: IConsumer;
    domainInfo: IDomainInfo;

    private ngUnsubscribe: Subject<any> = new Subject();

    constructor(private componentService: ComponentService, private consumerService: ConsumerService) {
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit() {
        this.retrieveAutopayAgreements();
        // Called after the constructor, initializing input properties, and the first call to ngOnChanges.
        // Add 'implements OnInit' to the class.
        this.componentService.contentService.content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((content: any) => {
                this.autopayHeadlineText = this.componentService.contentService.tryGetContentItem(content, 'home', 'payment', 'homePagePaymentAutopayHeadline').text;
                this.autopayLinkText = this.componentService.contentService.tryGetContentItem(content, 'home', 'payment', 'homePagePaymentAutopayLink').text;
                this.autopayLinkNotEnrolledText = this.componentService.contentService.tryGetContentItem(content, 'home', 'payment', 'homePagePaymentAutopayNotEnrolledLink').text;
            });

        this.componentService.domainService.domainInfo$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(domainInfo => {
                this.domainInfo = domainInfo;
        });
    }

    private isLinkDisabled(): boolean {
        return !!this.consumer && !this.consumer.hasAutopayAgreements && !this.domainInfo.enableWallet;
    }

    private retrieveAutopayAgreements(): void {
        this.consumerService.getAutopayAgreements()
            .then(agreements => {
                this.autopayResultText = !!agreements && agreements.length > 0 ? this.autopayLinkText : this.autopayLinkNotEnrolledText;
            });
    }
}
