import { CurrencyPipe } from '@angular/common';
import { Component, HostListener, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PaymentSelectionComponent } from '../../components/Controls/PaymentSelection/paymentselection.component';
import { PaymentMethod, PaymentMethodType } from '../../models/paymentmethod';
import { PaymentMethodAssignmentSource } from '../../models/paymentmethodassignmentsource';
import { PaymentSelectionModel, PaymentSelectionStateModel } from '../../models/paymentselectionmodel';
import { IRecurringPaymentPlan } from '../../models/recurringpaymentplan';
import { IRecurringPaymentPlanSubmit } from '../../models/recurringpaymentplansubmit';
import { AgentAssistedService } from '../../services/agentassisted/agentassisted.service';
import { ComponentService } from '../../services/component/component.service';
import { ConsumerService } from '../../services/consumer/consumer.service';

@Component({
    selector: 'recurring',
    template: require('./recurringpayment.component.html'),
    styles: [require('./recurringpayment.component.css')]
})

export class RecurringPaymentComponent implements OnInit, OnDestroy {
    private ngUnsubscribe: Subject<any> = new Subject();

    @ViewChild('paymentSelectionCmp', { static: false }) paymentSelectionCmp: PaymentSelectionComponent;

    submission: boolean;
    plansAreLoading = true;
    recurringPayment: IRecurringPaymentPlan;

    confirmationText: string;

    // Step 1
    paymentPlanStep1Name: string;
    paymentPlanBackButton: string;
    recurringContinueButton: string;
    balance = 0;
    paymentAmountText: string;
    paymentSource: string;
    emailErrorExists = false;
    paymentPlanEmailErrorText: string;
    customerEmailAddress: string;
    promptCustomerEmail = false;

    // Step 2
    paymentPlanStep2Name: string;
    recurringStep2Header: string;
    recurringStep2Subheader: string;
    paymentPaymentAmount: string;
    recurringContinueButtonText: string;

    recurringPerMonthText: string;
    perMonthValued: string;
    lastPaymentPerMonthValued: string;
    recurringPaymentSubmitErrorText: string;
    recurringPaymentSubmitError = false;

    selectedPaymentMethod: PaymentMethod;
    todaysDate = new Date();
    paymentDate: string = this.todaysDate.toISOString();
    firstPaymentDate: Date;

    recurringHeader: string;
    recurringPaymentSubheading: string;
    recurringPaymentMethodText: string;
    paymentPlanFirstPaymentDescription: string;
    firstPaymentText: string;
    firstPaymentTextSave: string;
    recurringPlanDetailsText: string;
    recurringPlanAuthorizationText: string;
    recurringAuthorizationDisclaimer: string;
    paymentPlanCreatePlanButtonText: string;
    paymentEndDate: string;
    paymentAuthorization = false;
    recurringEmailText: string;

    recurringMonthlyAmountDescription: string;
    paymentPlanFinalPaymentDescription: string;

    stepValidationMessage: string;
    paymentPaymentAuthorizationError: string;

    stepList: any[] = ['one', 'two', 'three', 'four'];
    currentStep = 2;
    finalStep = 5;
    step1Valid = true;
    step2Valid = true;
    step3Valid = true;
    step4Valid = true;
    step3Sent: boolean;

    paymentPlanStep3Name: string;
    paymentAmount = 0.00;
    lastPayment = 0.00;

    recurringPlanApiErrorMessage: string;
    updateErrorExists: boolean;

    agentAssistedPaymentEmailGUID: string;
    frequency: string;
    recurringFrequencyText: string;
    modelToPassToPayment: PaymentSelectionModel = new PaymentSelectionModel();
    paymentSelectionState: PaymentSelectionStateModel = new PaymentSelectionStateModel();

    constructor(
        private agentAssistedService: AgentAssistedService,
        private componentService: ComponentService,
        private consumerService: ConsumerService,
        private parentRouter: Router,
        private currencyPipe: CurrencyPipe,
        private ngZone: NgZone
    ) { }

    /**
     * onPopState listens for the popstate event on the window and performs the actions, this handles the browser back button
     * @see https://stackoverflow.com/questions/40381814/how-do-i-detect-user-navigating-back-in-angular2
     * @param {*} event the event
     * @memberof RecurringPaymentComponent
     */
    @HostListener('window:popstate', ['$event'])
    onPopState(event: any) {
        // Wrap with this because it messes up unit tests
        if (process.env.ENV !== 'test') {
            // Needed for onPopState to function completely
            history.pushState(false, 'recurring', 'recurringpaymentplan');
        }
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    async ngOnInit() {
        // Called after the constructor, initializing input properties, and the first call to ngOnChanges.
        // Add 'implements OnInit' to the class.
        this.componentService.scrollPageToTop();
        this.agentAssistedPaymentEmailGUID = this.componentService.storageService.retrieve('agentAssistedPaymentEmailGUID');
        this.componentService.contentService.content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((content: any) => {
                this.recurringHeader = this.componentService.contentService.tryGetContentItem(content, 'recurring', 'pageText', 'recurringHeader').text;
                this.recurringPaymentSubheading = this.componentService.contentService.tryGetContentItem(content, 'recurring', 'pageText', 'recurringPaymentSubheading').text;
                this.recurringPerMonthText = this.componentService.contentService.tryGetContentItem(content, 'recurring', 'pageText', 'recurringPerMonthText').text;
                this.recurringPaymentMethodText = this.componentService.contentService.tryGetContentItem(content, 'recurring', 'pageText', 'recurringPaymentMethodText').text;
                this.paymentPlanFirstPaymentDescription = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'paymentselection', 'paymentPlanFirstPaymentDescription').text;
                this.firstPaymentText = this.firstPaymentTextSave;
                this.recurringPlanDetailsText = this.componentService.contentService.tryGetContentItem(content, 'recurring', 'pageText', 'recurringPlanDetailsText').text;
                this.recurringPlanAuthorizationText = this.componentService.contentService.tryGetContentItem(content, 'recurring', 'pageText', 'recurringPlanAuthorizationText').text;
                this.recurringAuthorizationDisclaimer = this.componentService.contentService.tryGetContentItem(content, 'recurring', 'pageText', 'recurringAuthorizationDisclaimer').text;
                this.recurringPlanApiErrorMessage = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'error', 'paymentPlanApiErrorMessage').text;
                this.recurringMonthlyAmountDescription = this.componentService.contentService.tryGetContentItem(content, 'recurring', 'pageText', 'recurringMonthlyAmountDescription').text;
                this.recurringEmailText = this.componentService.contentService.tryGetContentItem(content, 'recurring', 'pageText', 'recurringEmailText').text;
                this.paymentPlanEmailErrorText = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'error', 'paymentPlanEmailErrorText').text;
                this.paymentPlanCreatePlanButtonText = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'pageText', 'paymentPlanCreatePlanButtonText').text;
                this.paymentPlanBackButton = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'pageText', 'paymentPlanBackButton').text;
                this.paymentPlanFinalPaymentDescription = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'paymentselection', 'paymentPlanFinalPaymentDescription').text;
                this.paymentPlanStep1Name = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'pageText', 'paymentPlanStep1Name').text;
                this.paymentPlanStep2Name = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'pageText', 'paymentPlanStep2Name').text;
                this.paymentPlanStep3Name = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'pageText', 'paymentPlanStep3Name').text;
                this.paymentPaymentAuthorizationError = this.componentService.contentService.tryGetContentItem(content, 'error', 'paymentPlan', 'paymentPaymentAuthorizationError').text;
                this.recurringFrequencyText = this.componentService.contentService.tryGetContentItem(content, 'recurring', 'pageText', 'recurringFrequencyText').text;
                this.recurringStep2Header = this.componentService.contentService.tryGetContentItem(content, 'recurring', 'pageText', 'recurringStep2Header').text;
                this.recurringStep2Subheader = this.componentService.contentService.tryGetContentItem(content, 'recurring', 'pageText', 'recurringStep2Subheader').text;
                this.paymentPaymentAmount = this.componentService.contentService.tryGetContentItem(content, 'recurring', 'paymentSelection', 'paymentPaymentAmount').text;
                this.recurringContinueButtonText = this.componentService.contentService.tryGetContentItem(content, 'recurring', 'pageText', 'recurringContinueButtonText').text;
            });

        this.stepList = [
            { stepName: this.paymentPlanStep1Name },
            { stepName: this.paymentPlanStep2Name },
            { stepName: this.paymentPlanStep3Name },
        ];

        // These next two values are sent to the PaymentSelectionComponent
        // where they are used with the "paymentPaymentAmount" content item
        // Name to set the "Recurring Plan Amount" on that control on this page.
        this.modelToPassToPayment.contentItemCategory = 'recurring';
        this.modelToPassToPayment.contentItemSubCategory = 'paymentSelection';
        this.modelToPassToPayment.lockSaveToWallet = true;
        this.modelToPassToPayment.showPaymentDate = false;

        this.retrieveRecurringPaymentPlan();
    }

    isSubmission(): boolean {
        return this.submission;
    }

    plansLoading(): boolean {
        return this.plansAreLoading;
    }

    retrieveRecurringPaymentPlan(): void {
        const token = this.componentService.storageService.retrieve('token');
        this.agentAssistedService.retrieveRecurringmentPayment(token, this.agentAssistedPaymentEmailGUID)
            .then(recurringPayment => {
                this.plansAreLoading = false;
                this.recurringPayment = recurringPayment;
                this.populateform();
                this.modelToPassToPayment.amountToPay = this.recurringPayment.totalAmount;
                this.setRecurringPaymentText(recurringPayment.numberOfPayments);
                this.frequency = recurringPayment.frequency.toString();
            });
    }

    async populateform() {
        this.paymentAmount = this.recurringPayment.paymentAmount;
        this.lastPayment = this.recurringPayment.finalPaymentAmount;
        this.firstPaymentDate = this.recurringPayment.nextScheduledPaymentDate;
        this.paymentDate = this.recurringPayment.nextScheduledPaymentDate.toString();
        this.paymentEndDate = this.recurringPayment.scheduledEndDate.toString();
        this.paymentSource = this.recurringPayment.paymentMethodAssignmentSource.toString();
        this.balance = this.recurringPayment.totalAmount;

        // We are not writing the email address to the plan record anymore,
        // but there may still exist some that only have their email there.
        this.customerEmailAddress = this.recurringPayment.emailAddress;
        if (!this.customerEmailAddress) {
            const consumer = await this.consumerService.getConsumer();

            this.customerEmailAddress = consumer.emailAddress;
        }

        this.promptCustomerEmail = !this.customerEmailAddress || this.customerEmailAddress.trim() === '';
    }

    nextStep() {
        if (this.currentStep === 2) {
            // Get payment method selected on step 2 so we can pass it to step 3's binding
            this.planValuesSetup();
        }

        if (this.currentStep === 3) {
            this.step3Sent = true;
            this.stepValidationMessage = '';
            this.reviewPaymentValidation();
        }

        if (this.currentStep < this.finalStep && this.currentStepIsValid(this.currentStep)) {
            this.currentStep++;
            this.componentService.scrollPageToTop();
        }
        if (this.currentStep === 4) {
            this.submitRecurringPayment();
        }
        this.selectedPaymentMethod = this.paymentSelectionCmp.getPaymentSelectionState().selectedPaymentMethod;
    }

    prevStep() {
        this.recurringPaymentSubmitError = false;

        if (this.currentStep > 1) {
            this.paymentSelectionCmp.clearErrorState();
            this.step2Valid = this.step3Valid = true;

            if (this.currentStep > 2 && this.paymentAuthorization) {
                // Reset authorization checkbox
                this.paymentAuthorization = false;
                this.stepValidationMessage = '';
            }

            if (this.currentStep > 2) {
                // Skip step 2
                this.currentStep--;
            }

            this.paymentSelectionCmp.revertFakeSavedPayment();
            this.firstPaymentText = this.firstPaymentTextSave;
            this.componentService.scrollPageToTop();
        } else {
            // Redirect to / should get them to their domain default homepage
            this.ngZone.run(() => this.parentRouter.navigateByUrl('/')).then();
        }
    }

    private reviewPaymentValidation(): void {
        this.step3Valid = true;
        this.stepValidationMessage = null;
        if (!this.paymentAuthorization) {
            this.step3Valid = false;
            this.stepValidationMessage = this.paymentPaymentAuthorizationError;
        } else {
            this.validateEmailAddress();
        }
    }

    private planValuesSetup(): void {
        this.step2Valid = this.paymentSelectionCmp.isValid();
    }

    validateEmailAddress(): void {
        this.emailErrorExists = false;
        this.step3Valid = true;

        if (
            this.step3Sent &&
            this.promptCustomerEmail &&
            (!this.customerEmailAddress ||
             !this.componentService.emailRegex.test(this.customerEmailAddress.toLowerCase()))
        ) {
            this.emailErrorExists = true;

            this.stepValidationMessage =
                !this.paymentAuthorization ? this.paymentPaymentAuthorizationError : this.paymentPlanEmailErrorText;

            this.step3Valid = false;
        }
    }

    currentStepIsValid(step: number): boolean {
        let stepIsValid: boolean;
        switch (step) {
            case 1:
                stepIsValid = this.step1Valid;
                break;
            case 2:
                stepIsValid = this.step2Valid;
                break;
            case 3:
                stepIsValid = this.step3Valid;
                break;
            case 4:
                stepIsValid = this.step4Valid;
                break;
        }
        return stepIsValid;
    }

    private setRecurringPaymentText(months: number): void {
        if (this.lastPayment === this.paymentAmount) {
            this.lastPaymentPerMonthValued = '&nbsp;';
            this.perMonthValued = this.recurringPerMonthText.replace('!MONTHS!', months.toString());
        } else {
            this.lastPaymentPerMonthValued = this.recurringPerMonthText.replace('!AMOUNT!', this.currencyPipe.transform(this.lastPayment, 'USD', 'symbol', '1.2-2'));
            this.perMonthValued = this.recurringPerMonthText.replace('!MONTHS!', (months - 1).toString());
        }
    }

    private createRecurringPaymentRequest(): IRecurringPaymentPlanSubmit {
        const paymentState: PaymentSelectionStateModel = this.paymentSelectionCmp.getPaymentSelectionState();
        const recurringPayment: IRecurringPaymentPlanSubmit = {} as IRecurringPaymentPlanSubmit;

        recurringPayment.PaymentSource = this.paymentSelectionCmp.getPaymentSource();
        // Must have one of tokenpayment, achpayment or creditcardpayment
        // Check for wallet
        if (!!paymentState.selectedPaymentMethod.tokenGUID &&
            paymentState.selectedPaymentMethod.tokenGUID !== 'newPaymentMethod'
        ) {
            recurringPayment.ConsumerPaymentMethodGUID = paymentState.selectedPaymentMethod.tokenGUID;
        } else {
            // Not wallet, is it cc or ach?
            if (paymentState.selectedPaymentMethod.paymentMethodType === PaymentMethodType.ach) {
                recurringPayment.ACHPaymentMethod = this.paymentSelectionCmp.getACHPayment();
            } else {
                recurringPayment.CreditCardPaymentMethod = this.paymentSelectionCmp.getCreditCardPayment();
            }
        }

        recurringPayment.Address = {
            firstName: paymentState.firstName,
            lastName: paymentState.lastName,
            address1: paymentState.address1,
            address2: paymentState.address2,
            city: paymentState.city,
            stateRegion: paymentState.state,
            postalCode: paymentState.postalCode,
            country: paymentState.country
        };

        recurringPayment.PaymentMethodNickname = paymentState.newPaymentNickname;
        recurringPayment.PaymentMethodAssignmentSource = PaymentMethodAssignmentSource.SelfService;
        recurringPayment.RecurringConsumerPaymentGUID = this.recurringPayment.recurringConsumerPaymentGUID;
        recurringPayment.CustomerAccountID = this.recurringPayment.customerAccountID;
        return recurringPayment;
    }

    private submitRecurringPayment(): void {
        this.recurringPaymentSubmitError = false;
        this.submission = true;
        const recurringPaymentSubmitModel: IRecurringPaymentPlanSubmit = this.createRecurringPaymentRequest();

        this.consumerService.activateRecurringPaymentAgreement(recurringPaymentSubmitModel)
            .then(result => {
                this.submission = false;
                this.plansAreLoading = false;
                this.stepValidationMessage = '';

                this.storeRecurringDetailsTerms();
                this.ngZone.run(() => this.parentRouter.navigateByUrl('/recurringconfirmation')).then();
            }).catch(() => {
                this.submission = false;
                this.plansAreLoading = false;
                this.currentStep = 3;
                this.stepValidationMessage = this.recurringPlanApiErrorMessage;
            });
    }

    private storeRecurringDetailsTerms(): void {
        // Needed for the Recurring payment confirmation page.
        if (this.customerEmailAddress) {
            this.componentService.storageService.store(
                'recurringEmailAddress', this.customerEmailAddress
            );

        } else {
            this.consumerService.getConsumer().then(consumer => {
                if (!!consumer) {
                    this.componentService.storageService.store(
                        'recurringEmailAddress', consumer.emailAddress
                    );
                }
            });
        }
    }
}
