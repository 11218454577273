import { Component, Input, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ErrorMessage } from '../../../models/errormessage';
import { ComponentService } from '../../../services/component/component.service';

@Component({
    selector: 'error-message',
    template: require('./errormessage.component.html'),
    styles: [require('./errormessage.component.css')]
})
export class ErrorMessageComponent implements OnInit {
    @Input() errorMessage: ErrorMessage;

    private ngUnsubscribe: Subject<any> = new Subject();
    private homeRouteLocation = '/';

    constructor(
        private parentRouter: Router,
        private componentService: ComponentService,
        private ngZone: NgZone
    ) { }

    ngOnInit() {
        this.componentService.contentService.content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((content: any) => {
                if (content != null) {
                    // get default warning message
                    if (!this.errorMessage) {
                        this.errorMessage = new ErrorMessage();
                        this.errorMessage = {
                            headerText: this.componentService.contentService.tryGetContentItem(content, 'global', 'error', 'genericWeAreSorryHeaderText').text,
                            mobileHeaderText: this.componentService.contentService.tryGetContentItem(content, 'global', 'error', 'genericWeAreSorryHeaderText').text,
                            errorMessageText: this.componentService.contentService.tryGetContentItem(content, 'global', 'error', 'genericWeAreSorryText').text,
                            redirectButtonText: this.componentService.contentService.tryGetContentItem(content, 'global', 'error', 'genericRedirectButtonText').text,
                            redirectUrl: this.homeRouteLocation
                        };
                    }
                }
            });
    }

    pageNavigate(): void {
        this.ngZone.run(() => this.parentRouter.navigateByUrl(this.errorMessage.redirectUrl)).then();
    }
}
