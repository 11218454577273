import { ACHPaymentMethod } from './achpaymentmethod';
import { Address } from './address';
import { CreditCard } from './creditcard';
import { PaymentMethodAssignmentSource } from './paymentmethodassignmentsource';

export enum PaymentPlanMode {
    First = 0,
    Manual = 1,
    Monthly = 2,
}

export interface IPaymentPlanSubmit {
    CustomerAccountID: string;
    MerchantProfileGUID: string;
    FirstDate: Date;
    LastDate: Date;
    InitialPayment: boolean;
    InitialPaymentAmount: number;
    PaymentAmount: number;
    Balance: number;
    ExpectedPaymentAmount: number;
    ExpectedPaymentDate: Date;
    PaymentPlanMode: PaymentPlanMode;
    CustomerEmailAddress: string;
    PaymentMethodAssignmentSource: PaymentMethodAssignmentSource;
    GuarantorAccountNumbers: string[];

    // Payment information
    // If existing
    consumerPaymentMethodGUID: string;

    // If new
    Address: Address;
    CreditCardPaymentMethod: CreditCard;
    ACHPaymentMethod: ACHPaymentMethod;
    PaymentMethodNickname: string;
    PaymentSource: string;

    // If adding paymentMethod via AgentAssisted
    PaymentPlanID: number;
}
