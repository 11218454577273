export class RealTimeBalanceMode {
    static Batch = 'Batch' as RealTimeBalanceModeValue;
    static None = 'None' as RealTimeBalanceModeValue;
    static EpicWebService = 'EpicWebService' as RealTimeBalanceModeValue;
}

export type RealTimeBalanceModeValue =
    'Batch'
    | 'None'
    | 'EpicWebService';
