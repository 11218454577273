import { Component, Input } from '@angular/core';

@Component({
    selector: 'logo',
    template: require('./logo.component.html'),
    styles: [require('./logo.component.css')]
})

export class LogoComponent {
    @Input() imageLoc: string;
    @Input() clickable = true;
}
