import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs/Subject';
import { IConsumer } from '../../models/consumer';
import { IDomainInfo } from '../../models/domaininfo';
import { AgentAssistedService } from '../../services/agentassisted/agentassisted.service';
import { ComponentService } from '../../services/component/component.service';
import { ConsumerService } from '../../services/consumer/consumer.service';
import { LoggingLevel } from '../../services/logging/logging.service';
import { LoginService } from '../../services/login/login.service';

@Component({
    selector: 'showpayment',
    template: require('./showpayment.component.html'),
    styles: [require('./showpayment.component.css')]
})

export class ShowPaymentComponent implements OnInit, OnDestroy {
    form: FormGroup;
    isDOB = false;
    dobValid = true;
    zipValid = true;
    validType = true;
    validationInError = false;

    // For satisfying linting rules.
    strDOB = 'dob';
    strZip = 'zip';

    error = false;
    loading = true;
    showSuccess = false;
    showAaPaymentForm = false;

    domainInfo: IDomainInfo;
    consumer: IConsumer;

    agentAssistedPaymentEmailGUID: string;
    validation: string;
    validationZipCodeLabelText: string;
    validationDateLabelText: string;
    enterValidationMessage: string;
    genericErrorMessage: string;
    incorrectValidationEnteredMessage: string;
    currentErrorMessage: string;
    paymentGUIDNoLongerValidMessage: string;
    devicelessPaymentsLinkUsed: string;
    headlineText: string;
    submitButtonText: string;
    token: string;
    paymentType: string;
    invalidDateErrorText: string;

    private ngUnsubscribe: Subject<any> = new Subject();

    constructor(
        private componentService: ComponentService,
        private consumerService: ConsumerService,
        private agentAssistedService: AgentAssistedService,
        private loginService: LoginService,
        private parentRouter: Router,
        private ngZone: NgZone,
        private formBuilder: FormBuilder
    ) {}

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit() {
        this.form = this.formBuilder.group({
            dob: [ null, Validators.required ],
            zip: [ null, Validators.required ]
        });

        this.agentAssistedPaymentEmailGUID = this.componentService.storageService.retrieve('agentAssistedPaymentEmailGUID');
        this.componentService.scrollPageToTop();
        this.componentService.contentService.content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(content => {
                this.headlineText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'deviceless', 'devicelessPaymentsValidationPageHeader').text;
                this.enterValidationMessage = this.componentService.contentService.tryGetContentItem(content, 'payment', 'deviceless', 'assistedPaymentsValidationPageSubheader').text;
                this.validationDateLabelText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'deviceless', 'devicelessPaymentsDOBLabel').text;
                this.validationZipCodeLabelText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'deviceless', 'devicelessPaymentsZipCodeLabel').text;
                this.incorrectValidationEnteredMessage = this.componentService.contentService.tryGetContentItem(content, 'payment', 'deviceless', 'assistedPaymentsValidationErrorMessage').text;
                this.paymentGUIDNoLongerValidMessage = this.componentService.contentService.tryGetContentItem(content, 'error', 'errorCode400', 'error40071').text;
                this.devicelessPaymentsLinkUsed = this.componentService.contentService.tryGetContentItem(content, 'payment', 'deviceless', 'devicelessPaymentsLinkUsed').text;
                this.invalidDateErrorText = this.componentService.contentService.tryGetContentItem(content, 'global', 'error', 'genericInvalidDateSelectedText').text;
            });

        this.loading = true;
        this.validateGuid();
    }

    async login() {
        // Always a payment for now, until signup and email links.
        // Here comes email links.
        let result;
        this.genericErrorMessage = '';
        this.error = false;
        let validationText;

        if (this.isDOB) {
            const dob = this.form.controls[this.strDOB].value;

            // DOB needs to be in "2023-8-17" format.
            validationText = [dob.getFullYear(), dob.getMonth() + 1, dob.getDate()].join('-');
        } else {
            validationText = this.form.controls[this.strZip].value;
        }

        try {
            result = await this.loginService.agentAssistedPaymentLogin(this.agentAssistedPaymentEmailGUID, validationText);

            if (result.success) {
                this.token = result.token;
                this.componentService.storageService.store('token', result.token);
                this.componentService.storageService.store('agentassistedrole', result.paymentType);
                this.componentService.storageService.store('confirmationMessageType', result.confirmationMessageType);
                this.componentService.storageService.store('agentAssistedPaymentEmailGUID', this.agentAssistedPaymentEmailGUID);
                this.paymentType = result.paymentType;

                await this.consumerService.getConsumer();
                this.doneLoading();
                this.redirectUser();
            } else {
                this.validationInError = true;
                if (this.isDOB) {
                    this.dobValid = false;
                } else {
                    this.zipValid = false;
                }
            }
        } catch (err) {
            this.genericErrorMessage = 'Deviceless Payment could not be found. Please try again later.';
            this.error = true;
        }
    }

    async continue(): Promise<void> {
        // Catch an empty DOB or Zip Code values in case the
        // User clicks "Continue" without first entering a value.
        if (this.isDOB) {
            const dob = this.form.controls[this.strDOB].value;
            this.dobValid = !!dob;
        } else {
            const zip = this.form.controls[this.strZip].value;
            this.zipValid = zip && zip.trim();
        }

        if ((this.isDOB && this.dobValid) ||
            (!this.isDOB && this.zipValid)
        ) {
            this.loading = true;

            this.login().then(() => {
                this.doneLoading();
            });
        }
    }

    onDobChanged(event: any) {
        // A null or garbage date will cause this value to be null.
        this.dobValid = event.target.value;
        if (this.validationInError) {
            this.login().then(() => {
                this.doneLoading();
            });
        }
    }

    onZipChange(event: any) {
        const zip = event.target.value;

        // Zip Code is at least five characters.
        if (zip && zip.trim().length >= 5) {
            // Zip Code is entirely numeric.
            this.zipValid = !Number.isNaN(Number(zip.substring(0, 5)));
            if (this.validationInError) {
                this.login().then(() => {
                    this.doneLoading();
                });
            }

        } else {
            this.zipValid = false;
        }
    }

    private doneLoading() {
        this.loading = false;
    }

    hasError(): boolean {
        return this.error;
    }

    private isLoading(): boolean {
        return this.loading;
    }

    private redirectUser(): void {
        if (this.paymentType) {
            this.ngZone.run(() => {
                switch (this.paymentType.toLowerCase()) {
                    case 'autopay':
                        this.parentRouter.navigateByUrl('/autopayenrollment');
                        break;
                    case 'autopayexisting':
                        this.parentRouter.navigateByUrl('/wallet');
                        break;
                    case 'future':
                        this.parentRouter.navigateByUrl('/payment');
                        break;
                    case 'payment':
                        this.parentRouter.navigateByUrl('/payment');
                        break;
                    case 'paymentplannew':
                        this.parentRouter.navigateByUrl('/paymentplans/new');
                        break;
                    case 'paymentplanexisting':
                        this.parentRouter.navigateByUrl('/wallet');
                        break;
                    case 'pre-servicenew':
                        this.componentService.storageService.store('agentAssistedRole', 'preservicenew');
                        this.parentRouter.navigateByUrl('/recurringpaymentplan');
                        break;
                    case 'recurringnew':
                        this.parentRouter.navigateByUrl('/recurringpaymentplan');
                        break;
                    case 'pre-serviceexisting':
                        this.componentService.storageService.store('agentAssistedRole', 'preserviceexisting');
                        this.parentRouter.navigateByUrl('/wallet');
                        break;
                    case 'recurringexisting':
                        this.componentService.storageService.store('agentAssistedRole', 'recurringexisting');
                        this.parentRouter.navigateByUrl('/wallet');
                        break;
                    case 'wallet':
                        this.parentRouter.navigateByUrl('/wallet');
                        break;
                    default:
                        this.genericErrorMessage = 'Deviceless Payment - Payment Type could not be found. Please contact Support.';
                        this.error = true;
                }
            });
        } else {
            this.genericErrorMessage = 'Deviceless Payment - Payment Type could not be found. Please contact Support.';
            this.error = true;
        }
    }

    private validateGuid() {
        this.agentAssistedService.isAgentAssistedGUIDValid(this.agentAssistedPaymentEmailGUID)
            .then(async agentAssistedValidation => {
                const status = agentAssistedValidation.status.toLowerCase();
                const type: string = agentAssistedValidation.paymentType.toLowerCase();

                const isPayment: boolean =
                    type === 'payment' ||
                    type === 'recurring' ||
                    type === 'future';

                const isPlanOrAgreementValid: boolean =
                    !isPayment &&
                    (status === 'active' || status === 'staged');

                if ((isPayment && status !== 'staged') ||
                    (!isPayment && !isPlanOrAgreementValid)) {
                    // Deviceless Payment have moved beyond the 'Staged' status.
                    // Or Plan or Agreement are not Active or Staged
                    this.error = true;
                    this.showAaPaymentForm = false;
                    this.genericErrorMessage = this.devicelessPaymentsLinkUsed;
                    this.doneLoading();
                } else if (agentAssistedValidation.expired === true) {
                    // Deviceless link has expired.
                    this.error = true;
                    this.showAaPaymentForm = false;
                    this.genericErrorMessage = this.paymentGUIDNoLongerValidMessage;
                    this.doneLoading();
                } else {
                    // We're good to go!
                    await this.getAgentAssistedPaymentEmailValidationType();
                }
            }).catch(err => {
                this.error = true;
                this.showAaPaymentForm = false;
                this.genericErrorMessage = this.paymentGUIDNoLongerValidMessage;
                this.doneLoading();
                const errorMessage = this.componentService.handleValidationErrorCode(err.message);
                this.componentService.loggingService.log(
                    'Deviceless Payment could not be found or is invalid for GUID: ' +
                    this.agentAssistedPaymentEmailGUID + '  Error: ' +
                    errorMessage, LoggingLevel.error);
            });
    }

    private async getAgentAssistedPaymentEmailValidationType() {
        try {
            const result = await this.agentAssistedService.getAgentAssistedEmailTextValidationMethod(this.agentAssistedPaymentEmailGUID);
            const validationType = result.toLowerCase();
            if (validationType === 'dob') {
                this.isDOB = true;
            } else if (validationType === 'zip code') {
                this.isDOB = false;
            } else {
                this.genericErrorMessage = 'Deviceless Payment Validation Type could not be found. Please contact Support.';
                this.error = true;
                this.validType = false;
            }

            this.doneLoading();
        } catch (err) {
            this.genericErrorMessage = 'Deviceless Payment Validation Type could not be found. Please contact Support.';
            this.error = true;
            this.doneLoading();
        }

        this.showAaPaymentForm = true;
    }
}
