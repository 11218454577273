import { IMerchantProfile } from './imerchantprofile';
import { IPaymentDetail } from './paymentdetail';
import { PaymentMethod } from './paymentmethod';

export class StatementHistoryPayment {
    documentID: number;
    consumerPaymentGUID: string;
    status: string;
    paymentDate: Date;
    accountName: string;
    customerAccountID: string;
    totalAmount: number;
    source: string;
    customerID: number;
    domainID: number;
    accountNumber: string;
    method: string;
    type: string;
    cardType: string;
    paymentMethodLast4: string;
    class: string;
    downloadPayment: boolean;
    paymentPdfUrl: string;
    details: IPaymentDetail[];
    paymentMethod: PaymentMethod;
    merchantProfileGUID: string;
    merchantProfile: IMerchantProfile;
}
