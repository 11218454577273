/* tslint:disable:triple-equals */
import { CurrencyPipe } from '@angular/common';
import { Component, Input, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ICommunicationPreferences } from '../../../models/communicationPreferences';
import { IConsumer } from '../../../models/consumer';
import { ConsumerAccount } from '../../../models/consumeraccount';
import { IDomainInfo } from '../../../models/domaininfo';
import { GuarantorEmailViewModel } from '../../../models/guarantoremailviewmodel';
import { ComponentService } from '../../../services/component/component.service';
import { ConsumerService } from '../../../services/consumer/consumer.service';
import { PaymentPlanService } from '../../../services/paymentplan/paymentplan.service';
import { ConsumerContactDetailsModalComponent } from '../ConsumerContactDetailsModal/consumercontactdetailsmodal.component';

@Component({
    selector: 'consumer-system-message',
    template: require('./consumersystemmessage.component.html'),
    styles: [require('./consumersystemmessage.component.css')]
})

export class ConsumerSystemMessageComponent implements OnInit, OnDestroy {

    @Input() showNewBalanceMessageOnPage = false;
    token: string;
    domainInfo: IDomainInfo;
    message: string;
    isVerified: boolean;
    emailIsPresent: boolean;
    verificationEmailSent = false;
    verificationEmailSentTo = '';
    consumerAccount: ConsumerAccount;
    consumer: IConsumer;
    emailVerificationMessageText: string;
    emailVerificationHeadlineText: string;
    emailVerificationLinkText: string;
    emailVerificationSentText: string;
    newBalanceMessageText: string;
    newBalanceHeadlineText: string;
    newBalanceLinkText: string;
    paperDeliveryEmailVerificationMessageText: string;
    verificationMessage: string;
    offPlanBalance = 0;
    isConsolidatedCommunicationEnabled: boolean;
    communicationPreferences: ICommunicationPreferences;

    @ViewChild('contactDetailsModal', { static: false }) contactDetailsModal: ConsumerContactDetailsModalComponent;

    private ngUnsubscribe: Subject<any> = new Subject();

    constructor(
        private componentService: ComponentService,
        private consumerService: ConsumerService,
        private paymentPlanService: PaymentPlanService,
        private parentRouter: Router,
        private currencyPipe: CurrencyPipe,
        private ngZone: NgZone
    ) { }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    async ngOnInit(): Promise<void> {
        this.componentService.domainService.domainInfo$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                domainInfo => {
                    this.domainInfo = domainInfo;
                    this.isConsolidatedCommunicationEnabled = domainInfo.useConsolidatedCommunication;
                });

        this.verificationEmailSent = this.componentService.storageService.exists('verificationemailsent');
        this.verificationEmailSentTo = this.componentService.storageService.retrieve('verificationemailsentto');
        this.componentService.contentService.content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((content: any) => {
                this.paperDeliveryEmailVerificationMessageText = this.componentService.contentService.tryGetContentItem(content, 'global', 'message', 'emailVerificationMessagePaperDelivery').text;
                this.emailVerificationMessageText = this.componentService.contentService.tryGetContentItem(content, 'global', 'message', 'emailVerificationMessage').text;
                this.emailVerificationHeadlineText = this.componentService.contentService.tryGetContentItem(content, 'global', 'message', 'emailVerificationHeadline').text;
                this.emailVerificationLinkText = this.componentService.contentService.tryGetContentItem(content, 'global', 'message', 'emailVerificationLinkText').text;
                this.emailVerificationSentText = this.componentService.contentService.tryGetContentItem(content, 'global', 'message', 'emailVerificationSentText').text;
                this.newBalanceMessageText = this.componentService.contentService.tryGetContentItem(content, 'global', 'message', 'newBalanceMessage').text;
                this.newBalanceHeadlineText = this.componentService.contentService.tryGetContentItem(content, 'global', 'message', 'newBalanceHeadline').text;
                this.newBalanceLinkText = this.componentService.contentService.tryGetContentItem(content, 'global', 'message', 'newBalanceLinkText').text;
            });

        this.token = this.componentService.storageService.retrieve('token');
        await this.evaluateShowEmailVerificationMessage();
        this.getBalances();

        if (this.domainInfo && this.isConsolidatedCommunicationEnabled) {
            this.communicationPreferences =
                await this.consumerService.getConsumerCommunicationPreferences(
                    this.consumerAccount.customerAccountID
                );
        }

        this.setVerificationMessage();
    }

    /**
     * Get the non-plan balance and fill in the headline amount
     *
     * @memberof ConsumerSystemMessageComponent
     */
    getBalances(): void {
        this.paymentPlanService.getBalanceDetails(this.consumerAccount.customerAccountID)
            .then(balances => {
                if (balances != null) {
                    this.offPlanBalance = balances.filter(
                        x => x.paymentPlanGUID == this.componentService.NULL_GUID
                            && x.isPaymentPlanEligible
                            && x.isActive
                            && x.isAccountPaymentPlanEligible)
                        .map(c => c.balance)
                        .reduce((sum, current) => sum + current, 0);
                    this.newBalanceHeadlineText = this.newBalanceHeadlineText.replace(
                        '!AMOUNT!', this.currencyPipe.transform(this.offPlanBalance, 'USD', 'symbol'));
                }
            });
    }

    async evaluateShowEmailVerificationMessage() {
        this.consumer = await this.consumerService.getConsumer(this.token);
        this.consumerAccount = await this.consumerService.getConsumerAccount();
        this.emailIsPresent = this.isEmailPresent();
        this.isVerified = !!this.consumerAccount.emailAddress ? this.consumerAccount.isVerified :
            !!this.consumer.emailAddress && this.consumer.isEmailVerified;
    }

    showEmailVerificationMessage(): boolean {
        return this.emailIsPresent && this.isVerified == false;
    }

    showEmailSentNotification(): boolean {
        return this.verificationEmailSent;
    }

    showMessage(): boolean {
        return this.showEmailVerificationMessage() || this.showNewBalanceMessage();
    }

    showNewBalanceMessage(): boolean {
        return this.showNewBalanceMessageOnPage
        && (this.domainInfo != null ? this.domainInfo.enablePaymentPlans && this.offPlanBalance > 0 : false)
            && (this.domainInfo.enableWallet || this.domainInfo.paymentPlanOnly);
    }

    goToPaymentPlans(): void {
        this.ngZone.run(() => this.parentRouter.navigateByUrl('/paymentplans/new')).then();
    }

    sendVerificationEmail(): void {
        if (this.consumerAccount.emailAddress && !this.consumerAccount.isVerified) {
            // Show consolidation modal
            this.contactDetailsModal.openModal();
        }
        else {
            this.sendVerificationEmailUsingConsumerService();
        }
    }

    onEmailUpdated(emailAddress: string): void {
        this.consumer.emailAddress = emailAddress;
    }

    private sendVerificationEmailUsingConsumerService() {
        this.consumerService.sendVerificationEmail(this.consumerAccount.customerAccountID, this.consumer.emailAddress)
            .then(response => {
                this.verificationEmailSent = true;
                this.verificationEmailSentTo = response;
                this.componentService.storageService.store('verificationemailsent', true);
                this.componentService.storageService.store('verificationemailsentto', response);
            })
            .catch(error => {
                this.componentService.loggingService.handleError(error);
            });

    }

    private isEmailPresent(): boolean {
        if (!!this.consumerAccount.emailAddress) {
            return true;
        }
        else if (!!this.consumer.emailAddress) {
            return true;
        }
        else {
            return false;
        }
    }

    private setVerificationMessage() {
        if (this.isDeliveryMethodPaperExclusive()) {
            this.verificationMessage = this.paperDeliveryEmailVerificationMessageText;
        } else {
            this.verificationMessage = this.emailVerificationMessageText;
        }
    }

    private isDeliveryMethodPaperExclusive(): boolean {
        const isCommunicationPreferencePaperOnlyPreferred =
            this.isConsolidatedCommunicationEnabled &&
            this.communicationPreferences &&
            this.communicationPreferences.statementPaperEnabled &&
            !this.communicationPreferences.statementEmailEnabled &&
            !this.communicationPreferences.statementSmsEnabled;

        return isCommunicationPreferencePaperOnlyPreferred ||
            (!this.isConsolidatedCommunicationEnabled &&
                this.consumerAccount.deliveryMethod.toLocaleLowerCase() == 'paper');
    }
}
