import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IConsumer } from '../../../models/consumer';
import { ConsumerAccount, ConsumerAccountDeliveryPreferences } from '../../../models/consumeraccount';
import { IDomainInfo } from '../../../models/domaininfo';
import { ComponentService } from '../../../services/component/component.service';
import { ConsumerService } from '../../../services/consumer/consumer.service';

@Component({
    selector: 'manage-delivery-profile',
    template: require('./managedeliveryprofile.component.html'),
    styles: [require('./managedeliveryprofile.component.css')]
})

export class ManageDeliveryProfileComponent implements OnInit, OnDestroy {
    @Input() emailAddress = '';
    @Input() useVerificationID = false;
    @Output() setProfileCompleteness: EventEmitter<any> = new EventEmitter<any>();
    domainInfo: IDomainInfo;
    consumerAccountPreferences: ConsumerAccountDeliveryPreferences;
    consumerAccount: ConsumerAccount;
    consumer: IConsumer;
    deliveryMethodLoading = false;
    emailLoading = false;
    updateEmail = false;
    showEmailError = false;
    showDeliveryMethodError = false;
    originalEmailAddress: string;
    emailAddressUpdate: string;
    deliveryMethodUpdate: string;
    lastEStatementDate: Date;
    verificationID: string;
    invalidVerificationID = false;

    header: string;
    subheading: string;
    emailText: string;
    emailUpdateText: string;
    emailUpdateCancelText: string;
    emailUpdateSubmitText: string;
    statementDeliveryText: string;
    // semi-colon delimited list of options:text for Delivery Method (e.g. "Electronic:e-Delivery;Paper:Paper;Both:Electronic & Paper")
    deliveryChoices: string;
    deliveryMethodUpdateText: string;
    deliveryMethodUpdateSubmitText: string;
    deliveryMethodUpdateCancelText: string;
    emailErrorText: string;
    accountNotFoundText: string;

    private ngUnsubscribe: Subject<any> = new Subject();

    constructor(private componentService: ComponentService, private consumerService: ConsumerService, private parentRouter: Router) {
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit() {
        this.componentService.contentService.content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((content: any) => {
                this.header = this.componentService.contentService.tryGetContentItem(
                    content, 'profile', 'pageText', 'profilePageTitle').text;

                this.subheading = this.componentService.contentService.tryGetContentItem(
                    content, 'profile', 'pageText', 'profilePageSubheading').text;

                this.emailText = this.componentService.contentService.tryGetContentItem(
                    content, 'profile', 'pageText', 'profileEmailText').text;

                this.emailUpdateText = this.componentService.contentService.tryGetContentItem(content, 'profile', 'pageText', 'profileEmailUpdateText').text;
                this.emailUpdateSubmitText = this.componentService.contentService.tryGetContentItem(content, 'profile', 'pageText', 'profileEmailUpdateSubmitText').text;
                this.emailUpdateCancelText = this.componentService.contentService.tryGetContentItem(content, 'profile', 'pageText', 'profileEmailUpdateCancelText').text;
                this.statementDeliveryText = this.componentService.contentService.tryGetContentItem(content, 'profile', 'pageText', 'profileStatementDeliveryText').text;
                this.deliveryChoices = this.componentService.contentService.tryGetContentItem(content, 'profile', 'pageText', 'profileDeliveryMethodChoices').text;
                this.deliveryMethodUpdateText = this.componentService.contentService.tryGetContentItem(content, 'profile', 'pageText', 'profileDeliveryMethodUpdateText').text;
                this.deliveryMethodUpdateSubmitText = this.componentService.contentService.tryGetContentItem(content, 'profile', 'pageText', 'profileDeliveryMethodUpdateSubmitText').text;
                this.deliveryMethodUpdateCancelText = this.componentService.contentService.tryGetContentItem(content, 'profile', 'pageText', 'profileDeliveryMethodUpdateCancelText').text;
                this.emailErrorText = this.componentService.contentService.tryGetContentItem(content, 'profile', 'error', 'profileEmailUpdateErrorText').text;
                this.accountNotFoundText = this.componentService.contentService.tryGetContentItem(content, 'profile', 'error', 'profileAccountNotFoundErrorText').text;
            });

        this.componentService.domainService.domainInfo$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(domainInfo => {
            this.domainInfo = domainInfo;
            if (!!domainInfo) {
                if (!this.domainInfo.enableDeliveryBoth) {
                    this.deliveryChoices = this.deliveryChoices.split(';').filter(x => !x.includes('&')).join(';');
                }
                if (this.domainInfo.deliveryMode.toLocaleLowerCase().includes('paper')) {
                    this.deliveryChoices = this.deliveryChoices.split(';').filter(x => x.toLocaleLowerCase().includes('paper')).join(';');
                }
            }
        });

        if (this.useVerificationID) {
            if (this.componentService.storageService.exists('accountverificationid')) {
                this.verificationID = this.componentService.storageService.retrieve('accountverificationid');
                this.consumerService.getConsumerAccountDeliveryPreferencesByVerificationID(this.verificationID).then(preferences => {
                    this.consumerAccountPreferences = preferences;
                    this.lastEStatementDate = preferences.lastEStatementDate;
                    this.deliveryMethodUpdate = preferences.deliveryMethod;
                    this.emailAddressUpdate = this.originalEmailAddress = preferences.emailAddress;
                }).catch(error => {
                    this.invalidVerificationID = true;
                });
            } else {
                this.invalidVerificationID = true;
            }
        } else {
            this.populateConsumer();
        }

    }

    submitUpdateEmail(): void {
        this.emailLoading = true;

        if (this.useVerificationID) {
            this.consumerService.updateConsumerAccountPreferencesByVerificationID(
                this.verificationID, this.emailAddressUpdate, this.deliveryMethodUpdate, false).then(response => {
                    // something changed with the shape of the response on these Observables... (was response.success)
                    if (response.body.success) {
                        this.emailLoading = false;
                        this.toggleUpdateEmail(false);
                        this.originalEmailAddress = this.emailAddressUpdate;
                    } else {
                        this.emailLoading = false;
                        this.showEmailError = true;
                    }
                },
                () => {
                    this.emailLoading = false;
                    this.showEmailError = true;
                });
        } else {
            this.consumerService.updateConsumerAccountPreferences(
                this.consumerAccount.customerAccountID, this.emailAddressUpdate, this.deliveryMethodUpdate).then(response => {
                    // something changed with the shape of the response on these Observables... (was response.success)
                    if (response.body.success) {
                        this.consumerService.updateConsumerAccountStorage().then(updated => {
                            this.consumerAccount.emailAddress = this.emailAddressUpdate;
                            this.emailLoading = false;
                            this.updateEmail = false;
                            this.setProfileCompleteness.emit();
                            this.originalEmailAddress = this.emailAddressUpdate;
                        });
                    } else {
                        this.emailLoading = false;
                        this.showEmailError = true;
                    }
                },
                () => {
                    this.emailLoading = false;
                    this.showEmailError = true;
                });
        }
    }

    submitUpdateDeliveryMethod(): void {
        this.deliveryMethodLoading = true;

        if (this.useVerificationID) {
            this.consumerService.updateConsumerAccountPreferencesByVerificationID(
                this.verificationID,
                null,
                this.deliveryMethodUpdate,
                false
            ).then(response => {
                    this.deliveryMethodLoading = false;

                    // something changed with the shape of the response on these Observables... (was response.success)
                    if (!response.body.success) {
                        this.showDeliveryMethodError = true;
                    }
                },
                () => {
                    this.deliveryMethodLoading = false;
                    this.showDeliveryMethodError = true;
                });
        } else {
            this.consumerService.updateConsumerAccountPreferences(
                this.consumerAccount.customerAccountID,
                null,
                this.deliveryMethodUpdate
            ).then(response => {
                    this.deliveryMethodLoading = false;

                    // something changed with the shape of the response on these Observables... (was response.success)
                    if (response.body.success) {
                        this.consumerService.updateConsumerAccountStorage().then(updated => {
                            this.consumerAccount.deliveryMethod = this.deliveryMethodUpdate;
                            this.setProfileCompleteness.emit();
                        });
                    } else {
                        this.showDeliveryMethodError = true;
                    }
                },
                () => {
                    this.deliveryMethodLoading = false;
                    this.showDeliveryMethodError = true;
                });
        }
    }

    public disableElectronicDeliveryOption(value: any): boolean {
        if (!this.originalEmailAddress) {
            if (value.toLowerCase() === 'both' || value.toLowerCase() === 'electronic') {
                return true;
            }
        }
        return false;
    }

    private emailInputUpdated(event: any): void {
        this.showEmailError = true;
    }

    toggleUpdateEmail(isCancel: boolean): void {
        // reset to original value
        if (isCancel) {
            this.emailAddressUpdate = this.originalEmailAddress;
        }

        this.updateEmail = !this.updateEmail;
    }

    public populateConsumer(): void {

        this.consumerService.getConsumer().then(consumer => {
            this.consumer = consumer;
            this.consumerService.getConsumerAccount().then(consumerAccount => {
                if (!!consumerAccount) {
                    this.saveConsumerAccount(consumerAccount);
                }
            });
        });
    }

    private saveConsumerAccount(account: ConsumerAccount): void {
        if (!this.domainInfo.enableDeliveryBoth && this.deliveryChoices != null) {
            this.deliveryChoices = this.deliveryChoices.split(';').filter(x => !x.includes('&')).join(';');
        }
        if (this.domainInfo.deliveryMode.toLocaleLowerCase().includes('paper') && this.deliveryChoices != null) {
            this.deliveryChoices = this.deliveryChoices.split(';').filter(x => x.toLocaleLowerCase().includes('paper')).join(';');
        }

        this.consumerAccount = account;
        this.deliveryMethodUpdate = account.deliveryMethod;
        this.emailAddressUpdate = account.emailAddress;
        this.originalEmailAddress = account.emailAddress;
        this.lastEStatementDate = account.lastEStatementDate;
    }

}
