import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ICommunicationPreferences } from '../../../models/communicationPreferences';
import { CommunicationPreference, ICommunicationPreferenceChange } from '../../../models/communicationPreferenceType';
import { IConsumer } from '../../../models/consumer';
import { ConsumerAccount } from '../../../models/consumeraccount';
import { IDomainInfo } from '../../../models/domaininfo';
import { GuarantorEmailViewModel } from '../../../models/guarantoremailviewmodel';
import { ComponentService } from '../../../services/component/component.service';
import { ConsumerService } from '../../../services/consumer/consumer.service';
import { ConsumerStorageService } from '../../../services/indexedDb/consumer-storage.service';
import { LoginService } from '../../../services/login/login.service';
import { DateUtils } from '../../../util/date.utils';

@Component({
  selector: 'estatement-prompt-modal',
  template: require('./estatement-prompt-modal.component.html'),
  styles: [require('./estatement-prompt-modal.component.css')]
})
export class EStatementPromptModalComponent implements OnInit {
  modalRef: BsModalRef;
  config: any = {
      keyboard: false, // allow them to press Escape key
      backdrop: true, // dim the page with a backdrop
      ignoreBackdropClick: true, // Disallow them from clicking the backdrop to dismiss
      animated: false
  };

  form: FormGroup;
  currentStep = 1;
  currentStepPos = 0;
  activeSteps = [ 1 ];
  emailViewModel: GuarantorEmailViewModel[];
  accountVerificationId: string;
  originalCommunicationPreferences: ICommunicationPreferences;
  enableSMS = false;
  confirmationError = false;
  emailAddress: string;
  phoneNumber: string;
  emailVerificationCode$ = new BehaviorSubject<string>('');

  // Content items
  modalHeader: string;
  step1ModalSubheader: string;
  step1Label: string;
  backButtonLabel: string;
  closeButtonLabel: string;
  continueButtonLabel: string;
  emailCommunicationPreference: string;
  paperCommunicationPreference: string;
  smsCommunicationPreference: string;
  confirmationMessage1: string;
  confirmationMessage2: string;
  confirmationOn: string;
  confirmationOff: string;
  step1ErrorMessage: string;
  communicationPrefSaveErrorMessage: string;

  step1Error$ = new BehaviorSubject<string>(null);

  consumer: IConsumer;
  domainInfo: IDomainInfo;

  @ViewChild('modaltemplate', { static: false }) modalTemplate: TemplateRef<HTMLTemplateElement>;
  @Output() emailUpdated = new EventEmitter<boolean>();

  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(
    private componentService: ComponentService,
    private consumerService: ConsumerService,
    private consumerStorageService: ConsumerStorageService,
    private formBuilder: FormBuilder,
    private loginService: LoginService,
    private modalService: BsModalService
  ) { }

  ngOnInit(): void {
    if (this.componentService.storageService.retrieve('sb')) {
      return;
    }

    this.componentService.domainService.getDomainInfo()
      .then(async domainInfo => {
        this.domainInfo = domainInfo;
        this.enableSMS = domainInfo.enableSMS;
        if (domainInfo.enableEStatementPrompt) {
          this.consumer = await this.consumerService.getConsumer();
          this.emailViewModel = [{
            emailAddress: this.consumer.emailAddress,
            displayValue: this.consumer.emailAddress,
            type: null, // Required but unused: keeping it null for clarity
            guarantorEmailAddressUsed: true
          }];

          const consumerAccount = await this.consumerService.getConsumerAccount();
          this.accountVerificationId = consumerAccount.accountGUID;

          this.originalCommunicationPreferences =
            await this.consumerService.getConsumerCommunicationPreferences(
              consumerAccount.customerAccountID
            );

          if (this.modalCheck(consumerAccount)) {
            this.setupForm(this.originalCommunicationPreferences);
            this.openModal();
            this.consumerService.updateLastEStatementPrompt(this.accountVerificationId).then(response => {
              if (response) {
                // Refetch the consumer so that this doesn't appear if they navigate back to the home page:
                this.consumerService.updateConsumerAccountStorage();
              }
            });
          }
        }
      });

    this.initTimeoutSubscription();

    this.componentService.contentService.content$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(c => {
      this.modalHeader = this.componentService.contentService.tryGetContentItem(c, 'enrollment', 'pageText', 'enrollmentModalHeader').text;
      this.step1ModalSubheader = this.componentService.contentService.tryGetContentItem(c, 'enrollment', 'pageText', 'enrollmentModalSubheader').text;
      this.backButtonLabel = // The linter only minded this line, not the other long ones. \_ -_- _/
        this.componentService.contentService.tryGetContentItem(c, 'payment', 'loggedInPayment', 'paymentBackButton').text;
      this.continueButtonLabel = this.componentService.contentService.tryGetContentItem(c, 'payment', 'loggedInPayment', 'paymentContinueButton').text;
      this.step1Label = this.componentService.contentService.tryGetContentItem(c, 'enrollment', 'pageText', 'enrollmentStep1Label').text;
      this.closeButtonLabel = this.componentService.contentService.tryGetContentItem(c, 'communicationpreferences', 'pageText', 'profileModalCloseButtonLabel').text;
      this.emailCommunicationPreference = this.componentService.contentService.tryGetContentItem(c, 'profile', 'pageText', 'profileEmailCommunicationPreferences').text;
      this.paperCommunicationPreference = this.componentService.contentService.tryGetContentItem(c, 'profile', 'pageText', 'profilePaperCommunicationPreferences').text;
      this.smsCommunicationPreference = this.componentService.contentService.tryGetContentItem(c, 'profile', 'pageText', 'profileTextCommunicationPreferences').text;
      this.confirmationMessage1 = this.componentService.contentService.tryGetContentItem(c, 'enrollment', 'pageText', 'enrollmentModalConfirmationSubheader').text;
      this.confirmationMessage2 = this.componentService.contentService.tryGetContentItem(c, 'enrollment', 'pageText', 'enrollmentModalConfirmationLabel').text;
      this.confirmationOn = this.componentService.contentService.tryGetContentItem(c, 'enrollment', 'pageText', 'enrollmentModalConfirmationOn').text;
      this.confirmationOff = this.componentService.contentService.tryGetContentItem(c, 'enrollment', 'pageText', 'enrollmentModalConfirmationOff').text;
      this.step1ErrorMessage = this.componentService.contentService.tryGetContentItem(c, 'enrollment', 'pageText', 'enrollmentStep1ErrorMessage').text;
      this.communicationPrefSaveErrorMessage = this.componentService.contentService.tryGetContentItem(c, 'enrollment', 'pageText', 'enrollmentPreferenceSaveErrorMessage').text;
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  modalCheck(ca: ConsumerAccount): boolean {
    const paperEnabled = this.originalCommunicationPreferences.statementPaperEnabled;
    const emailEnabled = this.originalCommunicationPreferences.statementEmailEnabled;
    const emailVerified = this.consumer.isEmailVerified;
    const lastPrompt = ca.lastEStatementPrompt;
    const promptDays = this.domainInfo.eStatementPromptIntervalDays;

    return (
      (paperEnabled || (emailEnabled && !emailVerified)) &&
      (!lastPrompt || DateUtils.addDays(lastPrompt, promptDays) < new Date())
    );
  }

  openModal(): void {
      this.modalRef = this.modalService.show(this.modalTemplate, this.config);
  }

  closeModal() {
    this.modalRef.hide();

    this.consumerService
      .getConsumerCommunicationPreferences(this.consumer.accountID)
      .then(currentPrefs => {
        // It is unlikely, but possible, that the user turned off some preferences in step 1
        // but never followed through with setting up new ones on the following steps
        // and thus managed to get rid of *all* their delivery preferences.
        // We're checking prefs here and, if that's the case, setting it back to what it was originally:
        if (!currentPrefs.statementEmailEnabled &&
          !currentPrefs.statementSmsEnabled &&
          !currentPrefs.statementPaperEnabled) {
            const changes = [{
              type: CommunicationPreference.StatementEmail,
              newValue: this.originalCommunicationPreferences.statementEmailEnabled
            },
            {
              type: CommunicationPreference.StatementSms,
              newValue: this.originalCommunicationPreferences.statementSmsEnabled
            },
            {
              type: CommunicationPreference.StatementPaper,
              newValue: this.originalCommunicationPreferences.statementPaperEnabled
            }];

            this.consumerService.updateConsumerCommunicationPreference(
              this.consumer.accountID, changes
            );
          }
      });
  }

  setupForm(prefs: ICommunicationPreferences) {
    this.form = this.formBuilder.group({
      statementEmailEnabled: prefs.statementEmailEnabled,
      statementPaperEnabled: prefs.statementPaperEnabled,
      statementSmsEnabled: prefs.statementSmsEnabled
    });

    this.form.valueChanges.subscribe(() => {
      this.step1Error$.next(null);
    });

    if (!this.domainInfo.enableDeliveryBoth) {
      this.form.controls.statementEmailEnabled.valueChanges.subscribe(newVal => {
        if (newVal) {
          this.form.controls.statementPaperEnabled.setValue(false);
        }
      });

      this.form.controls.statementPaperEnabled.valueChanges.subscribe(newVal => {
        if (newVal) {
          this.form.controls.statementEmailEnabled.setValue(false);
        }
      });
    }
  }

  onEmailUpdated(event: string) {
    this.emailAddress = event;
    this.consumerStorageService.updateConsumer({
      isEmailVerified: true
    }).then(updated => {
      // Are we also updating SMS? If not clear out prefChanges.
      if (!this.form.controls.statementSmsEnabled.value) {
        this.componentService.storageService.clear('prefChanges');
      }

      this.emailUpdated.emit(updated > 0);
    });

    this.nextStep();
  }

  onPhoneNumberUpdated(event: string) {
    this.phoneNumber = event;

    if (!this.originalCommunicationPreferences.statementSmsEnabled) {
      // This will contain whether or not a Paper preference has changed.
      const prefChanges: ICommunicationPreferenceChange[] = this.componentService.storageService.retrieve('prefChanges');

      prefChanges.push({
        customerAccountID: this.consumer.accountID,
        type: CommunicationPreference.StatementSms,
        newValue: this.form.controls.statementSmsEnabled.value
      });

      this.consumerService.updateConsumerCommunicationPreference(
        this.consumer.accountID,
        prefChanges
      ).catch(() => {
        this.confirmationError = true;
      });

      this.componentService.storageService.clear('prefChanges');
    }

    this.nextStep();
  }

  nextStep() {
    if (this.currentStep === 1) {
      if (
        !this.form.controls.statementEmailEnabled.value &&
        !this.form.controls.statementSmsEnabled.value &&
        !this.form.controls.statementPaperEnabled.value
        ) {
          this.step1Error$.next(this.step1ErrorMessage);
          return;
        }

      this.activeSteps = [1];
      const prefChanges: ICommunicationPreferenceChange[] = [];

      const oldPaper = this.originalCommunicationPreferences.statementPaperEnabled;
      const newPaper = this.form.controls.statementPaperEnabled.value;
      if (oldPaper !== newPaper) {
        prefChanges.push({
          customerAccountID: this.consumer.accountID,
          type: CommunicationPreference.StatementPaper,
          newValue: newPaper
        });
      }

      const oldEmail = this.originalCommunicationPreferences.statementEmailEnabled;
      const newEmail = this.form.controls.statementEmailEnabled.value;
      if (oldEmail && !newEmail) {
        prefChanges.push({
          customerAccountID: this.consumer.accountID,
          type: CommunicationPreference.StatementEmail,
          newValue: newEmail
        });
      }

      if (newEmail) {
        this.activeSteps.push(2);
      }

      const oldSms = this.originalCommunicationPreferences.statementSmsEnabled;
      const newSms = this.form.controls.statementSmsEnabled.value;
      if (!oldSms && newSms) {
        this.activeSteps.push(3);
      }

      if (oldSms !== newSms) {
        prefChanges.push({
          customerAccountID: this.consumer.accountID,
          type: CommunicationPreference.StatementSms,
          newValue: newSms
        });
      }

      if (newEmail || newSms || prefChanges.length > 0) {
        // SMS was already set, so make sure we show the phone number on the confirmation modal.
        if (oldSms && newSms) {
          this.phoneNumber = this.consumer.phone;
        }

        this.activeSteps.push(4);
      } else {
        this.modalRef.hide(); // not closeModal() because, in this case, prefs are taken care of - we really do just want to close.
      }

      if (prefChanges.length > 0 && this.canUpdatePreferencesNow()) {
        this.consumerService.updateConsumerCommunicationPreference(
          this.consumer.accountID,
          prefChanges
        ).catch(() => {
          this.step1Error$.next(this.communicationPrefSaveErrorMessage);
        });
      } else {
        // Set changes to session so Email and/or Text modal(s) can use them.
        this.componentService.storageService.store('prefChanges', prefChanges);
      }
    }

    this.currentStepPos++;
    if (this.currentStepPos < this.activeSteps.length) {
      this.currentStep = this.activeSteps[this.currentStepPos];
    }
  }

  canUpdatePreferencesNow(): boolean {
    const oldEmail = this.originalCommunicationPreferences.statementEmailEnabled;
    const newEmail = this.form.controls.statementEmailEnabled.value;

    const oldSms = this.originalCommunicationPreferences.statementSmsEnabled;
    const newSms = this.form.controls.statementSmsEnabled.value;

    const oldPaper = this.originalCommunicationPreferences.statementPaperEnabled;
    const newPaper = this.form.controls.statementPaperEnabled.value;

    // Because no further validation is required the following
    // scenarios can be updated immediately upon clicking the
    // "Continue" button on this EStatementPromptModalComponent modal:
    if (oldEmail && !newEmail && oldSms === newSms && !oldPaper && newPaper) {
      // Email to Paper
      return true;
    } else if (oldEmail && !newEmail && oldSms === newSms && oldPaper && newPaper) {
      // Email and Paper to Paper
      return true;
    } else if (!oldEmail && !newEmail && oldSms && !newSms && oldPaper && newPaper) {
      // Text and Paper to Paper
      return true;
    } else {
      return false;
    }
  }

  prevStep() {
    this.currentStepPos--;
    if (this.currentStepPos >= 0) {
      this.currentStep = this.activeSteps[this.currentStepPos];
    }

    if (this.currentStep === 2) {
      this.emailVerificationCode$.next('');
    }
  }

  private initTimeoutSubscription() {
    this.loginService.sessionTimeout$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((timeout: boolean) => {
          if (timeout) {
              this.closeModal();
          }
      });
  }
}
