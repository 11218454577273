import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IConsumer } from '../../models/consumer';
import { IPaymentPlanDetails } from '../../models/paymentplandetails';
import { ComponentService } from '../../services/component/component.service';
import { ConsumerService } from '../../services/consumer/consumer.service';
import { PaymentPlanService } from '../../services/paymentplan/paymentplan.service';

@Component({
    selector: 'paymentplanlist',
    template: require('./paymentplanlist.component.html'),
    styles: [require('./paymentplanlist.component.css')],
})
export class PaymentPlanListComponent implements OnInit, OnDestroy {
    private ngUnsubscribe: Subject<any> = new Subject();

    paymentPlans: IPaymentPlanDetails[] = [];

    headerText: string;
    subheaderText: string;
    mobileHeaderText: string;
    paymentButtonLabel: string;
    backButtonLabel: string;
    isLoading: boolean;
    paymentRoute: string;
    consumer: IConsumer;
    hideMakePaymentButton: boolean;
    isAgentAssistedPayment = false;

    constructor(
        private componentService: ComponentService,
        private consumerService: ConsumerService,
        private paymentPlanService: PaymentPlanService
    ) { }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit(): void {
        this.loadPaymentPlans();
        this.componentService.contentService.content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((content: any) => {
                this.headerText = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'pageText', 'paymentPlanDetailsHeaderText').text;
                this.subheaderText = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'pageText', 'paymentPlanDetailsSubHeaderText').text;
                this.mobileHeaderText = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'pageText', 'paymentPlanDetailsMobileHeaderText').text;
                this.paymentButtonLabel = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'button', 'paymentPlanDetailsGoToPaymentButtonText').text;
                this.backButtonLabel = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'button', 'paymentPlanListBackButtonText').text;
            });

        this.componentService.getPaymentUrlForDomain().then(url => this.paymentRoute = url);

        if (this.componentService.storageService.exists('agentAssistedPaymentEmailGUID')) {
            this.hideMakePaymentButton = true;
            this.isAgentAssistedPayment = true;
        }
    }

    loadPaymentPlans(): void {
        this.isLoading = true;
        this.consumerService.getConsumer().then((consumer) => {
            this.consumer = consumer;
            this.paymentPlanService.getPaymentPlanDetailsForAccount(consumer.accountID).then((paymentPlans) => {
                this.paymentPlans = paymentPlans;
                if (this.paymentPlans.some(p => ComponentService.toDecimal(p.balance) > 0
                                                && (p.paymentPlanMode === 'Monthly'
                                                || p.paymentPlanMode === 'First')
                                        ) || this.paymentPlans.reduce(this.paymentPlansReducer, 0) <= 0
                ) {
                    this.hideMakePaymentButton = true;
                }
                this.isLoading = false;
            });
        });
    }

    paymentPlansReducer(acc: number, p: IPaymentPlanDetails) {
        return acc + p.balance;
    }
}
