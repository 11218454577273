import {Pipe, PipeTransform} from '@angular/core';

/**
 * The CoverUpPipe will cover up string starting from the left for a certain number
 * of characters that is also passed in.
 *
 * @export
 * @class CoverUpPipe
 * @implements {PipeTransform}
 */
@Pipe({
    name: 'coverup'
})

export class CoverUpPipe implements PipeTransform {
    public transform(input: string, hideChars: number = 0, hideChar: string = '*'): string {
        if (!!input && hideChars > 0) {
            return Array(hideChars + 1).join(hideChar) + input.substring(hideChars);
        } else {
            return input;
        }
    }
}
