/* tslint:disable:triple-equals */
import { CurrencyPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject ,  Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CreditCardIssuerType, OtherPaymentMethod, PaymentMethod, PaymentMethodType } from '../../../../models/paymentmethod';
import { ConsumerService } from '../../../../services/consumer/consumer.service';
import { PaymentSelectionStateService } from '../../../../services/paymentselection/paymentselection.state.service';
import { BasePaymentMethod } from '../../../Base/BasePaymentMethod/basepaymentmethod';
import { IMerchantProfile } from './../../../../models/imerchantprofile';
import { ComponentService } from './../../../../services/component/component.service';

@Component({
    selector: 'payment-method-display',
    template: require('./paymentmethoddisplay.component.html'),
    styles: [require('./paymentmethoddisplay.component.css')]
})

export class PaymentMethodDisplayComponent extends BasePaymentMethod implements OnInit {
    merchantProfileLoading = true;
    walletNotEmpty = false;
    paymentMethodToUpdate: PaymentMethod;
    creditCardDisabled = false;

    private paymentMethodsArray: PaymentMethod[];
    @Input()
    get paymentMethods() {
        return this.paymentMethodsArray;
    }
    set paymentMethods(array: PaymentMethod[]) {
        this.paymentMethodsArray = array;
        if (this.stateService.shouldSetDefaultMethod) {
            this.selectDefaultPaymentMethod();
        }
    }

    private paymentAmountValue = new BehaviorSubject<number>(0);
    @Input()
    get paymentAmount() {
        return this.paymentAmountValue.getValue() || 0;
    }
    set paymentAmount(value: number) {
        this.paymentAmountValue.next(value);
    }

    @Input() paymentDate: string;
    @Input() selectedPaymentMethod: PaymentMethod = OtherPaymentMethod[0];
    @Input() newPaymentMethod = false;
    @Input() isOneTimePayment = false;
    @Input() isAccountPayment = false;
    @Input() walletEnabled = true;
    @Input() quickPayWalletEnabled = false;
    @Output() public paymentMethodChange = new EventEmitter();
    @Output() public paymentMethodTypeChange = new EventEmitter();
    // Used to bubble up the model change value to the parent.
    @Output() public newPaymentMethodChange = new EventEmitter();
    @Output() public clearParentError = new EventEmitter();

    merchantCredentialMismatch = false;
    checkCredentialMismatch = false;
    modalOpen = false;

    allowCredit = true;
    allowACH = true;

    months: string[] = new Array(12);
    years: string[] = new Array(20);

    billingZipCodeText: string;
    expirationDateText: string;
    dropdownExpiredText: string;
    payToTheOrderOfText: string;
    accountNumberText: string;
    accountNumberConfirmationText: string;
    changePaymentMethodText: string;
    expirationErrorText: string;
    expirationRequiredText: string;
    stepValidationError: string;

    cardNumberText: string;
    creditCardError: string;
    creditCardIssuerError: string;
    cvvError: string;
    expirationText: string;
    saveToWalletText: string;
    creditDebitText: string;
    bankAccountText: string;
    visaActive: string = this.cardClassIfActive(false);
    mcActive: string = this.cardClassIfActive(false);
    discoverActive: string = this.cardClassIfActive(false);
    amExActive: string = this.cardClassIfActive(false);
    merchantProfile: IMerchantProfile;

    maskAmex: Array<string | RegExp> = [/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/];
    maskOther: Array<string | RegExp> = [/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    maskHidden: Array<string | RegExp> =
        ['•', '•', '•', '•', '-', '•', '•', '•', '•', '-', '•', '•', '•', '•', '-', /\d/, /\d/, /\d/, /\d/];
    mask: Array<string | RegExp> = this.maskOther;

    // Credit Card Data
    cvvMask: Array<string | RegExp> = [/\d/, /\d/, /\d/, /\d/];
    creditCardNumber = '';
    cvvNumber = '';
    expirationMonth = 'MM';
    expirationYear = 'YYYY';
    creditCardErrorExists = false;
    creditCardIssuerErrorExists = false;
    cvvErrorExists = false;
    expirationRequiredErrorExists = false;
    expirationErrorExists = false;
    expirationMonthRequiredErrorExists = false;
    expirationYearRequiredErrorExists = false;
    creditCardMaxAmountErrorExists = false;

    // ACH Data
    accountNumber = '';
    accountNumberConfirmation = '';
    accountNumberError: string;
    accountNumbersDoNotMatch: string;
    accountErrorExists: boolean;
    accountNumbersDoNotMatchErrorExists: boolean;
    routingNumberText: string;
    routingNumberError: string;
    routingErrorExists: boolean;
    accountTypeText: string;
    accountChoicesText: string;
    maxCCPaymentErrorText: string;
    maxCCPaymentErrorTextContentItem: string;
    routingNumber = '';
    accountType: string;

    // Tooltips
    cvvTooltip: string;
    routingNumberTooltip: string;
    isFirstCVVFocus = true;
    isFirstRoutingNumberFocus = true;

    selectedOtherPaymentType: PaymentMethodType = PaymentMethodType.credit;
    creditOtherSelected = 'primary-white';
    bankOtherSelected = 'white-primary';

    private ngUnsubscribe: Subject<any> = new Subject();

    constructor(
        private componentService: ComponentService,
        private stateService: PaymentSelectionStateService,
        private consumerService: ConsumerService,
        private currencyPipe: CurrencyPipe
    ) {
        super(componentService);
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit() {
        this.componentService.contentService.content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((content: any) => {
                if (this.isOneTimePayment) {
                    this.cardNumberText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'oneTimePayment', 'paymentCardNumber').text;
                    this.creditCardError = this.componentService.contentService.tryGetContentItem(content, 'error', 'oneTimePayment', 'paymentCreditCardError').text;
                    this.creditCardIssuerError = this.componentService.contentService.tryGetContentItem(content, 'error', 'oneTimePayment', 'paymentCreditCardIssuerError').text;
                    this.expirationText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'oneTimePayment', 'paymentExpirationText').text;
                    this.saveToWalletText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'oneTimePayment', 'paymentSaveToWalletText').text;
                    this.bankAccountText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'oneTimePayment', 'paymentBankAccountText').text;
                    this.creditDebitText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'oneTimePayment', 'paymentCreditDebitText').text;
                    this.cvvError = this.componentService.contentService.tryGetContentItem(content, 'error', 'oneTimePayment', 'paymentCvvErrorText').text;
                    this.billingZipCodeText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'oneTimePayment', 'paymentBillingZipCodeText').text;
                    this.expirationDateText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'oneTimePayment', 'paymentExpirationDateText').text;
                    this.dropdownExpiredText = this.componentService.contentService.tryGetContentItem(content, 'error', 'oneTimePayment', 'paymentDropdownExpirationErrorText').text;
                    this.payToTheOrderOfText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'oneTimePayment', 'paymentPayToTheOrderOfText').text;
                    this.accountNumberText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'oneTimePayment', 'paymentAccountNumberText').text;
                    this.accountNumberConfirmationText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'oneTimePayment', 'paymentConfirmAccountNumberText').text;
                    this.accountNumbersDoNotMatch = this.componentService.contentService.tryGetContentItem(content, 'error', 'oneTimePayment', 'paymentAccountNumbersDoNotMatchText').text;
                    this.changePaymentMethodText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'oneTimePayment', 'paymentChangePaymentMethodText').text;
                    this.expirationErrorText = this.componentService.contentService.tryGetContentItem(content, 'error', 'oneTimePayment', 'paymentExpirationErrorText').text;
                    this.expirationRequiredText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'oneTimePayment', 'paymentDropdownExpirationRequiredText').text;
                    this.accountNumberError = this.componentService.contentService.tryGetContentItem(content, 'error', 'oneTimePayment', 'paymentAccountNumberErrorText').text;
                    this.routingNumberError = this.componentService.contentService.tryGetContentItem(content, 'error', 'oneTimePayment', 'paymentRoutingNumberErrorText').text;
                    this.routingNumberText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'oneTimePayment', 'paymentRoutingNumberText').text;
                    this.accountTypeText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'oneTimePayment', 'paymentAccountTypeText').text;
                    this.accountChoicesText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'oneTimePayment', 'paymentAccountChoicesText').text;
                    this.maxCCPaymentErrorTextContentItem = this.componentService.contentService.tryGetContentItem(content, 'error', 'oneTimePayment', 'paymentMaxCreditCardAmountWarningText').text;
                } else {
                    this.cardNumberText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'loggedInPayment', 'paymentCardNumber').text;
                    this.creditCardError = this.componentService.contentService.tryGetContentItem(content, 'error', 'loggedInPayment', 'paymentCreditCardError').text;
                    this.creditCardIssuerError = this.componentService.contentService.tryGetContentItem(content, 'error', 'loggedInPayment', 'paymentCreditCardIssuerError').text;
                    this.expirationText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'loggedInPayment', 'paymentExpirationText').text;
                    this.saveToWalletText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'loggedInPayment', 'paymentSaveToWalletText').text;
                    this.bankAccountText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'loggedInPayment', 'paymentBankAccountText').text;
                    this.creditDebitText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'loggedInPayment', 'paymentCreditDebitText').text;
                    this.cvvError = this.componentService.contentService.tryGetContentItem(content, 'error', 'loggedInPayment', 'paymentCvvErrorText').text;
                    this.billingZipCodeText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'loggedInPayment', 'paymentBillingZipCodeText').text;
                    this.expirationDateText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'loggedInPayment', 'paymentExpirationDateText').text;
                    this.dropdownExpiredText = this.componentService.contentService.tryGetContentItem(content, 'error', 'loggedInPayment', 'paymentDropdownExpirationErrorText').text;
                    this.payToTheOrderOfText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'loggedInPayment', 'paymentPayToTheOrderOfText').text;
                    this.accountNumberText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'loggedInPayment', 'paymentAccountNumberText').text;
                    this.accountNumberConfirmationText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'loggedInPayment', 'paymentConfirmAccountNumberText').text;
                    this.accountNumbersDoNotMatch = this.componentService.contentService.tryGetContentItem(content, 'error', 'loggedInPayment', 'paymentAccountNumbersDoNotMatchText').text;
                    this.changePaymentMethodText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'loggedInPayment', 'paymentChangePaymentMethodText').text;
                    this.expirationErrorText = this.componentService.contentService.tryGetContentItem(content, 'error', 'loggedInPayment', 'paymentExpirationErrorText').text;
                    this.expirationRequiredText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'loggedInPayment', 'paymentDropdownExpirationRequiredText').text;
                    this.accountNumberError = this.componentService.contentService.tryGetContentItem(content, 'error', 'loggedInPayment', 'paymentAccountNumberErrorText').text;
                    this.routingNumberError = this.componentService.contentService.tryGetContentItem(content, 'error', 'loggedInPayment', 'paymentRoutingNumberErrorText').text;
                    this.routingNumberText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'loggedInPayment', 'paymentRoutingNumberText').text;
                    this.accountTypeText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'loggedInPayment', 'paymentAccountTypeText').text;
                    this.accountChoicesText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'loggedInPayment', 'paymentAccountChoicesText').text;
                    this.maxCCPaymentErrorTextContentItem = this.componentService.contentService.tryGetContentItem(content, 'error', 'loggedInPayment', 'paymentMaxCreditCardAmountWarningText').text;
                }
                this.cvvTooltip = this.componentService.contentService.tryGetContentItem(
                    content, 'payment', 'tooltip', 'paymentCVVTooltip').text;
                this.routingNumberTooltip = this.componentService.contentService.tryGetContentItem(content, 'payment', 'tooltip', 'paymentRoutingNumberTooltip').text;
            });

        // initialize dropdown arrays
        for (let i = 1; i <= 12; i++) {
            if (i < 10) {
                this.months[i - 1] = '0' + i;
            } else {
                this.months[i - 1] = ' ' + i;
            }
        }

        for (let i = 0; i < 20; i++) {
            this.years[i] = (new Date().getFullYear() + i).toString();
        }

        const mode = this.componentService.storageService.retrieve('mode');
        if (!this.isOneTimePayment || (mode == 'fasttrack')) {
            // PaymentComponent sets the merchantProfile based on payable docs/items,
            // but a logged-in payment will use the domain's merchantProfile:
            this.consumerService.getMerchantProfileForDomain().then(merchant => {
                this.merchantProfile = merchant;
                this.updateMerchant();
            });
        }

        this.walletIsEmpty();

        this.accountType = !!this.accountChoicesText ? this.accountChoicesText.split(';')[0] : '';

        this.paymentAmountValue.subscribe(() => {
            this.updateMerchant();
        });
    }

    /**
     * This is called after an account number is entered to see if the confirmation account number matches
     *
     * @returns {boolean}
     *
     * @memberof PaymentMethodDisplayComponent
     */
    public hasAccountNumbersMatchError(): boolean {
        if (this.accountNumber != this.accountNumberConfirmation) {
            this.accountNumbersDoNotMatchErrorExists = true;
        } else {
            this.accountNumbersDoNotMatchErrorExists = false;
        }
        return this.accountNumbersDoNotMatchErrorExists;
    }

    walletIsEmpty(): void {
        if (this.componentService.userIsLoggedIn() && (!this.isOneTimePayment || this.quickPayWalletEnabled) && this.walletEnabled) {
            this.walletNotEmpty = this.paymentMethods && this.paymentMethods.some((pm) => pm.tokenGUID != 'newPaymentMethod');
        }
    }

    private setAllowACH(): void {
        if (this.isAccountPayment) {
            this.allowACH = this.merchantProfile.allowACH && this.merchantProfile.allowACHAccountPayments;
        } else if (this.checkCredentialMismatch) {
            this.allowACH = (this.merchantProfile.allowACH && !this.merchantCredentialMismatch);
        } else {
            this.allowACH = this.merchantProfile.allowACH;
        }

    }

    public updateMerchant() {
        if (this.merchantProfile != null) {
            if (!!this.maxCCPaymentErrorTextContentItem) {
                this.maxCCPaymentErrorText = this.maxCCPaymentErrorTextContentItem.replace(
                    '{0}', this.currencyPipe.transform(this.merchantProfile.maxCCPaymentAmount, 'USD', 'symbol', '1.2-2'));
            } else {
                this.maxCCPaymentErrorText = '';
            }

            this.setAllowACH();

            // Do not allow credit card payments when the selected amount is more than the maxCCPaymentAmount
            this.allowCredit = this.merchantProfile.allowCredit && (this.paymentAmount <= this.merchantProfile.maxCCPaymentAmount);

            if (this.allowACH) {
                this.bank();
            }
            if (this.allowCredit /* default choice */) {
                this.credit();
            }
        }
        this.walletIsEmpty();
        this.merchantProfileLoading = false;
    }

    getCreditCard(creditCardIssuer: CreditCardIssuerType): PaymentMethod {
        const paymentMethod: PaymentMethod = new PaymentMethod();
        paymentMethod.paymentMethodType = PaymentMethodType.credit;

        paymentMethod.cardType = creditCardIssuer;
        return paymentMethod;
    }

    public getLogoByCard(cardNumber: string): CreditCardIssuerType {
        const accountNumber: string = cardNumber;
        let creditCardIssuer: CreditCardIssuerType = null;

        if (accountNumber.toString().length >= 4) {
            const visaRegex = new RegExp('^4[0-9]{6,}$');
            const mastercardRegex = new RegExp('^5[1-5][0-9]{5,}|222[1-9][0-9]{3,}|22[3-9][0-9]{4,}|2[3-6][0-9]{5,}|27[01][0-9]{4,}|2720[0-9]{3,}$');
            const americanexpressRegex = new RegExp('^3[47][0-9]{5,}$');
            const dinersClubRegex = new RegExp('^3(?:0[0-5]|[68][0-9])[0-9]{4,}$');
            const discoverRegex = new RegExp('^6(?:011|5[0-9]{2})[0-9]{3,}$');
            const jcbRegex = new RegExp('^(?:2131|1800|35[0-9]{3})[0-9]{3,}$');

            if (visaRegex.test(accountNumber.toString())) {
                creditCardIssuer = CreditCardIssuerType.visa;
            } else if (americanexpressRegex.test(accountNumber.toString())) {
                creditCardIssuer = CreditCardIssuerType.americanexpress;
            } else if (dinersClubRegex.test(accountNumber.toString())) {
                creditCardIssuer = CreditCardIssuerType.dinersclub;
            } else if (discoverRegex.test(accountNumber.toString())) {
                creditCardIssuer = CreditCardIssuerType.discover;
            } else if (jcbRegex.test(accountNumber.toString())) {
                creditCardIssuer = CreditCardIssuerType.jcb;
            } else if (mastercardRegex.test(accountNumber.toString())) {
                creditCardIssuer = CreditCardIssuerType.mastercard;
            }
        }
        return creditCardIssuer;
    }

    /**
     * This is the change event for credit card, and also determines if a credit card error exists
     *
     * @returns {boolean}
     *
     * @memberof PaymentMethodDisplayComponent
     */
    public hasCardNumberError(): boolean {
        let cardNumber = '';
        if (this.creditCardDisabled) {
            return false;
        }

        if (this.creditCardNumber.includes('•') || this.creditCardNumber.includes('-')) {
            cardNumber = this.creditCardNumber.split('-').join('').split('•').join('');
        }
        if (cardNumber.length < 15 && cardNumber.length != 4) {
            this.creditCardErrorExists = true;
        } else {
            this.creditCardErrorExists = false;
            if (cardNumber.length != 4) {
                this.stepValidationError = null;
                this.creditCardIssuerErrorExists = false;
                const creditCardType = this.getLogoByCard(cardNumber);

                if (!this.merchantProfile.allowVisa && creditCardType == CreditCardIssuerType.visa) {
                    this.creditCardIssuerErrorExists = true;
                    this.stepValidationError = this.creditCardIssuerError;
                } else if (!this.merchantProfile.allowMC && creditCardType == CreditCardIssuerType.mastercard) {
                    this.creditCardIssuerErrorExists = true;
                    this.stepValidationError = this.creditCardIssuerError;
                } else if (!this.merchantProfile.allowDiscover && creditCardType == CreditCardIssuerType.discover) {
                    this.creditCardIssuerErrorExists = true;
                    this.stepValidationError = this.creditCardIssuerError;
                } else if (!this.merchantProfile.allowAmex && creditCardType == CreditCardIssuerType.americanexpress) {
                    this.creditCardIssuerErrorExists = true;
                    this.stepValidationError = this.creditCardIssuerError;
                } else if (creditCardType == CreditCardIssuerType.dinersclub || creditCardType == CreditCardIssuerType.jcb) {
                    this.creditCardIssuerErrorExists = true;
                    this.stepValidationError = this.creditCardIssuerError;
                }
            } else {
                // 4 digits, let's make sure we masked it
                if (!this.creditCardNumber.includes('•')) {
                    this.creditCardErrorExists = true;
                }
            }
        }
        return this.creditCardErrorExists || this.creditCardIssuerErrorExists;
    }

    /**
     * This is the keyup for credit card
     *
     * @returns {boolean}
     *
     * @memberof PaymentMethodDisplayComponent
     */
    determineCardandLogo(event: any): void {
        if (this.creditCardNumber.includes('•')) {
            this.creditCardNumber = '';
            this.mask = this.maskOther;
        }
        const card: CreditCardIssuerType = this.getLogoByCard(this.creditCardNumber.split('-').join(''));
        if (card != null) {
            this.visaActive = this.cardClassIfActive(false);
            this.amExActive = this.cardClassIfActive(false);
            this.mcActive = this.cardClassIfActive(false);
            this.discoverActive = this.cardClassIfActive(false);

            if (card == CreditCardIssuerType.visa) {
                this.visaActive = this.cardClassIfActive(true);
                this.mask = this.maskOther;
            } else if (card == CreditCardIssuerType.mastercard) {
                this.mcActive = this.cardClassIfActive(true);
                this.mask = this.maskOther;
            } else if (card == CreditCardIssuerType.americanexpress) {
                this.amExActive = this.cardClassIfActive(true);
                this.mask = this.maskAmex;
            } else if (card == CreditCardIssuerType.discover) {
                this.discoverActive = this.cardClassIfActive(true);
                this.mask = this.maskOther;
            }
        }
    }

    public cardClassIfActive(active: boolean): string {
        if (active) {
            return 'small-input-logo col-xs-1';
        } else {
            return 'small-input-logo-hidden col-xs-1';
        }
    }

    /**
     * This is the change event for ACH Account Number, and also determines if a Account Number error exists
     *
     * @returns {boolean}
     *
     * @memberof PaymentMethodDisplayComponent
     */
    public hasAchAccountNumberError(): boolean {
        if (this.accountNumber && this.accountNumberConfirmation) {
            this.hasAccountNumbersMatchError();
        }

        let account = '';
        if (this.accountNumber && (this.accountNumber.includes('•') || this.accountNumber.includes('-'))) {
            account = this.accountNumber.split('-').join('').split('•').join('');
        } else {
            account = this.accountNumber;
        }
        if (!account || account.length <= 0) {
            this.accountErrorExists = true;
        } else {
            this.accountErrorExists = false;
            if (account.length == 4) {
                // 4 digits, make sure it is masked
                if (!this.accountNumber.includes('•')) {
                    this.accountErrorExists = true;
                }
            }
        }

        return this.accountErrorExists;
    }

    /**
     * This is the change event for ACH Routing Number, and also determines if a Routing Number error exists
     *
     * @returns {boolean}
     *
     * @memberof PaymentMethodDisplayComponent
     */
    public hasAchRoutingNumberError(): boolean {
        this.routingErrorExists = this.routingNumber.length <= 0;
        return this.routingErrorExists;
    }

    /**
     * This is the change event for CVV, and also determines if a CVV error exists
     *
     * @returns {boolean}
     *
     * @memberof PaymentMethodDisplayComponent
     */
    public hasCvvError(): boolean {
        const requireCVV = this.merchantProfile != null ? this.merchantProfile.requireCVV : true;
        this.cvvErrorExists = this.cvvNumber.length < 3 && (this.cvvNumber.length > 0 || requireCVV);
        return this.cvvErrorExists;
    }

    hasMonthExpirationError(): boolean {
        if (this.expirationMonth == 'MM') {
            this.expirationErrorExists = false;
            this.expirationMonthRequiredErrorExists = true;
        } else {
            this.expirationMonthRequiredErrorExists = false;
            this.expirationErrorExists = this.expirationYear != 'YYYY'
                && this.expirationYear == new Date().getFullYear().toString()
                && Number(this.expirationMonth) <= new Date().getMonth();
        }
        return this.expirationErrorExists;
    }

    hasYearExpirationError(): boolean {
        if (this.expirationYear == 'YYYY') {
            this.expirationErrorExists = false;
            this.expirationYearRequiredErrorExists = true;
        } else {
            this.expirationYearRequiredErrorExists = false;
            this.expirationErrorExists = this.expirationMonth != 'MM'
                && this.expirationYear == new Date().getFullYear().toString()
                && Number(this.expirationMonth) <= new Date().getMonth();
        }
        return this.expirationErrorExists;
    }

    /**
     * This is the change event for Expiration Date dropdowns, and also determines if a Expiration Date error exists
     *
     * @returns {boolean}
     *
     * @memberof PaymentMethodDisplayComponent
     */
    public hasExpirationError(): boolean {
        this.hasMonthExpirationError();
        this.hasYearExpirationError();
        return this.expirationMonthRequiredErrorExists || this.expirationErrorExists;
    }

    /**
     * This makes the selected payment method the non-wallet "other" payment method
     *
     * @returns {void}
     *
     * @memberof PaymentMethodDisplayComponent
     */
    public selectOtherPaymentMethod(): void {
        if (this.paymentMethods && this.paymentMethods.length > 0) {
            const otherPaymentMethod = this.paymentMethods.find((x) => x.paymentMethodType == PaymentMethodType.unknown);
            if (otherPaymentMethod != null) {
                this.selectedPaymentMethod = otherPaymentMethod;
                this.paymentMethodChange.emit(this.selectedPaymentMethod);
            }
        }
    }

    /**
     * This makes the selected payment method the default payment method
     *
     * @returns {void}
     *
     * @memberof PaymentMethodDisplayComponent
     */
    public selectDefaultPaymentMethod(): void {
        if (this.paymentMethods && this.paymentMethods.length > 0 && !this.componentService.storageService.exists('agentAssistedPaymentEmailGUID')) {
            const primaryPaymentMethod = this.paymentMethods.find((x) => x.primary);
            if (primaryPaymentMethod != null) {
                this.selectedPaymentMethod = primaryPaymentMethod;
            } else {
                const otherPaymentMethod = this.paymentMethods.find((x) => x.paymentMethodType == PaymentMethodType.unknown);
                if (otherPaymentMethod != null) {
                    this.selectedPaymentMethod = otherPaymentMethod;
                    this.newPaymentMethodChange.emit(true);
                }
            }
            this.paymentMethodChange.emit(this.selectedPaymentMethod);
        }
    }

    clearErrorState(): void {
        this.creditCardErrorExists = false;
        this.accountErrorExists = false;
        this.routingErrorExists = false;
        this.cvvErrorExists = false;
        this.expirationErrorExists = false;
        this.expirationMonthRequiredErrorExists = false;
        this.expirationYearRequiredErrorExists = false;
        this.clearParentError.emit();
    }

    credit(): void {
        this.selectedOtherPaymentType = PaymentMethodType.credit;
        this.paymentMethodTypeChange.emit(PaymentMethodType.credit);
        this.creditOtherSelected = 'primary-white';
        this.bankOtherSelected = 'white-primary';
        this.clearErrorState();
    }

    bank(): void {
        this.selectedOtherPaymentType = PaymentMethodType.ach;
        this.paymentMethodTypeChange.emit(PaymentMethodType.ach);
        this.creditOtherSelected = 'white-primary';
        this.bankOtherSelected = 'primary-white';
        this.clearErrorState();
    }

    changePaymentMethod(paymentMethodTokenGUID: string): void {
        const paymentMethodFromWallet = this.paymentMethods.find((x) => x.tokenGUID == paymentMethodTokenGUID);
        if (paymentMethodFromWallet != null) {
            this.selectedPaymentMethod = paymentMethodFromWallet;
            this.creditCardMaxAmountErrorExists = false;
            if (this.selectedPaymentMethod.paymentMethodType == PaymentMethodType.unknown) {
                this.newPaymentMethod = true;
            } else {
                this.newPaymentMethod = false;
                this.checkMaxCCErrorCondition();
            }
            this.paymentMethodChange.emit(this.selectedPaymentMethod);
            this.newPaymentMethodChange.emit(this.newPaymentMethod);
        }
    }

    /**
     * Checks the max cc error condition based on the merchant profile settings and the payment method type.
     *
     * @memberof PaymentMethodDisplayComponent
     */
    checkMaxCCErrorCondition() {
        this.creditCardMaxAmountErrorExists = this.selectedPaymentMethod.paymentMethodType == PaymentMethodType.credit && !this.allowCredit;
    }

    enablePaymentMethodDropdown() {
        return !this.isOneTimePayment || (this.isOneTimePayment && this.quickPayWalletEnabled);
    }

    private maskNumbersOnly(rawValue: string = ''): Array<RegExp> {
        const digitRegExp: RegExp = /\d|•/;
        let returnExp: Array<RegExp> = [];
        for (let i = 0; i <= rawValue.length; i++) {
            returnExp = returnExp.concat(digitRegExp);
        }

        return returnExp;
    }

    private isLoading(): boolean {
        return this.merchantProfileLoading;
    }
}
