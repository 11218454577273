import { ACHPaymentMethod } from './achpaymentmethod';
import { Address } from './address';
import { CreditCard } from './creditcard';
import { DocumentPayments } from './documentpayments';
import { PaymentToken } from './paymenttoken';
import { RealtimePayment } from './realtimepayment';

export class ConsumerPayment {
    public AccountID: string;
    public AccountName: string;
    public Address: Address;
    public CreditCardPaymentMethod: CreditCard;
    public TokenPaymentMethod: PaymentToken;
    public ACHPaymentMethod: ACHPaymentMethod;
    public StorePaymentMethod: boolean;
    public PaymentMethodNickname: string;
    public DocumentsToPayOn: DocumentPayments[];
    public RealtimeBalanceToPayOn: RealtimePayment;
    public PaymentComment: string;
    public PhoneNumber: string;
    public PaymentLocationGUID: string;
    public PaymentSource: string;
    public ConfirmationEmail: string;
    public Language: string;
    public PaymentPlanGUID: string;
    public FastTrackID: string;
    public PayorType: string;
    public DetailAccountID: string;
    public smsGUID?: string;
    public ScheduledPaymentDate?: Date;
    public ConsumerPaymentID: number;
    public EasyViewUserID: number;
    public CaptchaToken: string;
    public CustomTextJSON: string;
    public ExternalPaymentID: string;
    public BINLookupGuid: string;
    public FeeAmount: number;
}
