import { Component, NgZone, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ICommunicationPreferenceChange } from '../../models/communicationPreferenceType';
import { IDomainInfo } from '../../models/domaininfo';
import { ComponentService } from '../../services/component/component.service';
import { ConsumerService } from '../../services/consumer/consumer.service';

@Component({
    selector: 'unsubscribeemail',
    template: require('./unsubscribeemail.component.html'),
    styles: [require('./unsubscribeemail.component.css')]
})
export class UnsubscribeEmailComponent implements  OnDestroy {
    consumerAccountGUID: string;

    // Content Items
    unsubscribeHeaderText: string;
    profileUnsubscribeSubheaderText: string;
    profileEmailText: string;
    profileStatementDeliveryText: string;
    profileUnsubscribeCancelButtonText: string;
    unsubscribeSaveButtonText: string;
    profileUpdatedWalletText: string;
    profileUnsubscribeErrorText: string;

    emailAddress: string;
    showUpdatedMessage: boolean;
    showErrorMessage: boolean;

    domainInfo: IDomainInfo;

    private ngUnsubscribe: Subject<any> = new Subject();

    constructor(
        private parentRouter: Router,
        private componentService: ComponentService,
        private consumerService: ConsumerService,
        private ngZone: NgZone
    ) { }

    async ngOnInit() {
        this.componentService.contentService.content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                content => {
                    this.unsubscribeHeaderText = this.tryGetContentItem(content, 'pageText', 'profileUnsubscribeHeaderText');
                    this.profileUnsubscribeSubheaderText = this.tryGetContentItem(content, 'pageText', 'profileUnsubscribeSubheaderText');
                    this.profileEmailText = this.tryGetContentItem(content, 'pageText', 'profileEmailText');
                    this.profileStatementDeliveryText = this.tryGetContentItem(content, 'pageText', 'profileStatementDeliveryText');
                    this.unsubscribeSaveButtonText = this.tryGetContentItem(content, 'button', 'profileUnsubscribeSaveButtonText');

                    this.profileUnsubscribeCancelButtonText = this.tryGetContentItem(
                        content, 'button', 'profileUnsubscribeCancelButtonText'
                    );

                    this.profileUpdatedWalletText = this.tryGetContentItem(content, 'wallet', 'profileUpdatedWalletText');
                    this.profileUnsubscribeErrorText = this.tryGetContentItem(content, 'error', 'profileUnsubscribeErrorText');
                });

        this.componentService.domainService.domainInfo$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(domainInfo => {
                this.domainInfo = domainInfo;
            });

        // We will only have the ConsumerAccountGUID here
        this.consumerAccountGUID = this.componentService.storageService.retrieve('consumerAccountGUID');

        // The email is shimmed from tblCustomerGuarantors for UCC = 1
        this.emailAddress = await this.consumerService.callGetEmailFromConsumerAccountGUID(this.consumerAccountGUID);
    }

    tryGetContentItem(content: any, subCategory: string, name: string): string {
        return this.componentService.contentService.tryGetContentItem(
            content,
            'profile',
            subCategory,
            name
        ).text;
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    public async unsubscribeEmail() {
        // Consumer is not taken anywhere upon save, so lets
        // make sure they can't keep clicking the button
        if (!this.showUpdatedMessage) {
            if (this.domainInfo.useConsolidatedCommunication) {
                this.showUpdatedMessage = await this.updateCommunicationPreference([
                    {
                        type: 'StatementEmail',
                        newValue: false,
                        customerAccountID: ''
                    },
                    {
                        type: 'StatementPaper',
                        newValue: true,
                        customerAccountID: ''
                    }
                ]);
            } else {
                this.showUpdatedMessage = await this.consumerService.callUpdateConsumerAccountForUnsubscribeEmail(
                    this.consumerAccountGUID
                );
            }

            this.showErrorMessage = !this.showUpdatedMessage;
        }
    }

    private async updateCommunicationPreference(changes: ICommunicationPreferenceChange[]): Promise<boolean> {
        return await this.consumerService.callUpdateCommunicationPreferenceForUnsubscribeEmail(
            this.consumerAccountGUID,
            changes
        );
    }

    public cancel(): void {
        this.ngZone.run(() => this.parentRouter.navigateByUrl('/')).then();
    }
}
