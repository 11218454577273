import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ComponentService } from '../../services/component/component.service';

@Component({
    selector: 'quickpaydeliveryprofile',
    template: require('./quickpaydeliveryprofile.component.html'),
    styles: [require('./quickpaydeliveryprofile.component.css')]
})
export class QuickPayDeliveryProfileComponent implements OnInit, OnDestroy {

    header: string;

    private ngUnsubscribe: Subject<any> = new Subject();

    constructor(private componentService: ComponentService, private parentRouter: Router) {
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit() {
        this.componentService.scrollPageToTop();
        this.componentService.contentService.content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((content: any) => {
                this.header = this.componentService.contentService.tryGetContentItem(content, 'profile', 'pageText', 'profileManageDeliveryProfileHeader').text;
            });
    }
}
