/* Defines the DomainContentItem entity */
export class DomainContentItem {
    category: string;
    subCategory: string;
    languageCode: string;
    name: string;
    text: string;
    text2: string;
    title: string;
    sequence: number;
    parentID: number;

    constructor(
        text?: string,
        category?: string,
        subCategory?: string,
        language?: string,
        name?: string,
        text2?: string,
        title?: string,
        sequence?: number,
        parentId?: number,
    ) {
        this.category = category;
        this.subCategory = subCategory;
        this.languageCode = language;
        this.name = name;
        this.text = text;
        this.text2 = text2;
        this.title = title;
        this.sequence = sequence;
        this.parentID = parentId;
    }
}

export let TEST_CONTENTITEMS = {
    profile: {
        pageText: {
            profileDeliveryMethodChoices: {
                languageCode: 'en-us',
                category: 'profile',
                subCategory: 'pageText',
                name: 'Profile - Delivery Method Choices',
                text: 'Electronic:Electronic;Paper:Paper;Both:Electronic & Paper',
                title: '',
                text2: ''
            }
        }
    },
    paymentplan: {
        modal: {
            paymentPlanAddPaymentMethodModalContent: {
                text: 'payment plan method modal content',
            },
        },
    },
    autopay: {
        featureText: {
            text: 'I don\'t know'
        }
    },
    payment: {
        oneTimePayment: {
            paymentStep1Header: {
                text: '!NAME! - step1H'
            }
        }
    },
};
