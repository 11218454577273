/* tslint:disable:triple-equals */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from '../../app.config';
import { AccountBalanceTransactionType, IAccountBalanceHistory } from '../../models/accountbalancehistory';
import { IConciergePresets } from '../../models/conciergepresets';
import { ICustomPlanPresets } from '../../models/customplanpresets';
import { GenericResponse, Response } from '../../models/genericresponse';
import { CreditCardIssuerType, PaymentMethodType } from '../../models/paymentmethod';
import { PaymentMethodAssignmentSource } from '../../models/paymentmethodassignmentsource';
import { BalanceMethod, IPaymentPlanBalance } from '../../models/paymentplanbalance';
import { IPaymentPlanDetails } from '../../models/paymentplandetails';
import { IPaymentPlanInitializationModel } from '../../models/paymentplaninitializationmodel';
import { IPaymentPlanPresetMinMax } from '../../models/paymentplanpresetminmax';
import { IPaymentPlanSubmit } from '../../models/paymentplansubmit';
import { IPaymentPlanTermsAndConditions } from '../../models/paymentplantermsandconditions';
import { jsonRequestOptions } from '../../util/HttpClientUtility';
import { ComponentService } from '../component/component.service';
import { LoggingService } from '../logging/logging.service';

@Injectable()
export class PaymentPlanService {
    private token = '';
    private domain = '';
    private domainUrl: string = this.config.getConfig('domainInfo');
    private balanceDetailsPath: string = this.config.getConfig('paymentPlanBalanceDetailsPath');
    private transactionHistoryPath: string = this.config.getConfig('paymentPlanTransactionHistoryPath');
    private paymentPlanPath: string = this.config.getConfig('paymentPlanPath');
    private paymentPlanConciergePath: string = this.config.getConfig('paymentPlanConciergePath');
    private paymentPlanCustomPresetsPath: string = this.config.getConfig('paymentPlanCustomPresetsPath');
    private paymentPlanSetPaymentMethodPath: string = this.config.getConfig('paymentPlanSetPaymentMethodPath');
    private paymentPlanRemovePaymentMethodPath: string = this.config.getConfig('paymentPlanRemovePaymentMethodPath');
    private paymentPlanCreateUpdatePath: string = this.config.getConfig('paymentPlanCreateUpdatePath');
    private paymentPlanPresetsRangePath: string = this.config.getConfig('paymentPlanPresetsRangePath');
    private paymentPlanInitializationDataPath: string = this.config.getConfig('paymentPlanInitializationDataPath');
    private queuePaymentPlanTermsAndConditionsEmailPath: string = this.config.getConfig('queuePaymentPlanTermsAndConditionsEmailPath');
    private getPaymentPlanDetailsTermsPath: string = this.config.getConfig('getPaymentPlanDetailsTermsPath');

    constructor(
        private http: HttpClient,
        private config: AppConfig,
        private componentService: ComponentService,
        private loggingService: LoggingService
    ) {
        this.domain = this.componentService.storageService.getDomain();
    }

    /**
     * Used to Get PaymentPlanDetails for the customer account
     *
     * @param {string} customerAccountId
     * @returns {Promise<IPaymentPlanDetails[]>}
     * @memberof PaymentPlanService
     */
    public async getPaymentPlanDetailsForAccount(customerAccountId: string): Promise<IPaymentPlanDetails[]> {
        let data = await this.callGetPaymentPlanDetailsForAccountApi(customerAccountId);
        data = data.filter(plan => plan.status == 'Active');
        data.forEach((paymentPlan) => {
            if (paymentPlan.consumerPaymentMethod) {
                paymentPlan.consumerPaymentMethod.paymentMethodType =
                    PaymentMethodType[paymentPlan.consumerPaymentMethod.paymentMethodType.toString().toLowerCase()];
                paymentPlan.consumerPaymentMethod.cardType =
                    CreditCardIssuerType[paymentPlan.consumerPaymentMethod.cardType.toString().toLowerCase()];
            }
        });
        return data;
    }

    /**
     * Gets the balance details for the given Customer Account Id
     *
     * @param {string} customerAccountId
     * @returns {Promise<IPaymentPlanBalance[]>}
     * @memberof PaymentPlanService
     */
    public async getBalanceDetails(customerAccountId: string, includeZeroBalances: boolean = false): Promise<IPaymentPlanBalance[]> {
        const data = await this.callGetBalanceDetailsApi(customerAccountId, includeZeroBalances);
        this.bindPaymentPlanBalances(data);
        return data;
    }

    /**
     * Gets the account balance transaction history for the given CustomerAccountId and AccountNumber
     *
     * @param {string} customerAccountId
     * @param {string} accountNumber
     * @returns {Promise<IAccountBalanceHistory[]>}
     * @memberof PaymentPlanService
     */
    public async getTransactionHistory(customerAccountId: string, accountNumber: string): Promise<IAccountBalanceHistory[]> {
        const data = await this.callGetTransactionHistoryApi(customerAccountId, accountNumber);
        this.bindAccountBalanceHistory(data);
        return data;
    }

    /**
     * Get the concierge presets for the given balance
     *
     * @param {string} customerAccountId
     * @param {number} balance to get the concierge presets for
     * @returns {Promise<IConciergePresets[]>}
     * @memberof PaymentPlanService
     */
    public async getConciergePlanPresets(
        customerAccountId: string, balance: number, merchantProfileGUID: string): Promise<IConciergePresets[]> {
        return this.callGetConciergePlanPresetsApi(this.domain, customerAccountId, balance, merchantProfileGUID);
    }

    /**
     * return the presets for the custom plan settings
     *
     * @param {string} customerAccountId
     * @param {number} balance  to get the presets for
     * @returns {Promise<ICustomPlanPresets>}
     * @memberof PaymentPlanService
     */
    public async getCustomPlanPresets(
        customerAccountId: string, balance: number, merchantProfileGUID: string): Promise<ICustomPlanPresets> {
        return this.callGetCustomPlanPresetsApi(this.domain, customerAccountId, balance, merchantProfileGUID);
    }

    /**
     * Sets the payment plan payment method for the payment plan
     *
     * @param {IPaymentPlanDetails} paymentPlan
     * @returns {Promise<boolean>}
     * @memberof PaymentPlanService
     */
    public async setPaymentMethodForPlan(
        customerAccountID: string,
        consumerPaymentMethodTokenGUID: string,
        paymentPlanGUID: string): Promise<boolean> {
        return this.callSetPaymentMethodForPlanApi(customerAccountID, consumerPaymentMethodTokenGUID, paymentPlanGUID);
    }

    /**
     * Send an email with the terms and conditions of the playment plan
     *
     * @param emailAddress
     * @param source MySecureBill
     * @param isNewPaymentPlan
     * @returns {Promise<any>}
     */
    public async queuePaymentPlanTermsAndConditionsEmail(
            emailAddress: string,
            source: string,
            isNewPaymentPlan: boolean,
            customerAccountID: string,
            paymentPlanGUID: string
        ): Promise<any> {
        return this.callQueuePaymentPlanTermsAndConditionsEmailApi(
            emailAddress,
            source,
            isNewPaymentPlan,
            customerAccountID,
            paymentPlanGUID
        );
    }

    /**
     * Gets the terms of a payment plan
     *
     * @param {string} customerAccountId
     * @param {string} paymentPlanGUID
     * @returns {Promise<IPaymentPlanTermsAndConditions>}
     * @memberOf PaymentPlanService
     */
    public async getPaymentPlanDetailsTerms(
            customerAccountId: string,
            paymentPlanGUID: string
        ): Promise<IPaymentPlanTermsAndConditions> {
        return this.callGetPaymentPlanDetailsTerms(customerAccountId, paymentPlanGUID);
    }

    /**
     * Removes the payment method from payment plan
     *
     * @param {IPaymentPlanDetails} paymentPlan
     * @returns {Promise<boolean>}
     * @memberOf PaymentPlanService
     */
    public async removePaymentMethodForPlan(paymentPlan: IPaymentPlanDetails): Promise<boolean> {
        return this.callRemovePaymentMethodForPlanApi(paymentPlan);
    }

    /**
     * Will submit data to the API to save/update a PaymentPlan
     * @param plan
     * @returns {Promise<boolean>}
     * @memberof PaymentPlanService
     */
    public async submitPaymentPlan(plan: IPaymentPlanSubmit): Promise<boolean> {
        return this.callSubmitPaymentPlan(plan);
    }

    /**
     * Gets the custom/concierge preset range for a given account's domain
     *
     * @param {string} customerAccountId
     * @returns {Promise<IPaymentPlanPresetMinMax>}
     * @memberof PaymentPlanService
     */
    public async getPaymentPlansPresetsRange(customerAccountId: string, merchantProfileGUID: string): Promise<IPaymentPlanPresetMinMax> {
        return this.callGetPaymentPlansPresetsRangeApi(this.domain, customerAccountId, merchantProfileGUID);
    }

    /**
     * Gets the initialization data for payment plan setup.
     *
     * @param {string} customerAccountID
     * @returns {Promise<IPaymentPlanInitializationModel>}
     * @memberof PaymentPlanService
     */
    public async getPaymentPlanInitializationData(customerAccountID: string): Promise<IPaymentPlanInitializationModel> {
        return this.callGetPaymentPlanInitializationDataApi(customerAccountID);
    }

    private async callGetConciergePlanPresetsApi(
        domain: string, customerAccountID: string, balance: number, merchantProfileGUID: string): Promise<IConciergePresets[] > {
        // need to reset the local token property when calling the API, to avoid login/logout/expiring issues
        this.token = this.componentService.storageService.retrieve('token');
        const body = {
            UrlAccessKey: this.token,
            bypass: true,
            path: this.paymentPlanConciergePath,
            customerAccountID,
            balance,
            merchantProfileGUID
        };
        return this.http.post(this.domainUrl, { body }, jsonRequestOptions())
            .toPromise()
            .then((response: Response<IConciergePresets>) => response.body.data)
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    private async callGetCustomPlanPresetsApi(
        domain: string, customerAccountID: string, balance: number, merchantProfileGUID: string): Promise<ICustomPlanPresets > {
        // need to reset the local token property when calling the API, to avoid login/logout/expiring issues
        this.token = this.componentService.storageService.retrieve('token');
        const body = {
            UrlAccessKey: this.token,
            bypass: true,
            path: this.paymentPlanCustomPresetsPath,
            customerAccountID,
            balance,
            merchantProfileGUID
        };
        return this.http.post(this.domainUrl, { body }, jsonRequestOptions())
            .toPromise()
            .then((response: Response<ICustomPlanPresets>) => response.body.data)
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    private async callGetBalanceDetailsApi(customerAccountID: string, includeZeroBalances = false): Promise<IPaymentPlanBalance[] > {
        this.token = this.componentService.storageService.retrieve('token');
        if (this.token == null) {
            return null;
        }

        const body = {
            UrlAccessKey: this.token,
            bypass: true,
            path: this.balanceDetailsPath,
            includeZeroBalances,
            customerAccountID
        };

        return this.http.post(this.domainUrl, { body }, jsonRequestOptions())
            .toPromise()
            .then((response: Response<boolean>) => response.body.data)
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    private async callGetTransactionHistoryApi(customerAccountID: string, accountNumber: string): Promise<IAccountBalanceHistory[]> {
        this.token = this.componentService.storageService.retrieve('token');
        if (this.token == null) {
            return null;
        }

        const body = {
            UrlAccessKey: this.token,
            bypass: true,
            path: this.transactionHistoryPath,
            customerAccountID,
            accountNumber
        };

        return this.http.post(this.domainUrl, { body }, jsonRequestOptions())
            .toPromise()
            .then((response: Response<boolean>) => response.body.data)
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    private async callGetPaymentPlanDetailsForAccountApi(customerAccountID: string): Promise<IPaymentPlanDetails[]> {
        this.token = this.componentService.storageService.retrieve('token');
        if (this.token == null) {
            return null;
        }

        const body = {
            UrlAccessKey: this.token,
            bypass: true,
            path: this.paymentPlanPath,
            customerAccountID
        };

        return this.http.post(this.domainUrl, { body }, jsonRequestOptions())
            .toPromise()
            .then((response: Response<IPaymentPlanDetails[]>) => response.body.data)
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    private async callSetPaymentMethodForPlanApi(
        customerAccountID: string,
        consumerPaymentMethodTokenGUID: string,
        paymentPlanGUID: string): Promise<boolean> {
        this.token = this.componentService.storageService.retrieve('token');

        const body = {
            UrlAccessKey: this.token,
            CustomerAccountID: customerAccountID,
            PaymentMethodGUID: consumerPaymentMethodTokenGUID,
            PaymentPlanGUID: paymentPlanGUID,
            PaymentMethodAssignmentSource: PaymentMethodAssignmentSource.SelfService,
            path: this.paymentPlanSetPaymentMethodPath,
            bypass: true
        };

        return this.http.post(this.domainUrl, { body }, jsonRequestOptions())
            .toPromise()
            .then((response: Response<boolean>) => response.body.data)
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    private async callRemovePaymentMethodForPlanApi(paymentPlan: IPaymentPlanDetails): Promise<boolean> {
        this.token = this.componentService.storageService.retrieve('token');

        const body = {
            UrlAccessKey: this.token,
            CustomerAccountID: paymentPlan.customerAccountID,
            PaymentPlanGUID: paymentPlan.paymentPlanGUID,
            path: this.paymentPlanRemovePaymentMethodPath,
            bypass: true
        };

        return this.http.post(this.domainUrl, { body }, jsonRequestOptions())
            .toPromise()
            .then((response: Response<boolean>) => response.body.data)
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    private async callSubmitPaymentPlan(plan: IPaymentPlanSubmit): Promise<boolean> {
        this.token = this.componentService.storageService.retrieve('token');

        const body: any = {
            UrlAccessKey: this.token,
            CustomerAccountID: plan.CustomerAccountID,
            MerchantProfileGUID: plan.MerchantProfileGUID,
            Nickname: plan.PaymentMethodNickname,
            path: this.paymentPlanCreateUpdatePath,
            bypass: true,
            ConsumerPaymentMethodGUID: plan.consumerPaymentMethodGUID,
            FirstDate: new Date(Date.UTC(plan.FirstDate.getFullYear(), plan.FirstDate.getMonth(), plan.FirstDate.getDate())),
            LastDate: new Date(Date.UTC(plan.LastDate.getFullYear(), plan.LastDate.getMonth(), plan.LastDate.getDate())),
            InitialPayment: plan.InitialPayment,
            InitialPaymentAmount: plan.InitialPaymentAmount,
            PaymentAmount: plan.PaymentAmount,
            Balance: plan.Balance,
            ExpectedPaymentAmount: plan.ExpectedPaymentAmount,
            ExpectedPaymentDate: new Date(
                Date.UTC(plan.ExpectedPaymentDate.getFullYear(), plan.ExpectedPaymentDate.getMonth(), plan.ExpectedPaymentDate.getDate())),
            PaymentPlanMode: plan.PaymentPlanMode,
            CustomerEmailAddress: plan.CustomerEmailAddress,
            PaymentMethodAssignmentSource: plan.PaymentMethodAssignmentSource,
            GuarantorAccountNumbers: plan.GuarantorAccountNumbers,
            PaymentPlanID: plan.PaymentPlanID,
            Status: 'Active',
            Source: 'MSB Ultra',
            IsAgentAssisted: this.componentService.storageService.exists('agentAssistedPaymentEmailGUID')
        };

        // tslint:disable-next-line: forin
        for (const i in plan.CreditCardPaymentMethod) {
            if (!body.CreditCardPaymentMethod) {
                body.CreditCardPaymentMethod = {};
            }
            body.CreditCardPaymentMethod[i] = plan.CreditCardPaymentMethod[i];
        }
        // tslint:disable-next-line: forin
        for (const i in plan.ACHPaymentMethod) {
            if (!body.ACHPaymentMethod) {
                body.ACHPaymentMethod = {};
            }
            body.ACHPaymentMethod[i] = plan.ACHPaymentMethod[i];
        }
        // tslint:disable-next-line: forin
        for (const i in plan.Address) {
            if (!body.Address) {
                body.Address = {};
            }
            body.Address[i] = plan.Address[i];
        }

        return this.http.post(this.domainUrl, { body }, jsonRequestOptions())
            .toPromise()
            .then((response: Response<boolean>) => response.body.data)
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    private async callGetPaymentPlansPresetsRangeApi(
        domain: string, customerAccountID: string, merchantProfileGUID: string): Promise<IPaymentPlanPresetMinMax > {
        this.token = this.componentService.storageService.retrieve('token');
        const body = {
            UrlAccessKey: this.token,
            bypass: true,
            path: this.paymentPlanPresetsRangePath,
            customerAccountID,
            domain,
            merchantProfileGUID
        };
        return this.http.post(this.domainUrl, { body }, jsonRequestOptions())
            .toPromise()
            .then((response: Response<IPaymentPlanPresetMinMax>) => response.body.data)
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    private async callGetPaymentPlanInitializationDataApi(customerAccountID: string): Promise<IPaymentPlanInitializationModel> {
        this.token = this.componentService.storageService.retrieve('token');
        const body = {
            UrlAccessKey: this.token,
            bypass: true,
            path: this.paymentPlanInitializationDataPath,
            customerAccountID
        };
        return this.http.post(this.domainUrl, { body }, jsonRequestOptions())
            .toPromise()
            .then((response: Response<IPaymentPlanInitializationModel>) => response.status != 204 ? response.body.data : null)
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    private async callQueuePaymentPlanTermsAndConditionsEmailApi(
            emailAddress: string,
            source: string,
            isNewPaymentPlan: boolean,
            customerAccountID: string,
            paymentPlanGUID: string
        ) {
        this.token = this.componentService.storageService.retrieve('token');
        const body = {
            UrlAccessKey: this.token,
            EmailAddress: emailAddress,
            Source: source,
            IsNew: isNewPaymentPlan,
            path: this.queuePaymentPlanTermsAndConditionsEmailPath,
            bypass: true,
            CustomerAccountID: customerAccountID,
            PaymentPlanGUID: paymentPlanGUID
        };
        return this.http.post(this.domainUrl, { body }, {
            responseType: 'json',
            observe: 'response'
        })
            .toPromise()
            .then((response: Response<any>) => response.body.data)
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    private async callGetPaymentPlanDetailsTerms(
            customerAccountID: string,
            paymentPlanGUID: string
        ): Promise<IPaymentPlanTermsAndConditions> {

        this.token = this.componentService.storageService.retrieve('token');
        const body = {
            UrlAccessKey: this.token,
            bypass: true,
            path: this.getPaymentPlanDetailsTermsPath,
            customerAccountID,
            paymentPlanGUID
        };

        return this.http.post(this.domainUrl, { body }, jsonRequestOptions())
            .toPromise()
            .then((response: Response<IPaymentPlanTermsAndConditions>) => response.status != 204 ? response.body.data : null)
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }
    /**
     * Binds API PaymentPlanBalances to web PaymentPlanBalances
     * @private
     * @param {IPaymentPlanBalance[]} balances
     *
     * @memberof PaymentPlanService
     */
    private bindPaymentPlanBalances(balances: IPaymentPlanBalance[]) {
        balances.forEach((balance) => {
            balance.balanceMethod = BalanceMethod[balance.balanceMethod.toString().toLowerCase()];
        });
    }

    /**
     * Binds API AccountBalanceHistory to web AccountBalanceHistory
     * @private
     * @param {IAccountBalanceHistory[]} balance history
     *
     * @memberof PaymentPlanService
     */
    private bindAccountBalanceHistory(balanceHistory: IAccountBalanceHistory[]) {
        balanceHistory.forEach((history) => {
            history.transactionType = AccountBalanceTransactionType[history.transactionType.toString().toLowerCase()];
        });
    }

    private urlAppend(first: string, second: string): string {
        const lastChar = first.substr(-1); // Selects the last character
        const firstChar = second.substr(0, 1);
        if (lastChar != '/' && firstChar != '/') {         // If there isn't a slash here, add it
            first = first + '/';            // Append a slash to it.
        }
        return first + second;
    }
}
