import moment = require('moment');

export class DateUtils {
    static lastMonth = DateUtils.addMonths(new Date(), -1);
    static nextMonth = DateUtils.addMonths(new Date(), 1);
    static tenDaysOut = DateUtils.addDays(new Date(), 10);

    static addMonths(date: Date, numberOfMonths: number): Date {
        return moment(date).add(numberOfMonths, 'M').toDate();
    }

    static addDays(date: Date, numberOfDays: number): Date {
        return moment(date).add(numberOfDays, 'd').toDate();
    }
}
