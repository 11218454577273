import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FloatingBucket, PaymentBucket } from '../../../../models/paymentbucket';
import { ComponentService } from '../../../../services/component/component.service';
import { BasePaymentBucketComponent } from '../../../Base/BasePaymentBucket/basepaymentbucket.component';
import { CheckboxComponent } from '../../../Controls/Checkbox/checkbox.component';

@Component({
    selector: 'payment-bucket',
    template: require('./paymentbucket.component.html'),
    styles: [require('./paymentbucket.component.css')]
})
export class PaymentBucketComponent extends BasePaymentBucketComponent implements OnInit, OnDestroy {
    constructor(
        private componentService: ComponentService,
        datePipe: DatePipe) {
        super(datePipe);
    }

    private ngUnsubscribe: Subject<any> = new Subject();

    @Input() bucket: PaymentBucket;
    @Input() canEditBuckets = false;
    @Input() allowShortPayDetailOverpayments = false;
    @Input() detailOverpayments = false;

    bucketCheckboxName: string;
    hardDisabled = false;
    isDisabled = false;
    overpayDisableUI = false;
    bucketErrorsExist = false;

    @Output() bucketUpdateEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() payToBucketCheckedEvent: EventEmitter<string> = new EventEmitter<string>();
    @Output() emitOverPaymentEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

    nonPayableItemText: string;
    bucketPaymentAmountErrorMessage: string;
    bucketChargeDetailsDescription: string;

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit() {
        this.componentService.contentService.content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((content: any) => {
                this.nonPayableItemText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'oneTimePayment', 'paymentBucketNonPayableItemText').text;
                this.bucketPaymentAmountErrorMessage = this.componentService.contentService.tryGetContentItem(content, 'error', 'oneTimePayment', 'paymentBucketPaymentAmountErrorText').text;
            });

        this.bucketCheckboxName = 'payToBucket' + this.bucket.bucketId;

        // If there's an installment amount, don't reset the display balance
        if (this.bucket.bucketInstallmentAmount == null || this.bucket.bucketInstallmentAmount === 0) {
            this.bucket.displayBalance = this.bucket.bucketBalance;
        }

        if (this.bucket.bucketBalance <= 0 && this.bucket.bucketId !== FloatingBucket.bucketId) {
            this.hardDisabled = true;
            this.isDisabled = true;
        }
        this.bucket.minPaymentAmount = 0;
        this.bucket.maxPaymentAmount = 99999;

        this.bucketChargeDetailsDescription = this.computeBucketChargeDetailsDescription(this.bucket);
    }

    bucketPaymentAmountMax(): number {
        return this.bucket.maxPaymentAmount;
    }

    bucketPaymentAmountMin(): number {
        return this.bucket.minPaymentAmount;
    }

    bucketPaymentAmountRange(): any {
        return [this.bucketPaymentAmountMin(), this.bucketPaymentAmountMax()];
    }

    /**
     * Checks the bucket payment amount against the min/max range allowed by business rules
     * and returns 'true' if the bucket payment amount is invalid.
     *
     * @returns {boolean}
     *
     * @memberOf PaymentBucketComponent
     */
    public isInvalid(): boolean {
        if (this.bucket.bucketPaymentAmount == null) {
            return false;
        }

        const bucketCannotBeUnderPaid =
            this.componentService.bucketCannotBeUnderPaid(
                this.bucket.bucketPaymentAmount,
                this.bucket.minPaymentAmount,
                this.allowShortPayDetailOverpayments
            );

        const bucketCannotBeOverPaid =
            this.componentService.bucketCannotBeOverPaid(
                this.bucket.bucketPaymentAmount,
                this.bucket.maxPaymentAmount,
                this.detailOverpayments
            );

        return bucketCannotBeUnderPaid || bucketCannotBeOverPaid;
    }

    bucketPaymentAmountChange() {
        this.bucketUpdateEvent.emit(false);
        if (!!this.bucket.bucketPaymentAmount) {
            this.bucket.payToBucket = true;
            this.payToBucketCheckedEvent.emit(this.bucket.bucketId);
        } else {
            this.bucket.payToBucket = false;
            this.payToBucketCheckedEvent.emit(this.bucket.bucketId);
        }

        this.checkUnderAndOverPayment();
    }

    bucketPaymentAmountFocusOut() {
        this.bucketUpdateEvent.emit(true);
    }

    /**
     * Selects the whole number in the input field so the user can type a new number after checking pay and having the amount prefill.
     *
     * @param {*} $event
     *
     * @memberOf PaymentBucketComponent
     */
    selectAllInputContent(event: any) {
        if (this.bucket.payToBucket) {
            event.target.select();
        }
    }

    /**
     * Called by checkbox component when the checkbox is clicked
     *
     * @param {CheckboxComponent} checkboxTarget
     *
     * @memberof PaymentBucketComponent
     */
    checkboxChecked(checkboxTarget: CheckboxComponent) {
        const bucketClicked: string = checkboxTarget.value;

        if (this.bucket.payToBucket && (this.bucket.bucketPaymentAmount == null || this.bucket.bucketPaymentAmount <= 0)) {
            this.bucket.bucketPaymentAmount = this.bucket.displayBalance;
        }

        if (!this.bucket.payToBucket) {
            // Set this to 0.00 instead of null so that invalid amount shows when overpaying document but short paying detail
            this.bucket.bucketPaymentAmount = 0.00;
        }

        this.checkUnderAndOverPayment();
        this.payToBucketCheckedEvent.emit(bucketClicked);
    }

    private isFloatingBucket() {
        return this.bucket.bucketId === FloatingBucket.bucketId;
    }

    private showBucketRow() {
        if (this.bucket.bucketId === FloatingBucket.bucketId) {
            return this.bucket.bucketPaymentAmount > 0;
        }

        return true;
    }

    checkUnderAndOverPayment() {
        if (
            this.componentService.bucketCannotBeUnderPaid(
                this.bucket.bucketPaymentAmount,
                this.bucket.minPaymentAmount,
                this.allowShortPayDetailOverpayments) ||
            this.componentService.bucketCannotBeOverPaid(
                this.bucket.bucketPaymentAmount,
                this.bucket.maxPaymentAmount,
                this.detailOverpayments)
        ) {
            this.emitOverPaymentEvent.emit(false);
            this.bucketErrorsExist = true;
        }
        else {
            this.emitOverPaymentEvent.emit(true);
            this.bucketErrorsExist = false;
        }
    }
}
