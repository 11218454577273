import { Component } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ComponentService } from '../../services/component/component.service';

@Component({
    selector: 'new-quickpay-manage-delivery-profile-component',
    template: require('./new-quick-pay-manage-delivery-profile.component.html'),
    styles: [require('./new-quick-pay-manage-delivery-profile.component.css')]
})
export class NewQuickPayManageDeliveryProfileComponent {
    header: string;
    subheading: string;
    emailText: string;
    emailUpdateText: string;
    emailUpdateCancelText: string;
    emailUpdateSubmitText: string;
    statementDeliveryText: string;

    // semi-colon delimited list of options:text for Delivery Method (e.g. "Electronic:Electronic;Paper:Paper;Both:Electronic & Paper")
    deliveryChoices: string;

    deliveryMethodUpdateText: string;
    deliveryMethodUpdateSubmitText: string;
    deliveryMethodUpdateCancelText: string;
    emailErrorText: string;
    accountNotFoundText: string;

    private ngUnsubscribe: Subject<void> = new Subject();

    constructor(private componentService: ComponentService) {
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit(): void {
        this.componentService.contentService.content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((content: any) => {
                this.header = this.componentService.contentService.tryGetContentItem(content, 'profile', 'pageText', 'profileManageDeliveryProfileHeader').text;
                this.subheading = this.componentService.contentService.tryGetContentItem(
                    content, 'profile', 'pageText', 'profilePageSubheading').text;

                this.emailText = this.componentService.contentService.tryGetContentItem(
                    content, 'profile', 'pageText', 'profileEmailText').text;

                this.emailUpdateText = this.componentService.contentService.tryGetContentItem(content, 'profile', 'pageText', 'profileEmailUpdateText').text;
                this.emailUpdateSubmitText = this.componentService.contentService.tryGetContentItem(content, 'profile', 'pageText', 'profileEmailUpdateSubmitText').text;
                this.emailUpdateCancelText = this.componentService.contentService.tryGetContentItem(content, 'profile', 'pageText', 'profileEmailUpdateCancelText').text;
                this.statementDeliveryText = this.componentService.contentService.tryGetContentItem(content, 'profile', 'pageText', 'profileStatementDeliveryText').text;
                this.deliveryChoices = this.componentService.contentService.tryGetContentItem(content, 'profile', 'pageText', 'profileDeliveryMethodChoices').text;
                this.deliveryMethodUpdateText = this.componentService.contentService.tryGetContentItem(content, 'profile', 'pageText', 'profileDeliveryMethodUpdateText').text;
                this.deliveryMethodUpdateSubmitText = this.componentService.contentService.tryGetContentItem(content, 'profile', 'pageText', 'profileDeliveryMethodUpdateSubmitText').text;
                this.deliveryMethodUpdateCancelText = this.componentService.contentService.tryGetContentItem(content, 'profile', 'pageText', 'profileDeliveryMethodUpdateCancelText').text;
                this.emailErrorText = this.componentService.contentService.tryGetContentItem(content, 'profile', 'error', 'profileEmailUpdateErrorText').text;
                this.accountNotFoundText = this.componentService.contentService.tryGetContentItem(content, 'profile', 'error', 'profileAccountNotFoundErrorText').text;
            });
    }
}
