import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MSBButtonComponent } from './msbbutton.component';

@NgModule({
    imports: [CommonModule, RouterModule],
    declarations: [MSBButtonComponent],
    exports: [MSBButtonComponent]
})
export class MSBButtonModule {
}
