import Dexie from 'dexie';

// Pattern for Dexie library usage: https://github.com/dfahlander/Dexie.js
export class IndexedDbService extends Dexie {
    constructor() {
        super('ultraDb');
        this.version(1).stores({
            consumer: '++consumerID,accountID'
        });
    }
}
