import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ComponentService } from '../../services/component/component.service';
import { EpicSsoService } from '../../services/epic-sso/epic-sso.service';
import { AccountSelectionService } from './accountselection.service';

@Component({
    selector: 'accountselection',
    template: require('./accountselection.component.html'),
    styles: [ require('./accountselection.component.css') ]
})
export class AccountSelectionComponent implements OnInit, OnDestroy {
    public contentHeader: string;
    public contentAccountIdLabel: string;
    public contentAccountNameLabel: string;
    public contentAccountTypeTitleLabel: string;
    public contentRecentStatementLabel: string;
    public contentAccountSelectionLabel: string;
    public accounts: any[] = [];
    private ngUnsubscribe: Subject<any> = new Subject();

    constructor(
        private componentService: ComponentService,
        private router: Router,
        private accountSelectionService: AccountSelectionService,
        private epicSsoService: EpicSsoService,
        private ngZone: NgZone
    ) { }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    async ngOnInit() {
        this.componentService.scrollPageToTop();
        this.prepareContentItems();
        await this.loadAccounts();
    }

    prepareContentItems() {
        this.componentService.contentService.content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((content: any) => {
                this.contentHeader = this.componentService.contentService.tryGetContentItem(content, 'sso', 'pageText', 'ssoLoginAccountSelectHeader').text;
                this.contentAccountIdLabel = this.componentService.contentService.tryGetContentItem(content, 'sso', 'pageText', 'ssoLoginAccountIDlabel').text;
                this.contentAccountNameLabel = this.componentService.contentService.tryGetContentItem(content, 'sso', 'pageText', 'ssoLoginAccountnamelabel').text;
                this.contentAccountTypeTitleLabel = this.componentService.contentService.tryGetContentItem(content, 'sso', 'pageText', 'ssoLoginAccountTypeTitlelabel').text;
                this.contentRecentStatementLabel = this.componentService.contentService.tryGetContentItem(content, 'sso', 'pageText', 'ssoLoginRecentstatementlabel').text;
                this.contentAccountSelectionLabel = this.componentService.contentService.tryGetContentItem(content, 'sso', 'pageText', 'ssoLoginAccountselectionlabel').text;
            });
    }

    async loadAccounts() {
        this.accounts = await this.epicSsoService.ssoGetAccounts(this.accountSelectionService.etid);
    }

    async selectAccount(accountID: string) {
        // Call select account service.
        const success = await this.epicSsoService.ssoSelectAccount(this.accountSelectionService.etid, accountID);
        if (success) {
            // Reprocess SSO call.
            this.ngZone.run(
                () => this.router.navigate([ '/redirect' ], { queryParams: { etid: this.accountSelectionService.etid } })
            );
        }
    }
}
