import { NgModule, Optional, SkipSelf } from '@angular/core';
import { IndexedDbService } from './indexedDb.service';

// Pattern for Dexie library usage: https://github.com/dfahlander/Dexie.js
@NgModule({
    providers: [
        IndexedDbService
    ]
})
export class IndexedDbModule {
    constructor(@Optional() @SkipSelf() parentModule: IndexedDbModule) {
        if (parentModule) {
            throw new Error('IndexedDbModule already loaded. Import it in the AppModule only.');
        }
    }
}
