import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppConfig } from '../../app.config';
import { ComponentService } from '../../services/component/component.service';

@Component({
    selector: 'showdocument',
    template: require('./showdocument.component.html'),
    styles: [require('./showdocument.component.css')]
})

export class ShowDocumentComponent implements OnInit, OnDestroy {

    secureEmailGUID: string;
    secureEmailDocumentGUID: string;
    secureMessageBlob: Blob;
    secureDocumentURL: string;
    documentFilename: string;

    downloadAttrSupported = false;
    error = false;
    isFileSaverSupported = false;
    incorrectPinEntered = false;
    loading = true;
    showPinForm = false;

    currentErrorMessage: string;
    documentFoundMessage: string;
    documentGUIDNoLongerValidMessage: string;
    enterPinMessage: string;
    genericErrorMessage: string;
    headlineText: string;
    incorrectPinEnteredMessage: string;

    downloadFileButtonText: string;
    ieOpenOrDownloadButtonText: string;
    openFileButtonText: string;
    submitPinButtonText: string;
    pinLabelText: string;
    documentGUID: string;
    pdfWindow: Window;
    showSuccess = false;
    lastUsedPin: string;
    documentURL: string;

    private ngUnsubscribe: Subject<any> = new Subject();

    constructor(
        private componentService: ComponentService,
        private config: AppConfig
    ) {}

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit() {
        this.documentURL = this.config.getConfig('secureDocumentPdfPath');

        // Check if HTML5 filesaving is supported, if not, they can open in new tab.
        try {
            this.isFileSaverSupported = !!new Blob();
        } catch (e) {
            // Do nothing because the variable is already false and we don't care.
        }
        const pinUsed = this.componentService.storageService.retrieve('secureemailauth') === 'pin';

        this.componentService.scrollPageToTop();
        this.componentService.contentService.content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((content: any) => {
                if (pinUsed) {
                    this.enterPinMessage = this.componentService.contentService.tryGetContentItem(content, 'document', 'mevPinDocument', 'enterPinMessage').text;
                    this.headlineText = this.componentService.contentService.tryGetContentItem(content, 'document', 'mevPinDocument', 'headlineText').text;
                    this.submitPinButtonText = this.componentService.contentService.tryGetContentItem(content, 'document', 'mevPinDocument', 'submitPinButtonText').text;
                    this.pinLabelText = this.componentService.contentService.tryGetContentItem(content, 'document', 'mevPinDocument', 'pinLabelText').text;
                    this.incorrectPinEnteredMessage = this.componentService.contentService.tryGetContentItem(content, 'error', 'mevPinDocument', 'incorrectPinMessage').text;
                } else {
                    this.enterPinMessage = this.componentService.contentService.tryGetContentItem(content, 'document', 'document', 'enterPinMessage').text;
                    this.headlineText = this.componentService.contentService.tryGetContentItem(content, 'document', 'document', 'headlineText').text;
                    this.submitPinButtonText = this.componentService.contentService.tryGetContentItem(content, 'document', 'button', 'submitPinButtonText').text;
                    this.pinLabelText = this.componentService.contentService.tryGetContentItem(content, 'document', 'document', 'pinLabelText').text;
                    this.incorrectPinEnteredMessage = this.componentService.contentService.tryGetContentItem(content, 'error', 'document', 'incorrectPasswordMessage').text;
                }

                this.genericErrorMessage = this.componentService.contentService.tryGetContentItem(content, 'error', 'document', 'showDocumentGenericErrorMessage').text;
                this.documentGUIDNoLongerValidMessage = this.componentService.contentService.tryGetContentItem(content, 'error', 'document', 'guidNotValidMessage').text;
                this.documentFoundMessage = this.componentService.contentService.tryGetContentItem(content, 'document', 'document', 'documentFoundMessage').text;

                this.ieOpenOrDownloadButtonText = this.componentService.contentService.tryGetContentItem(content, 'document', 'button', 'ieDownloadButtonText').text;
                this.openFileButtonText = this.componentService.contentService.tryGetContentItem(content, 'document', 'button', 'viewStatementButtonText').text;
                this.downloadFileButtonText = this.componentService.contentService.tryGetContentItem(content, 'document', 'button', 'downloadStatementButtonText').text;
            });

        this.secureEmailGUID = this.componentService.storageService.retrieve('secureemailguid');
        this.secureEmailDocumentGUID = this.componentService.storageService.retrieve('secureemaildocumentguid');

        this.downloadAttrSupported = 'download' in document.createElement('a');
        if (this.secureEmailGUID !== null) {
            this.componentService.archiveService.isValidSecureEmailGUID(this.secureEmailGUID).then(
                (success) => {
                    this.showPinForm = success;
                    this.documentFilename = this.secureEmailGUID + '.pdf';
                    this.doneLoading();
                },
                (error) => {
                    this.error = true;
                    this.currentErrorMessage = this.documentGUIDNoLongerValidMessage;
                    this.doneLoading();
                }
            );
        } else {
            this.documentGUID = this.componentService.storageService.retrieve('documentGUID');
            if (this.documentGUID !== null) {
                this.showPinForm = true;
            }
            this.doneLoading();
        }
    }

    getDocumentURL() {
        if (this.secureEmailGUID !== null) {
            return `${this.documentURL}?did=${this.secureEmailDocumentGUID}&s=${this.secureEmailGUID}&pin=${this.lastUsedPin}`;
        } else {
            return `${this.documentURL}?did=${this.documentGUID}&s=${this.secureEmailGUID}&pin=${this.lastUsedPin}`;
        }
    }

    async checkIfPinIsValid(pinForm: NgForm): Promise<void> {
        this.loading = true;
        this.lastUsedPin = pinForm.value.enteredPin;
        if (this.secureEmailGUID !== null) {
            await this.componentService.archiveService
                .isValidDocumentPin(pinForm.value.enteredPin, this.secureEmailDocumentGUID, this.secureEmailGUID, true)
                .then(response => {
                    this.doneLoading();
                    this.error = false;
                    this.incorrectPinEntered = false;
                    this.showPinForm = false;
                    this.showSuccess = true;
                })
                .catch(async (error) => {
                    this.doneLoading();
                    if (error.messages &&
                        error.messages[0]
                        && error.messages[0] === 'Secure Email Request is Invalid') {
                        this.incorrectPinEntered = true;
                    } else {
                        this.error = true;
                    }
                });
        } else {
            if (this.documentGUID !== null) {
                await this.componentService.archiveService
                    .isValidDocumentPin(pinForm.value.enteredPin, this.documentGUID, '', false)
                    .then(response => {
                        this.doneLoading();
                        this.error = false;
                        this.incorrectPinEntered = false;
                        this.showPinForm = false;
                        this.showSuccess = true;
                    })
                    .catch(async (error) => {
                        this.doneLoading();
                        if (error.messages &&
                            error.messages[0]
                            && error.messages[0] === 'Authentication Request is Invalid') {
                            this.incorrectPinEntered = true;
                        } else {
                            this.error = true;
                        }
                    });
            }
        }

    }
    private doneLoading() {
        this.loading = false;
    }

    private hasError(): boolean {
        return this.error;
    }

    private isLoading(): boolean {
        return this.loading;
    }
}
