import { Component, Input, OnInit } from '@angular/core';

/**
 * Displays a chevron svg graphic facing down by default, rotated by the input number of degrees.
 *
 * @export
 * @class ArrowComponent
 * @implements {OnInit}
 */
@Component({
    selector: 'arrow',
    template: require('./arrow.component.html'),
    styles: [require('./arrow.component.css')]
})

export class ArrowComponent implements OnInit {
    constructor() { }

    @Input() rotateDegrees = 0;
    @Input() styleOverride: any;

    ngOnInit() { }

    private rotatePercent(): string {
        return 'rotate(' + this.rotateDegrees + 'deg)';
    }

}
