import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import '../Content/circle.css';
import '../Content/site.css';
import '../Content/spinner.css';

// The following import is required because the Angular Material
// Mat-Menu will not render correctly without this import.
import '../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css';
import { AppModule } from './app.module';

if (process.env.ENV === 'production') {
    enableProdMode();
}
platformBrowserDynamic().bootstrapModule(AppModule);
