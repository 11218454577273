import { Component, EventEmitter, NgZone, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import moment from 'moment';
import { Subject ,  Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IDomainInfo } from '../../models/domaininfo';
import { ErrorMessage } from '../../models/errormessage';
import { MDWiseAccount } from '../../models/mdwiseaccount';
import { Roles } from '../../models/roles';
import { ComponentService } from '../../services/component/component.service';
import { ConsumerService } from '../../services/consumer/consumer.service';
import { EpicSsoService } from '../../services/epic-sso/epic-sso.service';
import { LoggingLevel } from '../../services/logging/logging.service';
import { StorageLevel } from '../../services/storage/storage.service';
import { AccountSelectionService } from '../accountselection/accountselection.service';
import { LoginService } from './../../services/login/login.service';

@Component({
    selector: 'redirect',
    template: require('./redirect.component.html'),
    styles: [require('./redirect.component.css')]
})
export class RedirectComponent implements OnInit, OnDestroy {
    domainInfo: IDomainInfo;
    qrMemCode: string;
    qpMemCode: string;
    textMemCode: string;
    linkMode: string;
    classicLogin: string;
    documentId: string;
    verificationId: string;
    enrollmentChannel: string;
    accountVerificationGUID: string;
    passwordResetId: string;
    cipher: string;
    ssoMode: string;
    ghostMode: string;
    impersonatorMode: string;
    secureEmailGUID: string;
    agentAssistedPaymentEmailGUID: string;
    enrollment: string;
    pendingEnrollmentGUID: string;
    isVerified = false;
    isExpired = false;
    defaultRouteRedirect = false;
    subscription: Subscription;
    letMeIn = false;
    fastTrackGUID: string;
    smsEnrollment: string;
    emailEnrollment: string;
    applicationId: string;
    customerAccountId: string;
    amount: string;
    enrollDate: string;
    quickPayManageType: string;
    smsGUID: string;
    secureEmailAuth: string;
    etid: string;
    consumerAccountGUID: string;
    unsubscribe: string;
    documentGUID: string;
    externalID: string;
    epicSsoErrorMessage: ErrorMessage;
    throwUnhandledError = false;
    private ngUnsubscribe: Subject<any> = new Subject();
    @Output() changeReset = new EventEmitter<boolean>();

    constructor(
        private componentService: ComponentService,
        private activatedRoute: ActivatedRoute,
        private parentRouter: Router,
        private loginService: LoginService,
        private consumerService: ConsumerService,
        private epicSsoService: EpicSsoService,
        private accountSelectionService: AccountSelectionService,
        private ngZone: NgZone
    ) { }

    ngOnDestroy() {
        this.componentService.loggingService.log('ngOnDestroy -> ngUnsubscribe', LoggingLevel.debug);
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    // this page verifies url params and redirects
    ngOnInit() {
        // subscribe to router event
        this.activatedRoute.queryParams.pipe(takeUntil(this.ngUnsubscribe)).subscribe((params: Params) => {
            this.qrMemCode = params.qr;
            this.textMemCode = !params.mem ? params.MEM : params.mem;
            this.qpMemCode = params.qpm;
            this.linkMode = params.m;
            this.ssoMode = params.f;
            this.ghostMode = params.g;
            this.impersonatorMode = params.i;
            this.cipher = params.d;
            this.classicLogin = params.login;
            this.documentId = params.did;
            this.verificationId = params.verification;
            this.enrollmentChannel = params.enrollmentchannel;
            this.accountVerificationGUID = params.cgd;
            this.quickPayManageType = params.mqp;
            this.passwordResetId = params.passwordReset;
            this.secureEmailGUID = params.gid;
            this.agentAssistedPaymentEmailGUID = params.aapg;
            this.pendingEnrollmentGUID = params.possignup;
            this.enrollment = params.enrollment;
            this.letMeIn = !params.LetMeIn ? params.letmein : params.LetMeIn;
            this.fastTrackGUID = params.Token;
            this.smsEnrollment = params.smsenrollment;
            this.emailEnrollment = params.emailenrollment;
            this.applicationId = params.ApplicationID;
            this.customerAccountId = params.caid;
            this.amount = params.Amount;
            this.enrollDate = params.Date;
            this.smsGUID = params.smsGUID;
            this.secureEmailAuth = params.auth;
            this.etid = params.etid;
            this.consumerAccountGUID = params.cgd;
            this.unsubscribe = params.mqp;
            this.documentGUID = params.dguid;
            this.externalID = params.ExternalId;
            this.throwUnhandledError = params.tue;

            if (this.qrMemCode != null) {
                this.componentService.loggingService.log('queryParams: qr', LoggingLevel.debug);
                this.componentService.storageService.clearSession();
                this.qrMemCode = this.qrMemCode.split(';')[0];
                this.componentService.storageService.store('mem', this.qrMemCode);
                this.componentService.storageService.store('mode', 'qr');
                this.componentService.storageService.store('paymentsource', 'mySecureBill QR', StorageLevel.protected);
            } else if (this.qpMemCode != null) {
                this.componentService.loggingService.log('queryParams: qpm', LoggingLevel.debug);
                this.componentService.storageService.clearSession();
                this.componentService.storageService.store('mem', this.qpMemCode);
                this.componentService.storageService.store('mode', 'qpm');
            } else if (this.linkMode != null) {
                this.componentService.loggingService.log('queryParams: m', LoggingLevel.debug);
                this.componentService.storageService.clearSession();
                this.componentService.storageService.store('mode', this.linkMode);
                this.componentService.storageService.store('documentId', this.documentId);
            } else if (this.verificationId != null) {
                this.componentService.loggingService.log('queryParams: verification', LoggingLevel.debug);
                this.componentService.storageService.store('mode', 'validate');
                this.componentService.storageService.store('verificationid', this.verificationId);
                this.componentService.storageService.store('enrollmentchannel', this.enrollmentChannel);
            } else if (this.accountVerificationGUID != null && this.quickPayManageType === 'delivery') {
                this.componentService.loggingService.log('queryParams: quickpaypreferencesverification', LoggingLevel.debug);
                this.componentService.storageService.store('mode', 'managedeliverypreferences');
                this.componentService.storageService.store('accountverificationid', this.accountVerificationGUID);
            } else if (this.accountVerificationGUID != null && this.quickPayManageType === 'sms') {
                this.componentService.loggingService.log('queryParams: quickpaypreferencesverification', LoggingLevel.debug);
                this.componentService.storageService.store('mode', 'managesmspreferences');
                this.componentService.storageService.store('accountverificationid', this.accountVerificationGUID);
            } else if (this.passwordResetId != null) {
                this.componentService.loggingService.log('queryParams: passwordReset', LoggingLevel.debug);
                this.componentService.storageService.store('mode', 'passwordreset');
                this.componentService.storageService.store('passwordresetid', this.passwordResetId, StorageLevel.protected);
            } else if (this.ssoMode != null && this.cipher != null && this.impersonatorMode == null) {
                this.componentService.loggingService.log('queryParams: f & d', LoggingLevel.debug);
                this.componentService.storageService.store('mode', 'sso');
                if (this.ghostMode != null) {
                    this.componentService.storageService.store('noconsumerstatementhistoryrole', true);
                }
            } else if (this.impersonatorMode != null) {
                this.componentService.loggingService.log('queryParams: f & i & d', LoggingLevel.debug);
                this.componentService.storageService.store('mode', 'impersonator');
            } else if (this.textMemCode != null) {
                this.componentService.loggingService.log('queryParams: mem', LoggingLevel.debug);
                this.componentService.storageService.store('mem', this.textMemCode);
                this.componentService.storageService.store('mode', 'textMemCode');
                this.componentService.storageService.store('smsGUID', this.smsGUID);
            } else if (this.classicLogin != null) {
                this.componentService.loggingService.log('queryParams: login', LoggingLevel.debug);
                this.componentService.storageService.store('mode', 'classiclogin');
            } else if (this.secureEmailGUID != null && this.documentId != null) {
                this.componentService.loggingService.log('queryParams: did & gid', LoggingLevel.debug);
                this.componentService.storageService.store('mode', 'secureemail');
                this.componentService.storageService.store('secureemailguid', this.secureEmailGUID);
                this.componentService.storageService.store('secureemaildocumentguid', this.documentId);
                if (this.secureEmailAuth != null && this.secureEmailAuth.toLowerCase() === 'pin') {
                    this.componentService.storageService.store('secureemailauth', this.secureEmailAuth.toLowerCase());
                } else {
                    this.componentService.storageService.clear('secureemailauth');
                }
            } else if (this.secureEmailGUID != null) {
                this.componentService.loggingService.log('queryParams: gid & r', LoggingLevel.debug);
                this.componentService.storageService.store('mode', 'recurringActivation');
                this.componentService.storageService.store('secureemailguid', this.secureEmailGUID);
            } else if (this.agentAssistedPaymentEmailGUID != null) {
                this.componentService.loggingService.log('queryParams: aapg', LoggingLevel.debug);
                this.componentService.storageService.store('mode', 'agentAssistedEmail');
                this.componentService.storageService.store('agentAssistedPaymentEmailGUID', this.agentAssistedPaymentEmailGUID);
                this.componentService.storageService.store('agentassistedrole', '');
            } else if (this.enrollment != null) {
                this.componentService.storageService.store('mode', 'pendingenrollment');
            } else if (this.pendingEnrollmentGUID != null) {
                this.componentService.loggingService.log('queryParams: possignup', LoggingLevel.debug);
                this.componentService.storageService.store('mode', 'pendingenrollment');
                this.componentService.storageService.store('pendingenrollmentguid', this.pendingEnrollmentGUID);
            } else if (this.fastTrackGUID != null) {
                this.componentService.loggingService.log('queryParams: fastTrackGUID', LoggingLevel.debug);
                this.componentService.storageService.store('mode', 'fasttrack');
                this.componentService.storageService.store('fasttrackguid', this.fastTrackGUID);
            } else if (this.smsEnrollment != null) {
                // If it is smsEnrollment it means it came from quick pay and the token should be set to quickpayidentifier
                this.componentService.storageService.store('token', this.componentService.storageService.retrieve('quickpayidentifier'));
                // re-store onetimepayment so we don't allow access to more pages than intended
                this.componentService.storageService.store('onetimepayment', 'quickpayenrollment');
                // Set mode to smsenrollment
                this.componentService.storageService.store('mode', 'smsenrollment');
            } else if (this.emailEnrollment != null) {
                // If it is emailEnrollment it means it came from quick pay and the token should be set to quickpayidentifier
                this.componentService.storageService.store('token', this.componentService.storageService.retrieve('quickpayidentifier'));
                // re-store onetimepayment so we don't allow access to more pages than intended
                this.componentService.storageService.store('onetimepayment', 'quickpayenrollment');
                // Set mode to emailenrollment
                this.componentService.storageService.store('mode', 'emailenrollment');
            } else if (this.applicationId != null) {
                // need to clear the session to not carry a possible merchant profile if the user has logged in recently.
                this.componentService.storageService.clearSession();
                this.componentService.loggingService.log('queryParams: accountId', LoggingLevel.debug);
                this.componentService.storageService.store('mode', 'inmdwise');
                this.componentService.storageService.store('applicationId', this.applicationId);

                if (this.externalID != null) {
                    this.componentService.storageService.store('ExternalId', this.externalID);
                }
            } else if (this.etid != null) {
                this.componentService.storageService.clearSession();
            } else if (this.consumerAccountGUID != null && this.unsubscribe === 'unsubscribe') {
                this.componentService.loggingService.log('queryParams: cgd & mqp', LoggingLevel.debug);
                this.componentService.storageService.store('consumerAccountGUID', this.consumerAccountGUID);
                this.componentService.storageService.store('mode', this.unsubscribe);
            } else if (this.documentGUID != null) {
                this.componentService.loggingService.log('queryParams: dguid', LoggingLevel.debug);
                this.componentService.storageService.store('documentGUID', this.documentGUID);
                this.componentService.storageService.store('mode', 'verifyDocumentGUID');
            } else {
                this.componentService.loggingService.log('defaultRouteRedirect set true', LoggingLevel.debug);
                this.componentService.storageService.store('mode', '');
                // default route redirect
                this.defaultRouteRedirect = true;
            }
            if (this.throwUnhandledError) {
                throw (new Error('I am a new thrown error'));
            }
        });

        // this is a spot where we don't want to use the takeUntil method and unsubscribe
        // right away when doing the tryRedirect method.

        this.subscription = this.componentService.domainService.domainInfo$.subscribe(domainInfo => {
            this.componentService.loggingService.log('domainInfo subscribed', LoggingLevel.debug);
            this.domainInfo = domainInfo;
            if (domainInfo != null) {
                this.componentService.loggingService.log('domainInfo not null -> tryRedirect', LoggingLevel.debug);
                this.checkOutages();
            }
        });

        this.componentService.contentService.content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(content => {
                this.epicSsoErrorMessage = {
                    headerText: this.componentService.contentService.tryGetContentItem(content, 'global', 'error', 'genericWeAreSorryHeaderText').text,
                    mobileHeaderText: this.componentService.contentService.tryGetContentItem(content, 'global', 'error', 'genericWeAreSorryHeaderText').text,
                    errorMessageText:
                        this.componentService.contentService.tryGetContentItem(content, 'global', 'error', 'epicSSOErrorText').text,
                    redirectButtonText: this.componentService.contentService.tryGetContentItem(content, 'global', 'error', 'genericRedirectButtonText').text,
                    redirectUrl: '/'
            };
        });
    }

    checkOutages() {
        if (this.componentService.letMeIn()) {
            this.tryRedirect();
        } else {
            this.componentService.domainService.getOutages().then(outageList => {
                if (!!outageList) {
                    if (outageList.some(x => new Date(x.outageStartDate) < new Date() && x.system === 'MySecureBill Maintenance')) {
                        if (!this.letMeIn) {
                            this.ngZone.run(() => this.parentRouter.navigateByUrl('/outage')).then();
                        } else {
                            this.tryRedirect();
                        }
                    } else {
                        this.tryRedirect();
                    }
                }
            });
        }
    }

    tryRedirect() {
        // this.componentService.loggingService.log('domainInfo$ subscription closed? -> ' + this.subscription.closed, LoggingLevel.debug);
        if (this.subscription != null) {
            this.componentService.loggingService.log('domainInfo unsubscribed', LoggingLevel.debug);
            this.subscription.unsubscribe();
        }
        this.componentService.loggingService.log('tryRedirect: defaultRouteRedirect=' + this.defaultRouteRedirect, LoggingLevel.debug);

        this.ngZone.run(async () => {
            // If nothing else, we're intending to do a "default" redirect
            if (this.defaultRouteRedirect) {
                const oneTimePayment = this.componentService.storageService.exists('onetimepayment');
                const userIsEnrolling = this.componentService.storageService.exists('enrollment');
                const rolesExist = this.componentService.storageService.exists('roles');
                const agentAssistedRoleExists = this.componentService.storageService.exists('agentAssistedRole');

                if (this.componentService.userIsLoggedIn()) {
                    if (!oneTimePayment && !userIsEnrolling) {
                        let valid;
                        const token = this.componentService.storageService.retrieve('token');

                        try {
                            valid = await this.loginService.isUserSessionValid(token);
                        } catch (e) {
                            this.componentService.loggingService.log(
                                'tryRedirect -> error checking session, dont send home.', LoggingLevel.debug);
                            return this.redirectToDomainDefaultPage();
                        }

                        if (valid && !agentAssistedRoleExists) {
                            this.componentService.loggingService.log('tryRedirect -> /home', LoggingLevel.debug);

                            if (rolesExist) {
                                if (this.componentService.hasRole(Roles.StatementsOnly)) {// it's statements only
                                    return this.ngZone.run(() => this.parentRouter.navigateByUrl('/statements')).then();
                                } else {
                                    return this.ngZone.run(() => this.parentRouter.navigateByUrl('/home')).then();
                                }
                            } else {
                                return this.ngZone.run(() => this.parentRouter.navigateByUrl('/home')).then();
                            }
                        } else {
                            this.componentService.loggingService.log('tryRedirect -> session expired, dont send home.', LoggingLevel.debug);
                            return this.redirectToDomainDefaultPage();
                        }
                    } else if (oneTimePayment) {
                        this.componentService.loggingService.log('tryRedirect -> gotoMem', LoggingLevel.debug);
                        return this.gotoMem();
                    } else {
                        this.componentService.loggingService.log('tryRedirect -> /default', LoggingLevel.debug);
                        return this.gotoDefault();
                    }
                } else {
                    return this.redirectToDomainDefaultPage();
                }
            }
            const mode = this.componentService.storageService.retrieve('mode');
            this.componentService.loggingService.log(`tryRedirect: mode=${mode}`, LoggingLevel.debug);

            switch (mode) {
                case 'smsenrollment':
                    return this.ngZone.run(() => this.parentRouter.navigateByUrl('/sms')).then();
                case 'emailenrollment':
                    return this.ngZone.run(() => this.parentRouter.navigateByUrl('/modifiedenrollment')).then();
                case 'classiclogin':
                    return this.ngZone.run(() => this.parentRouter.navigateByUrl('/default')).then();
                case 'secureemail':
                    return this.ngZone.run(() => this.parentRouter.navigateByUrl('/showdocument')).then();
                case 'agentAssistedEmail':
                    return this.ngZone.run(() => this.parentRouter.navigateByUrl('/showpayment')).then();
                case 'pendingenrollment':
                    return this.ngZone.run(() => this.parentRouter.navigateByUrl('/enrollment')).then();
                case 'recurringActivation':
                    return this.ngZone.run(() => this.parentRouter.navigateByUrl('/recurring/activation')).then();
                case 'managedeliverypreferences':
                    return this.ngZone.run(() => this.parentRouter.navigateByUrl('/quickpay/deliveryprofile')).then();
                case 'managesmspreferences':
                    return this.ngZone.run(() => this.parentRouter.navigateByUrl('/quickpay/sms')).then();
                case 'unsubscribe':
                    if (this.domainInfo.deliveryMode === 'Paper Only') {
                        return this.ngZone.run(() => this.parentRouter.navigateByUrl('/default')).then();
                    } else {
                        // deliveryMode equals 'None' or 'Standard'
                        return this.ngZone.run(() => this.parentRouter.navigateByUrl('/unsubscribeemail')).then();
                    }
                case 'verifyDocumentGUID':
                    const response = await this.consumerService.validateDocumentGUID(this.componentService.storageService.retrieve('documentGUID'));
                    if (response != null) {
                        this.isVerified = response;
                        if (this.isVerified) {
                            return this.ngZone.run(() => this.parentRouter.navigateByUrl('/showdocument')).then();
                        } else {
                            return this.ngZone.run(() => this.parentRouter.navigateByUrl('/default')).then();
                        }
                    } else {
                        return this.ngZone.run(() => this.parentRouter.navigateByUrl('/default')).then();
                    }
            }

            // SSO Verification / Account Creation / Login
            if (this.domainInfo != null && mode === 'sso') {
                return await this.ssoVerification();
            }

            // Impersonator login from MEV
            if (this.domainInfo != null && mode === 'impersonator') {
                return await this.impersonate();
            }

            // Epic SSO
            if (!!this.etid) {
                const ssoResponse = await this.epicSsoService.sso(this.etid);

                if (!!ssoResponse) {
                    if (!!ssoResponse.status && ssoResponse.status === 500) {
                        this.componentService.storageService.store('error', this.epicSsoErrorMessage);
                        this.componentService.storageService.store('hideCustomMemPaymentFooter', true);
                        return this.ngZone.run(() => this.parentRouter.navigateByUrl('/error')).then();
                    }

                    if (ssoResponse.handleMultipleAccounts) {
                        // Request that the user select the desired account.
                        this.accountSelectionService.etid = this.etid;
                        this.ngZone.run(() => this.parentRouter.navigate(['/accountselection'])).then();
                        return;
                    }

                    if (!!ssoResponse.token) {
                        this.componentService.storageService.store('token', ssoResponse.token);

                        // We need to call getConsumerAccount
                        // because it stores the consumer in local storage and allows us to successfully navigate to /home
                        const consumerAccount = await this.consumerService.getConsumerAccount(
                            ssoResponse.token,
                            ssoResponse.customerAccountID
                        );

                        if (!!consumerAccount) {
                            // Determine where to send the consumer
                            const roles = await this.consumerService.getConsumerRoles();
                            this.componentService.storageService.store('roles', roles);

                            const page = ssoResponse.page;
                            const pageOptions = [
                                'profile',
                                'payment',
                                'statements'
                            ];

                            if (!!page && pageOptions.indexOf(page) >= -1) {
                                    return this.ngZone.run(() => this.parentRouter.navigateByUrl(page)).then();
                            } else {
                                if (this.componentService.hasRole(Roles.StatementsOnly)) {
                                    return this.ngZone.run(() => this.parentRouter.navigateByUrl('/statements')).then();
                                } else {
                                    this.componentService.storageService.clear('noconsumerstatementhistoryrole');
                                    return this.ngZone.run(() => this.parentRouter.navigateByUrl('/home')).then();
                                }
                            }
                        }
                    } else {
                        return this.redirectToDomainDefaultPage();
                    }
                } else {
                    return this.redirectToDomainDefaultPage();
                }
            }

            // validate verification email
            if (mode === 'validate' && this.componentService.storageService.exists('verificationid')) {
                const response = await this.consumerService.validateVerificationId(this.componentService.storageService.retrieve('verificationid'),
                    this.componentService.storageService.retrieve('enrollmentchannel'));
                if (response != null) {
                    this.isVerified = response;
                    this.componentService.storageService.store('verificationemailexpired', !this.isVerified);
                    if (this.componentService.userIsLoggedIn()) {
                        // If user is logged in, update their account in storage.
                        await this.consumerService.updateConsumerAccountStorage();
                        this.ngZone.run(() => this.parentRouter.navigateByUrl('/home')).then();
                    } else {
                        if (this.isVerified) {
                            return this.ngZone.run(() => this.parentRouter.navigateByUrl('/emailactivation')).then();
                        } else {
                            this.gotoDefault();
                        }
                    }
                } else {
                    this.gotoDefault();
                }
            }

            // Fast track payment
            if (mode === 'fasttrack' && this.componentService.storageService.exists('fasttrackguid')) {
                const fastTrackGUID = this.componentService.storageService.retrieve('fasttrackguid');
                let response;
                try {
                    response = await this.consumerService.getFastTrackPaymentInfo(fastTrackGUID);
                } catch (e) {
                    return this.ngZone.run(() => this.parentRouter.navigateByUrl('/fasttrackpayment')).then();
                }
                this.componentService.storageService.clear('fasttrackguid');
                if (response != null) {
                    this.componentService.storageService.store('fasttrackresponse', response);
                }
                // modified account payment component will handle errors
                return this.ngZone.run(() => this.parentRouter.navigateByUrl('/fasttrackpayment')).then();
            }

            // careenroll MDWise
            if (mode === 'inmdwise') {
                const careenroll: MDWiseAccount = new MDWiseAccount();
                if (this.enrollDate != null) {
                    if (moment(this.enrollDate).isSame(moment(), 'second')) {
                        // date is broken, treat the rest as garbage and go to careenroll for errors
                        this.ngZone.run(() => this.parentRouter.navigateByUrl('/careenroll')).then();
                    }
                    careenroll.accountDate = moment(this.enrollDate).toDate();
                }
                careenroll.applicationId = this.applicationId;
                careenroll.customerAccountId = this.customerAccountId;

                if (this.amount != null && this.amount.length >= 3) {
                    careenroll.paymentAmount = Number(this.amount) / 100;
                }
                this.componentService.storageService.store('careenroll', careenroll);

                // modified account payment component will handle errors
                this.ngZone.run(() => this.parentRouter.navigateByUrl('/careenroll')).then();
            }

            // validate password reset email
            if (mode === 'passwordreset' && this.componentService.storageService.exists('passwordresetid')) {
                const response = await this.consumerService.validatePasswordResetId(this.componentService.storageService.retrieve('passwordresetid'));
                if (response != null) {
                    this.isExpired = !response;
                    return this.parentRouter.navigateByUrl(
                        '/default?passwordReset=' + this.passwordResetId + '&expired=' + this.isExpired.toString());
                }
            }

            // qr performs login
            if ((mode === 'qpm' || mode === 'qr' || mode === 'textMemCode')
                && this.componentService.storageService.exists('mem')) {
                this.componentService.loggingService.log(`tryRedirect-${mode}: ${this.domainInfo.paymentValidationMode} && ${this.domainInfo.paymentValidationMode}`, LoggingLevel.debug);
                if (this.domainInfo.paymentValidationMode !== 'ZIP5' && this.domainInfo.paymentValidationMode !== 'XML') {
                    this.login('');
                } else {
                    this.gotoMem();
                }
            }

            // link performs login
            if (this.linkMode != null
                && this.componentService.storageService.retrieve('mode') === this.linkMode
                && this.componentService.storageService.exists('documentId')) {
                if (this.domainInfo.desktopLinkValidationMode !== 'ZIP5' && this.domainInfo.desktopLinkValidationMode !== 'XML') {
                    this.login('');
                } else {
                    this.gotoMem();
                }
            }
        });
    }
    /**
     * Used for SSO Verification / Account Creation / Login
     */
    async ssoVerification() {
        // attempt to only have validate be called once.
        this.componentService.storageService.store('mode', '');
        let data;
        try {
            data = await this.loginService.verifySsoCipher(this.cipher, this.componentService.storageService.exists('noconsumerstatementhistoryrole'));
        } catch (e) {
            this.componentService.loggingService.log(
                'redirect mode=sso loginService.verifySsoCipher error -> clear session and go to /', LoggingLevel.debug);
            return this.clearAndReload();
        }
        // Clear out the session before impersonating so that we don't get token collisions and things don't go all wacky Wednesday on us
        this.componentService.storageService.clearSession();

        // Store the token to make all the API calls
        this.componentService.storageService.store('token', data.token);
        // Store hiding the address in the profile so that both old and new profile can reference it:
        this.componentService.storageService.store('hideAddress', data.hideAddress);

        try {
            await this.consumerService.getConsumer();
        } catch (e) {
            this.componentService.loggingService.log(
                'redirect mode=sso loginService.verifySsoCipher error -> clear session and go to /', LoggingLevel.debug);
            return this.clearAndReload();
        }

        try {
            await this.consumerService.getConsumerAccount();
            const roles = await this.consumerService.getConsumerRoles();
            this.componentService.storageService.store('roles', roles);
            this.componentService.loggingService.log(
                'redirect mode=sso -> consumerAccountId: ' + data.consumerAccountID, LoggingLevel.debug);
            this.componentService.loggingService.log(
                'redirect mode=sso -> noconsumerstatementhistoryrole: ' + this.componentService.storageService.exists('noconsumerstatementhistoryrole'), LoggingLevel.debug);
            if (this.componentService.hasRole(Roles.StatementsOnly)
                || (!data.consumerAccountID
                    && this.componentService.storageService.exists('noconsumerstatementhistoryrole'))) {
                if (!!data.page && data.page === 'Profile' && this.domainInfo.enableProfileTab) {
                    this.ngZone.run(() => this.parentRouter.navigateByUrl('/profile')).then();
                } else {
                    this.ngZone.run(() => this.parentRouter.navigateByUrl('/statements')).then();
                }
            } else {
                this.componentService.storageService.clear('noconsumerstatementhistoryrole');
                if (!!data.page && (data.page === 'Payment' || data.page === 'Profile' || data.page === 'Statements')) {
                    this.parentRouter.navigateByUrl('/' + data.page.toLowerCase());
                } else {
                    this.ngZone.run(() => this.parentRouter.navigateByUrl('/home')).then();
                }
            }
        } catch (err) {
            this.componentService.loggingService.log(
                'redirect mode=sso getConsumerRoles error -> clear session and go to /', LoggingLevel.debug);
            return this.clearAndReload();
        }
    }

    async impersonate() {
        // attempt to only have validate be called once.
        this.componentService.storageService.store('mode', '');
        let data;
        try {
            data = await this.loginService.verifyImpersonatorCipher(this.cipher);
        } catch (err) {
            this.componentService.loggingService.log('redirect mode=impersonator loginService.verifyImpersonatorCipher error -> clearAndReload', LoggingLevel.debug);
            return this.clearAndReload();
        }

        // Clear out the session before impersonating so that we don't get token collisions and things don't go all wacky Wednesday on us
        this.componentService.storageService.clearSession();

        // Store the token to make all the API calls
        this.componentService.storageService.store('token', data.token);
        this.componentService.storageService.store('sb', true);
        this.componentService.loggingService.log(
            'redirect mode=impersonator -> impersonatorroleallowpayments: ' + this.componentService.storageService.exists('impersonatorroleallowpayments'), LoggingLevel.debug);
        this.componentService.storageService.store('impersonatorroleallowpayments', data.impersonatorRoleAllowPayments);
        try {
            await this.consumerService.getConsumer();
        } catch (e) {
            this.componentService.loggingService.log('redirect mode=impersonator getConsumer error -> clearAndReload', LoggingLevel.debug);
            return this.clearAndReload();
        }

        try {
            await this.consumerService.getConsumerAccount();
            const roles = await this.consumerService.getConsumerRoles();
            this.componentService.storageService.store('roles', roles);
            if (this.componentService.hasRole(Roles.StatementsOnly)) {
                return this.ngZone.run(() => this.parentRouter.navigateByUrl('/statements')).then();
            }
            this.ngZone.run(() => this.parentRouter.navigateByUrl('/home')).then();
        } catch (e) {
            this.componentService.loggingService.log(
                'redirect mode=impersonator getConsumerAccount error -> clearAndReload', LoggingLevel.debug);
            return this.clearAndReload();
        }
    }

    login(memExtra: string) {
        // It could be coming from QR code or to a new domain.
        const logInMemCode = this.qrMemCode != null ? this.qrMemCode : this.textMemCode;
        // If its to a new domain, it is not in link mode.
        const isLinkMode = this.qrMemCode != null;
        this.loginService.memLogin(logInMemCode, null, memExtra, false, isLinkMode).then(login => {
            this.componentService.storageService.store('token', login.token);
                /*revisit if redirecting from non-qr*/
            this.componentService.storageService.store('onetimepayment', true);
            this.redirect();
        }).catch(error => {
            this.gotoMem();
        });
    }

    clearAndReload(): void {
        this.componentService.loggingService.log('clearAndReload -> clear session and go to /', LoggingLevel.debug);
        this.componentService.storageService.clearSession();

        // this isn't ideal, but we need to clear out query string params and reload to itself
        window.location.href = '/';
    }

    redirect(): void {
        if (this.componentService.userIsLoggedIn()) {
            history.replaceState(null, null, '/payment');
            this.ngZone.run(() => this.parentRouter.navigateByUrl('/payment')).then();
        } else {
            this.gotoMem();
        }
        return;
    }

    gotoMem(): void {
        history.replaceState(null, null, '/mempayment');
        this.ngZone.run(() => this.parentRouter.navigateByUrl('/mempayment')).then();
        return;
    }

    gotoDefault(): void {
        history.replaceState(null, null, '/default');
        this.ngZone.run(() => this.parentRouter.navigateByUrl('/default')).then();
        return;
    }

    gotoAccountPay(): void {
        history.replaceState(null, null, '/accountpayment');
        this.ngZone.run(() => this.parentRouter.navigateByUrl('/accountpayment')).then();
        return;
    }

    isSSO(): boolean {
        return this.componentService.isSSO(this.domainInfo);
    }

    private redirectToDomainDefaultPage() {
        // just in case there's any session data.
        this.componentService.storageService.clearSession();
        if (this.letMeIn != null) {
            if (this.letMeIn === true) {
                this.componentService.loggingService.log('saving: LetMeIn', LoggingLevel.debug);
                this.componentService.storageService.store('letmein', 1, StorageLevel.protected);
            } else {
                this.componentService.loggingService.log('clearing: LetMeIn', LoggingLevel.debug);
                this.componentService.storageService.clear('letmein');
            }
        }
        if (this.isSSO() || this.domainInfo.deliveryMode == null || this.domainInfo.deliveryMode === 'None') {
            if (!this.domainInfo.enableQuickPay && !this.domainInfo.requireDocumentCode) {
                // mempayment not allowed, redirect to AccountPayment
                this.componentService.loggingService.log('tryRedirect -> goToAccountPay', LoggingLevel.debug);
                this.gotoAccountPay();
            } else {
                // SSO or login not allowed, redirect to mem
                this.componentService.loggingService.log('tryRedirect -> gotoMem', LoggingLevel.debug);
                this.gotoMem();
            }
        } else {
            this.componentService.loggingService.log('tryRedirect -> gotoDefault', LoggingLevel.debug);
            this.gotoDefault();
        }
    }
}
