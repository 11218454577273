import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'system-message',
    template: require('./systemmessage.component.html'),
    styles: [require('./systemmessage.component.css')]
})
export class SystemMessageComponent implements OnInit {
    @Input() title: string;
    @Input() mobileTitle = '';
    @Input() message: string;
    @Input() homepage = false;
    @Input() iconName = 'info';
    @Input() iconLibrary = 'material';

    titleClass = '';
    mobileTitleClass = '';

    constructor() { }
    ngOnInit() {
        this.titleClass = 'headline';
        if (this.mobileTitle) {
            this.titleClass = this.titleClass + ' hidden-xs';
            this.mobileTitleClass = 'headline-small visible-xs';
        }
    }
}
