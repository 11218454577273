import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IConsumer } from '../../../models/consumer';
import { ConsumerAccount, ConsumerAccountDeliveryPreferences } from '../../../models/consumeraccount';
import { IDomainInfo } from '../../../models/domaininfo';
import { GuarantorEmailViewModel } from '../../../models/guarantoremailviewmodel';
import { ComponentService } from '../../../services/component/component.service';
import { ConsumerService } from '../../../services/consumer/consumer.service';
import { ConsumerContactDetailsModalComponent } from '../ConsumerContactDetailsModal/consumercontactdetailsmodal.component';

@Component({
    selector: 'new-quick-pay-manage-delivery-form',
    template: require('./new-quick-pay-manage-delivery-form.component.html'),
    styles: [require('./new-quick-pay-manage-delivery-form.component.css')]
})

export class NewQuickPayManageDeliveryFormComponent implements OnInit {
    @Input() emailAddress = '';
    @Input() showPhoneForm: boolean;
    @Output() setProfileCompleteness: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild('contactDetailsModal', { static: false }) contactDetailsModal: ConsumerContactDetailsModalComponent;

    domainInfo: IDomainInfo;
    consumerAccountPreferences: ConsumerAccountDeliveryPreferences;
    consumerAccount: ConsumerAccount;
    consumer: IConsumer;
    deliveryMethodLoading = false;
    emailLoading = false;
    showDeliveryMethodError = false;
    deliveryMethod: string;
    verificationID: string;
    invalidVerificationID = false;

    header: string;
    subheading: string;
    emailText: string;
    emailUpdateText: string;
    emailUpdateCancelText: string;
    emailUpdateSubmitText: string;
    statementDeliveryText: string;
    // semi-colon delimited list of options:text for Delivery Method (e.g. "Electronic:e-Delivery;Paper:Paper;Both:Electronic & Paper")
    deliveryChoices: string;
    deliveryMethodUpdateText: string;
    deliveryMethodUpdateSubmitText: string;
    deliveryMethodUpdateCancelText: string;
    emailErrorText: string;
    accountNotFoundText: string;

    private ngUnsubscribe: Subject<any> = new Subject();

    constructor(private componentService: ComponentService, private consumerService: ConsumerService) {
    }

    ngOnInit(): void {
        this.componentService.contentService.content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((content: any) => {
                this.header = this.componentService.contentService.tryGetContentItem(
                    content, 'profile', 'pageText', 'profilePageTitle').text;

                this.subheading = this.componentService.contentService.tryGetContentItem(
                    content, 'profile', 'pageText', 'profilePageSubheading').text;

                this.emailText = this.componentService.contentService.tryGetContentItem(
                    content, 'profile', 'pageText', 'profileEmailText').text;

                this.emailUpdateText = this.componentService.contentService.tryGetContentItem(content, 'profile', 'pageText', 'profileEmailUpdateText').text;
                this.emailUpdateSubmitText = this.componentService.contentService.tryGetContentItem(content, 'profile', 'pageText', 'profileEmailUpdateSubmitText').text;
                this.emailUpdateCancelText = this.componentService.contentService.tryGetContentItem(content, 'profile', 'pageText', 'profileEmailUpdateCancelText').text;
                this.statementDeliveryText = this.componentService.contentService.tryGetContentItem(content, 'profile', 'pageText', 'profileStatementDeliveryText').text;
                this.deliveryChoices = this.componentService.contentService.tryGetContentItem(content, 'profile', 'pageText', 'profileDeliveryMethodChoices').text;
                this.deliveryMethodUpdateText = this.componentService.contentService.tryGetContentItem(content, 'profile', 'pageText', 'profileDeliveryMethodUpdateText').text;
                this.deliveryMethodUpdateSubmitText = this.componentService.contentService.tryGetContentItem(content, 'profile', 'pageText', 'profileDeliveryMethodUpdateSubmitText').text;
                this.deliveryMethodUpdateCancelText = this.componentService.contentService.tryGetContentItem(content, 'profile', 'pageText', 'profileDeliveryMethodUpdateCancelText').text;
                this.emailErrorText = this.componentService.contentService.tryGetContentItem(content, 'profile', 'error', 'profileEmailUpdateErrorText').text;
                this.accountNotFoundText = this.componentService.contentService.tryGetContentItem(content, 'profile', 'error', 'profileAccountNotFoundErrorText').text;
            });

        this.componentService.domainService.domainInfo$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(domainInfo => {
            this.domainInfo = domainInfo;
            if (!!domainInfo) {
                this.setDeliveryChoices();
            }
        });

        if (this.componentService.storageService.exists('accountverificationid')) {
            this.emailLoading = true;
            this.verificationID = this.componentService.storageService.retrieve('accountverificationid');
            this.consumerService.getConsumerAccountDeliveryPreferencesByVerificationID(this.verificationID).then(preferences => {
                this.consumerAccountPreferences = preferences;
                this.deliveryMethod = preferences.deliveryMethod;
            }).catch(() => {
                this.invalidVerificationID = true;
            });
        } else {
            this.invalidVerificationID = true;
        }
    }

    openEmailModal(): void {
        this.contactDetailsModal.openModal();
    }

    onEmailUpdatedFromModal(event: any): void {
        this.emailAddress = event;
    }

    onGuarantorEmailsEmitted(emails: GuarantorEmailViewModel[]): void {
        this.emailLoading = false;
        if (emails.length === 1) {
            this.emailAddress = emails[0].emailAddress;
        }
    }

    submitUpdateDeliveryMethod(): void {
        this.deliveryMethodLoading = true;

        this.consumerService.updateConsumerAccountPreferencesByVerificationID(
            this.verificationID,
            this.emailAddress,
            this.deliveryMethod,
            true
        ).then(response => {
            this.deliveryMethodLoading = false;

            // something changed with the shape of the response on these Observables... (was response.success)
            if (!response.body.success) {
                this.showDeliveryMethodError = true;
            }
        },
        () => {
            this.deliveryMethodLoading = false;
            this.showDeliveryMethodError = true;
        });
    }

    disableElectronicDeliveryOption(value: any): boolean {
        if (!this.emailAddress) {
            // if email is blank, the only option available should be 'Paper'
            if (value.toLowerCase() === 'both' || value.toLowerCase() === 'electronic') {
                return true;
            }
        }
        return false;
    }

    private setDeliveryChoices(): void {
        if (!this.domainInfo.enableDeliveryBoth) {
            this.deliveryChoices = this.deliveryChoices.split(';').filter(x => !x.includes('&')).join(';');
        }
        if (this.domainInfo.deliveryMode.toLocaleLowerCase().includes('paper')) {
            this.deliveryChoices = this.deliveryChoices.split(';').filter(x => x.toLocaleLowerCase().includes('paper')).join(';');
        }
    }
}
