export class Address {
    firstName: string;
    lastName: string;
    address1: string;
    address2: string;
    city: string;
    stateRegion: string;
    postalCode: string;
    country: string;
}

export const TEST_ADDRESS: Address = {
    firstName: 'firstname',
    lastName: 'lastname',
    address1: 'address 1',
    address2: '',
    city: 'city',
    stateRegion: 'st',
    postalCode: '54321',
    country: 'US'
};
