import { ChangeDetectorRef, Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RecaptchaComponent } from '../../../components/RecaptchaComponent/recaptcha.component';
import { IAccountPaymentResponse } from '../../../models/accountpaymentresponse';
import { ACHPaymentMethod, ACHPaymentMethodAccountType, ACHPaymentMethodPaymentSource } from '../../../models/achpaymentmethod';
import { Address } from '../../../models/address';
import { ConsumerPayment } from '../../../models/consumerpayment';
import { CreditCard } from '../../../models/creditcard';
import { IDomainInfo } from '../../../models/domaininfo';
import { FastTrackPaymentInfoResponse } from '../../../models/fasttrackpaymentinforesponse';
import { MDWiseAccount } from '../../../models/mdwiseaccount';
import { PaymentConfirmation } from '../../../models/paymentconfirmation';
import { PaymentMethod, PaymentMethodType } from '../../../models/paymentmethod';
import { PaymentSelectionStateModel } from '../../../models/paymentselectionmodel';
import { RealtimePayment } from '../../../models/realtimepayment';
import { ComponentService } from '../../../services/component/component.service';
import { ModifiedAccountPaymentStateService } from '../../../services/modifiedaccountpayment/modifiedaccountpayment.state.service';
import { PaymentService } from '../../../services/payment/payment.service';
import { PaymentSelectionStateService } from '../../../services/paymentselection/paymentselection.state.service';

@Component({
    selector: 'modified-account-payment',
    template: require('./modifiedaccountpaymentreview.component.html'),
    styles: [require('./modifiedaccountpaymentreview.component.css')]
})

export class ModifiedAccountPaymentReviewComponent implements OnInit {
    private ngUnsubscribe: Subject<any> = new Subject();

    receiptEmail: string;
    submission = false;
    resubmitPayment = true;
    paymentAuthorization = false;
    showEmailError = false;
    stepValidationMessages: string[];
    paymentError: string;
    paymentMethod: PaymentMethod = new PaymentMethod();

    paymentMethodText: string;
    headerText: string;
    subHeaderText: string;
    backButtonText: string;
    makePaymentButtonText: string;
    paymentDateText: string;
    paymentAuthText: string;
    paymentAuthErrorText: string;
    paymentRevocationText: string;
    paymentRevocationTimeRangeText: string;
    emailReceiptText: string;
    emailReceiptInfoText: string;
    fastTrackIDText: string;
    paymentAuthDisclaimerText: string;
    invalidEmailError: string;
    todaysDate = new Date();
    paymentDate: string = this.todaysDate.toISOString();
    navigatePrviousStep: string;
    navigateBeginning: string;
    freeFormLabelText: string;
    freeFormDescription: string;
    showFreeForm = false;
    companyName: string;
    payorTypeChoices: string;
    payorTypeLabelText: string;
    payorTypeDescriptionText: string;
    freeFormInput = '';
    payorType: string;
    payorTypeErrorText: string;
    domainInfo: IDomainInfo;

    // recaptcha
    @ViewChild('recaptchaCmp', { static: false }) recaptchaCmp: RecaptchaComponent;
    recaptchaAttempted = false;
    recaptchaErrorMessage = '';
    captchaToken = '';
    recaptchaError = false;

    // public stateService so we can use it in the UI directly
    constructor(
        public stateService: ModifiedAccountPaymentStateService,
        private paymentSelectionStateService: PaymentSelectionStateService,
        private componentService: ComponentService,
        private paymentService: PaymentService,
        private parentRouter: Router,
        private ngZone: NgZone,
        private changeDetectorRef: ChangeDetectorRef
    ) { }

    ngOnInit() {
        this.componentService.contentService.content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((content: any) => {
                this.companyName = this.componentService.contentService.tryGetContentItem(
                    content, 'help', 'contactUs', 'contactUsTitle').text;

                this.paymentRevocationTimeRangeText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'accountPayment', 'paymentRevocationLanguageTimeRange').text;
                this.headerText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'accountPayment', 'paymentStep3Header').text;
                this.subHeaderText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'accountPayment', 'paymentStep3SubHeader').text;
                this.backButtonText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'accountPayment', 'paymentBackButton').text;
                this.makePaymentButtonText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'accountPayment', 'paymentMakePaymentButton').text;
                this.emailReceiptText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'accountPayment', 'paymentEmailReceipt').text;
                this.emailReceiptInfoText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'accountPayment', 'paymentEmailReceiptInfo').text;
                this.paymentDateText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'accountPayment', 'paymentPaymentDateLabel').text;
                this.paymentMethodText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'accountPayment', 'paymentPaymentMethod').text;
                this.paymentAuthText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'accountPayment', 'paymentPaymentAuthorization').text;
                this.paymentAuthErrorText = this.componentService.contentService.tryGetContentItem(content, 'error', 'accountPayment', 'paymentPaymentAuthorizationError').text;
                this.paymentError = this.componentService.contentService.tryGetContentItem(content, 'error', 'payment', 'paymentGenericErrorMessage').text;
                this.fastTrackIDText = this.componentService.contentService.tryGetContentItem(content, 'confirmation', 'fastTrack', 'fastTrackIDText').text;
                this.paymentAuthDisclaimerText = this.componentService.contentService.tryGetContentItem(
                    content, 'payment', 'accountPayment', 'paymentPaymentAuthorizationDisclaimer').text;
                this.invalidEmailError = this.componentService.contentService.tryGetContentItem(content, 'error', 'accountPayment', 'paymentInvalidEmailError').text;
                this.freeFormLabelText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'accountPayment', 'paymentFreeFormField').text;
                this.freeFormDescription = this.componentService.contentService.tryGetContentItem(content, 'payment', 'accountPayment', 'paymentFreeFormFieldDescription').text;
                this.payorTypeLabelText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'accountPayment', 'paymentPayorTypeLabelText').text;
                this.payorTypeDescriptionText = this.componentService.contentService.tryGetContentItem(content, 'payment','accountPayment', 'paymentPayorTypeDescriptionText').text;
                this.payorTypeChoices = this.componentService.contentService.tryGetContentItem(content, 'payment', 'accountPayment', 'paymentPayorTypeChoices').text;
                this.payorTypeErrorText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'error', 'paymentPayorTypeErrorText').text;
                this.recaptchaErrorMessage = this.componentService.contentService.tryGetContentItem(content, 'error', 'accountPayment', 'accountPaymentRecaptchaError').text;

                this.setPaymentRevocationText(content);
                this.componentService.domainService.domainInfo$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(domainInfo => {
                    this.domainInfo = domainInfo;
                });
            });

        this.showFreeForm = !!this.freeFormLabelText;
        this.stepValidationMessages = [];

        // check if steps 1 & 2 are valid, if they're not, boot user back
        if (!this.stateService.amountStepValid || !this.stateService.methodStepValid) {
            this.ngZone.run(() => this.parentRouter.navigateByUrl(this.navigateBeginning)).then();
        }

        // use stateService componentstate to set up paymentMethod and other things we need!
        const state: PaymentSelectionStateModel = this.paymentSelectionStateService.paymentSelectionState;
        if (state && this.stateService.paymentMethodType === PaymentMethodType.credit) {
            this.paymentMethod.expirationDate = new Date(
                state.creditCardExpirationMonth + '/01/' + state.creditCardExpirationYear).toDateString();
            this.paymentMethod.description = 'CARD XXXX' + state.creditCardNumber.substr(state.creditCardNumber.length - 4);
            this.paymentMethod.cardType = this.componentService.getLogoByCard(state.creditCardNumber.split('-').join(''));
            this.paymentMethod.paymentMethodType = PaymentMethodType.credit;
        } else if (state && this.stateService.paymentMethodType === PaymentMethodType.ach) {
            this.paymentMethod.description = 'Bank Account XXXX' + state.achAccountNumber.substr(state.achAccountNumber.length - 4);
            this.paymentMethod.paymentMethodType = PaymentMethodType.ach;
        }
        this.receiptEmail = this.stateService.confirmationEmail;
        this.populateNavigationSteps();

        this.stateService.currentStep = 3;
    }

    async ngAfterViewInit() {
        if (this.recaptchaAttempted) {
            this.recaptchaCmp.reset();
        } else {
            this.recaptchaCmp.displayRecaptcha();
        }
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    prevStep() {
        this.stateService.confirmationEmail = this.receiptEmail;
        this.paymentAuthorization = false;
        this.resubmitPayment = true;
        this.ngZone.run(() => this.parentRouter.navigateByUrl(this.navigatePrviousStep)).then();
    }

    nextStep() {
        this.reviewPaymentValidation(true);
        if (this.stateService.reviewStepValid) {
            if (this.resubmitPayment) {
                this.submitAccountPayment();
            } else {
                this.stateService.reviewStepValid = false;
                this.addPageValidationMessage('You must make changes to resubmit payment');
            }
        }
    }

    submitAccountPayment(): void {
        this.startSubmission();
        const payment: ConsumerPayment = new ConsumerPayment();
        const state: PaymentSelectionStateModel = this.paymentSelectionStateService.paymentSelectionState;

        payment.PaymentSource = this.stateService.paymentSource;
        if (this.stateService.paymentMethodType === PaymentMethodType.ach) {
            payment.ACHPaymentMethod = new ACHPaymentMethod();
            payment.ACHPaymentMethod.paymentSource = ACHPaymentMethodPaymentSource.Web;
            payment.ACHPaymentMethod.AccountType = this.getAccountType(state.achAccountType);
            payment.ACHPaymentMethod.bankAccountNumber = state.achAccountNumber;
            payment.ACHPaymentMethod.routingNumber = state.achRoutingNumber;
        } else {
            payment.CreditCardPaymentMethod = new CreditCard();
            payment.CreditCardPaymentMethod.CardNumber = state.creditCardNumber.replace(/-/g, '');
            payment.CreditCardPaymentMethod.CVV = state.creditCardCvv;
            payment.CreditCardPaymentMethod.ExpirationDate = new Date(
                state.creditCardExpirationMonth + '/01/' + state.creditCardExpirationYear);
        }
        payment.Address = new Address();
        payment.RealtimeBalanceToPayOn = new RealtimePayment();
        payment.AccountName = this.stateService.nameOnAccount;
        payment.RealtimeBalanceToPayOn.PaymentAmount = this.stateService.paymentAmount;
        payment.StorePaymentMethod = false;
        payment.Address.firstName = state.firstName;
        payment.Address.lastName = state.lastName;
        payment.Address.address1 = state.address1;
        payment.Address.address2 = state.address2;
        payment.Address.city = state.city;
        payment.Address.stateRegion = state.state;
        payment.Address.postalCode = state.postalCode;
        payment.PaymentComment = this.stateService.comments;
        payment.PaymentLocationGUID = this.stateService.facility;
        payment.ConfirmationEmail = this.receiptEmail;
        payment.Language = window.navigator.language;
        payment.PhoneNumber = this.stateService.phoneNumber;
        payment.smsGUID = this.componentService.storageService.retrieve('smsGUID');
        payment.AccountID = this.stateService.customerAccountId;
        payment.PayorType = this.payorType;
        payment.CaptchaToken = this.captchaToken;

        if (!this.domainInfo.accountPaymentPrePopulateComments) {
            // ExternalID can be sent into the redirect as a query string parameter.
            const storedExternalID = this.componentService.storageService.retrieve('ExternalId');
            if (storedExternalID) {
                payment.ExternalPaymentID = storedExternalID;
            } else {
                payment.ExternalPaymentID = this.stateService.applicationID;
            }
        }

        if (this.freeFormLabelText.length > 0) {
            payment.CustomTextJSON = JSON.stringify({ [this.freeFormLabelText]: this.freeFormInput.trim() });
        }

        // set mode specific payment settings
        this.setPaymentSettingsFromPaymentMode(payment);

        this.paymentService.payAccount(payment, payment.CaptchaToken).then(paymentDone => {
            this.processPayment(paymentDone);
        }).catch(paymentError => {
            this.processPayment(paymentError);
        });
    }

    processPayment(payment: IAccountPaymentResponse): void {
        // clear waiting icon
        this.submission = false;
        if (payment.success) {
            // we'll use the consumer payment stuff and redirect
            this.setConfirmationSettingsFromPaymentMode(payment.data);

            this.paymentMethod = null;
            this.ngZone.run(() => this.parentRouter.navigateByUrl('/confirmation')).then();
        } else {
            this.stateService.reviewStepValid = false;
            this.resubmitPayment = false;
            if (payment.data && payment.data[0] && payment.data[0].message != null) {
                this.addPageValidationMessage(this.componentService.handleValidationErrorCode(payment.data[0].message));
            } else if (payment.messages && payment.messages.length > 0) {
                this.addPageValidationMessage(payment.messages.join('<br/>'));
            } else {
                this.addPageValidationMessage(this.paymentError);
            }
        }
    }

    setConfirmationSettingsFromPaymentMode(paymentDataModel: any) {
        if (paymentDataModel != null) {
            const paymentConfirmation = new PaymentConfirmation(false, false, false, false);
            const modifiedPaymentMode = this.componentService.storageService.retrieve('mode');
            if (modifiedPaymentMode === 'fasttrack') {
                const fasttrackresponse = this.componentService.storageService.retrieve('fasttrackresponse');
                paymentConfirmation.accountId = fasttrackresponse.fastTrackID;
                paymentConfirmation.confirmationNumber = paymentDataModel.processorID;
                    paymentConfirmation.additionalDisplayFields.push({ name: this.fastTrackIDText, value: fasttrackresponse.fastTrackID });
                this.componentService.storageService.clear('fasttrackresponse');
                this.componentService.storageService.clear('fasttrackguid');
            } else if (modifiedPaymentMode === 'inmdwise') {
                const careEnrollResponse: MDWiseAccount = this.componentService.storageService.retrieve('careenroll');
                paymentConfirmation.accountId = careEnrollResponse.applicationId;
                paymentConfirmation.confirmationNumber = paymentDataModel.processorID;
                    paymentConfirmation.additionalDisplayFields
                        .push({ name: this.fastTrackIDText, value: careEnrollResponse.applicationId });
                this.componentService.storageService.clear('careenroll');
            }
            paymentConfirmation.paymentAmount = this.stateService.paymentAmount;
            paymentConfirmation.paymentDate = new Date().toISOString();
            paymentConfirmation.paymentReferenceId = paymentDataModel.referenceID;
            paymentConfirmation.consumerPaymentGUID = paymentDataModel.consumerPaymentGUID;
            paymentConfirmation.surveyCompleted = false;
            // store information needed for confirmation page
            this.componentService.storageService.store('paymentconfirmation', paymentConfirmation);
        }
    }

    setPaymentSettingsFromPaymentMode(payment: ConsumerPayment) {
        const modifiedPaymentMode = this.componentService.storageService.retrieve('mode');
        if (modifiedPaymentMode === 'fasttrack') {
            const fasttrackresponse: FastTrackPaymentInfoResponse = this.componentService.storageService.retrieve('fasttrackresponse');
            // we need something in the accountID field for the API to be happy
            payment.AccountID = 'fasttrack';
            payment.FastTrackID = fasttrackresponse.fastTrackID;
        } else  if (modifiedPaymentMode === 'inmdwise') {
            const careEnrollResponse: MDWiseAccount = this.componentService.storageService.retrieve('careenroll');
            payment.DetailAccountID = careEnrollResponse.applicationId;
        }
    }

    reviewPaymentValidation(fromNextStep: boolean = false): void {
        this.stateService.reviewStepValid = true;
        this.stepValidationMessages = [];

        if (!this.recaptchaAttempted && fromNextStep) {
            this.addPageValidationMessage(this.recaptchaErrorMessage);
            this.recaptchaError = true;
        }

        if (this.payorTypeChoices && !this.payorType && fromNextStep) {
            this.addPageValidationMessage(this.payorTypeErrorText);
        }

        if (!this.paymentAuthorization) {
            this.addPageValidationMessage(this.paymentAuthErrorText);
        }
    }

    private addPageValidationMessage(msg: string) {
        this.stateService.reviewStepValid = false;
        if (this.stepValidationMessages.indexOf(msg) < 0) {
            this.stepValidationMessages.unshift(msg);
        }
    }

    private startSubmission(): void {
        this.submission = true;
        setTimeout(this.submissionTimeout, 30000);
    }

    private isSubmission(): boolean {
        return this.submission;
    }

    private submissionTimeout(): void {
        this.submission = false;
    }

    emailInputUpdated(event: any): void {
        this.showEmailError = true;
    }

    /**
     * Returns true if current selected payment is ACH (bank account)
     * Intended to be consumed by the template for determining when to display revocation language.
     * @memberof PaymentComponent
     */
    isSelectedPaymentMethodAch(): boolean {
        if (this.paymentSelectionStateService.paymentSelectionState &&
            this.paymentSelectionStateService.paymentSelectionState.selectedPaymentMethod) {
            return this.paymentSelectionStateService.paymentSelectionState
                .selectedPaymentMethod.paymentMethodType === PaymentMethodType.ach;
        }
        return false;
    }

    /**
     * Listens for the authorization checkbox checking and then validates step 3.
     *
     * @private
     * @param {} event
     * @memberof PaymentComponent
     */
    private authorizationCheckboxChecked(event: any) {
        // When we check the authorization box, check the step 3 validation.
        this.reviewPaymentValidation();
    }

    private getAccountType(achAccountType: string): ACHPaymentMethodAccountType {
        if (achAccountType.trim().split(' ')[1] === 'Savings') {
            return ACHPaymentMethodAccountType.Savings;
        } else if (achAccountType.trim().split(' ')[0] === 'Personal') {
            return ACHPaymentMethodAccountType.Checking;
        } else {
            return ACHPaymentMethodAccountType.BusinessChecking;
        }
    }

    private populateNavigationSteps(): void {
        const mode = this.componentService.storageService.retrieve('mode');
        if (mode !== 'fasttrack') {
            this.navigatePrviousStep = '/careenroll/method';
            this.navigateBeginning = '/careenroll/amount';
        } else {
            this.navigatePrviousStep = '/fasttrackpayment/method';
            this.navigateBeginning = '/fasttrackpayment/amount';
        }
    }

    private checkOneTimePayment() {
        return this.componentService.storageService.exists('onetimepayment');
    }

    /**
     * Helper for replacing content item placeholders on revocation text.
     * Sets value after finding-and-replacement in order to present the message in the template.
     * @memberof PaymentComponent
     */
    private setPaymentRevocationText(content: any): void {
        if (this.checkOneTimePayment()) {
            this.paymentRevocationText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'oneTimePayment', 'paymentRevocationLanguage').text;
        } else {
            this.paymentRevocationText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'loggedInPayment', 'paymentRevocationLanguage').text;
        }

        this.paymentRevocationText = this.paymentRevocationText.replace(/!CONTACTUSTITLE!/g, this.companyName);
        this.paymentRevocationText = this.paymentRevocationText.replace(/!TIMERANGE!/g, this.paymentRevocationTimeRangeText);
    }

    async handleCorrectCaptcha(token: string) {
        this.recaptchaAttempted = true;
        this.captchaToken = token;

        // The error message is sticky on this page...
        this.recaptchaError = false;
        this.reviewPaymentValidation();
        this.changeDetectorRef.detectChanges();
    }

    async handleCaptchaExpired(evt: any) {
        this.captchaToken = '';
        this.recaptchaError = true;
    }
}
