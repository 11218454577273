import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { PaymentBucket } from '../../../models/paymentbucket';

import { BasePaymentBucketComponent } from '../../Base/BasePaymentBucket/basepaymentbucket.component';

@Component({
    selector: 'payment-bucket-display',
    template: require('./paymentbucketdisplay.component.html'),
    styles: [require('./paymentbucketdisplay.component.css')]
})
export class PaymentBucketDisplayComponent extends BasePaymentBucketComponent implements OnInit {
    constructor(datePipe: DatePipe) {
        super(datePipe);
    }

    @Input() bucket: PaymentBucket;
    bucketChargeDetailsDescription: string;

    ngOnInit() {
        this.bucketChargeDetailsDescription = this.computeBucketChargeDetailsDescription(this.bucket);
    }
}
