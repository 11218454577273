/* tslint:disable:triple-equals */
import { Component, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IConsumer } from '../../../models/consumer';
import { ConsumerAccount } from '../../../models/consumeraccount';
import { IDomainInfo } from '../../../models/domaininfo';
import { PaymentMethod } from '../../../models/paymentmethod';
import { IProfileCompleteness } from '../../../models/profilecompleteness';
import { ComponentService } from '../../../services/component/component.service';
import { ConsumerService } from '../../../services/consumer/consumer.service';
import { LoggingLevel } from '../../../services/logging/logging.service';
import { BasePaymentMethod } from '../../Base/BasePaymentMethod/basepaymentmethod';

@Component({
    selector: 'account-profile',
    template: require('./accountprofile.component.html'),
    styles: [require('./accountprofile.component.css')]
})

export class AccountProfileComponent extends BasePaymentMethod implements OnInit, OnDestroy {
    @Input() emailAddress = '';
    @Input() quickPayOnly = false;
    domainInfo: IDomainInfo;
    consumer: IConsumer;
    consumerAccount: ConsumerAccount;
    consumerAccountLoading = true;
    loading = true;
    consumerLoading = true;
    walletLoading = true;
    textMessagingLoading = false;
    profileCompletenessLoading = false;
    updatePassword = false;
    updateEmail = false;
    showPasswordError = false;
    passwordResetError = false;
    showEmailError = false;
    showDeliveryMethodError = false;
    defaultWalletExpired = false;
    passwordResetSubject: Subject<any> = new Subject();
    profileCompleteness: IProfileCompleteness;
    hideAddress = false;

    header: string;
    subheading: string;
    accountNumberTitle: string;
    userNameText: string;
    addressText: string;
    passwordText: string;
    passwordUpdateText: string;
    passwordUpdateCancelText: string;
    passwordUpdateSubmitText: string;
    statementDeliveryText: string;
    statementLastSentText: string;
    mySecureWalletText: string;
    mySecureWalletUpdateText: string;
    noWalletText: string;  // also using for no email!
    walletMethods: PaymentMethod[];
    defaultWallet: PaymentMethod;
    initials = '';
    smsPhone = '';
    smsStatusActiveText: string;
    smsStatusInactiveText: string;
    emailErrorText: string;
    expirationDateFullText: string;
    completeYourProfileText: string;

    private ngUnsubscribe: Subject<any> = new Subject();

    constructor(
        private componentService: ComponentService,
        private consumerService: ConsumerService,
        private parentRouter: Router,
        private ngZone: NgZone
    ) {
        super(componentService);
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit() {
        this.componentService.contentService.content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((content: any) => {
                if (!this.quickPayOnly) {
                    this.header = this.componentService.contentService.tryGetContentItem(
                        content, 'profile', 'pageText', 'profilePageTitle').text;
                    this.subheading = this.componentService.contentService.tryGetContentItem(content, 'profile', 'pageText', 'profilePageSubheading').text;
                } else {
                    this.header = 'stuff';
                    // this.componentService.contentService.tryGetContentItem(content, "profile", "pageText", "profilePageTitle").text;
                    this.subheading = 'The best stuff around';
                    // this.componentService.contentService.tryGetContentItem(content, "profile", "pageText", "profilePageSubheading").text;
                }

                this.accountNumberTitle = this.componentService.contentService.tryGetContentItem(content, 'profile', 'pageText', 'profileAccountNumberTitle').text;
                this.userNameText = this.componentService.contentService.tryGetContentItem(
                    content, 'profile', 'pageText', 'profileUsernameText').text;
                this.passwordText = this.componentService.contentService.tryGetContentItem(
                    content, 'profile', 'pageText', 'profilePasswordText').text;
                this.passwordUpdateText = this.componentService.contentService.tryGetContentItem(content, 'profile', 'pageText', 'profilePasswordUpdateText').text;
                this.passwordUpdateSubmitText = this.componentService.contentService.tryGetContentItem(content, 'profile', 'pageText', 'profilePasswordUpdateSubmitText').text;
                this.passwordUpdateCancelText = this.componentService.contentService.tryGetContentItem(content, 'profile', 'pageText', 'profilePasswordUpdateCancelText').text;
                this.statementDeliveryText = this.componentService.contentService.tryGetContentItem(content, 'profile', 'pageText', 'profileStatementDeliveryText').text;
                this.mySecureWalletText = this.componentService.contentService.tryGetContentItem(content, 'profile', 'pageText', 'profilemySecureWalletText').text;
                this.mySecureWalletUpdateText = this.componentService.contentService.tryGetContentItem(content, 'profile', 'pageText', 'profileManageWalletText').text;
                this.noWalletText = this.componentService.contentService.tryGetContentItem(
                    content, 'profile', 'pageText', 'profileNoWalletText').text;
                this.statementLastSentText = this.componentService.contentService.tryGetContentItem(content, 'profile', 'pageText', 'profileStatementLastSentText').text;
                this.smsPhone = this.componentService.contentService.tryGetContentItem(content, 'profile', 'pageText', 'profileNoTextMessageText').text;
                this.smsStatusActiveText = this.componentService.contentService.tryGetContentItem(content, 'profile', 'pageText', 'profileSmsStatusActiveText').text;
                this.smsStatusInactiveText = this.componentService.contentService.tryGetContentItem(content, 'profile', 'pageText', 'profileSmsStatusInactiveText').text;
                this.expirationDateFullText = this.componentService.contentService.tryGetContentItem(content, 'profile', 'wallet', 'profileWalletExpirationDateFullText').text;
                this.completeYourProfileText = this.componentService.contentService.tryGetContentItem(content, 'profile', 'pageText', 'profileCompleteYourProfile').text;
            });

        this.componentService.domainService.domainInfo$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(domainInfo => {
            this.domainInfo = domainInfo;
            if (!!domainInfo) {
                this.consumerLoading = false;
            }
        });

        this.populateConsumer();
        this.setProfileCompleteness();

        // When a consumer SSO's in with a new FirstName and LastName, we do not display the address:
        this.hideAddress = !!this.componentService.storageService.retrieve('hideAddress');
    }
    toggleUpdatePassword(isCancel: boolean): void {
        this.updatePassword = !this.updatePassword;
    }

    submitUpdatePassword(): void {
        this.passwordResetSubject.next();
    }

    passwordValidChanged(hasPasswordResetError: boolean): void {
        this.updatePassword = hasPasswordResetError;
        this.passwordResetError = hasPasswordResetError;
    }

    getWalletMethodDescription(): string {
        if (this.defaultWallet == null) {
            return '';
        }
        if (this.defaultWallet.description != null && this.defaultWallet.description.trim() != '') {
            return this.defaultWallet.description;
        }
        if (this.defaultWallet.account != null && this.defaultWallet.account.trim() != '') {
            return this.defaultWallet.account;
        }
        return '';
    }

    private passwordInputUpdated(event: any): void {
        this.showPasswordError = true;
    }

    private manageWallet(event: any): void {
        this.ngZone.run(() => this.parentRouter.navigateByUrl('/wallet')).then();
    }

    /**
     * Retrieves and inits the consumer, consumer account, and wallet payment methods
     *
     * @public
     *
     * @memberof AccountProfileComponent
     */
    public populateConsumer(): void {
        this.consumerService.getConsumer().then(consumer => {
            this.consumer = consumer;
            if (this.quickPayOnly) {
                if (this.emailAddress.length > 0) {
                    this.consumer.emailAddress = this.emailAddress;
                }
            }
            this.consumerService.getConsumerAccount().then(consumerAccount => {
                if (!!consumerAccount) {
                    this.saveConsumerAccount(consumerAccount);
                }
            });
            this.consumerService.getWalletPaymentMethods().then(wallet => this.updateWallet(wallet));
        });
    }

    private saveConsumerAccount(account: ConsumerAccount): void {

        this.consumerAccount = account;
        this.initials = account.firstName.substr(0, 1) + account.lastName.substr(0, 1);

        this.addressText = !!this.consumerAccount &&
            (!!this.consumerAccount.address.address1 ? this.consumerAccount.address.address1 + ', ' : '')
            + (!!this.consumerAccount.address.address2 ? this.consumerAccount.address.address2 + ', ' : '')
            + (!!this.consumerAccount.address.city ? this.consumerAccount.address.city + ', ' : '')
            + this.consumerAccount.address.stateRegion + ' ' + this.consumerAccount.address.postalCode;

        this.consumerAccountLoading = false;
        this.textMessagingLoading = false;
    }

    private setProfileCompleteness(): void {
        this.profileCompletenessLoading = true;
        this.consumerService.getProfileCompleteness().then(completeness => {
            this.componentService.contentService.content$
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe((content: any) => {
                    const profileCompleteness: IProfileCompleteness = {
                        percentComplete: ComponentService.toDecimal(completeness.percentComplete * 100, 0),
                        nextFeatureName: this.componentService.contentService.tryGetContentItem(
                            content, 'profile', 'profileFeature', completeness.nextFeatureName).text,
                        nextFeaturePath: completeness.nextFeaturePath
                    };
                    this.profileCompleteness = profileCompleteness;
                    this.profileCompletenessLoading = false;
                });
        }).catch(err => {
            this.profileCompletenessLoading = false;
            this.componentService.loggingService.log('Error loading the Profile Widget', LoggingLevel.error, err);
        });
    }

    private setDefaultWallet(): void {
        if (!!this.walletMethods && this.walletMethods.length > 1) {
            const temp: PaymentMethod[] = this.walletMethods.filter(x => x.primary == true);

            // return the one marked as primary, or the first in the list if none of them are marked primary
            if (!!temp && temp.length > 0) {
                this.defaultWallet = temp[0];
                // check if primary wallet is expired
                this.defaultWalletExpired = this.isExpired(this.defaultWallet);
            } else {
                this.defaultWallet = null;
            }
        } else {
            this.defaultWallet = null;
        }
    }

    private updateWallet(wallet: PaymentMethod[]): void {
        this.walletMethods = wallet;
        this.setDefaultWallet();
        this.walletLoading = false;
    }

    private isLoading(): boolean {
        return this.consumerLoading || this.consumerAccountLoading || this.walletLoading;
    }

    private isSSO(): boolean {
        return this.quickPayOnly || this.componentService.isSSO(this.domainInfo);
    }

    private allowSecureWallet(): boolean {
        if (this.domainInfo && this.domainInfo.enableWallet && !this.quickPayOnly) {
            return true;
        }
        return false;
    }

    private showProfileAvatar(): boolean {
        if (!!this.profileCompleteness && !this.quickPayOnly) {
            return this.profileCompleteness.percentComplete == 100;
        }
        return false;
    }
}
