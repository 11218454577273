import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { ChangeName, ICommunicationRowChange } from '../../../../models/communicationPreferenceType';

@Component({
    selector: 'communication-preference-row',
    template: require('./communicationPreferenceRow.component.html'),
    styles: [require('./communicationPreferenceRow.component.css')]
})
export class CommunicationPreferenceRowComponent {
    @Output() changed: EventEmitter<ICommunicationRowChange[]> = new EventEmitter();

    @Input() noneToolTipMessage = '';
    @Input() headerText = '';

    // Prevents SMS Preference from being set
    @Input() stopSms = false;

    @Input() paperAllowed = false;
    @Input() emailAllowed = false;
    @Input() smsAllowed = false;

    @Input() paperEnabled = false;
    @Input() emailEnabled = false;
    @Input() smsEnabled = false;

    @Input() allowNone = false;
    @Input() allowMulti = false;

    @Input() emailToggle: ChangeName = 'paper';
    @Input() isAgreement = false;

    buttonClicked(which: ChangeName): boolean {
        let newOption;
        switch (which) {
            case 'paper':
                newOption = !this.paperEnabled;
                break;
            case 'email':
                newOption = !this.emailEnabled;
                break;
            case 'sms':
                newOption = !this.smsEnabled;
                break;
        }

        const changes = [{name: which, option: newOption}];

        if (newOption === false) {
            if (!this.allowNone && this.totalEnabled() <= 1) {
                if (this.totalAllowed() === 2) {
                    this.toggle(changes);
                } else {
                    return true; // at least one is required
                }
            }
        } else if (newOption === true) {
            if (!this.allowMulti) { // toggle
                this.toggle(changes);
            }
        }

        // This change in communication preferences is getting emitted
        // up to the CommunicationPreferencesComponent and then to the
        // AccountProfileComponent > updateCommunicationPreference()
        this.changed.emit(changes);
        return false;
    }

    /**
     * Include a toggle change in an existing list of changes,
     * this will check that the other option is actually allowed before
     * inserting the change
     * @param changes Current list of changes (1st element will be used to setup the toggle)
     */
    toggle(changes: ICommunicationRowChange[]) {
        const change = changes[0];
        if (change.name === 'email' && this.paperAllowed) {
            changes.push({name: 'paper', option: !change.option});
        }

        if (change.name === this.emailToggle && this.emailAllowed) {
            changes.push({name: 'email', option: !change.option});
        }

        // Recurring and Payment Plans isAgreement are defaulted to false until they're implemented
        if (change.name === 'email' && this.isAgreement) {
            changes.push({name: 'sms', option: !change.option});
        }

        if (change.name === 'sms' && this.isAgreement) {
            changes.push({name: 'email', option: !change.option});
        }
    }

    /**
     * Count the total options that are allowed.
     */
    totalAllowed(): number {
        let ret = 0;
        if (this.paperAllowed) {
            ret += 1;
        }

        if (this.emailAllowed) {
            ret += 1;
        }

        if (this.smsAllowed) {
            ret += 1;
        }

        return ret;
    }

    /**
     * Count the total options currently enabled
     */
    totalEnabled(): number {
        let ret = 0;
        if (this.paperEnabled) {
            ret += 1;
        }

        if (this.emailEnabled) {
            ret += 1;
        }

        if (this.smsEnabled) {
            ret += 1;
        }

        return ret;
    }

    onShownAllowNoneToolTip(allowNoneTooltip: PopoverDirective): void {
        setTimeout(() => {
            allowNoneTooltip.hide();
        }, (2000));
    }
}
