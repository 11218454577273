/* tslint:disable:triple-equals */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfig } from '../../app.config';
import { ConnectMessage, MessageType } from '../../models/connectmessage';
import { IConsumer } from '../../models/consumer';
import { GenericResponse, Response } from '../../models/genericresponse';
import { jsonRequestOptions } from '../../util/HttpClientUtility';
import { ComponentService } from '../component/component.service';
import { LoggingService } from '../logging/logging.service';


@Injectable()
export class ConnectMessageService {
    private token = '';
    private domain = '';
    private domainUrl = this.config.getConfig('domainInfo');
    private connectMessageUrl = this.config.getConfig('connectMessagePath');
    private connectMessagesUrl = this.config.getConfig('connectMessagesPath');
    private connectMessageImpressionUrl = this.config.getConfig('connectMessageImpressionPath');

    constructor(
        private http: HttpClient,
        private config: AppConfig,
        private componentService: ComponentService,
        private loggingService: LoggingService,
        private router: Router) {
        this.domain = this.componentService.storageService.getDomain();
    }

    /**
     * Get the connect message from the api, based on location
     *
     * @param {string} location the name from the tblPageLocation location
     * @returns {Promise<ConnectMessage>}  the connect messages
     * @memberof ConnectMessageService
     */
    public async getConnectMessage(location: string): Promise<ConnectMessage> {
        return this.callGetConnectMessageApi(location);
    }

    /**
     * Get the connect message from the api, based on location
     *
     * @param {string} location the name from the tblPageLocation location
     * @returns {Promise<ConnectMessage>}  the connect messages
     * @memberof ConnectMessageService
     */
    public async getConnectMessages(location: string): Promise<ConnectMessage[]> {
        try {
            const data = await this.callGetConnectMessagesApi(location);
            data.forEach(element => this.bindMessageType(element));
            return data;

        } catch (e) {
            // already logged in call above
            return [];
        }
    }

    /**
     * Get the imageURL for the connect message from the api, based on location
     *
     * @param {string} location the name from the tblPageLocation location
     * @returns {Promise<string>}  the imageURL
     * @memberof ConnectMessageService
     */
    public async getConnectMessageImageUrl(location: string): Promise<string> {
        const data = await this.callGetConnectMessageApi(location);
        this.bindMessageType(data);
        let imageUrl = '';
        if (data != null && data.imageID != 0) {
            imageUrl = this.getImageUrlFromImageID(data.imageID);
        }
        return imageUrl;
    }

    public getImageUrlFromImageID(imageID: number): string {
        return '/File/Connect/' + imageID;
    }

    /**
     * sends a click impression for this location to the ConsumerAPI
     *
     * @param {ConnectMessage} message the connect message to get the domainConnectMessageID out of
     * @returns {Promise<boolean>}  true if saved successfully
     * @memberof ConnectMessageService
     */
    public async sendClickImpression(message: ConnectMessage): Promise<boolean> {
        return this.callPutConnectMessageClickApi(message);
    }

    private async callGetConnectMessageApi(location: string): Promise<ConnectMessage> {
        let requireCredentials = '&requireCredentials=';
        // need to reset the local token property when calling the API, to avoid login/logout/expiring issues
        this.token = this.componentService.storageService.retrieve('token');
        // if no token, for this service we will call with nouser
        if (this.token == null || this.token.length <= 0) {
            this.token = 'nouser';
            requireCredentials += 'false';
        } else {
            requireCredentials += 'true';
        }

        let relativePath = this.token + '/' + this.connectMessageUrl;
        // add in location and domain
        relativePath += '?domain=' + this.domain + '&location=' + location;
        const url = this.domainUrl + '?relativePath=' + encodeURIComponent(relativePath) + requireCredentials;
        return this.http.get(url, jsonRequestOptions())
            .toPromise()
            .then((response: Response<ConnectMessage>) => response.body.data)
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    private async callGetConnectMessagesApi(location: string): Promise<ConnectMessage[] > {
        let requireCredentials = '&requireCredentials=';
        // need to reset the local token property when calling the API, to avoid login/logout/expiring issues
        this.token = this.componentService.storageService.retrieve('token');
        // if no token, for this service we will call with nouser
        if (this.token == null || this.token.length <= 0) {
            this.token = 'nouser';
            requireCredentials += 'false';
        } else {
            requireCredentials += 'true';
        }
        let relativePath = this.token + '/' + this.connectMessagesUrl;
        // add in location and domain
        relativePath += '?domain=' + this.domain + '&location=' + location;

        return this.http.get(this.domainUrl + '?relativePath=' + encodeURIComponent(relativePath)
            + requireCredentials, jsonRequestOptions())
            .toPromise()
            .then((response: Response<ConnectMessage[]>) => response.body.data)
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    private async callPutConnectMessageClickApi(message: ConnectMessage): Promise<boolean > {
        // need to reset the local token property when calling the API, to avoid login/logout/expiring issues
        this.token = this.componentService.storageService.retrieve('token');
        // if no token, for this service we will not call
        if (this.token != null && this.token.length <= 0) {
            return;
        }
        const consumer: IConsumer = this.componentService.storageService.retrieve('consumer');

        let relativePath = this.token + '/' + this.connectMessageImpressionUrl;
        // add in location and domain
        relativePath += '?domainConnectMessageID=' + message.domainConnectMessageID;

        return this.http.get(
            this.domainUrl + '?relativePath=' + encodeURIComponent(relativePath) + '&requireCredentials=true',
            jsonRequestOptions()
        ).toPromise()
            .then((response: Response<boolean>) => response.body.data)
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    bindMessageType(connectMessage: ConnectMessage): void {
        if (connectMessage != null) {
            if (connectMessage.messageType) {
                connectMessage.messageType = MessageType[connectMessage.messageType.toString().toLowerCase()];
            } else {
                connectMessage.messageType = MessageType.imageandtext;
            }
        }
    }
}
