import { Injectable } from '@angular/core';
import { IPaymentPlanBalance } from '../../models/paymentplanbalance';
import { RealTimeBalanceMode } from '../../models/realTimeBalanceMode';
import { ComponentService } from '../component/component.service';
import { ConsumerService } from '../consumer/consumer.service';
import { DomainInfoService } from '../domainInfo/domaininfo.service';
import { PaymentPlanService } from '../paymentplan/paymentplan.service';

@Injectable()
export class PayableService {
    constructor(
        private consumerService: ConsumerService,
        private domainService: DomainInfoService,
        private paymentPlanService: PaymentPlanService,
    ) {}

    async canMakePayment(): Promise<boolean> {
        const totalPayableAccountBalance = await this.getTotalPayable();
        if (totalPayableAccountBalance !== null) {
            return totalPayableAccountBalance > 0;
        }

        const domainInfo = await this.domainService.getDomainInfo();
        if (!domainInfo || !domainInfo.enablePayments) {
            return false;
        }

        const statementBalance = await this.consumerService.getStatementBalance(domainInfo.totalBalanceType);
        const stmtValue = ComponentService.toDecimal(statementBalance.balance);
        if (domainInfo.realtimeBalanceMode === RealTimeBalanceMode.None) {
            const merchantProfile = await this.consumerService.getMerchantProfileForDomain();
            return stmtValue > 0 || merchantProfile.allowNegativeDocumentOverpayments;
        }

        const realTimeBalance = await this.consumerService.getRealtimeBalance();
        if (realTimeBalance) {
            if (ComponentService.toDecimal(realTimeBalance.payableAmount) <= 0) {
                return false;
            }
            if (stmtValue === 0) {
                return ComponentService.toDecimal(realTimeBalance.payableAmount) > 0;
            }
            return true;
        }
        return stmtValue > 0;
    }

    /**
     * Gets the total payable on plan and off plan account
     * balances. Returns null when no balances are returned
     * from the consumer api
     * @Returns {number | null}
     */
    async getTotalPayable(): Promise<number | null> {
        const consumer = await this.consumerService.getConsumer();
        const balances = await this.paymentPlanService.getBalanceDetails(consumer.accountID, true);
        if (balances.length === 0) {
            return null;
        }
        const total = balances
                    .filter(b => b.isActive)
                    .reduce(this.balanceReducer, 0);
        return total;
    }

    balanceReducer(acc: number, b: IPaymentPlanBalance): number {
        return acc + (b.balance || 0);
    }
}
