import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { DomainInfoService } from '../domainInfo/domaininfo.service';

@Injectable()
export class QuickpayDeliveryProfileGuard implements CanActivate {

    constructor(private domainService: DomainInfoService,
                private router: Router) {
    }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        const path = route.url[1].path;
        const domainInfo = await this.domainService.getDomainInfo();

        if (domainInfo) {
            if (domainInfo.useConsolidatedCommunication) {
                if (path.toLowerCase() !== 'newdeliveryprofile') {
                    this.router.navigateByUrl('quickpay/newdeliveryprofile');
                    return false;
                }
            } else {
                if (path.toLowerCase() !== 'deliveryprofile') {
                    this.router.navigateByUrl('quickpay/deliveryprofile');
                    return false;
                }
            }

            return true;
        } else {
            return false;
        }
    }
}
