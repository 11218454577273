import { Component, Input, NgZone, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IConsumer } from '../../../models/consumer';
import { IDomainInfo } from '../../../models/domaininfo';
import { IOutage } from '../../../models/outage';
import { ComponentService } from '../../../services/component/component.service';
import { ConsumerService } from '../../../services/consumer/consumer.service';
import { LoggingLevel } from '../../../services/logging/logging.service';
import { LoginService } from '../../../services/login/login.service';
import { ConfirmationModalComponent } from '../ConfirmationModal/confirmationmodal.component';

@Component({
    selector: 'logged-in-header',
    template: require('./loggedinheader.component.html'),
    styles: [
        require('./loggedinheader.component.css'),
        require('../ActiveUser/activeuser.component.css')
    ],
    encapsulation: ViewEncapsulation.None
})

export class LoggedInHeaderComponent implements OnInit, OnDestroy {
    @Input() route = '';
    @Input() logoIsClickable = true;

    profileIsClickable = true;
    loggedIn = false;
    oneTimePayment = false;
    agentAssistedRole = false;
    secureEmailMode = false;
    customerLogoImageUrl: string;
    outageNotifications: IOutage[] = [];
    consumer: IConsumer;
    sessionTimer: any;
    sessionTimeoutModalContent: string;
    sessionTimeoutModalTitle: string;
    customHeaderHtml: string;
    customHeaderScript: string;
    customHeaderStyle: string;
    isCustomHeader: boolean;
    isIE = false;
    domainInfo: IDomainInfo;
    hasChat = false;
    initials = '';
    stopDoubleclick = false;

    constructor(
        private componentService: ComponentService,
        private consumerService: ConsumerService,
        private loginService: LoginService,
        private parentRouter: Router,
        private ngZone: NgZone
    ) { }

    private ngUnsubscribe: Subject<any> = new Subject();

    @ViewChild('sessionTimeout', { static: true }) sessionTimeoutModal: ConfirmationModalComponent;

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
        clearInterval(this.sessionTimer);
    }

    ngOnInit() {
        this.componentService.contentService.content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((content: any) => {
                this.customerLogoImageUrl = this.componentService.contentService.tryGetContentItem(content, 'header', 'image', 'headerCustomerLogoImage').text;
                this.sessionTimeoutModalContent = this.componentService.contentService.tryGetContentItem(content, 'global', 'error', 'globalSessionTimeoutModalContent').text;
                this.sessionTimeoutModalTitle = this.componentService.contentService.tryGetContentItem(content, 'global', 'error', 'globalSessionTimeoutModalTitle').text;
                this.customHeaderStyle = this.componentService.contentService.tryGetContentItem(content, 'header', 'link', 'customHeaderStyle').text;
                this.customHeaderHtml = this.componentService.contentService.tryGetContentItem(
                    content, 'header', 'link', 'customHeaderHTML').text;
                this.customHeaderScript = this.componentService.contentService.tryGetContentItem(content, 'header', 'link', 'customHeaderScript').text;
                this.isCustomHeader = !this.componentService.isIE()
                    && (this.isCustomContentItemValid(this.customHeaderHtml)
                        || this.isCustomContentItemValid(this.customHeaderScript)
                        || this.isCustomContentItemValid(this.customHeaderStyle));
            });

        this.isIE = this.componentService.isIE();
        this.loggedIn = this.componentService.userIsLoggedIn();
        this.oneTimePayment = this.componentService.storageService.exists('onetimepayment');
        this.agentAssistedRole = this.componentService.storageService.exists('agentassistedrole');

        if (this.loggedIn) {
            this.consumerService.getConsumer()
                .then(consumer => this.setConsumer(consumer));
        }

        this.componentService.domainService.getOutages()
            .then(outageList => {
                this.outageNotifications = outageList;
                if (outageList.some(x => new Date(x.outageStartDate) < new Date() && x.system === 'MySecureBill Maintenance')) {
                    if (!this.componentService.letMeIn()) {
                        this.ngZone.run(() => this.parentRouter.navigateByUrl('/outage')).then();
                    }
                }
            });

        this.secureEmailMode = this.componentService.storageService.retrieve('mode') === 'secureemail';

        if (this.loggedIn) {
            this.loginService.isUserSessionValid().then((valid) => {
                this.handleSessionCheckResult(valid);
            });
            this.sessionTimer = setInterval(() => {
                this.loginService.isUserSessionValid().then((valid) => {
                    this.handleSessionCheckResult(valid);
                }).catch(error => {
                    this.componentService.loggingService.log(error, LoggingLevel.error);
                    this.handleSessionCheckResult(false);
                });
            }, 300000); // checks if session is valid 5 minutes later (300000 ms)
        }

        this.observeDomainSettings();

        this.componentService.domainService.domainInfo$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(domainInfo => {
                this.domainInfo = domainInfo;
                this.hasChat = (!!domainInfo && !!domainInfo.chatGroup && !!domainInfo.chatPID);
            });
    }

    observeDomainSettings(): void {
        this.componentService.domainService.domainInfo$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(domainInfo => {
                this.profileIsClickable = !!domainInfo && domainInfo.enableProfileTab;
            });
    }

    setConsumer(consumer: IConsumer): void {
        this.consumer = consumer;
        if (this.consumer.isQuickPay) {
            this.loggedIn = false;
        }

        this.initials = this.consumer.firstName.substr(0, 1);
        this.initials += this.consumer.lastName.substr(0, 1);
    }

    /**
     * Does nothing for a valid session, but pops the modal and clears things for an invalid session
     *
     * @param {boolean} valid
     * @memberof LoggedInHeaderComponent
     */
    handleSessionCheckResult(valid: boolean) {
        if (!valid && !this.isANonLoggedInRoute()) {
            this.sessionTimeoutModal.openModal();
            this.componentService.storageService.clearSession();
            clearInterval(this.sessionTimer);
        }
    }

    /**
     * Function that gets called when the user clicks the "OK" button on the timeout modal
     *
     * @param {*} event
     * @memberof LoggedInHeaderComponent
     */
    sessionTimeoutComplete(event: any) {
        if (this.domainInfo.domainMode.includes('SSO') && this.domainInfo.ssoLogoutURL) {
            window.location.href = this.domainInfo.ssoLogoutURL;
        } else {
            this.ngZone.run(() => this.parentRouter.navigateByUrl('/')).then();
        }
    }

    isANonLoggedInRoute(): boolean {
        return this.parentRouter.url.includes('/default') || this.parentRouter.url === '/';
    }

    private isCustomContentItemValid(contentItem: string): boolean {
        if (contentItem.length > 0) {
            return (contentItem.match('missing_text_header') == null);
        } else {
            return false;
        }
    }

    goToProfile(): void {
        if (this.profileIsClickable) {
            this.ngZone.run(() => this.parentRouter.navigateByUrl('/profile')).then();
        }
    }

    async logMeOut() {
        if (!this.stopDoubleclick) {
            try {
                await this.loginService.logout();
            } catch (error) {
                alert(error);
            } finally {
                this.stopDoubleclick = false;

                if (this.domainInfo.domainMode.includes('SSO') && this.domainInfo.ssoLogoutURL) {
                    window.location.href = this.domainInfo.ssoLogoutURL;
                } else {
                    this.ngZone.run(() => this.parentRouter.navigateByUrl('/')).then();
                }
            }
        }

        this.stopDoubleclick = true;
    }
}
