import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IDomainInfo } from '../../../../models/domaininfo';
import { ValidationResult } from '../../../../models/validationresult';
import { ComponentService } from '../../../../services/component/component.service';
import { ConsumerService } from '../../../../services/consumer/consumer.service';
import { ConsumerStorageService } from '../../../../services/indexedDb/consumer-storage.service';
import { StorageService } from '../../../../services/storage/storage.service';
import { BaseReactiveFormComponent } from '../../../Base/BaseReactiveFormComponent/basereactiveform.component';
import { CheckboxComponent } from '../../Checkbox/checkbox.component';

@Component({
    selector: 'phone-form',
    template: require('./phoneform.component.html'),
    styles: [require('./phoneform.component.css')]
})
export class PhoneFormComponent extends BaseReactiveFormComponent implements OnInit {
    phoneNumbersValid = false;
    phonePinSubmitted = false;

    confirmPhoneValidationError$ = new BehaviorSubject<string>(null);
    newPhoneInvalidFormatMessage$ = new BehaviorSubject<string>(null);
    confirmPhoneInvalidFormatMessage$ = new BehaviorSubject<string>(null);
    successfulPINSentMessage$ = new BehaviorSubject<string>(null);
    errorSendingPINMessage$ = new BehaviorSubject<string>(null);
    verificationCodeErrorMessage$ = new BehaviorSubject<string>(null);
    successfulSaveMessage$ = new BehaviorSubject<string>(null);
    smsAuthorizationRequiredMessage$ = new BehaviorSubject<string>(null);
    phoneRequiredMessage$ = new BehaviorSubject<string>(null);
    confirmedPhoneRequiredMessage$ = new BehaviorSubject<string>(null);
    verificationCodeRequiredErrorMessage$ = new BehaviorSubject<string>(null);
    numberIsBlacklistedMessage$ = new BehaviorSubject<string>(null);

    domainInfo: IDomainInfo;
    customerAccountID: string;
    phone: string;

    // Content items
    phoneNumberLabel: string;
    confirmPhoneNumberLabel: string;
    sendPinButtonText: string;
    verificationCodeLabel: string;
    phoneMismatchErrorMessage: string;
    invalidPhoneFormatMessage: string;
    invalidConfirmPhoneFormatMessage: string;
    saveButtonText: string;
    backButtonText: string;
    verificationCodeSendSuccessMessage: string;
    verificationCodeSendErrorMessage: string;
    pinValidationSuccessMessage: string;
    pinValidationErrorMessage: string;
    agreementText: string;
    agreementFinePrint: string;
    authorizationRequiredMessage: string;
    phoneRequiredMessage: string;
    confirmedPhoneRequiredMessage: string;
    verificationCodeRequiredErrorMessage: string;
    numberIsBlacklistedMessage: string;

    @Input() saveButtonTextOverride: string = null;

    @Output() updatedPhoneNumber = new EventEmitter<string>();
    @Output() backButtonClicked = new EventEmitter();

    @ViewChild('smsAuthCheckbox') smsAuthCheckbox: CheckboxComponent;

    phoneMask: Array<string | RegExp> = this.componentService.phoneMask;

    constructor(private formBuilder: FormBuilder,
                cdRef: ChangeDetectorRef,
                private componentService: ComponentService,
                private consumerService: ConsumerService,
                private storageService: StorageService,
                private consumerStorageService: ConsumerStorageService) {
        super(cdRef);
    }

    async ngOnInit(): Promise<void> {
        this.componentService.domainService.domainInfo$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(domainInfo => {
                this.domainInfo = domainInfo;
            });

        this.setPhoneAndCAID(); // don't 'await' this here! multiple errors will be thrown because the form won't be built in time.
        this.setContentItems();
        this.buildForm();
    }

    async onSendVerificationCodeClicked(): Promise<void> {
        if (!this.isFormValidToGetPIN()) {
            this.handleValidationMessages(false);
            return;
        }

        this.phonePinSubmitted = true;

        try {
            const savedPhoneNumber = this.getSavedPhoneNumber();

            const blacklistResponse =
                await this.consumerService.verifyNumberNotBlacklisted(savedPhoneNumber);

            if (blacklistResponse.messages && blacklistResponse.messages.length) {
                this.clearAllMessages();
                this.numberIsBlacklistedMessage$.next(this.numberIsBlacklistedMessage);
                return;
            } else {
                this.numberIsBlacklistedMessage$.next(null);
            }

            await this.consumerService.sendForSmsConfirmationPin(
                this.customerAccountID,
                savedPhoneNumber
            );

            this.handlePINSuccessMessaging();
        } catch (e) {
            this.handlePINErrorMessaging();
        }
    }

    // Remove masked characters before checking for
    // unsubscribed, validating SMS PIN, and saving.
    private getSavedPhoneNumber(): string {
        return this.formGroup.get('phoneNumber').value.replace(/\D+/g, '');
    }

    private handlePINSuccessMessaging() {
        this.phonePinSubmitted = false;

        this.successfulPINSentMessage$.next(this.verificationCodeSendSuccessMessage);
        this.errorSendingPINMessage$.next(null);
    }

    private handlePINErrorMessaging(): void {
        this.phonePinSubmitted = false;

        this.errorSendingPINMessage$.next(this.verificationCodeSendErrorMessage);
        this.successfulPINSentMessage$.next(null);
    }

    private handleValidationMessages(isValidForSendPin: boolean): void {
        this.clearAllMessages();
        this.formGroup.updateValueAndValidity();

        if (!this.formGroup.get('smsAuthorized').value) {
            this.smsAuthorizationRequiredMessage$.next(this.authorizationRequiredMessage);
        } else {
            this.smsAuthorizationRequiredMessage$.next(null);
        }

        if (!this.formGroup.get('phoneNumber').value) {
            this.phoneRequiredMessage$.next(this.phoneRequiredMessage);
        } else {
            this.phoneRequiredMessage$.next(null);
        }

        if (!this.formGroup.get('confirmPhoneNumber').value) {
            this.confirmedPhoneRequiredMessage$.next(this.confirmedPhoneRequiredMessage);
        } else {
            this.confirmedPhoneRequiredMessage$.next(null);
        }

        if (this.formGroup.errors && this.formGroup.errors.validateConfirmPhone) {
            this.confirmPhoneValidationError$.next(this.phoneMismatchErrorMessage);
        } else {
            this.confirmPhoneValidationError$.next(null);
        }

        if (isValidForSendPin) {
            if (!this.formGroup.get('verificationCode').value) {
                this.verificationCodeRequiredErrorMessage$.next(this.verificationCodeRequiredErrorMessage);
            } else {
                this.verificationCodeRequiredErrorMessage$.next(null);
            }
        }
    }

    async onSubmit(): Promise<void> {
        if (this.formGroup.status === 'INVALID') {
            this.handleValidationMessages(true);
            return;
        }

        this.successfulPINSentMessage$.next(null);
        // the SMS API's validate SMS PIN endpoint will not only validate the PIN,
        // it'll save the phone number to tblConsumerAccounts!
        try {
            const response = await this.consumerService.validateSmsPin(
                this.customerAccountID,
                this.getSavedPhoneNumber(),
                this.formGroup.get('verificationCode').value
            );

            if (!response.success) {
                this.clearAllMessages();
                if (response.messages && response.messages.length) {
                    const messages = response.messages.join('\n');
                    this.verificationCodeErrorMessage$.next(messages);
                } else {
                    this.verificationCodeErrorMessage$.next(this.pinValidationErrorMessage);
                }
                return;
            }

            this.clearAllMessages();
            this.phone = this.formGroup.get('phoneNumber').value;
            this.successfulSaveMessage$.next(this.pinValidationSuccessMessage);
            this.updatedPhoneNumber.emit(this.phone);

            if (this.domainInfo.useConsolidatedCommunication) {
                // Update the Consumer phone value in the IndexedDB storage
                await this.consumerStorageService.updateConsumer({ phone: this.phone });
            }

            // We are currently shimming the tblCustomerGuarantor's phone
            // number into the ConsumerAccount object (inside ConsumerApi),
            // so while (as UCC=1) the phone should never be referencing
            // the ConsumerAccount local storage value I am setting it here
            // anyway because if I don't the phone number shown in the
            // ConsumerAccount object in local storage will not match the
            // one being set to the IndexedDB value. I'm doing so to avoid
            // confusion to any devs in the future who may notice this
            // and wonder why they don't match.
            await this.consumerService.updateConsumerAccountStorage();
        } catch (e) {
            this.clearAllMessages();
            this.verificationCodeErrorMessage$.next(this.pinValidationErrorMessage);
        }
    }

    onSmsAuthToggled(checkbox: CheckboxComponent): void {
        this.formGroup.get('smsAuthorized').patchValue(checkbox.checkboxModel);
        if (checkbox.checkboxModel) {
            this.smsAuthorizationRequiredMessage$.next(null);
        }
    }

    private async setPhoneAndCAID(): Promise<void> {
        if (this.domainInfo.useConsolidatedCommunication) {
            const consumer = await this.consumerService.getConsumer();

            this.customerAccountID = consumer.accountID;
            this.phone = consumer.phone;
        } else {
            const consumerAccount = await this.consumerService.getConsumerAccount();

            this.customerAccountID = consumerAccount.customerAccountID;
            this.phone = consumerAccount.mobilePhone;
        }

        this.patchPhoneNumber();
    }

    protected buildForm(): void {
        this.formGroup = this.formBuilder.group({
            phoneNumber: new FormControl(
                null,
                {
                    validators: [
                        Validators.required,
                        Validators.pattern(new RegExp(/^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/))
                    ],
                    updateOn: 'blur'
                }),
            confirmPhoneNumber: new FormControl(
                null,
                {
                    validators: [
                        Validators.required,
                        Validators.pattern(new RegExp(/^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/))
                    ],
                    updateOn: 'blur'
                }),
            smsAuthorized: new FormControl(
                null,
                {validators: Validators.required, updateOn: 'blur'}
            ),
            verificationCode: new FormControl(
                null,
                {validators: Validators.required, updateOn: 'blur'}
            )
        }, {validators: this.validateConfirmPhone});

        this.formGroup.statusChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(validity => {
            this.setPhoneNumberValidity();
            this.setIsFormValid(validity);
            this.handleFormValidityChanges();
        });
    }

    private setPhoneNumberValidity() {
        this.phoneNumbersValid =
            this.formGroup.get('phoneNumber').valid && this.formGroup.get('confirmPhoneNumber').valid;
    }

    private patchPhoneNumber(): void {
        this.formGroup.get('phoneNumber').patchValue(this.phone);
        this.formGroup.get('confirmPhoneNumber').patchValue(this.phone);
    }

    private isFormValidToGetPIN() {
        const phoneValid = this.formGroup.get('phoneNumber').status === 'VALID';
        const confirmPhoneValid = this.formGroup.get('confirmPhoneNumber').status === 'VALID';
        const formGroupValid = !(this.formGroup.errors && this.formGroup.errors.validateConfirmPhone);
        const smsAuthValid = this.formGroup.get('smsAuthorized').value;

        return phoneValid && confirmPhoneValid && formGroupValid && smsAuthValid;
    }

    private validateConfirmPhone(phoneFormGroup: AbstractControl): ValidationResult {
        const phoneControl: AbstractControl = phoneFormGroup.get('phoneNumber');
        const confirmPhoneControl: AbstractControl = phoneFormGroup.get('confirmPhoneNumber');

        if (phoneControl.value && phoneControl.value.trim()) {
            if (confirmPhoneControl.value && confirmPhoneControl.value.trim()) {
                if (phoneControl.value.trim().toLocaleLowerCase() ===
                    confirmPhoneControl.value.trim().toLocaleLowerCase()) {
                    // everything valid; confirmPhone equals phone
                    return null;
                } else {
                    // confirmPhone doesn't match
                    return {validateConfirmPhone: {valid: false}};
                }
            }
        }

        return null;
    }

    private handleFormValidityChanges(): void {
        this.clearAllMessages();

        if (!this.isFormValid) {
            const validationMessages = this.getFormValidationErrors();

            if (this.formGroup.errors && this.formGroup.errors.validateConfirmPhone) {
                this.confirmPhoneValidationError$.next(this.phoneMismatchErrorMessage);
            } else {
                this.confirmPhoneValidationError$.next(null);
            }

            const invalidPhoneFormatErrors = validationMessages.filter(m => m.errorKey === 'pattern');
            if (invalidPhoneFormatErrors.length) {
                if (invalidPhoneFormatErrors.find(m => m.controlName === 'phoneNumber')) {
                    this.newPhoneInvalidFormatMessage$.next(this.invalidPhoneFormatMessage);
                } else {
                    this.newPhoneInvalidFormatMessage$.next(null);
                }
                if (invalidPhoneFormatErrors.find(m => m.controlName === 'confirmPhoneNumber')) {
                    this.confirmPhoneInvalidFormatMessage$.next(this.invalidConfirmPhoneFormatMessage);
                } else {
                    this.confirmPhoneInvalidFormatMessage$.next(null);
                }
            } else {
                this.confirmPhoneInvalidFormatMessage$.next(null);
                this.newPhoneInvalidFormatMessage$.next(null);
            }
        }
    }

    back() {
        this.clearAllMessages();
        this.setPhoneAndCAID();
        this.smsAuthCheckbox.checkboxModel = false;
        this.formGroup.get('verificationCode').reset();
        this.backButtonClicked.emit();
    }

    private setContentItems(): void {
        const category = 'communicationpreferences';
        const subCategory = 'pageText';

        this.componentService.contentService.content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(c => {
                this.phoneNumberLabel = this.componentService.contentService.tryGetContentItem(c, category, subCategory, 'phoneFormPhoneNumberFieldLabel').text;
                this.confirmPhoneNumberLabel = this.componentService.contentService.tryGetContentItem(c, category, subCategory, 'phoneFormConfirmPhoneNumberFieldLabel').text;
                this.sendPinButtonText = this.componentService.contentService.tryGetContentItem(c, category, subCategory, 'phoneFormSendPINButtonText').text;
                this.verificationCodeLabel = this.componentService.contentService.tryGetContentItem(c, category, subCategory, 'phoneFormVerificationCodeFieldLabel').text;
                this.phoneMismatchErrorMessage = this.componentService.contentService.tryGetContentItem(c, category, subCategory, 'phoneFormPhoneMismatchErrorMessage').text;
                this.invalidPhoneFormatMessage = this.componentService.contentService.tryGetContentItem(c, category, subCategory, 'phoneFormInvalidPhoneFormatErrorMessage').text;
                this.invalidConfirmPhoneFormatMessage = this.componentService.contentService.tryGetContentItem(c, category, subCategory, 'phoneFormInvalidConfirmPhoneNumber').text;
                this.saveButtonText = this.componentService.contentService.tryGetContentItem(c, category, subCategory, 'phoneFormSaveButtonText').text;
                this.backButtonText = this.componentService.contentService.tryGetContentItem(c, 'payment', 'loggedInPayment', 'paymentBackButton').text;
                this.verificationCodeSendSuccessMessage = this.componentService.contentService.tryGetContentItem(c, category, subCategory, 'phoneFormPINSendSuccessMessage').text;
                this.verificationCodeSendErrorMessage = this.componentService.contentService.tryGetContentItem(c, category, subCategory, 'phoneFormPINSendErrorMessage').text;
                this.pinValidationSuccessMessage = this.componentService.contentService.tryGetContentItem(c, category, subCategory, 'phoneFormPINValidationSuccessMessage').text;
                this.pinValidationErrorMessage = this.componentService.contentService.tryGetContentItem(c, category, subCategory, 'phoneFormPINValidationErrorMessage').text;
                this.agreementText = this.componentService.contentService.tryGetContentItem(c, category, subCategory, 'phoneFormAgreementText').text;
                this.authorizationRequiredMessage = this.componentService.contentService.tryGetContentItem(c, category, subCategory, 'phoneFormAuthorizationRequiredMessage').text;
                this.phoneRequiredMessage = this.componentService.contentService.tryGetContentItem(c, category, subCategory, 'phoneFormPhoneRequiredMessage').text;
                this.confirmedPhoneRequiredMessage = this.componentService.contentService.tryGetContentItem(c, category, subCategory, 'phoneFormConfirmPhoneRequiredMessage').text;
                this.verificationCodeRequiredErrorMessage = this.componentService.contentService.tryGetContentItem(c, category, subCategory, 'phoneFormVerificationCodeRequired').text;
                this.numberIsBlacklistedMessage = this.componentService.contentService.tryGetContentItem(c, category, subCategory, 'phoneFormSmsNumberBlacklistedText').text;

                // do not change last param to ..TermsOfService; it is being stored as ..TermsofService
                this.agreementFinePrint = this.componentService.contentService.tryGetContentItem(c, category, subCategory, 'phoneFormSummaryTermsofService')
                    .text.replace('!SECUREBILLDOMAIN!', this.storageService.getDomain());

                if (!!this.saveButtonTextOverride) {
                    this.saveButtonText = this.saveButtonTextOverride;
                }
            });
    }

    private clearAllMessages(): void {
        this.verificationCodeRequiredErrorMessage$.next(null);
        this.verificationCodeErrorMessage$.next(null);
        this.phoneRequiredMessage$.next(null);
        this.smsAuthorizationRequiredMessage$.next(null);
        this.confirmedPhoneRequiredMessage$.next(null);
        this.confirmPhoneValidationError$.next(null);
        this.confirmPhoneInvalidFormatMessage$.next(null);
        this.newPhoneInvalidFormatMessage$.next(null);
        this.phoneRequiredMessage$.next(null);
        this.successfulSaveMessage$.next(null);
        this.errorSendingPINMessage$.next(null);
        this.successfulPINSentMessage$.next(null);
        this.numberIsBlacklistedMessage$.next(null);
    }
}
