import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DateFormatPipe } from '../../../../pipes/DateFormat/dateformat.pipe';
import { ComponentService } from '../../../../services/component/component.service';

@Component({
    selector: 'statement-balance',
    template: require('./statementbalance.component.html'),
    styles: [require('./statementbalance.component.css')]
})
export class StatementBalanceComponent implements OnInit, OnDestroy {

    constructor(
        private componentService: ComponentService,
        private datePipe: DateFormatPipe
    ) {}

    statementHeadlineText: string;
    statementSubHeaderText: string;
    ngOnInitHasRun = false;

    private ngUnsubscribe: Subject<any> = new Subject();

    @Input() statementBalance: number;
    @Input() balanceAsOf: string;

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit() {
        // Called after the constructor, initializing input properties, and the first call to ngOnChanges.
        // Add 'implements OnInit' to the class.
        this.componentService.contentService.content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((content: any) => {
                this.statementHeadlineText =
                    this.componentService.contentService.tryGetContentItem(
                        content,
                        'home',
                        'statement',
                        'homePageStatementHeadline'
                    ).text;

                this.statementSubHeaderText =
                    this.componentService.contentService.tryGetContentItem(
                        content,
                        'home',
                        'statement',
                        'homePageStatementDashboardSubHeader'
                    ).text;
            });

        this.ngOnInitHasRun = true;
    }

    ngOnChanges() {
        // This will run before ngOnInit and we won't have balanceAsOf at that point.
        if (this.ngOnInitHasRun) {
            // The "switch" for this is the Customer adding the !BALANCEDUEASOF! macro to this content item.
            const showBalanceDueAsOf = this.statementHeadlineText.includes('!BALANCEDUEASOF!');

            if (showBalanceDueAsOf) {
                // Could not get a date for whatever reason, so just show today's date.
                if (!this.balanceAsOf) {
                    this.balanceAsOf = this.datePipe.transform(new Date(), 'MM/DD/y');
                }

                this.statementHeadlineText = this.statementHeadlineText.replace('!BALANCEDUEASOF!', this.balanceAsOf);
            }
        }
    }
}
