/* tslint:disable:triple-equals */
import { CurrencyPipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IDomainInfo } from '../../../models/domaininfo';
import { IMerchantProfile } from '../../../models/imerchantprofile';
import { IPaymentLocation } from '../../../models/PaymentLocation';
import { ComponentService } from '../../../services/component/component.service';
import { ConsumerService } from '../../../services/consumer/consumer.service';

@Component({
    selector: 'account-payment-entry',
    template: require('./accountpaymententry.component.html'),
    styles: [require('./accountpaymententry.component.css')]
})
export class AccountPaymentEntryComponent implements OnInit, OnDestroy {
    @Input() locationList: IPaymentLocation[] = [];
    facilityText: string;
    accountNumberText: string;
    nameOnAccountText: string;
    paymentAmountText: string;
    commentsText: string;
    selectALocationText: string;
    paymentAmount = 0.00;
    paymentAmountError: boolean;
    paymentAmountErrorMessage: string;
    paymentAmountMinimumError: boolean;
    facility: string;
    facilityErrorText: string;
    accountNumber: string;
    accountNumberError: boolean;
    nameOnAccount: string;
    comments: string;
    merchantProfile: IMerchantProfile;
    requiredText: string;
    invalidText: string;
    locationListError: boolean;
    nameOnAccountError: boolean;
    domainInfo: IDomainInfo;
    maxCCPaymentErrorText: string;
    maxCCPaymentTextToModify: string;
    phoneNumber: string;
    phoneNumberLabel = '';
    minimumPaymentErrorMessage: string;

    private ngUnsubscribe: Subject<any> = new Subject();

    constructor(
        private componentService: ComponentService,
        private consumerService: ConsumerService,
        private currencyPipe: CurrencyPipe
    ) { }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit() {
        this.componentService.contentService.content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((content: any) => {
                this.facilityText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'accountPayment', 'facilityTitleLabel').text;
                this.accountNumberText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'accountPayment', 'accountNumberLabel').text;
                this.nameOnAccountText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'accountPayment', 'nameOnAccountTitleLabel').text;
                this.paymentAmountText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'accountPayment', 'paymentAmountTitleLabel').text;
                this.commentsText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'accountPayment', 'commentsTitleLabel').text;
                this.selectALocationText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'accountPayment', 'selectALocationLabel').text;
                this.paymentAmountErrorMessage = this.componentService.contentService.tryGetContentItem(content, 'error', 'accountPayment', 'paymentAmountAmountRequiredErrorText').text;
                this.facilityErrorText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'accountPayment', 'facilityRequiredLabel').text;
                this.maxCCPaymentTextToModify = this.componentService.contentService.tryGetContentItem(content, 'error', 'accountPayment', 'paymentMaxCreditCardAmountWarningText').text;
                this.requiredText = this.componentService.contentService.tryGetContentItem(
                    content, 'global', 'error', 'isRequiredError').text;
                this.invalidText = this.componentService.contentService.tryGetContentItem(
                    content, 'global', 'error', 'isInvalidError').text;
                this.phoneNumberLabel = this.componentService.contentService.tryGetContentItem(content, 'payment', 'accountPayment', 'phoneNumberLabel').text;
                this.minimumPaymentErrorMessage = this.componentService.contentService.tryGetContentItem(content, 'payment', 'error', 'minimumPaymentWarningText').text;
            });
        this.facility = null;

        this.componentService.domainService.domainInfo$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                domainInfo => {
                    this.domainInfo = domainInfo;
                });
    }

    updateFacility(location: IPaymentLocation) {
        this.consumerService.getMerchantProfileByMerchantProfileGUID(location.merchantProfileGUID)
            .then(merchantProfile => {
                this.merchantProfile = merchantProfile;
                if (merchantProfile != null) {
                    this.maxCCPaymentErrorText = this.maxCCPaymentTextToModify.replace(
                        '{0}', this.currencyPipe.transform(merchantProfile.maxCCPaymentAmount, 'USD', 'symbol', '1.2-2'));
                }
            });
        this.facility = location.paymentLocationGUID;
    }

    locationChange() {
        const location = this.locationList.find(x => x.paymentLocationGUID == this.facility);
        this.consumerService.getMerchantProfileByMerchantProfileGUID(location.merchantProfileGUID)
            .then(merchantProfile => {
                this.merchantProfile = merchantProfile;
                if (merchantProfile != null) {
                    this.maxCCPaymentErrorText = this.maxCCPaymentTextToModify.replace(
                        '{0}', this.currencyPipe.transform(merchantProfile.maxCCPaymentAmount, 'USD', 'symbol', '1.2-2'));
                }
                if (this.paymentAmount > 0) {
                    this.paymentAmountChange();
                }
            });
        if (!!this.accountNumber) {
            this.checkAccountNumberEntry();
        }
        this.locationListError = false;
    }

    getRegularExpressionToTest(location: IPaymentLocation): RegExp {
        let regexFlag: string;
        let accountRegEx = location.accountNumberRegEx;
        if (location.accountNumberRegEx.endsWith('/i')) {
            accountRegEx = location.accountNumberRegEx.split('/')[0];
            regexFlag = location.accountNumberRegEx.split('/')[1];
        }
        let regularExpression: RegExp;
        if (regexFlag != null) {
            regularExpression = new RegExp(accountRegEx, regexFlag);
        } else {
            regularExpression = new RegExp(accountRegEx);
        }
        return regularExpression;
    }

    // onFocus of the AccountNumber input
    resetAccountNumberError() {
        this.accountNumberError = false;
    }

    // onblur of the AccountNumber input
    checkAccountNumberEntry() {
        if (this.domainInfo.showAccountPaymentLocationsMenu) {
            if (this.facility == null) {
                this.locationListError = true;
                return;
            }
            const location = this.locationList.find(x => x.paymentLocationGUID == this.facility);
            const regularExpression = this.getRegularExpressionToTest(location);
            this.accountNumberError = !regularExpression.test(this.accountNumber);
        } else {
            for (const location of this.locationList) {
                const regularExpression = this.getRegularExpressionToTest(location);
                if (regularExpression.test(this.accountNumber)) {
                    this.updateFacility(location);
                    return;
                }
            }
            // falling through to here means that we didn't match any of the possible regexp's
            this.accountNumberError = true;
        }
    }

    accountEntryIsValid(): boolean {
        this.locationListError = false;
        this.accountNumberError = false;
        this.nameOnAccountError = false;
        this.paymentAmountError = false;
        this.paymentAmountMinimumError = false;

        if (this.facility == null) {
            this.locationListError = true;
        }
        if (!this.accountNumber || this.checkAccountNumberEntry()) {
            this.accountNumberError = true;
        }
        if (!this.nameOnAccount) {
            this.nameOnAccountError = true;
        }
        if (this.paymentAmount <= 0) {
            this.paymentAmountError = true;
        } else {
            if (this.merchantProfile != null &&
                this.paymentAmount > this.merchantProfile.maxCCPaymentAmount &&
                !this.merchantProfile.allowACHAccountPayments) {

                this.paymentAmountError = true;
            }
        }
        if (this.paymentAmount < this.merchantProfile.minimumDocumentPaymentAmount) {
            this.paymentAmountMinimumError = true;
        }

        // has no errors (all errors false)
        return !(this.locationListError || this.accountNumberError || this.nameOnAccountError || this.paymentAmountError ||
            this.paymentAmountMinimumError);
    }

    nameChanged(): void {
        this.nameOnAccountError = false;
        if (!this.nameOnAccount) {
            this.nameOnAccountError = true;
        }
    }

    private getMinimumPaymentErrorMessage(minimumPayment: number): string {
        return this.minimumPaymentErrorMessage.replace(
            '!MINIMUMPAYMENTAMOUNT!',
            this.currencyPipe.transform(minimumPayment, 'USD', 'symbol')
        );
    }

    paymentAmountChange(): void {
        this.paymentAmountError = false;
        this.paymentAmountMinimumError = false;
        if (this.paymentAmount <= 0) {
            this.paymentAmountError = true;
        }
        if (this.merchantProfile != null &&
            this.paymentAmount > this.merchantProfile.maxCCPaymentAmount &&
            !this.merchantProfile.allowACHAccountPayments) {

            this.paymentAmountError = true;
        }
        if (this.merchantProfile != null &&
            this.paymentAmount < this.merchantProfile.minimumDocumentPaymentAmount) {
            this.minimumPaymentErrorMessage =
                this.getMinimumPaymentErrorMessage(this.merchantProfile.minimumDocumentPaymentAmount);
            this.paymentAmountMinimumError = true;
        }
    }
}
