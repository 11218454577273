import { IMerchantProfile } from './imerchantprofile';

export class PayableItem {
    id: string; // uuid
    itemType: PayableItemType;
    itemName: string;
    itemDescription: string;
    payableAmount: number;
    postDate: Date;
    dueDate: Date;
    paymentAmount: number;
    payToItem: boolean;
    merchantCredentialGUID: string;
    merchantProfileGUID: string;
    merchantProfile: IMerchantProfile;
    hasScheduledPayment: boolean;
    maxPayableAmount: number;
    isAccountPaymentPlanEligible: boolean;
    displayField?: string;
    paymentLocationGUID: string;
    initialPayment: boolean;
    initialPaymentAmount: number;

    constructor() {
    }
}

export enum PayableItemType {
    // currentbalance = 1, deprecated
    statement = 2,
    paymentplan = 3,
    paymentplanbalance = 4,
    unknown = 0
}

export const TEST_PAYABLE_ITEM: PayableItem = {
    id: '1234',
    itemType: PayableItemType.unknown,
    itemName: 'testItem',
    itemDescription: 'test',
    payableAmount: 1,
    postDate: new Date(),
    dueDate: new Date(),
    paymentAmount: 0,
    payToItem: false,
    merchantProfileGUID: 'guid-1-guid',
    merchantProfile: null,
    hasScheduledPayment: false,
    maxPayableAmount: 100,
    merchantCredentialGUID: 'guid-2-guid',
    isAccountPaymentPlanEligible: true,
    displayField: 'Optionally display this value',
    paymentLocationGUID: null,
    initialPayment: false,
    initialPaymentAmount: 0
};
