import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ComponentService } from '../../../services/component/component.service';
import { LoginService } from '../../../services/login/login.service';

@Component({
    selector: 'msb-wrapper',
    template: require('./msbwrapper.component.html'),
    styles: [require('./msbwrapper.component.css')]
})
export class MSBWrapperComponent implements OnInit, OnDestroy {
    backgroundImage: string;
    blurSessionTimeout = false;

    private ngUnsubscribe: Subject<any> = new Subject();

    constructor(private componentService: ComponentService, private loginService: LoginService) {
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit() {

        this.componentService.contentService.content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((content: any) => {
                this.backgroundImage = this.componentService.contentService.tryGetContentItem(content, 'global', 'image', 'defaultBackgroundImage').text;
            });

        this.loginService.sessionTimeout$.subscribe((timeout: boolean) => {
            this.blurSessionTimeout = timeout;
        });
    }

    isIE(): boolean {
        return this.componentService.isIE();
    }
}
