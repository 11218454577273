import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CommunicationPreference, ICommunicationPreferenceChange } from '../../../../models/communicationPreferenceType';
import { IConsumer } from '../../../../models/consumer';
import { GuarantorEmailViewModel } from '../../../../models/guarantoremailviewmodel';
import { ValidationResult } from '../../../../models/validationresult';
import { ComponentService } from '../../../../services/component/component.service';
import { ConsumerService } from '../../../../services/consumer/consumer.service';
import { ConsumerStorageService } from '../../../../services/indexedDb/consumer-storage.service';
import { BaseReactiveFormComponent } from '../../../Base/BaseReactiveFormComponent/basereactiveform.component';

@Component({
    selector: 'email-form',
    template: require('./emailform.component.html'),
    styles: [require('./emailform.component.css')]
})
export class EmailFormComponent extends BaseReactiveFormComponent implements OnInit {
    showEmailSelection = false;
    emailPinSubmitted = false;

    selectedEmail: GuarantorEmailViewModel;
    showEmailForm = false;
    showCancelButton = true;
    emailAddress: string;
    consumer: IConsumer;

    // Content Items
    emailLabel: string;
    addNewEmailLabel: string;
    cancelButtonText: string;
    saveButtonText: string;
    backButtonText: string;
    saveEmailSuccessMessage: string;
    saveEmailErrorMessage: string;
    invalidEmailFormatMessage: string;
    emailRequiredMessage: string;
    verificationCodeLabel: string;
    sendPinButtonText: string;
    continueButtonText: string;
    verificationCodeRequiredErrorMessage: string;
    verificationCodeSentMessage: string;
    verificationCodeErrorMessage: string;

    saveEmailSuccessful$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    saveEmailError$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    invalidEmailFormatMessage$ = new BehaviorSubject<string>(null);
    emailRequiredMessage$ = new BehaviorSubject<string>(null);
    verificationCodeSuccessful$ = new BehaviorSubject<string>(null);
    verificationCodeErrorMessage$ = new BehaviorSubject<string>(null);

    @Input() emailViewModels: GuarantorEmailViewModel[];
    @Input() multipleEmailsInstructions: string;
    @Input() singleEmailEditInstructions: string;
    @Input() isQuickPay = false;
    @Input() accountVerificationId: string;
    @Input() deliveryMethod: string;
    @Input() showVerificationCode = false;
    @Input() verificationCode$ = new BehaviorSubject<string>('');

    @Output() emailUpdated = new EventEmitter<string>();
    @Output() backButtonClicked = new EventEmitter();

    constructor(private formBuilder: FormBuilder,
                cdRef: ChangeDetectorRef,
                private componentService: ComponentService,
                private consumerStorageService: ConsumerStorageService,
                private consumerService: ConsumerService) {
        super(cdRef);
    }

    async ngOnInit(): Promise<void> {
        this.setContentItems();
        this.buildForm();
        this.setEmailSelection();
        this.consumer = await this.consumerService.getConsumer();
    }

    async onSubmit(): Promise<void> {
        if (this.formGroup.status === 'INVALID') {
            if (!this.showEmailForm ||
                (this.showEmailForm && !this.formGroup.get('emails').get('newEmail').value)) {
                this.emailRequiredMessage$.next(this.emailRequiredMessage);
            }

            return;
        }

        if (this.showEmailForm) {
            if (!this.isQuickPay) {
                await this.updateAccountPreferences(this.consumer, this.formGroup.get('emails').get('newEmail').value);
            } else {
                await this.updateAccountPreferencesByVerificationId(this.formGroup.get('emails').get('newEmail').value);
            }
        } else {
            if (!this.isQuickPay) {
                await this.updateAccountPreferences(this.consumer, this.formGroup.get('preferredEmail').value.emailAddress);
            } else {
                await this.updateAccountPreferencesByVerificationId(this.formGroup.get('preferredEmail').value.emailAddress);
            }
        }
    }

    private async updateAccountPreferences(consumer: IConsumer, emailAddress: string): Promise<void> {
        try {
            // strange casting -- or lack thereof -- of response going on in this consumer service method...
            // what's really being returned is a response model of a different shape entirely.
            // grabbing the data by indexers to make the compiler whine less.
            const response: any = await this.consumerService.updateConsumerAccountPreferences(
                consumer.accountID,
                emailAddress,
                'Electronic');

            await this.handleUpdatePreferencesResponse(response, emailAddress);
        } catch (e) {
            this.saveEmailError$.next(true);
            this.saveEmailSuccessful$.next(false);
        }
    }

    private async handleUpdatePreferencesResponse(response: any, emailAddress: string) {
        if (response.body.success) {
            this.removeAllMessages();

            this.saveEmailSuccessful$.next(true);
            this.saveEmailError$.next(false);
            this.emailUpdated.emit(emailAddress);
            await this.setEmailIndexedDbStorage(emailAddress);

            if (!this.showEmailForm) {
                // a choice was made from the presented options. move them into the single email flow.
                // also, hide the cancel button so as to prevent them from getting back to the radio buttons
                this.redirectToSingleEmailForm(emailAddress);
            } else {
                this.saveEmailError$.next(false);
                this.saveEmailSuccessful$.next(true);
            }
        } else {
            if (this.showVerificationCode && !!response.body.messages && response.body.messages.length > 0)
            {
                this.verificationCodeErrorMessage$.next(this.componentService.handleValidationErrorCode(response.body.messages[0]));
            } else {
                this.saveEmailError$.next(true);
                this.saveEmailSuccessful$.next(false);
            }
        }
    }

    // Update the email IndexedDB storage value so it is available
    // to the Consumer Profile or any other place its needed
    private async setEmailIndexedDbStorage(newValue: string) {
        await this.consumerStorageService.updateConsumer({emailAddress: newValue});
    }

    private async updateAccountPreferencesByVerificationId(emailAddress: string): Promise<void> {
        try {
            const response = await this.consumerService.updateConsumerAccountPreferencesByVerificationID(
                this.accountVerificationId,
                emailAddress,
                this.deliveryMethod,
                false
            );

            await this.handleUpdatePreferencesResponse(response, emailAddress);
        } catch (e) {
            this.saveEmailError$.next(true);
            this.saveEmailSuccessful$.next(false);
        }
    }

    private redirectToSingleEmailForm(emailAddress: string): void {
        this.toggleEmailForm();
        this.formGroup.get('emails').get('newEmail').patchValue(emailAddress);
        this.showCancelButton = false;
        this.saveEmailSuccessful$.next(true);
    }

    toggleEmailForm() {
        this.removeAllMessages();

        this.showEmailForm = !this.showEmailForm;
        this.showEmailSelection = !this.showEmailSelection;
        this.formGroup.reset();

        if (this.showEmailForm) {
            this.formGroup.get('emails').updateValueAndValidity();
        } else {
            this.clearFormGroupValidators(this.formGroup.get('emails') as FormGroup);
        }
    }

    private setEmailSelection(): void {
        if (this.emailViewModels && this.emailViewModels.length > 1) {
            this.showEmailSelection = true;
        } else if (this.emailViewModels.length <= 1) {
            this.showEmailForm = true;
            this.showCancelButton = false;
            this.patchEmail();
        }
    }

    private patchEmail(): void {
        if (this.emailViewModels && this.emailViewModels.length) {
            this.formGroup.get('emails').get('newEmail').patchValue(this.emailViewModels[0].emailAddress);
        } else {
            this.formGroup.get('emails').get('newEmail').patchValue('');
        }
    }

    protected buildForm(): void {
        this.formGroup = this.formBuilder.group({
            preferredEmail: [null],
            emails: this.formBuilder.group({
                newEmail: new FormControl(null, {updateOn: 'blur'}),
                verificationCode: new FormControl('')
            }, {validators: this.validateConfirmEmail.bind(this) as ValidatorFn})
        });

        this.formGroup.statusChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(validityStatus => {
            this.setIsFormValid(validityStatus);
        });

        this.formGroup.get('emails').statusChanges
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(s => {
                const emailFormGroup = this.formGroup.get('emails');
                if (s.toLocaleLowerCase() === 'invalid') {
                    if (emailFormGroup.errors && emailFormGroup.errors.validEmail) {
                        this.invalidEmailFormatMessage$.next(this.invalidEmailFormatMessage);
                    } else {
                        this.invalidEmailFormatMessage$.next(null);
                    }
                } else {
                    this.invalidEmailFormatMessage$.next(null);
                }
            });

        this.formGroup.get('emails').valueChanges.pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(() => this.removeAllMessages());

        this.verificationCode$.subscribe(val => {
            this.formGroup.get('emails').get('verificationCode').setValue(val);
        })
    }

    private setContentItems(): void {
        const category = 'communicationpreferences';
        const subCategory = 'pageText';

        this.componentService.contentService.content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(c => {
                this.emailLabel = this.componentService.contentService.tryGetContentItem(c, category, subCategory, 'emailFormEmailFieldLabel').text;
                this.cancelButtonText = this.componentService.contentService.tryGetContentItem(c, category, subCategory, 'emailFormCancelButtonText').text;
                this.saveButtonText = this.componentService.contentService.tryGetContentItem(c, category, subCategory, 'emailFormSaveButtonText').text;
                this.backButtonText = this.componentService.contentService.tryGetContentItem(c, 'payment', 'loggedInPayment', 'paymentBackButton').text;
                this.addNewEmailLabel = this.componentService.contentService.tryGetContentItem(c, category, subCategory, 'emailFormAddNewEmailLabel').text;
                this.saveEmailSuccessMessage = this.componentService.contentService.tryGetContentItem(c, category, subCategory, 'emailFormSaveEmailSuccessMessage').text;
                this.saveEmailErrorMessage = this.componentService.contentService.tryGetContentItem(c, category, subCategory, 'emailFormSaveEmailErrorMessage').text;
                this.invalidEmailFormatMessage = this.componentService.contentService.tryGetContentItem(c, category, subCategory, 'emailFormInvalidEmailMessage').text;
                this.emailRequiredMessage = this.componentService.contentService.tryGetContentItem(c, category, subCategory, 'emailFormEmailRequiredMessage').text;
                this.verificationCodeLabel = this.componentService.contentService.tryGetContentItem(c, category, subCategory, 'phoneFormVerificationCodeFieldLabel').text;
                this.verificationCodeRequiredErrorMessage = this.componentService.contentService.tryGetContentItem(c, category, subCategory, 'phoneFormVerificationCodeRequired').text;
                this.sendPinButtonText = this.componentService.contentService.tryGetContentItem(c, category, subCategory, 'phoneFormSendPINButtonText').text;
                this.continueButtonText =  this.componentService.contentService.tryGetContentItem(c, 'payment', 'loggedInPayment', 'paymentContinueButton').text;
                this.verificationCodeSentMessage = this.componentService.contentService.tryGetContentItem(c, category, subCategory, 'phoneFormPINSendSuccessMessage').text;
                this.verificationCodeErrorMessage = this.componentService.contentService.tryGetContentItem(c, category, subCategory, 'phoneFormPINValidationErrorMessage').text;
            });
    }

    validateConfirmEmail(emailGroup: AbstractControl): ValidationResult {
        if (!this.showEmailForm) {
            return null;
        } else {
            if (emailGroup) {
                const newEmailControl: AbstractControl = emailGroup.get('newEmail');

                if (newEmailControl.value && newEmailControl.value.trim()) {
                    if (this.componentService.emailRegex.test(newEmailControl.value.toLocaleLowerCase())) {
                        // valid
                        return null;
                    } else {
                        // invalid
                        return {validEmail: {valid: false}};
                    }
                } else {
                    return {emailRequired: {valid: false}};
                }
            }

            return null;
        }
    }

    back() {
        this.removeAllMessages();
        this.setEmailSelection();
        this.formGroup.get('emails').get('verificationCode').reset();
        this.backButtonClicked.emit();
    }

    async onSendVerificationCodeClicked() {
        if (this.formGroup.invalid) {
            this.formGroup.get('emails').updateValueAndValidity();
            this.emailRequiredMessage$.next(this.emailRequiredMessage);
            return;
        }

        this.emailPinSubmitted = true;

        this.removeAllMessages();
        this.emailAddress = this.formGroup.get('emails').get('newEmail').value;
        if (!this.formGroup.get('emails').get('newEmail').value) {
            this.emailPinSubmitted = false;
            this.emailRequiredMessage$.next(this.emailRequiredMessage);
        } else {
            this.consumerService.sendVerificationCode(this.consumer.accountID, this.emailAddress)
            .then(response => {
                this.emailPinSubmitted = false;

                if (!!response.body && response.body.success) {
                    this.verificationCodeSuccessful$.next(this.verificationCodeSentMessage);
                } else {
                    this.verificationCodeErrorMessage$.next(this.verificationCodeErrorMessage);
                }
            })
            .catch(err => {
                this.emailPinSubmitted = false;

                this.verificationCodeErrorMessage$.next(this.verificationCodeErrorMessage);
            });
        }
    }

    async onContinue() {
        this.removeAllMessages();
        const enteredEmailAddress = this.formGroup.get('emails').get('newEmail').value;
        if (!this.formGroup.get('emails').get('newEmail').value) {
            this.emailRequiredMessage$.next(this.emailRequiredMessage);
        } else if (!this.formGroup.get('emails').get('verificationCode').value) {
            this.verificationCodeErrorMessage$.next(this.verificationCodeRequiredErrorMessage);
        } else if (this.emailAddress !== enteredEmailAddress) {
            this.verificationCodeErrorMessage$.next(this.verificationCodeErrorMessage);
        } else {
            const code = this.formGroup.get('emails').get('verificationCode').value.trim();
            const prefChanges: ICommunicationPreferenceChange[] = this.componentService.storageService.retrieve('prefChanges');

            prefChanges.push({
                customerAccountID: this.consumer.accountID,
                type: CommunicationPreference.StatementEmail,
                newValue: true
            });

            const response = await this.consumerService.validateVerificationCode(
                this.consumer.accountID,
                this.emailAddress,
                code,
                this.accountVerificationId,
                prefChanges
            );

            this.handleUpdatePreferencesResponse(response, this.emailAddress);
        }
    }

    private removeAllMessages(): void {
        this.saveEmailSuccessful$.next(false);
        this.saveEmailError$.next(false);
        this.invalidEmailFormatMessage$.next(null);
        this.emailRequiredMessage$.next(null);
        this.verificationCodeSuccessful$.next(null);
        this.verificationCodeErrorMessage$.next(null);
    }
}

