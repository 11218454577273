/* tslint:disable:triple-equals */
import { Component, EventEmitter, Input, NgZone, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'msb-link',
    template: require('./msblink.component.html'),
    styles: [require('./msblink.component.css')]
})
export class MSBLinkComponent {
    constructor(
        private parentRouter: Router,
        private ngZone: NgZone
    ) { }

    @Input() linkRoute = '$';
    @Input() linkText: string;
    @Input() align: string;
    @Input() size = 'default';
    @Input() styleOverride: any;
    @Input() linkTarget = '';
    @Input() linkId = '';
    @Input() color = 'default';
    @Input() inverseIconColors = false;
    @Input() isDisabled = false;
    @Input() iconName = 'glyphicon-menu-right';
    @Input() settings = '';
    @Input() isMobile = false;

    @Output()
    public linkFunction: EventEmitter<MSBLinkComponent> = new EventEmitter<MSBLinkComponent>();

    /**
     * You can't use both the linkRoute and the linkFunction because the route will route before
     * your function comes close to completing.  This has been proven
     *
     * @param {*} event
     *
     * @memberof MSBLinkComponent
     */
    linkClick(event: any) {
        if (!this.isDisabled) {
            this.linkFunction.emit();
            if (this.linkRoute != '$') {
                if (this.linkTarget != '') {
                    if (this.isMobile) {
                        this.settings = '';
                    }
                    window.open(this.linkRoute, this.linkTarget, this.settings);
                } else {
                    this.ngZone.run(() => this.parentRouter.navigateByUrl(this.linkRoute)).then();
                }
            }
        }
    }
}
