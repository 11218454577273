import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IConsumer } from '../../../models/consumer';
import { IDomainInfo } from '../../../models/domaininfo';
import { GenerateUsernameRequest } from '../../../models/generateusernamerequest';
import { GuarantorEmailViewModel } from '../../../models/guarantoremailviewmodel';
import { QuickPayEnrollment } from '../../../models/quickpayenrollment';
import { ComponentService } from '../../../services/component/component.service';
import { ConsumerService } from '../../../services/consumer/consumer.service';
import { ConsumerContactDetailsModalComponent } from '../ConsumerContactDetailsModal/consumercontactdetailsmodal.component';

@Component({
    selector: 'enrollment-profile',
    template: require('./enrollmentprofile.component.html'),
    styles: [require('./enrollmentprofile.component.css')]
})

export class EnrollmentProfileComponent implements OnInit, OnDestroy {
    @Input() set pendingEnrollmentEmail(email: string) {
        this.email = email;
    }
    @Input() firstName: string;
    @Input() lastName: string;
    @Input() quickPayEnrollment: QuickPayEnrollment;
    @Input() quickPayOnly = false;

    @ViewChild('contactDetailsModal', { static: false }) contactDetailsModal: ConsumerContactDetailsModalComponent;

    domainInfo: IDomainInfo;
    consumer: IConsumer;
    consumerAccountLoading = true;
    header: string;
    subheading: string;
    loading = true;
    accountNumberTitle = 'Account Number';
    userNameText = 'Username';
    passwordText = 'Password';
    emailText = 'Email';
    statementDeliveryText = 'Statement Delivery';
    textMessagesText = 'Text Messages';
    mySecureWalletText = 'mySecureWallet®';
    eDeliveryText = 'e-Delivery';
    noneText = 'None';
    initials = '';
    confirmText = 'Confirm';
    deliveryChoices = 'Paper';
    deliveryTerms: string;
    passwordRulesText: string;
    password: string;
    passwordConfirm: string;
    passwordMatchError = false;
    passwordMatchErrorText: string;
    passwordRequiredError = false;
    passwordLengthError = false;
    passwordLengthErrorText: string;
    passwordValidationError = false;
    passwordValidationErrorText: string;
    passwordSpaceError = false;
    passwordSpaceErrorText: string;
    email: string;
    emailConfirm: string;
    emailValidationError = false;
    emailValidationErrorText: string;
    emailMatchErrorText: string;
    emailMatchError = false;
    emailRequiredError = false;
    username: string;
    usernameRequiredError = false;
    deliveryType: string;
    deliveryTypeError = false;
    isRequiredText: string;
    deliveryTypeErrorText: string;
    usernameValidationErrorText: string;
    usernameValidationError = false;
    usernameExists = false;
    usernameExistsErrorText: string;
    usernameContainsSpecialCharsErrorText: string;
    usernameSpecialCharsError = false;
    isConsolidatedCommunicationEnabled: boolean;
    changeLinkText: string;
    enableContactDetailsModal = false;

    private ngUnsubscribe: Subject<any> = new Subject();

    // Calls the function passed in when it's changed passing the checkbox that was clicked
    @Output() public blurMethod: EventEmitter<string> = new EventEmitter<string>();

    constructor(private componentService: ComponentService, private parentRouter: Router, private consumerService: ConsumerService) {
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    async ngOnInit(): Promise<void> {
        this.componentService.contentService.content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((content: any) => {
                if (!this.quickPayOnly) {
                    this.header = this.componentService.contentService.tryGetContentItem(content, 'enrollment', 'pageText', 'enrollmentProfilePageTitle').text;
                    this.subheading = this.componentService.contentService.tryGetContentItem(content, 'enrollment', 'pageText', 'enrollmentProfilePageSubheading').text;
                } else {
                    // quick Pay only
                    this.header = this.componentService.contentService.tryGetContentItem(content, 'modifiedenrollment', 'pageText', 'modifiedEnrollmentHeadline').text;
                    this.subheading = this.componentService.contentService.tryGetContentItem(content, 'modifiedenrollment', 'pageText', 'modifiedEnrollmentSubHeadline').text;
                }

                this.accountNumberTitle = this.componentService.contentService.tryGetContentItem(content, 'enrollment', 'pageText', 'enrollmentProfileAccountNumberTitle').text;
                this.userNameText = this.componentService.contentService.tryGetContentItem(content, 'enrollment', 'pageText', 'enrollmentProfileUsernameText').text;
                this.passwordText = this.componentService.contentService.tryGetContentItem(content, 'enrollment', 'pageText', 'enrollmentProfilePasswordText').text;
                this.statementDeliveryText = this.componentService.contentService.tryGetContentItem(content, 'enrollment', 'pageText', 'enrollmentProfileStatementDeliveryText').text;
                this.emailText = this.componentService.contentService.tryGetContentItem(content, 'enrollment', 'pageText', 'enrollmentProfileEmailText').text;
                this.eDeliveryText = this.componentService.contentService.tryGetContentItem(content, 'enrollment', 'pageText', 'enrollmentProfileeDeliveryText').text;
                this.confirmText = this.componentService.contentService.tryGetContentItem(content, 'enrollment', 'pageText', 'enrollmentProfileConfirmText').text;
                this.deliveryChoices = this.componentService.contentService.tryGetContentItem(content, 'profile', 'pageText', 'profileDeliveryMethodChoices').text;
                this.deliveryTerms = this.componentService.contentService.tryGetContentItem(content, 'enrollment', 'pageText', 'enrollmentDeliveryTermsAndConditions').text;
                this.passwordRulesText = this.componentService.contentService.tryGetContentItem(content, 'enrollment', 'pageText', 'enrollmentPasswordRules').text;
                this.passwordMatchErrorText = this.componentService.contentService.tryGetContentItem(content, 'enrollment', 'error', 'enrollmentProfilePasswordErrorText').text;
                this.passwordValidationErrorText = this.componentService.contentService.tryGetContentItem(
                    content, 'enrollment', 'error', 'enrollmentPasswordValidationErrorText').text;
                this.passwordSpaceErrorText = this.componentService.contentService.tryGetContentItem(content, 'enrollment', 'error', 'enrollmentProfilePasswordSpacesErrorText').text;
                this.passwordLengthErrorText = this.componentService.contentService.tryGetContentItem(content, 'enrollment', 'error', 'enrollmentProfilePasswordLengthErrorText').text;
                this.emailMatchErrorText = this.componentService.contentService.tryGetContentItem(content, 'enrollment', 'error', 'enrollmentProfileEmailErrorText').text;
                this.emailValidationErrorText = this.componentService.contentService.tryGetContentItem(content, 'enrollment', 'error', 'enrollmentProfileEmailValidationErrorText').text;
                this.deliveryTypeErrorText = this.componentService.contentService.tryGetContentItem(content, 'enrollment', 'error', 'enrollmentProfileDeliveryTypeErrorText').text;
                this.isRequiredText = this.componentService.contentService.tryGetContentItem(
                    content, 'global', 'error', 'isRequiredError').text;
                this.usernameValidationErrorText = this.componentService.contentService.tryGetContentItem(
                    content, 'enrollment', 'error', 'enrollmentUsernameValidationErrorText').text;
                this.usernameExistsErrorText = this.componentService.contentService.tryGetContentItem(content, 'enrollment', 'error', 'enrollmentUsernameAlreadyExistsErrorText').text;
                this.usernameContainsSpecialCharsErrorText = this.componentService.contentService.tryGetContentItem(content, 'enrollment', 'error', 'enrollmentUsernameBadCharsErrorText').text;
                this.changeLinkText = this.componentService.contentService.tryGetContentItem(content, 'enrollment', 'pageText', 'enrollmentChangeEmailText').text;
            });

        this.componentService.domainService.domainInfo$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
            domainInfo => {
                this.domainInfo = domainInfo;
                if (this.domainInfo) {
                    this.isConsolidatedCommunicationEnabled = this.domainInfo.useConsolidatedCommunication;
                }
            });

        await this.populateConsumer();
        if (this.quickPayEnrollment) {
             this.generateUsername();
         }
    }

    /**
     * populates the consumer
     *
     * @memberof EnrollmentProfileComponent
     */
    public async populateConsumer(): Promise<void> {
        if (this.componentService.userIsLoggedIn()) {
            const consumer = await this.consumerService.getConsumer();
            if (!!consumer) {
                this.saveConsumerAccount(consumer);
            }
            if (!!this.domainInfo) {
                if (!this.domainInfo.enableDeliveryBoth) {
                    this.deliveryChoices = this.deliveryChoices.split(';').filter(x => !x.includes('&')).join(';');
                }
                if (this.domainInfo.deliveryMode.toLocaleLowerCase().includes('paper')) {
                    this.deliveryChoices = this.deliveryChoices.split(';').filter(x => x.toLocaleLowerCase().includes('paper')).join(';');
                } else {
                    if (this.quickPayOnly) {
                        this.deliveryChoices = 'Electronic:Electronic';
                        this.deliveryType = 'Electronic';
                    }
                }

                if (this.isConsolidatedCommunicationEnabled) {
                    // the modal will try to make calls to get the current consumer's emails, etc.,
                    // even when the modal is not visible. since those endpoints require authentication,
                    // we should prevent the modal from doing so until we have a token
                    // (by means of MEM code auth, for example)
                    this.enableContactDetailsModal = true;
                }
            }
        }
    }

    /**
     * checks the username to see if exists already.
     *
     * @memberof EnrollmentProfileComponent
     */
    public checkUsername(): void {
        this.usernameExists = false;
        this.consumerService.checkIfUsernameExists(this.username).then(
            (response) => {
                if (response != null) {
                    this.usernameExists = response;
                    // before displaying the error, make sure there are not trimable spaces
                    if (!!this.usernameExistsErrorText && this.usernameExistsErrorText.length > 0) {
                        this.usernameExistsErrorText = this.usernameExistsErrorText.trim();
                    }
                    if (!response) { this.blurMethod.emit('username'); }
                }
            }
        );
    }

    // pass first+last into API, it will add a random number to the end and make sure to return us a unique username
    public generateUsername(): void {
        const request = new GenerateUsernameRequest();
        request.FirstName = this.firstName;
        request.LastName = this.lastName;

        this.consumerService.generateUsername(request).then(
            (response) => {
                if (response != null) {
                    this.username = response;
                }
            }
        );
    }

    /**
     * clear the errors as the user types
     *
     * @memberof EnrollmentProfileComponent
     */
    public clearUsernameError(): void {
        this.usernameExists = false;
        this.usernameValidationError = false;
        this.usernameRequiredError = false;
        this.usernameSpecialCharsError = false;
    }

    /**
     * clear the errors on email due to typing
     *
     * @memberof EnrollmentProfileComponent
     */
    public clearEmailError(): void {
        this.emailMatchError = false;
        this.emailValidationError = false;
        this.emailRequiredError = false;
    }

    /**
     * clear all errors relating to password
     *
     * @memberof EnrollmentProfileComponent
     */
    public clearPasswordError(): void {
        this.passwordLengthError = false;
        this.passwordMatchError = false;
        this.passwordRequiredError = false;
        this.passwordValidationError = false;
        this.passwordSpaceError = false;
    }

    /**
     * show that this is loading
     *
     * @returns {boolean}
     * @memberof EnrollmentProfileComponent
     */
    public isLoading(): boolean {
        return this.loading;
    }

    changeEmail(): void {
        this.contactDetailsModal.openModal();
    }

    onEmailUpdated(email: any): void {
        this.email = email;
        this.emailRequiredError = false;
    }

    onGuarantorEmailsEmitted(emails: GuarantorEmailViewModel[]): void {
        this.email = '';

        if (emails.length > 0) {
            if (emails.length === 1 || emails.every(e => e.guarantorEmailAddressUsed)) {
                this.email = emails[0].emailAddress;
            }
        }
    }

    private saveConsumerAccount(account: IConsumer): void {
        this.initials = account.firstName.substr(0, 1) + account.lastName.substr(0, 1);
        this.consumer = account;
        this.consumerAccountLoading = false;
        if (!!this.domainInfo && this.isSSO() && !this.username) {
            this.username = 'SSO/' + account.accountID;
        }
        if (this.quickPayOnly) {
            this.username = 'QP/' + account.accountID;
        }
        this.doneLoading();
    }

    private emailChanged(): void {
        this.blurMethod.emit('email');
    }

    private passwordChanged(): void {
        this.blurMethod.emit('password');
    }

    private doneLoading(): void {
        this.loading = this.consumerAccountLoading;
    }

    private isSSO(): boolean {
        return this.componentService.isSSO(this.domainInfo) || this.quickPayOnly;
    }

    private canModifyPassword(): boolean {
        return !this.isSSO() && !this.quickPayOnly;
    }
}
