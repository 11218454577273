/* tslint:disable:triple-equals */
import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Router } from '@angular/router';
import { IDomainInfo } from '../../models/domaininfo';
import { ComponentService } from '../component/component.service';
import { LoggingLevel } from '../logging/logging.service';

@Injectable()
export class EnrollmentGuard implements CanActivate {

    constructor(private router: Router, private componentService: ComponentService) {
    }

    public canActivate() {
        this.componentService.loggingService.log('EnrollmentGuard canActivate', LoggingLevel.debug);

        if (this.hasEnrollableDeliveryMode()) {
            this.componentService.loggingService.log('EnrollmentGuard canActivate ' + true, LoggingLevel.debug);
            return true;
        } else {
            this.componentService.loggingService.log('EnrollmentGuard canActivate ' + false, LoggingLevel.debug);
            return false;
        }
    }

    private hasEnrollableDeliveryMode() {
        const domainInfo: IDomainInfo = this.componentService.storageService.retrieve('domaininfo');
        // using this array for remediation
        const possibleDeliveryModes: string[] = ['None', 'Paper Only', 'Standard'];
        let canEnroll = false;
        if (domainInfo != null) {
            const modeToLog = possibleDeliveryModes.indexOf(domainInfo.deliveryMode);
            if (modeToLog > -1) {
                this.componentService.loggingService.log('Delivery Mode: ' + possibleDeliveryModes[modeToLog], LoggingLevel.debug);
            }
            if (domainInfo.deliveryMode != 'None') {
                canEnroll = true;
            }
        }
        this.componentService.loggingService.log('EnrollmentGuard->hasEnrollableDeliveryMode ' + canEnroll, LoggingLevel.debug);
        return canEnroll;
    }
}
