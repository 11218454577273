import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ComponentService } from '../../services/component/component.service';

@Component({
    selector: 'quickpay-sms-profile',
    template: require('./quickpaysmsprofile.component.html'),
    styles: [require('./quickpaysmsprofile.component.css')]
})
export class QuickPaySmsProfileComponent implements OnInit, OnDestroy {
    headline: string;

    constructor(private componentService: ComponentService) { }
    private ngUnsubscribe: Subject<any> = new Subject();

    ngOnInit(): void {
        this.componentService.contentService.content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((content: any) => {
                this.headline = this.componentService.contentService.tryGetContentItem(content, 'quickpaysmsprofile', 'pageText', 'quickpaySmsProfileHeadline').text;
            });
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    isLoading(): boolean {
        return false;
    }
}
