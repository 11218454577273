/* tslint:disable:triple-equals */
import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IDomainInfo } from '../../../models/domaininfo';
import { ErrorMessage } from '../../../models/errormessage';
import { FastTrackPaymentInfoResponse } from '../../../models/fasttrackpaymentinforesponse';
import { MDWiseAccount } from '../../../models/mdwiseaccount';
import { ComponentService } from '../../../services/component/component.service';
import { ModifiedAccountPaymentStateService } from '../../../services/modifiedaccountpayment/modifiedaccountpayment.state.service';

@Component({
    selector: 'modified-account-payment-amount',
    template: require('./modifiedaccountpaymentamount.component.html'),
    styles: [require('./modifiedaccountpaymentamount.component.css')]
})

export class ModifiedAccountPaymentAmountComponent implements OnInit, OnDestroy {
    private ngUnsubscribe: Subject<any> = new Subject();
    headerText: string;
    subHeaderText: string;
    backButtonText: string;
    continueButtonText: string;
    invalidTokenErrorHeader: string;
    invalidTokenErrorMessage: string;
    loading = true;
    paymentAmountEditable = true;
    navigateNextStep: string;
    domainInfo: IDomainInfo;

    // public stateService so we can use it in the UI directly
    constructor(
        public stateService: ModifiedAccountPaymentStateService,
        private componentService: ComponentService,
        private parentRouter: Router,
        private ngZone: NgZone
    ) { }

    ngOnInit() {
        this.componentService.contentService.content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((content: any) => {
                this.headerText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'accountPayment', 'paymentStep1Header').text;
                this.subHeaderText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'accountPayment', 'paymentStep1SubHeader').text;
                const step1Name = this.componentService.contentService.tryGetContentItem(content, 'payment', 'accountPayment', 'paymentStep1Name').text;
                const step2Name = this.componentService.contentService.tryGetContentItem(content, 'payment', 'accountPayment', 'paymentStep2Name').text;
                const step3Name = this.componentService.contentService.tryGetContentItem(content, 'payment', 'accountPayment', 'paymentStep3Name').text;
                this.continueButtonText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'accountPayment', 'paymentContinueButton').text;
                this.backButtonText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'accountPayment', 'paymentBackButton').text;
                this.invalidTokenErrorMessage = this.componentService.contentService.tryGetContentItem(content, 'error', 'fastTrack', 'invalidFastTrackRequestError').text;
                this.invalidTokenErrorHeader = this.componentService.contentService.tryGetContentItem(content, 'global', 'error', 'genericWeAreSorryHeaderText').text;

                this.stateService.stepList = [{ stepName: step1Name }, { stepName: step2Name }, { stepName: step3Name }];
                this.componentService.domainService.domainInfo$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(domainInfo => {
                    this.domainInfo = domainInfo;
                    this.setComments()
                });
            });

        const modifiedPaymentMode = this.componentService.storageService.retrieve('mode');
        this.setPaymentSettingsFromPaymentMode(modifiedPaymentMode);

        this.componentService.domainService.getLocations().then(locationList => {
            // Make sure locationList is sorted by description
            this.stateService.locationList = locationList.sort(this.componentService.sortListByStringProperty('description'));
            if (locationList.length == 1) {
                this.stateService.updateFacility(locationList[0]);
            }
        });
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    prevStep() {
        this.stateService.clearState();
        this.ngZone.run(() => this.parentRouter.navigateByUrl('/')).then();
    }

    nextStep() {
        if (this.stateService.locationList.length == 0) {
            const errorMessage = new ErrorMessage(this.invalidTokenErrorHeader, null, this.invalidTokenErrorMessage, null, null);
            this.componentService.storageService.store('error', errorMessage);
            this.ngZone.run(() => this.parentRouter.navigateByUrl('error')).then();
        }
        this.stateService.checkAmountStepValid();
        if (this.stateService.amountStepValid) {
            this.ngZone.run(() => this.parentRouter.navigateByUrl(this.navigateNextStep)).then();
        }
    }

    setComments() {
        if (!this.domainInfo?.accountPaymentPrePopulateComments) {
            this.stateService.comments = ''
        }
    }

    setPaymentSettingsFromPaymentMode(paymentMode: string) {
        if (paymentMode === 'fasttrack') {
            const fasttrackresponse: FastTrackPaymentInfoResponse = this.componentService.storageService.retrieve('fasttrackresponse');
            if (fasttrackresponse != null && fasttrackresponse.fastTrackTransmissionSuccess) {
                // set fast track things
                this.stateService.paymentAmount = fasttrackresponse.paymentAmount;
                this.stateService.currentStep = 1;
                this.paymentAmountEditable = false;
                this.navigateNextStep = '/fasttrackpayment/method';
                this.loading = false;
            } else {
                const errorMessage = new ErrorMessage(this.invalidTokenErrorHeader, null, this.invalidTokenErrorMessage, null, null);
                this.componentService.storageService.store('error', errorMessage);
                this.ngZone.run(() => this.parentRouter.navigateByUrl('error')).then();
            }
        } else if (paymentMode === 'inmdwise') {
            const careenroll: MDWiseAccount = this.componentService.storageService.retrieve('careenroll');
            if (careenroll != null) {
                // set fast track things
                if (careenroll.paymentAmount == null || careenroll.paymentAmount <= 0) {
                    const errorMessage = new ErrorMessage(this.invalidTokenErrorHeader, null, this.invalidTokenErrorMessage, null, null);
                    this.componentService.storageService.store('error', errorMessage);
                    this.ngZone.run(() => this.parentRouter.navigateByUrl('error')).then();
                }
                this.stateService.paymentAmount = careenroll.paymentAmount;
                this.stateService.customerAccountId = careenroll.customerAccountId;
                this.stateService.currentStep = 1;
                this.paymentAmountEditable = false;
                this.navigateNextStep = '/careenroll/method';
                // ideally the comments will be empty
                if (this.stateService.comments != null && this.stateService.comments.length > 0 && this.stateService.comments.indexOf('ApplicationID') < 0) {
                    this.stateService.comments += ':::';
                } else {
                    this.stateService.comments = '';
                }
                const fixedPaymentAmount = (careenroll.paymentAmount * 100).toFixed()
                this.stateService.comments = this.stateService.comments + 'ApplicationID='
                    + careenroll.applicationId + '&Amount=' + fixedPaymentAmount;
                this.stateService.applicationID = careenroll.applicationId
                if (careenroll.accountDate != null) {
                    const dateMoment: moment.Moment = moment(careenroll.accountDate);
                    this.stateService.comments = this.stateService.comments + '&Date=' + dateMoment.format('YYYYMMDD');
                }
                this.loading = false;
            } else {
                const errorMessage = new ErrorMessage(this.invalidTokenErrorHeader, null, this.invalidTokenErrorMessage, null, null);
                this.componentService.storageService.store('error', errorMessage);
                this.ngZone.run(() => this.parentRouter.navigateByUrl('error')).then();
            }
        } else {
            this.ngZone.run(() => this.parentRouter.navigateByUrl('error')).then();
        }
    }
}
