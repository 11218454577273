/* tslint:disable:triple-equals */
import { Component, Input, OnInit } from '@angular/core';
import { ComponentService } from '../../../services/component/component.service';
import { LoggingLevel } from '../../../services/logging/logging.service';

/**
 * Shows a variable number of steps in a wizard-style progress bar.
 *
 * @export
 * @class ProgressBarComponent
 * @implements {OnInit}
 */
@Component({
    selector: 'progressbar',
    template: require('./progressbar.component.html'),
    styles: [require('./progressbar.component.css')]
})

export class ProgressBarComponent implements OnInit {

    stepsClass = 'steps';
    @Input() stepList: any[];
    @Input() zeroBasedSteps = false;
    @Input() nonCurrentStepTextHiddenOnSmall = true;
    @Input() currentStep: number;
    @Input() lastDisplayedStepIndex = 0;
    @Input() hideProgressBar = false;

    // WIP Setting for allowing the page to have steps that don't show progress
    @Input() allowStepBeyondFinal = false;

    constructor(private componentService: ComponentService) {
    }

    ngOnInit() {
        if (this.stepList != null) {
            if (this.lastDisplayedStepIndex == 0) {
                this.lastDisplayedStepIndex = this.stepList.length;
            }
            this.stepsClass += ' steps-' + this.lastDisplayedStepIndex;
        } else {
            this.componentService.loggingService.log('Progress bar steps setup incorrectly', LoggingLevel.debug);
        }
    }

    getCurrentAndFinalClass(step: any) {
        let returnClass = '';

        if (this.isCurrentStep(step)) {
            returnClass += 'current';
        }

        if (this.isFinalStep(step)) {
            returnClass += ' final';
        }

        return returnClass;
    }

    getStepIndex(step: any): number {
        return this.stepList.findIndex((x) => x.stepName == step.stepName) + this.incrementStepIndex();
    }

    isHiddenOnSmall(step: any) {
        return this.nonCurrentStepTextHiddenOnSmall && !this.isCurrentStep(step) ? true : false;
    }

    private calculateDesktopWidth(step: any): string {
        const numSteps: number = Math.min(this.lastDisplayedStepIndex, this.stepList.length);

        const desktopWidth: number = 100 / numSteps;

        return desktopWidth + '%';
    }

    private isCurrentStep(step: any): boolean {
        if (this.allowStepBeyondFinal && this.currentStep > this.lastDisplayedStepIndex) {
            return this.getStepIndex(step) == this.lastDisplayedStepIndex;
        }
        return this.getStepIndex(step) == this.currentStep;
    }

    private isFinalStep(step: any): boolean {
        return this.getStepIndex(step) == this.lastDisplayedStepIndex;
    }

    private incrementStepIndex(): number {
        if (this.zeroBasedSteps) {
            return 0;
        }
        return 1;
    }

}
