import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'checkbox',
    template: require('./checkbox.component.html'),
    styles: [require('./checkbox.component.css')]
})

export class CheckboxComponent implements OnInit {
    // Names must be unique on the page, otherwise the label for does not work!
    @Input() checkboxName: string;
    @Input() checkboxModel: boolean;
    @Input() disabledModel = false;
    @Input() requiredModel = false;
    @Input() erroredModel = false;
    @Input() value: any;

    // Calls the function passed in when it's changed passing the checkbox that was clicked
    @Output() public onChangeFunction: EventEmitter<CheckboxComponent> = new EventEmitter<CheckboxComponent>();

    // bubbles up the model change to the parent component
    @Output() checkboxModelChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    ngOnInit() {}

    public change(event: any): void {
        this.onChangeFunction.emit(this);
    }

    public changeit(event: any): void {
        this.checkboxModel = !this.checkboxModel;
        this.checkboxModelChange.emit(this.checkboxModel);
        this.onChangeFunction.emit(this);
    }
}
