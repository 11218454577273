export class ConsumerAccountRequest {
    CustomerAccountID: string;
    Domain: string;
    DocumentCode: string; // MEM
    ValidationString: string;
    LanguageCode: string;
    DeliveryMethod: string;
    Username: string;
    Password: string;
    EmailAddress: string;
    Phone: string;
    FirstName: string;
    LastName: string;
    EnrolledByUserID: number;
    AccountType: string;
    EnrollmentSource: string;
    EnrollmentChannel: string;
    AccountGUID: string;
    EmailVerificationId: string;
    PendingEnrollmentGUID: string;
    DocumentGUID: string;
}
