import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from '../../app.config';
import { GenericResponse, Response } from '../../models/genericresponse';
import { VerifyPasswordComplexityRequest } from '../../models/verifypasswordcomplexityrequest';
import { jsonRequestOptions } from '../../util/HttpClientUtility';
import { DomainContentItemsService } from '../contentItems/domaincontentitems.service';
import { LoggingService } from '../logging/logging.service';

@Injectable()
export class ValidationService {
    passwordMatchErrorText: string;
    passwordLengthErrorText: string;
    passwordValidationErrorText: string;
    passwordSameErrorText: string;
    passwordSpaceErrorText: string;
    isRequiredText: string;

    private domainUrl = this.config.getConfig('domainInfo');
    private verifyPasswordComplexityPath = this.config.getConfig('verifyPasswordComplexityPath');

    constructor(
        private http: HttpClient,
        private config: AppConfig,
        private contentService: DomainContentItemsService,
        private loggingService: LoggingService
        ) {
        this.contentService.content$.subscribe(
            (content: any) => {
                this.passwordMatchErrorText = this.contentService.tryGetContentItem(content, 'enrollment', 'error', 'enrollmentProfilePasswordErrorText').text;
                this.passwordValidationErrorText = this.contentService.tryGetContentItem(content, 'enrollment', 'error', 'enrollmentPasswordValidationErrorText').text;
                this.passwordLengthErrorText = this.contentService.tryGetContentItem(content, 'enrollment', 'error', 'enrollmentProfilePasswordLengthErrorText').text;
                this.passwordSameErrorText = this.contentService.tryGetContentItem(content, 'landingPage', 'error', 'landingPagePasswordResetError').text;
                this.passwordSpaceErrorText = this.contentService.tryGetContentItem(content, 'enrollment', 'error', 'enrollmentProfilePasswordSpacesErrorText').text;
                this.isRequiredText = this.contentService.tryGetContentItem(content, 'global', 'error', 'isRequiredError').text;
            });
    }

    /**
     * Parses the given validation error and returns a more readable string
     *
     * @param {string} error
     * @returns {string}
     *
     * @memberof ValidationService
     */
    public async validatePassword(newPassword: string, confirmPassword: string, oldPassword?: string): Promise<string> {
        // New password and confirm password must match
        if (!!confirmPassword && confirmPassword.length > 0) {
            if (newPassword !== confirmPassword) {
                return this.passwordMatchErrorText;
            }
        } else {
            return 'Password ' + this.isRequiredText;
        }

        if (!!oldPassword && oldPassword.length > 0) {
            if (newPassword === oldPassword) {
                return this.passwordSameErrorText;
            }
        }

        // New password needs to be 8 characters long
        if (!!newPassword && newPassword.length < 8 && newPassword.length > 0) {
            return this.passwordLengthErrorText;
        }

        if (!!newPassword && newPassword.includes(' ')) {
            return this.passwordSpaceErrorText;
        }

        return null;
    }

    /**
     * Determines whether or not the given password matches the complexity requirements
     * @param customerAccountID identifier for the current consumer
     * @param password password which is to be verified for complexity
     */
    public async verifyPasswordComplexity(password: string): Promise<boolean> {
        const request: VerifyPasswordComplexityRequest = {
            password
        };
        return this.callVerifyPasswordComplexityApi(request);
    }

    private async callVerifyPasswordComplexityApi(request: VerifyPasswordComplexityRequest): Promise<boolean> {
        const body = {
            path: this.verifyPasswordComplexityPath,
            password: request.password
        };

        return this.http.post(this.domainUrl, {body}, jsonRequestOptions())
            .toPromise()
            .then((response: Response<boolean>) => response.body.success)
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }
}
