import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IDomainInfo } from '../../../models/domaininfo';
import { IPayment } from '../../../models/payment';
import { DateFormatPipe } from '../../../pipes/DateFormat/dateformat.pipe';
import { ComponentService } from '../../../services/component/component.service';
import { ConsumerService } from '../../../services/consumer/consumer.service';
import { PayableService } from '../../../services/payable/payable.service';

@Component({
    selector: 'statement-widget',
    template: require('./statementwidget.component.html'),
    styles: [require('./statementwidget.component.css')]
})
export class StatementWidgetComponent implements OnInit, OnDestroy {
    makePaymentButtonText: string;
    paymentDisabledText: string;
    viewBalanceDetailsLinkText: string;
    showBalanceDetailsLink = false;
    loading = true;
    domainInfo: IDomainInfo;
    paymentRoute: string;
    lastPaymentText = '';

    @Input() statementBalance = 0.00;
    @Input() lastPaymentDate: string;
    @Input() balanceAsOf: string;

    private ngUnsubscribe: Subject<any> = new Subject();

    canMakePayment: boolean;

    constructor(
        private componentService: ComponentService,
        private consumerService: ConsumerService,
        private datePipe: DateFormatPipe,
        private payableService: PayableService,
    ) {}

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit(): void {
        this.componentService.contentService.content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((content: any) => {
                this.makePaymentButtonText = this.componentService.contentService.tryGetContentItem(content, 'home', 'statement', 'homePageStatementMakePaymentButton').text;
                this.paymentDisabledText = this.componentService.contentService.tryGetContentItem(content, 'home', 'statement', 'homePagePaymentDisabled').text;
                this.viewBalanceDetailsLinkText = this.componentService.contentService.tryGetContentItem(content, 'home', 'statement', 'homePageBalanceDetailsLink').text;
                this.lastPaymentText = this.componentService.contentService.tryGetContentItem(content, 'home', 'statement', 'homePageLastPaymentText').text;
            });

        this.componentService.domainService.domainInfo$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(domainInfo => {
            this.domainInfo = domainInfo;
            this.componentService.getPaymentUrlForDomain().then(url => this.paymentRoute = url);
        });

        this.payableService.canMakePayment().then(can => {
            this.canMakePayment = can;
        });

        this.getStatementFields();
    }

    async getStatementFields(): Promise<void> {
        const balanceDetailsBalance = await this.consumerService.getAccountBalanceInfo();

        if (balanceDetailsBalance != null) {
            if (balanceDetailsBalance.realtimeAvailable) {
                this.statementBalance = balanceDetailsBalance.balance;
                this.lastPaymentDate = balanceDetailsBalance.lastPaymentDate != null
                    ? this.datePipe.transform(balanceDetailsBalance.lastPaymentDate, 'MMMM DD, YYYY')
                    : '';

                this.showBalanceDetailsLink = true;

                this.balanceAsOf =
                    balanceDetailsBalance.balanceAsOf != null ? this.datePipe.transform(balanceDetailsBalance.balanceAsOf, 'MM/DD/y') : '';

                this.doneLoading();
            } else {
                await this.getDocumentStatementFields();
            }
        }
    }

    async getDocumentStatementFields(): Promise<void> {
        const statementBalance = await this.consumerService.getStatementBalance(this.domainInfo.totalBalanceType);
        const history = await this.consumerService.getConsumerPaymentHistory();

        const lastPaymentDate = history
            .sort((a: IPayment, b: IPayment) => ComponentService.sortDateDescending(a.paymentDate, b.paymentDate))
            .map((payment) => payment.paymentDate)[0];

        this.statementBalance = statementBalance.balance;
        this.lastPaymentDate = lastPaymentDate != null ? this.datePipe.transform(lastPaymentDate, 'MMMM DD, YYYY') : '';

        this.balanceAsOf =
            statementBalance.balanceAsOf != null ? this.datePipe.transform(statementBalance.balanceAsOf, 'MM/DD/y') : '';

        this.doneLoading();
    }

    private doneLoading() {
        this.loading = false;
    }

    private isLoading(): boolean {
        return this.loading;
    }
}
