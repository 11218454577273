import { DatePipe } from '@angular/common';
import { PaymentBucket } from '../../../models/paymentbucket';

export class BasePaymentBucketComponent {
    constructor(private datePipe: DatePipe) { }

    computeBucketChargeDetailsDescription(bucket: PaymentBucket): string {
        const parts: string[] = [];
        if (bucket) {
            if (bucket.accountNumber) {
                parts.push(bucket.accountNumber);
            }
            if (bucket.chargeDate
                && Object.prototype.toString.call(bucket.chargeDate) === '[object Date]'
                && !isNaN(bucket.chargeDate.getTime()) ) {
                parts.push(this.datePipe.transform(bucket.chargeDate, 'MMM d'));
            }
        }
        return parts.join(' - ');
    }
}
