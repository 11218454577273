import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TextMaskModule } from 'angular2-text-mask';
import { BsModalService } from 'ngx-bootstrap/modal';
import { CheckboxModule } from '../Checkbox/checkbox.module';
import { IconModule } from '../Icon/icon.module';
import { MSBButtonModule } from '../MSBButton/msbbutton.module';
import { ConsumerContactDetailsModalComponent } from './consumercontactdetailsmodal.component';
import { EmailFormComponent } from './emailform/emailform.component';
import { PhoneFormComponent } from './phoneform/phoneform.component';

@NgModule({
    imports: [
        ReactiveFormsModule,
        CommonModule,
        CheckboxModule,
        IconModule,
        MSBButtonModule,
        TextMaskModule
    ],
    declarations: [
        PhoneFormComponent,
        EmailFormComponent,
        ConsumerContactDetailsModalComponent
    ],
    providers: [
        BsModalService
    ],
    exports: [
        PhoneFormComponent,
        EmailFormComponent,
        ConsumerContactDetailsModalComponent
    ]
})

export class ConsumerContactDetailsModalModule {
}
