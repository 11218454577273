import { IAutopayPayment } from './autopaypayment';
import { PaymentMethod, PaymentMethodType } from './paymentmethod';
import { PaymentMethodAssignmentSource } from './paymentmethodassignmentsource';

/* Defines the Autopay Agreement entity */
export interface IAutopayAgreement {
    autopayAgreementID: number;
    status: AgreementStatus;
    merchantProfileGUID: string;
    recurringAccountID: number;
    maxPaymentAmount: number;
    emailAddress: string;
    paymentLocationGUID: string;
    paymentLocationDescription: string;
    paymentMethodNickname: string;
    paymentMethodDescription: string;
    paymentMethodLast4: string;
    paymentMethodEntryMethod: string;
    cardType: string;
    createdDate: Date;
    cancelDate: Date;
    createdByUser: string;
    cancelledByUser: string;
    paymentMethodType: PaymentMethodType;
    mostRecentPayment: IAutopayPayment;
    pendingPayments: IAutopayPayment[];
    storedPaymentMethod: PaymentMethod;
    consumerPaymentMethodId: number;
    paymentMethodAssignmentSource: PaymentMethodAssignmentSource;
    autoPayFixedDate: number;
}

export enum AgreementStatus {
    active = 0,
    test = 1,
    pending = 2,
    hold = 3,
    completed = 4,
    cancelled = 5,
    staged = 6
}

export const TEST_AUTOPAYAGREEMENT: IAutopayAgreement = {
    autopayAgreementID: 6172,
    cancelDate: new Date('2017-10-12T15:17:00'),
    cancelledByUser: 'user',
    cardType: 'Visa',
    createdByUser: 'user',
    createdDate: new Date('2017-10-06T09:34:00'),
    emailAddress: 'email@test.com',
    maxPaymentAmount: 150,
    merchantProfileGUID: '372',
    mostRecentPayment: {
        amount: 60000,
        autopayAgreementID: 6172,
        autopayID: 1290,
        cancelDate: new Date(),
        cancelledByUser: '',
        documentID: 10056493,
        queuedPaymentDate: new Date('2017-03-31T11:22:00'),
        scheduledPaymentDate: new Date('2017-04-03T11:21:00'),
        status: AgreementStatus.completed
    },
    paymentLocationDescription: 'East Clinic',
    paymentLocationGUID: 'abc',
    paymentMethodDescription: 'Card XXXX9862',
    paymentMethodEntryMethod: 'Standard',
    paymentMethodLast4: 'XXXX9862',
    paymentMethodNickname: 'Autopay Card - XXXX9862',
    paymentMethodType: PaymentMethodType.credit,
    pendingPayments: null,
    recurringAccountID: 0,
    status: 0,
    storedPaymentMethod: null,
    consumerPaymentMethodId: 1234,
    paymentMethodAssignmentSource: PaymentMethodAssignmentSource.SelfService,
    autoPayFixedDate: 22
};

export const TEST_AUTOPAYAGREEMENTS: IAutopayAgreement[] = [TEST_AUTOPAYAGREEMENT];
