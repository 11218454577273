import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IPaymentPlanSubmit } from '../../../models/paymentplansubmit';
import { ComponentService } from '../../../services/component/component.service';

@Component({
    selector: 'paymentplan-details-message',
    template: require('./paymentplandetailsmessage.component.html'),
    styles: [require('./paymentplandetailsmessage.component.css')],
})
export class PaymentPlanDetailsMessageComponent implements OnInit, OnDestroy {
    private ngUnsubscribe: Subject<any> = new Subject();

    notificationMessageWithWalletContentItemText: string;
    notificationMessageWithNoWalletContentItemText: string;

    displayNewMessage = false;

    notificationTitle = '';
    notificationMobileTitle = '';
    notificationMessage = '';

    constructor(private componentService: ComponentService, private datePipe: DatePipe) {
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit(): void {
        this.componentService.contentService.content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((content: any) => {
                this.notificationTitle = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'pageText', 'paymentPlanCreatedMessageTitleText').text;
                this.notificationMobileTitle = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'pageText', 'paymentPlanCreatedMessageMobileTitleText').text;
                this.notificationMessageWithWalletContentItemText = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'pageText', 'paymentPlanCreatedMessageWithWalletText').text;
                this.notificationMessageWithNoWalletContentItemText = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'pageText', 'paymentPlanCreatedMessageWithNoWalletText').text;
            });

        this.initializeMessages();
    }

    initializeMessages() {
        if (this.componentService.storageService.exists('paymentplancreatedorupdated')) {
            const paymentPlanSubmit: IPaymentPlanSubmit = this.componentService.storageService.retrieve('paymentplancreatedorupdated');
            this.componentService.storageService.clear('paymentplancreatedorupdated');
            this.displayNewMessage = true;

            if (paymentPlanSubmit.consumerPaymentMethodGUID
                || paymentPlanSubmit.ACHPaymentMethod
                || paymentPlanSubmit.CreditCardPaymentMethod) {
                this.notificationMessage = this.notificationMessageWithWalletContentItemText;
            } else {
                this.notificationMessage = this.notificationMessageWithNoWalletContentItemText;
            }

            this.notificationMessage = this.notificationMessage.replace(
                '!PAYMENTDATE!', this.datePipe.transform(paymentPlanSubmit.ExpectedPaymentDate, 'M/d/y'));
        }
    }
}
