import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
    name: 'dateformat'
})
export class DateFormatPipe implements PipeTransform {
    // use moment.js formatting instead of Angular's default date formatting
    // refer to moment.js display docs for patterns https://momentjs.com/docs/#/displaying/
    transform(date: any, pattern?: any): any {
        return moment.utc(date).format(pattern);
    }
}
