import { Component, Input } from '@angular/core';


@Component({
    selector: 'breadcrumb',
    template: require('./breadcrumb.component.html'),
    styles: [require('./breadcrumb.component.css')]
})

export class BreadcrumbComponent {
    @Input() path: string;
}
