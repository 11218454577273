import { Component, NgZone, OnDestroy, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConsumerContactDetailsModalComponent } from '../../components/Controls/ConsumerContactDetailsModal/consumercontactdetailsmodal.component';
import { ICommunicationPreferenceChange } from '../../models/communicationPreferenceType';
import { ConsumerAccount } from '../../models/consumeraccount';
import { IDomainInfo } from '../../models/domaininfo';
import { ComponentService } from '../../services/component/component.service';
import { ConnectMessageService } from '../../services/connectmessage/connectmessage.service';
import { ConsumerService } from '../../services/consumer/consumer.service';
import { ConsumerStorageService } from '../../services/indexedDb/consumer-storage.service';

@Component({
    selector: 'smsenrollment',
    template: require('./smsenrollment.component.html'),
    styles: [require('./smsenrollment.component.css')]
})
export class SmsEnrollmentComponent implements  OnDestroy {
    backLinkPath = '/profile';

    consumerAccount: ConsumerAccount;
    hasQuickPayIdentifier = false;
    phoneLoading = false;
    pinLoading = false;
    addingNewPaymentMethod = false;
    newPaymentMethod = true;
    paymentMethodValid: boolean;

    smsBackgroundImage: string;
    header: string;
    subheading: string;
    smsInstructions: string;
    phoneNumberText: string;
    confirmPhoneNumberText: string;
    smsAgreementText: string;
    confirmationPinCodeText: string;
    sendConfirmationPinButtonText: string;
    submitButtonText: string;
    backButtonText: string;
    termsAndConditions: string;
    phoneNumberErrorText: string;
    phoneNumberInvalidText: string;
    confirmPhoneNumberErrorText: string;
    confirmPhoneNumberInvalidText: string;
    confirmPhoneNumberMatchText: string;
    smsAgreementErrorText: string;
    smsPinCodeRequiredText: string;
    smsErrorText: string;
    phoneNumberErrorExists = false;
    confirmPhoneNumberErrorExists = false;
    confirmPhoneNumberMatchErrorExists = false;
    smsAgreementErrorExists = false;
    smsPinCodeErrorExists = false;
    smsErrorExists = false;
    smsPinCodeSuccessfullySent = false;
    smsPinCodeSentText: string;
    accountNumberText: string;
    quickPayConfirmationText: string;
    showQuickPayConfirmationText = false;
    quickPaySmsAlreadySetupText: string;
    numberBlacklistedErrorExists = false;
    numberBlacklistedErrorText: string;
    displaySmsAlreadySetupText = false;

    smsBackgroundImagePlaceholder: string;
    triedSmsBackgroundImageConnectMessage = false;

    smsPhoneNumber: string;
    savedPhoneNumber: string;
    smsConfirmPhoneNumber: string;
    smsAgreement = false;
    smsPinCode: string;

    domainInfo: IDomainInfo;

    phoneMask: Array<string | RegExp> = this.componentService.phoneMask;

    @ViewChild('consumerProfileModal', { static: false }) consumerProfileModal: ConsumerContactDetailsModalComponent;

    private ngUnsubscribe: Subject<any> = new Subject();

    constructor(
        private componentService: ComponentService,
        private consumerService: ConsumerService,
        private parentRouter: Router,
        private connectMessageService: ConnectMessageService,
        private consumerStorageService: ConsumerStorageService,
        private ngZone: NgZone
    ) { }

    async ngOnInit() {
        if (this.componentService.storageService.exists('smsbacklink')) {
            this.backLinkPath = this.componentService.storageService.retrieve('smsbacklink');
            this.componentService.storageService.clear('smsbacklink');
        }
        this.componentService.scrollPageToTop();

        this.componentService.contentService.content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((content: any) => {
                this.smsBackgroundImagePlaceholder = this.tryGetContentItem(content, 'image', 'profileSmsBackgroundImage');
                this.header = this.tryGetContentItem(content, 'sms', 'profileSmsHeaderText');
                this.subheading = this.tryGetContentItem(content, 'sms', 'profileSmsSubHeadingText');
                this.smsInstructions = this.tryGetContentItem(content, 'sms', 'profileSmsInstructionsText');
                this.phoneNumberText = this.tryGetContentItem(content, 'sms', 'profileSmsPhoneNumberText');
                this.confirmPhoneNumberText = this.tryGetContentItem(content, 'sms', 'profileSmsConfirmPhoneNumberText');
                this.smsAgreementText = this.tryGetContentItem(content, 'sms', 'profileSmsAgreementText');
                this.sendConfirmationPinButtonText = this.tryGetContentItem(content, 'sms', 'profileSmsSendPinCodeText');
                this.confirmationPinCodeText = this.tryGetContentItem(content, 'sms', 'profileSmsEnterPinCodeText');
                this.backButtonText = this.tryGetContentItem(content, 'sms', 'profileSmsBackButtonText');
                this.submitButtonText = this.tryGetContentItem(content, 'sms', 'profileSmsSubmitButtonText');
                this.termsAndConditions = this.tryGetContentItem(content, 'sms', 'profileSmsTermsAndConditionsText');
                this.phoneNumberErrorText = this.tryGetContentItem(content, 'error', 'profileSmsPhoneNumberErrorText');
                this.phoneNumberInvalidText = this.tryGetContentItem(content, 'sms', 'profileSmsPhoneNumberInvalidText');
                this.confirmPhoneNumberErrorText = this.tryGetContentItem(content, 'error', 'profileSmsConfirmPhoneNumberErrorText');
                this.confirmPhoneNumberMatchText = this.tryGetContentItem(content, 'sms', 'profileSmsConfirmPhoneNumberMatchText');
                this.smsAgreementErrorText = this.tryGetContentItem(content, 'error', 'profileSmsAgreementErrorText');
                this.smsPinCodeRequiredText = this.tryGetContentItem(content, 'sms', 'profileSmsPinCodeRequiredText');
                this.smsErrorText = this.tryGetContentItem(content, 'error', 'profileSmsGenericErrorText');
                this.smsPinCodeSentText = this.tryGetContentItem(content, 'sms', 'profileSmsPinSentNotification');
                this.accountNumberText = this.tryGetContentItem(content, 'sms', 'profileQuickPayAccountNumberText');
                this.quickPayConfirmationText = this.tryGetContentItem(content, 'sms', 'profileQuickPayConfirmationText');
                this.quickPaySmsAlreadySetupText = this.tryGetContentItem(content, 'sms', 'profileSmsAlreadySetupText');
                this.numberBlacklistedErrorText = this.tryGetContentItem(content, 'sms', 'profileSmsNumberBlacklistedText');

                if (!this.smsBackgroundImage) {
                    this.smsBackgroundImage = this.tryGetContentItem(content, 'image', 'profileSmsBackgroundImage');
                }
            });
        this.consumerAccount = await this.consumerService.getConsumerAccount();
        // Check if quickpayidentifier exists.  If it does it came from quick pay
        this.hasQuickPayIdentifier = this.componentService.storageService.exists('quickpayidentifier');
        const imageUrl = await this.connectMessageService.getConnectMessageImageUrl('smsbackground');
        if (imageUrl) {
            this.smsBackgroundImage = imageUrl;
        }

        this.componentService.domainService.domainInfo$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(async domainInfo => {
                this.domainInfo = domainInfo;

                this.displaySmsAlreadySetupText = await this.setDisplaySmsAlreadySetupText();
            });
    }

    tryGetContentItem(content: any, sub: string, name: string): string {
        return this.componentService.contentService.tryGetContentItem(
            content,
            'profile',
            sub,
            name
        ).text;
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    /**
     * Verifies the phone and confirm phone fields are valid and matching,
     * and submits a request to have a PIN sent via SMS to the phone number
     */
    async submitSmsPinCode(f: NgForm) {
        this.clearErrors();
        if (!this.smsAgreement) {
            this.smsAgreementErrorExists = true;
            return;
        }
        if (this.smsPhoneNumber !== this.smsConfirmPhoneNumber) {
            this.confirmPhoneNumberMatchErrorExists = true;
            return;
        }
        if (f.valid) {
            // call api
            this.phoneLoading = true;
            this.savedPhoneNumber = this.smsPhoneNumber.replace(/\D+/g, '');
            try {
                const data = await this.consumerService.verifyNumberNotBlacklisted(this.savedPhoneNumber);
                if (data.messages && data.messages.length > 0) {
                    this.numberBlacklistedErrorExists = true;
                    this.doneLoading();
                }
            } catch (err) {
                this.doneLoading();
                this.smsErrorExists = true;
            }
            try {
                await this.consumerService.sendForSmsConfirmationPin(
                    this.consumerAccount.customerAccountID, this.savedPhoneNumber);
            } catch (e) {
                this.smsErrorExists = true;
                this.doneLoading();
                return;
            }
            this.doneLoading();
            // Show validation message that pin was successfully sent to number
            this.smsPinCodeSuccessfullySent = true;
        }
        return;
    }

    /**
     * Hides the SMS confirmation message if one is displayed and a phone number is changed.
     */
    hideSmsConfirmationMessage(): void {
        this.smsPinCodeSuccessfullySent = false;
    }

    /**
     * Submits the PIN in a request to verify the phone number and PIN,
     * updates the consumeraccount object in storage, and navigates back to /profile
     */
    submitSmsVerification(): void {
        this.clearErrors();
        if (!!this.smsPinCode && this.smsPinCode.length > 0) {
            this.smsPinCodeErrorExists = false;
            this.pinLoading = true;
            this.consumerService.validateSmsPin(this.consumerAccount.customerAccountID, this.savedPhoneNumber, this.smsPinCode)
                .then(async response => {
                    if (response.success) {
                        if (this.domainInfo.useConsolidatedCommunication) {
                            this.updateCommunicationPreference([
                                {
                                    type: 'StatementSms',
                                    newValue: true,
                                    customerAccountID: this.consumerAccount.customerAccountID
                                }
                            ]);
                        }

                        this.consumerAccount.smsDelivery = true;
                        this.consumerAccount.mobilePhone = this.savedPhoneNumber;

                        // We are currently shimming the tblCustomerGuarantor's phone
                        // number into the ConsumerAccount object (inside ConsumerApi),
                        // so while (as UCC=1) the phone should never be referencing
                        // the ConsumerAccount local storage value I am setting it here
                        // anyway because if I don't the phone number shown in the
                        // ConsumerAccount object in local storage will not match the
                        // one being set to the IndexedDB value. I'm doing so to avoid
                        // confusion to any devs in the future who may notice this
                        // and wonder why they don't match.
                        this.componentService.storageService.store('consumeraccount', this.consumerAccount);

                        if (this.domainInfo.useConsolidatedCommunication) {
                            // Update the Consumer phone value in the IndexedDB storage
                            await this.consumerStorageService.updateConsumer({ phone: this.savedPhoneNumber });
                        }

                        if (this.isOneTimePayment()) {
                            this.componentService.storageService.clearSession();
                            this.showQuickPayConfirmationText = true;
                            this.pinLoading = false;
                        } else {
                            this.backLink();
                        }
                    } else {
                        this.doneLoading();
                        this.smsErrorText = response.messages.join('<br/>');
                        this.smsErrorExists = true;
                    }
                }).catch(err => {
                    this.doneLoading();
                    this.smsErrorExists = true;
                });
        } else {
            this.smsPinCodeErrorExists = true;
        }
    }

    private updateCommunicationPreference(changes: ICommunicationPreferenceChange[]) {
        this.consumerService.updateConsumerCommunicationPreference(
            this.consumerAccount.customerAccountID, changes
        );
    }

    isOneTimePayment(): boolean {
        return this.hasQuickPayIdentifier;
    }

    async setDisplaySmsAlreadySetupText(): Promise<boolean> {
        let statementSmsEnabled = false;

        if (this.domainInfo.useConsolidatedCommunication) {
            const preferences = await this.consumerService.getConsumerCommunicationPreferences(
                this.consumerAccount.customerAccountID);

            statementSmsEnabled = preferences.statementSmsEnabled;
        }

        return this.consumerAccount != null && (this.consumerAccount.smsDelivery || statementSmsEnabled);
    }

    private clearErrors(): void {
        this.phoneNumberErrorExists = false;
        this.confirmPhoneNumberErrorExists = false;
        this.confirmPhoneNumberMatchErrorExists = false;
        this.smsAgreementErrorExists = false;
        this.smsPinCodeErrorExists = false;
        this.smsErrorExists = false;
    }

    private backLink(): void {
        this.ngZone.run(() => this.parentRouter.navigateByUrl(this.backLinkPath)).then();
    }

    private doneLoading(): void {
        this.phoneLoading = false;
        this.pinLoading = false;
    }
}
