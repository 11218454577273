import { IPayment } from './payment';
import { PaymentMethod } from './paymentmethod';
import { PaymentMethodAssignmentSource } from './paymentmethodassignmentsource';

export interface IRecurringPaymentPlan {
    accountNumber: string;
    completedDate: Date;
    customerAccountID: string;
    documentDate: Date;
    documentID: string;
    documentGUID: string;
    documentDescription: string;
    emailAddress: string;
    finalPaymentAmount: number;
    frequency: RecurringFrequency;
    holdDate: Date;
    holdReason: string;
    mostRecentPayment: IPayment;
    pendingPayments: IPayment[];
    name: string;
    nextScheduledPaymentDate: Date;
    numberOfPayments: number;
    numberOfRemainingPayments: number;
    paymentAccountDescription: string;
    paymentAmount: number;
    paymentCardType: string;
    paymentRecurringAccount: string;
    recurringConsumerPaymentGUID: string;
    recurringConsumerPaymentID: number;
    recurringMode: RecurringMode;
    scheduledEndDate: Date;
    startDate: Date;
    status: RecurringPaymentPlanStatus;
    totalAmount: number;
    storedPaymentMethod: PaymentMethod;
    consumerPaymentMethodId: number;
    validationSignature: string;
    type: RecurringPlanType;
    paymentMethodAssignmentSource: PaymentMethodAssignmentSource;
}

export enum RecurringPaymentPlanStatus {
    active = 0,
    test = 1,
    pending = 2,
    hold = 3,
    completed = 4,
    cancelled = 5
}

export enum RecurringMode {
    fixedAmount = 0,
    fixedDuration = 1
}

export enum RecurringFrequency {
    daily = 0,
    monthly = 1,
    weekly = 2,
    biweekly = 3
}

export enum RecurringPlanType {
    recurring = 0,
    preservice = 1
}

export const TEST_RECURRINGPAYMENTPLAN: IRecurringPaymentPlan = {
    accountNumber: '123456',
    customerAccountID: '637085017',
    documentDate: new Date(),
    documentDescription: 'Test Statement',
    documentID: '12345',
    emailAddress: 'testUser@apexrevtech.com',
    finalPaymentAmount: 200,
    frequency: RecurringFrequency.monthly,
    holdDate: new Date(),
    holdReason: 'because',
    mostRecentPayment: new IPayment(),
    name: 'testing',
    nextScheduledPaymentDate: new Date(),
    numberOfPayments: 5,
    numberOfRemainingPayments: 2,
    paymentAccountDescription: 'this account',
    paymentAmount: 50,
    paymentCardType: 'visa',
    paymentRecurringAccount: 'test',
    recurringConsumerPaymentGUID: '',
    recurringConsumerPaymentID: 1234,
    recurringMode: RecurringMode.fixedAmount,
    scheduledEndDate: new Date(),
    startDate: new Date(),
    status: RecurringPaymentPlanStatus.active,
    totalAmount: 250,
    completedDate: new Date(),
    documentGUID: 'this-is-a-guid',
    pendingPayments: null,
    storedPaymentMethod: null,
    consumerPaymentMethodId: 5,
    validationSignature: 'stuff',
    type: RecurringPlanType.recurring,
    paymentMethodAssignmentSource: PaymentMethodAssignmentSource.SelfService
};

export const TEST_RECURRINGPAYMENTPLANS: IRecurringPaymentPlan[] = [
    TEST_RECURRINGPAYMENTPLAN
];

export const TEST_PRESERVICERECURRINGPAYMENTPLAN: IRecurringPaymentPlan = {
    accountNumber: '123456',
    customerAccountID: '637085017',
    documentDate: new Date(),
    documentDescription: 'Test Statement',
    documentID: '12345',
    emailAddress: 'testUser@apexrevtech.com',
    finalPaymentAmount: 200,
    frequency: RecurringFrequency.monthly,
    holdDate: new Date(),
    holdReason: 'because',
    mostRecentPayment: new IPayment(),
    name: 'testing',
    nextScheduledPaymentDate: new Date(),
    numberOfPayments: 5,
    numberOfRemainingPayments: 2,
    paymentAccountDescription: 'this account',
    paymentAmount: 50,
    paymentCardType: 'visa',
    paymentRecurringAccount: 'test',
    recurringConsumerPaymentGUID: '',
    recurringConsumerPaymentID: 12345,
    recurringMode: RecurringMode.fixedAmount,
    scheduledEndDate: new Date(),
    startDate: new Date(),
    status: RecurringPaymentPlanStatus.pending,
    totalAmount: 250,
    completedDate: new Date(),
    documentGUID: 'this-is-a-guid',
    pendingPayments: null,
    storedPaymentMethod: null,
    consumerPaymentMethodId: 5,
    validationSignature: 'stuff',
    type: RecurringPlanType.preservice,
    paymentMethodAssignmentSource: PaymentMethodAssignmentSource.SelfService
};

export const TEST_PRESERVICERECURRINGPAYMENTPLANS: IRecurringPaymentPlan[] = [
    TEST_PRESERVICERECURRINGPAYMENTPLAN
];
