export class ACHPaymentMethod {
    bankAccountNumber: string;
    routingNumber: string;
    paymentSource: ACHPaymentMethodPaymentSource;
    AccountType: ACHPaymentMethodAccountType;
}


export enum ACHPaymentMethodPaymentSource {
    //  Telephone = 0
    Web = 1,
    CCD = 2,
    PPD = 3
}

export enum ACHPaymentMethodAccountType {
    Checking = 0,
    Savings = 1,
    BusinessChecking = 2
}
