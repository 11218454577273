import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IDomainInfo } from '../../models/domainInfo';
import { ComponentService } from '../../services/component/component.service';
import { ConnectMessageService } from '../../services/connectmessage/connectmessage.service';
import { ConsumerService } from '../../services/consumer/consumer.service';
import { LoginService } from '../../services/login/login.service';

@Component({
    selector: 'mem-payment',
    template: require('./mempayment.component.html'),
    styles: [require('./mempayment.component.css')]
})
export class MEMPaymentComponent implements OnInit, OnDestroy {
    MEMTitle: string;
    domainInfo: IDomainInfo;
    extraRequired: boolean;
    MEMErrorExists = false;
    MEMRedirect = false;
    hideMEMCode = false;
    MEMDocumentError: string;
    extraErrorExists = false;
    token = '';
    disableSubmit = false;
    MEMDescription: string;
    MEMExtra: string;
    MEMErrorText: string;
    MEMErrorMessage: string;
    MEMMixedErrorMessage: string;
    MEMImage: string;
    MEMInstructions: string;
    MEMPlaceholder: string;
    MEMExtraPlaceholder: string;
    buttonText: string;
    needHelp: string;
    noStatement: string;
    linkMEM = false;
    validationModeText: string;
    extraErrorText: string;
    errorMessage: string;

    MEMImagePlaceholder: string;
    triedMEMImageConnectMessage = false;

    smsGUID: string;

    // mask data
    memCode = '';
    mode = '';
    documentId = '';

    private ngUnsubscribe: Subject<any> = new Subject();

    public secondaryAuthMask: any = false;

    constructor(
        private componentService: ComponentService,
        private loginService: LoginService,
        private parentRouter: Router,
        private consumerService: ConsumerService,
        private connectMessageService: ConnectMessageService,
        private ngZone: NgZone
    ) { }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit() {
        // Outage? We're outta here.
        this.componentService.domainService.getOutages()
        .then(
            outageList => {
                if (!!outageList) {
                    const now = this.componentService.getNowWithOutSeconds();

                    if (outageList.some(x =>
                        now >= new Date(x.outageStartDate) &&
                        now < new Date(x.outageEndDate) &&
                        x.system === 'MySecureBill Maintenance'
                    )) {
                        if (!this.componentService.letMeIn()) {
                            this.ngZone.run(() => this.parentRouter.navigateByUrl('/outage')).then();
                        }
                    }
                }
            },
            error => this.errorMessage = (error as any)
        );

        this.componentService.scrollPageToTop();
        this.memCode = this.componentService.storageService.retrieve('mem');
        this.mode = this.componentService.storageService.retrieve('mode');
        this.smsGUID = this.componentService.storageService.retrieve('smsGUID');
        this.documentId = this.componentService.storageService.retrieve('documentId');
        if (this.isNullOrEmpty(this.memCode) && !this.isNullOrEmpty(this.documentId)) {
            this.linkMEM = true;
        }

        if (!this.mode || this.mode !== 'qr') {
            // I think this will be gone with .clearSession() above, but leaving for historical
            // purposes right now, will not cause a problem clearing something twice
            this.componentService.storageService.clear('paymentsource');
        }

        this.componentService.storageService.clearSession();

        // after clearing storage above, we will want to put mode back into storage.
        // it's value is critical to the submission of the form, thus progressing the user to the next step (/payment),
        // in the case where a user is redirected to /mempayment from the MemCodeLoginGuard.
        this.componentService.storageService.store('mode', this.mode);

        this.componentService.contentService.content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((content: any) => {
                this.MEMTitle = this.componentService.contentService.tryGetContentItem(content, 'memPayment', 'pageText', 'memPaymentPageTitle').text;
                this.MEMInstructions = this.componentService.contentService.tryGetContentItem(content, 'memPayment', 'pageText', 'memPaymentMemInstructions').text;
                this.MEMDescription = this.componentService.contentService.tryGetContentItem(content, 'memPayment', 'pageText', 'memPaymentMemDescription').text;
                this.MEMExtra = this.componentService.contentService.tryGetContentItem(content, 'memPayment', 'pageText', 'memPaymentMemExtra').text;
                this.MEMErrorMessage = this.componentService.contentService.tryGetContentItem(content, 'memPayment', 'error', 'memPaymentMemError').text;
                this.MEMMixedErrorMessage = this.componentService.contentService.tryGetContentItem(content, 'memPayment', 'error', 'memPaymentExtendedMemError').text;
                this.MEMPlaceholder = this.MEMPlaceholder == null ? this.componentService.contentService.tryGetContentItem(content, 'memPayment', 'pageText', 'memPaymentMemPlaceholder').text : this.MEMPlaceholder;
                this.MEMExtraPlaceholder = this.componentService.contentService.tryGetContentItem(content, 'memPayment', 'pageText', 'memPaymentMemExtraPlaceholder').text;
                this.MEMImagePlaceholder = this.componentService.contentService.tryGetContentItem(content, 'memPayment', 'image', 'memPaymentMemImage').text;
                this.buttonText = this.componentService.contentService.tryGetContentItem(content, 'memPayment', 'button', 'memPaymentMemButton').text;
                this.needHelp = this.componentService.contentService.tryGetContentItem(
                    content, 'memPayment', 'button', 'memPaymentMemHelp').text;
                this.noStatement = this.componentService.contentService.tryGetContentItem(content, 'memPayment', 'button', 'memPaymentMemNoStatementButton').text;
                this.validationModeText = this.componentService.contentService.tryGetContentItem(content, 'memPayment', 'pageText', 'memPaymentMemValidationMode').text;
                this.extraErrorText = this.componentService.contentService.tryGetContentItem(content, 'memPayment', 'error', 'memPaymentExtendedMemError').text;
                this.MEMDocumentError = this.componentService.contentService.tryGetContentItem(content, 'memPayment', 'error', 'memPaymentMemDocumentError').text;

                if (this.triedMEMImageConnectMessage && !this.MEMImage) {
                    this.MEMImage = this.MEMImagePlaceholder;
                }
            });
        this.componentService.domainService.domainInfo$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(domainInfo => {
                this.domainInfo = domainInfo;
                if (this.domainInfo != null) {
                    // mempayment not allowed, redirect to AccountPayment
                    if (!this.domainInfo.enableQuickPay && !this.domainInfo.requireDocumentCode) {
                        this.gotoAccountPay();
                    } else {
                        this.initializeMemForm();
                    }
                }
            });

        this.connectMessageService.getConnectMessageImageUrl('statementpreview').then(imageUrl => {
            this.triedMEMImageConnectMessage = true;
            if (imageUrl) {
                this.MEMImage = imageUrl;
            } else {
                this.MEMImage = this.MEMImagePlaceholder;
            }
        });
    }

    isNullOrEmpty(stringToCheck: string) {
        return !stringToCheck;
    }

    gotoAccountPay(): void {
        history.replaceState(null, null, '/accountpayment');
        this.ngZone.run(() => this.parentRouter.navigateByUrl('/accountpayment')).then();
        return;
    }

    initializeMemForm() {
        this.setMask();
        this.MEMErrorText = this.MEMErrorMessage;
        // qr/textMemCode performs login (though, we should be logged in already from redirect)
        if ((this.mode === 'textMemCode' || this.mode === 'qr') && !this.isNullOrEmpty(this.memCode)) {
            if (this.domainInfo.paymentValidationMode !== 'ZIP5' && this.domainInfo.paymentValidationMode !== 'XML') {
                this.login('');
            }
        }
        if (!this.isNullOrEmpty(this.mode) && this.mode.toLowerCase() === 'link') {
            if (this.domainInfo.desktopLinkValidationMask) {
                const tempMask: Array<string | RegExp> = [];
                this.domainInfo.desktopLinkValidationMask.split('~').forEach((s) => {
                    if (s.length === 1) {
                        tempMask.push(s);
                    }
                    if (s.indexOf('d') > -1) {
                        tempMask.push(/\d/);
                    }
                    if (s.indexOf('w') > -1) {
                        tempMask.push(/\w/);
                    }
                });
                this.secondaryAuthMask = tempMask;
            } else {
                this.secondaryAuthMask = false;
            }
        }
        if (this.mode === 'qr' || this.mode == null ) {
            if (this.domainInfo.paymentValidationMask) {
                const tempMask: Array<string | RegExp> = [];
                this.domainInfo.paymentValidationMask.split('~').forEach((s) => {
                    if (s.length === 1) {
                        tempMask.push(s);
                    }
                    if (s.indexOf('d') > -1) {
                        tempMask.push(/\d/);
                    }
                    if (s.indexOf('w') > -1) {
                        tempMask.push(/\w/);
                    }
                });
                this.secondaryAuthMask = tempMask;
            } else {
                this.secondaryAuthMask = false;
            }
        }
        if (this.mode === 'qpm') {
            this.hideMEMCode = true;
        }


        if (!this.isNullOrEmpty(this.mode) && this.mode.toLowerCase() === 'link' && !this.isNullOrEmpty(this.documentId)) {
            this.hideMEMCode = true;
            if (this.domainInfo.desktopLinkValidationMode !== 'ZIP5' && this.domainInfo.desktopLinkValidationMode !== 'XML') {
                this.login('');
            }
        }

        if (!this.isNullOrEmpty(this.documentId)) {
            if (this.domainInfo.desktopLinkValidationMode === 'ZIP5' || this.domainInfo.desktopLinkValidationMode === 'XML') {
                this.extraRequired = true;
                this.MEMErrorText = this.MEMMixedErrorMessage;
            }
        } else {
            if (this.domainInfo.paymentValidationMode === 'ZIP5' || this.domainInfo.paymentValidationMode === 'XML') {
                this.extraRequired = true;
                this.MEMErrorText = this.MEMMixedErrorMessage;
            } else {
                this.extraRequired = false;
            }
        }
    }

    setMask() {
        let memCodeType = this.domainInfo.memCodeType;
        if (memCodeType != null) {
            memCodeType = memCodeType.toUpperCase();
        } else {
            memCodeType = '';
        }

        if (memCodeType.includes('9 MIXED')) {
            this.MEMPlaceholder = 'BCD-FGH-JKL';
        } else if (memCodeType.includes('X-13')) {
            this.MEMPlaceholder = 'X-12345-1234-1234';
        }
    }

    hasMEMError(): boolean {
        return this.MEMErrorExists;
    }

    hasMemExtraError() {
        return this.extraErrorExists;
    }

    extraValidationRequired(): boolean {
        return this.extraRequired;
    }

    redirect(redirectPath: string) {
        this.ngZone.run(() => this.parentRouter.navigateByUrl(redirectPath)).then();
    }

    async login(memExtra: string, f: NgForm = null) {
        /// always a payment for now, until signup and email links.
        // here comes email links.
        let payment = true;
        let link = false;
        if (this.linkMEM) {
            payment = false;
            link = true;
            if (this.documentId.length !== 36) {
                this.extraErrorExists = true;
                this.extraErrorText = this.MEMDocumentError;
                this.disableSubmit = true;
                return;
            }
        }
        let result;
        try {
            result = await this.loginService.memLogin(this.memCode, this.documentId, memExtra, payment, link /*tbd for email*/);
            if (result.success) {
                this.token = result.token;
                this.componentService.storageService.store('token', result.token);
                this.componentService.storageService.store('onetimepayment', true);
                this.componentService.storageService.store('mem', this.memCode);
                localStorage?.setItem('enrollmentMemCode', this.memCode);
                localStorage?.setItem('enrollmentMemCodeStorageDate', '' + new Date());
                this.componentService.storageService.store('memExtra', memExtra);
                this.componentService.storageService.store('smsGUID', this.smsGUID);

                if (result.redirectLocation && result.redirectLocation.toString() !== '') {
                    const location = result.redirectLocation;
                    if (this.compareBaseUrl(location, window.location.origin)) {
                        this.MEMRedirect = true;
                        window.location.href = result.redirectLocation + 'redirect?mem=' + this.memCode;
                    }
                }
            } else {
                this.MEMErrorExists = true;
                if (this.extraValidationRequired()) {
                    this.extraErrorExists = true;
                    if (this.hideMEMCode) {
                        if (result.messages.length > 0) {
                            this.extraErrorText = this.componentService.handleValidationErrorCode(result.messages[0]);
                            this.hideMEMCode = false;
                        }
                    } else {
                        this.extraErrorText = result.userMessage;
                    }
                }
            }
        } catch (err) {
            if (f != null) {
                return this.clearForm(f);
            }
        } finally {
            if (!this.MEMErrorExists && !this.MEMRedirect) {
                this.checkCustomerAccountIdAndRedirect();
            }
        }
    }

    checkCustomerAccountIdAndRedirect(): void {
        // Check if they have a consumer, if they do not they do not have an CustomerAccountID set
        this.consumerService.getConsumer()
            .then(consumer => {
                if (consumer != null) {
                    this.redirect('/payment');
                } else {
                    // Make sure storage service clears out set information.
                    this.componentService.storageService.clearSession();

                    // Log them out since they dont have a CustomerAccountID
                    this.loginService.logout();

                    // Navigate to accountPayment page IF they have it enabled, else show error
                    // (I had set this to check enableAccountPay, but it may be enablePayments.
                    // Leaving this note in case this becomes an issue and we need to switch it back.)
                    if (!this.domainInfo != null && !this.domainInfo.requireDocumentCode) {
                        this.redirect('/accountpayment');
                    } else {
                        // error
                        this.redirect('/error');
                    }
                }
            });
    }

    clearForm(f: NgForm) {
        if (this.extraRequired && !this.linkMEM) {
            f.setValue({ memCode: '', MEMExtra: '' });
        } else if (this.extraRequired && this.linkMEM) {
            f.setValue({ memCode: this.memCode, MEMExtra: '' });
        } else if (!this.linkMEM) {
            f.setValue({ memCode: '' });
        }
        this.MEMErrorExists = true;
    }

    onSubmit(f: NgForm): any {
        if (f.value.memCode != null) {
            this.memCode = f.value.memCode.toUpperCase().trim();
        } else {
            if (this.mode !== 'qpm' && this.documentId == null) {
                this.MEMErrorExists = true;
                if (this.extraValidationRequired()) {
                    this.extraErrorExists = true;
                }
                return false;
            }
        }
        if (f.value.MEMExtra != null) {
            f.value.MEMExtra = f.value.MEMExtra.trim();
        }
        this.MEMErrorExists = false;
        this.extraErrorExists = false;
        this.componentService.storageService.clear('token');
        // validate mem code
        if (this.documentId == null
            && this.memCode.length !== 11
            && this.memCode.length !== 9
            && this.memCode.length !== 20
            && this.memCode.length !== 17
            && this.memCode.length !== 14) {
            this.MEMErrorExists = true;
        } else {
            if (this.documentId == null) {
                if (this.memCode.length === 9) {
                    this.memCode = this.memCode.replace(/([BCDFGHJKLMNPQRSTVWXYZ123456789]{3})([BCDFGHJKLMNPQRSTVWXYZ123456789]{3})([BCDFGHJKLMNPQRSTVWXYZ123456789]{3})/, '$1-$2-$3');
                }
                if (this.memCode.length === 17 && (this.memCode.startsWith('P') || this.memCode.startsWith('p'))) {
                    this.memCode = this.memCode.replace(/([Pp])([BCDFGHJKLMNPQRSTVWXYZ0123456789]{5})([BCDFGHJKLMNPQRSTVWXYZ0123456789]{5})([BCDFGHJKLMNPQRSTVWXYZ0123456789]{6})/, '$1-$2-$3-$4');
                }
                if (this.memCode.length === 14) {
                    this.memCode = this.memCode.replace(/([Xx])([0123456789]{5})([0123456789]{4})([0123456789]{4})/, '$1-$2-$3-$4');
                }
            }
            this.MEMErrorExists = false;
        }

        if (this.extraRequired) {
            if (this.MEMErrorExists) {
                this.extraErrorExists = true;
            }
            if (f.value.MEMExtra.length <= 0) {
                this.extraErrorExists = true;
            }
        }

        if (this.MEMErrorExists || this.extraErrorExists) {
            return false;
        }

        this.login(f.value.MEMExtra, f);
    }

    clearErrors() {
        this.MEMErrorExists = false;
        this.extraErrorExists = false;
        this.disableSubmit = false;
    }

    disableSubmitButton(): boolean {
        return this.disableSubmit;
    }

    compareBaseUrl(firstUrl: string, secondUrl: string): boolean {
        // remove '/' because trailing '/'
        // split on '.' to compare domain and top-level domain (e.g. 'mysecurebill' and 'com')
        const removeSlashRegex = /\//gm;
        const firstLocation = firstUrl.replace(removeSlashRegex, '').split('.');
        const secondLocation = secondUrl.replace(removeSlashRegex, '').split('.');

        // if the base url address is mysecurebill?? then good to go
        if (firstLocation.length >= 2 && secondLocation.length >= 2 && firstLocation[1] === secondLocation[1]) {
            if (firstLocation[2] === secondLocation[2]) {
                return true;
            }
        }
        return false;
    }
}
