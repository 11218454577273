import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output
} from '@angular/core';

@Component({
    selector: 'ng2-dropdown-button',
    styles: [ require('./style.css') ],
    template: require('./template.html')
})
export class Ng2DropdownButton {
    @Output() public onMenuToggled: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() public showCaret = true;

    constructor(private element: ElementRef) {}

    /**
     * @name toggleMenu
     * @desc emits event to toggle menu
     */
    public toggleMenu(): void {
        this.onMenuToggled.emit(true);
    }

    /**
     * @name getPosition
     * @desc returns position of the button
     * @returns {DOMRect}
     */
    public getPosition(): DOMRect {
      return this.element.nativeElement.getBoundingClientRect();
    }
}
