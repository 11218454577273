import { Component, Input } from '@angular/core';

@Component({
    selector: 'statement-last-payment',
    template: require('./statementlastpayment.component.html'),
    styles: [require('./statementlastpayment.component.css')]
})

export class StatementLastPaymentComponent {
    @Input() lastPaymentDate: string;
    @Input() lastPaymentText: string;  // keeping this component dumb
}
