/* tslint:disable:triple-equals */
import { Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IDomainInfo } from '../../models/domainInfo';
import { FutureDateAccountPaymentConfirmation } from '../../models/futuredateaccountpaymentconfirmation';
import { ComponentService } from '../../services/component/component.service';

@Component({
    selector: 'futuredateaccountpaymentconfirmation',
    template: require('./futuredateaccountpaymentconfirmation.component.html'),
    styles: [require('./futuredateaccountpaymentconfirmation.component.css')]
})
export class FutureDateAccountPaymentConfirmationComponent implements OnInit, OnDestroy {
    constructor(
        private parentRouter: Router,
        private componentService: ComponentService,
        private ngZone: NgZone
    ) { }

    private ngUnsubscribe: Subject<any> = new Subject();

    userIsLoggedIn = false;
    domainInfo: IDomainInfo;
    paymentConfirmation: FutureDateAccountPaymentConfirmation;
    startOverButtonLink = '/';
    isOneTimePayment = false;

    confirmationHeader: string;
    confirmationSubHeader: string;
    startOverButtonText: string;
    confirmationNumberText: string;
    paymentDateText: string;
    wereSorryText = 'We\'re Sorry';
    noLongerAvailableText = 'This page is no longer available.';
    quickPayIdentifier: string;

    ngOnInit() {
        this.componentService.scrollPageToTop();

        this.componentService.contentService.content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((content: any) => {
                this.confirmationHeader = this.componentService.contentService.tryGetContentItem(content, 'payment', 'scheduled', 'confirmationHeader').text;
                this.confirmationSubHeader = this.componentService.contentService.tryGetContentItem(content, 'payment', 'scheduled', 'confirmationSubHeader').text;
                this.startOverButtonText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'scheduled', 'confirmationStartOverButton').text;
                this.confirmationNumberText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'scheduled', 'confirmationConfirmationNumberText').text;
                this.paymentDateText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'scheduled', 'confirmationPaymentDateText').text;
                this.wereSorryText = this.componentService.contentService.tryGetContentItem(content, 'confirmation', 'pageText', 'paymentConfirmationWereSorryText').text;
                this.noLongerAvailableText = this.componentService.contentService.tryGetContentItem(content, 'confirmation', 'pageText', 'paymentConfirmationNoLongerAvailableText').text;
            });

        this.componentService.domainService.domainInfo$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(domainInfo => {
            this.domainInfo = domainInfo;
            if (this.domainInfo != null) {
                this.initializeConfirmationPage();
            }
        });
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();

        // Called once, before the instance is destroyed.
        this.componentService.storageService.clear('futuredatepaymentconfirmation');
        this.componentService.storageService.clear('paymentsource');
    }

    initializeConfirmationPage() {
        if (this.componentService.storageService.exists('onetimepayment')) {
            this.isOneTimePayment = true;
        }

        this.userIsLoggedIn = this.componentService.userIsLoggedIn();
        if (!this.isOneTimePayment && this.userIsLoggedIn) {
            // Not account payment and not onetimepayment -> redirect to home, otherwise go to /
            this.startOverButtonLink = '/home';
        } else if (this.isOneTimePayment) {
            this.startOverButtonLink = '/mempayment';
        }


        if (this.componentService.storageService.exists('futuredatepaymentconfirmation')) {
            this.paymentConfirmation = this.componentService.storageService.retrieve('futuredatepaymentconfirmation');
        } else {
            this.paymentConfirmation = null;
        }

        this.clearPaymentData();
    }

    private clearPaymentData() {
        if (this.componentService.storageService.exists('onetimepayment')) {
            // Store token as quickPayIdentifier
            this.quickPayIdentifier = this.componentService.storageService.retrieve('token');
            // Clear seassions
            this.componentService.storageService.clearSession();
            // Store quickPayIdentifier to storage.  The connect message link should be set to redirect?smsenrollment=smsenrollment
            this.componentService.storageService.store('quickpayidentifier', this.quickPayIdentifier);

        } else {
            this.componentService.storageService.clear('wallet');
        }
    }

    startOverLink() {
        this.ngZone.run(() => this.parentRouter.navigateByUrl(this.startOverButtonLink)).then();
    }
}
