import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ComponentService } from '../../services/component/component.service';
import { IDomainInfo } from './../../models/domaininfo';

@Component({
    selector: 'landing-list',
    template: require('./landinglist.component.html'),
    styles: [require('./landinglist.component.css')]
})
export class LandingListComponent implements OnInit, OnDestroy {
    @Input() domainInfo: IDomainInfo;

    @Input() payNowHeadline: string;
    @Input() payNowDescription: string;
    @Input() payNowText: string;
    @Input() payNowRoute = '/mempayment';
    @Input() manageAccountHeadline: string;
    @Input() manageAccountDescription: string;
    @Input() manageAccountText: string;
    @Input() manageAccountTextOther: string;
    @Input() donationHeadline: string;
    @Input() donationDescription: string;
    @Input() donationText: string;

    @Output() showLogin = new EventEmitter<boolean>();

    private ngUnsubscribe: Subject<any> = new Subject();

    constructor(private componentService: ComponentService) {
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit() {
        this.componentService.contentService.content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((content: any) => {
                this.payNowHeadline = this.componentService.contentService.tryGetContentItem(content, 'landingPage', 'pageText', 'landingPagePayNowHeadline').text;
                this.payNowText = this.componentService.contentService.tryGetContentItem(content, 'landingPage', 'button', 'landingPagePayNowButton').text;
                this.payNowDescription = this.componentService.contentService.tryGetContentItem(content, 'landingPage', 'pageText', 'landingPagePayNowDescription').text;
                this.manageAccountHeadline = this.componentService.contentService.tryGetContentItem(content, 'landingPage', 'pageText', 'landingPageManageAccountHeadline').text;
                this.manageAccountDescription = this.componentService.contentService.tryGetContentItem(content, 'landingPage', 'pageText', 'landingPageManageAccountDescription').text;
                this.manageAccountText = this.componentService.contentService.tryGetContentItem(content, 'landingPage', 'button', 'landingPageManageAccountButton').text;
                this.manageAccountTextOther = this.componentService.contentService.tryGetContentItem(content, 'landingPage', 'button', 'landingPageManageNewAccountButton').text;
                this.donationHeadline = this.componentService.contentService.tryGetContentItem(content, 'landingPage', 'pageText', 'landingPageDonationHeadline').text;
                this.donationDescription = this.componentService.contentService.tryGetContentItem(content, 'landingPage', 'pageText', 'landingPageDonationDescription').text;
                this.donationText = this.componentService.contentService.tryGetContentItem(content, 'landingPage', 'button', 'landingPageDonationButton').text;
            });
    }

    canLogin(): boolean {
        return this.domainInfo && !this.domainInfo.domainMode.includes('SSO') && !this.domainInfo.deliveryMode.includes('None');
    }

    changeLogin(val: boolean) {
        this.showLogin.emit(val);
    }
}
