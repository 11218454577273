export class RealtimePayment {
    PaymentAmount: number;
    PaidAccounts: BalanceDetailsPayment[];
    PaymentPlanPaymentAmount: number;
}

export class BalanceDetailsPayment {
    accountNumber: string;
    amount: number;
}
