import { Component, EventEmitter, Input, NgZone, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'msb-link-ada',
    template: require('./msblinkada.component.html'),
    styles: [require('./msblinkada.component.css')]
})
export class MSBLinkADAComponent {
    constructor(
        private parentRouter: Router,
        private ngZone: NgZone
    ) { }

    @Input() linkRoute = '$';
    @Input() linkText: string;
    @Input() align: string;
    @Input() size = 'default';
    @Input() styleOverride: any;
    @Input() linkTarget = '';
    @Input() linkId = '';
    @Input() color = 'default';
    @Input() inverseIconColors = false;
    @Input() isDisabled = false;
    @Input() iconName = 'glyphicon-menu-right';
    @Input() settings = '';
    @Input() isMobile = false;

    @Output()
    public linkFunction: EventEmitter<MSBLinkADAComponent> = new EventEmitter<MSBLinkADAComponent>();

    /**
     * The purpose of this component is to have an MSB Link which is ADA compliant.
     * This component isn't complete, but created with a future ADA Epic in mind
     * @param {*} event
     *
     * @memberof MSBLinkADAComponent
     */
    linkClick(event: any) {
    }
}
