import { Component, Input } from '@angular/core';
import { Activity } from '../../../models/activity';


@Component({
    selector: 'activity',
    template: require('./activity.component.html'),
    styles: [require('./activity.component.css')]
})

export class ActivityComponent {
    @Input() activity: Activity;
    @Input() activityDate: string;
    @Input() iconLibrary: string;
    @Input() activityIcon: string;
    @Input() activityAmount: number;
    @Input() activityDownload: boolean;
    @Input() activityMessage: string;
}
