import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpHeaders,
    HttpInterceptor,
    HttpParams,
    HttpRequest,
    HttpXsrfTokenExtractor
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IBlobOptions, IJsonOptions } from '../models/genericresponse';


export function jsonRequestOptions(
    headers: HttpHeaders = generateHeaders(),
    params?: HttpParams
): IJsonOptions {
    return {
        headers,
        responseType: 'json',
        observe: 'response',
        params
    };
}

export function blobRequestOptions(headers?: HttpHeaders, params?: HttpParams): IBlobOptions {
    return {
        headers,
        responseType: 'blob',
        observe: 'response',
        params,
    };
}

export function parseErrorBlob(error: HttpErrorResponse): Promise<any> {
    const reader = new FileReader();

    return new Promise((resolve, reject) => {
        reader.readAsText(error.error);
        reader.onloadend = (e: any) => {
            reject(JSON.parse(reader.result as any));
        };
        reader.onerror = () => {
            reject(new Error('Error parsing JSON error from API response'));
        };
    });
}

export function generateHeaders(
    contentType: string = 'application/json',
    accept: string = 'application/json'
): HttpHeaders {
    return new HttpHeaders({
        'Content-Type': contentType,
        Accept: accept,
    });
}

@Injectable()
export class HttpXsrfInterceptor implements HttpInterceptor {

    constructor(private tokenExtractor: HttpXsrfTokenExtractor) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Do not pass the Ultra cross-site (XSRF) token when making requests to any App Orchard endpoint.
        // We will get HTTP 400 responses per their CORS policy.
        if (!req.url.toLowerCase().includes('apporchard')) {
            const headerName = 'X-XSRF-TOKEN';
            const token = this.tokenExtractor.getToken() as string;
            if (token !== null && !req.headers.has(headerName)) {
                req = req.clone({headers: req.headers.set(headerName, token)});
            }
        }

        return next.handle(req);
    }
}
