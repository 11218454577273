import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ICommunicationPreferences } from '../../../models/communicationPreferences';
import { CommunicationPreference, ICommunicationPreferenceChange, ICommunicationRowChange } from '../../../models/communicationPreferenceType';
import { ComponentService } from '../../../services/component/component.service';
import { ConsumerService } from '../../../services/consumer/consumer.service';
import { ConsumerContactDetailsModalComponent } from '../ConsumerContactDetailsModal/consumercontactdetailsmodal.component';

type RowName = 'statements' | 'autopay' | 'recurring' | 'paymentPlan';

@Component({
    selector: 'communication-preferences',
    template: require('./communicationpreferences.component.html'),
    styles: [require('./communicationpreferences.component.css')]
})
export class CommunicationPreferencesComponent implements OnInit, OnDestroy {

    constructor(private componentService: ComponentService,
                private consumerService: ConsumerService) { }
    // This makes the string constants available in
    // the template
    CommunicationPreference = CommunicationPreference;

    @Input() emailAddress: string;
    @Input() phoneNumber: string;
    @Input() communicationPreferences: ICommunicationPreferences;
    @Input() isLoading: boolean;

    // Prevents SMS Preference from being set
    @Input() stopSms: boolean = null;

    @Input() customerAllowsPaperStmts = true;
    @Input() customerAllowsTextStmts = false;
    @Input() customerAllowsEStmts = true;
    @Input() customerAllowsMultiStmt = false;

    @Input() customerAllowsPaperAutoPay = false;
    @Input() customerAllowsTextAutoPay = false;

    @Input() customerAllowsPaperPaymentPlan = false;
    @Input() customerAllowsTextPaymentPlan = false;

    @Input() customerAllowsPaperRecurring = false;
    @Input() customerAllowsTextRecurring = false;

    @Input() customerAllowsPaymentPlans = false;
    @Input() customerAllowsRecurring = false;
    @Input() customerAllowsAutoPay = false;

    @Input() consumerHasAutoPay = false;
    @Input() consumerHasPmtPlan = false;
    @Input() consumerHasRecurring = false;
    @Input() consumerHasConfirmedEmail = false;

    @Output() onChange = new EventEmitter<ICommunicationPreferenceChange[]>();

    @ViewChild('consumerProfileModal', { static: false }) consumerProfileModal: ConsumerContactDetailsModalComponent;

    unsubscribedChecked = false;

    // Content items
    statementHeader: string;
    autoPayHeader: string;
    paymentPlanHeader: string;
    recurringHeader: string;
    emailWarning: string;
    phoneWarning: string;
    emailAddressHeader = 'EMAIL';
    phoneNumberHeader = 'PHONE';
    componentHeader = 'Delivery Preferences';
    emailHeader = 'Email';
    smsHeader = 'SMS';
    paperHeader = 'Paper';
    requiredCommMsg = 'This option is required';

    private ngUnsubscribe: Subject<any> = new Subject();
    /**
     * This map represents a quick way to convert the event
     * arguments to the correct communication preference value
     */
    private readonly multiKeyMap = {
        statements: {
            email: CommunicationPreference.StatementEmail,
            sms: CommunicationPreference.StatementSms,
            paper: CommunicationPreference.StatementPaper
        },
        autopay: {
            email: CommunicationPreference.AutoPayEmail,
            sms: CommunicationPreference.AutoPaySms,
            paper: CommunicationPreference.StatementPaper
        },
        recurring: {
            email: CommunicationPreference.StatementEmail,
            sms: CommunicationPreference.StatementSms,
            paper: CommunicationPreference.StatementPaper
        },
        paymentPlan: {
            email: CommunicationPreference.StatementEmail,
            sms: CommunicationPreference.StatementSms,
            paper: CommunicationPreference.StatementPaper
        },
    };

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit() {
        this.componentService
            .contentService
            .content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((content: any) => {
                this.autoPayHeader = this.getContentItem(content, 'profileAutoPayNotificationHeader');
                this.paymentPlanHeader = this.getContentItem(content, 'profilePaymentPlanNotificationHeader');
                this.recurringHeader = this.getContentItem(content, 'profileRecurringPlanAgreementHeader');
                this.statementHeader = this.getContentItem(content, 'profileStatementDeliveryText');
                this.emailAddressHeader = this.getContentItem(content, 'profileConsumersEmailHeader');
                this.phoneNumberHeader = this.getContentItem(content, 'profileConsumersPhoneNumber');
                this.componentHeader = this.getContentItem(content, 'profileCommunicationPreferencesHeader');
                this.emailHeader = this.getContentItem(content, 'profileEmailCommunicationPreferences');
                this.smsHeader = this.getContentItem(content, 'profileTextCommunicationPreferences');
                this.paperHeader = this.getContentItem(content, 'profilePaperCommunicationPreferences');
                this.emailWarning = this.getContentItem(content, 'profileEmailNotVerified');
                this.requiredCommMsg = this.getContentItem(content, 'profileNoneSelectedTooltip');
            });
    }

    // ngOnChanges is the only Angular lifecycle hook that allows me
    // to capture the phoneNumber value. It is hit 3-4 times, so this
    // check is inside a statement that prevents it from running
    // until we have the phoneNumber and prevents it from re-running.
    async ngOnChanges() {
        if (!this.unsubscribedChecked && this.phoneNumber && this.phoneNumber !== 'None') {
            const unsubscribedPhoneResponse = await this.consumerService.verifyNumberNotBlacklisted(this.phoneNumber);

            this.stopSms = unsubscribedPhoneResponse.messages && unsubscribedPhoneResponse.messages.length ? true : false;
            this.unsubscribedChecked = true;
        } else if (!this.stopSms && (!this.phoneNumber || this.phoneNumber === 'None')) {
            this.stopSms = true;
        }
    }

    changeEmailOrPhone(): void {
        this.consumerProfileModal.openModal();
    }

    onEmailUpdated(emailAddress: string): void {
        this.emailAddress = emailAddress;
    }

    onPhoneNumberUpdated(phoneNumber: string): void {
        this.phoneNumber = phoneNumber;
    }

    private getContentItem(content: any, name: string): string {
        return this
            .componentService
            .contentService
            .tryGetContentItem(
                content,
                'profile',
                'pageText',
                name
            ).text;
    }

    updateEmailOrPhone(row: RowName, changes: ICommunicationRowChange[]) {
        if (this.communicationPreferences != null) {
            if (row === 'autopay' && ((changes[0].name === 'sms' && this.communicationPreferences.autopaySmsEnabled === true) ||
            (changes[0].name === 'email' && this.communicationPreferences.autopayEmailEnabled === true))) {
                return;
            }
        }

        // A user shouldn't be able to click email w/o a stored email
        if (row === 'autopay' && changes[0].name === 'email' && this.emailAddress === '') {
            return;
        }

        // A user shouldn't be able to click text w/o both a stored email and phone number
        if (row === 'autopay' && changes[0].name === 'sms'
                              && (this.phoneNumber === 'None' || this.emailAddress === '')) {
            return;
        }

        const mapped = changes.map(change => this.newCommChange(row, change));

        // This change in communication preferences is getting emitted up
        // to the AccountProfileComponent > updateCommunicationPreference()
        this.onChange.emit(mapped);
    }

    newCommChange(row: RowName, change: ICommunicationRowChange): ICommunicationPreferenceChange {
        const type = this.multiKeyMap[row][change.name];
        return {type, newValue: change.option};
    }
}
