import { Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Roles } from '../../../models/roles';
import { ComponentService } from '../../../services/component/component.service';
import { ConsumerService } from '../../../services/consumer/consumer.service';
import { LoginService } from '../../../services/login/login.service';

@Component({
    selector: 'landing-item',
    template: require('./landingitem.component.html'),
    styles: [require('./landingitem.component.css')]
})
export class LandingItemComponent implements OnInit, OnDestroy {

    loading = false;
    token: string = null;
    bodyText: string = null;
    passwordErrorText: string = null;
    loggedIn = false;
    forceReset = false;
    showLogin = true;
    showForgotPassword = false;
    showPasswordResetEmailMessage = false;
    showEmptyUsernameError = false;
    showEmailAddressRequiredError = false;
    showInvalidEmailFormatError = false;
    showEmailAddressNotFoundError = false;
    showLandingPageUsernameRecoveryMessage = false;
    disablePasswordResetButton = false;
    disableUsernameRecoveryButton = false;

    @Input() bodyCopy: string;
    @Input() buttonText = '';
    @Input() buttonTextOther = '';
    @Input() buttonRoute: string = null;
    @Input() headline: string;
    @Input() loginForm = false;
    loginPageForgottenPassword: string;
    landingPagePasswordResetEmailInfo: string;
    landingPagePasswordResetMessage: string;
    landingPagePasswordResetButton: string;
    passwordLengthError = false;
    passwordError = false;
    userNameError = false;
    landingPageUserNameTooShortText = '';
    landingPagePasswordTooShortText = '';
    landingPageUserNamePasswordNotValidText = '';
    landingPagePasswordText = '';
    landingPageUserNameText = '';
    landingPageUserNameRequiredText = '';
    landingPageRecoverUsernameHeadline = '';
    landingPageEmailAddressText = '';
    landingPageUsernameRecoveryButton = '';
    landingPageEmailAddressRequired = '';
    landingPageEmailAddressNotFound = '';
    landingPageInvalidEmailFormat = '';
    landingPageUsernameRecoveryMessage = '';
    landingPageReturnToLoginButton = '';

    @Output() changeLogin = new EventEmitter<boolean>();
    @Output() changeReset = new EventEmitter<boolean>();

    private ngUnsubscribe: Subject<any> = new Subject();
    public reenableButton = new EventEmitter<boolean>(false);

    constructor(
        private componentService: ComponentService,
        private loginService: LoginService,
        private parentRouter: Router,
        private consumerService: ConsumerService,
        private ngZone: NgZone
    ) {}

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit() {
        this.componentService.contentService.content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((content: any) => {
                this.loginPageForgottenPassword = this.componentService.contentService.tryGetContentItem(content, 'login', 'link', 'loginPageForgottenPassword').text;
                this.landingPagePasswordResetEmailInfo = this.componentService.contentService.tryGetContentItem(content, 'landingPage', 'pageText', 'landingPagePasswordResetEmailInfo').text;
                this.landingPagePasswordResetButton = this.componentService.contentService.tryGetContentItem(content, 'landingPage', 'button', 'landingPagePasswordResetButton').text;
                this.landingPagePasswordResetMessage = this.componentService.contentService.tryGetContentItem(content, 'landingPage', 'pageText', 'landingPagePasswordResetMessage').text;
                this.landingPageUserNameTooShortText = this.componentService.contentService.tryGetContentItem(content, 'landingPage', 'error', 'landingPageUserNameTooShortText').text;
                this.landingPagePasswordTooShortText = this.componentService.contentService.tryGetContentItem(content, 'landingPage', 'error', 'landingPagePasswordTooShortText').text;

                this.landingPageUserNamePasswordNotValidText =
                    this.componentService.contentService.tryGetContentItem(
                        content,
                        'landingPage',
                        'error',
                        'landingPageUserNamePasswordNotValidText').text;

                this.landingPagePasswordText = this.componentService.contentService.tryGetContentItem(content, 'landingPage', 'pageText', 'landingPagePasswordText').text;
                this.landingPageUserNameText = this.componentService.contentService.tryGetContentItem(content, 'landingPage', 'pageText', 'landingPageUserNameText').text;
                this.landingPageUserNameRequiredText = this.componentService.contentService.tryGetContentItem(content, 'landingPage', 'error', 'landingPageUserNameRequiredText').text;
                this.landingPageRecoverUsernameHeadline = this.componentService.contentService.tryGetContentItem(content, 'landingPage', 'pageText', 'landingPageRecoverUsernameHeadline').text;

                this.landingPageEmailAddressText =
                    this.componentService.contentService.tryGetContentItem(
                        content,
                        'landingPage',
                        'pageText',
                        'landingPageEmailAddressText').text;

                this.landingPageUsernameRecoveryButton = this.componentService.contentService.tryGetContentItem(content, 'landingPage', 'button', 'landingPageUsernameRecoveryButton').text;
                this.landingPageEmailAddressRequired = this.componentService.contentService.tryGetContentItem(content, 'landingPage', 'error', 'landingPageEmailAddressRequired').text;
                this.landingPageEmailAddressNotFound = this.componentService.contentService.tryGetContentItem(content, 'landingPage', 'error', 'landingPageEmailAddressNotFound').text;
                this.landingPageInvalidEmailFormat = this.componentService.contentService.tryGetContentItem(content, 'landingPage', 'error', 'landingPageInvalidEmailFormat').text;
                this.landingPageUsernameRecoveryMessage = this.componentService.contentService.tryGetContentItem(content, 'landingPage', 'pageText', 'landingPageUsernameRecoveryMessage').text;
                this.landingPageReturnToLoginButton = this.componentService.contentService.tryGetContentItem(content, 'landingPage', 'button', 'landingPageReturnToLoginButton').text;
            });

        this.bodyText = this.bodyCopy;
    }

    login(event: any): boolean {
        if (this.buttonRoute != null) {
            this.ngZone.run(() => this.parentRouter.navigateByUrl(this.buttonRoute)).then();
            return false;
        }
        this.showLogin = !this.showLogin;
        this.changeLogin.emit(this.showLogin);
        this.componentService.storageService.clearSession();
        return true;
    }

    hasPasswordLengthError() {
        return this.passwordLengthError;
    }

    hasPasswordError() {
        return this.passwordError;
    }

    hasUserNameError() {
        return this.userNameError;
    }

    hasEmptyUsernameError() {
        return this.showEmptyUsernameError;
    }

    hasEmptyEmailError() {
        return this.showEmailAddressRequiredError;
    }

    forgotPassword(): void {
        this.componentService.setForgotUsernameAndPasswordReset(true);

        this.showLogin = false;
        this.showForgotPassword = true;
    }

    enablePasswordReset() {
        this.disablePasswordResetButton = false;
    }

    enableUsernameRecovery(resetForm: NgForm) {
        const recoverThisUsername = resetForm.value.recoverUsernameForThisEmailAddress;

        this.showEmailAddressRequiredError = false;
        this.showInvalidEmailFormatError = false;

        if (recoverThisUsername && !this.componentService.emailRegex.test(recoverThisUsername)) {
            this.showInvalidEmailFormatError = true;
        }
    }

    sendPasswordResetOrUsernameRecoveryEmail(resetForm: NgForm, isPasswordReset: boolean) {
        if (isPasswordReset) {
            this.startLoading();

            this.showEmptyUsernameError = false;

            const resetPasswordForThisUsername = resetForm.value.resetPasswordForThisUsername;
            if (!resetPasswordForThisUsername) {
                this.showEmptyUsernameError = true;
                this.doneLoading();
            } else {
                this.consumerService.sendPasswordResetEmail(resetForm.value.resetPasswordForThisUsername).then(
                    response => {
                        // show the same message regardless of success (security measure)
                        this.showPasswordResetEmailMessage = true;
                        this.disablePasswordResetButton = true;
                        this.doneLoading();
                    }).catch(
                    error => {
                        this.showPasswordResetEmailMessage = true;
                        this.disablePasswordResetButton = true;
                        this.doneLoading();
                    }
                );
            }
        } else {
            this.showEmailAddressNotFoundError = false;

            // Only check empty email input on submit
            if (!resetForm.value.recoverUsernameForThisEmailAddress) {
                this.showEmailAddressRequiredError = true;
                this.doneLoading();

                return;
            } else if (this.showInvalidEmailFormatError) {
                this.doneLoading();

                return;
            }

            this.showInvalidEmailFormatError = false;

            if (!this.showEmailAddressRequiredError) {
                this.startLoading();

                this.consumerService.verifyEmailExists(resetForm.value.recoverUsernameForThisEmailAddress).then(
                    emailExists => {
                        if (emailExists) {
                            this.showLandingPageUsernameRecoveryMessage = true;
                        } else {
                            this.showEmailAddressNotFoundError = true;
                        }

                        this.doneLoading();
                    }).catch(
                    error => {
                        this.showEmailAddressNotFoundError = true;
                        this.doneLoading();
                    }
                );
            }
        }
    }

    redirect(url: string = '') {
        if (url.length > 0) {
            this.ngZone.run(() => this.parentRouter.navigateByUrl(url)).then();
        } else {
            this.ngZone.run(() => this.parentRouter.navigateByUrl('/home')).then();
        }
        this.loggedIn = true;
    }

    async onSubmit(f: NgForm) {
        this.startLoading();

        const saveUserName: string = f.value.userName;

        this.passwordError = false;
        this.componentService.storageService.clearSession();
        this.userNameError = f.value.userName.length < 4;
        this.passwordLengthError = f.value.password.length < 8;

        if (this.userNameError || this.passwordLengthError) {
            this.doneLoading();
            return false;
        }

        let login;
        try {
            login = await this.loginService.login(f.value.userName, f.value.password);

        } catch (error) {
            if (f != null) {
                f.setValue({ password: '', userName: saveUserName });
                this.passwordError = true;
            }
            this.doneLoading();
        }

        if (login && login.success) {
            this.token = login.token;
            this.componentService.storageService.store('token', this.token);
        } else {
            this.passwordError = true;
            if (!!login && !!login.userMessage) {
                this.passwordErrorText = login.userMessage;
            } else {
                this.passwordErrorText = this.landingPageUserNamePasswordNotValidText;
            }
            this.doneLoading();
        }

        if (!this.passwordError) {
            // Get the consumer at login before redirecting to the home page.
            // This will prevent multiple components calling the API for the consumer object simultaneously
            await this.consumerService.getConsumer(this.token);

            // Get the consumer account and check forcePasswordReset flag before redirecting.
            const account = await this.consumerService.getConsumerAccount();
            const roles = await this.consumerService.getConsumerRoles();
            if (account.forcePasswordReset) {
                this.changeReset.emit(true);
            } else {
                this.componentService.storageService.store('roles', roles);
                if (this.componentService.hasRole(Roles.StatementsOnly)) {
                    this.redirect('/statements');
                } else {
                    this.redirect();
                }
            }
            this.doneLoading();
        }
    }

    private startLoading() {
        this.loading = true;
    }

    private doneLoading() {
        this.loading = false;
    }

    private isLoading(): boolean {
        return this.loading;
    }

    returnToLogin(): void {
        this.componentService.setForgotUsernameAndPasswordReset(false);

        this.ngZone.run(() => this.parentRouter.navigateByUrl('/')).then();
    }

    get isForgotUsernameOrPasswordResetOpen(): boolean {
        return this.componentService.isForgotUsernameOrPasswordResetOpen;
    }
}
