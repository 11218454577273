import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { StorageService } from '../storage/storage.service';

@Injectable()
export class MemCodeLoginGuard implements CanActivate {
    constructor(private storageService: StorageService, private router: Router) {
    }

    /**
     * Inspects if there is a MEM code and auth token in local storage.
     * If so, we want to prevent navigation to wherever MemCodeLoginGuard gets applied.
     * @param _route (unused)
     * @param _state (unused)
     */
    canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): boolean {
        // QR and 'text' MEM codes are stored under the same key.
        const memCode = this.storageService.retrieve('mem');
        const token = this.storageService.retrieve('token');

        const canActivate = !(!!memCode && !!token);

        if (!canActivate) {
            this.router.navigateByUrl('/mempayment');
            return false;
        }

        return true;
    }
}
