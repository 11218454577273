/* tslint:disable:triple-equals */
import { ChangeDetectorRef, OnDestroy } from '@angular/core';
import { AbstractControl, FormGroup, ValidationErrors } from '@angular/forms';
import { Subject } from 'rxjs';
import { ValidationMessages } from '../../../models/validationmessages';

export abstract class BaseReactiveFormComponent implements OnDestroy {
    formGroup: FormGroup;
    isFormValid: boolean;

    protected ngUnsubscribe: Subject<any> = new Subject();

    protected abstract buildForm(): void;
    protected abstract onSubmit(): Promise<void>;

    protected constructor(public cdRef: ChangeDetectorRef) {
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    protected clearValidators(formControl: AbstractControl): void {
        if (formControl) {
            formControl.clearValidators();
            formControl.updateValueAndValidity();
        }
    }

    protected clearFormGroupValidators(formGroup: FormGroup): void {
        if (formGroup) {
            Object.keys(formGroup.controls).forEach(controlKey => {
                this.clearValidators(formGroup.get(controlKey));
            });
        }
    }

    protected getFormValidationErrors(): ValidationMessages[] {
        const validationErrors: ValidationMessages[] = [];
        Object.keys(this.formGroup.controls).forEach(key => {
            const controlErrors: ValidationErrors = this.formGroup.get(key).errors;
            if (controlErrors != null) {
                Object.keys(controlErrors).forEach(keyError => {
                    validationErrors.push({controlName: key, errorKey: keyError});
                });
            }
        });

        return validationErrors;
    }

    protected setIsFormValid(validityStatus: string): void {
        this.isFormValid = validityStatus == 'VALID';
        // workaround for ValueChangedAfterChecked error:
        // tslint:disable-next-line: max-line-length
        // https://blog.angularindepth.com/everything-you-need-to-know-about-the-expressionchangedafterithasbeencheckederror-error-e3fd9ce7dbb4
        this.cdRef.detectChanges();
    }
}

