/* tslint:disable:triple-equals */
import { HttpClient, HttpErrorResponse, HttpResponse, } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from '../../app.config';
import { ArchiveZipFile } from '../../models/archivezipfile';
import { GenericResponse, Response } from '../../models/genericresponse';
import { StorageService } from '../../services/storage/storage.service';
import { blobRequestOptions, generateHeaders, jsonRequestOptions, parseErrorBlob } from '../../util/HttpClientUtility';
import { LoggingService } from '../logging/logging.service';

@Injectable()
export class ArchiveService {
    private domain = '';
    private domainUrl = this.config.getConfig('domainInfo');
    private archiveDocumentPdfPath = this.config.getConfig('archiveDocumentPdfPath');
    private archiveReceiptPdfPath = this.config.getConfig('archiveReceiptPdfPath');
    private secureEmailPdfPath = this.config.getConfig('secureEmailPdfPath');
    private secureEmailCheckGUIDPath = this.config.getConfig('secureEmailCheckGUIDPath');
    private archiveHistoryPath = this.config.getConfig('archiveHistoryPath');
    private validateDocumentGuidAuthenticationPath = this.config.getConfig('validateDocumentGuidAuthenticationPath');
    private securePdfPath = this.config.getConfig('openPDF');

    constructor(
        private http: HttpClient,
        private config: AppConfig,
        private storage: StorageService,
        private loggingService: LoggingService
    ) {
        this.domain = this.storage.getDomain();
    }

    /**
     * Gets the document blob from the ConsumerAPI -> Archive Service based on the passed in document GUID
     *
     * @param {string} documentGUID
     * @returns {Promise<Blob>}
     *
     * @memberof ArchiveService
     */
    public async getArchiveDocumentPdf(documentGUID: string): Promise<Blob> {
        return this.callArchiveDocumentPdfApi(documentGUID);
    }

    /**
     * Gets the receipt blob from the ConsumerAPI -> Archive Service based on the passed in document GUID
     * Needs to take a token param because of the confirmation page and the different ways that retrieves receipts
     *
     * @param {string} receiptGUID
     * @param {string} [token]
     * @returns {Promise<Blob>}
     * @memberof ArchiveService
     */
    public async getArchiveReceiptPdf(receiptGUID: string, token?: string): Promise<Blob> {
        return this.callArchiveReceiptPdfApi(receiptGUID, token);
    }

    /**
     * Gets the Document or Receipt Blob from the ConsumerAPI -> Archive Service based on the PIN,
     * DocumentGUID and Secure GUID email.
     *
     * @param {string} pin
     * @param {string} documentGUID
     * @param {string} secureEmailGUID
     * @returns {Promise<Blob>}
     *
     * @memberof ArchiveService
     */
    public async getSecureEmail(pin: string, documentGUID: string, secureEmailGUID: string): Promise<Blob> {
        return this.callGetSecureEmailApi(pin, documentGUID, secureEmailGUID);
    }

    /**
     * Gets the Document or Receipt Blob from the ConsumerAPI -> Archive Service
     * based on the PIN, DocumentGUID.
     *
     * @param {string} pin
     * @param {string} documentGUID
     * @returns {Promise<Blob>}
     *
     * @memberof ArchiveService
     */
    public async isValidDocumentPin(pin: string, documentGUID: string, secureEmailGUID: string, isEmail: boolean): Promise<boolean> {
        return this.callIsValidDocumentPinApi(pin, documentGUID, secureEmailGUID, isEmail);
    }

    public async getDocumentPdf(documentGUID: string, secureEmailGUID: string, pin: string): Promise<Blob> {
        return this.callGetDocumentPdfApi(documentGUID,secureEmailGUID, pin);
    }

    /**
     * Will return a boolean based on if the Secure Email GUID passed in to the method is a valid Secure Email Request
     * in the database. This is based on the record being created in the DB and the record not being expired.
     *
     * @param {string} secureEmailGUID
     * @returns {Promise<boolean>}
     *
     * @memberof ArchiveService
     */
    public async isValidSecureEmailGUID(secureEmailGUID: string): Promise<boolean> {
        return this.callIsValidSecureEmailGUIDApi(secureEmailGUID);
    }

    /**
     * Gets the statement and receipt history ArchiveZipFile from the ConsumerAPI -> Archive
     * Service based on the passed in documentGUIDs and consumerPaymentGUIDs
     *
     * @param {string} documentGUIDs
     * @param {string} receiptGUIDs
     * @returns {Promise<ArchiveZipFile>}
     *
     * @memberof ArchiveService
     */
    public async getArchiveHistory(
        documentGUIDs: string[], consumerPaymentGUIDs: string[], materialInsertGUIDs: string[]): Promise<ArchiveZipFile> {
        return this.callArchiveHistoryApi(documentGUIDs, consumerPaymentGUIDs, materialInsertGUIDs);
    }

    private async callArchiveDocumentPdfApi(documentGUID: string): Promise<Blob> {
        const headers = generateHeaders(
            'application/json',
            'application/pdf',
        );
        let body: any;
        const token = this.storage.retrieve('token');
        body = {
            AccessKey: token,
            DocumentGUID: documentGUID,
            ViewMethod: 'MySecureBill',
            path: this.archiveDocumentPdfPath,
            bypass: true
        };
        return this.http.post(this.domainUrl, { body }, blobRequestOptions(headers))
            .toPromise()
            .then((response: HttpResponse<Blob>) => {
                return new Blob([response.body], { type: 'application/pdf' });
            })
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    async getArchivePdf(documentGUID: string): Promise<Blob> {
        return this.callArchiveDocumentPdfApi(documentGUID);
    }

    private async callArchiveReceiptPdfApi(receiptGUID: string, token?: string): Promise<Blob> {
        const headers = generateHeaders(
            'application/json',
            'application/pdf',
        );

        let body: any;
        if (token == null) {
            token = this.storage.retrieve('token');
        }
        body = { AccessKey: token, ID: receiptGUID, path: this.archiveReceiptPdfPath, bypass: true };
        return this.http.post(this.domainUrl, { body }, blobRequestOptions(headers))
            .toPromise()
            .then((response: HttpResponse<Blob>) => {
                return new Blob([response.body], { type: 'application/pdf' });
            })
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    private async callGetSecureEmailApi(pin: string, documentGUID: string, secureEmailGUID: string): Promise<Blob> {
        const headers = generateHeaders(
            'application/json',
            'application/pdf',
        );
        const body: any = {
            pin,
            documentGUID,
            secureEmailGUID,
            domain: this.domain,
            path: this.secureEmailPdfPath,
            bypass: true
        };

        return this.http.post(this.domainUrl, { body }, blobRequestOptions(headers))
            .toPromise()
            .then((response: HttpResponse<Blob>) => {
                return new Blob([response.body], { type: 'application/pdf' });
            })
            .catch(async (response: HttpErrorResponse) => {
                return await parseErrorBlob(response);
            });
    }

    private async callIsValidDocumentPinApi(pin: string, documentGUID: string,
        secureEmailGUID: string, isEmail: boolean): Promise<boolean> {
        const headers = generateHeaders(
            'application/json',
            'application/pdf',
        );
        const body: any = {
            pin,
            documentGUID,
            secureEmailGUID,
            domain: this.domain,
            path: isEmail ? this.secureEmailPdfPath : this.validateDocumentGuidAuthenticationPath,
            bypass: true
        };

        return this.http.post(this.domainUrl, { body }, blobRequestOptions(headers))
            .toPromise()
            .then((response: HttpResponse<Blob>) => {
                return response.body;
            })
            .catch(async (response: HttpErrorResponse) => {
                return await parseErrorBlob(response);
            });
    }

    private async callGetDocumentPdfApi(documentGUID: string, secureEmailGUID: string,  pin: string): Promise<Blob> {
        const headers = generateHeaders(
            'application/json',
            'application/pdf',
        );
        const body: any = {
            pin,
            documentGUID,
            secureEmailGUID,
            domain: this.domain,
            path: this.securePdfPath,
            bypass: true
        };

        return this.http.post(this.domainUrl, { body }, blobRequestOptions(headers))
            .toPromise()
            .then((response: HttpResponse<Blob>) => {
                return new Blob([response.body], { type: 'application/pdf' });
            })
            .catch(async (response: HttpErrorResponse) => {
                return await parseErrorBlob(response);
            });
    }

    private async callIsValidSecureEmailGUIDApi(secureEmailGUID: string): Promise<boolean> {
        const headers = generateHeaders();
        const body: any = {
            secureEmailGUID,
            path: this.secureEmailCheckGUIDPath,
            bypass: true
        };

        return this.http.post(this.domainUrl, { body }, jsonRequestOptions(headers))
            .toPromise()
            .then((response: HttpResponse<null>) => {
                return response.status === 204;
            })
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    private async callArchiveHistoryApi(
        documentGUIDs: string[], consumerPaymentGUIDs: string[], materialInsertGUIDs: string[]): Promise<ArchiveZipFile> {
        const headers = generateHeaders(
            'application/json',
            'application/pdf',
        );
        // let options: RequestOptions = new RequestOptions({ headers: headers, responseType: ResponseContentType.Json });
        let body: any;
        const token: string = this.storage.retrieve('token');

        body = {
            AccessKey: token,
            DocumentGUIDs: documentGUIDs,
            ConsumerPaymentGUIDs: consumerPaymentGUIDs,
            MaterialInsertGUIDs: materialInsertGUIDs,
            path: this.archiveHistoryPath,
            bypass: true,
        };
        return this.http.post(this.domainUrl, { body }, jsonRequestOptions(headers))
            .toPromise()
            .then((response: Response<ArchiveZipFile>) => response.body.data)
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }
}
