import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DomainContentItem } from '../../models/domaincontentitems';
import { IDomainInfo } from '../../models/domaininfo';
import { ComponentService } from '../../services/component/component.service';

@Component({
    selector: 'forms',
    template: require('./forms.component.html'),
    styles: [require('./forms.component.css')]
})
export class FormsComponent implements OnInit, OnDestroy {
    private domainInfo: IDomainInfo;
    private ngUnsubscribe: Subject<any> = new Subject();
    public formsTitle: string;
    public forms: DomainContentItem[] = [];
    public parentContactUsItems: DomainContentItem[] = [];
    private contactUsItems: DomainContentItem[] = [];
    public facebookImage: string;
    public linkedInImage: string;
    public twitterImage: string;
    public youTubeImage: string;

    constructor(
        private componentService: ComponentService,
        private parentRouter: Router,
        private ngZone: NgZone
    ) { }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit() {
        this.componentService.scrollPageToTop();
        this.componentService.contentService.content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((content: any) => {
                if (content != null) {
                    this.buildParentContactUsItems(content.help);
                    this.buildContactUsItems(content.help);
                    this.buildContent(content.forms);

                    this.formsTitle = this.componentService.contentService.tryGetContentItem(
                        content, 'forms', 'pageText', 'formsTitle').text;

                    this.twitterImage = this.componentService.contentService.tryGetContentItem(
                        content, 'help', 'image', 'helpTwitterImageUrl').text;

                    this.youTubeImage = this.componentService.contentService.tryGetContentItem(
                        content, 'help', 'image', 'helpYouTubeImageUrl').text;

                    this.facebookImage = this.componentService.contentService.tryGetContentItem(
                        content, 'help', 'image', 'helpFacebookImageUrl').text;

                    this.linkedInImage = this.componentService.contentService.tryGetContentItem(
                        content, 'help', 'image', 'helpLinkedInImageUrl').text;
                }
            });

        this.componentService.domainService.domainInfo$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(domainInfo => {
            this.domainInfo = domainInfo;
            this.navigateIfFeatureDisabled();
        });
    }

    /**
     * Navigate to the homepage if enableForms is false.
     */
    navigateIfFeatureDisabled(): void {
        if (this.domainInfo && !this.domainInfo.enableForms) {
            this.ngZone.run(() => this.parentRouter.navigateByUrl('/')).then();
        }
    }

    /**
     * Build parent contact us items.
     *
     * @param {*} help
     */
    private buildParentContactUsItems(help: any): void {
        this.parentContactUsItems = [];
        if (!help || !help.contactUs) {
            return;
        }
        Object.keys(help.contactUs).forEach(key => {
            this.parentContactUsItems.push(help.contactUs[key]);
        });
        this.parentContactUsItems = this.parentContactUsItems.sort(
            function(a: DomainContentItem, b: DomainContentItem) { return a.sequence - b.sequence; }
        );
    }

    /**
     * Build contact us items.
     *
     * @param {*} help
     */
    private buildContactUsItems(help: any): void {
        this.contactUsItems = [];
        this.addContactUsItems(help.contactUsAddress);
        this.addContactUsItems(help.contactUsOther);
        this.addContactUsItems(help.contactUsInternet);
    }

    /**
     * Build content items to contact us array.
     *
     * @param {*} content
     */
    private addContactUsItems(content: any): void {
        if (!content) {
            return;
        }
        Object.keys(content).forEach(key => {
            const item: DomainContentItem = content[key];
            this.formatContent(item);
            this.contactUsItems.push(item);
        });
    }

    /**
     * Set contact items.
     *
     * @param {*} content
     */
    private buildContent(content: any): void {
        // Reset and build form array.
        this.forms = [];
        if (!content || !content.subCategory) {
            return;
        }
        Object.keys(content.subCategory).forEach(key => {
            this.forms.push(content.subCategory[key]);
        });
        // Sort forms on sequence number.
        this.forms = this.forms.sort(
            function(a: DomainContentItem, b: DomainContentItem) { return a.sequence - b.sequence; }
        );
    }

    /**
     * Replace empty values with non-breaking space chars and format certain data properly
     *
     * @param {DomainContentItem} item
     */
    private formatContent(item: DomainContentItem): void {
        if (item == null) {
            return;
        }

        if (item.subCategory === 'contactUsInternet' && item.title != null) {
            if (item.title.toLowerCase().includes('email') && !item.text.includes('mailto')) {
                item.text = '<a class="site-color" href="mailto:' + item.text + '">' + item.text + '</a>';
            } else if (!item.text.toLowerCase().includes('href') && !item.text.includes('mailto')) {
                item.text = '<a class="site-color" href="http://' + item.text + '" target="_blank">' + item.text + '</a>';
            }
        }

        if (item.title === '' && item.text === '') {
            item.title = '&nbsp;';
            item.text = '&nbsp;';
        }
    }

    /**
     * Get contact us items by parent.
     *
     * @param {DomainContentItem} contactItem
     * @returns {DomainContentItem[]}
     */
    public getContactUsItemsByParent(contactItem: DomainContentItem): DomainContentItem[] {
        return this.contactUsItems
            .filter(x => x.parentID === contactItem.parentID)
            .sort(function(a: DomainContentItem, b: DomainContentItem) {
                return a.sequence - b.sequence;
            });
    }

    /**
     * Determine if the domain information information exists.
     *
     * @param {string} key
     * @returns {boolean}
     */
    public isDomainInfoItemVisible(key: string): boolean {
        return this.domainInfo != null &&
            this.domainInfo[key] != null &&
            this.domainInfo[key].length > 0;
    }

    /**
     * Determine if the domain information information exists.
     *
     * @param {string} key
     * @returns {string}
     */
    public getLink(key: string): string {
        return this.domainInfo != null &&
            this.domainInfo[key] != null &&
            this.domainInfo[key].length > 0 ? this.domainInfo[key] : '';
    }

    /**
     * Left column classes.
     *
     * @param {DomainContentItem} item
     * @returns {string}
     */
    public leftColumnClasses(item: DomainContentItem): string {
        if (item.subCategory === 'contactUsInternet') {
            return item.title !== '' ? 'col-xs-3' : 'col-xs-12';
        } else {
            return item.title !== '' ? 'col-xs-6' : 'col-xs-12';
        }
    }

    /**
     * Left column content.
     *
     * @param {DomainContentItem} item
     * @returns {string}
     */
    public leftColumnContent(item: DomainContentItem): string {
        return item.title !== '' ? item.title : item.text;
    }

    /**
     * Left column classes.
     *
     * @param {DomainContentItem} item
     * @returns {string}
     */
    public rightColumnClasses(item: DomainContentItem): string {
        return item.subCategory === 'contactUsInternet' ? 'contact-info-links site-color' : '';
    }

    /**
     * Right column content.
     *
     * @param {DomainContentItem} item
     * @returns {string}
     */
    public rightColumnContent(item: DomainContentItem): string {
        return item.title !== '' ? item.text : '&nbsp;';
    }

    /**
     * Function called when link clicked in mobile view.
     *
     * @param {string} link
     */
    public linkClick(link: string): void {
        window.open(link, '_blank');
    }
}
