import { AutopayPaymentStatus } from './document';
import { IMerchantProfile } from './imerchantprofile';
import { IPaymentPlanBalance } from './paymentplanbalance';
import { IPaymentPlanDetails } from './paymentplandetails';

export class PayableEntry {
    accountNumber: string;
    autopayPaymentStatus: AutopayPaymentStatus = AutopayPaymentStatus.none;
    bucketInstallmentAmount: number;
    chargeDate: Date;
    childItems: PayableEntry[];
    displayBalance: number;
    documentGUID: string;
    documentID: number;
    dueDate: Date;
    externalPaymentPlanAmount: number;
    id: string;
    isChild: boolean;
    isDisabled: boolean;
    itemDescription: string;
    itemName: string;
    itemType: PayableEntryType;
    hasScheduledPayment: boolean;
    maxPayableAmount: number;
    minPayableAmount: number;
    merchantCredentialGUID: string;
    merchantProfile: IMerchantProfile;
    merchantProfileGUID: string;
    overpayDisableUI: boolean;
    payableAmount: number; // Going to replace "bucketBalance" with this
    paymentAmount: number;
    paymentLocationGUID: string;
    payToItem: boolean;
    percentAllocation: number;
    postDate: Date;
    promptPayAmount: number;
    promptPayDueDate: Date;
    sequence: number;
    displayField?: string;
    isAccountPaymentPlanEligible: boolean;
    ineligibleReason: string;
    paymentPlanGUID: string;
    initialPayment: boolean;
    initialPaymentAmount: number;

    public constructor() {
        this.accountNumber = null;
        this.autopayPaymentStatus = null;
        this.bucketInstallmentAmount = null;
        this.chargeDate = null;
        this.childItems = null;
        this.displayBalance = null;
        this.documentGUID = null;
        this.documentID = null;
        this.dueDate = null;
        this.externalPaymentPlanAmount = null;
        this.hasScheduledPayment = false;
        this.id = null;
        this.isChild = false;
        this.isDisabled = false;
        this.itemDescription = null;
        this.itemName = null;
        this.itemType = null;
        this.maxPayableAmount = null;
        this.merchantCredentialGUID = null;
        this.merchantProfile = null;
        this.merchantProfileGUID = null;
        this.minPayableAmount = null;
        this.overpayDisableUI = false;
        this.payableAmount = null;
        this.paymentAmount = null;
        this.paymentLocationGUID = null;
        this.payToItem = false;
        this.percentAllocation = null;
        this.postDate = null;
        this.promptPayAmount = null;
        this.promptPayDueDate = null;
        this.sequence = null;
        this.isAccountPaymentPlanEligible = true;
        this.displayField = null;
        this.paymentPlanGUID = null;
        this.initialPayment = null;
        this.initialPaymentAmount = null;
    }

    public static initializeBalance(balance: IPaymentPlanBalance, uniqueID: string): PayableEntry {
        const instance = new PayableEntry();

        instance.id = uniqueID;
        instance.itemDescription = balance.accountNumber;
        instance.itemName = balance.description;
        instance.itemType = PayableEntryType.paymentplanbalance;
        instance.maxPayableAmount = balance.balance;
        instance.merchantProfileGUID = balance.merchantProfileGUID;
        instance.payableAmount = balance.balance;
        instance.paymentLocationGUID = balance.paymentLocationGUID;
        instance.postDate = balance.balanceAsOf;
        instance.isAccountPaymentPlanEligible = balance.isAccountPaymentPlanEligible;
        instance.displayField = balance.displayField;
        instance.ineligibleReason = balance.ineligibleReason;
        instance.paymentPlanGUID = balance.paymentPlanGUID;

        return instance;
    }

    public static initializePaymentPlan(plan: IPaymentPlanDetails, description: string): PayableEntry {
        const instance = new PayableEntry();

        instance.dueDate = plan.expectedPaymentDate;
        instance.hasScheduledPayment = !!plan.consumerPaymentMethod;
        instance.id = plan.paymentPlanGUID;
        instance.itemDescription = description;
        instance.itemName = 'Plan ' + plan.sequentialIdentifier;
        instance.itemType = PayableEntryType.paymentplan;
        instance.maxPayableAmount = plan.balance;
        instance.merchantProfileGUID = plan.merchantProfileGUID;
        instance.payableAmount = plan.balance > plan.expectedPaymentAmount ? plan.expectedPaymentAmount : plan.balance; // TODO?
        instance.postDate = plan.firstDate;
        instance.isAccountPaymentPlanEligible = true;
        instance.initialPayment = plan.initialPayment;
        instance.initialPaymentAmount = plan.initialPaymentAmount;
        return instance;
    }

    public static initializeMerchantProfileGroup(merchantProfileGUID: string, uniqueID: string): PayableEntry {
        const instance = new PayableEntry();

        instance.childItems = [];
        instance.id = uniqueID;
        instance.itemType = PayableEntryType.accountBalanceGroup;
        instance.merchantProfileGUID = merchantProfileGUID;

        return instance;
    }
}


export enum PayableEntryType {
    // currentbalance = 1, // deprecated
    statement = 2,
    paymentplan = 3,
    paymentplanbalance = 4,
    accountBalanceGroup = 5,
    unknown = 0
}


export const FloatingChildItem: PayableEntry = {
    accountNumber: '',
    autopayPaymentStatus: AutopayPaymentStatus.none,
    bucketInstallmentAmount: 0,
    chargeDate: new Date(),
    childItems: null,
    displayBalance: 0,
    documentGUID: null,
    documentID: null,
    dueDate: null,
    externalPaymentPlanAmount: 0,
    id: 'FLOATER',
    isChild: true,
    isDisabled: false,
    itemDescription: null,
    itemName: 'Additional Payment',
    itemType: PayableEntryType.unknown,
    hasScheduledPayment: null,
    maxPayableAmount: 99999,
    merchantCredentialGUID: null,
    merchantProfile: null,
    merchantProfileGUID: '00000000-0000-0000-000000000000',
    minPayableAmount: 0,
    overpayDisableUI: false,
    payableAmount: 0,
    paymentAmount: 0,
    paymentLocationGUID: null,
    payToItem: false,
    percentAllocation: 100,
    postDate: null,
    promptPayAmount: null,
    promptPayDueDate: null,
    sequence: 99999,
    isAccountPaymentPlanEligible: true,
    displayField: 'mock display field',
    ineligibleReason: 'mock reason field',
    paymentPlanGUID: null,
    initialPayment: false,
    initialPaymentAmount: 0
};
