import { CurrencyPipe } from '@angular/common';
import { Component, EventEmitter, OnDestroy, Output, ViewChild } from '@angular/core';
import _ = require('lodash');
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConsumerAccount } from '../../../models/consumeraccount';
import { ICustomPlanPresets } from '../../../models/customplanpresets';
import { PayableEntry } from '../../../models/PayableEntry';
import { IPaymentPlanBalance } from '../../../models/paymentplanbalance';
import { IPaymentPlanDetails } from '../../../models/paymentplandetails';
import { IPaymentPlanPresetMinMax } from '../../../models/paymentplanpresetminmax';
import { ComponentService } from '../../../services/component/component.service';
import { ConsumerService } from '../../../services/consumer/consumer.service';
import { LoginService } from '../../../services/login/login.service';
import { PaymentPlanService } from '../../../services/paymentplan/paymentplan.service';

@Component({
    selector: 'select-accounts-modal',
    template: require('./selectaccountsmodal.component.html'),
    styles: [require('./selectaccountsmodal.component.css')]
})

export class SelectAccountsModalComponent implements OnDestroy {
    constructor(public componentService: ComponentService,
                private modalService: BsModalService,
                private currencyPipe: CurrencyPipe,
                private paymentPlanService: PaymentPlanService,
                private consumerService: ConsumerService,
                private loginService: LoginService) {
    }

    // Output
    @Output() accounts: EventEmitter<PayableEntry[]> = new EventEmitter<PayableEntry[]>();

    // Modal
    @ViewChild('modaltemplate', { static: false }) modalTemplate: any; // Should be HTMLTemplateElement (IE issues)

    public modalRef: BsModalRef;
    private ngUnsubscribe: Subject<any> = new Subject();
    public isLoading = true;
    public requestedPaymentPlanGuid: string;
    private requestedPaymentPlan: IPaymentPlanDetails;
    private merchantProfileGUID: string;
    public minimumPaymentAmountError: boolean;
    public balanceOutsideLimitsError = false;
    public selectedAccountTotal: number;
    private consumerAccount: ConsumerAccount;
    private paymentPlanPresetRange: IPaymentPlanPresetMinMax;
    private customPlanPresets: ICustomPlanPresets;
    public ineligibleAccountExists = false;
    public selectedView = 'Eligible';
    public eligibleSelectedClass = 'primary-white';
    public ineligibleSelectedClass = 'white-primary';
    // Content items
    public modalHeader: string;
    public modalSubHeader: string;
    public selectAccountExistingPlanModalSubHeader: string;
    public totalFooter: string;
    public confirmButtonLabel: string;
    public minMonthlyPaymentAmountError: string;
    public balanceOutsideLimitsErrorText: string;
    public eligibleText: string;
    public ineligibleText: string;

    // Payable entry accounts component
    public showDate = true;
    public eligiblePaymentPlanBalances: PayableEntry[];
    public ineligiblePaymentPlanBalances: PayableEntry[];
    // Content items
    public detailsHeader: string;
    public amountHeader: string;
    public selectHeader: string;
    public notesHeader: string;

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    public async openModalExistingPaymentPlan(requestedPaymentPlanGuid: string): Promise<void> {
        this.requestedPaymentPlanGuid = requestedPaymentPlanGuid;
        await this.openModal();
    }

    public async openModalNewPaymentPlan(merchantProfileGUID: string): Promise<void> {
        this.merchantProfileGUID = merchantProfileGUID;
        await this.openModal();
    }

    private async openModal(): Promise<void> {
        const modalConfig: any = {
            keyboard: false, // Allow Escape to close
            backdrop: true, // Dim the page with a backdrop
            ignoreBackdropClick: true, // Disallow them from clicking the backdrop to dismiss
            animated: false
        };
        this.modalRef = this.modalService.show(this.modalTemplate, modalConfig);
        this.showEligible();
        await this.readData();
    }

    private async readData(): Promise<void> {
        this.isLoading = true;
        this.consumerAccount = await this.consumerService.getConsumerAccount();
        await this.getRequestedPaymentPlan();
        await this.getAccounts();
        this.resetSelections(this.eligiblePaymentPlanBalances);
        this.setContentItems();
        this.initTimeoutSubscription();
        this.isLoading = false;
    }

    private async getRequestedPaymentPlan(): Promise<void> {
        if (this.requestedPaymentPlanGuid) {
            const paymentPlanDetails =
                await this.paymentPlanService.getPaymentPlanDetailsForAccount(this.consumerAccount.customerAccountID);
            this.requestedPaymentPlan = paymentPlanDetails.filter(g => g.paymentPlanGUID === this.requestedPaymentPlanGuid)[0];
            this.merchantProfileGUID = this.requestedPaymentPlan.merchantProfileGUID;
        }
    }

    private async getAccounts(): Promise<void> {
        const balanceDetails = await this.paymentPlanService.getBalanceDetails(this.consumerAccount.customerAccountID);
        if (balanceDetails != null && balanceDetails.length > 0) {
            const activePaymentPlanBalances = balanceDetails.filter(b => b.isActive);

            // Convert balances to payable entries.
            const payableEntries = activePaymentPlanBalances
                .map(balance => {
                    return PayableEntry.initializeBalance(balance, balance.description);
                })
                .filter(m => m.merchantProfileGUID === this.merchantProfileGUID);

            await this.getAccountsEligibleBalances(payableEntries);
            this.getAccountsIneligibleBalances(payableEntries);
        }
    }

    private async getAccountsEligibleBalances(payableEntries: PayableEntry[]): Promise<void> {
        // Eligible payment plan balances.
        this.eligiblePaymentPlanBalances = payableEntries.filter(b => b.isAccountPaymentPlanEligible);

        this.showDate = payableEntries.every(b => b.merchantProfile === null ? true : b.merchantProfile.enableDateColumn);

        // Calculate the eligible payment balances.
        const defaultCheckedAccountTotal = _.sumBy(this.eligiblePaymentPlanBalances, b => b.payableAmount);
        this.selectedAccountTotal = this.requestedPaymentPlan
            ? this.requestedPaymentPlan.balance + defaultCheckedAccountTotal
            : defaultCheckedAccountTotal;

        // I don't know that every eligiblePaymentPlanBalances has the same merchant profile
        for (const element of this.eligiblePaymentPlanBalances) {
            element.merchantProfile = await this.consumerService.getMerchantProfileByMerchantProfileGUID(element.merchantProfileGUID);
        }

        this.showDate = this.eligiblePaymentPlanBalances
                            .every(x => x.merchantProfile === null ? true : x.merchantProfile.enableDateColumn);

        // Get payment plan range - needed to determine minimum payment amount.
        this.paymentPlanPresetRange = await this.paymentPlanService.getPaymentPlansPresetsRange(
            this.consumerAccount.customerAccountID, this.merchantProfileGUID);

        // Determine if the account total is in minimum and maximum balance range.
        if (this.paymentPlanPresetRange &&
            this.selectedAccountTotal >= this.paymentPlanPresetRange.customMinimumBalance &&
            this.selectedAccountTotal <= this.paymentPlanPresetRange.customMaximumBalance) {
                // Get the custom plan presets which contain minimum monthly payment data.
                this.customPlanPresets = await this.paymentPlanService.getCustomPlanPresets(
                    this.consumerAccount.customerAccountID,
                    this.selectedAccountTotal,
                    this.merchantProfileGUID);
        } else {
            // Balance outside of range - display error message.
            this.balanceOutsideLimitsError = true;
        }
    }

    private getAccountsIneligibleBalances(payableEntries: PayableEntry[]): void {
        // Ineligible payment plan balances.
        this.ineligiblePaymentPlanBalances = payableEntries.filter(b =>
            !b.isAccountPaymentPlanEligible &&
            (b.paymentPlanGUID === null || b.paymentPlanGUID === this.componentService.NULL_GUID)
        );
        this.ineligibleAccountExists = Object.keys(this.ineligiblePaymentPlanBalances).length > 0;
    }

    private resetSelections(payableEntries: PayableEntry[]): void {
        if (payableEntries) {
            payableEntries.forEach((item) => {
                item.payToItem = true;
            });
        }
    }

    private setContentItems(): void {
        const category = 'paymentplan';
        const subCategory = 'pageText';

        this.componentService.contentService.content$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(c => {
            this.modalHeader = this.componentService.contentService.tryGetContentItem(c, category, subCategory, 'paymentPlanSelectAccountsModalHeader').text;
            this.modalSubHeader = this.componentService.contentService.tryGetContentItem(c, category, subCategory, 'paymentPlanSelectAccountsModalSubHeader').text;
            this.eligibleText = this.componentService.contentService.tryGetContentItem(c, category, 'button', 'paymentPlanEligibleAccountsToggle').text;
            this.ineligibleText = this.componentService.contentService.tryGetContentItem(c, category, 'button', 'paymentPlanIneligibleAccountsToggle').text;
            this.detailsHeader = this.componentService.contentService.tryGetContentItem(c, category, subCategory, 'paymentPlanSelectAccountsModalDetails').text;
            this.amountHeader = this.componentService.contentService.tryGetContentItem(c, category, subCategory, 'paymentPlanSelectAccountsModalAmount').text;
            this.selectHeader = this.componentService.contentService.tryGetContentItem(c, category, subCategory, 'paymentPlanSelectAccountsModalSelect').text;
            this.notesHeader = this.componentService.contentService.tryGetContentItem(c, category, subCategory, 'paymentPlanSelectAccountsModalNotes').text;
            this.totalFooter = this.componentService.contentService.tryGetContentItem(c, category, subCategory, 'paymentPlanSelectAccountsModalTotal').text;
            this.confirmButtonLabel = this.componentService.contentService.tryGetContentItem(c, category, subCategory, 'paymentPlanSelectAccountsModalConfirm').text;
            this.minMonthlyPaymentAmountError = this.componentService.contentService.tryGetContentItem(c, 'error', 'paymentPlan', 'paymentPlanMinMonthlyPaymentAmountError').text;
            this.balanceOutsideLimitsErrorText = this.componentService.contentService.tryGetContentItem(c, category, subCategory, 'paymentPlanSelectAccountsModalLimit').text;
            this.selectAccountExistingPlanModalSubHeader = this.componentService.contentService.tryGetContentItem(c, category, subCategory, 'paymentPlanSelectAccountExistingPlanHeader').text;

            // Update macro text
            const eligibleBalance = _.sumBy(this.eligiblePaymentPlanBalances, b => b.payableAmount);
            const balanceReplaceText = this.currencyPipe.transform(eligibleBalance, 'USD', 'symbol');
            this.modalSubHeader = this.modalSubHeader.replace('!AMOUNT!', balanceReplaceText);

            if (this.requestedPaymentPlan) {
                const paymentPlanBalance = this.currencyPipe.transform(this.requestedPaymentPlan.balance, 'USD', 'symbol');
                this.selectAccountExistingPlanModalSubHeader =
                    this.selectAccountExistingPlanModalSubHeader.replace('!PAYMENTPLANAMOUNT!', paymentPlanBalance);
            }

            if (this.customPlanPresets) {
                const minMonthlyPayment = this.currencyPipe.transform(this.customPlanPresets.minimumMonthlyPayment, 'USD', 'symbol');
                this.minMonthlyPaymentAmountError = this.minMonthlyPaymentAmountError.replace('!MIN!', minMonthlyPayment);
            }
        });
    }

    public showEligible(): void {
        this.selectedView = 'Eligible';
        this.eligibleSelectedClass = 'primary-white';
        this.ineligibleSelectedClass = 'white-primary';
    }

    public showIneligible(): void {
        this.selectedView = 'Ineligible';
        this.eligibleSelectedClass = 'white-primary';
        this.ineligibleSelectedClass = 'primary-white';
    }

    public onSelectedAccountsChange(payableEntry: PayableEntry[]) {
        this.eligiblePaymentPlanBalances = payableEntry;

        // Sum selected account balances.
        const checkedAccountTotal =
            _.sumBy(payableEntry.filter(b => b.payToItem), b => b.payableAmount);
        this.selectedAccountTotal = this.requestedPaymentPlan
            ? this.requestedPaymentPlan.balance + checkedAccountTotal
            : checkedAccountTotal;
    }

    public confirmClick(): void {
        if (this.customPlanPresets) {
            // A custom preset exists - determine if the minimum monthly payment is allowed.
            this.minimumPaymentAmountError =
                this.paymentPlanPresetRange && this.selectedAccountTotal < this.customPlanPresets.minimumMonthlyPayment;
            if (!this.minimumPaymentAmountError) {
                // The payment amount is within an acceptable range.
                this.modalRef.hide();
                this.accounts.emit(this.eligiblePaymentPlanBalances.filter(b => b.payToItem));
            }
        } else {
            // Balance is outside of limits. Allow the user to close the modal without selecting accounts.
            this.modalRef.hide();
        }
    }

    private initTimeoutSubscription(): void {
        // Hide the modal on session timeout.
        this.loginService.sessionTimeout$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((timeout: boolean) => {
                if (timeout) {
                    this.modalRef.hide();
                }
            });
    }

    /**
     * Determines if the date column should be displayed
     *
     * @private
     * @returns boolean
     *
     * @memberof SelectAccountsModalComponent
     */
    public showPostDate(): boolean {
        return this.showDate;
    }
}
