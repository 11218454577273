import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { ComponentService } from '../component/component.service';

@Injectable()
export class SmsGuard implements CanActivate {
    constructor(private componentService: ComponentService,
                private router: Router) {
    }

    async canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Promise<boolean> {
        const domainInfo = await this.componentService.domainService.getDomainInfo();

        if (this.componentService.quickpayUserIsLoggedIn()) {
            if (!domainInfo.enableQuickPaySMS) {
                this.router.navigateByUrl('home');
            }

            return domainInfo.enableQuickPaySMS;
        } else if (this.componentService.userIsLoggedIn()) {
            if (!domainInfo.enableSMS) {
                this.router.navigateByUrl('home');
            }

            return domainInfo.enableSMS;
        }
    }
}
