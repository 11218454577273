import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ComponentService } from '../../services/component/component.service';
import { IDomainInfo } from './../../models/domaininfo';

@Component({
    selector: 'landing-list-login',
    template: require('./landinglistlogin.component.html'),
    styles: [require('./landinglistlogin.component.css')]
})
export class LandingListLoginComponent implements OnInit, OnDestroy {

    constructor(private componentService: ComponentService, private activatedRoute: ActivatedRoute) {
    }

    @Input() domainInfo: IDomainInfo;
    @Output() showLogin = new EventEmitter<boolean>();

    passwordResetSubject: Subject<any> = new Subject();
    private ngUnsubscribe: Subject<any> = new Subject();

    passwordResetId: string;
    passwordResetExpired = false;
    showResetForm = false;
    newUserButtonText: string;
    newUserHeadlineText: string;
    newUserDescription: string;
    loginButtonText: string;
    oneTimePaymentLinkText: string;
    makeADonationLinkText: string;

    get isForgotUsernameOrPasswordResetOpen(): boolean {
        return this.componentService.isForgotUsernameOrPasswordResetOpen;
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit() {
        this.activatedRoute.queryParams
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((params: Params) => {
                this.passwordResetId = params.passwordReset;
                this.passwordResetExpired = (params.expired === 'true');
            });
        this.showResetForm = this.passwordResetId != null && !this.passwordResetExpired;
        this.componentService.contentService.content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((content: any) => {
                this.newUserButtonText = this.componentService.contentService.tryGetContentItem(content, 'login', 'button', 'loginPageNewUserButton').text;
                this.newUserHeadlineText = this.componentService.contentService.tryGetContentItem(content, 'login', 'pageText', 'loginPageNewUserHeadline').text;
                this.newUserDescription = this.componentService.contentService.tryGetContentItem(content, 'login', 'pageText', 'loginPageNewUserDescription').text;
                this.loginButtonText = this.componentService.contentService.tryGetContentItem(content, 'login', 'button', 'loginPageLoginButton').text;
                this.oneTimePaymentLinkText = this.componentService.contentService.tryGetContentItem(content, 'login', 'link', 'loginPageOneTimePayment').text;
                this.makeADonationLinkText = this.componentService.contentService.tryGetContentItem(content, 'login', 'link', 'loginPageDonationLink').text;
            });
    }

    changeLogin(val: boolean) {
        this.showLogin.emit(val);
    }

    changeReset(val: boolean) {
        this.showResetForm = val;
    }

    submitPasswordReset(): void {
        this.passwordResetSubject.next();
    }

    forgotUsernameOrPasswordResetOpen(): boolean {
        return this.isForgotUsernameOrPasswordResetOpen;
    }
}
