import { Injectable } from '@angular/core';
import { BehaviorSubject ,  Observable } from 'rxjs';
import { IMerchantProfile } from '../../models/imerchantprofile';
import { IPaymentLocation } from '../../models/paymentlocation';
import { PaymentMethodType } from '../../models/paymentmethod';

@Injectable()
export class ModifiedAccountPaymentStateService {
    private _stateUpdate = new BehaviorSubject<boolean>(true);
    private _forceValidateAmountStep = new BehaviorSubject<boolean>(false);
    private _forceValidateMethodStep = new BehaviorSubject<boolean>(false);
    private _forceValidateReviewStep = new BehaviorSubject<boolean>(false);
    private _facilityUpdate = new BehaviorSubject<IPaymentLocation>(null);
    public stateUpdate$: Observable<boolean>;
    public forceValidateAmountStep$: Observable<boolean>;
    public forceValidateMethodStep$: Observable<boolean>;
    public forceValidateReviewStep$: Observable<boolean>;
    public facilityUpdate$: Observable<IPaymentLocation>;
    private _merchantProfile: IMerchantProfile = null;
    private _stepList: any = null;
    private _currentStep = 1;
    private _locationList: IPaymentLocation[] = [];
    private _paymentAmount = 0.00;
    private _customerAccountId: string;
    private _facility: string;
    private _nameOnAccount: string;
    private _comments: string;
    private _phoneNumber: string;
    private _confirmationEmail: string;
    private _paymentSource: string;
    private _amountStepValid: boolean;
    private _methodStepValid: boolean;
    private _reviewStepValid: boolean;
    private _applicationID: string;
    private _paymentMethodType: PaymentMethodType;

    constructor() {
        this.stateUpdate$ = this._stateUpdate.asObservable();
        this.forceValidateAmountStep$ = this._forceValidateAmountStep.asObservable();
        this.forceValidateMethodStep$ = this._forceValidateMethodStep.asObservable();
        this.forceValidateReviewStep$ = this._forceValidateReviewStep.asObservable();
        this.facilityUpdate$ = this._facilityUpdate.asObservable();
    }

    get locationList(): IPaymentLocation[] {
        return this._locationList;
    }

    set locationList(locationList: IPaymentLocation[]) {
        if (this._locationList != null) {
            this._locationList = locationList;
        }
    }

    get paymentAmount(): number {
        return this._paymentAmount;
    }

    set paymentAmount(paymentAmount: number) {
        this._paymentAmount = paymentAmount;
        this._stateUpdate.next(true);
    }

    get customerAccountId(): string {
        return this._customerAccountId;
    }

    set customerAccountId(customerAccountId: string) {
        this._customerAccountId = customerAccountId;
        this._stateUpdate.next(true);
    }

    get facility(): string {
        return this._facility;
    }

    set facility(facility: string) {
        this._facility = facility;
        this._stateUpdate.next(true);
    }

    get nameOnAccount(): string {
        return this._nameOnAccount;
    }

    set nameOnAccount(nameOnAccount: string) {
        this._nameOnAccount = nameOnAccount;
        this._stateUpdate.next(true);
    }

    get comments(): string {
        return this._comments;
    }

    set comments(comments: string) {
        this._comments = comments;
        this._stateUpdate.next(true);
    }

    get phoneNumber(): string {
        return this._phoneNumber;
    }

    set phoneNumber(phoneNumber: string) {
        this._phoneNumber = phoneNumber;
        this._stateUpdate.next(true);
    }

    get stepList(): any {
        return this._stepList;
    }

    set stepList(stepList: any) {
        this._stepList = stepList;
    }

    get currentStep(): number {
        return this._currentStep;
    }

    set currentStep(currentStep: number) {
        this._currentStep = currentStep;
    }

    get confirmationEmail(): string {
        return this._confirmationEmail;
    }

    set confirmationEmail(confirmationEmail: string) {
        this._confirmationEmail = confirmationEmail;
    }

    get amountStepValid(): boolean {
        return this._amountStepValid;
    }

    set amountStepValid(amountStepValid: boolean) {
        this._amountStepValid = amountStepValid;
    }

    get paymentSource(): string {
        return this._paymentSource;
    }

    set paymentSource(paymentSource: string) {
        this._paymentSource = paymentSource;
    }

    get methodStepValid(): boolean {
        return this._methodStepValid;
    }

    set methodStepValid(methodStepValid: boolean) {
        this._methodStepValid = methodStepValid;
    }

    get reviewStepValid(): boolean {
        return this._reviewStepValid;
    }

    set reviewStepValid(reviewStepValid: boolean) {
        this._reviewStepValid = reviewStepValid;
    }

    get paymentMethodType(): PaymentMethodType {
        return this._paymentMethodType;
    }

    set paymentMethodType(paymentMethodType: PaymentMethodType) {
        this._paymentMethodType = paymentMethodType;
    }

    get merchantProfile(): IMerchantProfile {
        return this._merchantProfile;
    }

    set merchantProfile(profile: IMerchantProfile) {
        this._merchantProfile = profile;
    }

    get applicationID(): string {
        return this._applicationID;
    }

    set applicationID(applicationID: string) {
        this._applicationID = applicationID;
    }

    checkAmountStepValid() {
        this._forceValidateAmountStep.next(true);
    }

    checkMethodStepValid() {
        this._forceValidateMethodStep.next(true);
    }

    checkReviewStepValid() {
        this._forceValidateReviewStep.next(true);
    }

    updateFacility(location: IPaymentLocation) {
        if (location != null) {
            this._facilityUpdate.next(location);
        }
    }

    clearState() {
        this._facility = null;
        this._nameOnAccount = null;
        this._phoneNumber = null;
        this._comments = null;
        this._paymentAmount = null;
        this._customerAccountId = null;
        this._amountStepValid = false;
        this._methodStepValid = false;
        this._reviewStepValid = false;
        this._stateUpdate.next(true);
    }

}
