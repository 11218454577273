import { Activity } from '../../../models/activity';

export class BaseActivity {
    constructor() { }

    loading = true;

    doneLoading() {
        this.loading = false;
    }

    isLoading(): boolean {
        return this.loading;
    }

    setIcon(activity: Activity): void {
        if (activity.activityType === 'AutoPay') {
            activity.iconLibrary = 'material';
            activity.activityIcon = 'account_balance';
        } else if (activity.activityType === 'Payment') {
            activity.iconLibrary = 'glyphicon';
            activity.activityIcon = 'glyphicon-usd';
        } else if (activity.activityType === 'Enrollment') {
            activity.iconLibrary = 'material';
            activity.activityIcon = 'person_add';
        } else if (activity.activityType === 'Refund') {
            activity.iconLibrary = 'glyphicon';
            activity.activityIcon = 'glyphicon-usd';
        } else if (activity.activityType === 'Void') {
            activity.iconLibrary = 'glyphicon';
            activity.activityIcon = 'glyphicon-usd';
        } else if (activity.activityType === 'DeliveryPreference') {
            activity.iconLibrary = 'glyphicon';
            activity.activityIcon = 'glyphicon-file';
        } else if (activity.activityType === 'PasswordUpdate') {
            activity.iconLibrary = 'glyphicon';
            activity.activityIcon = 'glyphicon-lock';
        } else if (activity.activityType === 'EmailAddressUpdate') {
            activity.iconLibrary = 'glyphicon';
            activity.activityIcon = 'glyphicon-ok-sign';
        } else if (activity.activityType === 'AddPaymentMethod') {
            activity.iconLibrary = 'glyphicon';
            activity.activityIcon = 'glyphicon-plus-sign';
        } else if (activity.activityType === 'DeletePaymentMethod') {
            activity.iconLibrary = 'glyphicon';
            activity.activityIcon = 'glyphicon-minus-sign';
        } else if (activity.activityType === 'PaymentPlan') {
            activity.iconLibrary = 'glyphicon';
            activity.activityIcon = 'glyphicon-usd';
        } else if (activity.activityType === 'IVR') {
            activity.iconLibrary = 'material';
            activity.activityIcon = 'phone';
        } else if (activity.activityType === 'Scheduled') {
            activity.iconLibrary = 'glyphicon';
            activity.activityIcon = 'glyphicon-calendar';
        } else if (activity.activityType === 'OutboundSMS') {
            activity.iconLibrary = 'glyphicon';
            activity.activityIcon = 'glyphicon-phone';
        } else if (activity.activityType === 'Notification') {
            activity.iconLibrary = 'glyphicon';
            activity.activityIcon = 'glyphicon-envelope';
        } else if (activity.activityType === 'DigitalFirst') {
            activity.iconLibrary = 'glyphicon';
            activity.activityIcon = 'glyphicon-send';
        } else if(activity.activityType === 'PreServicePlan') {
            activity.iconLibrary = 'glyphicon';
            activity.activityIcon = 'glyphicon-usd';
        }
    }
}
