import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GuarantorEmail } from '../../../models/guarantoremail';
import { GuarantorEmailViewModel } from '../../../models/guarantoremailviewmodel';
import { ComponentService } from '../../../services/component/component.service';
import { ConsumerService } from '../../../services/consumer/consumer.service';
import { LoginService } from '../../../services/login/login.service';

@Component({
    selector: 'consumer-contact-details-modal',
    template: require('./consumercontactdetailsmodal.component.html'),
    styles: [require('./consumercontactdetailsmodal.component.css')]
})
export class ConsumerContactDetailsModalComponent implements OnInit, OnDestroy {
    modalRef: BsModalRef;
    config: any = {
        keyboard: false, // allow them to press Escape key
        backdrop: true, // dim the page with a backdrop
        ignoreBackdropClick: true, // Disallow them from clicking the backdrop to dismiss
        animated: false
    };
    showEmailSelection = false;
    showEmailForm = false;
    showPhoneForm = false;

    // Content items
    emailFormHeader: string;
    phoneFormHeader: string;
    modalHeader: string;
    multipleEmailsInstructions: string;
    oneEmailSubHeader: string;
    recurringEmailLabel: string;
    autoPayEmailLabel: string;
    paymentPlanLabel: string;
    statementLabel: string;
    profileModalCloseButtonLabel: string;

    emailViewModels: GuarantorEmailViewModel[] = [];

    @ViewChild('modaltemplate', { static: false }) modalTemplate: any; // Should be HTMLTemplateElement but IE sucks

    @Input() isForQuickPayDeliveryMethodProfile = false;
    @Input() accountVerificationId: string;
    @Input() deliveryMethod: string;
    @Input() showPhoneFormOverride = true;
    @Input() showEmailFormOverride = false;
    @Output() emailUpdated = new EventEmitter<string>();
    @Output() phoneNumberUpdated = new EventEmitter<string>();
    @Output() guarantorEmails = new EventEmitter<GuarantorEmailViewModel[]>();

    private ngUnsubscribe: Subject<any> = new Subject();

    constructor(private modalService: BsModalService,
                private componentService: ComponentService,
                private consumerService: ConsumerService,
                private loginService: LoginService) {
    }

    async ngOnInit(): Promise<void> {
        this.setContentItems();
        await this.setGuarantorEmails();
        this.setEmailSelection();
        this.setPhoneForm();
        this.initTimeoutSubscription();
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    openModal(): void {
        this.modalRef = this.modalService.show(this.modalTemplate, this.config);
    }

    async onEmailUpdated(emailAddress: string): Promise<void> {
        this.emailUpdated.emit(emailAddress);

        await this.setGuarantorEmails();
    }

    onPhoneNumberUpdated(phoneNumber: string): void {
        this.phoneNumberUpdated.emit(phoneNumber);
    }

    private async setGuarantorEmails(): Promise<void> {
        if (!this.isForQuickPayDeliveryMethodProfile) {
            const emails = await this.consumerService.getGuarantorEmails();
            this.setEmailViewModels(emails);
        } else {
            const emails = await this.consumerService.getGuarantorEmailsByVerificationId(this.accountVerificationId);
            this.setEmailViewModels(emails);
        }
    }

    private setEmailViewModels(emails: GuarantorEmail[]): void {
        if (emails.some(e => !e.guarantorEmailAddressUsed)) {
            this.emailViewModels =
                this.getEmailViewModels(emails);
        } else {
            this.emailViewModels = this.getEmailViewModels(emails.slice(0, 1));
        }

        this.guarantorEmails.emit(this.emailViewModels);
    }

    private getEmailViewModels(associatedEmails: GuarantorEmail[]): GuarantorEmailViewModel[] {
        const emailViewModels: GuarantorEmailViewModel[] = associatedEmails
            .map(e => {
                return {
                    emailAddress: e.emailAddress,
                    displayValue: this.getDisplayValue(e),
                    type: e.type,
                    guarantorEmailAddressUsed: e.guarantorEmailAddressUsed
                };
            });

        const temp = {};

        // filter to a collection with elements that are distinct by emailAddress and type.
        const distinctEmailsByEmailAndType = emailViewModels.filter(e => {
            const key = `${e.emailAddress}|${e.type}`;
            if (!temp[key]) {
                temp[key] = true;
                return true;
            }
        });

        return distinctEmailsByEmailAndType;
    }

    private getDisplayValue(email: GuarantorEmail): string {
        if (email.emailAddress) {
            const typeLabel = this.getTypeLabel(email.type);

            if (typeLabel) {
                return `${email.emailAddress} -- ${typeLabel}`;
            } else {
                return email.emailAddress;
            }
        }

        return null;
    }

    private getTypeLabel(type: string): string {
        switch (type) {
            case 'AP':
                return this.autoPayEmailLabel;
            case 'REC':
                return this.recurringEmailLabel;
            case 'PP':
                return this.paymentPlanLabel;
            case 'STMT':
                return this.statementLabel;
            default:
                return '';
        }
    }

    private setEmailSelection(): void {
        if (this.showEmailFormOverride || (this.emailViewModels && this.emailViewModels.length > 1)) {
            this.showEmailSelection = true;
        } else {
            this.showEmailSelection = false;
            this.showEmailForm = true;
        }
    }


    private setPhoneForm(): void {
        if (this.showPhoneFormOverride) {
            if (!this.isForQuickPayDeliveryMethodProfile) {
                this.componentService.domainService.getDomainInfo().then(info => {
                    this.consumerService.getConsumer().then(cons => {
                        if (cons.isQuickPay) {
                            this.showPhoneForm = info.enableQuickPaySMS;
                        } else {
                            this.showPhoneForm = info.enableSMS;
                        }
                    });
                });
            }
        }
    }

    private setContentItems(): void {
        const category = 'communicationpreferences';
        const subCategory = 'pageText';

        this.componentService.contentService.content$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(c => {
            this.modalHeader = this.componentService.contentService.tryGetContentItem(c, category, subCategory, 'profileModalHeader').text;
            this.emailFormHeader = this.componentService.contentService.tryGetContentItem(c, category, subCategory, 'profileModalEmailFormHeader').text;
            this.phoneFormHeader = this.componentService.contentService.tryGetContentItem(c, category, subCategory, 'profileModalPhoneFormHeader').text;
            this.multipleEmailsInstructions = this.componentService.contentService.tryGetContentItem(c, category, subCategory, 'profileModalMultipleEmailsSubHeader').text;
            this.oneEmailSubHeader = this.componentService.contentService.tryGetContentItem(c, category, subCategory, 'profileModalOneEmailSubHeader').text;
            this.recurringEmailLabel = this.componentService.contentService.tryGetContentItem(c, category, subCategory, 'emailFormRecurringLabel').text;
            this.autoPayEmailLabel = this.componentService.contentService.tryGetContentItem(c, category, subCategory, 'emailFormAutopayLabel').text;
            this.paymentPlanLabel = this.componentService.contentService.tryGetContentItem(c, category, subCategory, 'emailFormPaymentPlanLabel').text;

            this.statementLabel =
                this.componentService.contentService.tryGetContentItem(
                        c,
                        category,
                        subCategory,
                        'emailFormStatementLabel'
                ).text;

            this.profileModalCloseButtonLabel = this.componentService.contentService.tryGetContentItem(c, category, subCategory, 'profileModalCloseButtonLabel').text;
        });
    }

    private initTimeoutSubscription() {
        this.loginService.sessionTimeout$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((timeout: boolean) => {
                if (timeout) {
                    this.modalRef.hide();
                }
            });
    }
}
