import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CarouselConfig } from 'ngx-bootstrap/carousel';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CarouselItem } from '../../../models/carouselitem';
import { ConnectMessage } from '../../../models/connectmessage';
import { IContentItem } from '../../../models/contentItem';
import { ComponentService } from '../../../services/component/component.service';
import { ConnectMessageService } from '../../../services/connectmessage/connectmessage.service';
/**
 * This is the shape of the original
 * content item from the server
 */
export interface ICarouselContent {
    body: ICarouselSubContent;
    headline: ICarouselSubContent;
    caption: ICarouselSubContent;
    image: ICarouselSubContent;
}
/**
 * These content items will have a faux array
 * shape, body will have keys for each IContentItem
 * with a number at the end. This will allow for
 * typing on the object to work correctly
 */
export interface ICarouselSubContent {
    [key: string]: IContentItem;
}

/**
 * This is an intermediate state for the
 * content items as they are transformed
 * into an array of `CarouselItems`.
 *
 * In this state each of the content item
 * properties share an index. though there
 * is no guarantee that all 4 arrays will be
 * the same length.
 */
export interface IParsedCarouselContent {
    body: string[];
    headline: string[];
    caption: string[];
    image: string[];
}

@Component({
    selector: 'msb-carousel',
    template: require('./msbcarousel.component.html'),
    styles: [require('./msbcarousel.component.css')],
    providers: [{ provide: CarouselConfig, useValue: { interval: 5000, noPause: true } }]
})
export class MSBCarouselComponent implements OnInit, OnDestroy {

    @Input() carouselBody: string;
    @Input() carouselBodyHeadline: string;
    @Input() carouselCaption: string;
    @Input() imageUrl: string;

    carouselItems: CarouselItem[];

    carouselContentPlaceholder: ICarouselContent;

    constructor(
        private componentService: ComponentService,
        private connectMessageService: ConnectMessageService
    ) { }

    private ngUnsubscribe: Subject<any> = new Subject();

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit() {
        // This will first try and get the connect carousel items
        // if that comes back empty, we will default down to the
        // content items for this carousel
        this.connectMessageService.getConnectMessages('carousel')
            .then((messages) => {
            if (messages && messages.length > 0) {
                this.setCarouselFromConnectMessages(messages);
            } else {
                this.componentService.contentService.content$
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe((content: any) => {
                    if (content.carousel) {
                        this.setCarouselFromContent(content.carousel);
                    }
                });
            }
        });
    }

    /**
     * For customers who have opted into the connect service
     * the call to getConnectMessages will return the values that
     * should be included in this component
     * @param messages The list of message from the connect service
     */
    setCarouselFromConnectMessages(messages: ConnectMessage[]): void {
        this.carouselItems = messages.map(message => {
            const item = new CarouselItem();

            item.carouselBody = message.linkText;
            item.carouselBodyHeadline = message.description;
            item.carouselCaption = message.title;
            item.imageUrl = this.connectMessageService.getImageUrlFromImageID(message.imageID);

            return item;
        });
    }
    /**
     * For customers who have not opted into the connect service
     * the subscription to `this.componentService.contentService.content$`
     * should return a content item object representing the rendered state of this component.
     * The content item object will need to be transformed into an array of `CarouselItem`s.
     *
     * This method will perform the transformation and assign the result to `this.carouselItems`
     *
     * @param content the content item object to be transformed into the this.carouselItems property
     */
    setCarouselFromContent(content: ICarouselContent): void {
        const items = this.parseContent(content);
        this.carouselItems = this.convertToCarouselItems(items);
    }
    /**
     * This method will convert the raw content item object into
     * a set of 4 arrays. This set will represent 1 `CarouselItem`
     * at each index. For example
     * ```js
     * {
     *  body: ['body1', 'body2'],
     *  headline: ['headline1', 'headline2'],
     *  caption: ['caption1', 'caption2'],
     *  image: ['path/to/image1', 'path/to/image2']
     * }
     * ```
     * Would represent 2 `CarouselItem`s, one at index 0
     * and another at index 1
     *
     * TODO: when Object.values becomes available in our TypeScript
     * version, the for loops could be converted to use that instead
     * @param content the content item object to be transformed
     */
    parseContent(content: ICarouselContent): IParsedCarouselContent {
        const body = [];
        const headline = [];
        const caption = [];
        const image = [];
        // tslint:disable-next-line: forin
        for (const key in content.body) {
            body.push(content.body[key].text);
        }
        // tslint:disable-next-line: forin
        for (const key in content.headline) {
            headline.push(content.headline[key].text);
        }
        // tslint:disable-next-line: forin
        for (const key in content.caption) {
            caption.push(content.caption[key].text);
        }
        // tslint:disable-next-line: forin
        for (const key in content.image) {
            image.push(content.image[key].text);
        }
        return {
            body,
            headline,
            caption,
            image,
        };
    }
    /**
     * The final step in the conversion from a content item
     * to an array of `CarouselItem`s. This method will
     * loop over each of the indexes provided and construct
     * a carousel for each item.
     * @param items The parsed content item arrays
     */
    convertToCarouselItems(items: IParsedCarouselContent): CarouselItem[] {
        const maxLength = Math.max(items.body.length, items.headline.length, items.caption.length, items.image.length);
        const ret = [];
        for (let i = 0; i < maxLength; i++) {
            const item = new CarouselItem();

            // doing index checks just in case there is missing info on parts
            item.carouselBody = (i < items.body.length) ? items.body[i] : '';
            item.carouselBodyHeadline = (i < items.headline.length) ? items.headline[i] : '';
            item.carouselCaption = (i < items.caption.length) ? items.caption[i] : '';
            item.imageUrl = (i < items.image.length) ? items.image[i] : '';

            ret.push(item);
        }
        return ret;
    }

    backgroundImage(url: string): string {
        return 'url(\'' + url + '\') no-repeat center';
    }

    backgroundSize(prop: string): string {
        return prop;
    }
}
