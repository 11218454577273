import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppConfig } from '../../../app.config';
import { AutopayPaymentStatus } from '../../../models/document';
import { PayableItemType } from '../../../models/payableitem';
import { PayableStatement } from '../../../models/payablestatement';
import { FloatingBucket, PaymentBucket } from '../../../models/paymentbucket';
import { ComponentService } from '../../../services/component/component.service';
import { ConsumerService } from '../../../services/consumer/consumer.service';
import { LoggingLevel } from '../../../services/logging/logging.service';
import { CheckboxComponent } from '../../Controls/Checkbox/checkbox.component';
import { PaymentBucketComponent } from './PaymentBucket/paymentbucket.component';

@Component({
    selector: 'payable-statement',
    template: require('./payablestatement.component.html'),
    styles: [require('./payablestatement.component.css')]
})
export class PayableStatementComponent implements OnInit, OnDestroy {

    constructor(
        private config: AppConfig,
        private componentService: ComponentService,
        private consumerService: ConsumerService,
        private datePipe: DatePipe,
        private currencyPipe: CurrencyPipe
    ) { }
    @ViewChildren('paymentBucketCmp') paymentBucketCmpList: QueryList<PaymentBucketComponent>;
    @Input() statement: PayableStatement;
    @Input() currentBalanceDisclaimer: string;
    @Input() isOneTimePayment = false;
    detailsExpanded = false;
    isDisabled = false;
    statementCheckboxName: string;
    bucketArrowRotateDegrees = 0;
    paymentAmountMax = 0;
    bucketsAreDirty = false;
    showBucketAdjustmentDisabledDisclaimer = false;
    showPromptPayShortPayDisclaimer = false;
    merchantProfileLoaded = false;
    paymentAmountErrorMessage: string;
    invalidAmountError: string;
    dueDateText: string;
    bucketAdjustmentDisabledDisclaimer: string;
    exceedStatementBalance: string;
    viewStatementLinkText: string;
    viewDocumentLinkRoute: string;
    promptPayAvailableMessage: string;
    promptPayUnavailableMessage: string;
    promptPayBucketDisabledDisclaimer: string;
    promptPayCalculated = false;
    promptPayAvailable = false;
    autopayWarning: string;
    externalPaymentPlanText: string;
    payDifferentAmountText: string;
    backToPaymentPlanText: string;
    overpaymentExceedsMaximumLimitError: string;
    overPayShortPayError: string;
    showOverPayShortPayError = false;
    externalPaymentPlanWarningText: string;
    oneTimeExternalPaymentPlanWarningText: string;
    externalPlanUserPayingDifferentAmount = false;
    canEditBuckets = false;

    private ngUnsubscribe: Subject<any> = new Subject();

    // set up output calls to PayableStatementListComponent
    @Output() sumTotalEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() payToStatementCheckedEvent: EventEmitter<PayableStatement> = new EventEmitter<PayableStatement>();
    @Output() emitOverPaymentEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    async ngOnInit() {
        this.componentService.contentService.content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((content: any) => {
                this.invalidAmountError = this.componentService.contentService.tryGetContentItem(content, 'home', 'error', 'homePagePaymentAmountError').text;
                this.exceedStatementBalance = this.componentService.contentService.tryGetContentItem(content, 'home', 'payment', 'payableStatementExceedsStatementBalanceText').text;
                this.promptPayAvailableMessage = this.componentService.contentService.tryGetContentItem(content, 'payment', 'promptpay', 'paymentPromptPayAvailableMessage').text;
                this.promptPayUnavailableMessage = this.componentService.contentService.tryGetContentItem(content, 'payment', 'promptpay', 'paymentPromptPayUnavailableMessage').text;
                this.promptPayBucketDisabledDisclaimer = this.componentService.contentService.tryGetContentItem(content, 'payment', 'promptpay', 'paymentPromptPayBucketDisabledDisclaimer').text;
                this.autopayWarning = this.componentService.contentService.tryGetContentItem(content, 'payment', 'loggedInPayment', 'paymentPendingPaymentWarning').text;
                this.externalPaymentPlanText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'pageText', 'paymentExternalPaymentPlanText').text;
                this.payDifferentAmountText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'pageText', 'paymentPayDifferentAmountText').text;
                this.backToPaymentPlanText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'pageText', 'paymentBackToPaymentText').text;
                this.overpaymentExceedsMaximumLimitError = this.componentService.contentService.tryGetContentItem(content, 'payment', 'error', 'paymentOverpaymentExceedsMaximumLimit').text;
                this.overPayShortPayError = this.componentService.contentService.tryGetContentItem(content, 'error', 'errorCode400', 'error40033').text;

                if (this.isOneTimePayment) {
                    if (this.statement.dueUponReceipt) {
                        this.dueDateText =
                            this.componentService.contentService.tryGetContentItem(
                                content,
                                'payment',
                                'oneTimePayment',
                                'paymentDueUponReceiptText'
                            ).text;
                    } else {
                        this.dueDateText =
                            this.componentService.contentService.tryGetContentItem(
                                content,
                                'payment',
                                'oneTimePayment',
                                'paymentDueDateLabel'
                            ).text;
                    }

                    this.bucketAdjustmentDisabledDisclaimer =
                        this.componentService.contentService.tryGetContentItem(
                            content,
                            'payment',
                            'oneTimePayment',
                            'paymentBucketAdjustmentDisabledDisclaimer'
                        ).text;

                    this.viewStatementLinkText =
                        this.componentService.contentService.tryGetContentItem(
                            content,
                            'payment',
                            'oneTimePayment',
                            'paymentViewStatementLinkText'
                        ).text;

                    this.externalPaymentPlanWarningText =
                        this.componentService.contentService.tryGetContentItem(
                            content,
                            'payment',
                            'oneTimePayment',
                            'paymentExternalPaymentPlanWarningText'
                        ).text;
                } else {
                    if (this.statement.dueUponReceipt) {
                        this.dueDateText =
                            this.componentService.contentService.tryGetContentItem(
                                content,
                                'payment',
                                'loggedInPayment',
                                'paymentDueUponReceiptText'
                            ).text;
                    } else {
                        this.dueDateText =
                            this.componentService.contentService.tryGetContentItem(
                                content,
                                'payment',
                                'loggedInPayment',
                                'paymentDueDateLabel'
                            ).text;
                    }

                    this.bucketAdjustmentDisabledDisclaimer = this.componentService.contentService.tryGetContentItem(content, 'payment', 'loggedInPayment', 'paymentBucketAdjustmentDisabledDisclaimer').text;
                    this.viewStatementLinkText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'loggedInPayment', 'paymentViewStatementLinkText').text;
                    this.externalPaymentPlanWarningText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'loggedInPayment', 'paymentExternalPaymentPlanWarningText').text;
                }
            });

        this.statementCheckboxName = 'payToStatement' + this.statement.id;
        await this.setErrorMessages();
        await this.setPaymentAmountMax();
        const getDocumentUrl: string = this.config.getConfig('documentPdfPath');
        this.viewDocumentLinkRoute = getDocumentUrl + '?t=' +
            this.componentService.storageService.retrieve('token') + '&did=' + this.statement.documentGUID + '&v=MySecureBill';

        if (this.statementHasBuckets()) {
            this.setCanEditBuckets();
        }
    }

    /**
     * Checks if its external payment plan and one time payment and auto set payment total if it is
     *
     * @memberof PayableStatementComponent
     */
    public async setExternalPaymentPlan(): Promise<void> {
        // Auto check external plan amount if they have one set
        if (this.documentHasExternalPaymentPlan()) {
            // Using setTimeout makes this code execute in next VM Turn and prevents changes happening outside of
            // change detection cycle and throwing ExpressionChangedAfterItHasBeenCheckedError
            setTimeout(async () => {
                if (this.isOneTimePayment) {
                    this.statement.payToItem = true;
                    await this.checkboxChecked();
                }

                this.paymentBucketCmpList.forEach(bucketcmp => {
                    bucketcmp.overpayDisableUI = true;
                });
            });
        }
    }

    /**
     * Triggered on change of the Payment Amount input field and its model value
     *
     * @memberof PayableStatementComponent
     */
    async paymentAmountChange() {
        this.sumTotalEvent.emit(true);
        await this.setErrorMessages();

        if (!!this.statement.paymentAmount) {
            if (this.statementHasBuckets()) {
                this.setCanEditBuckets();
                this.enableDisableBucketRows(false);

                // If they type an amount >= to prompt pay, we want to disable the buckets.
                // Any other overpayment is handled in distributePaymentAmountToBuckets.
                if (this.promptPayIsPossible() && this.statement.paymentAmount >= this.statement.promptPayAmount) {
                    this.showPromptPayShortPayDisclaimer = false;
                    this.bucketUIToggle(true);
                } else if (this.promptPayIsPossible()
                    && this.statement.paymentAmount < this.statement.promptPayAmount
                    && this.statement.paymentAmount > 0) {
                    this.showPromptPayShortPayDisclaimer = true;
                    this.bucketUIToggle(true);
                } else {
                    this.showPromptPayShortPayDisclaimer = false;
                }
                this.distributePaymentAmountToBuckets();
            }

            this.statement.payToItem = true;
            this.payToStatementCheckedEvent.emit(this.statement);
        } else {
            this.statement.payToItem = false;
            this.clearPayableItemsAndBuckets();
            this.payToStatementCheckedEvent.emit(this.statement);
        }
    }
    /**
     * Returns the merchant profile setting for how many account characters should be hidden.
     *
     * @returns {number}
     * @memberof PayableStatementComponent
     */
    hideAccountChars(): number {
        return this.statement.merchantProfile != null ? this.statement.merchantProfile.hideAccountChars : 0;
    }

    /**
     * Called by checkbox component when the checkbox is clicked
     *
     * @param {CheckboxComponent} checkboxTarget
     *
     * @memberOf PayableStatementComponent
     */
    public async checkboxChecked(checkboxTarget: CheckboxComponent = null) {
        await this.checkUnderAndOverPayment();
        let statementClicked: PayableStatement;
        if (checkboxTarget != null) {
            statementClicked = checkboxTarget.value as PayableStatement;
        } else {
            statementClicked = this.statement;
        }

        let workingStatementBalance = 0;
        if (this.promptPayIsPossible()) {
            workingStatementBalance = this.statement.promptPayAmount;
        } else if (this.documentHasExternalPaymentPlan() && !this.externalPlanUserPayingDifferentAmount) {
            workingStatementBalance = this.statement.externalPaymentPlanAmount;
        } else {
            workingStatementBalance = this.statement.payableAmount;
        }

        if (this.statement.payToItem && (this.statement.paymentAmount == null || this.statement.paymentAmount <= 0)) {
            this.statement.paymentAmount = workingStatementBalance;

            if (this.statementHasBuckets()) {
                this.setCanEditBuckets();

                if (!this.documentHasExternalPaymentPlan() || this.externalPlanUserPayingDifferentAmount) {
                    this.distributePaymentAmountToBuckets();
                } else {
                    this.statement.paymentBuckets.forEach((x) => {
                        x.payToBucket = true;
                    });
                }

                if (this.promptPayIsPossible()) {
                    this.bucketUIToggle(true);
                    this.showPromptPayShortPayDisclaimer = false;
                }
            }
        }
        if (!this.statement.payToItem) {
            this.statement.paymentAmount = null;

            if (this.statementHasBuckets()) {
                if (!this.documentHasExternalPaymentPlan() || !!this.externalPlanUserPayingDifferentAmount) {
                    this.statement.paymentBuckets.forEach((x) => {
                        x.bucketPaymentAmount = null;
                        x.payToBucket = false;
                    });
                    this.showBucketAdjustmentDisabledDisclaimer = false;
                    this.paymentBucketCmpList.forEach((x) => x.overpayDisableUI = false);
                } else {
                    this.statement.paymentBuckets.forEach((x) => {
                        x.payToBucket = false;
                    });
                }
            }
        }
        this.payToStatementCheckedEvent.emit(statementClicked);
    }

    /**
     * Returns boolean whether the payable item type == statement
     *
     * @returns {boolean}
     *
     * @memberof PayableStatementComponent
     */
    public isStatementType(): boolean {
        return this.statement.itemType === PayableItemType.statement;
    }

    /**
     * Looks at statement payment amount and the collection of PaymentBucketComponents and returns 'true' if any one bucket is invalid.
     *
     * @returns {boolean}
     *
     * @memberOf PayableStatementComponent
     */
    public async isPayableStatementInvalid(): Promise<boolean> {
        let statementIsInvalid = false;
        let someBucketsAreInvalid = false;

        if (this.statement.paymentAmount > this.paymentAmountMax) {
            statementIsInvalid = true;
        }

        const payableBuckets = this.paymentBucketCmpList.filter((bucket) => !bucket.hardDisabled);
        if (payableBuckets.some((x) => x.isInvalid())) {
            someBucketsAreInvalid = true;
        }

        // If statement level is invalid and the buckets are expanded, close them
        if (statementIsInvalid && !someBucketsAreInvalid && this.detailsExpanded) {
            await this.toggleBucketDetails();
        }

        // If statement is good, but buckets are bad and details are closed, open them
        if (!statementIsInvalid && someBucketsAreInvalid && !this.detailsExpanded) {
            await this.toggleBucketDetails();
        }

        return statementIsInvalid || someBucketsAreInvalid;
    }

    /**
     * Disables this statements buckets based on the passed in boolean
     *
     * @param {boolean} disable
     *
     * @memberof PayableStatementComponent
     */
    public enableDisableBucketRows(disable: boolean) {
        this.paymentBucketCmpList.forEach((x) => x.isDisabled = disable);
        this.bucketUIToggle(disable);
        this.showBucketAdjustmentDisabledDisclaimer = !this.canEditBuckets;
    }

    /**
     * Clears this statments input value, checkbox setting, and then does the same for its buckets if it has any
     *
     *
     * @memberof PayableStatementComponent
     */
    public clearPayableItemsAndBuckets() {
        this.statement.payToItem = false;
        this.statement.paymentAmount = null;

        if (this.statementHasBuckets()) {
            this.statement.paymentBuckets.forEach((bucket) => {
                bucket.payToBucket = false;
                bucket.bucketPaymentAmount = null;
            });
        }
    }

    /**
     * Sets up the error messages based on the merchant profile settings. Must be tolerant of the merchantProfile not being set.
     *
     * @public
     *
     * @memberof PayableStatementComponent
     */
    public async setErrorMessages(): Promise<void> {
        await this.checkUnderAndOverPayment();

        if (!!this.statement.merchantProfile && !this.merchantProfileLoaded) {
            if (this.documentCanBeOverpaid()) {
                this.paymentAmountErrorMessage =
                    this.statement.payableAmount <= 0 ? this.overpaymentExceedsMaximumLimitError : this.invalidAmountError;
            } else {
                this.paymentAmountErrorMessage = this.exceedStatementBalance;
            }

            await this.setPaymentAmountMax();
            this.merchantProfileLoaded = true;
        } else if (!this.merchantProfileLoaded) {
            this.paymentAmountErrorMessage = this.invalidAmountError;
        }

        if (this.promptPayEnabled()) {
            this.promptPayAvailableMessage = this.promptPayAvailableMessage.replace(
                '!PROMPTPAYDATE!', this.datePipe.transform(this.statement.promptPayDueDate, 'MMMM d'));
            this.promptPayAvailableMessage = this.promptPayAvailableMessage.replace(
                '!PROMPTPAYAMOUNT!', this.currencyPipe.transform(this.statement.promptPayAmount, 'USD', 'symbol'));
            this.promptPayUnavailableMessage = this.promptPayUnavailableMessage.replace(
                '!PROMPTPAYDATE!', this.datePipe.transform(this.statement.promptPayDueDate, 'MMMM d'));
            this.promptPayUnavailableMessage = this.promptPayUnavailableMessage.replace(
                '!PROMPTPAYAMOUNT!', this.currencyPipe.transform(this.statement.promptPayAmount, 'USD', 'symbol'));
        }
    }

    /**
     * Returns true if this component's statement model has buckets.
     *
     * @returns
     *
     * @memberof PayableStatementComponent
     */
    public statementHasBuckets() {
        return this.statement.paymentBuckets != null && this.statement.paymentBuckets.length > 0;
    }

    floaterIsTheOnlyBucket() {
        const bucketsNotNull = this.statement.paymentBuckets != null;
        if (!bucketsNotNull) {
            return false;
        }

        const bucketsNotEmpty = this.statement.paymentBuckets.length > 0;

        const bucketsNotIncludingFloaterIsZero = this.statement.paymentBuckets.filter(
            (bucket) => bucket.bucketId !== FloatingBucket.bucketId).length === 0;

        return bucketsNotNull && bucketsNotEmpty && bucketsNotIncludingFloaterIsZero;
    }

    public getAutopayWarningText(): string {
        if (this.statement.autopayPaymentStatus === AutopayPaymentStatus.pending) {
            return this.autopayWarning.replace('!STATUS!', 'pending');
        } else if (this.statement.autopayPaymentStatus === AutopayPaymentStatus.completed) {
            return this.autopayWarning.replace('!STATUS!', 'completed');
        }
    }

    showAutopayWarning(): boolean {
        return (this.statement.autopayPaymentStatus === AutopayPaymentStatus.pending
            || this.statement.autopayPaymentStatus === AutopayPaymentStatus.completed);
    }

    showExternalPaymentPlanWarning(): boolean {
        return this.documentHasExternalPaymentPlan()
            && this.externalPlanUserPayingDifferentAmount
            && (0 < this.statement.paymentAmount)
            && (this.statement.paymentAmount < this.statement.externalPaymentPlanAmount);
    }

    /**
     * Distributes the entered statement payment amount to the buckets based on posting method and overpayment settings
     *
     * @memberof PayableStatementComponent
     */
    distributePaymentAmountToBuckets() {
        if (this.statement.paymentAmount != null && this.statement.paymentAmount > 0 && this.statement.payableAmount > 0) {
            let bucketAllocationAmount = 0;
            const payableBuckets = this.statement.paymentBuckets.filter((x) => x.bucketBalance >= 0);
            let promptPayOverpayAmount = 0;
            bucketAllocationAmount = this.statement.paymentAmount;

            // If prompt pay, anything over that is an overpayment. Squirrel the extra amount away.
            if (this.promptPayIsPossible() && this.statement.paymentAmount > this.statement.promptPayAmount) {
                promptPayOverpayAmount = ComponentService.toDecimal(bucketAllocationAmount - this.statement.promptPayAmount);
                bucketAllocationAmount = this.statement.promptPayAmount;
            }

            const nonFloaterBuckets = payableBuckets.filter((x) => x.bucketId !== FloatingBucket.bucketId);
            // If floater is the only bucket, only allocate overpayments to it.
            if (nonFloaterBuckets.length === 0) {
                bucketAllocationAmount = Math.max(bucketAllocationAmount - this.statement.payableAmount, 0);
            } else {
                bucketAllocationAmount = this.setBucketAmountFromPercent(nonFloaterBuckets, bucketAllocationAmount);
                this.statement.paymentBuckets.forEach((x) => x.payToBucket = x.bucketPaymentAmount > 0);
            }

            // Now that normal distribution is done, put the prompt pay overpay back
            if (promptPayOverpayAmount > 0) {
                bucketAllocationAmount = bucketAllocationAmount + promptPayOverpayAmount;
            }

            if (bucketAllocationAmount > 0) {
                if (this.bucketCanBeOverpaid()) {
                    this.addOverpaymentToBucketsByPercent(payableBuckets, bucketAllocationAmount);
                    this.bucketUIToggle(!this.canEditBuckets);
                } else if (this.documentCanBeOverpaid()) {
                    // Add/fill floating bucket
                    if (payableBuckets.some((x) => x.bucketId === FloatingBucket.bucketId)) {
                        const floatingBucket = payableBuckets.filter((x) => x.bucketId === FloatingBucket.bucketId)[0];

                        floatingBucket.bucketPaymentAmount = bucketAllocationAmount;
                        floatingBucket.payToBucket = true;
                        bucketAllocationAmount = 0;
                        this.bucketUIToggle(true);
                    } else {
                        this.componentService.loggingService.log(
                            'couldn\'t locate a floating bucket...something went wrong', LoggingLevel.error);
                    }
                } else {
                    // No overpayments allowed, but they're trying to.
                    bucketAllocationAmount = 0;
                    this.bucketUIToggle(true);
                }
            } else {
                // if there is a bucket, clear it just in case it has a remnant amount
                if (payableBuckets.filter((x) => x.bucketId === FloatingBucket.bucketId).length > 0) {
                    const floatingBucket = payableBuckets.filter((x) => x.bucketId === FloatingBucket.bucketId)[0];
                    floatingBucket.bucketPaymentAmount = 0;
                }

                // the one case we don't want to reset this is when they're paying == prompt pay amount
                if (this.statement.paymentAmount !== this.statement.promptPayAmount) {
                    this.bucketUIToggle(false);
                }
            }

        }
    }

    /**
     * Triggered when user taps/clicks on the payable row typically on mobile.
     *
     * @memberof PayableStatementComponent
     */
    async payToItem(): Promise<void> {
        if (this.componentService.isMobileBrowser()) {
            this.statement.payToItem = !this.statement.payToItem;
            await this.checkboxChecked(null);
        }
    }

    /**
     * This only returns true if prompt pay is enabled and they haven't missed the date.
     *
     * @returns {boolean}
     * @memberof PayableStatementComponent
     */
    promptPayIsPossible(): boolean {
        if (!this.promptPayCalculated) {
            const todaysDate = new Date();
            todaysDate.setHours(0, 0, 0, 0);
            const promptPayDueDate = this.statement.promptPayDueDate;
            this.promptPayCalculated = true;

            this.promptPayAvailable = this.statement.promptPayAmount != null
                && this.statement.promptPayAmount > 0
                && this.statement.promptPayDueDate != null
                && todaysDate <= promptPayDueDate;
        }

        return this.promptPayAvailable;
    }

    private addOverpaymentToBucketsByPercent(payableBuckets: PaymentBucket[], bucketAllocationAmount: number) {
        // keep running until there's no money left
        while (bucketAllocationAmount > 0) {
            const bucketAllocationAmounts: number[] = [];

            // calculate amount to be allocated to each bucket by its percent
            payableBuckets.map((x) => x.percentAllocation).forEach((x) => {
                const percentOfDoc = (x / 100);
                // push that amount into the list based on the index of the current bucket.
                bucketAllocationAmounts.push(ComponentService.toDecimal(bucketAllocationAmount * percentOfDoc));
            });

            // iterate through the buckets
            payableBuckets.forEach((bucket) => {
                // get this buckets amount
                let amountToAllocate = bucketAllocationAmounts[payableBuckets.indexOf(bucket)];
                if (bucketAllocationAmount > 0) {
                    // sometimes we have an extra cent due to rounding, here we remove it
                    if (amountToAllocate > bucketAllocationAmount) {
                        amountToAllocate -= 0.01;
                    }

                    // sometimes the amounts are fractional cents, if that happens, we round up
                    if (amountToAllocate < 0.01) {
                        amountToAllocate = 0.01;
                    }

                    // add the new amount to the bucket amount
                    bucket.bucketPaymentAmount += amountToAllocate;

                    // remove what we added from the total
                    bucketAllocationAmount = ComponentService.toDecimal(bucketAllocationAmount - amountToAllocate);
                }
            });
        }
    }

    private setBucketAmountFromPercent(payableBuckets: PaymentBucket[], bucketAllocationAmount: number): number {
        const bucketAllocationAmounts: number[] = [];

        // calculate amount to be allocated to each bucket by its percent
        payableBuckets.map((x) => x.percentAllocation).forEach((x) => {
            const percentOfDoc = (x / 100);
            // push that amount into the list based on the index of the current bucket.
            bucketAllocationAmounts.push(ComponentService.toDecimal(bucketAllocationAmount * percentOfDoc));
        });

        payableBuckets.forEach((bucket) => {
            // Get this bucket's amount from the list
            let amountToAllocate = bucketAllocationAmounts[payableBuckets.indexOf(bucket)];

            // We don't allow overpayment in this method
            if (this.promptPayIsPossible()) {
                if (amountToAllocate > bucket.promptPayAmount) {
                    amountToAllocate = bucket.promptPayAmount;
                }
            } else {
                if (amountToAllocate > bucket.bucketBalance) {
                    amountToAllocate = bucket.bucketBalance;
                }
            }

            // Only allocate if there's enough left
            if (bucketAllocationAmount > 0) {
                if (amountToAllocate > bucketAllocationAmount) {
                    amountToAllocate = bucketAllocationAmount;
                }
            } else {
                amountToAllocate = 0;
            }

            bucketAllocationAmount = ComponentService.toDecimal(bucketAllocationAmount - amountToAllocate);
            bucket.bucketPaymentAmount = amountToAllocate;
        });

        if (bucketAllocationAmount > 0 && payableBuckets.some((bucket) => bucket.bucketPaymentAmount < bucket.bucketBalance)) {
            // Some buckets are not full and the percent split failed us...
            bucketAllocationAmount = this.allocateRemainingToUnderpaidBuckets(payableBuckets, bucketAllocationAmount);
        }

        return bucketAllocationAmount;
    }

    /**
     * When the percent split has not filled the buckets, but it's not necessarily an overpayment, refactor the allocations.
     *
     * @private
     * @param {PaymentBucket[]} payableBuckets
     * @param {number} bucketAllocationAmount
     * @returns {number}
     *
     * @memberof PayableStatementComponent
     */
    private allocateRemainingToUnderpaidBuckets(payableBuckets: PaymentBucket[], bucketAllocationAmount: number): number {
        let underpaidBuckets = payableBuckets.filter((x) => x.bucketPaymentAmount < x.bucketBalance);
        while (bucketAllocationAmount > 0 && underpaidBuckets.length > 0) {
            this.componentService.loggingService.log('current remaining ' + bucketAllocationAmount, LoggingLevel.debug);
            underpaidBuckets = underpaidBuckets.filter((x) => x.bucketPaymentAmount < x.bucketBalance);
            // try to subdivide what's left evenly
            let roundedPieceOfTotal = ComponentService.toDecimal(bucketAllocationAmount / underpaidBuckets.length);
            this.componentService.loggingService.log('new divided piece ' + roundedPieceOfTotal, LoggingLevel.debug);
            // if it's fractions of a penny, round up and start over.
            if (roundedPieceOfTotal < 0.01) {
                roundedPieceOfTotal = 0.01;
            }

            underpaidBuckets.forEach((bucket) => {
                // sometimes we get extra cents due to rounding, but we want to drop it by one so we can keep allocating
                if (roundedPieceOfTotal > bucketAllocationAmount) {
                    roundedPieceOfTotal -= 0.01;
                }

                if (bucketAllocationAmount - roundedPieceOfTotal >= 0) {
                    this.componentService.loggingService.log(
                        'allocating ' + roundedPieceOfTotal + ' to bucket ' + bucket.bucketId, LoggingLevel.debug);
                    if (bucket.bucketPaymentAmount + roundedPieceOfTotal > bucket.bucketBalance) {
                        roundedPieceOfTotal = bucket.bucketBalance - bucket.bucketPaymentAmount;
                    }
                    bucket.bucketPaymentAmount += roundedPieceOfTotal;
                    // subtract what we added to the bucket from the total, but round to the nearest cent
                    bucketAllocationAmount = ComponentService.toDecimal(bucketAllocationAmount - roundedPieceOfTotal);
                }
            });
        }
        return bucketAllocationAmount;
    }

    /**
     * Reset the bucket min/max values based on current payment amount and merchant profile
     *
     *
     * @memberof PayableStatementComponent
     */
    private refactorBucketMinMax(): void {
        // Bucket minimum is always zero unless they're overpaying the doc and
        // detailoverpay is allowed. In that case, the minimum is the balance.
        // Bucket maximum is either a randomly big number or the bucket balance
        // depending on whether overpayments are allowed or not, respectively.
        this.paymentBucketCmpList.filter((b) => b.bucket.bucketId !== FloatingBucket.bucketId).forEach((bucketCmp) => {
            bucketCmp.bucket.minPaymentAmount = 0;
            if (this.statement.paymentAmount > this.statement.payableAmount && this.statement.merchantProfile.detailOverpayments) {
                bucketCmp.bucket.minPaymentAmount = this.promptPayIsPossible()
                    ? bucketCmp.bucket.promptPayAmount
                    : bucketCmp.bucket.bucketBalance;
            }

            if (this.statement.merchantProfile.detailOverpayments) {
                bucketCmp.bucket.maxPaymentAmount = 99999;
            } else {
                bucketCmp.bucket.maxPaymentAmount = this.promptPayIsPossible()
                    ? bucketCmp.bucket.promptPayAmount
                    : bucketCmp.bucket.bucketBalance;
            }
        });
    }

    private bucketUIToggle(disabled: boolean, showDisclaimer: boolean = true) {
        this.paymentBucketCmpList.forEach((bucketCmp) => bucketCmp.overpayDisableUI = disabled);
        if (showDisclaimer) {
            if (this.promptPayIsPossible()) {
                this.showBucketAdjustmentDisabledDisclaimer = disabled;
            } else {
                this.showBucketAdjustmentDisabledDisclaimer = disabled && !this.canEditBuckets;
            }
        } else {
            this.showBucketAdjustmentDisabledDisclaimer = false;
        }
    }

    toggleEditableExternalPaymentPlan() {
        this.externalPlanUserPayingDifferentAmount = !this.externalPlanUserPayingDifferentAmount;
        if (this.externalPlanUserPayingDifferentAmount) {
            this.bucketUIToggle(false, false);
            this.clearPayableItemsAndBuckets();
            this.payToStatementCheckedEvent.emit(this.statement);
            this.paymentBucketCmpList.forEach(bucketcmp => {
                bucketcmp.overpayDisableUI = false;
                bucketcmp.bucket.bucketPaymentAmount = null;
                bucketcmp.bucket.displayBalance = bucketcmp.bucket.bucketBalance;
            });
        } else {
            this.bucketUIToggle(true, false);
            this.clearPayableItemsAndBuckets();
            this.payToStatementCheckedEvent.emit(this.statement);
            this.paymentBucketCmpList.forEach(bucketcmp => {
                bucketcmp.overpayDisableUI = true;
                bucketcmp.bucket.bucketPaymentAmount = bucketcmp.bucket.bucketInstallmentAmount;
                bucketcmp.bucket.displayBalance = bucketcmp.bucket.bucketInstallmentAmount;

                if (this.isOneTimePayment) {
                    // Reapply external plan amount
                    bucketcmp.bucket.payToBucket = true;
                    this.payToBucketChecked();
                }
            });
        }
    }

    /**
     * Ensures the max validation is never < 0 which would show the field is always invalid once touched.
     *
     * @returns {number}
     *
     * @memberof PayableStatementComponent
     */
    async setPaymentAmountMax(): Promise<void> {
        let previousPayments = 0;
        await this.consumerService.getConsumerPaymentHistory()
                .then(payments => {
                    payments.forEach(payment => {
                        payment.details.forEach(detail => {
                            if (detail.documentID === this.statement.documentID) {
                                previousPayments += detail.amount;
                            }
                        });
                    });

                    if (this.promptPayIsPossible()
                        && this.statement.merchantProfile != null
                        && this.statement.merchantProfile.maxOverpayment != null
                    ) {
                        if (this.isOneTimePayment) {
                            // Eventually, this logic (taking past payments into account) will
                            // be used for both oneTime and loggedIn payments,
                            // but for now, only oneTime payments will use it.
                            this.paymentAmountMax = Math.max(
                                0, this.statement.merchantProfile.maxOverpayment + this.statement.promptPayAmount - previousPayments);
                        } else {
                            this.paymentAmountMax = this.statement.merchantProfile.maxOverpayment + this.statement.promptPayAmount;
                        }
                    } else if (this.statement.merchantProfile != null && this.statement.merchantProfile.maxOverpayment != null) {
                        if (this.isOneTimePayment) {
                            // Eventually, this logic (taking past payments into account)
                            // will be used for both oneTime and loggedIn payments,
                            // but for now, only oneTime payments will use it.

                            // If this is an overpayment on a $0 or credit statement,
                            // still take previous payments into accounts, but not statement amount (since it's negative or non-existent):
                            if (this.statement.merchantProfile.allowNegativeDocumentOverpayments && this.statement.payableAmount <= 0) {
                                this.paymentAmountMax = Math.max(
                                    0, this.statement.merchantProfile.maxOverpayment - previousPayments);
                            } else {
                                this.paymentAmountMax = Math.max(
                                    0, this.statement.merchantProfile.maxOverpayment + this.statement.payableAmount - previousPayments);
                            }
                        } else {
                            if (this.statement.merchantProfile.allowNegativeDocumentOverpayments && this.statement.payableAmount <= 0) {
                                this.paymentAmountMax = this.statement.merchantProfile.maxOverpayment;
                            } else {
                                this.paymentAmountMax = this.statement.merchantProfile.maxOverpayment + this.statement.payableAmount;
                            }
                        }
                    } else if (this.isStatementType()) {
                        this.paymentAmountMax = this.statement.payableAmount;
                    } else {
                        this.paymentAmountMax = Math.max(this.statement.payableAmount, 0);
                    }
                });
    }

    /**
     * Listener function for the bucket component, bound in the HTML
     *
     * @param {boolean} bucketAmountLostFocus
     *
     * @memberof PayableStatementComponent
     */
    bucketUpdate(bucketAmountLostFocus: boolean) {
        this.sumBuckets();
        this.refactorBucketMinMax();
        if (bucketAmountLostFocus) {
            // If you overpay one detail over the statement balance, and merchantProfile.allowShortPayDetailOverpayments is false,
            // then we will still reset the amount if other details are under paid.
            // If merchantProfile.allowShortPayDetailOverpayments is true, then we allow shortpay in one detail and overpay in another.
            // No prompt pay, use full balances
            this.showOverPayShortPayError = false;

            if (!this.promptPayIsPossible() && (this.statement.paymentAmount <= this.statement.payableAmount &&
                !this.statement.merchantProfile.allowShortPayDetailOverpayments)) {
                this.statement.paymentBuckets.filter((x) => x.bucketBalance > 0).forEach((bucket) => {
                    if (bucket.bucketPaymentAmount > bucket.bucketBalance) {
                        bucket.bucketPaymentAmount = bucket.bucketBalance;
                        this.showOverPayShortPayError = true;  // Show an error that this is not allowed
                    }
                });
            } else if (this.promptPayIsPossible() && this.statement.paymentAmount <= this.statement.promptPayAmount) {
                this.statement.paymentBuckets.filter((x) => x.bucketBalance > 0).forEach((bucket) => {
                    if (bucket.bucketPaymentAmount > bucket.promptPayAmount) {
                        bucket.bucketPaymentAmount = bucket.promptPayAmount;
                    }
                });
            }

            this.sumBuckets();
        }

        this.sumTotalEvent.emit(true);
    }

    /**
     * Listener for the bucket event when the buckets checkbox is checked
     *
     * @memberof PayableStatementComponent
     */
    payToBucketChecked() {
        this.sumBuckets();
        this.refactorBucketMinMax();

        if (this.statement.paymentAmount === 0) {
            this.statement.payToItem = false;
        } else {
            this.statement.payToItem = true;
        }

        this.payToStatementCheckedEvent.emit(this.statement);
    }

    /**
     * Selects the whole number in the input field so the user can type a new number after checking pay and having the amount prefill.
     *
     * @param {*} $event
     *
     * @memberOf PayableStatementComponent
     */
    selectAllInputContent(event: any) {
        if (this.statement.payToItem) {
            event.target.select();
        }
    }

    /**
     * Triggered from the HTML by clicking the "expand" arrow for bucket details
     *
     * @returns boolean
     *
     * @memberof PayableStatementComponent
     */
    async toggleBucketDetails(): Promise<void> {
        // If maxoverpayment is 0 or null and they overpay the doc, we don't want them opening the buckets.
        // But if they invalidate it at the bucket level, they'll need to close them. Thus the inclusion of not details expanded
        // Also, if the overpayment bucket is the only bucket and they aren't overpaying yet, we don't want it opening.
        if ((!this.documentCanBeOverpaid() && !this.detailsExpanded && this.statement.paymentAmount > this.statement.payableAmount)
            || this.floaterIsTheOnlyBucket() && this.statement.paymentAmount < this.statement.payableAmount) {
            return;
        }

        // If they can prompt pay and the amounts aren't touched, prefill so they don't ruin the discount for themselves
        if (!this.detailsExpanded && this.promptPayIsPossible()
            && !this.statement.payToItem
            && (this.statement.paymentAmount == null
                || this.statement.paymentAmount === 0)) {
            this.statement.payToItem = true;
            await this.checkboxChecked();
        }

        this.bucketsAreDirty = true;
        this.detailsExpanded = !this.detailsExpanded;
        this.bucketArrowRotateDegrees = Math.abs(this.bucketArrowRotateDegrees - 180);
    }

    /**
     * Determines if the payment details arrow that expands to show buckets can
     * be visible based on Merchant Profile setting ShowDetailBuckets
     *
     * @returns boolean
     *
     * @memberof PayableStatementComponent
     */
    canShowDetailBuckets(): boolean {
        if (!this.statement.merchantProfile) {
            return true;
        }

        if (this.statement.merchantProfile.showDetailBuckets && this.floaterIsTheOnlyBucket()) {
            return this.statement.paymentAmount > this.statement.payableAmount;
        }

        return this.statement.merchantProfile.showDetailBuckets && this.statement.paymentBuckets.length > 0;
    }

    private bucketCanBeOverpaid(): boolean {
        return this.statement.merchantProfile != null
            && this.statement.merchantProfile.detailOverpayments != null
            && this.statement.merchantProfile.detailOverpayments;
    }

    private documentCanBeOverpaid(): boolean {
        return this.statement.merchantProfile != null
            && this.statement.merchantProfile.maxOverpayment != null
            && this.statement.merchantProfile.maxOverpayment > 0;
    }

    private sumBuckets() {
        if (this.statementHasBuckets()) {
            const bucketSum = this.paymentBucketCmpList.filter(
                (x) => !x.hardDisabled).reduce((sum, val) => sum + Math.abs(val.bucket.bucketPaymentAmount), 0);

            this.statement.paymentAmount = bucketSum;
        }
    }

    documentHasExternalPaymentPlan(): boolean {
        return this.statement.merchantProfile != null
            && this.statement.merchantProfile.allowExternalPaymentPlans
            && this.statement.externalPaymentPlanAmount > 0;
    }

    /**
     * Regardless of whether they've missed the due date or not, if they are/were allowed to prompt pay, we trigger things.
     *
     * @returns {boolean}
     * @memberof PayableStatementComponent
     */
    promptPayEnabled(): boolean {
        return this.statement.promptPayAmount != null && this.statement.promptPayDueDate != null;
    }

    /**
     * Determines if the date column should be displayed
     *
     * @returns boolean
     *
     * @memberof PayableStatementComponent
     */
    showPostDate(): boolean {
        if (this.merchantProfileLoaded) {
            return this.statement.merchantProfile.enableDateColumn;
        }

        return true;
    }

    private setCanEditBuckets() {
        if (!!this.statement && !!this.statement.merchantProfile) {
            const stmt = this.statement;
            const mp = this.statement.merchantProfile;

            this.canEditBuckets =
                (mp.allowDetailOverpayments && mp.showDetailBreakdownOnOverPayment && stmt.paymentAmount > stmt.payableAmount) ||
                stmt.paymentAmount <= stmt.payableAmount;
        } else {
            this.canEditBuckets = false;
        }
    }

    async checkUnderAndOverPayment(): Promise<void> {
        if (!!!this.paymentAmountMax) {
            await this.setPaymentAmountMax();
        }

        if (this.statementHasBuckets() && this.detailsExpanded) {
            for (const bucket of this.statement.paymentBuckets) {
                if (bucket.payToBucket &&
                   (this.componentService.bucketCannotBeUnderPaid(
                        bucket.bucketPaymentAmount,
                        bucket.minPaymentAmount,
                        this.statement.merchantProfile.allowShortPayDetailOverpayments) ||
                    this.componentService.bucketCannotBeOverPaid(
                        bucket.bucketPaymentAmount,
                        bucket.maxPaymentAmount,
                        this.statement.merchantProfile.detailOverpayments))
                ) {
                    this.emitOverPaymentEvent.emit(false);
                    return;
                }
                else {
                    this.emitOverPaymentEvent.emit(true);
                }
            }
        }

        if (this.statement.paymentAmount === 0 || (this.statement.paymentAmount > this.paymentAmountMax)) {
            this.emitOverPaymentEvent.emit(false);
        } else {
            this.emitOverPaymentEvent.emit(true);
        }
    }

    async emitOverPayment(): Promise<void> {
        await this.checkUnderAndOverPayment();
    }
}
