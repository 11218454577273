/* tslint:disable:variable-name */
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { PaymentMethod } from '../../models/paymentmethod';
import { PaymentSelectionStateModel } from '../../models/paymentselectionmodel';

@Injectable()
export class PaymentSelectionStateService {
    private _paymentSelectionState: PaymentSelectionStateModel;
    private _shouldSetDefaultMethod = true;
    private _updatePaymentMethod = new BehaviorSubject<boolean>(false);
    private _exitPaymentMethodModal = new BehaviorSubject<boolean>(false);
    public updatePaymentMethod$: Observable<boolean>;
    public exitPaymentMethodModal$: Observable<boolean>;

    constructor() {
        this.updatePaymentMethod$ = this._updatePaymentMethod.asObservable();
        this.exitPaymentMethodModal$ = this._exitPaymentMethodModal.asObservable();
    }

    get paymentSelectionState(): PaymentSelectionStateModel {
        return this._paymentSelectionState;
    }

    set paymentSelectionState(paymentSelectionState: PaymentSelectionStateModel) {
        this._paymentSelectionState = paymentSelectionState;
    }

    get shouldSetDefaultMethod(): boolean {
        return this._shouldSetDefaultMethod;
    }

    set shouldSetDefaultMethod(shouldSetDefaultMethod: boolean) {
        this._shouldSetDefaultMethod = shouldSetDefaultMethod;
    }

    updatePaymentMethod() {
        this._updatePaymentMethod.next(true);
    }

    exitPaymentMethodModal() {
        this._exitPaymentMethodModal.next(true);
    }

    clearState() {
        this._paymentSelectionState = null;
        this._shouldSetDefaultMethod = true;
    }
}
