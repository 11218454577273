import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ComponentService } from '../../../services/component/component.service';

@Component({
    selector: 'chatlink',
    template: require('./chatlink.component.html'),
    styles: [require('./chatlink.component.css')]
})
export class ChatLinkComponent implements OnInit, OnDestroy {

    constructor(private componentService: ComponentService) {
    }

    private ngUnsubscribe: Subject<any> = new Subject();

    path = '/chatwindow';
    chatText: string;
    isMobile = false;
    settings = '';

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit() {
        this.componentService.contentService.content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((content: any) => {
                this.chatText = this.componentService.contentService.tryGetContentItem(content, 'header', 'link', 'headerChatLink').text;
            });

        this.isMobile = this.componentService.isMobileBrowser();
    }

    linkClick() {
        if (!this.isMobile) {
            this.settings = 'toolbar=no,location=yes,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=310,height=600,top=0';
        }

        window.open(this.path, 'ChatWindow', this.settings);
    }
}
