import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ComponentService } from '../../../services/component/component.service';

@Component({
    selector: 'profile-complete',
    template: require('./profilecomplete.component.html'),
    styles: [require('./profilecomplete.component.css')]
})
export class ProfileCompleteComponent implements OnInit, OnDestroy {

    constructor(private componentService: ComponentService) {
    }

    accountSettingsLinkText: string;
    completeYourProfileText: string;
    private ngUnsubscribe: Subject<any> = new Subject();

    @Input() percentComplete: number;

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit() {
        // Called after the constructor, initializing input properties, and the first call to ngOnChanges.
        // Add 'implements OnInit' to the class.
        this.componentService.contentService.content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((content: any) => {
                this.accountSettingsLinkText = this.componentService.contentService.tryGetContentItem(content, 'home', 'profile', 'homePageAccountSettingsLink').text;
                this.completeYourProfileText = this.componentService.contentService.tryGetContentItem(content, 'home', 'profile', 'homePageCompleteYourProfile').text;
            });
    }
}
