import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccountPaymentComponent } from './pages/accountpayment/accountpayment.component';
import { AccountSelectionComponent } from './pages/accountselection/accountselection.component';
import { AutopayComponent } from './pages/autopay/autopay.component';
import { AutopayEnrollmentComponent } from './pages/autopayenrollment/autopayenrollment.component';
import { AutopayEnrollmentConfirmationComponent } from './pages/autopayenrollmentconfirmation/autopayenrollmentconfirmation.component';
import { BalanceDetailsComponent } from './pages/balancedetails/balancedetails.component';
import { ClearStorageComponent } from './pages/clearstorage/clearstorage.component';
import { ConfirmationComponent } from './pages/confirmation/confirmation.component';
import { DefaultComponent } from './pages/default/default.component';
import { EmailActivationComponent } from './pages/emailactivation/emailactivation.component';
import { EnrollmentComponent } from './pages/enrollment/enrollment.component';
import { EpicSsoComponent } from './pages/epic-sso/epic-sso.component';
import { ErrorPageComponent } from './pages/error/error.component';
import { FaqComponent } from './pages/faq/faq.component';
import { FormsComponent } from './pages/forms/forms.component';
import { FutureDateAccountPaymentConfirmationComponent } from './pages/futuredateaccountpaymentconfirmation/futuredateaccountpaymentconfirmation.component';
import { LatestActivityComponent } from './pages/latestactivity/latestactivity.component';
import { LoggedInComponent } from './pages/loggedin/loggedin.component';
import { MEMPaymentComponent } from './pages/mempayment/mempayment.component';
import { ModifiedAccountPaymentAmountComponent } from './pages/modifiedaccountpayment/amount/modifiedaccountpaymentamount.component';
import { ModifiedAccountPaymentMethodComponent } from './pages/modifiedaccountpayment/method/modifiedaccountpaymentmethod.component';
import { ModifiedAccountPaymentReviewComponent } from './pages/modifiedaccountpayment/review/modifiedaccountpaymentreview.component';
import { ModifiedEnrollmentComponent } from './pages/modifiedenrollment/modifiedenrollment.component';
import { ProfileComponent as NewProfileComponent } from './pages/newprofile/profile.component';
import { NewQuickPayManageDeliveryProfileComponent } from './pages/newquickpaydeliveryprofile/new-quick-pay-manage-delivery-profile.component';
import { OutageComponent } from './pages/outage/outage.component';
import { PaymentComponent } from './pages/payment/payment.component';
import { PaymentPlanComponent } from './pages/paymentplan/paymentplan.component';
import { PaymentPlanListComponent } from './pages/paymentplanlist/paymentplanlist.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { QuickPayDeliveryProfileComponent } from './pages/quickpaydeliveryprofile/quickpaydeliveryprofile.component';
import { QuickPaySmsProfileComponent } from './pages/quickpaysmsprofile/quickpaysmsprofile.component';
import { RecurringComponent } from './pages/recurring/recurring.component';
import { RecurringActivationComponent } from './pages/recurringactivation/recurringactivation.component';
import { RecurringConfirmationComponent } from './pages/recurringconfirmation/recurringconfirmation.component';
import { RecurringPaymentComponent } from './pages/recurringpayment/recurringpayment.component';
import { RedirectComponent } from './pages/redirect/redirect.component';
import { ShowDocumentComponent } from './pages/showdocument/showdocument.component';
import { ShowPaymentComponent } from './pages/showpayment/showpayment.component';
import { SmsEnrollmentComponent } from './pages/sms/smsenrollment.component';
import { StatementHistoryComponent } from './pages/statementhistory/statementhistory.component';
import { UnsubscribeEmailComponent } from './pages/unsubscribeemail/unsubscribeemail.component';
import { MySecureWalletComponent } from './pages/wallet/mysecurewallet.component';
import { AccountPaymentGuard } from './services/guard/account-payment.guard';
import { EnrollmentGuard } from './services/guard/enrollment.guard.service';
import { HomeGuard } from './services/guard/home.guard.service';
import { LoginGuard } from './services/guard/login.guard.service';
import { MemCodeLoginGuard } from './services/guard/mem-code-login.guard';
import { ProfileGuard } from './services/guard/profile.guard';
import { QuickpayDeliveryProfileGuard } from './services/guard/quickpay-delivery-profile.guard';
import { RoleGuard } from './services/guard/role.guard.service';
import { SmsGuard } from './services/guard/sms.guard';
import { WalletGuard } from './services/guard/wallet.guard';

const routes: Routes = [
    { path: 'home', component: LoggedInComponent, canActivate: [LoginGuard, RoleGuard, HomeGuard, MemCodeLoginGuard] },
    { path: 'default', component: DefaultComponent },
    { path: 'activity', component: LatestActivityComponent, canActivate: [LoginGuard, RoleGuard, MemCodeLoginGuard] },
    { path: 'payment', component: PaymentComponent, canActivate: [LoginGuard, RoleGuard] },
    { path: 'mempayment', component: MEMPaymentComponent },
    { path: 'accountpayment', component: AccountPaymentComponent, canActivate: [AccountPaymentGuard] },
    { path: 'accountselection', component: AccountSelectionComponent },
    { path: 'redirect', component: RedirectComponent },
    { path: 'showdocument', component: ShowDocumentComponent, canActivate: [MemCodeLoginGuard] },
    { path: 'showpayment', component: ShowPaymentComponent, canActivate: [MemCodeLoginGuard] },
    { path: 'enrollment', component: EnrollmentComponent, canActivate: [EnrollmentGuard] },
    { path: 'clean', component: ClearStorageComponent },
    { path: 'faq', component: FaqComponent },
    { path: 'forms', component: FormsComponent },
    { path: 'confirmation', component: ConfirmationComponent, canActivate: [RoleGuard] },
    { path: 'futuredateconfirmation', component: FutureDateAccountPaymentConfirmationComponent, canActivate: [RoleGuard] },
    { path: 'profile', component: ProfileComponent, canActivate: [LoginGuard, RoleGuard, ProfileGuard, MemCodeLoginGuard] },
    { path: 'newprofile', component: NewProfileComponent, canActivate: [LoginGuard, RoleGuard, ProfileGuard, MemCodeLoginGuard] },
    { path: 'statements', component: StatementHistoryComponent, canActivate: [LoginGuard, MemCodeLoginGuard] },
    { path: 'unsubscribeemail', component: UnsubscribeEmailComponent },
    { path: 'wallet', component: MySecureWalletComponent, canActivate: [LoginGuard, RoleGuard, WalletGuard] },
    { path: 'autopay', component: AutopayComponent, canActivate: [LoginGuard, RoleGuard, MemCodeLoginGuard] },
    { path: 'autopayenrollment', component: AutopayEnrollmentComponent, canActivate: [LoginGuard, RoleGuard, MemCodeLoginGuard] },
    {
        path: 'autopayenrollmentconfirmation', component: AutopayEnrollmentConfirmationComponent,
        canActivate: [LoginGuard, RoleGuard, MemCodeLoginGuard]
    },
    { path: 'recurring', component: RecurringComponent, canActivate: [LoginGuard, RoleGuard, MemCodeLoginGuard] },
    {
        path: 'recurringconfirmation',
        component: RecurringConfirmationComponent,
        canActivate: [
            LoginGuard,
            RoleGuard,
            MemCodeLoginGuard
        ]
    },
    { path: 'paymentplans/new', component: PaymentPlanComponent, canActivate: [LoginGuard, RoleGuard, MemCodeLoginGuard] },
    { path: 'paymentplans', component: PaymentPlanListComponent, canActivate: [LoginGuard, RoleGuard, MemCodeLoginGuard] },
    { path: 'balancedetails', component: BalanceDetailsComponent, canActivate: [LoginGuard, RoleGuard, MemCodeLoginGuard] },
    { path: 'sms', component: SmsEnrollmentComponent, canActivate: [LoginGuard, RoleGuard, SmsGuard] },
    { path: 'recurring/activation', component: RecurringActivationComponent, canActivate: [] },
    { path: 'recurringpaymentplan', component: RecurringPaymentComponent, canActivate: [LoginGuard, RoleGuard, MemCodeLoginGuard] },
    { path: 'outage', component: OutageComponent },
    { path: 'error', component: ErrorPageComponent },
    {
        path: 'fasttrackpayment',
        children: [
            { path: '', redirectTo: '/fasttrackpayment/amount', pathMatch: 'full'},
            { path: 'amount', component: ModifiedAccountPaymentAmountComponent, canActivate: [MemCodeLoginGuard] },
            { path: 'method', component: ModifiedAccountPaymentMethodComponent, canActivate: [MemCodeLoginGuard] },
            { path: 'review', component: ModifiedAccountPaymentReviewComponent, canActivate: [MemCodeLoginGuard] }
        ]
    },
    {
        path: 'careenroll',
        children: [
            { path: '', redirectTo: '/careenroll/amount', pathMatch: 'full'},
            { path: 'amount', component: ModifiedAccountPaymentAmountComponent, canActivate: [MemCodeLoginGuard] },
            { path: 'method', component: ModifiedAccountPaymentMethodComponent, canActivate: [MemCodeLoginGuard] },
            { path: 'review', component: ModifiedAccountPaymentReviewComponent, canActivate: [MemCodeLoginGuard] }
        ]
    },
    { path: 'modifiedenrollment', component: ModifiedEnrollmentComponent, canActivate: [] },
    { path: 'emailactivation', component: EmailActivationComponent, canActivate: [MemCodeLoginGuard] },
    { path: 'quickpay/deliveryprofile', component: QuickPayDeliveryProfileComponent, canActivate: [QuickpayDeliveryProfileGuard] },
    {
        path: 'quickpay/newdeliveryprofile',
        component: NewQuickPayManageDeliveryProfileComponent,
        canActivate: [QuickpayDeliveryProfileGuard]
    },
    { path: 'quickpay/sms', component: QuickPaySmsProfileComponent },
    { path: 'epicSso', component: EpicSsoComponent },
    { path: '**', component: RedirectComponent }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})

export class AppRoutingModule { }
