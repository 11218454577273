/* tslint:disable:triple-equals */
import { Component, Input, OnInit } from '@angular/core';
import { CreditCardIssuerType, PaymentMethod, PaymentMethodType } from '../../../models/paymentmethod';

/**
 *
 * @description Displays a passed in payment method logo image path {logoPath} with the configured options.
 * @whatItDoes
 * @export
 * @class PaymentMethodLogoComponent
 * @implements {OnInit}
 */
@Component({
    selector: 'payment-method-logo',
    template: require('./paymentmethodlogo.component.html'),
    styles: [require('./paymentmethodlogo.component.css')]
})

export class PaymentMethodLogoComponent implements OnInit {
    constructor() { }
    creditCardPaymentImages: { [key: number]: string; };

    @Input() paymentMethod: PaymentMethod;
    @Input() iconFontSize = '46px';
    @Input() backgroundSize = 'contain';

    ngOnInit() {
        this.creditCardPaymentImages = {};
        this.creditCardPaymentImages[CreditCardIssuerType.visa] = 'visa.png';
        this.creditCardPaymentImages[CreditCardIssuerType.discover] = 'discover.png';
        this.creditCardPaymentImages[CreditCardIssuerType.mastercard] = 'mc.png';
        this.creditCardPaymentImages[CreditCardIssuerType.americanexpress] = 'amex.png';
    }

    private everythingLooksGood(): boolean {
        if (!this.paymentMethod
            || this.paymentMethod.paymentMethodType == PaymentMethodType.unknown
            || (this.paymentMethod.paymentMethodType == PaymentMethodType.credit
                && this.paymentMethod.cardType == CreditCardIssuerType.unknown)) {
            // passed in payment method is not valid
            return false;
        }
        return true;
    }

    private getLogo(paymentMethod: PaymentMethod): string {
        if (!!paymentMethod) {
            let logoName = '';
            if (this.isCreditCardAndHaveImageInLookupDictionary(paymentMethod)) {
                logoName = this.creditCardPaymentImages[paymentMethod.cardType];
            }
            if (this.isBankAccount(paymentMethod)) {
                logoName = 'bank-account-icon.png';
            }
            if (this.isOther(paymentMethod)) {
                // returning nothing for now, but if there's an icon for this in the future, put it here.
                return '';
            }
            return 'url(\'/Content/' + logoName + '\')';
        } else {
            return '';
        }
    }

    private isCreditCardAndHaveImageInLookupDictionary(paymentMethod: PaymentMethod) {
        return paymentMethod.paymentMethodType == PaymentMethodType.credit && !!this.creditCardPaymentImages[paymentMethod.cardType];
    }

    private isBankAccount(paymentMethod: PaymentMethod) {
        return paymentMethod.paymentMethodType == PaymentMethodType.ach;
    }

    private isOther(paymentMethod: PaymentMethod) {
        return paymentMethod.paymentMethodType == PaymentMethodType.unknown;
    }
}
