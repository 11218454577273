
export class ErrorMessage {
    constructor(
        headerText: string = null,
        mobileHeaderText: string = null,
        errorMessageText: string = null,
        redirectButtonText: string = null,
        redirectUrl: string = null
    ) {
        this.headerText = headerText;
        this.mobileHeaderText = mobileHeaderText;
        this.errorMessageText = errorMessageText;
        this.redirectButtonText = redirectButtonText;
        this.redirectUrl = redirectUrl;
    }
    headerText: string;
    mobileHeaderText: string;
    errorMessageText: string;
    redirectButtonText: string;
    redirectUrl: string;
}

export const TEST_ERROR_MESSAGE: ErrorMessage = {
    headerText: 'Test Header',
    mobileHeaderText: 'Test Mobile Header',
    errorMessageText: 'An Error Message',
    redirectButtonText: 'Navigate Somewhere',
    redirectUrl: '/NavigateSomewhere'
};
