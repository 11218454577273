/* tslint:disable:triple-equals */
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IDomainInfo } from '../../../models/domaininfo';
import { Roles } from '../../../models/roles';
import { StatementHistoryStatement } from '../../../models/statementhistorystatement';
import { ComponentService } from '../../../services/component/component.service';
import { CheckboxComponent } from '../../Controls/Checkbox/checkbox.component';

@Component({
    selector: 'statementhistory-statements-by-year',
    template: require('./statementsbyyear.component.html'),
    styles: [require('./statementsbyyear.component.css')]
})
export class StatementHistoryStatementsByYearComponent implements OnInit, OnDestroy {

    constructor(private componentService: ComponentService) { }
    @Input() year: number;
    @Input() statements: StatementHistoryStatement[];
    domainInfo: IDomainInfo;
    private checkAllFlag: boolean;
    amountColumnHeaderText: string;
    paidColumnHeaderText: string;
    showPaidColumn = true;

    private ngUnsubscribe: Subject<any> = new Subject();

    @Output() public checkAllChecked: EventEmitter<boolean> = new EventEmitter<boolean>();

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit() {
        this.componentService.contentService.content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((content: any) => {
                this.amountColumnHeaderText = this.componentService.contentService.tryGetContentItem(content, 'statements', 'pageText', 'statementHistoryAmountColumnHeaderText').text;
                this.paidColumnHeaderText = this.componentService.contentService.tryGetContentItem(content, 'statements', 'pageText', 'statementHistoryPaidColumnHeaderText').text;
            });

        this.componentService.domainService.domainInfo$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(domainInfo => {
            if (domainInfo != null) {
            this.domainInfo = domainInfo;
            this.configurePageForRole();
            }
        });
    }

    checkAllCheckboxChecked(checkboxTarget: CheckboxComponent) {
        this.checkAllChecked.emit(this.checkAllFlag);
    }

    public configurePageForRole(): void {
        if (this.componentService.hasRole(Roles.StatementsOnly)) {
            if (!this.domainInfo.enablePayments && (this.domainInfo.externalPaymentUrl.length == 0)) {
                this.showPaidColumn = false;
            }
        }
    }
}
