import { DatePipe } from '@angular/common';
import { NgModule, Pipe } from '@angular/core';

/**
 * Verifies that a date string is valid before trying to format it since Angular 2 DatePipe will choke on an invalid date.
 *
 * @export
 * @class ValidDatePipe
 * @extends {DatePipe}
 */
@Pipe({
    name: 'validdate'
})
@NgModule({ providers: [DatePipe] })

export class ValidDatePipe extends DatePipe {

    transform(date: string, pattern?: string): any {
        if (this.isValidDate(date)) {
            return super.transform(date, pattern);
        }

        // We'll have to decide how to handle an invalid date here
        return super.transform('01/01/1969', pattern);

    }

    isValidDate(date: string) {
        const d = new Date(date);
        return (Object.prototype.toString.call(d) === '[object Date]' && !isNaN(d.getTime()));
    }
}
