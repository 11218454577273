/* tslint:disable:triple-equals */
import { Component, Input, OnInit } from '@angular/core';

/**
 *
 * @description Displays a passed in icon{iconName} from the passed in library{iconLibrary} with the configured options.
 * @whatItDoes
 * @export
 * @class IconComponent
 * @implements {OnInit}
 */
@Component({
    selector: 'icon',
    template: require('./icon.component.html'),
    styles: [require('./icon.component.css')]
})
export class IconComponent implements OnInit {
    constructor() { }

    /**
     * A comma-separated list of the possible icon libraries.
     * If adding to this list, you must import the CSS for the new library into the project.
     *
     * @private
     * @type {string[]}
     * @memberof IconComponent
     */
    private possibleIconLibraries: string[] = ['material', 'glyphicon'];
    private possibleSizeOptions: string[] = ['tiny', 'small', 'default'];
    @Input() iconLibrary = '';
    @Input() iconName = '';
    @Input() withRing = false;
    @Input() secondaryColor = false;
    @Input() inverseColors = false;
    @Input() size = 'default';
    @Input() wrapStyleOverride: any;
    @Input() wrapClassOverride = '';
    @Input() iconStyleOverride: any;
    @Input() iconClassOverride = '';
    @Input() isDisabled = false;

    ngOnInit() { }

    private everythingLooksGood(): boolean {
        let lookingGood = true;

        if (this.possibleIconLibraries.filter(library => library == this.iconLibrary).length == 0) {
            // passed in library is not valid
            lookingGood = false;
        }
        if (this.iconName == null || this.iconName == '') {
            // no icon name passed in
            lookingGood = false;
        }
        if (this.possibleSizeOptions.filter(size => size == this.size).length == 0) {
            // passed in size is not valid
            lookingGood = false;
        }

        return lookingGood;
    }

    private iconRingClass(): string {
        let returnClass = '';

        if (this.withRing) {
            returnClass = 'icon-ring';
            if (this.size == 'tiny') {
                returnClass += '-tiny';
            } else if (this.size == 'small') {
                returnClass += '-small';
            }
        }
        return returnClass;
    }

    private iconColorBgClass(): string {
        let bgClass = '';
        if (!this.isDisabled) {
            if (this.withRing && this.secondaryColor) {
                bgClass = 'icon-color-bg-secondary';
            } else if (this.withRing) {
                bgClass = 'icon-color-bg';
            }
            if (this.inverseColors) {
                bgClass += '-inverse';
            }
        } else {
            bgClass = 'icon-color-disabled';
        }
        return bgClass;
    }
}
