/* tslint:disable:triple-equals */
import { Component, Input, NgZone } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'active-user',
    template: require('./activeuser.component.html'),
    styles: [require('./activeuser.component.css')]
})

export class ActiveUserComponent {
    @Input() path: string;
    @Input() initials: string;
    @Input() size = 'small';
    @Input() isClickable = true;

    constructor(
        private parentRouter: Router,
        private ngZone: NgZone
    ) { }

    ngOnInit() {
    }

    onClick() {
        if (!!this.path && this.path.length > 0 && this.isClickable) {
            this.ngZone.run(() => this.parentRouter.navigateByUrl(this.path)).then();
        }
    }

    hasInitials(): boolean {
        return this.initials != null && this.initials != '';
    }
}
