import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IDomainInfo } from '../../models/domaininfo';
import { ComponentService } from '../../services/component/component.service';
import { ConsumerService } from '../../services/consumer/consumer.service';

@Component({
    selector: 'email-activation',
    template: require('./emailactivation.component.html'),
    styles: [require('./emailactivation.component.css')]
})

export class EmailActivationComponent implements OnInit, OnDestroy {
    domainInfo: IDomainInfo;
    loading = true;
    headlineText: string;
    thankYouMessage: string;

    private ngUnsubscribe: Subject<any> = new Subject();

    constructor(private componentService: ComponentService, private consumerService: ConsumerService) {
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit() {
        this.componentService.scrollPageToTop();
        this.componentService.contentService.content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((content: any) => {
                this.headlineText = this.componentService.contentService.tryGetContentItem(content, 'emailactivation', 'pageText', 'emailActivationHeadline').text;
                this.thankYouMessage = this.componentService.contentService.tryGetContentItem(content, 'emailactivation', 'pageText', 'emailActivationThankYouMessage').text;
                this.loading = false;
            });
    }

    isLoading() {
        return this.loading;
    }
}
