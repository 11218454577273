import { CurrencyPipe } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ACHPaymentMethod, ACHPaymentMethodAccountType, ACHPaymentMethodPaymentSource } from '../../../models/achpaymentmethod';
import { ICountry } from '../../../models/countries';
import { CreditCard } from '../../../models/creditcard';
import { IMerchantProfile } from '../../../models/imerchantprofile';
import { CreditCardIssuerType, OtherPaymentMethod, PaymentMethod, PaymentMethodType } from '../../../models/paymentmethod';
import { IState } from '../../../models/states';
import { ConsumerService } from '../../../services/consumer/consumer.service';
import { PaymentSelectionStateService } from '../../../services/paymentselection/paymentselection.state.service';
import { BasePaymentMethod } from '../../Base/BasePaymentMethod/basepaymentmethod';
import { CheckboxComponent } from './../../../components/Controls/Checkbox/checkbox.component';
import { IConsumer } from './../../../models/consumer';
import { PaymentSelectionModel, PaymentSelectionStateModel } from './../../../models/paymentselectionmodel';
import { ComponentService } from './../../../services/component/component.service';
import { PaymentMethodDisplayComponent } from './PaymentMethodDisplay/paymentmethoddisplay.component';

@Component({
    selector: 'payment-selection',
    template: require('./paymentselection.component.html'),
    styles: [require('./paymentselection.component.css')]
})

export class PaymentSelectionComponent extends BasePaymentMethod implements OnInit, OnDestroy {
    @Input() paymentSelectionModel: PaymentSelectionModel;

    @Output() checkboxCheckedEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

    componentState: PaymentSelectionStateModel;

    loading = false;
    newPaymentMethod = false;
    step2Valid = false;
    isWarningMessage = false;
    enableFuturePayment = false;
    userisLoggedIn: boolean = null;
    editingPaymentMethod = false;
    paymentMethodToUpdate: PaymentMethod;
    isFirstSaveToWalletTooltipFocus = true;
    pickerPaymentDateInitiallySet = false;
    viewChecked = false;

    isWalletEnabled = false;
    showWalletNickname = false;

    // form componentState
    nameErrorExists = false;
    addressErrorExists = false;
    cityErrorExists = false;
    stateCodeErrorExists = false;
    postalCodeErrorExists = false;
    updateAddress = false;
    isAgentAssistedPayment = false;

    stateList: IState[] = [];
    countryList: ICountry[] = [];

    // potential Content Items
    domainDocumentMerchantWarning: string;
    firstNameText: string;
    lastNameText: string;
    nameErrorText: string;
    addressText: string;
    addressErrorText: string;
    cityErrorText: string;
    stateCodeErrorText: string;
    postalCodeErrorText: string;
    paymentAmountText: string;
    paymentPlanAmountText: string;
    addToWalletTooltip: string;
    saveToWalletText: string;
    optionalNicknameText: string;
    nicknamePlaceholderText: string;
    address1Placeholder: string;
    cityPlaceholder: string;
    statePlaceholder: string;
    provincePlaceholder: string;
    countryPlaceholder: string;
    postalCodePlaceholder: string;

    updateCardLinkText: string;
    updateAchLinkText: string;
    updateLinkText: string;
    paymentDateText: string;

    maxCCPaymentErrorText: string;
    maxACHPaymentErrorText: string;
    paymentMethodExpiredErrorText: string;
    datepickerTodayText: string;
    // end Content Items

    stepValidationMessage = '';

    consumer: IConsumer;

    paymentMethods: PaymentMethod[] = [];
    preselectedPaymentMethod: PaymentMethod;

    pickerPaymentDateForm: FormGroup;

    minDate: Date = new Date();
    maxDate: Date;

    invalidDateErrorText: string;
    yesterday: Date = new Date(new Date().setDate(new Date().getDate() - 1));

    @ViewChild('paymentMethodCmp', { static: false }) paymentMethodCmp: PaymentMethodDisplayComponent;

    private ngUnsubscribe: Subject<any> = new Subject();

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private componentService: ComponentService,
        private consumerService: ConsumerService,
        private currencyPipe: CurrencyPipe,
        private paymentSelectionStateService: PaymentSelectionStateService,
        private formBuilder: FormBuilder
    ) {
        super(componentService);
    }

    get isFuturePaymentDateValid(): boolean {
        return this.componentService.isFuturePaymentDateValid;
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    async ngAfterViewChecked() {
        // The lockSaveToWallet value is not ready on ngOnInit().
        // If we have the componentState and paymentDate available
        // then we have what we need to successfully set saveToWallet.
        if (this.isViewChecked()) {
            this.componentState.saveToWallet =
                this.componentState.saveToWallet || this.paymentSelectionModel.lockSaveToWallet;

            if (!!this.paymentSelectionModel) {
                // Payments and Payment Plans go through here.  A Payment will have already
                // had this value set using the MP's FutureDateAccountPaymentDays, so we
                // don't want it overwritten by the value we use for Payment Plans.
                if (this.paymentSelectionModel.contentItemCategory === 'paymentplan') {
                    this.disablePickerAfterDays(this.paymentSelectionModel.msbPaymentPlanFutureDateLimit);
                }

                if (!!this.paymentSelectionModel.paymentDate) {
                    const modelPaymentDate = new Date(this.paymentSelectionModel.paymentDate);
                    const modelPaymentDateMoment = moment(modelPaymentDate).startOf('day');
                    const todaysDateMoment = moment(new Date()).startOf('day');

                    // If the incoming date is in the future, set the date picker to that (we want past dates to default to today):
                    if (modelPaymentDateMoment.diff(todaysDateMoment, 'days') > 0) {
                        this.pickerPaymentDateForm.patchValue({
                            paymentSelectionPaymentDate: modelPaymentDate
                        });

                        this.componentState.pickerPaymentDate = modelPaymentDate;

                        // This allows the paymentSelectionPaymentDate to be set without Angular
                        // throwing a ExpressionChangedAfterItHasBeenCheckedError.
                        this.changeDetectorRef.detectChanges();
                    }
                }
            }

            this.viewChecked = true;
        }
    }

    ngOnInit() {
        // The hours need to be reset or they'll be whatever the current hours are.
        this.minDate.setHours(0, 0, 0, 0);

        this.pickerPaymentDateForm = this.formBuilder.group({
            paymentSelectionPaymentDate: new FormControl(new Date(), Validators.required)
        });

        // in the specific case that we have an existing paymentSelectionState (ex. modifiedAccountPayment)
        if (this.paymentSelectionStateService.paymentSelectionState != null) {
            this.componentState = this.paymentSelectionStateService.paymentSelectionState;
        } else {
            this.componentState = new PaymentSelectionStateModel();
            // default country to US
            this.componentState.country = 'US';
        }

        // Default the next two settings to ensure the User isn't told their date it out of range.
        this.componentState.pickerPaymentDate = new Date();
        this.componentService.setFuturePaymentDateValid(true);

        this.isAgentAssistedPayment = this.componentService.storageService.exists('agentAssistedPaymentEmailGUID');

        this.populateConsumer();

        this.isWalletEnabled = ((!this.paymentSelectionModel.isOneTimePayment || this.paymentSelectionModel.enableQuickPayWallet) &&
                            this.paymentSelectionModel.enableWallet) &&
                            this.isLoggedIn();

        if (!this.isWalletEnabled) {
            this.disableWallet();
        }

        this.componentService.domainService.getStates().then(
            stateList => this.stateList = stateList.sort(function(a, b) { return a.code.localeCompare(b.code); }));
        this.componentService.domainService.getCountries().then(countryList => this.countryList = countryList);

        this.updateAddress = this.paymentSelectionModel.defaultUpdateAddress;

        this.componentState.pickerPaymentDate = new Date();

        this.componentService.contentService.content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((content: any) => {
                // Use one time payment content items
                this.paymentAmountText = this.componentService.contentService.tryGetContentItem(
                    content, this.paymentSelectionModel.contentItemCategory, this.paymentSelectionModel.contentItemSubCategory, 'paymentPaymentAmount').text;

                // If this is for a Deviceless Recurring payment then we need that paymentAmountText to reference
                // the correct content item to display the 'RECURRING PLAN AMOUNT' text.
                // After that we are ok with using the content items normally associated with a 'paymentplan' value.
                if (this.paymentSelectionModel.contentItemCategory === 'recurring') {
                    this.paymentSelectionModel.contentItemCategory = 'paymentplan';
                }
                this.invalidDateErrorText = this.componentService.contentService.tryGetContentItem(content, 'global', 'error', 'genericInvalidDateSelectedText').text;
                this.paymentDateText = this.componentService.contentService.tryGetContentItem(
                    content, this.paymentSelectionModel.contentItemCategory, this.paymentSelectionModel.contentItemSubCategory, 'paymentPaymentDateLabel').text;

                this.paymentMethodExpiredErrorText = this.componentService.contentService.tryGetContentItem(
                    content, 'error', this.paymentSelectionModel.contentItemSubCategory, 'paymentPaymentMethodExpiredError').text;

                this.updateAchLinkText = this.componentService.contentService.tryGetContentItem(
                    content, this.paymentSelectionModel.contentItemCategory, this.paymentSelectionModel.contentItemSubCategory, 'paymentUpdateLinkText').text;

                this.updateCardLinkText = this.componentService.contentService.tryGetContentItem(
                    content, this.paymentSelectionModel.contentItemCategory, this.paymentSelectionModel.contentItemSubCategory, 'paymentUpdateCardLinkText').text;

                this.saveToWalletText = this.componentService.contentService.tryGetContentItem(
                    content, this.paymentSelectionModel.contentItemCategory, this.paymentSelectionModel.contentItemSubCategory, 'paymentSaveToWalletText').text;

                this.optionalNicknameText = this.componentService.contentService.tryGetContentItem(
                    content, this.paymentSelectionModel.contentItemCategory, this.paymentSelectionModel.contentItemSubCategory, 'paymentOptionalNicknameText').text;

                this.nicknamePlaceholderText = this.componentService.contentService.tryGetContentItem(
                    content, this.paymentSelectionModel.contentItemCategory, this.paymentSelectionModel.contentItemSubCategory, 'paymentNicknamePlaceholderText').text;

                this.firstNameText = this.componentService.contentService.tryGetContentItem(
                    content, this.paymentSelectionModel.contentItemCategory, this.paymentSelectionModel.contentItemSubCategory, 'paymentFirstNameText').text;

                this.lastNameText = this.componentService.contentService.tryGetContentItem(
                    content, this.paymentSelectionModel.contentItemCategory, this.paymentSelectionModel.contentItemSubCategory, 'paymentLastNameText').text;

                this.addressText = this.componentService.contentService.tryGetContentItem(
                    content, this.paymentSelectionModel.contentItemCategory, this.paymentSelectionModel.contentItemSubCategory, 'paymentAddressText').text;

                this.nameErrorText = this.componentService.contentService.tryGetContentItem(
                    content, 'error', this.paymentSelectionModel.contentItemSubCategory, 'paymentNameErrorText').text;

                this.addressErrorText = this.componentService.contentService.tryGetContentItem(
                    content, 'error', this.paymentSelectionModel.contentItemSubCategory, 'paymentAddressErrorText').text;

                this.cityErrorText = this.componentService.contentService.tryGetContentItem(
                    content, 'error', this.paymentSelectionModel.contentItemSubCategory, 'paymentCityErrorText').text;

                this.postalCodeErrorText = this.componentService.contentService.tryGetContentItem(
                    content, 'error', this.paymentSelectionModel.contentItemSubCategory, 'paymentPostalCodeErrorText').text;

                this.maxCCPaymentErrorText = this.componentService.contentService.tryGetContentItem(
                    content, 'error', this.paymentSelectionModel.contentItemSubCategory, 'paymentMaxCreditCardAmountWarningText').text;

                this.maxACHPaymentErrorText = this.componentService.contentService.tryGetContentItem(
                    content, 'error', this.paymentSelectionModel.contentItemSubCategory, 'paymentMaxACHAmountWarningText').text;

                this.domainDocumentMerchantWarning = this.componentService.contentService.tryGetContentItem(
                    content, this.paymentSelectionModel.contentItemCategory, this.paymentSelectionModel.contentItemSubCategory, 'paymentDomainvsDocumentmerchantmismatch').text;

                this.address1Placeholder = this.componentService.contentService.tryGetContentItem(
                    content, this.paymentSelectionModel.contentItemCategory, this.paymentSelectionModel.contentItemSubCategory, 'paymentAddressPlaceHolderText').text;

                this.cityPlaceholder = this.componentService.contentService.tryGetContentItem(
                    content, this.paymentSelectionModel.contentItemCategory, this.paymentSelectionModel.contentItemSubCategory, 'paymentCityPlaceHolderText').text;

                this.statePlaceholder = this.componentService.contentService.tryGetContentItem(
                    content, this.paymentSelectionModel.contentItemCategory, this.paymentSelectionModel.contentItemSubCategory, 'paymentStatePlaceHolderText').text;

                this.provincePlaceholder = this.componentService.contentService.tryGetContentItem(
                    content, this.paymentSelectionModel.contentItemCategory, this.paymentSelectionModel.contentItemSubCategory, 'paymentProvidencePlaceHolderText').text;

                this.countryPlaceholder = this.componentService.contentService.tryGetContentItem(
                    content, this.paymentSelectionModel.contentItemCategory, this.paymentSelectionModel.contentItemSubCategory, 'paymentCountryPlaceHolderText').text;

                this.postalCodePlaceholder = this.componentService.contentService.tryGetContentItem(
                    content, this.paymentSelectionModel.contentItemCategory, this.paymentSelectionModel.contentItemSubCategory, 'paymentPostalCodePlaceHolderText').text;

                this.paymentPlanAmountText = this.componentService.contentService.tryGetContentItem(
                    content, this.paymentSelectionModel.contentItemCategory, this.paymentSelectionModel.contentItemSubCategory, 'paymentPlanPaymentAmountSpan').text;

                this.addToWalletTooltip = this.componentService.contentService.tryGetContentItem(
                    content, this.paymentSelectionModel.contentItemCategory, this.paymentSelectionModel.contentItemSubCategory, 'paymentAddtoWalletTooltip').text;
            });
    }

    private isViewChecked(): boolean {
        return !this.viewChecked &&
            !!this.componentState &&
            !!this.paymentSelectionModel.paymentDate;
    }

    private disablePickerAfterDays(disableAfterDays: number) {
        const futureDate: Date = new Date();
        futureDate.setDate(new Date().getDate() + disableAfterDays);
        this.maxDate = futureDate;
    }

    public setMerchantProfile(
        payableItemMerchantProfile: IMerchantProfile, updateWallet: boolean = false, checkMerchantCredentials: boolean = false
    ): void {
        this.paymentMethodCmp.merchantProfile = payableItemMerchantProfile;

        if (updateWallet) {
            this.refetchWalletUsingMerchantCredentials(payableItemMerchantProfile, checkMerchantCredentials);
        }

        this.paymentMethodCmp.updateMerchant();
        this.checkCreditCardMaxAmountExceeded();
        this.checkACHMaxAmountExceeded();
        this.checkExpiredPayment();
        this.disablePickerAfterDays(payableItemMerchantProfile.futureDateAccountPaymentDays - 1);
    }

    public getMerchantProfile(): IMerchantProfile {
        return this.paymentMethodCmp.merchantProfile;
    }

    public setPreselectedPaymentMethod(paymentMethod: PaymentMethod): void {
        if (paymentMethod != null && paymentMethod.tokenGUID !== this.componentService.NULL_GUID) {
            this.preselectedPaymentMethod = paymentMethod;
            this.componentState.selectedPaymentMethod = this.paymentMethods.find(
                p => p.tokenGUID === this.preselectedPaymentMethod.tokenGUID);
        }
    }

    public setPaymentMethodToUpdate(paymentMethodToUpdate: PaymentMethod): void {
        this.editingPaymentMethod = true;
        this.paymentMethodToUpdate = paymentMethodToUpdate;

        if (paymentMethodToUpdate.paymentProcessor.toLowerCase() === 'upay') {
            this.paymentMethodCmp.creditCardDisabled = false;
        } else {
            this.paymentMethodCmp.creditCardNumber = paymentMethodToUpdate.paymentMethodLast4;
            this.paymentMethodCmp.creditCardDisabled = true;
        }

        this.paymentMethodCmp.paymentMethodToUpdate = paymentMethodToUpdate;
        this.paymentMethodCmp.selectedPaymentMethod = paymentMethodToUpdate;
        this.initializeBillingAddress();
    }

    public isValid(): boolean {
        this.step2Valid = true;

        this.checkCreditCardMaxAmountExceeded();
        this.checkACHMaxAmountExceeded();
        this.checkExpiredPayment();

        if (this.paymentMethodCmp.selectedOtherPaymentType === PaymentMethodType.credit) {
            this.checkCreditCard();
        }

        if (this.paymentMethodCmp.selectedOtherPaymentType === PaymentMethodType.ach) {
            this.checkACH();
        }

        this.clearUnusedPayment();

        return this.step2Valid;
    }

    public selectedOtherPaymentType(): PaymentMethodType {
        if (this.paymentMethodCmp.selectedOtherPaymentType === PaymentMethodType.credit) {
            return PaymentMethodType.credit;
        } else if (this.paymentMethodCmp.selectedOtherPaymentType === PaymentMethodType.ach) {
            return PaymentMethodType.ach;
        } else {
            return PaymentMethodType.unknown;
        }
    }

    public filteredStateList(): IState[] {
        if (!this.componentState.country) {
            return this.stateList.filter(state => state.countryCode === 'US');
        } else {
            return this.stateList.filter(state => state.countryCode === this.componentState.country);
        }
    }

    public clearPayments(resetToOriginal: boolean = false): void {
        this.clearOtherPaymentACH();
        this.clearOtherPaymentCard();
        this.revertFakeSavedPayment();
        this.editingPaymentMethod = false;
        this.paymentMethodCmp.paymentMethodToUpdate = null;
        this.paymentMethodCmp.creditCardDisabled = false;

        // more than one payment method means there is a stored/saved one
        if (resetToOriginal && this.paymentMethods.length > 1) {
            this.newPaymentMethod = false;

            if (this.preselectedPaymentMethod != null) {
                this.paymentMethodCmp.changePaymentMethod(this.preselectedPaymentMethod.tokenGUID);
            } else {
                this.paymentMethodCmp.selectDefaultPaymentMethod();
            }
        }
    }

    public enableWallet(): void {
        if (this.stepValidationMessage === this.domainDocumentMerchantWarning) {
            this.stepValidationMessage = '';
        }

        this.paymentSelectionModel.enableWallet = true;
        this.paymentSelectionModel.enableQuickPayWallet = true;
        this.paymentMethodCmp.selectDefaultPaymentMethod();
    }

    public showStateDropdown(): boolean {
        return (!this.componentState.country || this.componentState.country === 'US' || this.componentState.country === 'CA');
    }

    public showProvincePlaceholder(): boolean {
        return (this.componentState.country === 'CA');
    }

    public disableWallet(showWarning: boolean = true): void {
        this.step2Valid = false;
        if (!this.paymentSelectionModel.isOneTimePayment && showWarning) {
            this.isWarningMessage = true;
            this.stepValidationMessage = this.domainDocumentMerchantWarning;
        } else if (!showWarning) {
            this.isWarningMessage = false;
            this.stepValidationMessage = '';
        }

        this.paymentSelectionModel.enableWallet = false;
        this.paymentSelectionModel.enableQuickPayWallet = false;
        this.newPaymentMethod = true;

        // If we don't have payment method set we will get the new/other payment method
        const otherPaymentMethod = Object.assign({}, OtherPaymentMethod[0]);
        this.paymentMethods = [otherPaymentMethod];
        this.componentState.selectedPaymentMethod = otherPaymentMethod;
    }

    public getPaymentSelectionState(): PaymentSelectionStateModel {
        return this.componentState;
    }

    /**
     * PaymentSource is used to show where the payment came from in reporting.  Best guess with mobile
     *
     * @returns {string}
     *
     * @memberof PaymentSelectionComponent
     */
    public getPaymentSource(): string {
        let returnVal = 'mySecureBill';
        if (this.componentService.storageService.exists('paymentsource')) {
            returnVal = this.componentService.storageService.retrieve('paymentsource');
        } else if (this.componentService.isMobileBrowser()) {
            returnVal = 'mySecureBill Mobile';
        }

        if (!!this.componentState.selectedPaymentMethod.tokenGUID && this.componentState.selectedPaymentMethod.tokenGUID !== 'newPaymentMethod') {
            // Yes, this is how it is in MSB 5.0
            returnVal = 'MySecureBill Wallet';
        }
        return returnVal;
    }

    /**
     * Setting the CreditCardPaymentMethod for making a payment
     *
     * @param {ConsumerPayment} payment
     * @returns {ConsumerPayment}
     *
     * @memberof PaymentSelectionComponent
     */
    public getCreditCardPayment(): CreditCard {
        const payment = new CreditCard();
        payment.CardNumber = this.componentState.creditCardNumber.replace(/-/g, '');
        payment.CVV = this.componentState.creditCardCvv;
        payment.ExpirationDate = new Date(
            this.componentState.creditCardExpirationMonth + '/01/' + this.componentState.creditCardExpirationYear);

        return payment;
    }

    /**
     * Setting the ACHPaymentMethod for making a payment
     *
     * @param {ConsumerPayment} payment
     * @returns {ConsumerPayment}
     *
     * @memberof PaymentSelectionComponent
     */
    public getACHPayment(): ACHPaymentMethod {
        const payment = new ACHPaymentMethod();
        payment.AccountType = this.getAccountType(this.componentState.achAccountType);
        payment.paymentSource = payment.AccountType === ACHPaymentMethodAccountType.BusinessChecking ?
                ACHPaymentMethodPaymentSource.CCD : ACHPaymentMethodPaymentSource.Web;
        payment.bankAccountNumber = this.componentState.achAccountNumber;
        payment.routingNumber = this.componentState.achRoutingNumber;
        return payment;
    }

    onPickerDateChanged(event: any) {
        const pickerDate = new Date(event.target.value);

        // This will also catch garbage dates.
        if (pickerDate < this.minDate || pickerDate > this.maxDate) {
            this.componentService.setFuturePaymentDateValid(false);

            return;
        } else {
            this.componentService.setFuturePaymentDateValid(true);
            this.componentState.pickerPaymentDate = new Date(event.target.value);
        }
    }

    public revertFakeSavedPayment(): void {
        const paymentMethod = this.paymentMethods[this.paymentMethods.length - 1];
        if (paymentMethod) {
            const otherPaymentMethod = Object.assign({}, OtherPaymentMethod[0]);
            paymentMethod.expirationDate = otherPaymentMethod.expirationDate;
            paymentMethod.description = otherPaymentMethod.description;
            paymentMethod.cardType = otherPaymentMethod.cardType;
            paymentMethod.paymentMethodType = otherPaymentMethod.paymentMethodType;
            paymentMethod.accountHolderName = otherPaymentMethod.accountHolderName;
            paymentMethod.paymentMethodLast4 = otherPaymentMethod.paymentMethodLast4;
        }
    }

    private clearOtherPaymentCard(): void {
        this.componentState.creditCardNumber = '';
        this.paymentMethodCmp.creditCardNumber = '';
        this.paymentMethodCmp.expirationMonth = 'MM';
        this.paymentMethodCmp.expirationYear = 'YYYY';
        this.paymentMethodCmp.cvvNumber = '';
        this.paymentMethodCmp.visaActive = this.paymentMethodCmp.cardClassIfActive(false);
        this.paymentMethodCmp.amExActive = this.paymentMethodCmp.cardClassIfActive(false);
        this.paymentMethodCmp.mcActive = this.paymentMethodCmp.cardClassIfActive(false);
        this.paymentMethodCmp.discoverActive = this.paymentMethodCmp.cardClassIfActive(false);
    }

    private clearOtherPaymentACH(): void {
        this.componentState.achAccountNumber = '';
        this.componentState.achRoutingNumber = '';
        this.paymentMethodCmp.accountNumber = '';
        this.paymentMethodCmp.accountNumberConfirmation = '';
        this.paymentMethodCmp.routingNumber = '';
        this.paymentMethodCmp.accountType = this.paymentMethodCmp.accountChoicesText.split(';')[0];
    }

    private clearUnusedPayment(): void {
        if (this.paymentMethodCmp.allowACH && this.paymentMethodCmp.selectedOtherPaymentType === PaymentMethodType.ach) {
            this.componentState.creditCardNumber = '';
            this.paymentMethodCmp.creditCardNumber = '';
            this.paymentMethodCmp.expirationMonth = 'MM';
            this.paymentMethodCmp.expirationYear = 'YYYY';
            this.paymentMethodCmp.cvvNumber = '';
            this.paymentMethodCmp.visaActive = this.paymentMethodCmp.cardClassIfActive(false);
            this.paymentMethodCmp.amExActive = this.paymentMethodCmp.cardClassIfActive(false);
            this.paymentMethodCmp.mcActive = this.paymentMethodCmp.cardClassIfActive(false);
            this.paymentMethodCmp.discoverActive = this.paymentMethodCmp.cardClassIfActive(false);
        }

        if (this.paymentMethodCmp.allowCredit && this.paymentMethodCmp.selectedOtherPaymentType === PaymentMethodType.credit) {
            this.componentState.achAccountNumber = '';
            this.componentState.achRoutingNumber = '';
            this.paymentMethodCmp.accountNumber = '';
            this.paymentMethodCmp.accountNumberConfirmation = '';
            this.paymentMethodCmp.routingNumber = '';
            this.paymentMethodCmp.accountType = this.paymentMethodCmp.accountChoicesText.split(';')[0];
        }
    }

    private checkCreditCardMaxAmountExceeded(): void {
        this.paymentMethodCmp.checkMaxCCErrorCondition();
        if (this.paymentMethodCmp.creditCardMaxAmountErrorExists &&
            this.paymentSelectionModel.amountToPay > this.paymentMethodCmp.merchantProfile.maxCCPaymentAmount) {
            this.step2Valid = false;

            if (this.paymentMethodCmp.merchantProfile) {
                this.stepValidationMessage = this.maxCCPaymentErrorText.replace(
                    '{0}', this.currencyPipe.transform(this.paymentMethodCmp.merchantProfile.maxCCPaymentAmount, 'USD', 'symbol', '1.2-2'));
            }
        }
    }

    private checkACHMaxAmountExceeded(): void {
        const paymentMethodType = this.selectedOtherPaymentType();
        const maxACHPaymentAmount = this.paymentMethodCmp.merchantProfile.maxACHPaymentAmount;

        // If the payment amount is greater than the ACH Max
        if (maxACHPaymentAmount !== 0 &&
            paymentMethodType === PaymentMethodType.ach &&
            this.paymentSelectionModel.amountToPay > maxACHPaymentAmount) {
            this.step2Valid = false;

            if (this.paymentMethodCmp.merchantProfile) {
                this.stepValidationMessage = this.maxACHPaymentErrorText.replace(
                    '!MAXACHPAYMENTAMOUNT!', this.currencyPipe.transform(this.paymentMethodCmp.merchantProfile.maxACHPaymentAmount, 'USD', 'symbol', '1.2-2'));
            }
        }
    }

    private checkExpiredPayment(): void {
        if (!!this.componentState && this.paymentMethodCmp.isExpired(this.componentState.selectedPaymentMethod)) {
            this.step2Valid = false;
            this.stepValidationMessage = this.paymentMethodExpiredErrorText;
        }
    }

    checkCreditCard(): void {
        if (this.paymentMethodCmp.allowCredit && this.paymentMethodCmp.selectedOtherPaymentType === PaymentMethodType.credit) {
            if (!this.componentState.selectedPaymentMethod) {
                this.componentState.selectedPaymentMethod = this.paymentMethodCmp.selectedPaymentMethod;
            }

            if (this.componentState.selectedPaymentMethod === this.paymentMethods[this.paymentMethods.length - 1] ||
                this.paymentMethodCmp.selectedPaymentMethod === this.paymentMethods[this.paymentMethods.length - 1] ||
                this.newPaymentMethod) {
                this.prepareCreditCard();
            }
        }
    }

    public clearErrorState(): void {
        this.paymentMethodCmp.clearErrorState();
        this.nameErrorExists = false;
        this.addressErrorExists = false;
        this.cityErrorExists = false;
        this.stateCodeErrorExists = false;
        this.postalCodeErrorExists = false;
    }

    clearError(): void {
        if (this.stepValidationMessage !== this.domainDocumentMerchantWarning) {
            this.stepValidationMessage = '';
        }
    }

    public prepareCreditCard(): void {
        // This is the credit card entered
        if (!this.paymentMethodCmp.creditCardNumber.includes('•')) {
            this.componentState.creditCardNumber = this.paymentMethodCmp.creditCardNumber;
        }

        this.paymentMethodCmp.hasCardNumberError();
        this.paymentMethodCmp.hasCvvError();
        this.paymentMethodCmp.hasExpirationError();
        this.validateForm();

        const errorExists = this.paymentMethodCmp.creditCardErrorExists
                        || this.paymentMethodCmp.creditCardIssuerErrorExists
                        || this.paymentMethodCmp.expirationErrorExists
                        || this.paymentMethodCmp.expirationMonthRequiredErrorExists
                        || this.paymentMethodCmp.expirationYearRequiredErrorExists
                        || this.paymentMethodCmp.cvvErrorExists
                        || this.paymentMethodCmp.creditCardMaxAmountErrorExists
                        || this.nameErrorExists
                        || this.addressErrorExists
                        || this.cityErrorExists
                        || this.stateCodeErrorExists
                        || this.postalCodeErrorExists;

        if (this.componentState.creditCardNumber.length > 0 && !this.paymentMethodCmp.creditCardErrorExists) {
            this.paymentMethodCmp.creditCardNumber = '••••-••••-••••-' + this.componentState.creditCardNumber.substr(-4);
            this.paymentMethodCmp.mask = this.paymentMethodCmp.maskHidden;
        }

        if (!errorExists) {
            this.componentState.creditCardCvv = this.paymentMethodCmp.cvvNumber;
            this.paymentMethodCmp.cvvNumber = '';
            this.componentState.creditCardExpirationMonth = this.paymentMethodCmp.expirationMonth;
            this.componentState.creditCardExpirationYear = this.paymentMethodCmp.expirationYear;

            // The following line inside this "if" was added to fix an issue with the Profile Wallet.
            // The following three values may not be present when making a payment which will result in an error.
            if (this.paymentMethodToUpdate) {
                this.componentState.selectedPaymentMethod.accountHolderName = this.paymentMethodToUpdate.accountHolderName;
                this.componentState.selectedPaymentMethod.cardType = this.paymentMethodToUpdate.cardType;
                this.componentState.selectedPaymentMethod.paymentMethodLast4 = this.paymentMethodToUpdate.paymentMethodLast4;
            }

            this.paymentMethodCmp.expirationMonth = 'MM';
            this.paymentMethodCmp.expirationYear = 'YYYY';

            const summaryExpiration = this.componentState.creditCardExpirationMonth + '/01/' + this.componentState.creditCardExpirationYear;
            let summaryDescription = '';
            if (this.componentState.newPaymentNickname != null && this.componentState.newPaymentNickname.length > 0) {
                summaryDescription = this.componentState.newPaymentNickname;
            } else {
                summaryDescription = 'CARD XXXX' + this.componentState.creditCardNumber.substr(
                    this.componentState.creditCardNumber.length - 4);
            }

            const summaryCreditCardType: CreditCardIssuerType = this.paymentMethodCmp.getLogoByCard(
                this.componentState.creditCardNumber.split('-').join(''));

            this.setPaymentTypeSummaryInformation(summaryDescription, PaymentMethodType.credit, summaryExpiration, summaryCreditCardType);
        } else {
            this.step2Valid = false;
            this.stepValidationMessage = this.paymentMethodCmp.stepValidationError;
        }
    }

    public checkACH(): void {
        this.validateForm();
        if (this.paymentMethodCmp.allowACH && this.paymentMethodCmp.selectedOtherPaymentType === PaymentMethodType.ach) {
            if (this.componentState.selectedPaymentMethod === this.paymentMethods[this.paymentMethods.length - 1] ||
                    this.newPaymentMethod) {
                if (this.nameErrorExists) {
                    this.step2Valid = false;
                }

                if (this.paymentMethodCmp.accountNumber.length <= 0) {
                    this.paymentMethodCmp.accountErrorExists = true;
                    this.step2Valid = false;
                } else {
                    this.paymentMethodCmp.accountErrorExists = false;
                }

                if (this.paymentMethodCmp.hasAccountNumbersMatchError()) {
                    this.step2Valid = false;
                }

                if (this.paymentMethodCmp.routingNumber.length <= 0) {
                    this.paymentMethodCmp.routingErrorExists = true;
                    this.step2Valid = false;
                } else {
                    this.paymentMethodCmp.routingErrorExists = false;
                }
            }
            if (this.step2Valid) {
                if (!this.paymentMethodCmp.accountNumber.includes('•') && this.paymentMethodCmp.accountNumber.length > 0) {
                    this.componentState.achAccountNumber = this.paymentMethodCmp.accountNumber;
                    this.paymentMethodCmp.accountNumber = '••••' +
                        this.paymentMethodCmp.accountNumber.substr(this.paymentMethodCmp.accountNumber.length - 4);
                    this.paymentMethodCmp.accountNumberConfirmation = '••••' +
                        this.paymentMethodCmp.accountNumberConfirmation.substr(this.paymentMethodCmp.accountNumberConfirmation.length - 4);
                }

                let summaryDescription = '';
                if (this.componentState.newPaymentNickname != null && this.componentState.newPaymentNickname.length > 0) {
                    summaryDescription = this.componentState.newPaymentNickname;
                } else if (!!this.componentState.achAccountNumber) {
                    summaryDescription = 'Bank Account XXXX' +
                        this.componentState.achAccountNumber.substr(this.componentState.achAccountNumber.length - 4);
                } else if (!!this.componentState.selectedPaymentMethod && !!this.componentState.selectedPaymentMethod.description) {
                    summaryDescription = this.componentState.selectedPaymentMethod.description;
                }

                this.componentState.achAccountType = this.paymentMethodCmp.accountType;
                this.componentState.achRoutingNumber = this.paymentMethodCmp.routingNumber;
                this.componentState.otherPaymentType = this.paymentMethodCmp.selectedOtherPaymentType;
                this.setPaymentTypeSummaryInformation(summaryDescription, PaymentMethodType.ach);
            }
        }
    }

    private setPaymentTypeSummaryInformation(
        description: string, paymentMethodType: PaymentMethodType,
        expirationDate: string = null, cardType: CreditCardIssuerType = CreditCardIssuerType.unknown) {
        if (!!this.paymentMethods[this.paymentMethods.length - 1]) {
            this.paymentMethods[this.paymentMethods.length - 1].description = description;
            this.paymentMethods[this.paymentMethods.length - 1].paymentMethodType = paymentMethodType;
            this.paymentMethods[this.paymentMethods.length - 1].cardType = cardType;

            if (expirationDate) {
                this.paymentMethods[this.paymentMethods.length - 1].expirationDate = expirationDate;
            }
        }
    }

    isLoggedIn() {
        if (this.userisLoggedIn == null) {
            this.userisLoggedIn = this.componentService.userIsLoggedIn();
        }
        return this.userisLoggedIn;
    }

    private populateConsumer(): void {
        if (this.isLoggedIn()) {
            this.consumerService.getConsumer()
                .then(consumer => {
                    this.consumer = consumer, this.initializeBillingAddress(), this.initializeWallet();
                });
        }
    }

    checkboxChecked(checkboxTarget: CheckboxComponent): void {
        // Can't uncheck if locked
        if (this.paymentSelectionModel.lockSaveToWallet) {
            return;
        }

        if (this.componentState.saveToWallet) {
            this.showWalletNickname = true;
        } else {
            this.showWalletNickname = false;
        }

        this.checkboxCheckedEvent.emit(this.componentState.saveToWallet);
    }

    private validateForm(): void {
        this.validateName();
        this.validateAddress();
        this.validateCity();
        this.validateState();
        this.validatePostalCode();
    }

    validateName(): void {
        if ((!this.componentState.firstName || this.componentState.firstName.trim().length <= 0) &&
            (!this.componentState.lastName || this.componentState.lastName.trim().length <= 0)) {
            this.nameErrorExists = true;
        } else {
            this.nameErrorExists = false;
        }

        if (this.nameErrorExists) {
            this.updateAddress = true;
        }
    }

    validateAddress(): void {
        if (!this.componentState.address1 || this.componentState.address1.trim().length <= 0) {
            this.addressErrorExists = true;
        } else {
            this.addressErrorExists = false;
        }

        if (this.addressErrorExists) {
            this.updateAddress = true;
        }
    }

    validateCity(): void {
        if (!this.componentState.city || this.componentState.city.trim().length <= 0) {
            this.cityErrorExists = true;
        } else {
            this.cityErrorExists = false;
        }

        if (this.cityErrorExists) {
            this.updateAddress = true;
        }
    }

    validateState(): void {
        if (!this.componentState.state || this.componentState.state.trim().length <= 0) {
            this.stateCodeErrorExists = true;
        } else {
            this.stateCodeErrorExists = false;
        }

        // If there is an address related error, just in case we have bad data, pop this open to show the errors.
        if (this.stateCodeErrorExists) {
            this.updateAddress = true;
        }
    }

    validatePostalCode(): void {
        if (this.showStateDropdown()) {
            if (!this.componentState.postalCode) {
                this.postalCodeErrorExists = true;
            } else if (this.componentState.country === 'US'
                && this.componentState.postalCode.trim().length !== 5
                && this.componentState.postalCode.trim().length !== 10) {
                this.postalCodeErrorExists = true;
            } else if (this.componentState.country === 'CA'
                && this.componentState.postalCode.trim().length !== 6
                && this.componentState.postalCode.trim().length !== 7) {
                this.postalCodeErrorExists = true;
            } else {
                this.postalCodeErrorExists = false;
            }
        } else {
            this.postalCodeErrorExists = false;
        }

        if (this.postalCodeErrorExists) {
            this.updateAddress = true;
        }
    }

    expandAddress(event: any): void {
        this.updateAddress = true;
    }

    private checkOneTimePayment(): boolean {
        return this.componentService.storageService.exists('onetimepayment') && this.componentService.storageService.retrieve('onetimepayment') === true;
    }

    isPaymentMethodSelected(): boolean {
        return !!this.componentState.selectedPaymentMethod;
    }

    public initializeBillingAddress(): void {
        if (this.consumer != null) {
            if (this.consumer.firstName || this.consumer.lastName) {
                this.componentState.firstName = this.consumer.firstName;
                this.componentState.lastName = this.consumer.lastName;
            } else {
                if (this.consumer.fullName.includes(',')) {
                    // last first
                    this.componentState.firstName = this.consumer.fullName.split(',')[1];
                    this.componentState.lastName = this.consumer.fullName.split(',')[0];
                } else {
                    this.componentState.firstName = this.consumer.fullName.split(' ')[0];
                    this.componentState.lastName = this.consumer.fullName.split(' ')[1];
                }
            }
        }

        if (this.editingPaymentMethod) {
            // Update billing address fields to use paymentMethod's address
            this.componentState.address1 = this.paymentMethodToUpdate.address;
            this.componentState.city = this.paymentMethodToUpdate.city;
            this.componentState.state = this.paymentMethodToUpdate.state;
            this.componentState.postalCode = this.paymentMethodToUpdate.postalCode;
            if (this.paymentMethodToUpdate.country) {
                this.componentState.country = this.paymentMethodToUpdate.country;
            }
        } else if (this.consumer != null) {
            this.componentState.address1 = this.consumer.address1;
            this.componentState.address2 = this.consumer.address2;
            this.componentState.city = this.consumer.city;
            this.componentState.state = this.consumer.state;
            this.componentState.postalCode = this.consumer.postalCode;
            if (this.consumer.country) {
                this.componentState.country = this.consumer.country;
            }
        }
    }

    private initializeWallet(): void {
        if ((this.paymentSelectionModel.isOneTimePayment
            && !this.paymentSelectionModel.enableQuickPayWallet)
            || !this.isWalletEnabled) {
            // Don't display the wallet for one time payments (today) or when wallet is disabled in Domain settings
            this.disableWallet();
        } else {
            this.consumerService.getWalletPaymentMethods().then(walletPaymentMethods => {
                if (walletPaymentMethods.length === 1) {
                    // Only 'Enter Other Payment' is available and should be selected
                    this.newPaymentMethod = true;
                }

                // Calling getWalletPaymentMethods will load localstorage with the wallet. subsequent requests can use paymentMethods$
                this.consumerService.paymentMethods$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(methods => {
                    this.paymentMethods = methods;

                    if (this.isAgentAssistedPayment) {
                        this.selectOtherPaymentMethod();
                    } else {
                        const primaryPaymentMethod: PaymentMethod = this.paymentMethods.find((x) => x.primary);
                        this.componentState.selectedPaymentMethod = !!primaryPaymentMethod ? primaryPaymentMethod : this.paymentMethods[0];
                    }
                });
            });
        }
    }

    public selectOtherPaymentMethod(): void {
        // newPaymentMethod will let us enter payment method info instead of just selecting a saved one:
        this.newPaymentMethod = true;

        // "otherPaymentMethod" is a new payment method - that is, the option to enter payment method info:
        this.paymentMethodCmp.paymentMethods = this.paymentMethods;
        this.paymentMethodCmp.selectOtherPaymentMethod();

        // Finally, this call ensures that the "Change Payment Method" dropdown link shows up:
        this.paymentMethodCmp.walletIsEmpty();
    }

    private refetchWalletUsingMerchantCredentials(payableItemMerchantProfile: IMerchantProfile, checkMerchantCredentials: boolean): void {
        this.loading = true;
        this.componentService.domainService.getDomainInfo().then(domainInfo => {
            if (this.isWalletEnabled) {
                this.paymentMethodCmp.checkCredentialMismatch = checkMerchantCredentials;

                // Get payable item merchant profile, compare to domain merchant profile,
                // excluding MerchantCredentialVendor = "Card Connect" or "RevSpring"
                this.consumerService.getMerchantProfileForDomain().then(domainMerchantProfile => {
                    const vendors = [
                        payableItemMerchantProfile.merchantCredentialVendor,
                        domainMerchantProfile.merchantCredentialVendor
                    ];
                    const multiMID = this.consumerService.isProcessorMultiMID(vendors);

                    // If the payableitem (paymentplan, paymentplanbalance)  merchant credentials
                    if (payableItemMerchantProfile.merchantCredentialGUID !== domainMerchantProfile.merchantCredentialGUID && !multiMID) {
                        // Determine if ACH is allowed
                        this.paymentMethodCmp.merchantCredentialMismatch = true;
                        this.disableWallet(false);
                        this.paymentMethodCmp.updateMerchant();
                        this.loading = false;
                    } else {
                        // Get wallet based on merchant credentials of payable item (paymentplan, paymentplanbalance)
                        this.consumerService.getWalletPaymentMethodsByMerchant(
                            payableItemMerchantProfile.merchantCredentialGUID).then(wallet => {
                            this.paymentMethods = wallet;
                            if (!this.paymentMethods.some(x => x.tokenGUID === 'newPaymentMethod')) {
                                const otherPayment = Object.assign({}, OtherPaymentMethod[0]);
                                this.paymentMethods = this.paymentMethods.concat(otherPayment);
                            }

                            this.clearPayments(true);

                            if (this.paymentMethods.length === 1 || this.isAgentAssistedPayment) {
                                // Only 'Enter Other Payment' is available and should be selected
                                this.newPaymentMethod = true;
                            }

                            // Determine if ACH is allowed
                            this.paymentMethodCmp.merchantCredentialMismatch =
                                payableItemMerchantProfile.merchantCredentialGUID !== domainMerchantProfile.merchantCredentialGUID;

                            this.paymentMethodCmp.paymentMethods = this.paymentMethods;
                            this.enableWallet();
                            this.paymentMethodCmp.updateMerchant();
                            this.loading = false;
                        }).catch(walletError => {
                            this.componentService.loggingService.handleError(walletError);
                            this.loading = false;
                        });
                    }
                });
            } else {
                this.loading = false;
            }
        });
    }

    isExtraSmall(): boolean {
        return window.matchMedia('(max-width:768px)').matches;
    }

    paymentMethodChanged(paymentMethod: PaymentMethod): void {
        if (paymentMethod != null) {
            // We don't want to remove the document merchant warning if we can avoid it.
            if (paymentMethod.paymentMethodType !== PaymentMethodType.unknown
                || this.stepValidationMessage !== this.domainDocumentMerchantWarning) {
                this.stepValidationMessage = '';
            }

            this.componentState.selectedPaymentMethod = paymentMethod;
        }
    }

    paymentMethodTypeChanged(paymentMethodType: PaymentMethodType): void {
        if (paymentMethodType === PaymentMethodType.credit) {
            this.updateLinkText = this.updateCardLinkText;
        } else {
            this.updateLinkText = this.updateAchLinkText;
        }
    }

    isLoading(): boolean {
        return this.loading;
    }

    private getAccountType(achAccountType: string ): ACHPaymentMethodAccountType {
        if (achAccountType.trim().split(' ')[1] === 'Savings') {
            return ACHPaymentMethodAccountType.Savings;
        } else if (achAccountType.trim().split(' ')[0] === 'Personal') {
            return ACHPaymentMethodAccountType.Checking;
        } else {
            return ACHPaymentMethodAccountType.BusinessChecking;
        }
    }
}
