import { Injectable, NgZone } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Roles } from '../../models/roles';
import { ComponentService } from '../component/component.service';
import { LoggingLevel } from '../logging/logging.service';

@Injectable()
export class RoleGuard implements CanActivate {

    constructor(
        private router: Router,
        private componentService: ComponentService,
        private ngZone: NgZone
    ) {
    }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.isRoleAllowed(state.url)) {
            return true;
        } else {
            // redirect to / will send them to the redirect page which should do the figuring out of where they should go.
            this.ngZone.run(() => {
                this.router.navigateByUrl('/');
            });
            return false;
        }
    }

    /**
     * A user is technically logged in when a token is present, but they are not allowed to go to the page based on role permissions
     *
     *
     * @private
     * @returns {boolean}
     * @memberof RoleGuard
     */
    private isRoleAllowed(url: string): boolean {
        // TODO: call API to get a list of allowed roles for the requested path => allowedRoles
        // TODO: compare user's list of roles to the allowedRoles list
        // TODO: am not rewriting existing code right now "Williams Rule"  next role
        // This is temporarily hard-coded for MSB-1153
        let roleAllowed: boolean;
        if (this.componentService.storageService.exists('noconsumerstatementhistoryrole')) {
            roleAllowed = url === '/statements';
        } else if (this.componentService.hasRole(Roles.StatementsOnly)) {
            // statements only allows statements and profile
            roleAllowed = url === '/statements'
                        || url === '/profile'
                        || url === '/newprofile';
        } else if (this.componentService.storageService.exists('agentAssistedRole')) {
            const role = this.componentService.storageService.retrieve('agentAssistedRole');

            switch (role.toLowerCase()) {
                case 'autopayenrollmentconfirmation':
                    roleAllowed = url === '/autopayenrollmentconfirmation';
                    break;
                case 'autopayenrollment':
                    roleAllowed = url === '/autopayenrollment';
                    break;
                case 'future':
                    roleAllowed = url === '/payment';
                    break;
                case 'futureconfirmation':
                    roleAllowed = url === '/futuredateconfirmation';
                    break;
                case 'autopay':
                    roleAllowed = url === '/autopayenrollment';
                    break;
                case 'autopayexisting':
                    roleAllowed = url === '/wallet' || url === '/autopayenrollmentconfirmation';
                    break;
                case 'payment':
                    roleAllowed = url === '/payment';
                    break;
                case 'paymentconfirmation':
                    roleAllowed = url === '/confirmation';
                    break;
                case 'paymentplannew':
                    roleAllowed = url === '/paymentplans/new';
                    break;
                case 'paymentplanexisting':
                    roleAllowed = url === '/wallet' || url === '/paymentplans';
                    break;
                case 'preservicenew':
                    roleAllowed = url === '/recurringpaymentplan' || url === '/recurringconfirmation';
                    break;
                case 'preserviceexisting':
                    roleAllowed = url === '/wallet' || url === '/recurringconfirmation';
                    break;
                case 'recurringconfirmation':
                    roleAllowed = url === '/recurringconfirmation';
                    break;
                case 'recurringnew':
                    roleAllowed = url === '/recurringpaymentplan' || url === '/recurringconfirmation';
                    break;
                case 'recurringexisting':
                    roleAllowed = url === '/wallet' || url === '/recurringconfirmation';
                    break;
                case 'wallet':
                    roleAllowed = url === '/wallet';
                    break;
                default:
                    roleAllowed = false;
            }
        } else {
            roleAllowed = true;
        }
        this.componentService.loggingService.log('RoleGuard->isRoleAllowed->' + roleAllowed, LoggingLevel.debug);
        return roleAllowed;
    }
}
