import { Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PaymentMethod } from '../../../models/paymentmethod';
import { ComponentService } from '../../../services/component/component.service';
import { BasePaymentMethod } from '../../Base/BasePaymentMethod/basepaymentmethod';

@Component({
    selector: 'payment-method-dropdown',
    template: require('./paymentmethoddropdown.component.html'),
    styles: [require('./paymentmethoddropdown.component.css')],
})
export class PaymentMethodDropdownComponent extends BasePaymentMethod implements OnInit, OnDestroy {
    private ngUnsubscribe: Subject<any> = new Subject();

    @Input() selectedPaymentMethod: PaymentMethod;
    @Input() isOneTimePayment: boolean;
    @Input() paymentMethods: PaymentMethod[] = [];
    @Input() sourceUrl: string;
    @Input() showNewPaymentMethodOption = false;

    @Output() public paymentMethodChanged: EventEmitter<string> = new EventEmitter<string>();

    changePaymentMethodText: string;
    addNewPaymentMethodText: string;
    dropdownSize: string;

    constructor(
        private componentService: ComponentService,
        private parentRouter: Router,
        private ngZone: NgZone
    ) {
        super(componentService);
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit(): void {
        this.setDropdownSize();
        this.componentService.contentService.content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((content: any) => {
                if (this.isOneTimePayment) {
                    this.changePaymentMethodText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'oneTimePayment', 'paymentChangePaymentMethodText').text;
                } else {
                    this.changePaymentMethodText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'loggedInPayment', 'paymentChangePaymentMethodText').text;
                }

                this.addNewPaymentMethodText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'loggedInPayment', 'paymentAddNewPaymentMethodText').text;
            });
    }

    changePaymentMethod(walletGUID: string): void {
        this.paymentMethodChanged.emit(walletGUID);
    }

    goToWallet(): void {
        this.componentService.storageService.store('returnurl', this.sourceUrl);
        this.ngZone.run(() => this.parentRouter.navigateByUrl('wallet')).then();
    }

    setDropdownSize(): void {
        if (window.innerWidth <= 350) {
            this.dropdownSize = '4';
        } else if (window.innerWidth <= 400) {
            this.dropdownSize = '6';
        } else {
            this.dropdownSize = '8';
        }
    }
}
