/* tslint:disable:triple-equals */
import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { ComponentService } from '../../../services/component/component.service';

@Component({
    selector: 'custom-widget',
    template: require('./customwidget.component.html')
})
export class CustomWidgetComponent implements OnInit {
    @Input() customHtml: string;
    @Input() customScript: string;
    @Input() customStyle: string;

    @ViewChild('component', { static: false }) component: any;

    safeHtml: SafeHtml;
    styleHash: number;
    // scriptHash: number;

    // Something we might need if we are going to load these all over the place with widgets. Not sure though.
    uniqueID = '';

    constructor(@Inject(DOCUMENT) private document: any, private componentService: ComponentService) {
    }

    /**
     * First hash the style and script so we don't need to hash them again.
     *
     * Then make the HTML safe to be loaded into the DOM and add the style script to the DOM
     */
    ngOnInit() {
        this.customScript = this.customScript.trim();
        this.customStyle = this.customStyle.trim();
        this.customHtml = this.customHtml.trim();
        if (this.customStyle) {
            this.addStyleTag();
        }
        if (this.customScript) {
            this.addScriptTag();
        }
        this.safeHtml = this.componentService.BypassSecurityTrustHtml(this.customHtml);
    }
    /**
     * Add the style tag with the hash data attribute to the DOM
     */
    addStyleTag(): void {
        const style = document.createElement('link');
        style.setAttribute('data-hash', this.hashCode(this.customStyle).toString());
        style.type = 'text/css';
        style.setAttribute('rel', 'stylesheet');
        style.setAttribute('href', this.customStyle);
        this.document.head.appendChild(style);
    }

    /**
     * Add the script tag with the hash data attribute to the DOM
     *
     * In order to have the javascript run on every page we need to add it to the DOM every time.
     * We could execute it - but don't know of a way to do that when the script has a src tag.
     *
     * So first we remove the old script and then readd the javascript to the page.
     *
     * If the string is a script tag
     *   - Get the source and set the source on the tag and append to the DOM
     *
     * If the string is not a script tag
     *   - Add the javascript directly to the script tag as a text node
     */
    addScriptTag(): void {
        if (/<script.*?"(http.*?)"/g.test(this.customScript)) {
            // the call in the if is against a pure regex because if the variable is used it omits the first match
            const regex = /<script.*?"(http.*?)"/g;
            const scriptsToLoad: string[] = [];
            let regexMatch;
            // tslint:disable-next-line: no-conditional-assignment
            while (regexMatch = regex.exec(this.customScript)) {
                const scriptUrl = regexMatch[1];
                const scriptAlreadyExists = this.document.querySelector('script[data-hash=\'' + this.hashCode(scriptUrl) + '\']');
                // if (!scriptAlreadyExists) {
                //    scriptsToLoad.push(scriptUrl);
                // }
                scriptsToLoad.push(scriptUrl);
            }

            this.loadScriptAsync(scriptsToLoad, 0);
        } else {
            this.loadScriptAsync([this.customScript], 0);
        }
    }

    private loadScriptAsync(scriptsToLoad: string[], indexToLoad: number) {
        if (indexToLoad < scriptsToLoad.length) {
            const script = document.createElement('script');
            const scriptUrl = scriptsToLoad[indexToLoad];
            script.setAttribute('data-hash', this.hashCode(scriptUrl).toString());
            script.src = scriptUrl;
            const that = this;
            script.onload = function() {
                that.loadScriptAsync(scriptsToLoad, indexToLoad + 1);
            };
            this.document.head.appendChild(script);
        }
    }

    /**
     * Hashing the css and js is done so that when the user navigates around the site we are not adding
     * the same script and style tags over and over and over again.
     *
     * https://stackoverflow.com/questions/7616461/generate-a-hash-from-string-in-javascript-jquery
     */
    private hashCode(input: string): number {
        let hash = 0;
        if (!input || input.length == 0) { return hash; }

        for (let i = 0; i < input.length; i++) {
            const char = input.charCodeAt(i);
            // tslint:disable-next-line: no-bitwise
            hash = ((hash << 5) - hash) + char;
            // tslint:disable-next-line: no-bitwise
            hash = hash & hash; // Convert to 32bit integer
        }
        return hash;
    }
}
