import { IPaymentPlanBalance } from './paymentplanbalance';
import { IPaymentPlanNewChargesModel } from './paymentplannewchargesmodel';

export interface IPaymentPlanInitializationModel {
    hasEligibleBalances: boolean;
    status: PaymentPlanInitializationStatus;
    totalBalance: number;
    balancesToCreate: IPaymentPlanBalance[];
    balancesToAdd: IPaymentPlanNewChargesModel[];
}

export enum PaymentPlanInitializationStatus {
    eligible = 1,
    noEligibleBalances = 2,
    multiplePaymentPlansNotAllowed = 3,
    unknown = 0
}
