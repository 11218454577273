/* tslint:disable:triple-equals */
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IAutopayPayment } from '../../models/autopaypayment';
import { IRecurringPaymentPlan, RecurringPaymentPlanStatus, RecurringPlanType } from '../../models/recurringpaymentplan';
import { ComponentService } from '../../services/component/component.service';
import { ConsumerService } from '../../services/consumer/consumer.service';

@Component({
    selector: 'recurring-plan-list',
    template: require('./recurringplanlist.component.html'),
    styles: [require('./recurringplanlist.component.css')]
})

export class RecurringPlanListComponent implements OnInit, OnDestroy {

    currentList: IRecurringPaymentPlan[] = [];
    pastList: IRecurringPaymentPlan[] = [];
    loading = true;
    currentButtonText: string;
    pastButtonText: string;
    currentOtherSelected = 'primary-white';
    pastOtherSelected = 'white-primary';
    currentPlansText: string;
    pastPlansText: string;
    currentPlansStatus: string;
    pastPlansStatus: string;
    showCurrent = true;

    private ngUnsubscribe: Subject<any> = new Subject();
    @Output() public optedOutPlan: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(private componentService: ComponentService, private consumerService: ConsumerService) {
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    async ngOnInit() {
        this.componentService.contentService.content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((content: any) => {
                this.currentButtonText = this.componentService.contentService.tryGetContentItem(content, 'recurring', 'pageText', 'recurringCurrentButtonText').text;
                this.pastButtonText = this.componentService.contentService.tryGetContentItem(content, 'recurring', 'pageText', 'recurringPastButtonText').text;
                this.currentPlansText = this.componentService.contentService.tryGetContentItem(content, 'recurring', 'pageText', 'recurringCurrentPlansText').text;
                this.pastPlansText = this.componentService.contentService.tryGetContentItem(content, 'recurring', 'pageText', 'recurringPastPlansText').text;
            });
        await this.retrieveRecurringPaymentPlans();
    }

    async retrieveRecurringPaymentPlans() {
        this.currentList = [];
        this.pastList = [];
        try {
            const plans = await this.consumerService.getRecurringPaymentPlans();
            for (const plan of plans) {
                if (plan.status == RecurringPaymentPlanStatus.cancelled || plan.status == RecurringPaymentPlanStatus.completed) {
                    this.pastList.push(plan);
                } else {
                    if (plan.type == RecurringPlanType.preservice && plan.status == RecurringPaymentPlanStatus.pending) {
                        plan.status = RecurringPaymentPlanStatus.active;
                    }
                    this.currentList.push(plan);
                }
            }
            this.setPlanNumbersText();
            this.doneLoading();
        } catch (e) {
            this.doneLoading();
        }
    }

    setPlanNumbersText() {
        this.currentPlansStatus = this.currentPlansText.replace(
            '!NUMAGREEMENTS!', (!this.currentList ? '0' : this.currentList.length.toString()));
        this.currentPlansStatus = this.currentPlansStatus.replace('!AGREEMENT!', (!this.currentList || this.currentList.length != 1) ? ' plans.' : ' plan.');
        this.pastPlansStatus = this.pastPlansText.replace('!NUMAGREEMENTS!', (!this.pastList ? '0' : this.pastList.length.toString()));
        this.pastPlansStatus = this.pastPlansStatus.replace('!AGREEMENT!', (!this.pastList || this.pastList.length != 1) ? ' plans.' : ' plan.');
    }

    doneLoading(): void {
        this.loading = false;
    }

    optedOut(agreement: IRecurringPaymentPlan): void {
        this.loading = true;
        this.retrieveRecurringPaymentPlans();
        this.showPastList();
        this.optedOutPlan.emit(true);
    }

    cancelledPayment(payment: IAutopayPayment): void {
        this.loading = true;
        this.retrieveRecurringPaymentPlans();
    }

    showCurrentList(): void {
        this.showCurrent = true;
        this.setPlanNumbersText();
        this.currentOtherSelected = 'primary-white';
        this.pastOtherSelected = 'white-primary';
    }

    showPastList(): void {
        this.showCurrent = false;
        this.setPlanNumbersText();
        this.currentOtherSelected = 'white-primary';
        this.pastOtherSelected = 'primary-white';
    }
}
