import { Injectable, NgZone } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { DomainInfoService } from '../../services/domainInfo/domaininfo.service';

@Injectable()
export class ProfileGuard implements CanActivate {
  constructor(private router: Router, private domainService: DomainInfoService, private ngZone: NgZone) {}

  canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    return this.checkEnabledProfile(route);
  }

  async checkEnabledProfile(route: ActivatedRouteSnapshot): Promise<boolean> {
    const domainInfo = await this.domainService.getDomainInfo();

    if (!domainInfo.enableProfileTab) {
      // Navigate to the home page
      this.ngZone.run(() => this.router.navigate(['/home']));
      return false;
    }

    if (route.url[0].path === 'profile' && domainInfo.useConsolidatedCommunication) {
      this.ngZone.run(() => this.router.navigate(['newprofile']));
      return false;
    }

    if (route.url[0].path === 'newprofile' && !domainInfo.useConsolidatedCommunication) {
      this.ngZone.run(() => this.router.navigate(['profile']));
      return false;
    }

    return true;
  }
}
