/* tslint:disable:triple-equals */
import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IDomainInfo } from '../../models/domaininfo';
import { IRecurringPaymentPlan } from '../../models/recurringpaymentplan';
import { ComponentService } from '../../services/component/component.service';
import { ConsumerService } from '../../services/consumer/consumer.service';

@Component({
    selector: 'recurring',
    template: require('./recurring.component.html'),
    styles: [require('./recurring.component.css')]
})
export class RecurringComponent implements OnInit, OnDestroy {

    recurringPlans: IRecurringPaymentPlan[];
    recurringTitle: string;
    recurringPlanTitle: string;
    recurringSubheader: string;
    recurringPlanSubheader: string;
    recurringBody: string;
    recurringEnrollmentLink: string;
    recurringImage: string;
    buttonText: string;
    recurringContentItems: string[] = [];
    manageRecurringText: string;
    buttonLink: string;
    domainInfo: IDomainInfo;

    private ngUnsubscribe: Subject<any> = new Subject();

    plansLoaded = false;

    constructor(
        private componentService: ComponentService,
        private consumerService: ConsumerService,
        private parentRouter: Router,
        private ngZone: NgZone
    ) { }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit() {
        this.componentService.scrollPageToTop();
        this.componentService.contentService.content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((content: any) => {
                if (content != null) {
                    this.recurringTitle = this.componentService.contentService.tryGetContentItem(content, 'recurring', 'pageText', 'recurringTitle').text;
                    this.recurringPlanTitle = this.componentService.contentService.tryGetContentItem(content, 'recurring', 'pageText', 'recurringAgreementTitle').text;
                    this.recurringSubheader = this.componentService.contentService.tryGetContentItem(content, 'recurring', 'pageText', 'recurringSubheader').text;
                    this.recurringPlanSubheader = this.componentService.contentService.tryGetContentItem(content, 'recurring', 'pageText', 'recurringAgreementSubheader').text;
                    this.recurringBody = this.componentService.contentService.tryGetContentItem(content, 'recurring', 'pageText', 'recurringBodyText').text;
                    this.recurringImage = this.componentService.contentService.tryGetContentItem(content, 'recurring', 'image', 'recurringBackgroundImage').text;
                    this.manageRecurringText = this.componentService.contentService.tryGetContentItem(content, 'recurring', 'pageText', 'recurringManageRecurringText').text;
                    this.buttonText = this.componentService.contentService.tryGetContentItem(content, 'recurring', 'pageText', 'recurringBackButtonText').text;
                }
            });
        this.componentService.domainService.domainInfo$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(domainInfo => {
                this.domainInfo = domainInfo;
        });
        this.retrieveRecurringPaymentPlans();
    }

    retrieveRecurringPaymentPlans(): void {
        this.plansLoaded = false;
        this.consumerService.getRecurringPaymentPlans()
            .then(recurringPlans => {
                this.recurringPlans = recurringPlans;
                this.plansLoaded = true;
            });
    }

    showBackgroundImage(): boolean {
        return !this.isLoading() && (!this.recurringPlans || this.recurringPlans.length == 0);
    }

    isLoading(): boolean {
        this.buttonLink = '/home';
        return !(this.plansLoaded);
    }

    private backLink() {
        this.ngZone.run(() => this.parentRouter.navigateByUrl(this.buttonLink)).then();
    }
}
