import { Address, TEST_ADDRESS } from './address';

export class PaymentMethod {
    accountHolderName: string;
    expirationDate: string;
    postalCode: string;
    primary: boolean; // default payment method
    tokenGUID: string; // unique identifier
    paymentMethodType: PaymentMethodType; // ACH, Credit, Unknown
    cardType: CreditCardIssuerType; // Credit Card issuer (Visa, Mastercard, etc.)
    description: string; // description of payment method (e.g. Bank Account XXXX6789) - need this to be editable
    account: string; // name of payment method
    paymentMethodLast4: string; // XXXX6789
    merchantGUID: string;
    paymentMethodInUse: boolean;
    paymentProcessor: string;
    address: string;
    city: string;
    state: string;
    country: string;
    paymentMethodId: number = null;

    constructor(options?: {
        tokenGUID: string;
        primary: boolean;
        paymentMethodType: PaymentMethodType;
        cardType: CreditCardIssuerType;
        account: string;
        description: string;
        accountHolderName: string;
        expirationDate: string;
        merchantGUID: string;
        paymentMethodLast4: string;
        postalCode: string;
        paymentProcessor: string;
        address: string;
        city: string;
        state: string;
        country: string;
        paymentMethodInUse: boolean;
    }) {
        if (options) {
            this.tokenGUID = options.tokenGUID;
            this.primary = options.primary;
            this.paymentMethodType = options.paymentMethodType;
            this.cardType = options.cardType;
            this.account = options.account;
            this.description = options.description;
            this.accountHolderName = options.accountHolderName;
            this.expirationDate = options.expirationDate;
            this.postalCode = options.postalCode;
            this.merchantGUID = options.merchantGUID;
            this.paymentMethodLast4 = options.paymentMethodLast4;
            this.paymentMethodInUse = options.paymentMethodInUse;
        }
    }
}

export enum PaymentMethodType {
    credit = 1,
    ach = 2,
    unknown = 0
}

export enum CreditCardIssuerType {
    visa = 1,
    discover = 2,
    mastercard = 3,
    americanexpress = 4,
    dinersclub = 5,
    jcb = 6,
    unknown = 0
}

export const OtherPaymentMethod = [
    new PaymentMethod({
        tokenGUID: 'newPaymentMethod',
        primary: false,
        paymentMethodType: PaymentMethodType.unknown,
        cardType: CreditCardIssuerType.unknown,
        account: null,
        description: 'Enter Other Payment Method',
        accountHolderName: '',
        expirationDate: '',
        merchantGUID: '',
        paymentMethodLast4: '',
        paymentMethodInUse: false,
        postalCode: '',
        paymentProcessor: 'Card Connect',
        address: '123 Test St.',
        city: '',
        state: '',
        country: '',
    })
];

const dummyDate: Date = new Date(2017, 1, 1, 1, 1, 1, 1);

export const TEST_PAYMENT_METHOD: PaymentMethod = new PaymentMethod(
    {
        account: null,
        accountHolderName: 'First Last',
        cardType: null,
        description: 'description text',
        expirationDate: dummyDate.toISOString(),
        merchantGUID: '',
        paymentMethodLast4: '1234',
        paymentMethodType: PaymentMethodType.unknown,
        postalCode: '55434',
        primary: true,
        tokenGUID: 'tokentoken',
        paymentMethodInUse: true,
        paymentProcessor: 'Card Connect',
        address: '123 Test St.',
        city: '',
        state: '',
        country: ''
    }
);

export const TEST_PAYMENTMETHODS: PaymentMethod[] = [
    new PaymentMethod({
        tokenGUID: '111',
        primary: true,
        paymentMethodType: PaymentMethodType.credit,
        cardType: CreditCardIssuerType.visa,
        account: '123',
        description: 'my credit card',
        accountHolderName: 'firstname lastname',
        expirationDate: '01/22',
        postalCode: '12345',
        paymentProcessor: 'Card Connect',
        address: '123 Test St.',
        city: '',
        state: '',
        country: '',
        merchantGUID: '',
        paymentMethodInUse: false,
        paymentMethodLast4: '1111'
    }),
    new PaymentMethod({
        tokenGUID: '222',
        primary: false,
        paymentMethodType: PaymentMethodType.credit,
        cardType: CreditCardIssuerType.visa,
        account: '123',
        description: 'my credit card',
        accountHolderName: 'firstname lastname',
        expirationDate: '01/22',
        postalCode: '12345',
        paymentProcessor: 'Card Connect',
        address: '123 Test St.',
        city: '',
        state: '',
        country: '',
        merchantGUID: '',
        paymentMethodInUse: false,
        paymentMethodLast4: '1111'
    }),
    new PaymentMethod({
        tokenGUID: 'newPaymentMethodTokenGUID',
        primary: false,
        paymentMethodType: PaymentMethodType.credit,
        cardType: CreditCardIssuerType.visa,
        account: '123',
        description: 'my credit card',
        accountHolderName: 'firstname lastname',
        expirationDate: '01/22',
        postalCode: '12345',
        paymentProcessor: 'Card Connect',
        address: '123 Test St.',
        city: '',
        state: '',
        country: '',
        merchantGUID: '',
        paymentMethodInUse: false,
        paymentMethodLast4: '1111'
    })
];
