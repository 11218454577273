import { Component, OnInit } from '@angular/core';

import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BaseActivity } from '../../components/Base/BaseActivity/baseactivity';
import { Activity } from '../../models/activity';
import { ComponentService } from '../../services/component/component.service';
import { ConsumerService } from '../../services/consumer/consumer.service';

interface IActivitiesOnly {
    [key: string]: Activity[];
}

@Component({
    selector: 'latest-activity',
    template: require('./latestactivity.component.html'),
    styles: [require('./latestactivity.component.css')]
})
export class LatestActivityComponent extends BaseActivity implements OnInit {
    private ngUnsubscribe: Subject<any> = new Subject();

    constructor(
        private componentService: ComponentService,
        private consumerService: ConsumerService,
        private formBuilder: FormBuilder
    ) {
        super();
    }

    readonly ACTIVITY_LIMIT: number = 50;

    adOneTitle = '';
    adOneText = '';
    adOneImageUrl = '';
    adOneLinkText = '';
    adOneLinkUrl = '';
    noActivityFoundMessage = '';

    activitiesByYear: IActivitiesOnly = {};
    yearsThatHaveActivities: number[] = [];

    strStartDate = 'startDate';
    strEndDate = 'endDate';

    dateRangeForm: FormGroup;
    dateRangeError: boolean;
    invalidDateErrorText: string;

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit() {
        this.dateRangeForm = this.formBuilder.group({
            startDate: new FormControl(moment(new Date()).subtract(6, 'months').toDate(), Validators.required),
            endDate: new FormControl(new Date(), Validators.required)
        });

        this.componentService.scrollPageToTop();
        this.componentService.contentService.content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((content: any) => {
                if (content != null) {
                    this.adOneTitle = this.componentService.contentService.tryGetContentItem(
                        content, 'ad', 'activity', 'adOneTitle').text;

                    this.adOneText = this.componentService.contentService.tryGetContentItem(
                        content, 'ad', 'activity', 'adOneText').text;

                    this.adOneImageUrl = this.componentService.contentService.tryGetContentItem(
                        content, 'ad', 'image', 'adOneImageUrl').text;

                    this.adOneLinkText = this.componentService.contentService.tryGetContentItem(
                        content, 'ad', 'activity', 'adOneLinkText').text;

                    this.adOneLinkUrl = this.componentService.contentService.tryGetContentItem(
                        content, 'ad', 'activity', 'adOneLinkUrl').text;

                    this.noActivityFoundMessage = this.componentService.contentService.tryGetContentItem(
                        content, 'error', 'activity', 'noActivitiesFound').text;

                    this.invalidDateErrorText = this.componentService.contentService.tryGetContentItem(
                        content, 'global', 'error', 'genericInvalidDateSelectedText').text;
                }
            });

        this.updateActivityListing();
    }

    dateChange(event: any, isStartDate: boolean) {
        if (this.dateRangeValid(event.target.value, isStartDate)) {
            this.dateRangeError = false;
        } else {
            this.dateRangeError = true;
        }

        this.updateActivityListing();
    }

    dateRangeValid(dateFromUI: any, isStartDate: boolean): boolean {
        // DatePicker is going to return a null value if the User enters an invalid date.
        if (dateFromUI) {
            let startDate = new Date();
            let endDate = new Date();

            if (isStartDate) {
                startDate = new Date(dateFromUI);
                endDate = new Date(this.dateRangeForm.controls[this.strEndDate].value);
            } else {
                startDate = new Date(this.dateRangeForm.controls[this.strStartDate].value);
                endDate = new Date(dateFromUI);
            }

            if (startDate.getTime() > endDate.getTime()) {
                return false;
            }

            return true;
        }

        return false;
    }

    updateActivityListing(): void {
        if (this.dateRangeError) {
            this.yearsThatHaveActivities = [];
        } else {
            const startDate = this.dateRangeForm.controls[this.strStartDate].value;
            const endDate = this.dateRangeForm.controls[this.strEndDate].value;

            this.loading = true;
            this.consumerService.getConsumerActivity(this.ACTIVITY_LIMIT, startDate, endDate).then(
                result => {
                    result.forEach(al => this.setIcon(al));

                    this.activitiesByYear = this.groupActivitiesByYear(result);
                    this.setYearsThatHaveActivities();
                    this.doneLoading();
                });
        }
    }

    // Set the years that we have activities for. In the activitesByYear object we have keys
    // of Number (the year) with a value of the array of Activies for that year.
    // This method is to return the years that we have in the correct order, ['2017', '2016', '2015']
    private setYearsThatHaveActivities(): void {
        this.yearsThatHaveActivities = Object.keys(this.activitiesByYear)
            .map((a) => Number.parseInt(a, 10))
            .sort((a: number, b: number) => {
                return b - a;
            });
    }

    private groupActivitiesByYear(items: Activity[]): IActivitiesOnly {
        return items.reduce(function(byYear: IActivitiesOnly, item: Activity) {
            const itemYear = new Date(item.activityDate).getFullYear();
            (byYear[itemYear] = byYear[itemYear] || []).push(item);
            return byYear;
        }, {});
    }
}
