import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { AppConfig } from '../../app.config';
import { EpicSsoAuth } from '../../models/epic-sso-auth';
import { EpicSsoTokenResponse } from '../../models/epic-sso-token-response';
import { GenericResponse, Response } from '../../models/genericresponse';
import { jsonRequestOptions } from '../../util/HttpClientUtility';
import { LoggingService } from '../logging/logging.service';
import { StorageService } from '../storage/storage.service';

@Injectable()
export class EpicSsoService {
    private domainUrl = this.config.getConfig('domainInfo');
    private ssoPath = this.config.getConfig('epicSsoPath');
    private epicSsoGetAccountsPath = this.config.getConfig('epicSsoGetAccountsPath');
    private ssoSelectAccountPath = this.config.getConfig('epicSsoSelectAccountPath');

    constructor(private httpClient: HttpClient,
                private config: AppConfig,
                private loggingService: LoggingService,
                private storageService: StorageService) {
    }

    async getEpicConnectionDetails(launchToken: string, redirectUri: string): Promise<any> {
        const body: any = {
            // we don't care about this value;
            // we just need to pass something since it's required at the controller level:
            // [RoutePrefix("api/{accessKey}/epic/v{version:apiVersion}")]
            UrlAccessKey: 'test',

            // tslint:disable-next-line:object-literal-shorthand
            launchToken: launchToken,

            path: 'epic/v2/apporchardconnection',

            // tslint:disable-next-line:object-literal-shorthand
            redirectUri: redirectUri,

            requireCredentials: false,

            bypass: true
        };

        return this.httpClient.post(this.domainUrl, {body}, jsonRequestOptions())
            .toPromise()
            .then((response: Response<EpicSsoAuth>) => {
                this.storageService.store('epic-sso-model', response.body.data);
                return response.body.data;
            })
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    async getTokenWithAuthCode(authCode: string, redirectUri: string): Promise<any> {
        const authModel: EpicSsoAuth = this.storageService.retrieve('epic-sso-model');
        const params = new URLSearchParams();
        params.set('grant_type', 'authorization_code');
        params.set('code', authCode);
        params.set('redirect_uri', decodeURIComponent(redirectUri));
        params.set('client_id', authModel.clientID);

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

        return this.httpClient.post(
            authModel.tokenUrl,
            params.toString(),
            {headers, observe: 'response'})
            .pipe(
                map((response: HttpResponse<EpicSsoTokenResponse>) => {
                    return response.body;
                })
            ).toPromise();
    }

    async sso(epicSsoAuditGuid: string): Promise<any> {
        const domain = this.storageService.getDomain();
        const path = `?EpicSsoAuditGuid=${epicSsoAuditGuid}&domain=${domain}`;

        return this.httpClient.get(this.ssoPath + path + '&requireCredentials=false',
            jsonRequestOptions())
            .toPromise()
            .then((response: Response<GenericResponse>) => response.body.data)
            .catch((response: GenericResponse) => response);
    }

    async ssoGetAccounts(epicSsoAuditGuid: string): Promise<any> {
        const domain = this.storageService.getDomain();
        const path = `?EpicSsoAuditGuid=${epicSsoAuditGuid}&domain=${domain}`;

        return this.httpClient.get(this.epicSsoGetAccountsPath + path + '&requireCredentials=false',
            jsonRequestOptions())
            .toPromise()
            .then((response: Response<GenericResponse>) => response.body.data)
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    async ssoSelectAccount(epicSsoAuditGuid: string, selectedAccount: string): Promise<any> {
        const path = `?EpicSsoAuditGuid=${epicSsoAuditGuid}&selectedAccount=${selectedAccount}`;

        return this.httpClient.get(this.ssoSelectAccountPath + path + '&requireCredentials=false',
            jsonRequestOptions())
            .toPromise()
            .then((response: Response<GenericResponse>) => response.body.data)
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }
}
