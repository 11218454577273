import { Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IConsumer } from '../../../models/consumer';
import { PasswordChangeResult } from '../../../models/passwordchangeresult';
import { ComponentService } from '../../../services/component/component.service';
import { ConsumerService } from '../../../services/consumer/consumer.service';
import { LoggingLevel } from '../../../services/logging/logging.service';

@Component({
    selector: 'password-reset',
    template: require('./passwordreset.component.html'),
    styles: [require('./passwordreset.component.css')]
})
export class PasswordResetComponent implements OnInit, OnDestroy {

    @Input() cssOverride = 'headline text-center';
    @Input() cssPadding = 'reset-password';
    @Input() cssBootstrap = 'col-xs-8 col-xs-offset-2';
    @Input() headline: string;
    @Input() buttonText = '';
    @Input() oldPasswordRequired: boolean;
    @Input() redirectOnSuccess = true;
    @Input() parentSubject: Subject<any>;

    @Output() passwordValidChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    consumer: IConsumer = null;
    token: string = null;
    loading = false;
    showReset = true;
    passwordResetId: string;
    passwordResetError = false;
    passwordInvalidError = false;
    oldPasswordText: string;
    newPasswordText: string;
    confirmPasswordText: string;
    passwordResetInfoText: string;
    passwordResetErrorText: string;
    passwordResetInvalidPasswordText: string;
    passwordResetSamePasswordErrorText: string;
    apiErrorText: string;
    landingPagePasswordComplexityError: string;

    oldPassword: string;
    newPassword: string;
    confirmPassword: string;
    passwordStrengthText = '';

    private ngUnsubscribe: Subject<any> = new Subject();

    constructor(
        private componentService: ComponentService,
        private parentRouter: Router,
        private activatedRoute: ActivatedRoute,
        private consumerService: ConsumerService,
        private ngZone: NgZone
    ) { }

    ngOnInit() {
        this.activatedRoute.queryParams
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((params: Params) => {
                this.passwordResetId = params.passwordReset;
            });

        this.componentService.contentService.content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((content: any) => {
                this.oldPasswordText = this.componentService.contentService.tryGetContentItem(content, 'landingPage', 'pageText', 'landingPageOldPasswordText').text;
                this.newPasswordText = this.componentService.contentService.tryGetContentItem(content, 'landingPage', 'pageText', 'landingPageNewPasswordText').text;
                this.confirmPasswordText = this.componentService.contentService.tryGetContentItem(content, 'landingPage', 'pageText', 'landingPageConfirmNewPasswordText').text;
                this.passwordResetInfoText = this.componentService.contentService.tryGetContentItem(content, 'landingPage', 'pageText', 'landingPagePasswordResetInfo').text;
                this.passwordStrengthText = this.componentService.contentService.tryGetContentItem(content, 'landingPage', 'pageText', 'landingPagePasswordStrengthText').text;
                this.passwordResetInvalidPasswordText = this.componentService.contentService.tryGetContentItem(content, 'landingPage', 'error', 'landingPageInvalidPasswordError').text;
                this.passwordResetSamePasswordErrorText = this.componentService.contentService.tryGetContentItem(content, 'landingPage', 'error', 'landingPagePasswordResetError').text;
                this.apiErrorText = this.componentService.contentService.tryGetContentItem(content, 'global', 'error', 'apiError').text;
                this.landingPagePasswordComplexityError = this.componentService.contentService.tryGetContentItem(content, 'landingPage', 'error', 'landingPagePasswordComplexityError').text;
            });

        if (!this.parentSubject) {
            this.componentService.loggingService.log('please pass in a Subject for the PasswordResetComponent', LoggingLevel.warning);
        } else {
            this.parentSubject
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe(event => {
                    this.submitPasswordReset();
                });
        }
        this.token = this.componentService.storageService.retrieve('token');
        this.componentService.storageService.clear('token');
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    hasPasswordResetError() {
        return this.passwordResetError;
    }

    hasInvalidPasswordError() {
        return this.passwordInvalidError;
    }

    clearErrors() {
        this.passwordResetError = false;
        this.passwordInvalidError = false;
    }

    resetFields() {
        this.newPassword = null;
        this.confirmPassword = null;
        this.oldPassword = null;
    }

    redirect() {
        this.ngZone.run(() => this.parentRouter.navigateByUrl('/home')).then();
    }

    redirectToDefault() {
        if (this.parentRouter.url.includes('/default')) {
            // this isn't the ideal way, but we need to clear out query string params
            window.location.href = '/default';
        } else {
            this.ngZone.run(() => this.parentRouter.navigateByUrl('/default')).then();
        }
    }

    public async submitPasswordReset(): Promise<boolean> {
        this.startLoading();

        this.passwordResetError = false;
        this.componentService.storageService.store('token', this.token);
        const passwordValidationError =
            await this.componentService.validationService.validatePassword(
                this.newPassword,
                this.confirmPassword,
                this.oldPassword
            );

        if (!!passwordValidationError) { // this.passwordLengthError || this.passwordMatchError
            this.passwordResetError = true;
            this.passwordResetErrorText = passwordValidationError;
            this.doneLoading();
            this.passwordValidChange.emit(this.passwordResetError);

            return false;
        }

        if (!!this.passwordResetId) {
            this.consumerService.submitForgotPasswordReset(this.passwordResetId, this.newPassword)
                .then(response => {
                    if (response.success) {
                        this.passwordResetError = false;
                        this.componentService.storageService.clearSession();
                        this.redirectToDefault();
                    } else {
                        if (PasswordChangeResult[response.messages[0]] ===  PasswordChangeResult.Duplicate) {
                            // New password is one of last 10 previously used passwords.
                            this.passwordResetError = true;
                            this.passwordResetErrorText = this.passwordResetSamePasswordErrorText;
                        } else if (PasswordChangeResult[response.messages[0]] === PasswordChangeResult.Complexity) {
                            // New password failed complexity requirements.
                            this.passwordResetError = true;
                            this.passwordResetErrorText = this.landingPagePasswordComplexityError;
                        } else {
                            // Old Password was not valid.
                            this.passwordInvalidError = true;
                        }

                        this.doneLoading();
                        this.passwordValidChange.emit(this.passwordResetError);

                        return false;
                    }
                })
                .catch(error => {
                    this.passwordResetError = true;
                    this.doneLoading();
                    this.passwordValidChange.emit(this.passwordResetError || this.passwordInvalidError);
                });
        } else {
            // Get consumer, then update consumer with new password
            this.consumerService.getConsumer()
                .then(consumer => {
                    this.consumerService.updateConsumerAccountPassword(consumer.accountID, this.newPassword, this.oldPassword)
                        .then(response => {
                            if (response.success) {
                                this.passwordResetError = false;
                                this.resetFields();

                                if (this.redirectOnSuccess) {
                                    // Get consumer again so we clear out the forcepasswordreset flag.
                                    this.componentService.storageService.clear('consumeraccount');
                                    this.consumerService.getConsumerAccount().then((c) => {
                                        this.redirect();
                                    });
                                }
                            } else {
                                if (PasswordChangeResult[response.messages[0]] === PasswordChangeResult.Duplicate) {
                                    // New password is one of last 10 previously used passwords.
                                    this.passwordResetError = true;
                                    this.passwordResetErrorText = this.passwordResetSamePasswordErrorText;
                                } else if (PasswordChangeResult[response.messages[0]] === PasswordChangeResult.Complexity) {
                                    // New password failed complexity requirements.
                                    this.passwordResetError = true;
                                    this.passwordResetErrorText = this.landingPagePasswordComplexityError;
                                } else {
                                    // Old Password was not valid.
                                    this.passwordInvalidError = true;
                                }

                                this.doneLoading();
                            }

                            this.passwordValidChange.emit(this.passwordResetError || this.passwordInvalidError);
                        })
                        .catch(error => {
                            this.passwordResetError = true;
                            this.passwordResetErrorText = this.apiErrorText;
                            this.doneLoading();
                            this.passwordValidChange.emit(this.passwordResetError || this.passwordInvalidError);
                        });
                });
        }
    }

    private startLoading() {
        this.loading = true;
    }

    private doneLoading() {
        this.loading = false;
    }

    private removeQueryParams() {

        this.parentRouter.navigate(
            [],
            {
                relativeTo: this.activatedRoute,
                queryParams: {},
                queryParamsHandling: 'merge'
            }
        );
    }

    isLoading(): boolean {
        return this.loading;
    }
}
