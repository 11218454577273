import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AgreementStatus, IAutopayAgreement } from '../../../models/autopayagreement';
import { IAutopayPayment } from '../../../models/autopaypayment';
import { CancelAutoPayPayment } from '../../../models/cancelautopaypayment';
import { IDomainInfo } from '../../../models/domaininfo';
import { EmailAddressType } from '../../../models/emailaddresstype';
import { CreditCardIssuerType, PaymentMethod } from '../../../models/paymentmethod';
import { PaymentMethodAssignmentSource } from '../../../models/paymentmethodassignmentsource';
import { ComponentService } from '../../../services/component/component.service';
import { ConsumerService } from '../../../services/consumer/consumer.service';
import { ConfirmationModalComponent } from '../../Controls/ConfirmationModal/confirmationmodal.component';
import { ConsumerContactDetailsModalComponent } from '../../Controls/ConsumerContactDetailsModal/consumercontactdetailsmodal.component';

@Component({
    selector: 'autopay-agreement',
    template: require('./autopayagreement.component.html'),
    styles: [require('./autopayagreement.component.css')]
})
export class AutopayAgreementComponent implements OnInit, OnDestroy {

    @Input() set agreementValue(agreement: IAutopayAgreement) {
        this.agreement = agreement;
        this.replaceAgreementText(agreement);
        this.showActive = this.agreement.status === AgreementStatus.active;
        this.oldEmailAddress = agreement.emailAddress;
        this.oldPaymentMethod = agreement.storedPaymentMethod;
    }

    @Output() public optedOutAgreement: EventEmitter<IAutopayAgreement> = new EventEmitter<IAutopayAgreement>();
    @Output() public cancelledAutoPayPayment: EventEmitter<IAutopayPayment> = new EventEmitter<IAutopayPayment>();

    @ViewChild('optoutconfirmation', { static: false }) optOutConfirmationModal: ConfirmationModalComponent;
    @ViewChild('cancelpaymentconfirmation', { static: false }) cancelPaymentConfirmationModal: ConfirmationModalComponent;
    @ViewChild('contactDetailsModal', { static: false }) contactDetailsModal: ConsumerContactDetailsModalComponent;

    detailArrowRotateDegrees = 0;
    detailsExpanded: boolean;
    loading = false;
    paymentMethods: PaymentMethod[] = [];
    emailAddressChangesExist = false;
    paymentMethodChangesExist = false;
    oldEmailAddress: string;
    oldPaymentMethod: PaymentMethod;
    agreement: IAutopayAgreement;
    newPaymentDate: number;
    newMaxPaymentAmount: number;
    updateDateModeActive = false;
    updateMaxPaymentAmountModeActive = false;
    paymentDateFailedValidation = false;
    maxPaymentAmountFailedValidation = false;
    maxCCPaymentAmountFailedValidation = false;
    showAutoPayPendingPaymentAmountChangeMessage = false;

    // Content items, messaging
    currentAgreementText: string;
    cancelledAgreementText: string;
    cancelledAgreementNoDateText: string;
    confirmationEmailLabel: string;
    locationText: string;
    paymentDateLabel: string;
    paymentDateErrorMessage: string;
    paymentDatePendingPaymentMessage: string;
    profilePasswordUpdateText: string;
    profilePasswordUpdateSubmitText: string;
    profilePasswordUpdateCancelText: string;
    pendingPaymentLabel: string;
    recentPaymentLabel: string;
    pendingPaymentDateText: string;
    recentPaymentDateText: string;
    optOutButtonText: string;
    statusActiveText: string;
    statusCancelledText: string;
    optOutAutopayModalContent: string;
    optOutModalTitle: string;
    optOutConfirmationModalDisplay: string;
    optOutErrorText: string;
    optOutErrorExists = false;
    updateErrorExists = false;
    emailErrorExists = false;
    cancelPaymentModalContent: string;
    cancelPaymentModalTitle: string;
    cancelPaymentErrorText: string;
    cancelPaymentErrorExists = false;
    cancelPaymentButtonText: string;
    cancelPaymentModalBackButtonText: string;
    paymentMethodText: string;
    emailErrorText: string;
    updateErrorText: string;
    autoPayMaxPaymentAmountLabel: string;
    autoPayInvalidAmount: string;
    autoPayPendingPaymentAmountChangeMessage: string;
    paymentMaxCreditCardAmountWarningText: string;

    showActive = true;
    domainInfo: IDomainInfo;

    private ngUnsubscribe: Subject<any> = new Subject();

    constructor(
        private componentService: ComponentService,
        private datePipe: DatePipe,
        private currencyPipe: CurrencyPipe,
        private consumerService: ConsumerService
    ) { }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    async ngOnInit() {
        this.componentService.contentService.content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((content: any) => {
                this.currentAgreementText = this.componentService.contentService.tryGetContentItem(content, 'autopay', 'pageText', 'autopayAgreementText').text;
                this.cancelledAgreementText = this.componentService.contentService.tryGetContentItem(content, 'autopay', 'pageText', 'autopayCancelledAgreementText').text;
                this.cancelledAgreementNoDateText = this.componentService.contentService.tryGetContentItem(content, 'autopay', 'pageText', 'autopayCancelledAgreementNoDateText').text;
                this.confirmationEmailLabel = this.componentService.contentService.tryGetContentItem(content, 'autopay', 'pageText', 'autopayConfirmationEmailLabel').text;
                this.locationText = this.componentService.contentService.tryGetContentItem(content, 'autopay', 'pageText', 'autopayLocationText').text;
                this.paymentDateLabel = this.componentService.contentService.tryGetContentItem(content, 'autopay', 'label', 'autopayDayofMonthLabel').text;
                this.paymentDateErrorMessage = this.componentService.contentService.tryGetContentItem(content, 'autopay', 'error', 'autoPayInvalidDayofMonth').text;
                this.paymentDatePendingPaymentMessage = this.componentService.contentService.tryGetContentItem(content, 'autopay', 'pageText', 'autoPayPendingPaymentDateChangeMessage').text;
                this.profilePasswordUpdateText = this.componentService.contentService.tryGetContentItem(content, 'profile', 'pageText', 'profilePasswordUpdateText').text;

                this.profilePasswordUpdateSubmitText =
                    this.componentService.contentService.tryGetContentItem(
                        content,
                        'profile',
                        'pageText',
                        'profilePasswordUpdateSubmitText').text;

                this.profilePasswordUpdateCancelText =
                    this.componentService.contentService.tryGetContentItem(
                        content,
                        'profile',
                        'pageText',
                        'profilePasswordUpdateCancelText').text;

                this.pendingPaymentLabel = this.componentService.contentService.tryGetContentItem(content, 'autopay', 'pageText', 'autopayPendingPaymentLabel').text;
                this.recentPaymentLabel = this.componentService.contentService.tryGetContentItem(content, 'autopay', 'pageText', 'autopayRecentPaymentLabel').text;
                this.optOutButtonText = this.componentService.contentService.tryGetContentItem(content, 'autopay', 'pageText', 'autopayOptOutButtonText').text;
                this.statusActiveText = this.componentService.contentService.tryGetContentItem(content, 'autopay', 'pageText', 'autopayStatusActiveText').text;
                this.statusCancelledText = this.componentService.contentService.tryGetContentItem(content, 'autopay', 'pageText', 'autopayStatusCancelledText').text;
                this.recentPaymentDateText = this.componentService.contentService.tryGetContentItem(content, 'autopay', 'pageText', 'autopayRecentPaymentText').text;
                this.pendingPaymentDateText = this.componentService.contentService.tryGetContentItem(content, 'autopay', 'pageText', 'autopayPendingPaymentText').text;
                this.optOutAutopayModalContent = this.componentService.contentService.tryGetContentItem(content, 'autopay', 'modal', 'autopayModalOptOutContent').text;
                this.optOutModalTitle = this.componentService.contentService.tryGetContentItem(content, 'autopay', 'modal', 'autopayModalOptOutTitle').text;
                this.optOutErrorText = this.componentService.contentService.tryGetContentItem(content, 'autopay', 'error', 'autopayOptOutError').text;
                this.cancelPaymentModalContent = this.componentService.contentService.tryGetContentItem(content, 'autopay', 'modal', 'autopayModalCancelPaymentContent').text;
                this.cancelPaymentModalTitle = this.componentService.contentService.tryGetContentItem(content, 'autopay', 'modal', 'autopayModalCancelPaymentTitle').text;
                this.cancelPaymentErrorText = this.componentService.contentService.tryGetContentItem(content, 'autopay', 'error', 'autopayCancelPaymentError').text;
                this.cancelPaymentButtonText = this.componentService.contentService.tryGetContentItem(content, 'autopay', 'pageText', 'autopayCancelPaymentButtonText').text;
                this.cancelPaymentModalBackButtonText = this.componentService.contentService.tryGetContentItem(content, 'autopay', 'modal', 'autopayModalCancelPaymentBackButtonText').text;
                this.paymentMethodText = this.componentService.contentService.tryGetContentItem(content, 'autopay', 'pageText', 'autopayPaymentMethodText').text;
                this.emailErrorText = this.componentService.contentService.tryGetContentItem(content, 'autopay', 'error', 'autopayEmailErrorText').text;
                this.updateErrorText = this.componentService.contentService.tryGetContentItem(content, 'autopay', 'error', 'autopayUpdateErrorText').text;
                this.autoPayMaxPaymentAmountLabel = this.componentService.contentService.tryGetContentItem(content, 'autopay', 'label', 'autoPayMaxPaymentAmountLabel').text;
                this.autoPayInvalidAmount = this.componentService.contentService.tryGetContentItem(content, 'autopay', 'error', 'autoPayInvalidAmount').text;
                this.autoPayPendingPaymentAmountChangeMessage = this.componentService.contentService.tryGetContentItem(content, 'autopay', 'pageText', 'autoPayPendingPaymentAmountChangeMessage').text;
                this.paymentMaxCreditCardAmountWarningText = this.componentService.contentService.tryGetContentItem(content, 'error', 'paymentSelection', 'paymentMaxCreditCardAmountWarningText').text;
            });

        this.replaceAgreementText(this.agreement);
        this.updatePaymentMethods(null);
        this.componentService.domainService.domainInfo$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(domainInfo => {
                this.domainInfo = domainInfo;
            });

        // Needed for paymentmethodmanager should the MaxPaymentAmount be greater than MaxCCPaymentAmount
        this.componentService.setMaxCCPaymentAmount(this.domainInfo.maxCCPaymentAmount);
        this.newMaxPaymentAmount = this.agreement.maxPaymentAmount;

        this.paymentMaxCreditCardAmountWarningText =
            this.paymentMaxCreditCardAmountWarningText.replace(
                '{0}',
                this.currencyPipe.transform(this.domainInfo.maxCCPaymentAmount, 'USD', 'symbol', '1.2-2')
            );

        await this.setAgreementEmail();
    }

    private updatePaymentMethods(walletToken: string) {
        this.consumerService.getWalletPaymentMethods().then(wallet => {
            if (!!wallet) {
                if (this.domainInfo && this.domainInfo.enableWallet) {
                    this.paymentMethods = wallet;
                } else {
                    this.paymentMethods = wallet.filter(x => x.tokenGUID !== 'newPaymentMethod');
                }
            }

            if (walletToken) {
                this.changePaymentMethod(walletToken);
            }
        });
    }

    /**
     * Triggered when the AutoPay Agreement is opted out (cancelled), bubbles the event up to parent component
     *
     * @public
     *
     * @memberof AutopayAgreementComponent
     */
    optOut(): void {
        this.loading = true;
        this.optOutErrorExists = false;
        this.agreement.status = AgreementStatus.cancelled;
        this.consumerService.updateAutoPayAgreement(this.agreement)
            .then(updated => {
                if (updated.success) {
                    this.optedOutAgreement.emit(this.agreement);
                } else {
                    this.optOutErrorExists = true;
                }
                this.doneLoading();
            }).catch(() => {
                this.optOutErrorExists = true;
                this.doneLoading();
            });
    }

    /**
     * Triggered when a pending AutoPay Payment is cancelled after they confirm via the modal.
     *
     * @public
     *
     * @memberof AutopayAgreementComponent
     */
    cancelPayment(paymentToCancel: IAutopayPayment): void {
        this.loading = true;
        this.cancelPaymentErrorExists = false;
        this.consumerService.getConsumerAccount().then(consumerAccount => {
            if (!!consumerAccount) {
                const cancelPaymentModel: CancelAutoPayPayment = {
                    amount: paymentToCancel.amount,
                    autoPayId: paymentToCancel.autopayID,
                    cancelDate: null,
                    customerAccountId: consumerAccount.customerAccountID,
                    emailAddress: this.agreement.emailAddress,
                    merchantProfileGUID: this.agreement.merchantProfileGUID,
                    paymentLocationDescription: this.agreement.paymentLocationDescription,
                    paymentMethodDescription: this.agreement.paymentMethodDescription
                };

                this.consumerService.cancelAutoPayPayment(cancelPaymentModel).then(cancelled => {
                    if (cancelled.success) {
                        this.cancelledAutoPayPayment.emit(paymentToCancel);
                    } else {
                        this.cancelPaymentErrorExists = true;
                    }
                    this.doneLoading();
                }).catch(() => {
                    this.cancelPaymentErrorExists = true;
                    this.doneLoading();
                });
            }
        });
    }

    onEmailUpdatedFromModal(emailAddress: string): void {
        this.agreement.emailAddress = emailAddress;
        this.emailInputUpdated();
    }

    private replaceAgreementText(agreement: IAutopayAgreement): void {
        if (this.currentAgreementText != null && this.agreement != null) {
            this.currentAgreementText = this.currentAgreementText.replace('!LOCATION!', agreement.paymentLocationDescription);
            this.currentAgreementText = this.currentAgreementText.replace('!CREATEDDATE!', this.datePipe.transform(agreement.createdDate, 'MM/d/y'));
            this.currentAgreementText = this.currentAgreementText.replace(
                '!MAXPAYMENTAMOUNT!', this.currencyPipe.transform(agreement.maxPaymentAmount, 'USD', 'symbol'));
        }

        if (this.cancelledAgreementText != null && this.agreement != null) {
            if (this.agreement.cancelDate != null) {
                this.cancelledAgreementText = this.cancelledAgreementText.replace(
                    '!CANCELLEDDATE!', this.datePipe.transform(agreement.cancelDate, 'MM/d/y'));
            } else {
                this.cancelledAgreementText = this.cancelledAgreementNoDateText;
            }
        }

        if (this.recentPaymentDateText != null && this.agreement.mostRecentPayment != null) {
            this.recentPaymentDateText = this.recentPaymentDateText.replace(
                '!PAYMENT!', this.currencyPipe.transform(this.agreement.mostRecentPayment.amount, 'USD', 'symbol'));
            this.recentPaymentDateText = this.recentPaymentDateText.replace(
                '!PAYMENTDATE!', this.datePipe.transform(this.agreement.mostRecentPayment.scheduledPaymentDate, 'MM/d/y'));
        }
    }

    changePaymentMethod(walletGUID: string): void {
        this.updateErrorExists = false;
        const newPaymentMethod = this.paymentMethods.filter(x => x.tokenGUID === walletGUID)[0];
        this.agreement.storedPaymentMethod = newPaymentMethod;

        // Transform cardType enum back to string and capitalize first letter
        if (newPaymentMethod.cardType != null) {
            this.agreement.cardType = this.capitalizeFirstLetter(CreditCardIssuerType[newPaymentMethod.cardType]);
        }
        // If toggling back to their original payment method, don't need to leave up save/cancel buttons
        this.paymentMethodChangesExist = (this.oldPaymentMethod.tokenGUID !== newPaymentMethod.tokenGUID);
    }

    private capitalizeFirstLetter(value: string): string {
        return value.charAt(0).toUpperCase() + value.slice(1);
    }

    private doneLoading(): void {
        this.loading = false;
    }

    emailInputUpdated(): void {
        this.emailErrorExists = false;
        this.updateErrorExists = false;
        this.emailAddressChangesExist = true;
        if (!this.componentService.emailRegex.test(this.agreement.emailAddress.toLocaleLowerCase())) {
            this.emailErrorExists = true;
        }
    }

    toggleAgreementDetails(): void {
        this.detailsExpanded = !this.detailsExpanded;
        this.detailArrowRotateDegrees = Math.abs(this.detailArrowRotateDegrees - 180);
        this.optOutErrorExists = false;
    }

    pendingAgreementChangesExist(): boolean {
        return this.paymentMethodChangesExist || this.emailAddressChangesExist;
    }

    openOptOutModal(): void {
        this.optOutErrorExists = false;
        this.optOutConfirmationModalDisplay = this.optOutAutopayModalContent.replace(
            '!AGREEMENTLOCATION!', this.agreement.paymentLocationDescription);
        this.optOutConfirmationModal.openModal();
    }

    /**
     * Update email address and/or payment method.
     * Used within the UCC = 0 flow.
     */
    saveAgreementChanges(): void {
        if (this.maxPaymentAmountExceedsMaxCCPaymentAmount(this.agreement.maxPaymentAmount)) {
            this.componentService.setAutoPayMaxPaymentAmountGreaterThanMaxCCPaymentAmount(true);

            return;
        }

        if (!this.emailErrorExists) {
            // Call updateAutopayAgreement service
            this.loading = true;
            this.updateErrorExists = false;

            if (this.paymentMethodChangesExist) {
                this.agreement.paymentMethodAssignmentSource = PaymentMethodAssignmentSource.SelfService;
            }

            this.consumerService.updateAutoPayAgreement(this.agreement)
                .then(updated => {
                    if (updated.success) {
                        this.oldEmailAddress = this.agreement.emailAddress;
                        this.oldPaymentMethod = this.agreement.storedPaymentMethod;
                        this.emailAddressChangesExist = false;
                        this.paymentMethodChangesExist = false;
                    } else {
                        this.updateErrorExists = true;
                    }
                    this.doneLoading();
                }).catch(err => {
                this.updateErrorExists = true;
                this.doneLoading();
            });
        }
    }

    /**
     * Updates the autopay agreement's AutoPayFixedDate property
     */
    updateFixedDate() {
        this.updateErrorExists = false;

        this.agreement.autoPayFixedDate = this.newPaymentDate;
        this.consumerService.updateAutoPayAgreement(this.agreement)
            .then(updated => {
                if (updated.success) {
                    this.updateDateModeActive = false;
                } else {
                    this.updateErrorExists = true;
                }
            }).catch(err => {
                this.updateErrorExists = true;
            });
    }

    /**
     * Updates the autopay agreement's MaxPaymentAmount property
     */
    updateMaxPaymentAmount() {
        this.validateMaxPaymentAmount(this.newMaxPaymentAmount);
        const oldMaxPaymentAmount = this.currencyPipe.transform(this.agreement.maxPaymentAmount, 'USD', 'symbol');

        if (!this.maxPaymentAmountFailedValidation &&
            !this.maxCCPaymentAmountFailedValidation
        ) {
            this.agreement.maxPaymentAmount = this.newMaxPaymentAmount;

            this.consumerService.updateAutoPayAgreement(this.agreement)
            .then(updated => {
                if (updated.success) {
                    this.updateMaxPaymentAmountModeActive = false;
                    this.showAutoPayPendingPaymentAmountChangeMessage = false;

                    this.currentAgreementText =
                        this.currentAgreementText.replace(
                            oldMaxPaymentAmount,
                            this.currencyPipe.transform(this.agreement.maxPaymentAmount, 'USD', 'symbol')
                        );
                } else {
                    this.updateErrorExists = true;
                }
            }).catch(err => {
                this.updateErrorExists = true;
            });
        }
    }

    cancelAgreementChanges(): void {
        this.emailAddressChangesExist = false;
        this.paymentMethodChangesExist = false;
        this.emailErrorExists = false;
        this.updateErrorExists = false;
        this.agreement.emailAddress = this.oldEmailAddress;
        this.agreement.storedPaymentMethod = this.oldPaymentMethod;
    }

    pendingPaymentExists(): boolean {
        return this.agreement.pendingPayments
            && this.agreement.pendingPayments.length > 0;
    }

    /* Autopay fixed payment date update mode */
    toggleUpdateDate(event: Event): void {
        this.updateDateModeActive = !this.updateDateModeActive;
        this.newPaymentDate = this.agreement.autoPayFixedDate;

        if (!this.updateDateModeActive) {
            this.paymentDateFailedValidation = false;
        }
    }

    /* Autopay max payment amount update mode */
    toggleMaxPaymentAmount(event: Event): void {
        this.updateMaxPaymentAmountModeActive = !this.updateMaxPaymentAmountModeActive;

        if (!this.updateMaxPaymentAmountModeActive) {
            this.maxPaymentAmountFailedValidation = false;
            this.maxCCPaymentAmountFailedValidation = false;
            this.showAutoPayPendingPaymentAmountChangeMessage = false;

            this.newMaxPaymentAmount = this.agreement.maxPaymentAmount;
        } else {
            if (this.pendingPaymentExists()) {
                this.showAutoPayPendingPaymentAmountChangeMessage = true;
            }
        }
    }

    validatePaymentDate(): void {
        this.paymentDateFailedValidation = !Number.isInteger(this.newPaymentDate) || this.newPaymentDate < 1 || this.newPaymentDate > 28;
    }

    validateMaxPaymentAmount(maxPaymentAmount?: number): void {
        this.maxCCPaymentAmountFailedValidation = false;

        if (this.maxPaymentAmountExceedsMaxCCPaymentAmount(maxPaymentAmount)) {
            this.maxCCPaymentAmountFailedValidation = true;

            return;
        } else {
            this.componentService.setAutoPayMaxPaymentAmountGreaterThanMaxCCPaymentAmount(false);
        }

        this.maxPaymentAmountFailedValidation =
            this.newMaxPaymentAmount === 0 ||
            !Number.isFinite(this.newMaxPaymentAmount);
    }

    private maxPaymentAmountExceedsMaxCCPaymentAmount(maxPaymentAmount: number): boolean {
        const paymentMethodType = this.agreement.storedPaymentMethod.paymentMethodType;
        const maxCCPaymentAmount = this.domainInfo.maxCCPaymentAmount;

        return paymentMethodType === 1 &&  maxPaymentAmount > maxCCPaymentAmount;
    }

    /**
     * This method gets called by the child component AutopayPendingPaymentComponent
     *
     * @private
     * @param {*} pendingPaymentToCancel
     * @memberof AutopayAgreementComponent
     */
    launchCancelModal(pendingPaymentToCancel: any): void {
        this.cancelPaymentErrorExists = false;
        if (this.agreement.pendingPayments.length > 0) {
            if (pendingPaymentToCancel != null) {
                this.cancelPaymentConfirmationModal.openModal(pendingPaymentToCancel);
            }
        }
    }

    private async setAgreementEmail() {
        if (this.domainInfo.useConsolidatedCommunication) {
            const emails = await this.consumerService.getGuarantorEmail(EmailAddressType.AP, this.agreement.autopayAgreementID);

            if (emails.length === 1) {
                this.agreement.emailAddress = emails[0].emailAddress;
            } else {
                // Is it possible for an existing autopay agreement to have no email? #SafetyFirst
                this.agreement.emailAddress = null;
            }
        }
    }
}
