export interface ICommunicationPreferenceChange {
    type: CommunicationPreference;
    newValue: boolean;
    customerAccountID?: string;
}

export interface ICommunicationRowChange {
    name: ChangeName;
    option: boolean;
}
export type ChangeName = 'paper' | 'email' | 'sms';

export class CommunicationPreference {
    static readonly AutoPayEmail = 'AutoPayEmail' as CommunicationPreferenceType;
    static readonly AutoPaySms = 'AutoPaySms' as CommunicationPreferenceType;
    static readonly RecurringEmail = 'RecurringEmail' as CommunicationPreferenceType;
    static readonly RecurringSms = 'RecurringSms' as CommunicationPreferenceType;
    static readonly PaymentPlanEmail = 'PaymentPlanEmail' as CommunicationPreferenceType;
    static readonly PaymentPlanSms = 'PaymentPlanSms' as CommunicationPreferenceType;
    static readonly StatementEmail = 'StatementEmail' as CommunicationPreferenceType;
    static readonly StatementSms = 'StatementSms' as CommunicationPreferenceType;
    static readonly StatementPaper = 'StatementPaper' as CommunicationPreferenceType;
}

export type CommunicationPreferenceType =
    | 'AutoPayEmail'
    | 'AutoPaySms'
    | 'RecurringEmail'
    | 'RecurringSms'
    | 'PaymentPlanEmail'
    | 'PaymentPlanSms'
    | 'StatementEmail'
    | 'StatementSms'
    | 'StatementPaper';
