import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { AppConfig } from '../../app.config';
import { DomainContentItem } from '../../models/domaincontentitems';
import { GenericResponse, Response } from '../../models/genericresponse';
import { jsonRequestOptions } from '../../util/HttpClientUtility';
import { LoggingLevel, LoggingService } from '../logging/logging.service';
import { StorageLevel, StorageService } from '../storage/storage.service';

@Injectable()
export class DomainContentItemsService {
    private domainUrl = this.config.getConfig('domainInfo');
    private domain = '';
    private content = new ReplaySubject<any>(1);
    private merchantProfileContentItemPath: string = this.config.getConfig('merchantProfileContentItemPath');

    private defaultContentItem: DomainContentItem = new DomainContentItem('missing_text');

    constructor(
        private http: HttpClient,
        private config: AppConfig,
        private storage: StorageService,
        private loggingService: LoggingService
    ) {
        this.domain = this.storage.getDomain();
    }

    /**
     * Provides an observable hook into the content items for components to use that gets updated as content gets reloaded.
     *
     * @memberof DomainContentItemsService
     */
    public content$ = this.content.asObservable();

    // Returns the domain content items from API or from storage
    public async getContentItems(): Promise<any> {
        if (this.storage.exists('content')) {
            this.content.next(this.storage.retrieve('content'));
            return Promise.resolve(this.storage.retrieve('content'));
        } else {
            const response = await this.callContentApi();
            this.storage.store('content', response.data, StorageLevel.protected);
            const content = this.storage.retrieve('content');
            this.content.next(content);
            return content;
        }
    }

    /**
     * Provide a call to get the content items by Merchant Profile GUID
     * @param {string} merchantProfileGuid
     * @param {string} category
     * @param {string} subCategory
     * @param {string} name
     * @returns {Promise<DomainContentItem>}
     * @memberof DomainContentItemsService
     */
    public async getMerchantProfileContentItems(
        merchantProfileGuid: string, category: string, subCategory: string, name: string ): Promise<DomainContentItem> {
        return this.callGetMerchantProfileContentItem(merchantProfileGuid, category, subCategory, name);
    }

    /**
     * Attempts to get a content item based on parameters and returns default text when
     * any portion of the content item object is 'undefined'
     * This prevents the app from throwing a breaking exception at runtime when
     * a content item is missing
     *
     * @param {any} content
     * @param {string} category
     * @param {string} subCategory
     * @param {string} name
     *
     * @returns {DomainContentItem}
     *
     * @memberof DomainContentItemsService
     */
    public tryGetContentItem(content: any, category: string, subCategory: string, name: string): DomainContentItem {
        let contentItem: DomainContentItem = Object.assign({}, this.defaultContentItem);
        contentItem.text = contentItem.text + '_' + category + '_' + subCategory + '_' + name;

        if (content[category]) {
            this.loggingService.log('found ' + category, LoggingLevel.debug);
            if (content[category][subCategory]) {
                this.loggingService.log('found ' + subCategory, LoggingLevel.debug);
                if (content[category][subCategory][name]) {
                    this.loggingService.log('found ' + name, LoggingLevel.debug);
                    contentItem = content[category][subCategory][name];
                } else {
                    this.loggingService.log('could not find ' + name, LoggingLevel.debug);
                }
            } else {
                this.loggingService.log('could not find ' + subCategory, LoggingLevel.debug);
            }
        } else {
            this.loggingService.log('could not find ' + category, LoggingLevel.debug);
        }

        this.loggingService.log('returning item', LoggingLevel.debug, contentItem);
        this.loggingService.log('returning text', LoggingLevel.debug, contentItem.text);
        return contentItem;
    }

    private async callContentApi(): Promise<any> {
        const categories: string[] = [
            'activity', 'ad', 'autopay', 'balanceDetails', 'carousel', 'chat', 'communicationpreferences', 'confirmation',
            'document', 'emailactivation', 'enrollment', 'error', 'footer', 'forms', 'global', 'header', 'help',
            'home', 'landingPage', 'login', 'memPayment', 'modal', 'modifiedenrollment', 'payment', 'paymentplan', 'preservice',
            'profile','quickpaysmsprofile', 'recurring', 'recurringactivation', 'sso', 'statements'
        ];
        const body = {
            bypass: true,
            domain: this.domain,
            categories,
            language: window.navigator.language,
            path: 'settings/v1/contentitems'
        };

        return this.http.post(this.domainUrl, { body }, jsonRequestOptions())
            .toPromise()
            .then((response: Response<any>) => response.body)
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    private async callGetMerchantProfileContentItem(
        merchantProfileGuid: string, category: string, subCategory: string, name: string): Promise<DomainContentItem > {
        const body = {
            bypass: true,
            merchantprofileguid: merchantProfileGuid,
            category,
            subcategory: subCategory,
            name,
            language: window.navigator.language,
            path: this.merchantProfileContentItemPath
        };

        return this.http.post(this.domainUrl, { body }, jsonRequestOptions())
            .toPromise()
            .then((response: Response<DomainContentItem>) => response.body.data)
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }
}
