import { DateUtils } from '../util/date.utils';
import { IPaymentBucket } from './ipaymentbucket';
import { IMaterial, MaterialType } from './material';
import { TEST_CONSUMER_PAYMENT_GUID } from './payment';

/* Defines the Document entity */
export interface IDocument {
    documentID: number;
    totalAmount: number;
    documentDate: Date;
    documentGUID: string;
    dueDate: Date;
    dueDateUtc: Date;
    lobCode: string;
    customerLOBName: string;
    accountNumber: string;
    addressee: string;
    paymentPlanAmount: number;
    customerAccountID: string;
    isPayable: boolean;
    isSuppressed: boolean;
    isStricken: boolean;
    merchantCredentialGUID: string; // Also known as MerchantCredential
    merchantProfileGUID: string;
    totalPayments: number;
    promptPayAmount: number;
    promptPayDueDate: Date;
    externalPaymentPlanAmount: number;  // installment amount
    paymentBuckets: IPaymentBucket[];
    consumerPaymentGUIDs: string[];
    autopayPaymentStatus: AutopayPaymentStatus;
    materials: IMaterial[];
    mem: string;
    dueUponReceipt: boolean;
}

export enum AutopayPaymentStatus {
    pending = 1,
    completed = 2,
    cancelled = 3,
    none = 0
}

export const TEST_DOCUMENT_ONE_PAYMENT: IDocument = {
    documentID: 1,
    totalAmount: 159,
    documentDate: DateUtils.lastMonth,
    dueDate: DateUtils.nextMonth,
    dueDateUtc: new Date(Date.UTC(DateUtils.nextMonth.getFullYear(), DateUtils.nextMonth.getMonth(), DateUtils.nextMonth.getDate())),
    documentGUID: 'b6a6f696-bb3d-4b0a-a603-8f4897a52346',
    lobCode: 'SJB-005',
    customerLOBName: 'test',
    accountNumber: '799039422',
    addressee: 'Doreen Mayer',
    mem: 'P-PCXNT-36699-GXFNKB',
    externalPaymentPlanAmount: 100,
    customerAccountID: '799039422',
    paymentPlanAmount: 0,
    totalPayments: 0,
    promptPayAmount: 0,
    promptPayDueDate: DateUtils.nextMonth,
    paymentBuckets: [
        {
            sequence: 1,
            description: 'FLU VACCINATION',
            code: 'MN',
            accountNumber: '001-799039422',
            date: DateUtils.lastMonth,
            amount: 85,
            location: 'North Branch',
            name: 'TIMMY',
            percentToAllocate: 53.4591,
            promptPayAmount: 0
        },
        {
            sequence: 2,
            description: 'OFFICE/OUTPT VISIT EST PT LEVL 3',
            code: 'MN',
            accountNumber: '002-799039422',
            date: DateUtils.lastMonth,
            amount: 36.5,
            location: 'Eastview',
            name: 'TOMMY',
            percentToAllocate: 22.956,
            promptPayAmount: 0
        },
        {
            sequence: 3,
            description: 'OFFICE VISIT',
            code: 'MN',
            accountNumber: '003-799039422',
            date: DateUtils.lastMonth,
            amount: 37.5,
            location: 'Southtown',
            name: 'TONY',
            percentToAllocate: 23.5849,
            promptPayAmount: 0
        }
    ],
    consumerPaymentGUIDs: [TEST_CONSUMER_PAYMENT_GUID], // align with mock payment consumerPaymentGUID (see payment.ts)
    materials: [
        {
            type: MaterialType.env_outer,
            materialGUID: '91b6f680-07a2-e711-80bf-005056a31049',
            pdfUrl: ''
        },
        {
            type: MaterialType.env_return,
            materialGUID: '69b7f680-07a2-e711-80bf-005056a31049',
            pdfUrl: ''
        },
        {
            type: MaterialType.stock,
            materialGUID: '82bbf680-07a2-e711-80bf-005056a31049',
            pdfUrl: ''
        }
    ],
    isPayable: true,
    merchantCredentialGUID: '4ff6c2bb-ccdf-e911-80d3-005056a31049',
    merchantProfileGUID: 'cd489da2-5693-4573-acdd-630045a83e48',
    autopayPaymentStatus: 0,
    isSuppressed: false,
    isStricken: false,
    dueUponReceipt: false
};

export const TEST_DOCUMENT_SUPPRESSED_AND_STRICKEN: IDocument = {
    documentID: 2,
    totalAmount: 174.5,
    documentDate: DateUtils.lastMonth,
    dueDate: DateUtils.nextMonth,
    dueDateUtc: new Date(Date.UTC(DateUtils.nextMonth.getFullYear(), DateUtils.nextMonth.getMonth(), DateUtils.nextMonth.getDate())),
    documentGUID: 'de1dc41b-95c6-4a4c-8d2f-37b787b6498b',
    lobCode: 'SJB-005',
    customerLOBName: 'test',
    accountNumber: '799039422',
    addressee: 'Doreen Mayer',
    mem: 'P-PVRRH-59675-DXSWGN',
    externalPaymentPlanAmount: 100,
    customerAccountID: '799039422',
    paymentPlanAmount: 0,
    totalPayments: 0,
    promptPayAmount: 0,
    promptPayDueDate: new Date(2000, 0, 2),
    paymentBuckets: [
        {
            sequence: 1,
            description: 'FLU VACCINATION',
            code: 'MN',
            accountNumber: '001-799039422',
            date: DateUtils.lastMonth,
            amount: 95.5,
            location: 'North Branch',
            name: 'TIMMY',
            percentToAllocate: 54.7278,
            promptPayAmount: 0
        },
        {
            sequence: 2,
            description: 'OFFICE/OUTPT VISIT EST PT LEVL 3',
            code: 'MN',
            accountNumber: '002-799039422',
            date: DateUtils.lastMonth,
            amount: 75.5,
            location: 'Eastview',
            name: 'TOMMY',
            percentToAllocate: 43.2665,
            promptPayAmount: 0
        },
        {
            sequence: 3,
            description: 'OFFICE VISIT',
            code: 'MN',
            accountNumber: '003-799039422',
            date: DateUtils.lastMonth,
            amount: 3.5,
            location: 'Southtown',
            name: 'TONY',
            percentToAllocate: 2.0057,
            promptPayAmount: 0
        }
    ],
    consumerPaymentGUIDs: [],
    materials: [
        {
            type: MaterialType.env_outer,
            materialGUID: '91b6f680-07a2-e711-80bf-005056a31049',
            pdfUrl: ''
        },
        {
            type: MaterialType.env_outer,
            materialGUID: '69b7f680-07a2-e711-80bf-005056a31049',
            pdfUrl: ''
        },
        {
            type: MaterialType.stock,
            materialGUID: '82bbf680-07a2-e711-80bf-005056a31049',
            pdfUrl: ''
        }
    ],
    isPayable: true,
    merchantCredentialGUID: '4ff6c2bb-ccdf-e911-80d3-005056a31049',
    merchantProfileGUID: 'cd489da2-5693-4573-acdd-630045a83e48',
    autopayPaymentStatus: 0,
    isSuppressed: true,
    isStricken: true,
    dueUponReceipt: false
};

export const TEST_DOCUMENT_DUE_TOMORROW: IDocument = {
    documentID: 3,
    totalAmount: 176.5,
    documentDate: DateUtils.addMonths(DateUtils.lastMonth, -1),
    dueDate: DateUtils.addDays(new Date(), 1),
    dueDateUtc: new Date(Date.UTC(
        DateUtils.addDays(new Date(), 1).getFullYear(),
        DateUtils.addDays(new Date(), 1).getMonth(),
        DateUtils.addDays(new Date(), 1).getDate()
    )),
    documentGUID: 'ef95b3bd-027c-4d2d-95b8-7e1cd7772fe3',
    lobCode: 'SJB-004 Last Statement',
    customerLOBName: 'test',
    accountNumber: '799039422',
    addressee: 'Doreen Mayer',
    mem: 'P-SDYZY-62164-MWNHGY',
    externalPaymentPlanAmount: 100,
    customerAccountID: '799039422',
    paymentPlanAmount: 0,
    totalPayments: 0,
    promptPayAmount: 0,
    promptPayDueDate: new Date(2000, 0, 2),
    paymentBuckets: [
        {
            sequence: 1,
            description: 'FLU VACCINATION',
            code: 'MN',
            accountNumber: '001-799039422',
            date: DateUtils.addMonths(DateUtils.lastMonth, -1),
            amount: 100,
            location: 'North Branch',
            name: 'TIMMY',
            percentToAllocate: 33.3334,
            promptPayAmount: 0
        },
        {
            sequence: 2,
            description: 'OFFICE/OUTPT VISIT EST PT LEVL 3',
            code: 'MN',
            accountNumber: '002-799039422',
            date: DateUtils.addMonths(DateUtils.lastMonth, -1),
            amount: 50,
            location: 'Eastview',
            name: 'TOMMY',
            percentToAllocate: 33.3333,
            promptPayAmount: 0
        },
        {
            sequence: 3,
            description: 'OFFICE VISIT',
            code: 'MN',
            accountNumber: '003-799039422',
            date: DateUtils.addMonths(DateUtils.lastMonth, -1),
            amount: 26.5,
            location: 'Southtown',
            name: 'TONY',
            percentToAllocate: 33.3333,
            promptPayAmount: 0
        }
    ],
    consumerPaymentGUIDs: [],
    materials: [
        {
            type: MaterialType.env_outer,
            materialGUID: '91b6f680-07a2-e711-80bf-005056a31049',
            pdfUrl: ''
        }
    ],
    isPayable: true,
    merchantCredentialGUID: '33842254-47cb-e911-80d3-005056a31049',
    merchantProfileGUID: '320fe1e7-8fb4-4c31-b69d-7b58a54b4a79',
    autopayPaymentStatus: 0,
    isSuppressed: false,
    isStricken: false,
    dueUponReceipt: false
};

export const TEST_DOCUMENT_DUE_NEXT_WEEK: IDocument = {
    documentID: 3,
    totalAmount: 176.5,
    documentDate: DateUtils.addMonths(DateUtils.lastMonth, -1),
    dueDate: DateUtils.addDays(new Date(), 7),
    dueDateUtc: new Date(Date.UTC(
        DateUtils.addDays(new Date(), 7).getFullYear(),
        DateUtils.addDays(new Date(), 7).getMonth(),
        DateUtils.addDays(new Date(), 7).getDate()
    )),
    documentGUID: 'ef95b3bd-027c-4d2d-95b8-7e1cd7772fe3',
    lobCode: 'SJB-004 Last Statement',
    customerLOBName: 'test',
    accountNumber: '799039422',
    addressee: 'Doreen Mayer',
    mem: 'P-SDYZY-62164-MWNHGY',
    externalPaymentPlanAmount: 100,
    customerAccountID: '799039422',
    paymentPlanAmount: 0,
    totalPayments: 0,
    promptPayAmount: 0,
    promptPayDueDate: new Date(2000, 0, 2),
    paymentBuckets: [
        {
            sequence: 1,
            description: 'FLU VACCINATION',
            code: 'MN',
            accountNumber: '001-799039422',
            date: DateUtils.addMonths(DateUtils.lastMonth, 7),
            amount: 100,
            location: 'North Branch',
            name: 'TIMMY',
            percentToAllocate: 33.3334,
            promptPayAmount: 0
        },
        {
            sequence: 2,
            description: 'OFFICE/OUTPT VISIT EST PT LEVL 3',
            code: 'MN',
            accountNumber: '002-799039422',
            date: DateUtils.addMonths(DateUtils.lastMonth, 7),
            amount: 50,
            location: 'Eastview',
            name: 'TOMMY',
            percentToAllocate: 33.3333,
            promptPayAmount: 0
        },
        {
            sequence: 3,
            description: 'OFFICE VISIT',
            code: 'MN',
            accountNumber: '003-799039422',
            date: DateUtils.addMonths(DateUtils.lastMonth, 7),
            amount: 26.5,
            location: 'Southtown',
            name: 'TONY',
            percentToAllocate: 33.3333,
            promptPayAmount: 0
        }
    ],
    consumerPaymentGUIDs: [],
    materials: [
        {
            type: MaterialType.env_outer,
            materialGUID: '91b6f680-07a2-e711-80bf-005056a31049',
            pdfUrl: ''
        }
    ],
    isPayable: true,
    merchantCredentialGUID: '33842254-47cb-e911-80d3-005056a31049',
    merchantProfileGUID: '320fe1e7-8fb4-4c31-b69d-7b58a54b4a79',
    autopayPaymentStatus: 0,
    isSuppressed: false,
    isStricken: false,
    dueUponReceipt: false
};

export const TEST_DOCUMENT_NOT_PAYABLE: IDocument = {
    documentID: 5,
    totalAmount: -10,
    documentDate: DateUtils.lastMonth,
    dueDate: DateUtils.nextMonth,
    dueDateUtc: new Date(Date.UTC(DateUtils.nextMonth.getFullYear(), DateUtils.nextMonth.getMonth(), DateUtils.nextMonth.getDate())),
    documentGUID: 'ef95b3bd-027c-4d2d-95b8-7e1cd7772fe3',
    lobCode: 'SJB-004 Last Statement',
    customerLOBName: 'test',
    accountNumber: '799039422',
    addressee: 'Doreen Mayer',
    mem: 'P-SDYZY-62164-MWNHGY',
    externalPaymentPlanAmount: 100,
    customerAccountID: '799039422',
    paymentPlanAmount: 0,
    totalPayments: 0,
    promptPayAmount: 0,
    promptPayDueDate: new Date(2000, 0, 2),
    paymentBuckets: [
        {
            sequence: 1,
            description: 'FLU VACCINATION',
            code: 'MN',
            accountNumber: '001-799039422',
            date: DateUtils.lastMonth,
            amount: -100,
            location: 'North Branch',
            name: 'TIMMY',
            percentToAllocate: 33.3334,
            promptPayAmount: 0
        },
        {
            sequence: 2,
            description: 'OFFICE/OUTPT VISIT EST PT LEVL 3',
            code: 'MN',
            accountNumber: '002-799039422',
            date: DateUtils.lastMonth, // e.g. one year ago
            amount: 50,
            location: 'Eastview',
            name: 'TOMMY',
            percentToAllocate: 33.3333,
            promptPayAmount: 0
        },
        {
            sequence: 3,
            description: 'OFFICE VISIT',
            code: 'MN',
            accountNumber: '003-799039422',
            date: DateUtils.lastMonth, // e.g. one year ago
            amount: 0,
            location: 'Southtown',
            name: 'TONY',
            percentToAllocate: 33.3333,
            promptPayAmount: 0
        }
    ],
    consumerPaymentGUIDs: [],
    materials: [
        {
            type: MaterialType.env_outer,
            materialGUID: '91b6f680-07a2-e711-80bf-005056a31049',
            pdfUrl: ''
        }
    ],
    isPayable: false,
    merchantCredentialGUID: '33842254-47cb-e911-80d3-005056a31049',
    merchantProfileGUID: '320fe1e7-8fb4-4c31-b69d-7b58a54b4a79',
    autopayPaymentStatus: 0,
    isSuppressed: false,
    isStricken: false,
    dueUponReceipt: false
};

export const TEST_DOCUMENT_ONE_BUCKET: IDocument = {
    documentID: 10,
    totalAmount: 25,
    documentDate: DateUtils.lastMonth,
    dueDate: DateUtils.nextMonth,
    dueDateUtc: new Date(Date.UTC(DateUtils.nextMonth.getFullYear(), DateUtils.nextMonth.getMonth(), DateUtils.nextMonth.getDate())),
    documentGUID: 'ef95b3bd-027c-4d2d-95b8-7e1cd7772fe3',
    lobCode: 'SJB-004 Last Statement',
    customerLOBName: 'test',
    accountNumber: '799039422',
    addressee: 'Doreen Mayer',
    mem: 'P-SDYZY-62164-MWNHGY',
    externalPaymentPlanAmount: 100,
    customerAccountID: '799039422',
    paymentPlanAmount: 0,
    totalPayments: 0,
    promptPayAmount: 0,
    promptPayDueDate: DateUtils.tenDaysOut,
    paymentBuckets: [
        {
            sequence: 1,
            description: 'FLU VACCINATION',
            code: 'MN',
            accountNumber: '001-799039422',
            date: DateUtils.lastMonth,
            amount: 25,
            location: 'North Branch',
            name: 'TIMMY',
            percentToAllocate: 100,
            promptPayAmount: 0
        }
    ],
    consumerPaymentGUIDs: [],
    materials: [
        {
            type: MaterialType.env_outer,
            materialGUID: '91b6f680-07a2-e711-80bf-005056a31049',
            pdfUrl: ''
        }
    ],
    isPayable: true,
    merchantCredentialGUID: '33842254-47cb-e911-80d3-005056a31049',
    merchantProfileGUID: '320fe1e7-8fb4-4c31-b69d-7b58a54b4a79',
    autopayPaymentStatus: 0,
    isSuppressed: false,
    isStricken: false,
    dueUponReceipt: false
};

export const TEST_DOCUMENT_BUCKETS_LESS_THAN_EXTERNAL_PLAN: IDocument = {
    documentID: 100,
    totalAmount: 100,
    documentDate: DateUtils.lastMonth,
    dueDate: DateUtils.nextMonth,
    dueDateUtc: new Date(Date.UTC(DateUtils.nextMonth.getFullYear(), DateUtils.nextMonth.getMonth(), DateUtils.nextMonth.getDate())),
    documentGUID: 'ef95b3bd-027c-4d2d-95b8-7e1cd7772fe3',
    lobCode: 'SJB-004 Last Statement',
    customerLOBName: 'test',
    accountNumber: '799039422',
    addressee: 'Doreen Mayer',
    mem: 'P-SDYZY-62164-MWNHGY',
    externalPaymentPlanAmount: 100.00,
    customerAccountID: '799039422',
    paymentPlanAmount: 0,
    totalPayments: 0,
    promptPayAmount: 0,
    promptPayDueDate: DateUtils.tenDaysOut,
    paymentBuckets: [
        {
            sequence: 1,
            description: 'FLU VACCINATION',
            code: 'MN',
            accountNumber: '001-799039422',
            date: DateUtils.lastMonth,
            amount: 25.00,
            location: 'North Branch',
            name: 'TIMMY',
            percentToAllocate: 100,
            promptPayAmount: 0
        },
        {
            sequence: 1,
            description: 'ARROW TO THE KNEE',
            code: 'MN',
            accountNumber: '001-799039422',
            date: DateUtils.lastMonth,
            amount: 50.00,
            location: 'North Branch',
            name: 'TIMMY',
            percentToAllocate: 100,
            promptPayAmount: 0
        }
    ],
    consumerPaymentGUIDs: [],
    materials: [
        {
            type: MaterialType.env_outer,
            materialGUID: '91b6f680-07a2-e711-80bf-005056a31049',
            pdfUrl: ''
        }
    ],
    isPayable: true,
    merchantCredentialGUID: '33842254-47cb-e911-80d3-005056a31049',
    merchantProfileGUID: '320fe1e7-8fb4-4c31-b69d-7b58a54b4a79',
    autopayPaymentStatus: 0,
    isSuppressed: false,
    isStricken: false,
    dueUponReceipt: false
};

export const TEST_DOCUMENT_BUCKETS_GREATER_THAN_EXTERNAL_PLAN: IDocument = {
    documentID: 200,
    totalAmount: 200,
    documentDate: DateUtils.lastMonth,
    dueDate: DateUtils.nextMonth,
    dueDateUtc: new Date(Date.UTC(DateUtils.nextMonth.getFullYear(), DateUtils.nextMonth.getMonth(), DateUtils.nextMonth.getDate())),
    documentGUID: 'ef95b3bd-027c-4d2d-95b8-7e1cd7772fe3',
    lobCode: 'SJB-004 Last Statement',
    customerLOBName: 'test',
    accountNumber: '799039422',
    addressee: 'Doreen Mayer',
    mem: 'P-SDYZY-62164-MWNHGY',
    externalPaymentPlanAmount: 75.00,
    customerAccountID: '799039422',
    paymentPlanAmount: 0,
    totalPayments: 0,
    promptPayAmount: 0,
    promptPayDueDate: DateUtils.tenDaysOut,
    paymentBuckets: [
        {
            sequence: 1,
            description: 'FLU VACCINATION',
            code: 'MN',
            accountNumber: '001-799039422',
            date: DateUtils.lastMonth,
            amount: 50.00,
            location: 'North Branch',
            name: 'TIMMY',
            percentToAllocate: 100,
            promptPayAmount: 0
        },
        {
            sequence: 2,
            description: 'ARROW TO THE KNEE',
            code: 'MN',
            accountNumber: '001-799039422',
            date: DateUtils.lastMonth,
            amount: 50.00,
            location: 'North Branch',
            name: 'TIMMY',
            percentToAllocate: 100,
            promptPayAmount: 0
        }
    ],
    consumerPaymentGUIDs: [],
    materials: [
        {
            type: MaterialType.env_outer,
            materialGUID: '91b6f680-07a2-e711-80bf-005056a31049',
            pdfUrl: ''
        }
    ],
    isPayable: true,
    merchantCredentialGUID: '33842254-47cb-e911-80d3-005056a31049',
    merchantProfileGUID: '320fe1e7-8fb4-4c31-b69d-7b58a54b4a79',
    autopayPaymentStatus: 0,
    isSuppressed: false,
    isStricken: false,
    dueUponReceipt: false
};

export const TEST_DOCUMENT_NO_PAYABLE_AMOUNT_POSITIVE_BUCKET_BALANCE: IDocument = {
    documentID: 300,
    totalAmount: 0,
    documentDate: DateUtils.lastMonth,
    dueDate: DateUtils.nextMonth,
    dueDateUtc: new Date(Date.UTC(DateUtils.nextMonth.getFullYear(), DateUtils.nextMonth.getMonth(), DateUtils.nextMonth.getDate())),
    documentGUID: 'ef95b3bd-027c-4d2d-95b8-7e1cd7772fe3',
    lobCode: 'SJB-004 Last Statement',
    customerLOBName: 'test',
    accountNumber: '799039422',
    addressee: 'Doreen Mayer',
    mem: 'P-SDYZY-62164-MWNHGY',
    externalPaymentPlanAmount: 0,
    customerAccountID: '799039422',
    paymentPlanAmount: 0,
    totalPayments: 0,
    promptPayAmount: 0,
    promptPayDueDate: DateUtils.tenDaysOut,
    paymentBuckets: [
        {
            sequence: 1,
            description: 'FLU VACCINATION',
            code: 'MN',
            accountNumber: '001-799039422',
            date: DateUtils.lastMonth,
            amount: 50.00,
            location: 'North Branch',
            name: 'TIMMY',
            percentToAllocate: 100,
            promptPayAmount: 0
        },
        {
            sequence: 2,
            description: 'ARROW TO THE KNEE',
            code: 'MN',
            accountNumber: '001-799039422',
            date: DateUtils.lastMonth,
            amount: 50.00,
            location: 'North Branch',
            name: 'TIMMY',
            percentToAllocate: 100,
            promptPayAmount: 0
        }
    ],
    consumerPaymentGUIDs: [],
    materials: [
        {
            type: MaterialType.env_outer,
            materialGUID: '91b6f680-07a2-e711-80bf-005056a31049',
            pdfUrl: ''
        }
    ],
    isPayable: true,
    merchantCredentialGUID: '33842254-47cb-e911-80d3-005056a31049',
    merchantProfileGUID: '320fe1e7-8fb4-4c31-b69d-7b58a54b4a79',
    autopayPaymentStatus: 0,
    isSuppressed: false,
    isStricken: false,
    dueUponReceipt: false
};

export const TEST_DOCUMENT_FROM_ONE_YEAR_AGO: IDocument = {
    // document from a year ago:
    documentID: 3,
    totalAmount: 176.5,
    documentDate: DateUtils.addMonths(DateUtils.lastMonth, -11), // e.g. one year ago
    dueDate: DateUtils.nextMonth,
    dueDateUtc: new Date(Date.UTC(DateUtils.nextMonth.getFullYear(), DateUtils.nextMonth.getMonth(), DateUtils.nextMonth.getDate())),
    documentGUID: 'ef95b3bd-027c-4d2d-95b8-7e1cd7772fe3',
    lobCode: 'SJB-004 Last Statement',
    customerLOBName: 'test',
    accountNumber: '799039422',
    addressee: 'Doreen Mayer',
    mem: 'P-SDYZY-62164-MWNHGY',
    externalPaymentPlanAmount: 100,
    customerAccountID: '799039422',
    paymentPlanAmount: 0,
    totalPayments: 0,
    promptPayAmount: 0,
    promptPayDueDate: new Date(2000, 0, 2),
    paymentBuckets: [
        {
            sequence: 1,
            description: 'FLU VACCINATION',
            code: 'MN',
            accountNumber: '001-799039422',
            date: DateUtils.addMonths(DateUtils.lastMonth, -11), // e.g. one year ago
            amount: 100,
            location: 'North Branch',
            name: 'TIMMY',
            percentToAllocate: 33.3334,
            promptPayAmount: 0
        },
        {
            sequence: 2,
            description: 'OFFICE/OUTPT VISIT EST PT LEVL 3',
            code: 'MN',
            accountNumber: '002-799039422',
            date: DateUtils.addMonths(DateUtils.lastMonth, -11), // e.g. one year ago
            amount: 50,
            location: 'Eastview',
            name: 'TOMMY',
            percentToAllocate: 33.3333,
            promptPayAmount: 0
        },
        {
            sequence: 3,
            description: 'OFFICE VISIT',
            code: 'MN',
            accountNumber: '003-799039422',
            date: DateUtils.addMonths(DateUtils.lastMonth, -11), // e.g. one year ago
            amount: 26.5,
            location: 'Southtown',
            name: 'TONY',
            percentToAllocate: 33.3333,
            promptPayAmount: 0
        }
    ],
    consumerPaymentGUIDs: [],
    materials: [
        {
            type: MaterialType.env_outer,
            materialGUID: '91b6f680-07a2-e711-80bf-005056a31049',
            pdfUrl: ''
        }
    ],
    isPayable: true,
    merchantCredentialGUID: '33842254-47cb-e911-80d3-005056a31049',
    merchantProfileGUID: '320fe1e7-8fb4-4c31-b69d-7b58a54b4a79',
    autopayPaymentStatus: 0,
    isSuppressed: false,
    isStricken: false,
    dueUponReceipt: false
};

