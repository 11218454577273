import { Pipe, PipeTransform } from '@angular/core';

/**
 * The PhonePipe will take a 10 digit string and format it to a readable phone number
 * 6125551234 becomes (612) 555-1234
 * Modified version of: http://davidsdotnetlines.blogspot.com/2016/10/angular2-custom-pipe-for-phone.html
 *
 * @export
 * @class PhonePipe
 * @implements {PipeTransform}
 */
@Pipe({
    name: 'phone'
})

export class PhonePipe implements PipeTransform {

    transform(value: string): string {
        let phoneNumber = value.trim().replace(/^\+/, '');
        phoneNumber = phoneNumber.replace(/[^0-9]/g, '').slice(0, 10);
        let area: string;
        let n: string;

        if (phoneNumber.length) {
            area = phoneNumber.slice(0, 3);
            n = phoneNumber.slice(3);
        }

        if (n) {
            if (n.length) {
                n = n.slice(0, 3) + '-' + n.slice(3, 7);
            } else {
                n = n;
            }
            return ('(' + area + ') ' + n).trim().slice(0, 14);
        } else {
            return '';
        }
    }
}
