/* tslint:disable:triple-equals */
import { Component, ElementRef, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConsumerAccount } from '../../../models/consumeraccount';
import { SubmitFeedbackRequest } from '../../../models/submitfeedbackrequest';
import { ComponentService } from '../../../services/component/component.service';
import { ConsumerService } from '../../../services/consumer/consumer.service';
import { LoggingLevel } from '../../../services/logging/logging.service';

@Component({
    selector: 'feedback',
    template: require('./feedback.component.html'),
    styles: [require('./feedback.component.css')]
})
export class FeedbackComponent implements OnInit, OnDestroy {
    constructor(
        private componentService: ComponentService,
        private consumerService: ConsumerService,
        private parentRouter: Router,
        private element: ElementRef
    ) {
        this.elementRef = element;
    }

    elementRef: ElementRef;
    chatButton: HTMLElement;
    feedbackLabelText: string;
    feedbackButtonText: string;
    feedbackPlaceholder: string;
    feedbackCategory: string;
    feedbackCategories: string[] = ['General feedback', 'Suggestion', 'Report issue or complaint'];

    enableFeedback = true;
    comment = '';
    feedbackTitle: string;
    feedbackQuestion: string;
    feedbackCategoryText: string;
    feedbackErrorMessage: string;
    feedbackSuccessMessage: string;
    consumerAccount: ConsumerAccount;
    customerAccountID: string;
    domainID: number;
    token: string;
    loading = false;
    showSlideout = false;
    feedbackSubmitted = false;
    feedbackErrorExists = false;
    commentError = false;
    categoryError = false;
    feedbackValidationMessage: string;
    isMobile = false;

    private ngUnsubscribe: Subject<any> = new Subject();

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit() {
        this.isMobile = this.componentService.isMobileBrowser();
        this.componentService.contentService.content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((content: any) => {
                this.feedbackLabelText = this.componentService.contentService.tryGetContentItem(content, 'global', 'feedback', 'feedbackTabLabel').text;
                this.feedbackButtonText = this.componentService.contentService.tryGetContentItem(content, 'global', 'feedback', 'feedbackSubmitButton').text;
                this.feedbackPlaceholder = this.componentService.contentService.tryGetContentItem(content, 'global', 'feedback', 'feedbackPlaceholderText').text;
                this.feedbackTitle = this.componentService.contentService.tryGetContentItem(
                    content, 'global', 'feedback', 'feedbackTitle').text;
                this.feedbackQuestion = this.componentService.contentService.tryGetContentItem(
                    content, 'global', 'feedback', 'feedbackQuestion').text;
                this.feedbackCategoryText = this.componentService.contentService.tryGetContentItem(content, 'global', 'feedback', 'feedbackCategoryText').text;
                this.feedbackErrorMessage = this.componentService.contentService.tryGetContentItem(content, 'global', 'feedback', 'feedbackErrorMessage').text;
                this.feedbackSuccessMessage = this.componentService.contentService.tryGetContentItem(content, 'global', 'feedback', 'feedbackSuccessMessage').text;
                this.feedbackValidationMessage = this.componentService.contentService.tryGetContentItem(content, 'global', 'feedback', 'feedbackValidationMessage').text;
            });

        this.token = this.componentService.storageService.retrieve('token');
        this.chatButton = document.getElementById('lhnHocButton');
        // need to reset chatButton in case it was expanded on previous page
        if (this.chatButton != null) {
            this.chatButton.style.transitionDuration = '0.0s';
            this.chatButton.style.right = '-25px';
        }
        if (!this.componentService.userIsLoggedIn() && this.token == null) {
            this.componentService.loggingService.log(
                'Feedback component loaded with no consumer info, force disabling.', LoggingLevel.debug);
            this.enableFeedback = false;
        } else {
            this.componentService.domainService.domainInfo$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(domainInfo => {
                this.enableFeedback = domainInfo.enableFeedback;
            });
            this.consumerService.getConsumer(this.token).then(consumer => {
                this.customerAccountID = consumer.accountID;
            });
        }
    }

    /**
     * For cases where the session is cleared before Feedback was able to retrieve the consumerAccount,
     * the parent component can call this method via ViewChild and populate the consumerAccount properties
     *
     * @param {string} customerAccountID
     * @param {number} domainID
     * @memberof FeedbackComponent
     */
    public populateConsumerProperties(customerAccountID: string): void {
        this.customerAccountID = customerAccountID;
    }

    saveFeedback() {
        if (this.validateFields()) {
            this.feedbackErrorExists = false;
            this.startLoading();
            this.componentService.loggingService.log('submitting feedback on page ' + this.parentRouter.url, LoggingLevel.debug);
            this.componentService.loggingService.log('submitting feedback: ' + this.comment, LoggingLevel.debug);
            const feedbackRequest: SubmitFeedbackRequest = new SubmitFeedbackRequest();
            feedbackRequest.FeedbackCategory = this.feedbackCategory;
            feedbackRequest.CustomerAccountID = this.customerAccountID;
            feedbackRequest.Comment = this.comment;
            feedbackRequest.ActivePage = this.parentRouter.url;
            feedbackRequest.ScreenSize = (window.outerWidth + 'x' + window.outerHeight);
            feedbackRequest.Browser = this.componentService.detectBrowser();
            this.consumerService.submitFeedback(feedbackRequest, this.token).then((response) => {
                this.commentError = false;
                this.categoryError = false;
                this.doneLoading();
                if (response) {
                    this.feedbackSubmitted = true;
                } else {
                    this.feedbackErrorExists = true;
                }
            }).catch((error) => {
                this.doneLoading();
                this.feedbackErrorExists = true;
            });
        }
    }

    isIE(): boolean {
        return this.componentService.isIE();
    }

    slideoutClicked(): void {
        if (this.showSlideout) {
            this.showSlideout = false;
        } else {
            this.showSlideout = true;
            if (!this.isMobile) {
                this.chatButton = document.getElementById('lhnHocButton');
                if (this.chatButton != null) {
                    this.chatButton.style.transitionDuration = '0.3s';
                    this.chatButton.style.right = '425px';
                }
            }
        }
    }

    private startLoading() {
        this.loading = true;
    }

    private doneLoading() {
        this.loading = false;
    }

    private isLoading(): boolean {
        return this.loading;
    }

    private validateFields(): boolean {
        if (!this.feedbackCategory) {
            this.categoryError = true;
        }
        if (!this.comment || this.comment.length == 0) {
            this.commentError = true;
        }
        return (!this.categoryError && !this.commentError);
    }

    private clearCommentErrors(): void {
        this.commentError = false;
    }

    private clearCategoryErrors(): void {
        this.categoryError = false;
    }

    private hideSubmitLink(): boolean {
        return (this.feedbackSubmitted || this.categoryError || this.commentError);
    }

    // listen to clicks on page. if slideout is open and user clicks outside of it, close the slideout
    @HostListener('document:click', ['$event'])
    @HostListener('document:touchstart', ['$event'])
    clickOutside(event: any): void {
        if (this.showSlideout) {
            let inside = false;
            let clickedComponent = event.target;
            while (clickedComponent) {
                if (clickedComponent == this.elementRef.nativeElement) {
                    inside = true;
                }
                clickedComponent = clickedComponent.parentNode;
            }
            if (!inside) {
                this.showSlideout = false;
                this.chatButton = document.getElementById('lhnHocButton');
                if (this.chatButton != null) {
                    this.chatButton.style.right = '-25px';
                }
            }
        }
    }
}
