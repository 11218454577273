import { Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConsumerAccount } from '../../../models/consumeraccount';
import { IDomainInfo } from '../../../models/domaininfo';
import { ComponentService } from '../../../services/component/component.service';
import { ConsumerService } from '../../../services/consumer/consumer.service';

@Component({
    selector: 'manage-sms-profile',
    template: require('./managesmsprofile.component.html'),
    styles: [require('./managesmsprofile.component.css')]
})
export class ManageSmsProfileComponent implements OnInit, OnDestroy {
    @Input() quickPayOnly = false;
    @Input() backLinkPath: string = null;
    @Input() useVerificationID = false;

    @Output() public optedOutSms: EventEmitter<string> = new EventEmitter<string>();

    textMessagingLoading = false;
    domainInfo: IDomainInfo;
    textMessagesText: string;
    textMessageUpdateText: string;
    textMessageOptOutText: string;
    textMessageLastSentText: string;
    textMessageNoTextsSent: string;
    smsPhone = '';
    smsActive: boolean;
    smsStatusActiveText: string;
    smsStatusInactiveText: string;
    consumerAccount: ConsumerAccount;
    verificationID: string;
    lastSMSDate: Date;

    private ngUnsubscribe: Subject<any> = new Subject();

    constructor(
        private componentService: ComponentService,
        private consumerService: ConsumerService,
        private parentRouter: Router,
        private ngZone: NgZone
    ) { }

    async ngOnInit() {
        this.componentService.contentService.content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((content: any) => {
                this.textMessagesText = this.componentService.contentService.tryGetContentItem(content, 'profile', 'pageText', 'profileTextMessagesText').text;
                this.textMessageUpdateText = this.componentService.contentService.tryGetContentItem(content, 'profile', 'pageText', 'profileTextMessageUpdateText').text;
                this.textMessageOptOutText = this.componentService.contentService.tryGetContentItem(content, 'profile', 'pageText', 'profileTextMessageOptOutText').text;
                this.textMessagesText = this.componentService.contentService.tryGetContentItem(content, 'profile', 'pageText', 'profileTextMessagesText').text;
                this.textMessageLastSentText = this.componentService.contentService.tryGetContentItem(content, 'profile', 'pageText', 'profileTextMessageLastSentText').text;
                this.textMessageNoTextsSent = this.componentService.contentService.tryGetContentItem(content, 'profile', 'pageText', 'profileTextMessageNoTextSent').text;
                this.smsPhone = this.componentService.contentService.tryGetContentItem(content, 'profile', 'pageText', 'profileNoTextMessageText').text;
                this.smsStatusActiveText = this.componentService.contentService.tryGetContentItem(content, 'profile', 'pageText', 'profileSmsStatusActiveText').text;
                this.smsStatusInactiveText = this.componentService.contentService.tryGetContentItem(content, 'profile', 'pageText', 'profileSmsStatusInactiveText').text;
                this.textMessageUpdateText = this.componentService.contentService.tryGetContentItem(content, 'profile', 'pageText', 'profileTextMessageUpdateText').text;
                this.textMessageOptOutText = this.componentService.contentService.tryGetContentItem(content, 'profile', 'pageText', 'profileTextMessageOptOutText').text;
            });

        this.componentService.domainService.domainInfo$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(domainInfo => {
            this.domainInfo = domainInfo;
        });

        if (this.useVerificationID) {
            await this.populateSMSProfileByVerificationID();
        } else {
            await this.populateSMSProfileByConsumer();
        }
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    /**
     * Retrieves and inits the consumer, consumer account, and wallet payment methods
     */
    public async populateSMSProfileByConsumer() {
        const consumerAccount = await this.consumerService.getConsumerAccount();
        if (!!consumerAccount) {
            this.smsActive = consumerAccount.smsDelivery;
            if (consumerAccount.mobilePhone != null && consumerAccount.mobilePhone.length > 0) {
                this.smsPhone = consumerAccount.mobilePhone;
            }
            this.lastSMSDate = consumerAccount.lastSMSDate;
            this.consumerAccount = consumerAccount;
        }
        this.textMessagingLoading = false;
    }

    public async populateSMSProfileByVerificationID() {
        this.textMessagingLoading = true;
        if (this.componentService.storageService.exists('accountverificationid')) {
            this.verificationID = this.componentService.storageService.retrieve('accountverificationid');
            let preferences;
            try {
                preferences = await this.consumerService.getConsumerAccountSMSPreferencesByVerificationID(this.verificationID);
            } catch (e) {
                this.textMessagingLoading = false;
                return;
            }
            if (!!preferences) {
                this.smsActive = preferences.smsDelivery;
                if (preferences.mobilePhone != null && preferences.mobilePhone.length > 0) {
                    this.smsPhone = preferences.mobilePhone;
                }
                this.lastSMSDate = preferences.lastSMSDate;
            }
        }
        this.textMessagingLoading = false;
    }

    private allowSMS(): boolean {
        return this.domainInfo && this.domainInfo.enableSMS && !this.quickPayOnly;
    }

    private manageSMS(event: any): void {
        const url = '/sms';
        if (this.backLinkPath) {
            this.componentService.storageService.store('smsbacklink', this.backLinkPath);
        }
        this.ngZone.run(() => this.parentRouter.navigateByUrl(url)).then();
    }

    private async optOutSMS(event: any) {
        this.textMessagingLoading = true;
        if (this.useVerificationID) {
            let deactivated;
            try {
                deactivated = await this.consumerService.deactivateSmsByVerificationID(this.verificationID);
            } catch (e) {
                this.textMessagingLoading = false;
                return;
            }
            if (deactivated.success) {
                await this.populateSMSProfileByVerificationID();
            } else {
                this.textMessagingLoading = false;
            }
        } else {
            let deactivated;
            try {
                deactivated = await this.consumerService.deactivateSms(this.consumerAccount.customerAccountID);
            } catch (e) {
                this.textMessagingLoading = false;
                return;
            }
            if (deactivated.success) {
                this.componentService.storageService.clear('consumeraccount');
                await this.populateSMSProfileByConsumer();
                this.optedOutSms.emit(null);
            } else {
                this.textMessagingLoading = false;
            }
        }
    }
}
