import { Component } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IConsumer } from '../../../models/consumer';
import { ConsumerAccount } from '../../../models/consumeraccount';
import { IDomainInfo } from '../../../models/domaininfo';
import { PaymentMethod } from '../../../models/paymentmethod';
import { Profile } from '../../../models/profile';
import { IProfileCompleteness } from '../../../models/profilecompleteness';
import { ComponentService } from '../../../services/component/component.service';
import { ConsumerService } from '../../../services/consumer/consumer.service';
import { LoggingLevel } from '../../../services/logging/logging.service';

@Component({
    selector: 'profile-widget',
    template: require('./profilewidget.component.html'),
    styles: [require('./profilewidget.component.css')]
})

export class ProfileWidgetComponent {

    loading = true;
    showLink = true;
    consumer: IConsumer;
    consumerAccount: ConsumerAccount;
    walletMethods: PaymentMethod[];
    defaultWallet: PaymentMethod;
    domainInfo: IDomainInfo;
    profile: Profile;
    accountSettingsLinkText: string;
    completeYourProfileText: string;
    private ngUnsubscribe: Subject<any> = new Subject();

    constructor(private consumerService: ConsumerService, private componentService: ComponentService) {
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit() {
        this.componentService.domainService.domainInfo$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
            domainInfo => {
                this.domainInfo = domainInfo;
                // Calling populateConsumer here because it calls setProfile
                // which requires this.domainInfo to be set:
                this.populateConsumer();
            });
        this.componentService.contentService.content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((content: any) => {
                this.accountSettingsLinkText = this.componentService.contentService.tryGetContentItem(content, 'home', 'profile', 'homePageAccountSettingsLink').text;
                this.completeYourProfileText = this.componentService.contentService.tryGetContentItem(content, 'home', 'profile', 'homePageCompleteYourProfile').text;
            });
    }

    private populateConsumer(): void {
        this.consumerService.getConsumer().then(consumer => {
            this.consumer = consumer;
            this.consumerService.getWalletPaymentMethods().then(wallet => {
                this.updateWallet(wallet);
                this.consumerService.getConsumerAccount().then(consumerAccount => {
                    this.consumerAccount = consumerAccount;
                    this.setProfile();
                });
            });
        });
    }

    private setProfile(): void {
        this.consumerService.getProfileCompleteness().then(completeness => {
            this.componentService.contentService.content$
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe(async (content: any) => {
                    const profileCompleteness: IProfileCompleteness = {
                        percentComplete: ComponentService.toDecimal(completeness.percentComplete * 100, 0),
                        nextFeatureName: !!completeness.nextFeatureName ? this.componentService.contentService.tryGetContentItem(content, 'profile', 'profileFeature', completeness.nextFeatureName).text : null,
                        nextFeaturePath: completeness.nextFeaturePath
                    };

                    let deliveryMethod;
                    let smsDelivery;

                    if (this.domainInfo.useConsolidatedCommunication) {
                        const preferences = await this.consumerService.getConsumerCommunicationPreferences(
                            this.consumerAccount.customerAccountID);
                        deliveryMethod = this.consumerService.convertCommunicationPreferencesToWords(preferences);
                        smsDelivery = preferences.statementSmsEnabled;
                    } else {
                        deliveryMethod = this.consumerAccount.deliveryMethod;
                        smsDelivery = this.consumerAccount.smsDelivery;
                    }

                    this.profile = {
                        profileCompleteness,
                        deliveryMethod,
                        smsNotifications: smsDelivery,
                        mySecureWallet: this.defaultWallet
                    };

                    this.loading = false;
                });
        }).catch(err => {
            this.loading = false;
            this.componentService.loggingService.log('Error loading the Profile Widget', LoggingLevel.error, err);
        });
    }

    private showProfileAvatar(): boolean {
        if (!!this.profile && !!this.profile.profileCompleteness) {
            return this.profile.profileCompleteness.percentComplete === 100;
        }

        return false;
    }

    private setDefaultWallet(): void {
        if (!!this.walletMethods && this.walletMethods.length > 1) {
            const temp: PaymentMethod[] = this.walletMethods.filter(x => x.primary === true);

            // return the one marked as primary, or the first in the list if none of them are marked primary
            if (!!temp && temp.length > 0) {
                this.defaultWallet = temp[0];
            } else {
                this.defaultWallet = null;
            }
        } else {
            this.defaultWallet = null;
        }
    }

    private updateWallet(wallet: PaymentMethod[]): void {
        this.walletMethods = wallet;
        this.setDefaultWallet();
    }

    getInitials() {
        if (!!this.consumer) {
            const initials = this.consumer.firstName.substr(0, 1) + this.consumer.lastName.substr(0, 1);

            return initials;
        }

        return '';
    }

    isLoading(): boolean {
        return this.loading;
    }
}
