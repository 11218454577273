import { Component, EventEmitter, NgZone, OnInit, Output, ViewChild } from '@angular/core';

@Component({
    selector: 'recaptcha-component',
    template: require('./recaptcha.component.html'),
    styles: [require('./recaptcha.component.css')]
})

export class RecaptchaComponent {
    @Output() captchaResponse = new EventEmitter<string>();
    @Output() captchaExpired = new EventEmitter();
    @Output() loaded = new EventEmitter<boolean>();

    isDisplayed: boolean;

    constructor(private zone: NgZone) {
        // The callback needs to exist before the API is loaded
        if (typeof window !== 'undefined') {
            window['onloadCallback' as any] = (() => zone.runOutsideAngular(this.onloadCallback.bind(this))) as any;
        }
    }

    displayRecaptcha() {
        if (!this.isDisplayed) {
            const doc = document.body as HTMLDivElement;
            const script = document.createElement('script');
            script.innerHTML = '';
            script.src = 'https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit';
            script.async = true;
            script.defer = true;
            doc.appendChild(script);
            this.isDisplayed = true;
        }
    }

    onloadCallback() {
        (window as any).grecaptcha.render('html_element', {
            sitekey: '6Lc-AigUAAAAADSsb3Ks1FCrPRX2RJCy34DE8dov',
            callback: (token: string) => this.recaptchaCallback(token),
            'expired-callback': () => this.recaptchaExpiredCallback()
        });
    }

    public recaptchaCallback(response: string) {
        this.captchaResponse.emit(response);
    }

    public recaptchaExpiredCallback() {
        this.captchaExpired.emit();
    }

    public reset() {
        if (typeof window !== 'undefined') {
            window['onloadCallback' as any] = (() => this.zone.runOutsideAngular(this.onloadCallback.bind(this))) as any;
            this.zone.runOutsideAngular((window as any).grecaptcha.reset.bind());
        }
    }
}
