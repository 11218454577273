import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IconModule } from '../Icon/icon.module';
import { CheckboxComponent } from './checkbox.component';

@NgModule({
    imports: [CommonModule, IconModule, FormsModule],
    declarations: [CheckboxComponent],
    exports: [CheckboxComponent]
})

export class CheckboxModule {}
