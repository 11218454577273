export class ConsumerActivityRequest {
    customerAccountID: string;
    numberOfResults: number;
    fromDate: Date;
    toDate: Date;

    UrlAccessKey: string;
    path: string;
    requireCredentials: boolean;
    bypass: boolean;
}
