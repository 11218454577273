/* tslint:disable:triple-equals */
import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PayableItem, PayableItemType } from '../../../models/payableitem';
import { ComponentService } from '../../../services/component/component.service';
import { CheckboxComponent } from '../../Controls/Checkbox/checkbox.component';

@Component({
    selector: 'payable-item',
    template: require('./payableitem.component.html'),
    styles: [require('./payableitem.component.css')]
})
export class PayableItemComponent implements OnInit, OnDestroy {

    constructor(
        private componentService: ComponentService,
        private datePipe: DatePipe,
        private currencyPipe: CurrencyPipe,
        private parentRouter: Router,
        private ngZone: NgZone
    ) { }

    @Input() payableItem: PayableItem;
    @Input() currentBalanceDisclaimer: string;
    @Input() isOneTimePayment = false;
    @Input() headerText: string = null;
    @Input() paymentInputTabIndex = 0;
    @Input() anyAccountPaymentPlanEligible = false;
    detailsExpanded = false;
    arrowRotateDegrees = 0;
    isDisabled = false;
    payableItemCheckboxName: string;
    merchantProfileLoaded = false;
    paymentAmountErrorMessage: string;
    invalidAmountError: string;
    dueDateText: string;
    exceedStatementBalance: string;
    paymentPlanWarningText: string;
    paymentPlanEligibilityLinkText: string;
    paymentPlansAllowedForDomain: boolean;
    quickPayBalanceDetails = false;

    private ngUnsubscribe: Subject<any> = new Subject();

    // set up output calls to PayableStatementListComponent
    @Output() sumTotalEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() payToItemCheckedEvent: EventEmitter<PayableItem> = new EventEmitter<PayableItem>();
    @Output() emitOverPaymentEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit() {
        this.componentService.contentService.content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((content: any) => {
                this.invalidAmountError = this.componentService.contentService.tryGetContentItem(content, 'home', 'error', 'homePagePaymentAmountError').text;
                this.exceedStatementBalance = this.componentService.contentService.tryGetContentItem(content, 'home', 'payment', 'payableStatementExceedsStatementBalanceText').text;
                this.paymentPlanWarningText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'loggedInPayment', 'paymentPaymentPlanPendingWarning').text;

                if (this.isOneTimePayment) {
                    this.dueDateText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'oneTimePayment', 'paymentDueDateLabel').text;
                } else {
                    this.dueDateText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'loggedInPayment', 'paymentDueDateLabel').text;
                }

                this.paymentPlanEligibilityLinkText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'pageText', 'paymentPaymentPlanEligibilityLinkText').text;
            });
        this.payableItemCheckboxName = 'payToItem' + this.payableItem.id;
        this.setErrorMessages();

        this.componentService.domainService.domainInfo$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
            domainInfo => {
                this.paymentPlansAllowedForDomain = domainInfo && domainInfo.enablePaymentPlans && this.anyAccountPaymentPlanEligible;
                this.quickPayBalanceDetails = domainInfo && domainInfo.enableQuickPayBalanceDetails;
            });
    }

    /**
     * Triggered on change of the Payment Amount input field and its model value
     *
     * @private
     *
     * @memberof PayableItemComponent
     */
    private paymentAmountChange() {
        this.sumTotalEvent.emit(true);
        this.setErrorMessages();
        if (!!this.payableItem.paymentAmount) {
            this.payableItem.payToItem = true;
            this.payToItemCheckedEvent.emit(this.payableItem);
        } else {
            this.payableItem.payToItem = false;
            this.clearPayableItem();
            this.payToItemCheckedEvent.emit(this.payableItem);
        }
    }
    /**
     * Returns the merchant profile setting for how many account characters should be hidden.
     *
     * @returns {number}
     * @memberof PayableItemComponent
     */
    hideAccountChars(): number {
        return this.payableItem.merchantProfile != null ? this.payableItem.merchantProfile.hideAccountChars : 0;
    }

    /**
     * Called by checkbox component when the checkbox is clicked
     *
     * @param {CheckboxComponent} checkboxTarget
     *
     * @memberOf PayableItemComponent
     */
    public checkboxChecked(checkboxTarget: CheckboxComponent = null) {
        this.checkOverPayment();
        let payableItemClicked: PayableItem;
        if (checkboxTarget != null) {
            payableItemClicked = checkboxTarget.value as PayableItem;
        } else {
            payableItemClicked = this.payableItem;
        }

        if (this.payableItem.payToItem && (this.payableItem.paymentAmount == null || this.payableItem.paymentAmount <= 0)) {
                this.payableItem.paymentAmount = this.payableItem.payableAmount;
            }
        if (!this.payableItem.payToItem) {
            this.payableItem.paymentAmount = null;
        }
        this.payToItemCheckedEvent.emit(payableItemClicked);
    }

    /**
     * Clears this payableItem input value, checkbox setting
     *
     *
     * @memberof PayableItemComponent
     */
    public clearPayableItem() {
        this.payableItem.payToItem = false;
        this.payableItem.paymentAmount = null;
    }

    /**
     * Sets up the error messages based on the merchant profile settings. Must be tolerant of the merchantProfile not being set.
     *
     * @public
     *
     * @memberof PayableItemComponent
     */
    public setErrorMessages(): void {
        this.checkOverPayment();
        if (!!this.payableItem.merchantProfile && !this.merchantProfileLoaded) {
            this.paymentAmountErrorMessage = this.invalidAmountError;
            this.merchantProfileLoaded = true;
        } else if (!this.merchantProfileLoaded) {
            this.paymentAmountErrorMessage = this.invalidAmountError;
        }
    }

    /**
     * Triggered when user taps/clicks on the payable row typically on mobile.
     *
     * @memberof PayableItemComponent
     */
    payToItem() {
        if (this.componentService.isMobileBrowser()) {
            this.payableItem.payToItem = !this.payableItem.payToItem;
            this.checkboxChecked(null);
        }
    }

    /**
     * Returns true when the data contained in the itemDescription field needs to be obfuscated.
     * Called by the HTML for each item in the ngFor
     *
     * @param {PayableItem} item
     * @returns {boolean}
     * @memberof PayableItemComponent
     */
    private descriptionNeedsObfuscation(item: PayableItem): boolean {
        return item.itemType == PayableItemType.paymentplanbalance;
    }

    /**
     * Ensures the max validation is never < 0 which would show the field is always invalid once touched.
     *
     * @public
     * @returns {number}
     *
     * @memberof PayableItemComponent
     */
    public paymentAmountMax(): number {
        if (this.payableItem.maxPayableAmount != null) {
            return this.payableItem.maxPayableAmount;
        } else if (this.payableItem.merchantProfile != null && this.payableItem.merchantProfile.maxOverpayment != null) {
            // currently have no payment items that should use this value, but leaving in for future payable things
            return this.payableItem.merchantProfile.maxOverpayment + this.payableItem.payableAmount;
        }
        return this.payableItem.payableAmount;
    }

    /**
     * Selects the whole number in the input field so the user can type a new number after checking pay and having the amount prefill.
     *
     * @param {*} $event
     *
     * @memberOf PayableItemComponent
     */
    private selectAllInputContent(event: any) {
        if (this.payableItem.payToItem) {
            event.target.select();
        }
    }

    /**
     * Triggered from the HTML by clicking the "expand" arrow for bucket details
     *
     * @private
     * @returns boolean
     *
     * @memberof PayableItemComponent
     */
    private toggleDetails() {
        this.detailsExpanded = !this.detailsExpanded;
        this.arrowRotateDegrees = Math.abs(this.arrowRotateDegrees - 180);
    }

    private goToPaymentPlans() {
        this.ngZone.run(() => this.parentRouter.navigateByUrl('/paymentplans/new')).then();
    }

    /**
     * Determines if the date column should be displayed
     *
     * @private
     * @returns boolean
     *
     * @memberof PayableItemComponent
     */
    showPostDate(): boolean {
        if (this.merchantProfileLoaded) {
            return this.payableItem.merchantProfile.enableDateColumn;
        }
        return true;
    }

    /**
     * The balance should be displayed if it is lower than the paymentAmount.
     *
     * @public
     * @returns number
     *
     * @memberof PayableItemComponent
     */
    public getNextPayment(): number {
        if (this.payableItem.payableAmount < this.payableItem.maxPayableAmount) {
            if (this.payableItem.initialPayment) {
                return this.payableItem.initialPaymentAmount;
            } else {
                return this.payableItem.payableAmount;
            }
        }
        return this.payableItem.maxPayableAmount;
    }

    checkOverPayment() {
        if (this.payableItem.paymentAmount === 0 || (this.payableItem.paymentAmount > this.paymentAmountMax())) {
            this.emitOverPaymentEvent.emit(false);
        }
        else {
            this.emitOverPaymentEvent.emit(true);
        }
    }

    emitOverPayment(canMakePayment: boolean): void {
        this.emitOverPaymentEvent.emit(canMakePayment);
    }

}
