import { IPaymentDetail } from './paymentdetail';

export const TEST_CONSUMER_PAYMENT_GUID = 'stuff';

export class IPayment {
    consumerAccountID: number;
    consumerPaymentID: number;
    consumerPaymentGUID: string;
    status: string;
    paymentDate: Date;
    accountName: string;
    customerAccountID: string;
    totalAmount: number;
    source: string;
    class: string;
    sessionID: string;
    customerID: number;
    domainID: number;
    LOB: string;
    merchantProfileID: number;
    merchantProfileGUID: string;
    site: string;
    accountNumber: string;
    method: string;
    type: string;
    cardEntryMethod: string;
    cardType: string;
    paymentType: string;
    TransactionID: string;
    paymentCode: string;
    paymentMethodLast4: string;
    comment: string;
    paymentLocationGUID: string;
    confirmationEmail: string;
    details: IPaymentDetail[];
}

export const TEST_PAYMENT: IPayment = {
    consumerAccountID: 123,
    consumerPaymentID: 123,
    consumerPaymentGUID: TEST_CONSUMER_PAYMENT_GUID,
    status: 'stuff',
    paymentDate: new Date('2017-08-01'),
    accountName: 'stuff',
    customerAccountID: 'stuff',
    totalAmount: 0,
    source: 'stuff',
    class: 'stuff',
    sessionID: 'stuff',
    customerID: 0,
    domainID: 0,
    LOB: 'stuff',
    merchantProfileID: 0,
    site: 'stuff',
    accountNumber: 'string',
    method: 'string',
    type: 'string',
    cardEntryMethod: 'string',
    cardType: 'visa',
    paymentType: 'string',
    TransactionID: 'string',
    paymentCode: 'string',
    paymentMethodLast4: 'string',
    comment: 'string',
    paymentLocationGUID: '123',
    confirmationEmail: 'string',
    details: []
} as IPayment;

export const TEST_REFUND_PAYMENT: IPayment = {
    details: [
        { amount: -1 },
        { amount: -1 }
    ]
} as IPayment;

export const TEST_50_DOLLAR_PAYMENT: IPayment = {
    details: [
        {
            amount: 50,
            documentID: 1
        }
    ]
} as IPayment;

export const TEST_500_DOLLAR_PAYMENT: IPayment = {
    details: [
        {
            amount: 500,
            documentID: 1
        }
    ]
} as IPayment;

export const TEST_HISTORY_ARRAY: IPayment[] = [
    Object.assign({}, TEST_PAYMENT),
    Object.assign({}, TEST_PAYMENT)
];

export const TEST_HISTORY_REFUND_ARRAY: IPayment[] = [
    Object.assign({}, TEST_REFUND_PAYMENT)
];

export const TEST_50_DOLLAR_PAYMENT_ARRAY: IPayment[] = [
    Object.assign({}, TEST_50_DOLLAR_PAYMENT)
];

export const TEST_500_DOLLAR_PAYMENT_ARRAY: IPayment[] = [
    Object.assign({}, TEST_500_DOLLAR_PAYMENT)
];
