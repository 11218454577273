import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IDomainInfo } from '../../../models/domaininfo';
import { ComponentService } from '../../../services/component/component.service';

@Component({
    selector: 'recurring-plans-widget',
    template: require('./recurringwidget.component.html'),
    styles: [require('./recurringwidget.component.css')]
})
export class RecurringWidgetComponent implements OnInit, OnDestroy {
    recurringWidgetHeadlineText: string;
    recurringWidgetLinkText: string;
    recurringRoute = '/recurring';
    domainInfo: IDomainInfo;

    private ngUnsubscribe: Subject<any> = new Subject();

    constructor(private componentService: ComponentService) { }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit() {
        // Called after the constructor, initializing input properties, and the first call to ngOnChanges.
        // Add 'implements OnInit' to the class.
        this.componentService.contentService.content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((content: any) => {
                this.recurringWidgetHeadlineText = this.componentService.contentService.tryGetContentItem(content, 'home', 'recurring', 'homePageRecurringPaymentPlansHeadline').text;
                this.recurringWidgetLinkText = this.componentService.contentService.tryGetContentItem(content, 'home', 'recurring', 'homePageRecurringPaymentPlansLink').text;
            });

        this.componentService.domainService.domainInfo$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(domainInfo => {
                this.domainInfo = domainInfo;
        });
    }

    private isLinkDisabled(): boolean {
        return !!this.domainInfo && !this.domainInfo.enableWallet;
    }
}
