import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ComponentService } from '../../../services/component/component.service';

@Component({
    selector: 'statement-history-widget',
    template: require('./statementhistorywidget.component.html'),
    styles: [require('./statementhistorywidget.component.css')]
})
export class StatementHistoryWidgetComponent implements OnInit, OnDestroy {

    statementHistoryHeadlineText: string;
    statementHistoryLinkText: string;
    statementsRoute = '/statements';

    private ngUnsubscribe: Subject<any> = new Subject();

    constructor(private componentService: ComponentService) {
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit() {
        this.componentService.contentService.content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((content: any) => {
                this.statementHistoryHeadlineText = this.componentService.contentService
                    .tryGetContentItem(content, 'home', 'payment', 'homePageStatementHistoryHeadline').text;

                this.statementHistoryLinkText = this.componentService.contentService
                    .tryGetContentItem(content, 'home', 'payment', 'homePageStatementHistoryLink').text;
            });
    }
}
