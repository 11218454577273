import { AutopayPaymentStatus } from './document';
import { PayableItem, PayableItemType } from './payableitem';
import { PaymentBucket } from './paymentbucket';

export class PayableStatement extends PayableItem {
    paymentBuckets: PaymentBucket[];
    documentID: number;
    documentGUID: string;
    promptPayAmount: number;
    promptPayDueDate: Date;
    autopayPaymentStatus: AutopayPaymentStatus;
    externalPaymentPlanAmount: number;
    dueUponReceipt: boolean;

    constructor() {
        super();
    }
}

export const TEST_PAYABLE_STATEMENT: PayableStatement = {
    id: '1234',
    itemType: PayableItemType.statement,
    itemName: 'testStatement',
    itemDescription: 'test',
    payableAmount: 1,
    postDate: new Date(),
    dueDate: new Date(),
    paymentAmount: 0,
    payToItem: false,
    merchantProfileGUID: 'guid-1-guid',
    merchantProfile: null,
    hasScheduledPayment: false,
    maxPayableAmount: 100,
    merchantCredentialGUID: 'guid-2-guid',
    isAccountPaymentPlanEligible: true,
    displayField: 'Optionally display this value',
    paymentLocationGUID: null,
    paymentBuckets: null,
    documentID: 1,
    documentGUID: 'guid-3-guid',
    promptPayAmount: null,
    promptPayDueDate: null,
    autopayPaymentStatus: AutopayPaymentStatus.none,
    externalPaymentPlanAmount: null,
    initialPayment: null,
    initialPaymentAmount: 0,
    dueUponReceipt: false
};
