/* tslint:disable:triple-equals */
import { CurrencyPipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IConsumer } from '../../../models/consumer';
import { IDomainInfo } from '../../../models/domaininfo';
import { OtherPaymentMethod, PaymentMethod, PaymentMethodType } from '../../../models/paymentmethod';
import { PaymentMethodAssignmentSource } from '../../../models/paymentmethodassignmentsource';
import { IPaymentPlanDetails } from '../../../models/paymentplandetails';
import { DateFormatPipe } from '../../../pipes/DateFormat/dateformat.pipe';
import { ComponentService } from '../../../services/component/component.service';
import { ConsumerService } from '../../../services/consumer/consumer.service';
import { PaymentPlanService } from '../../../services/paymentplan/paymentplan.service';
import { ConfirmationModalComponent } from '../../Controls/ConfirmationModal/confirmationmodal.component';

@Component({
    selector: 'payment-plan-detail',
    template: require('./paymentplandetail.component.html'),
    styles: [require('./paymentplandetail.component.css')],
})
export class PaymentPlanDetailComponent implements OnInit, OnDestroy {
    private ngUnsubscribe: Subject<any> = new Subject();

    @Input() paymentPlan: IPaymentPlanDetails;
    @Input() consumer: IConsumer;
    @ViewChild('termsAndConditions', { static: false }) termsAndConditionsModal: ConfirmationModalComponent;
    @ViewChild('addPaymentMethodModal', { static: true }) addPaymentMethodModal: ConfirmationModalComponent;
    @ViewChild('removePaymentMethodModal', { static: false }) removePaymentMethodModal: ConfirmationModalComponent;

    detailsExpanded = false;
    detailArrowRotateDegrees = 0;
    editButtonText = '';
    paymentMethods: PaymentMethod[] = [];
    domainInfo: IDomainInfo;
    showLoadingSpinner = false;

    description = '';
    planTitle = '';
    remainingBalanceText = '';
    remainingBalanceContentText: string;
    planText: string;
    monthsText: string;
    termsAndConditionsContent: SafeHtml;
    termsAndConditionsModalTitle: string;
    termsAndConditionsLink: string;
    monthlyPaymentAmountText: string;
    nextPaymentText: string;
    paymentMethodText: string;
    noPaymentMethodText: string;
    removePaymentMethodLinkText: string;
    addPaymentMethodModalContent: string;
    addPaymentMethodModalTitle: string;
    removePaymentMethodModalContent: string;
    removePaymentMethodModalTitle: string;

    constructor(
        private componentService: ComponentService,
        private consumerService: ConsumerService,
        private paymentPlanService: PaymentPlanService,
        private currencyPipe: CurrencyPipe,
        private datePipe: DateFormatPipe,
    ) { }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit(): void {
        this.componentService.contentService.content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((content: any) => {
                this.editButtonText = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'button', 'paymentPlanDetailsEditButton').text;
                this.remainingBalanceContentText = this.componentService.contentService.tryGetContentItem(
                    content, 'paymentplan', 'pageText', 'paymentPlanRemainingBalanceText').text;

                this.planText = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'pageText', 'paymentPlanPlansText').text;
                this.monthsText = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'pageText', 'paymentPlanMonthsText').text;
                this.termsAndConditionsModalTitle = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'pageText', 'paymentPlanTermsAndConditionsModalTitle').text;
                this.termsAndConditionsLink = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'pageText', 'paymentPlanTermsAndConditionsModalLink').text;
                this.monthlyPaymentAmountText = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'pageText', 'paymentPlanDetailMonthlyPaymentAmountText').text;
                this.nextPaymentText = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'pageText', 'paymentPlanDetailNextPaymentText').text;
                this.paymentMethodText = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'pageText', 'paymentPlanDetailPaymentMethodText').text;
                this.noPaymentMethodText = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'pageText', 'paymentPlanDetailNoPaymentMethodText').text;
                this.removePaymentMethodLinkText = this.componentService.contentService.tryGetContentItem(
                    content, 'paymentplan', 'pageText', 'paymentPlanRemovePaymentMethodLinkText').text;

                this.addPaymentMethodModalContent = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'modal', 'paymentPlanAddPaymentMethodModalContent').text;
                this.addPaymentMethodModalTitle = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'modal', 'paymentPlanAddPaymentMethodModalTitle').text;
                this.removePaymentMethodModalContent = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'modal', 'paymentPlanRemovePaymentMethodModalContent').text;
                this.removePaymentMethodModalTitle = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'modal', 'paymentPlanRemovePaymentMethodModalTitle').text;

                // fill in !! values
                const amount = this.currencyPipe.transform(this.paymentPlan.paymentAmount, 'USD', 'symbol');
                this.addPaymentMethodModalContent = this.addPaymentMethodModalContent.replace('!AMOUNT!', amount);
                this.removePaymentMethodModalContent = this.removePaymentMethodModalContent.replace('!AMOUNT!', amount);

                const date = this.paymentPlan.expectedPaymentDate != null ? this.datePipe.transform(this.paymentPlan.expectedPaymentDate, 'MMMM D') : '';
                this.addPaymentMethodModalContent = this.addPaymentMethodModalContent.replace('!NEXTPAYMENTDATE!', date);
                this.removePaymentMethodModalContent = this.removePaymentMethodModalContent.replace('!NEXTPAYMENTDATE!', date);

                this.componentService.contentService.getMerchantProfileContentItems(
                    this.paymentPlan.merchantProfileGUID,
                    'phrase',
                    null,
                    'TermsAndConditionsExistingPlanMSB'
            ).then(merchantContentItem => {
                if (!!merchantContentItem) {
                    const termsAndConditionsContent = merchantContentItem.text;

                    this.getPaymentPlanDetailsTerms(termsAndConditionsContent);
                }
            });
        });

        this.updatePaymentMethods(null);

        this.componentService.domainService.domainInfo$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(domainInfo => {
            this.domainInfo = domainInfo;
        });
        this.planTitle = this.planText + ' ' + this.paymentPlan.sequentialIdentifier;
        this.remainingBalanceText = this.currencyPipe.transform(
            this.paymentPlan.balance, 'USD', 'symbol') + ' ' + this.remainingBalanceContentText;
        this.description = this.componentService.monthDiff(this.paymentPlan.firstDate, this.paymentPlan.lastDate) + ' ' + this.monthsText + ':';
    }

    updatePaymentMethods(walletToken: string) {
        this.showLoadingSpinner = true;
        this.consumerService.getMerchantProfileByMerchantProfileGUID(this.paymentPlan.merchantProfileGUID).then(merchantProfile => {
            this.consumerService.getWalletPaymentMethodsByMerchant(merchantProfile.merchantCredentialGUID).then(wallet => {
                if (!!wallet) {
                    this.paymentMethods = wallet;
                    if (this.domainInfo && this.domainInfo.enableWallet) {
                        this.paymentMethods.push(OtherPaymentMethod[0]);
                    }
                }

                if (walletToken) {
                    this.changePaymentMethod(walletToken);
                }

                this.showLoadingSpinner = false;
            });
        });
    }

    togglePlanDetails(): void {
        this.detailsExpanded = !this.detailsExpanded;
        this.detailArrowRotateDegrees = Math.abs(this.detailArrowRotateDegrees - 180);
    }

    /**
     * Called when the user changes payment method in paymentMethodManager
     * Passes in the GUID for the paymentMethod to be changed
     *
     * @param walletGUID {string}
     * @memberof PaymentPlanDetailComponent
     */
    changePaymentMethod(walletGUID: string): void {
        // if the user previously didn't have a payment method on the plan, they need to go thru the addPaymentMethodModal process
        if (!this.paymentPlan.consumerPaymentMethod) {
            this.openAddPaymentMethodModal(walletGUID);
        } else {
            this.setPaymentMethod(walletGUID);
        }
    }

    setPaymentMethod(walletGUID: string): void {
        const savedPaymentMethod = this.paymentPlan.consumerPaymentMethod;
        this.paymentPlan.consumerPaymentMethod = this.paymentMethods.filter(x => x.tokenGUID == walletGUID)[0];
        this.paymentPlan.paymentMethodAssignmentSource = PaymentMethodAssignmentSource.SelfService;

        // Call the API to save the payment method for this payment plan here
        this.showLoadingSpinner = true;
        this.paymentPlanService.setPaymentMethodForPlan(
            this.paymentPlan.customerAccountID,
            this.paymentPlan.consumerPaymentMethod.tokenGUID,
            this.paymentPlan.paymentPlanGUID).then(success => {
            if (success != null) {
                this.showLoadingSpinner = false;
                if (!success) {
                    this.paymentPlan.consumerPaymentMethod = savedPaymentMethod;
                }
            }
        }).catch(err => {
            this.showLoadingSpinner = false;
            this.paymentPlan.consumerPaymentMethod = savedPaymentMethod;
        });
    }

    removePaymentMethod(event: any): void {
        this.showLoadingSpinner = true;
        this.paymentPlanService.removePaymentMethodForPlan(this.paymentPlan).then(success => {
            if (success != null) {
                this.showLoadingSpinner = false;
                if (!success) {
                    // generic error message
                } else {
                    this.paymentPlan.consumerPaymentMethod = null;
                }
            }
        }).catch(err => {
            this.showLoadingSpinner = false;
        });
    }

    getWalletMethodDescription(): string {
        if (this.paymentPlan.consumerPaymentMethod == null) {
            return '';
        }
        if (this.paymentPlan.consumerPaymentMethod.description != null && this.paymentPlan.consumerPaymentMethod.description.trim() != '') {
            return this.paymentPlan.consumerPaymentMethod.description;
        }
        if (this.paymentPlan.consumerPaymentMethod.account != null && this.paymentPlan.consumerPaymentMethod.account.trim() != '') {
            return this.paymentPlan.consumerPaymentMethod.account;
        }
        return '';
    }

    showLogoBorder(): boolean {
        return !!this.paymentPlan && this.paymentPlan.consumerPaymentMethod
            && this.paymentPlan.consumerPaymentMethod.paymentMethodType != PaymentMethodType.unknown;
    }

    private openTermsModal(): void {
        this.termsAndConditionsModal.openModal();
    }

    private getPaymentPlanDetailsTerms(termsAndConditionsContent: string): void {
        this.paymentPlanService.getPaymentPlanDetailsTerms(
            this.paymentPlan.customerAccountID,
            this.paymentPlan.paymentPlanGUID
        ).then((plan) => {
            const htmlContent = this.componentService.formatTermsAndConditions(termsAndConditionsContent, plan);
            this.termsAndConditionsContent = this.componentService.BypassSecurityTrustHtml(htmlContent);
        });
    }

    private openAddPaymentMethodModal(walletGUID: string): void {
        this.addPaymentMethodModal.openModal(walletGUID);
    }

    private openRemovePaymentMethodModal(): void {
        this.removePaymentMethodModal.openModal();
    }

    /**
     * The balance should be displayed if it is lower than the expectedPaymentAmount.
     *
     * @public
     * @returns number
     *
     * @memberof PaymentPlanDetailComponent
     */
    public getMonthlyPayment(): number {
        if (this.paymentPlan.expectedPaymentAmount < this.paymentPlan.balance) {
            return this.paymentPlan.expectedPaymentAmount;
        }
        return this.paymentPlan.balance;
    }

}
