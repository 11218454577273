/* tslint:disable:triple-equals */
import { CurrencyPipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IDomainInfo } from '../../../models/domaininfo';
import { IMerchantProfile } from '../../../models/imerchantprofile';
import { IPaymentLocation } from '../../../models/PaymentLocation';
import { ComponentService } from '../../../services/component/component.service';
import { ConsumerService } from '../../../services/consumer/consumer.service';
import { ModifiedAccountPaymentStateService } from '../../../services/modifiedaccountpayment/modifiedaccountpayment.state.service';

@Component({
    selector: 'modified-account-payment-entry',
    template: require('./modifiedaccountpaymententry.component.html'),
    styles: [require('./modifiedaccountpaymententry.component.css')]
})
export class ModifiedAccountPaymentEntryComponent implements OnInit, OnDestroy {
    @Input() paymentAmountEditable = true;
    facilityText: string;
    nameOnAccountText: string;
    paymentAmountText: string;
    customerAccountIdText: string;
    commentsText: string;
    selectALocationText: string;
    paymentAmountError: boolean;
    paymentAmountErrorMessage: string;
    facilityErrorText: string;
    merchantProfile: IMerchantProfile;
    requiredText: string;
    invalidText: string;
    locationListError: boolean;
    nameOnAccountError: boolean;
    domainInfo: IDomainInfo;
    maxCCPaymentErrorText: string;
    maxCCPaymentTextToModify: string;
    phoneNumberLabel = '';

    private ngUnsubscribe: Subject<any> = new Subject();

    constructor(
        public stateService: ModifiedAccountPaymentStateService,
        private componentService: ComponentService,
        private consumerService: ConsumerService,
        private currencyPipe: CurrencyPipe
    ) { }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit() {
        this.componentService.contentService.content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((content: any) => {
                this.facilityText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'accountPayment', 'facilityTitleLabel').text;
                this.nameOnAccountText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'accountPayment', 'nameOnAccountTitleLabel').text;
                this.paymentAmountText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'accountPayment', 'paymentAmountTitleLabel').text;
                this.customerAccountIdText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'accountPayment', 'accountNumberLabel').text;
                this.commentsText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'accountPayment', 'commentsTitleLabel').text;
                this.selectALocationText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'accountPayment', 'selectALocationLabel').text;
                this.paymentAmountErrorMessage = this.componentService.contentService.tryGetContentItem(content, 'error', 'accountPayment', 'paymentAmountAmountRequiredErrorText').text;
                this.facilityErrorText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'accountPayment', 'facilityRequiredLabel').text;
                this.maxCCPaymentTextToModify = this.componentService.contentService.tryGetContentItem(content, 'error', 'accountPayment', 'paymentMaxCreditCardAmountWarningText').text;
                this.requiredText = this.componentService.contentService.tryGetContentItem(
                    content, 'global', 'error', 'isRequiredError').text;

                this.invalidText = this.componentService.contentService.tryGetContentItem(
                    content, 'global', 'error', 'isInvalidError').text;

                this.phoneNumberLabel = this.componentService.contentService.tryGetContentItem(content, 'payment', 'accountPayment', 'phoneNumberLabel').text;
            });

        this.componentService.domainService.domainInfo$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(domainInfo => {
            this.domainInfo = domainInfo;
        });

        this.stateService.facilityUpdate$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(facility => {
            if (facility != null) {
                this.updateFacility(facility);
            }
        });

        // Force Validate gets called by ModifiedAccountPaymentAmount page when user clicks "Continue"
        this.stateService.forceValidateAmountStep$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(update => {
            if (update) {
                this.stateService.amountStepValid = this.accountEntryIsValid();
            }
        });
    }

    updateFacility(location: IPaymentLocation) {
        if (location == null) {
            return;
        }
        this.consumerService.getMerchantProfileByMerchantProfileGUID(location.merchantProfileGUID).then(merchantProfile => {
            this.merchantProfile = merchantProfile;
            if (merchantProfile != null) {
                this.stateService.merchantProfile = merchantProfile;
                this.maxCCPaymentErrorText = this.maxCCPaymentTextToModify.replace(
                    '{0}', this.currencyPipe.transform(merchantProfile.maxCCPaymentAmount, 'USD', 'symbol', '1.2-2'));
            }
        });
        this.stateService.facility = location.paymentLocationGUID;
    }

    locationChange() {
        const location = this.stateService.locationList.find(x => x.paymentLocationGUID == this.stateService.facility);
        if (location == null) {
            return;
        }
        this.consumerService.getMerchantProfileByMerchantProfileGUID(location.merchantProfileGUID).then(merchantProfile => {
            this.merchantProfile = merchantProfile;
            if (merchantProfile != null) {
                this.stateService.merchantProfile = merchantProfile;
                this.maxCCPaymentErrorText = this.maxCCPaymentTextToModify.replace(
                    '{0}', this.currencyPipe.transform(merchantProfile.maxCCPaymentAmount, 'USD', 'symbol', '1.2-2'));
            }
            if (this.stateService.paymentAmount > 0) {
                this.paymentAmountChange();
            }
        });
        this.locationListError = false;
    }

    getRegularExpressionToTest(location: IPaymentLocation): RegExp {
        let regexFlag: string;
        if (location == null) {
            return null;
        }
        let accountRegEx = location.accountNumberRegEx;
        if (location.accountNumberRegEx.endsWith('/i')) {
            accountRegEx = location.accountNumberRegEx.split('/')[0];
            regexFlag = location.accountNumberRegEx.split('/')[1];
        }
        let regularExpression: RegExp;
        if (regexFlag != null) {
            regularExpression = new RegExp(accountRegEx, regexFlag);
        } else {
            regularExpression = new RegExp(accountRegEx);
        }
        return regularExpression;
    }

    accountEntryIsValid(): boolean {
        this.locationListError = false;
        this.nameOnAccountError = false;
        this.paymentAmountError = false;

        if (this.stateService.facility == null) {
            this.locationListError = true;
        }
        if (!this.stateService.nameOnAccount) {
            this.nameOnAccountError = true;
        }
        if (this.stateService.paymentAmount <= 0) {
            this.paymentAmountError = true;
        } else {
            if (this.merchantProfile != null
                && this.merchantProfile.maxCCPaymentAmount > 0
                && this.stateService.paymentAmount > this.merchantProfile.maxCCPaymentAmount) {
                this.paymentAmountError = true;
            }
        }

        // has no errors (all errors false)
        return !(this.locationListError || this.nameOnAccountError || this.paymentAmountError);
    }

    nameChanged(): void {
        this.nameOnAccountError = false;
        if (!this.stateService.nameOnAccount) {
            this.nameOnAccountError = true;
        }
    }

    paymentAmountChange(): void {
        this.paymentAmountError = false;
        if (this.stateService.paymentAmount <= 0) {
            this.paymentAmountError = true;
        }
        if (this.merchantProfile != null
            && this.merchantProfile.maxCCPaymentAmount > 0 &&
            this.stateService.paymentAmount > this.merchantProfile.maxCCPaymentAmount) {
            this.paymentAmountError = true;
        }
    }
}
