import { Component, EventEmitter, Input, Output } from '@angular/core';
import _ = require('lodash');
import { PayableEntry } from '../../../../models/PayableEntry';
import { ComponentService } from '../../../../services/component/component.service';
import { CheckboxComponent } from '../../Checkbox/checkbox.component';

@Component({
    selector: 'select-accounts',
    template: require('./selectaccounts.component.html'),
    styles: [require('./selectaccounts.component.css')]
})

export class SelectAccountsComponent {
    constructor(public componentService: ComponentService) {
    }

    // Input/Output
    @Input() showSelectColumn: boolean;
    @Input() showNotesColumn: boolean;
    @Input() showPostDate: boolean;
    @Input() contentDetailsHeader: string;
    @Input() contentAmountHeader: string;
    @Input() contentSelectHeader: string;
    @Input() contentNotesHeader: string;
    @Input() balances: PayableEntry[];
    @Output() selectedAccountsChange: EventEmitter<PayableEntry[]> = new EventEmitter<PayableEntry[]>();

    public balancesGrouped: _.Dictionary<PayableEntry[]> = null;

    ngOnChanges() {
        this.balancesGrouped = _.groupBy(this.balances, (item) => {
            return new Date(item.postDate).getFullYear();
        });
    }

    public selectionChangeRow(row: any): void {
        if (this.showSelectColumn && this.componentService.isMobileBrowser()) {
            this.balances.filter(b => b === row).map(v => { v.payToItem = !v.payToItem; });
            this.sendBalanceBack();
        }
    }

    public onSelectionChangeCheckbox(event: CheckboxComponent): void {
        if (this.componentService.isMobileBrowser()) {
            // Manually toggle the checkbox state.
            event.value.payToItem = !event.value.payToItem;
        }
        this.sendBalanceBack();
    }

    private sendBalanceBack(): void {
        const balancesFlat = _.flatten(_.map(this.balancesGrouped, _.values));
        this.selectedAccountsChange.emit(balancesFlat);
    }
}
