/**
 * Payment Bucket View Model used in the UI
 *
 * @export
 * @class PaymentBucket
 */
export class PaymentBucket {
    sequence: number;
    bucketId: string;
    bucketDescription: string;
    bucketBalance: number;
    promptPayAmount: number;
    payToBucket: boolean;
    percentAllocation: number;
    bucketPaymentAmount: number;
    accountNumber: string;
    maxPaymentAmount: number;
    minPaymentAmount: number;
    chargeDate: Date;
    bucketInstallmentAmount: number;
    displayBalance: number;
}

export const FloatingBucket: PaymentBucket = {
    sequence: 99999,
    bucketId: 'FLOATER',
    bucketDescription: 'Additional Payment',
    bucketBalance: 0,
    promptPayAmount: null,
    payToBucket: false,
    percentAllocation: 100,
    bucketPaymentAmount: 0,
    accountNumber: '',
    maxPaymentAmount: 99999,
    minPaymentAmount: 0,
    chargeDate: new Date(),
    bucketInstallmentAmount: 0,
    displayBalance: 0
};
