// TODO:
// There is something in linting fixes that break the following flow:
// one-time payment -> confirmation -> /redirect?emailenrollment=1 -> /modifiedenrollment
// If you take on fixing the linting, be sure to test that flow.
// The value we get out of linting fixes may not outweigh breaking that flow.
/* tslint:disable:triple-equals */
import { forwardRef, Inject, Injectable, InjectionToken, Injector } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';

import { AppConfig } from '../../app.config';
import { ConsumerStorageService } from '../indexedDb/consumer-storage.service';
import { LoggingLevel, LoggingService } from '../logging/logging.service';

export enum StorageLevel {
    protected = 0,
    session = 1
}
@Injectable({
    providedIn: 'root'
})
export class StorageService {
    defaultOffset = '24:00:00';
    protectedValidUntilKey = 'protected-valid-until';
    protectedValidUntilFromConfigKey = 'config-setting-protected-valid-until';

    constructor(private config: AppConfig,
                private consumerStorageService: ConsumerStorageService,
                injector: Injector,
                private localStorageService: LocalStorageService,
                @Inject(forwardRef(() => LoggingService)) private loggingService: LoggingService) {
        this.instantiateKeys();
        this.checkValueFromConfigMatchesStorage();
        this.storeStorageInvalidationKeys();
        this.writeDebugInfoToConsole();
    }

    /**
     * Returns a boolean if the key is currently stored in web-storage.
     *
     * @param {string} key
     * @returns {boolean}
     *
     * @memberof StorageService
     */
    public exists(key: string): boolean {
        const existsInStorage = this.retrieve(key) != null;
        this.loggingService.log('checking for ' + key + ' in storage -> found ' + existsInStorage, LoggingLevel.debug);
        return existsInStorage;
    }

    /**
     * Stores the given key/value pair and logs the key in the appropriate list for its given protection level.
     *
     * @param {string} key
     * @param {*} value
     * @param {StorageLevel} [protection=StorageLevel.session]
     *
     * @memberof StorageService
     */
    public store(key: string, value: any, protection: StorageLevel = StorageLevel.session): void {
        const itemKey = key.toLowerCase();
        const keys = this.getKeyStore(protection);
        this.loggingService.log(key + ' is stored ' + protection.toString(), LoggingLevel.debug);
        if (!keys.find((x) => x == itemKey)) {
            keys.push(itemKey);
            this.loadKeyStore(protection, keys);
        }
        this.localStorageService.store(itemKey, value);
    }

    /**
     * Clears the given key from web-storage.
     *
     * @param {string} key
     *
     * @memberof StorageService
     */
    public clear(key: string): void {
        this.loggingService.log('clearing ' + key, LoggingLevel.debug);
        const protectedKeys = this.getKeyStore(StorageLevel.protected);
        const sessionKeys = this.getKeyStore(StorageLevel.session);

        if (protectedKeys.find((x) => x == key)) {
            this.loggingService.log(key + ' no longer protected.', LoggingLevel.debug);
            this.loadKeyStore(StorageLevel.protected, protectedKeys.filter((x) => x != key));
        }
        if (sessionKeys.find((x) => x == key)) {
            this.loggingService.log(key + ' no longer session.', LoggingLevel.debug);
            this.loadKeyStore(StorageLevel.session, sessionKeys.filter((x) => x != key));
        }
        this.localStorageService.clear(key);
    }

    /**
     * Retrieves the provided key:value from localStorage unless the storage has been invalidated.
     *
     * @param {string} key
     * @returns {*}
     *
     * @memberof StorageService
     */
    public retrieve(key: string): any {
        if (!this.protectedStorageIsStillValid()) {
            this.loggingService.log('storage is invalid, burning it all down.', LoggingLevel.debug);
            this.clearProtectedKeysBecauseStorageExpired();
        }

        return this.localStorageService.retrieve(key);
    }

    /**
     * Clears local web-storage of all items stored with a storage level of session.
     *
     *
     * @memberof StorageService
     */
    public clearSession() {
        const sessionKeys = this.getKeyStore(StorageLevel.session);
        this.loggingService.log('clearing all these session keys', LoggingLevel.debug, sessionKeys);
        sessionKeys.forEach((key) => this.clear(key));
        // no need to await here
        this.consumerStorageService.clearConsumerStorage();
        this.loadKeyStore(StorageLevel.session, []);
    }

    public getDomain(): string {
        let domain = window.location.hostname;
        if (domain.includes('localhost') || domain.includes('192.168')) {
            domain = 'demo.mysecurebill-qa1-prod.apexprint.loc';
        }
        if (!this.exists('domain')) {
            this.store('domain', domain, StorageLevel.protected);
        }
        return domain;
    }

    private instantiateKeys(): void {
        if (this.getKeyStore(StorageLevel.protected) == null) {
            this.loadKeyStore(StorageLevel.protected, []);
        }
        if (this.getKeyStore(StorageLevel.session) == null) {
            this.loadKeyStore(StorageLevel.session, []);
        }
    }

    private getKeyStore(level: StorageLevel): string[] {
        return this.localStorageService.retrieve(this.keystoreName(level));
    }

    private loadKeyStore(level: StorageLevel, keys: string[]) {
        this.localStorageService.store(this.keystoreName(level), keys);
    }

    private keystoreName(level: StorageLevel) {
        return StorageLevel[level] + 'keys';
    }

    private storeStorageInvalidationKeys() {
        if (this.localStorageService.retrieve(this.protectedValidUntilFromConfigKey) != null
            && this.localStorageService.retrieve(this.protectedValidUntilKey) != null) {
            this.loggingService.log('keys still there, don\'t store again', LoggingLevel.debug);
            return;
        }

        const validUntil = new Date();
        const today = new Date();
        let configOffset: string[] = this.config.getConfig('invalidateStorageAfter').split(':');

        if (configOffset.length != 3) {
            this.loggingService.log('configured offset in invalid format, using default.', LoggingLevel.debug);
            configOffset = this.defaultOffset.split(':');
        }

        const offsetHours = this.parseNumberOrReturnDefault(configOffset[0], this.defaultOffset.split(':')[0]);
        const offsetMinutes = this.parseNumberOrReturnDefault(configOffset[1], this.defaultOffset.split(':')[1]);
        const offsetSeconds = this.parseNumberOrReturnDefault(configOffset[2], this.defaultOffset.split(':')[2]);

        this.loggingService.log('setting offset: ' + offsetHours + ' ' + offsetMinutes + ' ' + offsetSeconds, LoggingLevel.debug);
        validUntil.setHours(today.getHours() + offsetHours, today.getMinutes() + offsetMinutes, today.getSeconds() + offsetSeconds);

        this.localStorageService.store(this.protectedValidUntilFromConfigKey, configOffset);
        this.localStorageService.store(this.protectedValidUntilKey, validUntil);
    }

    private clearStorageInvalidationKeys() {
        this.localStorageService.clear(this.protectedValidUntilFromConfigKey);
        this.localStorageService.clear(this.protectedValidUntilKey);
    }

    private protectedStorageIsStillValid(): boolean {
        return (new Date(this.localStorageService.retrieve(this.protectedValidUntilKey)) > new Date());
    }

    private parseNumberOrReturnDefault(numberToParse: string, defaultValue: string) {
        return !isNaN(Number(numberToParse)) ? Number(numberToParse) : Number(defaultValue);
    }

    private clearProtectedKeysBecauseStorageExpired() {
        this.clearProtectedKeys();
        this.clearStorageInvalidationKeys();
        this.storeStorageInvalidationKeys();
    }

    private checkValueFromConfigMatchesStorage() {
        const offsetConfig: string = this.config.getConfig('invalidateStorageAfter').split(':').toString();
        const offsetStored: string = this.exists(this.protectedValidUntilFromConfigKey)
            ? this.localStorageService.retrieve(this.protectedValidUntilFromConfigKey)
            : '';

        if (offsetConfig != offsetStored) {
            this.loggingService.log('config does not match stored value, reset storage and set new invalidation keys', LoggingLevel.debug);
            this.clearProtectedKeys();
            this.clearStorageInvalidationKeys();
        } else {
            this.loggingService.log('offset still == config', LoggingLevel.debug);
        }
    }

    private clearProtectedKeys() {
        this.getKeyStore(StorageLevel.protected).forEach((key) => {
            this.clear(key);
        });
        this.loadKeyStore(StorageLevel.protected, []);
    }

    private writeDebugInfoToConsole() {
        this.loggingService.log('storage valid until -> ' +
            new Date(this.localStorageService.retrieve(this.protectedValidUntilKey)), LoggingLevel.debug);
        this.loggingService.log(
            'stored offset from config -> ' + this.localStorageService.retrieve(this.protectedValidUntilFromConfigKey), LoggingLevel.debug);
    }
}


