/* tslint:disable:triple-equals */
import { Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EnrollmentProfileComponent } from '../../components/Controls/EnrollmentProfile/enrollmentprofile.component';
import { IConsumer } from '../../models/consumer';
import { ConsumerAccount } from '../../models/consumeraccount';
import { ConsumerAccountRequest } from '../../models/consumeraccountrequest';
import { IDomainInfo } from '../../models/domaininfo';
import { QuickPayEnrollment } from '../../models/quickpayenrollment';
import { ComponentService } from '../../services/component/component.service';
import { ConsumerService } from '../../services/consumer/consumer.service';

@Component({
    selector: 'modified-enrollment',
    template: require('./modifiedenrollment.component.html'),
    styles: [require('./modifiedenrollment.component.css')]
})

export class ModifiedEnrollmentComponent implements OnInit, OnDestroy {

    constructor(private componentService: ComponentService,
                private parentRouter: Router,
                private consumerService: ConsumerService,
                private ngZone: NgZone) {
    }
    domainInfo: IDomainInfo;
    consumerAccount: ConsumerAccount;
    consumer: IConsumer;
    currentStep = 1;
    token = '';
    errorMessage: string;
    isValid = true;
    stopDoubleclick = false;
    pendingEnrollmentEmail: string;
    pendingEnrollmentError: string;
    loading = false;

    submitButtonText: string;
    headlineText: string;
    subHeadlineText: string;
    enrollment = false;
    enrollmentError = false;
    enrollmentErrorText: string;
    quickPayEnrollment: QuickPayEnrollment;
    thankYouMessage: string;
    enrollmentDescription: string;

    private ngUnsubscribe: Subject<any> = new Subject();

    @ViewChild('enrollmentProfileCmp', { static: true }) public enrollmentProfileCmp: EnrollmentProfileComponent;

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit() {
        this.startLoading();

        // check for quickPayEnrollment object
        if (this.componentService.storageService.exists('quickpayenrollment')) {
            this.quickPayEnrollment = this.componentService.storageService.retrieve('quickpayenrollment');
            this.componentService.storageService.clear('quickpayenrollment');
            this.prefillFromQuickPay();
        }
        this.consumerService.getConsumer(this.componentService.storageService.retrieve('quickpayidentifier')).then(consumer => {
            if (!!consumer) {
                this.consumer = consumer;
                this.consumerService.getConsumerAccount().then(consumerAccount => {
                    if (!!consumerAccount) {
                        this.consumerAccount = consumerAccount;
                    }
                });
            }
        });

        this.componentService.scrollPageToTop();
        this.componentService.contentService.content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((content: any) => {
                this.submitButtonText = this.componentService.contentService.tryGetContentItem(content, 'modifiedenrollment', 'pageText', 'modifiedEnrollmentEnrollButton').text;
                this.headlineText = this.componentService.contentService.tryGetContentItem(content, 'modifiedenrollment', 'pageText', 'modifiedEnrollmentHeadline').text;
                this.subHeadlineText = this.componentService.contentService.tryGetContentItem(content, 'modifiedenrollment', 'pageText', 'modifiedEnrollmentSubHeadline').text;
                this.enrollmentErrorText = this.componentService.contentService.tryGetContentItem(content, 'modifiedenrollment', 'error', 'modifiedEnrollmentPageGeneralError').text;
                this.thankYouMessage = this.componentService.contentService.tryGetContentItem(content, 'modifiedenrollment', 'pageText', 'modifiedEnrollmentThankYouMessage').text;
                this.enrollmentDescription = this.componentService.contentService.tryGetContentItem(content, 'modifiedenrollment', 'pageText', 'modifiedEnrollmentEnrollmentDescription').text;
            });

        this.componentService.domainService.domainInfo$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(domainInfo => {
            this.domainInfo = domainInfo;
            if (this.domainInfo != null) {
                this.initializeEnrollmentForm();
            }
            this.enrollmentProfileCmp.domainInfo = domainInfo;
        });
    }

    initializeEnrollmentForm() {
        if (this.domainInfo.deliveryMode == 'Paper') {
            this.enrollmentProfileCmp.deliveryType = 'Paper';
        } else if (this.domainInfo.deliveryMode == 'Standard') {
            this.enrollmentProfileCmp.deliveryType = 'Electronic';
        }
        this.doneLoading();
    }

    private prefillFromQuickPay(): void {
        this.pendingEnrollmentEmail = this.quickPayEnrollment.email;
    }

    nextStep() {
        this.clearErrors();

        this.validateForm();
        // fall through to final step

        if (this.isValid) {
            // Go to profile completeness page?
            this.enrollment = true;
            this.saveNewAccount();
        }

        this.componentService.scrollPageToTop();
    }

    clearErrors() {
        const inputForm = this.enrollmentProfileCmp;
        this.clearEmailErrors();
        inputForm.deliveryTypeError = false;
    }

    clearEmailErrors() {
        const inputForm = this.enrollmentProfileCmp;
        inputForm.emailMatchError = false;
        inputForm.emailRequiredError = false;
        inputForm.emailValidationError = false;
    }

    isEnrollment(): boolean {
        return this.enrollment;
    }

    private blurComponent(componentName: string) {
        switch (componentName) {
            case 'email':
                this.clearEmailErrors();
                this.validateEmail(true);
                break;
        }
    }


    private saveNewAccount() {
        const inputForm = this.enrollmentProfileCmp;
        const consumerAccountRequest: ConsumerAccountRequest = new ConsumerAccountRequest();
        consumerAccountRequest.CustomerAccountID = inputForm.consumer.accountID;
        consumerAccountRequest.DeliveryMethod = inputForm.deliveryType;
        consumerAccountRequest.EmailAddress = inputForm.email;
        consumerAccountRequest.FirstName = inputForm.consumer.firstName;
        consumerAccountRequest.LastName = inputForm.consumer.lastName;
        consumerAccountRequest.LanguageCode = window.navigator.language;
        consumerAccountRequest.EnrollmentChannel = 'MySecureBill';
        consumerAccountRequest.Username = inputForm.username;
        consumerAccountRequest.Password = this.componentService.generateUUID();
        consumerAccountRequest.AccountType = 'QuickPay';

        this.consumerService.createConsumerAccount(consumerAccountRequest).then((response) => {
            this.processEnrollment(response);
        }).catch((error) => {
            this.enrollment = false;
            this.enrollmentError = true;
        });
    }

    private processEnrollment(response: boolean) {
        if (response != null && response) {
            this.componentService.storageService.clear('enrollment');
            this.componentService.storageService.clear('mem');
            this.finishEnrollment();
        } else if (response != null && !response) {
            this.isValid = false;
            this.enrollment = false;
            // fail message?
        }
    }

    private finishEnrollment() {
        this.componentService.storageService.clearSession();
        this.currentStep = 2;
        this.enrollment = false;
    }

    private validateForm() {
        this.isValid = true;
        this.clearErrors();
        // one letter, one capital and one number
        const inputForm = this.enrollmentProfileCmp;
        this.validateEmail();
        if (!!inputForm.deliveryChoices && inputForm.deliveryChoices.split(';').length == 1) {
            inputForm.deliveryType = inputForm.deliveryChoices.split(':')[0];
        }
        if (!inputForm.deliveryType || (!!inputForm.deliveryType && inputForm.deliveryType.length == 0)) {
            inputForm.deliveryTypeError = true;
            this.isValid = false;
        }
    }

    private validateEmail(fromTyping: boolean = false) {
        const inputForm = this.enrollmentProfileCmp;
        const isConsolidatedCommunicationEnabled = this.domainInfo && this.domainInfo.useConsolidatedCommunication;

        if (!isConsolidatedCommunicationEnabled) {
            if (fromTyping && inputForm.emailConfirm != null) {
                if (inputForm.email != inputForm.emailConfirm) {
                    inputForm.emailMatchError = true;
                    this.isValid = false;
                }
            }
            if (!fromTyping) {
                if (inputForm.email != inputForm.emailConfirm) {
                    inputForm.emailMatchError = true;
                    this.isValid = false;
                }
            }
        }

        if (!inputForm.email || inputForm.email.length == 0) {
            inputForm.emailRequiredError = true;
            this.isValid = false;
        } else if (!this.componentService.emailRegex.test(inputForm.email.toLocaleLowerCase())) {
            inputForm.emailValidationError = true;
            this.isValid = false;
        }
    }

    private redirect(relativeRoute: string) {
        this.stopDoubleclick = false;
        this.ngZone.run(() => this.parentRouter.navigateByUrl(relativeRoute)).then();
    }

    private startLoading() {
        this.loading = true;
    }

    private doneLoading() {
        this.loading = false;
    }

    private isLoading(): boolean {
        return this.loading;
    }

    private isSubmitting(): boolean {
        return this.enrollmentProfileCmp.loading;
    }
}
