import { ChangeDetectorRef, Component, HostListener, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EnrollmentProfileComponent } from '../../components/Controls/EnrollmentProfile/enrollmentprofile.component';
import { RecaptchaComponent } from '../../components/RecaptchaComponent/recaptcha.component';
import { ConsumerAccountRequest } from '../../models/consumeraccountrequest';
import { IDomainInfo } from '../../models/domaininfo';
import { GenerateUsernameRequest } from '../../models/generateusernamerequest';
import { QuickPayEnrollment } from '../../models/quickpayenrollment';
import { Roles } from '../../models/roles';
import { ComponentService } from '../../services/component/component.service';
import { ConsumerService } from '../../services/consumer/consumer.service';
import { ConsumerStorageService } from '../../services/indexedDb/consumer-storage.service';
import { LoggingLevel } from '../../services/logging/logging.service';
import { LoginService } from '../../services/login/login.service';

@Component({
    selector: 'enrollment',
    template: require('./enrollment.component.html'),
    styles: [require('./enrollment.component.css')]
})

export class EnrollmentComponent implements OnInit, OnDestroy {

    constructor(
        private componentService: ComponentService,
        private loginService: LoginService,
        private activatedRoute: ActivatedRoute,
        private parentRouter: Router,
        private consumerService: ConsumerService,
        private cdRef: ChangeDetectorRef,
        private consumerStorageService: ConsumerStorageService,
        private ngZone: NgZone
    ) { }

    domainInfo: IDomainInfo;
    token = '';
    errorMessage: string;
    stepList: any[];
    currentStep = 1;
    finalStep = 2;
    step1Valid = true;
    step2Valid = true;
    extraRequired: boolean;
    memErrorExists = false;
    extraErrorExists = false;
    memCode: string;
    memExtra = '';
    dateOfBirth: string;
    stopDoubleclick = false;
    recaptchaError = false;
    recaptchaAttempted = false;
    recaptchaToken = '';
    queryString: string;
    showPendingEnrollment = false;
    pendingEnrollmentGUID: string;
    pendingEnrollmentEmail: string;
    pendingEnrollmentErrorExists = false;
    pendingEnrollmentError: string;
    firstName: string;
    lastName: string;
    loading = false;
    days: string[] = new Array(31);
    months: string[] = new Array(12);
    years: string[] = new Array(120);
    expirationDay = 'DD';
    expirationMonth = 'MM';
    expirationYear = 'YYYY';
    quickPayEnrollment: QuickPayEnrollment;
    queryStringMEMCode: string;
    email: string;

    nextButtonText: string;
    submitButtonText: string;
    backButtonText: string;
    cancelButtonText: string;
    memCodeText: string;
    memExtraText: string;
    memPlaceholder: string;
    memErrorText: string;
    memErrorMessage: string;
    memMixedErrorMessage: string;
    secondFactorAuthLabel: string;
    headlineText: string;
    subHeadlineText: string;
    memImage: string;
    recaptchaErrorMessage: string;
    enrollment = false;
    enrollmentStep1Error = false;
    enrollmentStep1ErrorText: string;
    enrollmentStep2Error = false;
    enrollmentStep2ErrorText: string;
    enrollmentConsumerAccountExistsErrorText: string;
    isConsolidatedCommunicationEnabled: boolean;
    grecaptcha = (window as any).grecaptcha;

    public secondaryAuthMask: any;

    private ngUnsubscribe: Subject<any> = new Subject();

    @ViewChild('enrollmentProfileCmp', { static: true }) public enrollmentProfileCmp: EnrollmentProfileComponent;
    @ViewChild('recaptchaCmp', { static: false }) recaptchaCmp: RecaptchaComponent;

    /**
     * onPopState listens for the popstate event on the window and performs the actions, this handles the browser back button
     * @see https://stackoverflow.com/questions/40381814/how-do-i-detect-user-navigating-back-in-angular2
     * @param {*} event the event
     * @memberof PaymentComponent
     */
    @HostListener('window:popstate', ['$event'])
    onPopState(event: any) {
        // wrap with this because it messes up unit tests
        if (process.env.ENV !== 'test') {
            history.pushState(false, 'enrollment', 'enrollment');
        }
        this.prevStep();
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit() {
        this.startLoading();
        this.pendingEnrollmentGUID = this.componentService.storageService.retrieve('pendingenrollmentguid');
        this.componentService.scrollPageToTop();
        this.componentService.storageService.clearSession();
        this.componentService.contentService.content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((content: any) => {
                this.nextButtonText = this.componentService.contentService.tryGetContentItem(content, 'enrollment', 'step1', 'enrollmentPageStep1NextButton').text;
                this.cancelButtonText = this.componentService.contentService.tryGetContentItem(content, 'enrollment', 'step1', 'enrollmentPageStep1BackButton').text;
                this.submitButtonText = this.componentService.contentService.tryGetContentItem(content, 'enrollment', 'step2', 'enrollmentPageStep2NextButton').text;
                this.backButtonText = this.componentService.contentService.tryGetContentItem(content, 'enrollment', 'step2', 'enrollmentPageStep2BackButton').text;
                this.memCodeText = this.componentService.contentService.tryGetContentItem(content, 'enrollment', 'step1', 'enrollmentPageMemCode').text;
                this.memExtraText = this.componentService.contentService.tryGetContentItem(content, 'enrollment', 'step1', 'enrollmentPageMemExtra').text;
                this.memPlaceholder = this.memPlaceholder == null ? this.componentService.contentService.tryGetContentItem(
                    content, 'memPayment', 'pageText', 'memPaymentMemPlaceholder').text : this.memPlaceholder;

                this.memErrorMessage = this.componentService.contentService.tryGetContentItem(content, 'enrollment', 'error', 'enrollmentPageMemCodeError').text;
                this.memMixedErrorMessage = this.componentService.contentService.tryGetContentItem(content, 'enrollment', 'error', 'enrollmentPageExtendedMemError').text;
                this.headlineText = this.componentService.contentService.tryGetContentItem(content, 'enrollment', 'step1', 'enrollmentPageStep1Headline').text;
                this.subHeadlineText = this.componentService.contentService.tryGetContentItem(content, 'enrollment', 'step1', 'enrollmentPageStep1SubHeadline').text;
                this.memImage = this.componentService.contentService.tryGetContentItem(content, 'enrollment', 'image', 'enrollmentPageStatementImageUrl').text;
                this.recaptchaErrorMessage = this.componentService.contentService.tryGetContentItem(content, 'enrollment', 'error', 'enrollmentPageRecaptchaError').text;
                this.enrollmentStep2ErrorText = this.componentService.contentService.tryGetContentItem(content, 'enrollment', 'error', 'enrollmentPageGeneralError').text;
                this.secondFactorAuthLabel = this.componentService.contentService.tryGetContentItem(content, 'enrollment', 'step1', 'enrollmentSecondFactorAuthLabel').text;
                this.enrollmentConsumerAccountExistsErrorText = this.componentService.contentService.tryGetContentItem(
                    content, 'enrollment', 'error', 'enrollmentConsumerAccountExistsErrorText').text;
            });

        // initialize dropdown arrays
        for (let i = 1; i <= 12; i++) {
            if (i < 10) {
                this.months[i - 1] = '0' + i;
            } else {
                this.months[i - 1] = i.toString();
            }
        }

        for (let i = 1; i <= 31; i++) {
            if (i < 10) {
                this.days[i - 1] = '0' + i;
            } else {
                this.days[i - 1] = i.toString();
            }
        }

        for (let i = 0; i < 120; i++) {
            this.years[i] = (new Date().getFullYear() - i).toString();
        }

        this.stepList = [{stepName: 'Verification'}, {stepName: 'Account Sign Up'}];
        this.componentService.domainService.domainInfo$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(domainInfo => {
            this.domainInfo = domainInfo;
            if (this.domainInfo != null) {
                this.isConsolidatedCommunicationEnabled = this.domainInfo.useConsolidatedCommunication;
                this.initializeEnrollmentForm();
                if (this.domainInfo.signupValidationMask) {
                    const tempMask: Array<string | RegExp> = [];
                    this.domainInfo.signupValidationMask.split('~').forEach((s) => {
                        if (s.length === 1) {
                            tempMask.push(s);
                        }

                        if (s.indexOf('d') > -1) {
                            tempMask.push(/\d/);
                        }

                        if (s.indexOf('w') > -1) {
                            tempMask.push(/\w/);
                        }
                    });
                    this.secondaryAuthMask = tempMask;
                } else {
                    this.secondaryAuthMask = false;
                }
            }

            this.enrollmentProfileCmp.domainInfo = domainInfo;

            this.activatedRoute.queryParams.pipe(takeUntil(this.ngUnsubscribe)).subscribe((params: Params) => {
                this.queryString = params.account;
                this.memCode = this.queryStringMEMCode = params.mem;

                // After memCode gets re-set when moving to "step 2" ("Account Signup" phase),
                // "Expression changed after checked" error occurs w/o manually firing another change detection cycle.
                // the following line will kick off another CD cycle
                // https://github.com/angular/angular/issues/6005
                this.cdRef.detectChanges();

                this.email = params.email;

                if (this.queryStringMEMCode && this.domainInfo.domainMode === 'SSO') {
                    this.redirect('/mempayment');
                } else if (this.queryStringMEMCode && this.domainInfo.deliveryMode === 'None') {
                    this.redirect('/default');
                }
            });
        });
    }

    ngAfterViewInit() {
        this.recaptchaCmp.displayRecaptcha();

        // take mem code from enrollment page if it's less than 1 day old
        if (new URLSearchParams(document.location.search).get('prefill') === '1') {
            const enrollmentMemCode = localStorage?.getItem('enrollmentMemCode');
            const storageDateStr = localStorage?.getItem('enrollmentMemCodeStorageDate');
            const currentDate = new Date().getTime();
            const storageDate = new Date(storageDateStr).getTime();
            const oneDay = 1000 * 60 * 60 * 24;

            if (enrollmentMemCode && storageDateStr && (currentDate - storageDate) < oneDay) {
                this.memCode = enrollmentMemCode
                this.cdRef.detectChanges();
            }
        }
    }

    initializeEnrollmentForm() {
        this.setMask();
        this.memErrorText = this.memErrorMessage;

        // check for quickPayEnrollment object
        if (this.componentService.storageService.exists('quickpayenrollment')) {
            this.quickPayEnrollment = this.componentService.storageService.retrieve('quickpayenrollment');
            this.componentService.storageService.clear('quickpayenrollment');
        }

        // validate pending enrollment
        if (this.pendingEnrollmentGUID != null) {
            this.consumerService.validatePendingEnrollment(this.pendingEnrollmentGUID).then(response => {
                if (response.success) {
                    // show secondFactorAuth inputs for pending enrollment
                    this.showPendingEnrollment = true;
                    this.pendingEnrollmentErrorExists = false;

                } else {
                    // pending enrollment isn't valid (expired or bad guid)
                    this.showPendingEnrollment = false;
                    this.pendingEnrollmentErrorExists = true;
                    this.pendingEnrollmentError = this.componentService.handleValidationErrorCode(response.messages[0]);
                }

                this.setupMemValidation();
                this.doneLoading();
            }).catch(error => {
                // just show normal enrollment page (no errors)
                this.showPendingEnrollment = false;
                this.setupMemValidation();
                this.doneLoading();
            });
        } else {
            this.setupMemValidation();
            this.doneLoading();
        }

        if (this.domainInfo.deliveryMode === 'Paper') {
            this.enrollmentProfileCmp.deliveryType = 'Paper';
        } else if (this.domainInfo.deliveryMode === 'Standard') {
            this.enrollmentProfileCmp.deliveryType = 'Electronic';
        }
    }

    async handleCaptcha(token: string) {
        this.recaptchaAttempted = true;
        this.recaptchaToken = token;
        this.recaptchaError = false;
    }

    async handleCaptchaExpired(evt: any) {
        this.recaptchaToken = '';
        this.recaptchaError = true;
    }

    setMask() {
        let memCodeType = this.domainInfo.memCodeType;
        if (memCodeType != null) {
            memCodeType = memCodeType.toUpperCase();
        } else {
            memCodeType = '';
        }

        if (memCodeType.includes('9 MIXED')) {
            this.memPlaceholder = 'BCD-FGH-JKL';
        } else if (memCodeType.includes('X-13')) {
            this.memPlaceholder = 'X-12345-1234-1234';
        }
    }

    async prevStep() {
        if (this.currentStep > 1) {
            if (this.currentStep === 2) {
                if (this.recaptchaAttempted) {
                    this.recaptchaCmp.reset();
                }

                this.componentService.loggingService.log('logging you out', LoggingLevel.debug);

                if (!this.stopDoubleclick) {
                    try {
                        await this.loginService.logout();
                        this.clearErrors();
                    } catch (error) {
                        alert(error);
                    }
                }
                this.stopDoubleclick = true;
            }

            this.currentStep--;
            this.componentService.scrollPageToTop();
        } else {
            this.redirect('/default');
        }
    }

    async nextStep() {
        // wrap with this because it messes up unit tests
        if (process.env.ENV !== 'test') {
            // needed for onPopState to function completely
            history.pushState(false, 'enrollment', 'enrollment');
        }

        this.clearErrors();

        if (this.currentStep === 1) {
            // Get the consumerAccount
            if (this.enrollmentProfileCmp != null) {
                this.enrollmentProfileCmp.populateConsumer();
            }
        }

        if (this.currentStep === 2) {
            await this.validateForm();
            // fall through to final step
        }

        if (this.currentStep < this.finalStep && this.currentStepIsValid(this.currentStep)) {
            this.currentStep++;
        } else if (this.currentStep === this.finalStep && this.currentStepIsValid(this.currentStep)) {
            // Go to profile completeness page?
            this.enrollment = true;
            this.saveNewAccount();
        }

        this.componentService.scrollPageToTop();
    }

    async submitStep1Validation(): Promise<void> {
        if (this.showPendingEnrollment) {
            await this.submitPendingEnrollmentValidation();
        } else {
            await this.submitMemCode();
        }
    }

    async submitPendingEnrollmentValidation() {
        const dontDelete: string = this.pendingEnrollmentGUID;
        if (this.currentStep === 1) {
            let result;
            try {
                result = await this.loginService.pendingEnrollment(this.pendingEnrollmentGUID, this.getDateOfBirth());
            } catch (e) {
                this.step1Valid = false;
                this.enrollmentStep1Error = true;
                this.enrollmentStep1ErrorText = '';
            }

            if (result && result.success) {
                if (result.redirectLocation && result.redirectLocation.toString() !== '') {
                    const location = result.redirectLocation;
                    if (this.compareBaseUrl(location, window.location.origin)) {
                        window.location.href = result.redirectLocation + 'redirect?enrollment=1';
                    }
                }
                this.enrollmentStep1Error = false;
                this.token = result.token;
                this.componentService.storageService.store('token', result.token);
                this.componentService.storageService.store('enrollment', true);
                this.consumerService.getEmailByPendingEnrollment(dontDelete).then(email => {
                    this.pendingEnrollmentEmail = email;
                }).catch(error => {
                    this.componentService.loggingService.handleError(error);
                });
            } else {
                this.enrollmentStep1Error = true;
                this.enrollmentStep1ErrorText = result.userMessage;
            }

            this.checkRecaptcha();

            if (!this.enrollmentStep1Error && !this.hasRecaptchaError()) {
                this.nextStep();
            } else {
                if (this.hasRecaptchaError()) {
                    this.componentService.storageService.clear('token');
                    this.componentService.storageService.clear('enrollment');
                }
            }
        }
    }

    private setupMemValidation(): void {
        if (this.componentService.storageService.exists('mem')) {
            this.memCode = this.componentService.storageService.retrieve('mem');
            if (!this.showPendingEnrollment
                && (this.domainInfo.desktopLinkValidationMode === 'ZIP5'
                    || this.domainInfo.desktopLinkValidationMode === 'XML')) {
                this.extraRequired = true;
                this.memErrorText = this.memMixedErrorMessage;
            }
        } else {
            if (this.quickPayEnrollment != null) {
                this.prefillFromQuickPay();
                if (this.domainInfo.signupValidationMode === 'ZIP5' || this.domainInfo.signupValidationMode === 'XML') {
                    this.extraRequired = true;
                    this.memErrorText = this.memMixedErrorMessage;
                }

                if (this.domainInfo.signupValidationMode === 'none'
                    || (this.domainInfo.signupValidationMode === this.domainInfo.paymentValidationMode)) {
                    this.submitMemCode();
                }
            } else if (!this.showPendingEnrollment
                && (this.domainInfo.signupValidationMode === 'ZIP5'
                    || this.domainInfo.signupValidationMode === 'XML')) {
                this.extraRequired = true;
                this.memErrorText = this.memMixedErrorMessage;
            } else {
                this.extraRequired = false;
            }
        }
    }

    private prefillFromQuickPay(): void {
        this.memCode = this.quickPayEnrollment.memCode;
        this.memExtra = this.quickPayEnrollment.secondFactorAuth ? this.quickPayEnrollment.secondFactorAuth : '';
        this.pendingEnrollmentEmail = this.quickPayEnrollment.email;
        this.firstName = this.quickPayEnrollment.firstName;
        this.lastName = this.quickPayEnrollment.lastName;
    }

    async submitMemCode(): Promise<boolean> {
        if (this.currentStep === 1) {
            // validate MEM Code
            if (this.memCode != null) {
                this.memCode = this.memCode.trim().toUpperCase();
            } else {
                this.memErrorExists = true;
                if (this.extraRequired) {
                    this.extraErrorExists = true;
                }
                this.step1Valid = false;
                return false;
            }

            this.memErrorExists = false;
            this.extraErrorExists = false;
            this.componentService.storageService.clear('token');
            // validate mem code
            const memCodeLengths: number[] = [11, 9, 20, 17, 14];
            if (!memCodeLengths.some(x => x === this.memCode.length)) {
                this.memErrorExists = true;
            } else {
                if (this.memCode.length === 9) {
                    this.memCode = this.memCode.replace(/([BCDFGHJKLMNPQRSTVWXYZ123456789]{3})([BCDFGHJKLMNPQRSTVWXYZ123456789]{3})([BCDFGHJKLMNPQRSTVWXYZ123456789]{3})/, '$1-$2-$3');
                }

                if (this.memCode.length === 17 && (this.memCode.startsWith('P') || this.memCode.startsWith('p'))) {
                    this.memCode = this.memCode.replace(/([Pp])([BCDFGHJKLMNPQRSTVWXYZ0123456789]{5})([BCDFGHJKLMNPQRSTVWXYZ0123456789]{5})([BCDFGHJKLMNPQRSTVWXYZ0123456789]{6})/, '$1-$2-$3-$4');
                }

                if (this.memCode.length === 14) {
                    this.memCode = this.memCode.replace(/([Xx])([0123456789]{5})([0123456789]{4})([0123456789]{4})/, '$1-$2-$3-$4');
                }

                this.memErrorExists = false;
            }

            if (this.memErrorExists && this.extraRequired) {
                this.extraErrorExists = true;
            } else {
                this.extraErrorExists = false;
            }

            if (this.extraRequired && this.memExtra.trim() === '') {
                this.extraErrorExists = true;
            }

            if (this.memErrorExists || this.extraErrorExists) {
                this.step1Valid = false;
                return false;
            }

            let result;
            try {
                result = await this.loginService.memLogin(this.memCode,
                    null, this.memExtra.trim(), false, false, this.recaptchaToken /*tbd for email*/);
            } catch (error) {
                this.memErrorText = this.memMixedErrorMessage;
                this.memErrorExists = true;
                this.step1Valid = false;
                this.grecaptcha = (window as any).grecaptcha;
                this.grecaptcha.reset();
            }

            if (result && result.success) {
                if (result.redirectLocation && result.redirectLocation.toString() !== '') {
                    const location = result.redirectLocation;
                    if (this.compareBaseUrl(location, window.location.origin)) {
                        window.location.href = result.redirectLocation + 'redirect?enrollment=1';
                    }
                }
                this.token = result.token;
                this.componentService.storageService.store('token', result.token);
                this.componentService.storageService.store('enrollment', true);
            } else {
                this.memErrorExists = true;
                this.memErrorText = this.memMixedErrorMessage;
                if (this.extraValidationRequired()) {
                    this.extraErrorExists = true;
                }
            }

            this.checkRecaptcha();
            if (!this.memErrorExists && !this.hasRecaptchaError()) {
                this.startLoading();
                const consumer = await this.consumerService.getConsumer();
                if (this.queryStringMEMCode != null) {
                    this.pendingEnrollmentEmail = this.email;
                    this.autoGenerateUsername(consumer.firstName, consumer.lastName);
                }

                if (!consumer.consumerAccountExists) {
                    this.nextStep();
                    this.doneLoading();
                } else {
                    this.step1Valid = false;
                    if (consumer.isQuickPay) {
                        this.nextStep();
                    } else {
                        this.enrollmentStep1Error = true;
                        this.enrollmentStep1ErrorText = this.enrollmentConsumerAccountExistsErrorText;
                    }
                    this.doneLoading();
                }
            }

            if (this.enrollmentStep1Error || this.hasRecaptchaError()) {
                this.componentService.storageService.clear('enrollment');
                this.componentService.storageService.clear('mem');
                this.componentService.storageService.clear('token');
            }
        }
    }

    currentStepIsValid(step: number): boolean {
        let stepIsValid: boolean;
        switch (step) {
            case 1:
                stepIsValid = this.step1Valid;
                break;

            case 2:
                stepIsValid = this.step2Valid;
                break;
        }

        return stepIsValid;
    }

    compareBaseUrl(firstUrl: string, secondUrl: string): boolean {
        // remove '/' because trailing '/'
        // split on '.' to compare domain and top-level domain (e.g. 'mysecurebill' and 'com')
        const removeSlashRegex = /\//gm;
        const firstLocation = firstUrl.replace(removeSlashRegex, '').split('.');
        const secondLocation = secondUrl.replace(removeSlashRegex, '').split('.');

        // if the base url address is mysecurebill?? then good to go
        if (firstLocation.length >= 2 && secondLocation.length >= 2 && firstLocation[1] === secondLocation[1]) {
            if (firstLocation[2] === secondLocation[2]) {
                return true;
            }
        }
        return false;
    }

    hasMemCodeError() {
        if (this.extraRequired) {
            return this.memErrorExists || this.extraErrorExists;
        } else {
            return this.memErrorExists;
        }
    }

    hasMemExtraError() {
        return this.extraErrorExists;
    }

    hasRecaptchaError() {
        return this.recaptchaError;
    }

    extraValidationRequired(): boolean {
        return this.extraRequired;
    }

    checkRecaptcha(): void {
        if (this.quickPayEnrollment != null || (process.env.ENV !== 'production' && this.queryString === 'automation')) {
            // allow qa automation or a quickpayenrollment to pass through without recaptcha
            this.recaptchaError = false;
        } else if (!this.recaptchaAttempted || (this.recaptchaAttempted && this.recaptchaError)) {
            this.recaptchaError = true;
            this.step1Valid = false;
        }
    }

    clearErrors() {
        this.memErrorExists = false;
        this.extraErrorExists = false;
        this.step1Valid = true, this.step2Valid = true;
        const inputForm = this.enrollmentProfileCmp;
        this.clearEmailErrors();
        this.clearPasswordErrors();
        this.clearUsernameErrors();
        inputForm.deliveryTypeError = false;
    }

    clearUsernameErrors() {
        const inputForm = this.enrollmentProfileCmp;
        inputForm.usernameRequiredError = false;
        inputForm.usernameValidationError = false;
        inputForm.usernameExists = false;
        inputForm.usernameSpecialCharsError = false;
    }

    clearEmailErrors() {
        const inputForm = this.enrollmentProfileCmp;
        inputForm.emailMatchError = false;
        inputForm.emailRequiredError = false;
        inputForm.emailValidationError = false;
    }

    clearPasswordErrors() {
        const inputForm = this.enrollmentProfileCmp;
        inputForm.passwordMatchError = false;
        inputForm.passwordLengthError = false;
        inputForm.passwordRequiredError = false;
        inputForm.passwordValidationError = false;
    }

    isEnrollment(): boolean {
        return this.enrollment;
    }

    blurComponent(componentName: string) {
        switch (componentName) {
            case 'password':
                this.clearPasswordErrors();
                this.validatePassword(true);
                break;
            case 'email':
                this.clearEmailErrors();
                this.validateEmail(true);
                break;
            case 'username':
                this.clearUsernameErrors();
                this.validateUsername();
                break;
        }
    }

    private getDateOfBirth(): string {
        return this.expirationMonth + '/' + this.expirationDay + '/' + this.expirationYear;
    }

    private async saveNewAccount(): Promise<void> {
        const inputForm = this.enrollmentProfileCmp;
        const consumerAccountRequest: ConsumerAccountRequest = new ConsumerAccountRequest();
        consumerAccountRequest.CustomerAccountID = inputForm.consumer.accountID;
        consumerAccountRequest.DocumentCode = this.memCode; // MEM
        consumerAccountRequest.ValidationString = this.memExtra;
        consumerAccountRequest.DeliveryMethod = inputForm.deliveryType;

        if (!this.componentService.isSSO(this.domainInfo)) {
            consumerAccountRequest.Username = inputForm.username;
            consumerAccountRequest.Password = inputForm.password;
        }

        consumerAccountRequest.EmailAddress = inputForm.email;
        consumerAccountRequest.FirstName = inputForm.consumer.firstName;
        consumerAccountRequest.LastName = inputForm.consumer.lastName;
        consumerAccountRequest.LanguageCode = window.navigator.language;
        consumerAccountRequest.PendingEnrollmentGUID = this.pendingEnrollmentGUID;
        consumerAccountRequest.EnrollmentChannel = 'MySecureBill';
        consumerAccountRequest.AccountType = 'Standard';

        try {
            const createResponse = await this.consumerService.createConsumerAccount(
                consumerAccountRequest,
                this.isConsolidatedCommunicationEnabled
            );
            this.processEnrollment(createResponse);
        } catch (e) {
            this.enrollment = false;
            this.enrollmentStep2Error = true;
        }
    }

    private processEnrollment(response: boolean) {
        if (response != null && response) {
            this.componentService.storageService.clear('enrollment');
            this.componentService.storageService.clear('mem');
            this.consumerService.getConsumerAccount().then(response2 => {
                if (response2 != null) {
                    this.finishEnrollment();
                }
            });
        } else if (response != null && !response) {
            this.step2Valid = false;
            this.enrollment = false;
            this.enrollmentProfileCmp.passwordValidationError = true;
        }
    }

    private async finishEnrollment() {
        // get roles to determine later where to redirect to
        const roles = await this.consumerService.getConsumerRoles();
        this.componentService.storageService.store('roles', roles);
        this.consumerStorageService.updateConsumer({ isQuickPay: false }).then(() => {
            if (this.queryStringMEMCode) {
                this.redirect('/profile');
            } else if (this.componentService.hasRole(Roles.StatementsOnly)) {
                this.redirect('/statements');
            } else {
                this.redirect('/home');
            }
        }).catch(() => {
            throw new Error('An error occurred when completing enrollment.');
        });
    }

    private async validateForm() {
        this.step2Valid = true;
        this.clearErrors();
        // one letter, one capital and one number
        const inputForm = this.enrollmentProfileCmp;
        await this.validatePassword();
        this.validateEmail();
        this.validateUsername();

        if (!!inputForm.deliveryChoices && inputForm.deliveryChoices.split(';').length === 1) {
            inputForm.deliveryType = inputForm.deliveryChoices.split(':')[0];
        }

        if (!inputForm.deliveryType || (!!inputForm.deliveryType && inputForm.deliveryType.length === 0)) {
            inputForm.deliveryTypeError = true;
            this.step2Valid = false;
        }
    }

    private async validatePassword(fromTyping: boolean = false) {
        const inputForm = this.enrollmentProfileCmp;
        if (this.componentService.isSSO(this.domainInfo)) {
            inputForm.password = this.componentService.generateUUID();
            inputForm.passwordConfirm = inputForm.password;
            return;
        }

        if (!!inputForm.password && inputForm.password.includes(' ')) {
            this.step2Valid = false;
            inputForm.passwordSpaceError = true;
        }

        if (fromTyping && inputForm.passwordConfirm != null) {
            if (inputForm.password !== inputForm.passwordConfirm) {
                inputForm.passwordMatchError = true;
                this.step2Valid = false;
            }
        }

        if (!fromTyping) {
            if (inputForm.password !== inputForm.passwordConfirm) {
                inputForm.passwordMatchError = true;
                this.step2Valid = false;
            }
        }

        if (!!inputForm.password && (inputForm.password.length < 8 && inputForm.password.length > 0)) {
            inputForm.passwordLengthError = true;
            this.step2Valid = false;
        }

        if (!inputForm.password || inputForm.password.length === 0) {
            inputForm.passwordRequiredError = true;
            this.step2Valid = false;
        }

        if (!!inputForm.password && inputForm.password.length >= 8) {
            const result = await this.componentService.validationService.verifyPasswordComplexity(
                inputForm.password
            );

            if (!result) {
                inputForm.passwordValidationError = true;
                this.step2Valid = false;
            }
        }
    }

    private validateEmail(fromTyping: boolean = false) {
        const inputForm = this.enrollmentProfileCmp;

        if (!this.isConsolidatedCommunicationEnabled) {
            if (fromTyping && inputForm.emailConfirm != null) {
                if (inputForm.email !== inputForm.emailConfirm) {
                    inputForm.emailMatchError = true;
                    this.step2Valid = false;
                }
            }

            if (!fromTyping) {
                if (inputForm.email !== inputForm.emailConfirm) {
                    inputForm.emailMatchError = true;
                    this.step2Valid = false;
                }
            }

            if (!inputForm.email || inputForm.email.length === 0) {
                inputForm.emailRequiredError = true;
                this.step2Valid = false;
            } else if (!this.componentService.emailRegex.test(inputForm.email.toLocaleLowerCase())) {
                inputForm.emailValidationError = true;
                this.step2Valid = false;
            }
        } else {
            this.step2Valid = !!inputForm.email;
            inputForm.emailRequiredError = !this.step2Valid;
        }
    }

    private validateUsername() {
        const inputForm = this.enrollmentProfileCmp;
        if (this.componentService.isSSO(this.domainInfo)) {
            return;
        }

        if (inputForm && inputForm.username) {
            inputForm.username = inputForm.username.trim();
        }

        if (!inputForm.username || inputForm.username.length === 0) {
            inputForm.usernameRequiredError = true;
            this.step2Valid = false;
        }

        if (!!inputForm.username && (inputForm.username.length < 6 || inputForm.username.length > 80)) {
            inputForm.usernameValidationError = true;
            this.step2Valid = false;
        }

        if (!!inputForm.username && !this.componentService.emailRegex.test(inputForm.username.toLocaleLowerCase())) {
            // if it isn't an email address, then it has to not have special characters in it
            const specialChars = /[$-/:-?{-~!"^@&#%^\*\(\)=|_`\[\]]/g; // "
            if (specialChars.test(inputForm.username)) {
                inputForm.usernameSpecialCharsError = true;
                this.step2Valid = false;
            }
        }
    }

    private redirect(relativeRoute: string) {
        this.stopDoubleclick = false;
        this.ngZone.run(() => this.parentRouter.navigateByUrl(relativeRoute)).then();
    }

    private startLoading() {
        this.loading = true;
    }

    private doneLoading() {
        this.loading = false;
    }

    isLoading(): boolean {
        return this.loading;
    }

    isSubmitting(): boolean {
        return this.enrollmentProfileCmp.loading;
    }

    private autoGenerateUsername(firstName: string, lastName: string): void {
        const request = new GenerateUsernameRequest();
        request.FirstName = firstName;
        request.LastName = lastName;

        this.consumerService.generateUsername(request).then(
            (response) => {
                if (response != null) {
                    this.enrollmentProfileCmp.username = response;
                }
            }
        );
    }
}
