import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ComponentService } from '../../../services/component/component.service';

@Component({
    selector: 'help',
    template: require('./help.component.html'),
    styles: [require('./help.component.css')]
})
export class HelpComponent implements OnInit, OnDestroy {

    constructor(private componentService: ComponentService) {
    }

    private ngUnsubscribe: Subject<any> = new Subject();

    @Input() path: string;

    helpText: string;

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit() {
        this.componentService.contentService.content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((content: any) => {
                this.helpText = this.componentService.contentService.tryGetContentItem(content, 'header', 'link', 'headerHelpLink').text;
            });
    }

    linkClick(): void {
        window.open('/faq', '_blank');
    }
}
