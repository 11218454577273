/* tslint:disable:triple-equals */
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ComponentService } from '../../../services/component/component.service';
import { ConsumerService } from '../../../services/consumer/consumer.service';
import { PaymentPlanService } from '../../../services/paymentplan/paymentplan.service';

@Component({
    selector: 'confirmation-modal',
    template: require('./confirmationmodal.component.html'),
    styles: [require('./confirmationmodal.component.css')]
})

export class ConfirmationModalComponent implements OnInit {
    public modalRef: BsModalRef;

    @ViewChild('modaltemplate', { static: false }) modalTemplate: any; // Should be HTMLTemplateElement but IE sucks

    @Input() modalTitle: string;
    @Input() modalContent: string;
    @Input() showCloseButton = false;
    @Input() showCancelButton = true;
    @Input() cancelButtonText: string;
    @Input() confirmButtonText: string;
    @Input() emailAddress: string;
    @Input() isNewPaymentPlan: boolean;
    @Input() showPrintSection: boolean;
    @Input() paymentPlanGUID: string;

    public callbackModel: any;
    message = '';
    titleMessage = '';
    showErrorMessage: boolean;
    showSuccessMessage: boolean;
    printButtonText = '';
    emailButtonText = '';
    isLoading: boolean;

    public config = {
        keyboard: false, // allow them to press Escape key
        backdrop: true, // dim the page with a backdrop
        ignoreBackdropClick: true, // Disallow them from clicking the backdrop to dismiss
        animated: false
    };

    @Output() public onConfirmFunction: EventEmitter<any> = new EventEmitter<any>();
    @Output() public onCancelFunction: EventEmitter<any> = new EventEmitter<any>();

    private ngUnsubscribe: Subject<any> = new Subject();

    constructor(private modalService: BsModalService, private componentService: ComponentService,
                private consumerService: ConsumerService, private paymentPlanService: PaymentPlanService) {
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit() {
        if (this.confirmButtonText == null || this.confirmButtonText == '' ) {
            this.componentService.contentService.content$
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe((content: any) => {
                    this.confirmButtonText = this.componentService.contentService.tryGetContentItem(content, 'modal', 'button', 'modalOkButton').text;
                });
        }

        if (this.cancelButtonText == null || this.cancelButtonText == '') {
            this.componentService.contentService.content$
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe((content: any) => {
                    this.cancelButtonText = this.componentService.contentService.tryGetContentItem(content, 'modal', 'button', 'modalCancelButton').text;
                });
        }

        this.componentService.contentService.content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((content: any) => {
                this.printButtonText = this.componentService.contentService.tryGetContentItem(
                    content, 'modal', 'button', 'modalPrintButton').text;

                this.emailButtonText = this.componentService.contentService.tryGetContentItem(
                    content, 'modal', 'button', 'modalEmailButton').text;
            });
    }

    public openModal(passedInData: any = null) {
        this.callbackModel = passedInData;
        this.modalRef = this.modalService.show(this.modalTemplate, this.config);
    }

    public confirm() {
        this.modalRef.hide();
        this.onConfirmFunction.emit(this.callbackModel);
    }

    public cancel() {
        this.modalRef.hide();
        this.onCancelFunction.emit(this.callbackModel);
    }

    public printTermsAndConditions() {
        const html = `<html><body>
                    <h1>${this.modalTitle}</h1>
                    <div id="printTermsAndConditions">
                    ${this.modalContent}
                    </div></body></html>`;
        const windowObject = window.open(',', '_blank');
        windowObject.document.writeln(html);
        windowObject.document.close();

        if (/MSIE|Trident/.test(window.navigator.userAgent)) {
            setTimeout(printAndClose, 1800);
        } else {
            windowObject.addEventListener('load', printAndClose);
        }

        function printAndClose() {
            windowObject.focus();
            windowObject.print();
            windowObject.close();
        }
    }

    public sendTermsAndConditionsEmail() {
        this.isLoading = true;

        this.consumerService.getConsumer().then((consumer) => {
            if (consumer.emailAddress && consumer.accountID && this.paymentPlanGUID) {
                this.paymentPlanService.queuePaymentPlanTermsAndConditionsEmail(
                    this.emailAddress,
                    'MSB',
                    this.isNewPaymentPlan,
                    consumer.accountID,
                    this.paymentPlanGUID
                ).then((data) => {
                    this.isLoading = false;
                    if (data) {
                        this.titleMessage = 'Email sent to';
                        this.message = consumer.emailAddress;
                        this.showSuccessMessage = true;
                    } else {
                        this.titleMessage = 'Error';
                        this.message = 'Error sending email';
                        this.showErrorMessage = true;
                    }
                });
            } else {
                this.titleMessage = 'Error';
                this.message = 'No email address exists';
                this.showErrorMessage = true;
                this.isLoading = false;
            }
        });
    }
}
