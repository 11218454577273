import { Component, Input, OnInit } from '@angular/core';
import { Activity } from '../../models/activity';

@Component({
    selector: 'latest-activity-by-year',
    template: require('./latestactivitybyyear.component.html'),
    styles: [require('./latestactivitybyyear.component.css')]
})
export class LatestActivityByYearComponent implements OnInit {
    constructor() { }

    @Input() year: number;
    @Input() activities: Activity[];

    ngOnInit() { }
}
