import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'powered-by',
    template: require('./poweredby.component.html'),
    styles: [require('./poweredby.component.css')]
})

export class PoweredbyComponent implements OnInit {
    @Input() topOffset = 10;
    offsetString: string;

    ngOnInit() {
        this.offsetString = this.topOffset + 'px';
    }
}
