/* tslint:disable:triple-equals */
import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppConfig } from '../../../app.config';
import { IDomainInfo } from '../../../models/domaininfo';
import { EmailAddressType } from '../../../models/emailaddresstype';
import { GuarantorEmailViewModel } from '../../../models/guarantoremailviewmodel';
import { CreditCardIssuerType, PaymentMethod } from '../../../models/paymentmethod';
import { PaymentMethodAssignmentSource } from '../../../models/paymentmethodassignmentsource';
import {
    IRecurringPaymentPlan,
    RecurringFrequency,
    RecurringPaymentPlanStatus,
    RecurringPlanType
} from '../../../models/recurringpaymentplan';
import { ComponentService } from '../../../services/component/component.service';
import { ConsumerService } from '../../../services/consumer/consumer.service';
import { ConfirmationModalComponent } from '../../Controls/ConfirmationModal/confirmationmodal.component';
import { ConsumerContactDetailsModalComponent } from '../../Controls/ConsumerContactDetailsModal/consumercontactdetailsmodal.component';

@Component({
    selector: 'recurring-plan',
    template: require('./recurringplan.component.html'),
    styles: [require('./recurringplan.component.css')]
})
export class RecurringPlanComponent implements OnInit, OnDestroy {

    @Input() set planValue(plan: IRecurringPaymentPlan) {
        const activeArray: RecurringPaymentPlanStatus[] = [
            RecurringPaymentPlanStatus.active,
            RecurringPaymentPlanStatus.hold,
            RecurringPaymentPlanStatus.pending
        ];
        this.plan = plan;
        this.replacePlanText(plan);
        this.showActive = activeArray.indexOf(this.plan.status) > -1;
        this.oldEmailAddress = plan.emailAddress;
    }

    @ViewChild('cancelpaymentconfirmation', { static: false }) cancelPaymentConfirmationModal: ConfirmationModalComponent;
    @ViewChild('consumerProfileModal', { static: false }) consumerProfileModal: ConsumerContactDetailsModalComponent;

    detailArrowRotateDegrees = 0;
    detailsExpanded: boolean;
    loading = false;
    paymentMethods: PaymentMethod[] = [];
    emailAddressChangesExist = false;
    paymentMethodChangesExist = false;
    oldEmailAddress: string;
    emailAddress: string;
    oldPaymentMethod: PaymentMethod;
    plan: IRecurringPaymentPlan;
    currentPlanText: string;
    pastPlanText = '';
    cancelledPlanText: string;
    completedPlanText: string;
    completedPlanNoDateText: string;
    statusHoldText: string;
    statusPendingText: string;
    statusCompletedText: string;
    cancelledPlanNoDateText: string;
    confirmationEmailLabel: string;
    numberOfPaymentsLabel: string;
    remainingPaymentsText: string;
    nextPaymentLabel: string;
    recentPaymentLabel: string;
    nextPaymentDateText: string;
    recentPaymentDateText: string;
    statusActiveText: string;
    statusCancelledText: string;
    totalPaymentAmountLabel: string;
    viewStatementLinkText: string;
    changeEmailText: string;
    updateErrorExists = false;
    emailErrorExists = false;
    cancelPaymentModalContent: string;
    cancelPaymentModalTitle: string;
    cancelPaymentErrorText: string;
    cancelPaymentErrorExists = false;
    cancelPaymentButtonText: string;
    cancelPaymentModalBackButtonText: string;
    paymentMethodText: string;
    emailErrorText: string;
    updateErrorText: string;
    showActive = true;
    domainInfo: IDomainInfo;

    private ngUnsubscribe: Subject<any> = new Subject();

    constructor(
        private componentService: ComponentService,
        private datePipe: DatePipe,
        private currencyPipe: CurrencyPipe,
        private consumerService: ConsumerService,
        private config: AppConfig
    ) { }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit() {
        this.componentService.contentService.content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((content: any) => {
                this.currentPlanText = this.componentService.contentService.tryGetContentItem(content, 'recurring', 'pageText', 'recurringPlanText').text;
                this.cancelledPlanText = this.componentService.contentService.tryGetContentItem(content, 'recurring', 'pageText', 'recurringCancelledPlanText').text;
                this.cancelledPlanNoDateText = this.componentService.contentService.tryGetContentItem(content, 'recurring', 'pageText', 'recurringCancelledPlanNoDateText').text;
                this.completedPlanText = this.componentService.contentService.tryGetContentItem(content, 'recurring', 'pageText', 'recurringCompletedPlanText').text;
                this.completedPlanNoDateText = this.componentService.contentService.tryGetContentItem(content, 'recurring', 'pageText', 'recurringCompletedPlanNoDateText').text;
                this.confirmationEmailLabel = this.componentService.contentService.tryGetContentItem(content, 'recurring', 'pageText', 'recurringConfirmationEmailLabel').text;
                this.nextPaymentLabel = this.componentService.contentService.tryGetContentItem(content, 'recurring', 'pageText', 'recurringNextPaymentLabel').text;
                this.recentPaymentLabel = this.componentService.contentService.tryGetContentItem(content, 'recurring', 'pageText', 'recurringRecentPaymentLabel').text;
                this.statusActiveText = this.componentService.contentService.tryGetContentItem(content, 'recurring', 'pageText', 'recurringStatusActiveText').text;
                this.statusCancelledText = this.componentService.contentService.tryGetContentItem(content, 'recurring', 'pageText', 'recurringStatusCancelledText').text;
                this.statusHoldText = this.componentService.contentService.tryGetContentItem(content, 'recurring', 'pageText', 'recurringStatusHoldText').text;
                this.statusPendingText = this.componentService.contentService.tryGetContentItem(content, 'recurring', 'pageText', 'recurringStatusPendingText').text;
                this.statusCompletedText = this.componentService.contentService.tryGetContentItem(content, 'recurring', 'pageText', 'recurringStatusCompletedText').text;
                this.recentPaymentDateText = this.componentService.contentService.tryGetContentItem(content, 'recurring', 'pageText', 'recurringRecentPaymentText').text;
                this.nextPaymentDateText = this.componentService.contentService.tryGetContentItem(content, 'recurring', 'pageText', 'recurringNextPaymentText').text;
                this.paymentMethodText = this.componentService.contentService.tryGetContentItem(content, 'recurring', 'pageText', 'recurringPaymentMethodText').text;
                this.numberOfPaymentsLabel = this.componentService.contentService.tryGetContentItem(content, 'recurring', 'pageText', 'recurringNumberOfPaymentsLabel').text;
                this.remainingPaymentsText = this.componentService.contentService.tryGetContentItem(content, 'recurring', 'pageText', 'recurringRemainingPaymentsText').text;
                this.totalPaymentAmountLabel = this.componentService.contentService.tryGetContentItem(content, 'recurring', 'pageText', 'recurringTotalPaymentAmountLabel').text;
                this.viewStatementLinkText = this.componentService.contentService.tryGetContentItem(content, 'recurring', 'pageText', 'recurringViewStatementLinkText').text;
                this.changeEmailText = this.componentService.contentService.tryGetContentItem(content, 'recurring', 'pageText', 'recurringChangeEmailText').text;

                this.emailErrorText = this.componentService.contentService.tryGetContentItem(content, 'recurring', 'error', 'recurringEmailErrorText').text;
                this.updateErrorText = this.componentService.contentService.tryGetContentItem(content, 'recurring', 'error', 'recurringUpdateErrorText').text;
            });
        this.componentService.domainService.domainInfo$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(domainInfo => {
                this.domainInfo = domainInfo;

                if (this.domainInfo.useConsolidatedCommunication) {
                    this.consumerService.getGuarantorEmail(EmailAddressType.REC, this.plan.recurringConsumerPaymentID).then(emails => {
                        if (emails.length == 1) {
                            this.emailAddress = emails[0].emailAddress;
                        }
                    });
                }
        });
        this.replacePlanText(this.plan);
        this.updatePaymentMethods(null);
        this.oldPaymentMethod = this.plan.storedPaymentMethod;
    }

    private updatePaymentMethods(walletToken: string) {
        this.consumerService.getWalletPaymentMethods().then(wallet => {
            if (!!wallet) {
                if (this.domainInfo && this.domainInfo.enableWallet) {
                    this.paymentMethods = wallet;
                } else {
                    this.paymentMethods = wallet.filter(x => x.tokenGUID != 'newPaymentMethod');
                }
            }

            if (walletToken) {
                this.changePaymentMethod(walletToken);
            }
        });
    }

    savePlanChanges(): void {
        if (!this.emailErrorExists) {
            // call updateAutopayAgreement service
            this.loading = true;
            this.updateErrorExists = false;

            if (this.paymentMethodChangesExist) {
                this.plan.paymentMethodAssignmentSource = PaymentMethodAssignmentSource.SelfService;
            }

            this.consumerService.updateRecurringPaymentPlan(this.plan)
                .then(updated => {
                    if (updated.success) {
                        this.oldEmailAddress = this.plan.emailAddress;
                        this.oldPaymentMethod = this.plan.storedPaymentMethod;
                        this.emailAddressChangesExist = false;
                        this.paymentMethodChangesExist = false;
                    } else {
                        this.updateErrorExists = true;
                    }
                    this.doneLoading();
                }).catch(err => {
                this.updateErrorExists = true;
                this.doneLoading();
            });
        }
    }

    private statusText(): string {
        switch (this.plan.status) {
            case RecurringPaymentPlanStatus.active:
                return this.statusActiveText;
            case RecurringPaymentPlanStatus.cancelled:
                return this.statusCancelledText;
            case RecurringPaymentPlanStatus.completed:
                return this.statusCompletedText;
            case RecurringPaymentPlanStatus.hold:
                return this.statusHoldText;
            case RecurringPaymentPlanStatus.pending:
                return this.statusPendingText;
            default:
                return 'Unknown';
        }
    }

    private cancelPlanChanges(): void {
        this.emailAddressChangesExist = false;
        this.paymentMethodChangesExist = false;
        this.emailErrorExists = false;
        this.updateErrorExists = false;
        this.plan.emailAddress = this.oldEmailAddress;
        this.plan.storedPaymentMethod = this.oldPaymentMethod;
    }

    private replacePlanText(plan: IRecurringPaymentPlan): void {
        if (this.currentPlanText != null && this.plan != null) {
            this.currentPlanText = this.currentPlanText.replace('!FREQUENCY!', RecurringFrequency[plan.frequency]);
            this.currentPlanText = this.currentPlanText.replace('!PAYMENTAMOUNT!', this.currencyPipe.transform(plan.paymentAmount, 'USD', 'symbol'));
            this.currentPlanText = this.currentPlanText.replace('!CREATEDDATE!', this.datePipe.transform(plan.startDate, 'M/d/y'));
            this.currentPlanText = this.currentPlanText.replace('!FINALAMOUNT!', this.currencyPipe.transform(plan.finalPaymentAmount, 'USD', 'symbol'));
            this.currentPlanText = this.currentPlanText.replace('!ENDDATE!', this.datePipe.transform(plan.scheduledEndDate, 'M/d/y'));
            this.currentPlanText = this.capitalizeFirstLetter(this.currentPlanText);
        }
        if (this.completedPlanText != null && this.plan != null && this.plan.status == RecurringPaymentPlanStatus.completed) {
            if (this.plan.completedDate != null) {
                this.completedPlanText = this.completedPlanText.replace('!COMPLETEDDATE!', this.datePipe.transform(plan.completedDate, 'M/d/y'));
            } else if (this.plan.status == RecurringPaymentPlanStatus.completed) {
                this.completedPlanText = this.completedPlanNoDateText;
            }
            this.completedPlanText = this.completedPlanText.replace('!FREQUENCY!', RecurringFrequency[plan.frequency]);
            this.pastPlanText = this.completedPlanText.replace('!PAYMENTAMOUNT!', this.currencyPipe.transform(plan.paymentAmount, 'USD', 'symbol'));
        }
        if (this.cancelledPlanText != null && this.plan != null && this.plan.status == RecurringPaymentPlanStatus.cancelled) {
            if (this.plan.completedDate != null) {
                this.cancelledPlanText = this.cancelledPlanText.replace('!CANCELLEDDATE!', this.datePipe.transform(plan.completedDate, 'M/d/y'));
            } else if (this.plan.status == RecurringPaymentPlanStatus.cancelled) {
                this.cancelledPlanText = this.cancelledPlanNoDateText;
            }
            this.cancelledPlanText = this.cancelledPlanText.replace('!FREQUENCY!', RecurringFrequency[plan.frequency]);
            this.pastPlanText = this.cancelledPlanText.replace('!PAYMENTAMOUNT!', this.currencyPipe.transform(plan.paymentAmount, 'USD', 'symbol'));
        }
        this.pastPlanText = this.capitalizeFirstLetter(this.pastPlanText);
        if (this.nextPaymentDateText != null && this.plan.numberOfRemainingPayments > 0) {
            if (this.plan.numberOfRemainingPayments == 1) {
                this.nextPaymentDateText = this.nextPaymentDateText.replace(
                    '!PAYMENT!', this.currencyPipe.transform(this.plan.finalPaymentAmount, 'USD', 'symbol'));
            } else {
                this.nextPaymentDateText = this.nextPaymentDateText.replace(
                    '!PAYMENT!', this.currencyPipe.transform(this.plan.paymentAmount, 'USD', 'symbol'));
            }
            this.nextPaymentDateText = this.nextPaymentDateText.replace(
                '!PAYMENTDATE!', this.datePipe.transform(this.plan.nextScheduledPaymentDate, 'M/d/y'));
        }
        if (this.remainingPaymentsText != null && this.plan.numberOfRemainingPayments > 0) {
            this.remainingPaymentsText = this.remainingPaymentsText.replace(
                '!REMAININGPAYMENTS!', this.plan.numberOfRemainingPayments.toString());
        }
        if (this.recentPaymentDateText != null && this.plan.mostRecentPayment != null) {
            this.recentPaymentDateText = this.recentPaymentDateText.replace(
                '!PAYMENT!', this.currencyPipe.transform(this.plan.mostRecentPayment.totalAmount, 'USD', 'symbol'));
            this.recentPaymentDateText = this.recentPaymentDateText.replace(
                '!PAYMENTDATE!', this.datePipe.transform(this.plan.mostRecentPayment.paymentDate, 'M/d/y'));
        }
    }

    private changePaymentMethod(walletGuid: string): void {
        this.updateErrorExists = false;
        const newPaymentMethod = this.paymentMethods.filter(x => x.tokenGUID == walletGuid)[0];
        this.plan.storedPaymentMethod = newPaymentMethod;
        // transform cardType enum back to string and capitalize first letter
        if (newPaymentMethod.cardType != null) {
            this.plan.paymentCardType = this.capitalizeFirstLetter(CreditCardIssuerType[newPaymentMethod.cardType]);
        }
        // if toggling back to their original payment method, don't need to leave up save/cancel buttons
        this.paymentMethodChangesExist = (this.oldPaymentMethod.tokenGUID != newPaymentMethod.tokenGUID);
    }

    private toggleAgreementDetails() {
        this.detailsExpanded = !this.detailsExpanded;
        this.detailArrowRotateDegrees = Math.abs(this.detailArrowRotateDegrees - 180);
    }

    private capitalizeFirstLetter(value: string): string {
        return value.charAt(0).toUpperCase() + value.slice(1);
    }

    private doneLoading(): void {
        this.loading = false;
    }

    private emailInputUpdated(): void {
        this.emailErrorExists = false;
        this.updateErrorExists = false;
        this.emailAddressChangesExist = true;
        if (!this.componentService.emailRegex.test(this.plan.emailAddress.toLocaleLowerCase())) {
            this.emailErrorExists = true;
        }
    }

    private changeEmailOrPhone(): void {
        this.consumerProfileModal.openModal();
    }

    onGuarantorEmailsEmitted(guarantorEmails: GuarantorEmailViewModel[]): void {
        if (guarantorEmails.length === 1) {
            this.emailAddress = guarantorEmails[0].emailAddress;
        }
    }

    onEmailUpdated(emailAddress: string): void {
        this.emailAddress = emailAddress;
        this.emailErrorExists = false;
    }

    private pendingAgreementChangesExist(): boolean {
        return this.paymentMethodChangesExist || this.emailAddressChangesExist;
    }

    private getStatementPdfLink() {
        const getDocumentUrl: string = this.config.getConfig('documentPdfPath');
        return getDocumentUrl + '?t=' + this.componentService.storageService.retrieve('token') + '&did=' + this.plan.documentGUID + '&v=MySecureBill';
    }

    private isTraditionalRecurring(): boolean {
        return this.plan.type == RecurringPlanType.recurring;
    }
}
