import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ICommunicationPreferences } from '../../../models/communicationPreferences';
import { ICommunicationPreferenceChange } from '../../../models/communicationPreferenceType';
import { GuarantorEmail } from '../../../models/guarantoremail';
import { GuarantorEmailViewModel } from '../../../models/guarantoremailviewmodel';
import { PhonePipe } from '../../../pipes/Phone/phone.pipe';
import { ComponentService } from '../../../services/component/component.service';
import { ConsumerService } from '../../../services/consumer/consumer.service';
import { ConsumerContactDetailsModalComponent } from '../ConsumerContactDetailsModal/consumercontactdetailsmodal.component';

@Component({
    selector: 'communication-preference-selection',
    template: require('./communicationpreferenceselection.component.html'),
    styles: [require('./communicationpreferenceselection.component.css')]
})
export class CommunicationPreferenceSelectionComponent implements OnInit {
    customerAccountID: string;
    emailAddress: string;

    // Used for unsubscribed number check
    phoneNumber: string;

    // Used for UI
    phoneNumberPretty: string;

    // Content items
    autoPayHeadlineLabel: string;
    autoPayHeadlineSubheader: string;
    autoPayHeadlineSubheaderLink: string;
    autoPayEmailRadioButton: string;
    autoPayTextRadioButton: string;
    autoPayNoEmailStored: string;
    autoPayNoPhoneNumberStored: string;
    profileSmsNumberBlacklistedText: string;

    isUnsubscribedPhoneNumber = false;
    isLoaded = false;
    isEmailRadioButtonDisabled = false;
    isSmsRadioButtonDisabled = false;

    radioStyle = 'radio-icon-wrap';
    iconForEmail = 'radio_button_unchecked';
    iconForSMS = 'radio_button_unchecked';

    selectedPref = -1;

    emailViewModels: GuarantorEmailViewModel[] = [];

    private ngUnsubscribe: Subject<any> = new Subject();

    communicationPreferences: ICommunicationPreferences;

    constructor(private componentService: ComponentService,
                private consumerService: ConsumerService,
                private pipe: PhonePipe) {

    }

    // NOTE ON @Input() agreementType: string;
    // Make sure you are passing in the correct term here. Based on the
    // CommunicationPreferenceType(s) in communicationPreferenceType.ts:
    // AutoPay Plans = AutoPay
    // Recurring Plans = Recurring
    // Payment Plans = PaymentPlan
    @Input() agreementType: string;
    @Input() hasAgreements: boolean;
    @Input() enableSMS: boolean;
    @Input() noPreferenceSelected: boolean;

    // These are emitted below for use by the AutopayEnrollmentComponent
    @Output() public missingEmailOrPhone: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() public emailForNewAgreement: EventEmitter<string> = new EventEmitter<string>();
    @Output() public phoneForNewAgreement: EventEmitter<string> = new EventEmitter<string>();
    @Output() public preferenceForNewAgreement:
        EventEmitter<ICommunicationPreferenceChange[]> = new EventEmitter<ICommunicationPreferenceChange[]>();

    @ViewChild('consumerProfileModal', { static: false }) consumerProfileModal: ConsumerContactDetailsModalComponent;

    ngOnInit(): void {
        this.loadComponent();

        this.isLoaded = true;
    }

    async loadComponent() {
        const consumer = await this.consumerService.getConsumer();

        this.customerAccountID = consumer.accountID;
        this.phoneNumber = consumer.phone;

        if (!!this.customerAccountID) {
            this.communicationPreferences = await this.consumerService
            .getConsumerCommunicationPreferences(this.customerAccountID);

            // Set email if email exists and this isn't a grandafathered scenario
            const emails = await this.consumerService.getGuarantorEmails();
            if (emails && emails.length > 0) {
                this.setEmail(emails);
            }

            if (this.phoneNumber) {
                this.phoneNumberPretty = this.pipe.transform(this.phoneNumber);
                this.isUnsubscribedPhoneNumber = (await this.getUnsubscribedPhoneNumber()).valueOf();
            }

            if (this.hasAgreements) {
                // Set current communication preference
                // Add new agreement types as this is used outside of AutoPay
                if (this.agreementType === 'AutoPay') {
                    this.selectedPref = this.communicationPreferences.autopaySmsEnabled ? 2 : 1;
                }

                // This is an exisiting agreement, so a preference should exist
                this.styleSelectedRadioButton(this.selectedPref);

                // Need to emit the incoming pref settings in case no changes are made
                this.emitAllChanges(
                    this.getPreferenceChanges(
                        this.selectedPref === 1,
                        this.customerAccountID
                        )
                    );
            } else {
                if (!this.enableSMS) {
                    this.selectedPref = 1;

                    // New Enrollment && SMS not enabled = Auto select Email
                    this.styleSelectedRadioButton(this.selectedPref);

                    // Need to emit the incoming pref settings in case no changes are made
                    this.emitAllChanges(
                        this.getPreferenceChanges(true, this.customerAccountID)
                    );
                }
            }

            this.setContentItems();
        }
    }

    private setEmail(emails: GuarantorEmail[]): void {
        const validEmails: GuarantorEmail[] = [];
        for (const email of emails) {
            if (email.guarantorEmailAddressUsed === false && email.emailAddress === '') {
                continue;
            }

            validEmails.push(email);
        }

        let checkedEmail = '';
        let allEmailsTheSame = true;
        for (const validEmail of validEmails) {
            if (checkedEmail === '' && allEmailsTheSame) {
                checkedEmail = validEmail.emailAddress;
            } else {
                if (checkedEmail !== validEmail.emailAddress) {
                    allEmailsTheSame = false;
                }
            }
        }

        if (validEmails && allEmailsTheSame) {
            this.emailAddress = checkedEmail;
        }
    }

    private async getUnsubscribedPhoneNumber() {
        const unsubscribedPhoneNumberListResponse =
            await this.consumerService.verifyNumberNotBlacklisted(this.phoneNumber);

        if (unsubscribedPhoneNumberListResponse.messages &&
            unsubscribedPhoneNumberListResponse.messages.length) {
            return true;
        }

        return false;
    }

    private setContentItems(): void {
        this.componentService.contentService.content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(c => {
                this.autoPayHeadlineLabel = this.componentService.contentService.tryGetContentItem(c, 'autopay', 'pageText', 'autoPayHeadlineLabel').text;
                this.autoPayHeadlineSubheader = this.componentService.contentService.tryGetContentItem(c, 'autopay', 'pageText', 'autoPayHeadlineSubheader').text;
                this.autoPayHeadlineSubheaderLink = this.componentService.contentService.tryGetContentItem(c, 'autopay', 'pageText', 'autoPayHeadlineSubheaderLink').text;
                this.autoPayEmailRadioButton = this.componentService.contentService.tryGetContentItem(c, 'autopay', 'pageText', 'autoPayEmailRadioButton').text;
                this.autoPayTextRadioButton = this.componentService.contentService.tryGetContentItem(c, 'autopay', 'pageText', 'autoPayTextRadioButton').text;
                this.autoPayNoEmailStored = this.componentService.contentService.tryGetContentItem(c, 'autopay', 'error', 'autoPayNoEmailStored').text;
                this.autoPayNoPhoneNumberStored = this.componentService.contentService.tryGetContentItem(c, 'autopay', 'error', 'autoPayNoPhoneNumberStored').text;
                this.profileSmsNumberBlacklistedText = this.componentService.contentService.tryGetContentItem(c, 'profile', 'sms', 'profileSmsNumberBlacklistedText').text;
            });
    }

    public async onPreferenceChange(selectedRadioButton: number) {
        // Reset on preference change
        this.missingEmailOrPhone.emit(false);

        // Preference is being set - turn off the red
        // coloring of the radio button(s) if it is on
        this.noPreferenceSelected = false;

        this.selectedPref = selectedRadioButton;
        this.radioStyle = 'radio-icon-wrap';

        let changes: ICommunicationPreferenceChange[] = [];

        if (selectedRadioButton === 1) {
            if (this.emailAddress) {
                changes = this.getPreferenceChanges(true, this.customerAccountID);

                if (this.hasAgreements && this.isLoaded === true) {
                    this.isSmsRadioButtonDisabled = true;

                    // Update Email Preference now
                    this.updateCommunicationPreference(changes);
                    this.isSmsRadioButtonDisabled = false;
                }
            } else {
                this.missingEmailOrPhone.emit(true);
            }
        } else {
            if (this.phoneNumber) {
                this.isUnsubscribedPhoneNumber = (await this.getUnsubscribedPhoneNumber()).valueOf();

                if (this.isUnsubscribedPhoneNumber) {
                    // Checks the SMS radio button even though it hasn't saved the SMS preference
                    this.styleSelectedRadioButton(selectedRadioButton);

                    return;
                }

                changes = this.getPreferenceChanges(false, this.customerAccountID);

                if (this.hasAgreements && this.isLoaded === true) {
                    this.isEmailRadioButtonDisabled = true;

                    // Update SMS Preference now
                    this.updateCommunicationPreference(changes);
                    this.isEmailRadioButtonDisabled = false;
                }
            } else {
                this.missingEmailOrPhone.emit(true);
            }
        }

        this.emitAllChanges(changes);
        this.styleSelectedRadioButton(selectedRadioButton);
    }

    // Create the list of changes the comm pref update is going to need
    private getPreferenceChanges(isEmail: boolean, caid: string): ICommunicationPreferenceChange[] {
        if (isEmail) {
            return [
                {
                    type: this.agreementType + 'Email',
                    newValue: true,
                    customerAccountID: caid
                },
                {
                    type: this.agreementType + 'Sms',
                    newValue: false,
                    customerAccountID: caid
                }
            ];
        } else {
            return [
                {
                    type: this.agreementType + 'Email',
                    newValue: false,
                    customerAccountID: caid
                },
                {
                    type: this.agreementType + 'Sms',
                    newValue: true,
                    customerAccountID: caid
                }
            ];
        }
    }

    // Make the changes for email and phone when the comm prefs are updated
    private emitEmailAndPhoneChanges() {
        this.emailForNewAgreement.emit(this.emailAddress);
        this.phoneForNewAgreement.emit(this.phoneNumberPretty);
    }

    // Make the changes the consumer made available to AutopayEnrollmentComponent
    // These are only used for new enrollments when no previous agreements exist
    private emitAllChanges(changes: ICommunicationPreferenceChange[]) {
        this.emailForNewAgreement.emit(this.emailAddress);
        this.phoneForNewAgreement.emit(this.phoneNumberPretty);
        this.preferenceForNewAgreement.emit(changes);
    }

    // This visually changes the radio button
    // from unchecked to checked and vice versa
    styleSelectedRadioButton(selectedRadioButton: number) {
        if (selectedRadioButton === 1) {
            this.iconForEmail = 'radio_button_checked';
            this.iconForSMS = 'radio_button_unchecked';
        } else {
            this.iconForEmail = 'radio_button_unchecked';
            this.iconForSMS = 'radio_button_checked';
        }
    }

    changeEmailOrPhone(): void {
        this.consumerProfileModal.openModal();
    }

    async onEmailUpdated(emailAddress: string): Promise<void> {
        this.emailAddress = emailAddress;

        // We don't update on !hasAgreements because a FIRST agreement isn't created yet
        if (this.hasAgreements) {
            this.updatePreferenceOnEmailChange();
        }

        this.emitEmailAndPhoneChanges();

        // Set to false in case empty email error was showing
        this.missingEmailOrPhone.emit(false);
    }

    async onPhoneNumberUpdated(phoneNumber: string): Promise<void> {
        this.phoneNumber = phoneNumber;
        this.phoneNumberPretty = this.pipe.transform(phoneNumber);

        // We don't update on !hasAgreements because a FIRST agreement isn't created yet
        if (this.hasAgreements) {
            this.updatePreferenceOnPhoneChange(this.communicationPreferences.autopaySmsEnabled);
        }

        this.emitEmailAndPhoneChanges();
        // Set to false in case empty phone number error was showing
        this.missingEmailOrPhone.emit(false);

        this.isUnsubscribedPhoneNumber = (await this.getUnsubscribedPhoneNumber()).valueOf();
    }

    // Scenario - user doesn't have a phone saved.
    // They select the TEXT radio button anyway.
    // They open modal and save a phone number.
    // They return to the EMAIL/TEXT selection area and so we
    // don't force them to select EMAIL and then back to TEXT
    // (to trigger a SMS preference change) we just update the
    // SMS preference on return to the preference selection area.
    private updatePreferenceOnPhoneChange(smsEnabled: boolean) {
        // We don't update on !hasAgreements because a FIRST agreement isn't created yet
        if (!smsEnabled && this.selectedPref === 2 && this.hasAgreements) {
            this.updateCommunicationPreference(this.getPreferenceChanges(false, this.customerAccountID));
        }

        this.emitAllChanges(this.getPreferenceChanges(false, this.customerAccountID));
    }

    // Scenario - see comments on updatePreferenceOnPhoneChange()
    // and apply to this method only for emails this time
    private updatePreferenceOnEmailChange() {
        const caid = this.customerAccountID;

        // We don't update on !hasAgreements because a FIRST agreement isn't created yet
        if (this.selectedPref === 1 && this.hasAgreements) {
            this.updateCommunicationPreference(this.getPreferenceChanges(true, caid));
        }

        this.emitAllChanges(this.getPreferenceChanges(true, caid));
    }

    private async updateCommunicationPreference(changes: ICommunicationPreferenceChange[]) {
        this.communicationPreferences = await this.consumerService.updateConsumerCommunicationPreference(
            this.customerAccountID, changes
        );
    }

    radioClass(): string {
        return this.radioStyle;
    }
}
