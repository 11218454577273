
export class ConnectMessage  {
    constructor(
        imageID?: number,
        title?: string,
        description?: string,
        link?: string,
        linkText?: string,
        domainConnectMessageID?: number,
        messageType?: MessageType,
    ) {
        this.imageID = imageID;
        this.description = description;
        this.title = title;
        this.link = link;
        this.linkText = linkText;
        this.domainConnectMessageID = domainConnectMessageID;
        this.messageType = messageType;
    }

    imageID: number;
    title: string;
    description: string;
    link: string;
    linkText: string;
    domainConnectMessageID: number;
    messageType: MessageType;
}

export const TEST_CONNECTMESSAGE: ConnectMessage = new ConnectMessage(
    123,
    'description',
    'title',
    'link',
    'linkText'
);

export enum MessageType {
    imageandtext = 0,
    text = 1
}
