import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, ComponentFactoryResolver, EventEmitter, Input, NgZone, OnDestroy,
    OnInit, Output, TemplateRef, ViewChild,
    ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppConfig } from '../../../app.config';
import { FloatingChildItem, PayableEntry, PayableEntryType } from '../../../models/PayableEntry';
import { ComponentService } from '../../../services/component/component.service';
import { ConsumerService } from '../../../services/consumer/consumer.service';
import { LoggingLevel } from '../../../services/logging/logging.service';
import { CheckboxComponent } from '../../Controls/Checkbox/checkbox.component';

@Component({
    selector: 'payable-entry',
    template: require('./payableentry.component.html'),
    styles: [require('./payableentry.component.css')]
})
export class PayableEntryComponent implements OnInit, OnDestroy {

    constructor(
        private componentService: ComponentService,
        private config: AppConfig,
        private consumerService: ConsumerService,
        private currencyPipe: CurrencyPipe,
        private datePipe: DatePipe,
        private parentRouter: Router,
        private ngZone: NgZone
    ) { }

    @Input() payableEntry: PayableEntry;
    @Input() isOneTimePayment = false;
    @Input() headerText: string = null;
    @Input() paymentInputTabIndex = 0;
    @Input() currentBalanceDisclaimer: string;
    @Input() anyAccountPaymentPlanEligible = false;

    autopayWarning: string;
    backToPaymentPlanText: string;
    bucketAdjustmentDisabledDisclaimer: string;
    bucketArrowRotateDegrees: number;
    displayChildItems = false;
    dueDateText: string;
    exceedStatementBalance: string;
    externalPaymentPlanText: string;
    externalPlanUserPayingDifferentAmount = false;
    invalidAmountError: string;
    merchantProfileLoaded = false;
    nonPayableItemText: string;
    payableItemCheckboxName: string;
    payDifferentAmountText: string;
    paymentAmountErrorMessage: string;
    paymentAmountMax = 0;
    paymentPlanEligibilityLinkText: string;
    paymentPlanWarningText: string;
    paymentPlansAllowedForDomain = false;
    promptPayAvailable = false;
    promptPayAvailableMessage: string;
    promptPayBucketDisabledDisclaimer: string;
    promptPayCalculated = false;
    promptPayShortPayDisclaimer: string;
    promptPayUnavailableMessage: string;
    quickPayBalanceDetails = false;
    showBucketAdjustmentDisabledDisclaimer = false;
    showPromptPayShortPayDisclaimer = false;
    viewStatementLinkText: string;
    viewDocumentLinkRoute: string;

    private ngUnsubscribe: Subject<any> = new Subject();

    @Output() itemPaymentAmountChangedEvent: EventEmitter<PayableEntry> = new EventEmitter<PayableEntry>();
    @Output() itemCheckedEvent: EventEmitter<PayableEntry> = new EventEmitter<PayableEntry>();
    @Output() emitOverPaymentEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit() {
        this.componentService.contentService.content$
        .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((content: any) => {
                this.handleContentItems(content);
            });

        this.setErrorMessages();
        this.setPaymentAmountMax();

        this.bucketArrowRotateDegrees = 0;
        this.payableItemCheckboxName = 'payToItem' + this.payableEntry.id;
        if (!!this.payableEntry.documentGUID) {
            const getDocumentUrl: string = this.config.getConfig('documentPdfPath');
            this.viewDocumentLinkRoute = getDocumentUrl + '?t=' +
                this.componentService.storageService.retrieve('token') + '&did=' + this.payableEntry.documentGUID + '&v=MySecureBill';
        }

        if (!!this.payableEntry.chargeDate) {
            this.computeChildItemChargeDetailsDescription();
        }

        this.componentService.domainService.domainInfo$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
            domainInfo => {
                this.paymentPlansAllowedForDomain = domainInfo && domainInfo.enablePaymentPlans && this.anyAccountPaymentPlanEligible;
                this.quickPayBalanceDetails = domainInfo && domainInfo.enableQuickPayBalanceDetails;
            });
    }

    paymentAmountChange(): void {
        this.setErrorMessages();
        if (!!this.payableEntry.paymentAmount) {
            if (!!this.payableEntry.childItems) {
                if (this.promptPayIsPossible() && this.payableEntry.paymentAmount >= this.payableEntry.promptPayAmount) {
                    this.showPromptPayShortPayDisclaimer = false;
                    this.bucketUIToggle(true);
                } else if (this.promptPayIsPossible()
                    && this.payableEntry.paymentAmount < this.payableEntry.promptPayAmount
                    && this.payableEntry.paymentAmount > 0) {
                    this.showPromptPayShortPayDisclaimer = true;
                    this.bucketUIToggle(true);
                } else {
                    this.showPromptPayShortPayDisclaimer = false;
                }
                this.distributePaymentAmountToBuckets();
            }
            this.payableEntry.payToItem = true;
            this.itemCheckedEvent.emit(this.payableEntry);
        } else {
            this.clearPayableEntryAndChildItems();
            this.itemCheckedEvent.emit(this.payableEntry);
        }
        this.checkOverPayment();
        this.itemPaymentAmountChangedEvent.emit(this.payableEntry);
    }

    /**
     * Listener function for the child items, bound in the HTML
     *
     * @param {boolean} bucketAmountLostFocus
     *
     * @memberof PayableEntryComponent
     */
    childPaymentAmountChange(bucketAmountLostFocus: boolean) {
        this.sumChildItems();

        // TODO: this is a section to be revisited with statements and prompt-pay
        // if (bucketAmountLostFocus) {
        //     if (!this.promptPayIsPossible() && this.payableEntry.paymentAmount <= this.payableEntry.payableAmount) {
        //         this.payableEntry.childItems.filter((x) => x.payableAmount > 0).forEach((childItem) => {
        //             if (childItem.paymentAmount > childItem.payableAmount) {
        //                 childItem.paymentAmount = childItem.payableAmount;
        //             }
        //         });
        //     } else if (this.promptPayIsPossible() && this.payableEntry.paymentAmount <= this.payableEntry.promptPayAmount) {
        //         this.payableEntry.childItems.filter((x) => x.payableAmount > 0).forEach((childItem) => {
        //             if (childItem.paymentAmount > childItem.promptPayAmount) {
        //                 childItem.paymentAmount = childItem.promptPayAmount;
        //             }
        //         });
        //     }

        //     this.sumChildItems();
        // }

        this.checkOverPayment();
        this.payableEntry.payToItem = this.payableEntry.paymentAmount > 0;
        this.itemCheckedEvent.emit(this.payableEntry);
    }

    private sumChildItems() {
        if (!!this.payableEntry.childItems) {
            const childItemsSum = this.payableEntry.childItems.filter(
                (x) => !x.isDisabled).reduce((sum, val) => sum + Math.abs(val.paymentAmount), 0);
            this.payableEntry.paymentAmount = childItemsSum;
        }
    }

    emitOverPayment(canMakePayment: boolean): void {
        this.emitOverPaymentEvent.emit(canMakePayment);
    }

    toggleDisplayChildItems(): void {
        // If maxoverpayment is 0 or null and they overpay the doc, we don't want them opening the buckets.
        // But if they invalidate it at the bucket level, they'll need to close them. Thus the inclusion of not details expanded
        // Also, if the overpayment bucket is the only bucket and they aren't overpaying yet, we don't want it opening.
        if ((!this.payableEntryCanBeOverpaid() && !this.displayChildItems &&
                this.payableEntry.paymentAmount > this.payableEntry.payableAmount)
            || this.floatingChildItemIsTheOnlyChildItem() && this.payableEntry.paymentAmount < this.payableEntry.payableAmount) {
            return;
        }

        // If they can prompt pay and the amounts aren't touched, prefill so they don't ruin the discount for themselves
        if (!this.displayChildItems
            && (!this.isStatementType() || (this.isStatementType() && this.promptPayIsPossible()))
            && !this.payableEntry.payToItem
            && !this.payableEntry.paymentAmount) {
            this.payableEntry.payToItem = true;
            this.checkboxChecked();
        }

        this.displayChildItems = !this.displayChildItems;
        this.bucketArrowRotateDegrees = Math.abs(this.bucketArrowRotateDegrees - 180);
    }

    private floatingChildItemIsTheOnlyChildItem() {
        const childItemsNotNull = this.payableEntry.childItems != null;
        if (!childItemsNotNull) {
            return false;
        }
        const childItemsExist = this.payableEntry.childItems.length > 0;
        const childItemsNotIncludingFloaterIsZero = this.payableEntry.childItems.filter(
            (childItem) => childItem.id !== FloatingChildItem.id).length === 0;

        return childItemsNotNull && childItemsExist && childItemsNotIncludingFloaterIsZero;
    }

    goToPaymentPlans() {
        this.ngZone.run(() => this.parentRouter.navigateByUrl('/paymentplans/new')).then();
    }

    /**
     * Called by checkbox component when the checkbox is clicked
     *
     * @param {CheckboxComponent} checkboxTarget
     *
     * @memberOf PayableEntryComponent
     */
    checkboxChecked(checkboxTarget: CheckboxComponent = null): void {
        let itemClicked: PayableEntry;
        if (checkboxTarget != null) {
            itemClicked = checkboxTarget.value as PayableEntry;
        } else {
            itemClicked = this.payableEntry;
        }

        if (this.payableEntry.payToItem) {
            if (this.payableEntry.paymentAmount == null || this.payableEntry.paymentAmount <= 0) {
                if (this.promptPayIsPossible()) {
                    this.payableEntry.paymentAmount = this.payableEntry.promptPayAmount;
                } else if (this.documentHasExternalPaymentPlan() && !this.externalPlanUserPayingDifferentAmount) {
                    this.payableEntry.paymentAmount = this.payableEntry.externalPaymentPlanAmount;
                } else {
                    if (this.payableEntry.initialPayment) {
                        this.payableEntry.paymentAmount = this.payableEntry.initialPaymentAmount;
                    } else {
                        this.payableEntry.paymentAmount = this.payableEntry.payableAmount;
                    }
                }
            }
            if (!!this.payableEntry.childItems) {
                if (!this.documentHasExternalPaymentPlan() || this.externalPlanUserPayingDifferentAmount) {
                    this.distributePaymentAmountToBuckets();
                } else {
                    this.payableEntry.childItems.forEach((x) => {
                        x.payToItem = true;
                    });
                }
                if (this.promptPayIsPossible()) {
                    this.bucketUIToggle(true);
                    this.showPromptPayShortPayDisclaimer = false;
                }
            }
        } else {
            this.payableEntry.paymentAmount = null;
            if (!!this.payableEntry.childItems) {
                if (!this.documentHasExternalPaymentPlan() || !!this.externalPlanUserPayingDifferentAmount) {
                    this.payableEntry.childItems.forEach((x) => {
                        x.paymentAmount = null;
                        x.payToItem = false;
                    });
                    this.showBucketAdjustmentDisabledDisclaimer = false;
                    this.payableEntry.childItems.forEach((x) => x.overpayDisableUI = false);
                } else {
                    this.payableEntry.childItems.forEach((x) => {
                        x.payToItem = false;
                    });
                }
            }
        }

        this.checkOverPayment();
        this.itemCheckedEvent.emit(itemClicked);
    }


    /**
     * Returns the merchant profile setting for how many account characters should be hidden.
     *
     * @returns {number}
     * @memberof PayableEntryComponent
     */
    hideAccountCharsIfNeedsObfuscation(): number {
        return (this.payableEntry.itemType === PayableEntryType.paymentplanbalance ||
                this.payableEntry.itemType === PayableEntryType.statement) &&
                this.payableEntry.merchantProfile != null
            ? this.payableEntry.merchantProfile.hideAccountChars
            : 0;
    }

    /**
     * The balance should be displayed if it is lower than the paymentAmount.
     *
     * @returns number
     *
     * @memberof PayableEntryComponent
     */
    getNextPayment(): number {
        if (this.payableEntry.itemType === PayableEntryType.statement) {
            return this.payableEntry.payableAmount;
        } else if (this.payableEntry.initialPayment) {
            return this.payableEntry.initialPaymentAmount;
        } else {
            return !!this.payableEntry.displayBalance ?
                this.payableEntry.displayBalance :
                Math.min(this.payableEntry.payableAmount, this.payableEntry.maxPayableAmount);
        }
    }

    /**
     * Ensures the max validation is never < 0 which would show the field is always invalid once touched.
     *
     * @memberof PayableEntryComponent
     */
    setPaymentAmountMax(): void {
        if (this.payableEntry.itemType === PayableEntryType.statement) {
            let previousPayments = 0;
            this.consumerService.getConsumerPaymentHistory()
                    .then(payments => {
                        payments.forEach(payment => {
                            payment.details.forEach(detail => {
                                if (detail.documentID === this.payableEntry.documentID) {
                                    previousPayments += detail.amount;
                                }
                            });
                        });

                        if (this.promptPayIsPossible()
                            && this.payableEntry.merchantProfile != null
                            && this.payableEntry.merchantProfile.maxOverpayment != null) {
                            if (this.isOneTimePayment) {
                                // Eventually, this logic (taking past payments into account) will
                                // be used for both oneTime and loggedIn payments,
                                // but for now, only oneTime payments will use it.
                                this.paymentAmountMax = Math.max(
                                    0, this.payableEntry.merchantProfile.maxOverpayment +
                                        this.payableEntry.promptPayAmount - previousPayments);
                            } else {
                                this.paymentAmountMax = this.payableEntry.merchantProfile.maxOverpayment +
                                    this.payableEntry.promptPayAmount;
                            }
                        } else if (this.payableEntry.merchantProfile != null && this.payableEntry.merchantProfile.maxOverpayment != null) {
                            if (this.isOneTimePayment) {
                                // Eventually, this logic (taking past payments into account)
                                // will be used for both oneTime and loggedIn payments,
                                // but for now, only oneTime payments will use it.
                                this.paymentAmountMax = Math.max(
                                    0, this.payableEntry.merchantProfile.maxOverpayment +
                                        this.payableEntry.payableAmount - previousPayments);
                            } else {
                                this.paymentAmountMax = this.payableEntry.merchantProfile.maxOverpayment + this.payableEntry.payableAmount;
                            }
                        } else {
                            this.paymentAmountMax = this.payableEntry.payableAmount;
                        }
                    });
        } else if (this.isGroupType()) {
            this.paymentAmountMax = this.payableEntry.payableAmount;
        } else {
            if (this.payableEntry.maxPayableAmount != null) {
                this.paymentAmountMax = this.payableEntry.maxPayableAmount;
            } else if (this.payableEntry.merchantProfile != null && this.payableEntry.merchantProfile.maxOverpayment != null) {
                // currently have no payment items that should use this value, but leaving in for future payable things
                this.paymentAmountMax = this.payableEntry.merchantProfile.maxOverpayment + this.payableEntry.payableAmount;
            } else {
                this.paymentAmountMax = this.payableEntry.payableAmount;
            }
        }
    }

    /**
     * Distributes the entered payment amount to the child items based on posting method and overpayment settings
     *
     * @memberof PayableEntryComponent
     */
    private distributePaymentAmountToBuckets() {
        if (this.payableEntry.paymentAmount != null && this.payableEntry.paymentAmount > 0 && this.payableEntry.payableAmount > 0) {
            let childItemAllocationAmount = 0;
            const payableChildItems = this.payableEntry.childItems.filter((x) => x.payableAmount >= 0);
            let promptPayOverpayAmount = 0;
            childItemAllocationAmount = this.payableEntry.paymentAmount;

            // If prompt pay, anything over that is an overpayment. Squirrel the extra amount away.
            if (this.promptPayIsPossible() && this.payableEntry.paymentAmount > this.payableEntry.promptPayAmount) {
                promptPayOverpayAmount = ComponentService.toDecimal(childItemAllocationAmount - this.payableEntry.promptPayAmount);
                childItemAllocationAmount = this.payableEntry.promptPayAmount;
            }

            const nonFloaterBuckets = payableChildItems.filter((x) => x.id !== FloatingChildItem.id);
            // If floater is the only bucket, only allocate overpayments to it.
            if (nonFloaterBuckets.length === 0) {
                childItemAllocationAmount = Math.max(childItemAllocationAmount - this.payableEntry.payableAmount, 0);
            } else {
                childItemAllocationAmount = this.setBucketAmountFromPercent(nonFloaterBuckets, childItemAllocationAmount);
                this.payableEntry.childItems.forEach((x) => x.payToItem = x.paymentAmount > 0);
            }

            // Now that normal distribution is done, put the prompt pay overpay back
            if (promptPayOverpayAmount > 0) {
                childItemAllocationAmount = childItemAllocationAmount + promptPayOverpayAmount;
            }

            if (childItemAllocationAmount > 0) {
                if (this.childItemCanBeOverpaid()) {
                    this.addOverpaymentToBucketsByPercent(payableChildItems, childItemAllocationAmount);
                    this.bucketUIToggle(true);
                } else if (this.payableEntryCanBeOverpaid()) {
                    // Add/fill floating bucket
                    if (payableChildItems.some((x) => x.id === FloatingChildItem.id)) {
                        const floatingBucket = payableChildItems.find((x) => x.id === FloatingChildItem.id);

                        floatingBucket.paymentAmount = childItemAllocationAmount;
                        floatingBucket.payToItem = true;
                        childItemAllocationAmount = 0;
                        this.bucketUIToggle(true);
                    } else {
                        this.componentService.loggingService.log(
                            'couldn\'t locate a floating bucket...something went wrong', LoggingLevel.error);
                    }
                } else {
                    // No overpayments allowed, but they're trying to.
                    childItemAllocationAmount = 0;
                    this.bucketUIToggle(true);
                }
            } else {
                // if there is a bucket, clear it just in case it has a remnant amount
                if (payableChildItems.filter((x) => x.id === FloatingChildItem.id).length > 0) {
                    const floatingBucket = payableChildItems.find((x) => x.id === FloatingChildItem.id);
                    floatingBucket.paymentAmount = 0;
                }
                // the one case we don't want to reset this is when they're paying == prompt pay amount
                if (this.payableEntry.paymentAmount !== this.payableEntry.promptPayAmount) {
                    this.bucketUIToggle(false);
                }
            }

        }
    }

    private setBucketAmountFromPercent(payableChildItems: PayableEntry[], bucketAllocationAmount: number): number {
        const bucketAllocationAmounts: number[] = [];

        // calculate amount to be allocated to each bucket by its percent
        payableChildItems.map((childItem) => childItem.percentAllocation).forEach((x) => {
            const percentOfDoc = (x / 100);
            // push that amount into the list based on the index of the current bucket.
            bucketAllocationAmounts.push(ComponentService.toDecimal(bucketAllocationAmount * percentOfDoc));
        });
        payableChildItems.forEach((bucket) => {
            // Get this bucket's amount from the list
            let amountToAllocate = bucketAllocationAmounts[payableChildItems.indexOf(bucket)];

            // We don't allow overpayment in this method
            if (this.promptPayIsPossible()) {
                if (amountToAllocate > bucket.promptPayAmount) {
                    amountToAllocate = bucket.promptPayAmount;
                }
            } else {
                if (amountToAllocate > bucket.payableAmount) {
                    amountToAllocate = bucket.payableAmount;
                }
            }

            // Only allocate if there's enough left
            if (bucketAllocationAmount > 0) {
                if (amountToAllocate > bucketAllocationAmount) {
                    amountToAllocate = bucketAllocationAmount;
                }
            } else {
                amountToAllocate = 0;
            }

            bucketAllocationAmount = ComponentService.toDecimal(bucketAllocationAmount - amountToAllocate);
            bucket.paymentAmount = amountToAllocate;
        });

        if (bucketAllocationAmount > 0 && payableChildItems.some((bucket) => bucket.paymentAmount < bucket.payableAmount)) {
            // Some buckets are not full and the percent split failed us...
            bucketAllocationAmount = this.allocateRemainingToUnderpaidChildItems(payableChildItems, bucketAllocationAmount);
        }

        return bucketAllocationAmount;
    }

    /**
     * When the percent split has not filled the buckets, but it's not necessarily an overpayment, refactor the allocations.
     *
     * @private
     * @param {PaymentBucket[]} payableChildItems
     * @param {number} childItemAllocationAmount
     * @returns {number}
     *
     * @memberof PayableEntryComponent
     */
    private allocateRemainingToUnderpaidChildItems(payableChildItems: PayableEntry[], childItemAllocationAmount: number): number {
        const underpaidChildItems = payableChildItems.filter((x) => x.paymentAmount < x.payableAmount);
        while (childItemAllocationAmount > 0 && underpaidChildItems.length > 0) {
            this.componentService.loggingService.log('current remaining ' + childItemAllocationAmount, LoggingLevel.debug);

            // try to subdivide what's left evenly
            let roundedPieceOfTotal = ComponentService.toDecimal(childItemAllocationAmount / underpaidChildItems.length);
            this.componentService.loggingService.log('new divided piece ' + roundedPieceOfTotal, LoggingLevel.debug);
            // if it's fractions of a penny, round up and start over.
            if (roundedPieceOfTotal < 0.01) {
                roundedPieceOfTotal = 0.01;
            }

            underpaidChildItems.forEach((childItem) => {
                // sometimes we get extra cents due to rounding, but we want to drop it by one so we can keep allocating
                if (roundedPieceOfTotal > childItemAllocationAmount) {
                    roundedPieceOfTotal -= 0.01;
                }
                if (childItemAllocationAmount - roundedPieceOfTotal >= 0) {
                    this.componentService.loggingService.log(
                        'allocating ' + roundedPieceOfTotal + ' to bucket ' + childItem.id, LoggingLevel.debug);
                    if (childItem.paymentAmount + roundedPieceOfTotal > childItem.payableAmount) {
                        roundedPieceOfTotal = childItem.payableAmount - childItem.paymentAmount;
                    }
                    childItem.paymentAmount += roundedPieceOfTotal;
                    // subtract what we added to the bucket from the total, but round to the nearest cent
                    childItemAllocationAmount = ComponentService.toDecimal(childItemAllocationAmount - roundedPieceOfTotal);
                }
            });
        }
        return childItemAllocationAmount;
    }

    private addOverpaymentToBucketsByPercent(payableChildItems: PayableEntry[], childItemAllocationAmount: number) {
        // keep running until there's no money left
        while (childItemAllocationAmount > 0) {
            const childItemAllocationAmounts: number[] = [];
            // calculate amount to be allocated to each bucket by its percent
            payableChildItems.map((x) => x.percentAllocation).forEach((x) => {
                const percentOfDoc = (x / 100);
                // push that amount into the list based on the index of the current bucket.
                childItemAllocationAmounts.push(ComponentService.toDecimal(childItemAllocationAmount * percentOfDoc));
            });
            // iterate through the buckets
            payableChildItems.forEach((childItem) => {
                // get this buckets amount
                let amountToAllocate = childItemAllocationAmounts[payableChildItems.indexOf(childItem)];
                if (childItemAllocationAmount > 0) {
                    // sometimes we have an extra cent due to rounding, here we remove it
                    if (amountToAllocate > childItemAllocationAmount) {
                        amountToAllocate -= 0.01;
                    }
                    // sometimes the amounts are fractional cents, if that happens, we round up
                    if (amountToAllocate < 0.01) {
                        amountToAllocate = 0.01;
                    }
                    // add the new amount to the bucket amount
                    childItem.paymentAmount += amountToAllocate;
                    // remove what we added from the total
                    childItemAllocationAmount = ComponentService.toDecimal(childItemAllocationAmount - amountToAllocate);
                }
            });
        }
    }

    private childItemCanBeOverpaid(): boolean {
        return this.payableEntry.merchantProfile != null
            && this.payableEntry.merchantProfile.detailOverpayments != null
            && this.payableEntry.merchantProfile.detailOverpayments;
    }

    private payableEntryCanBeOverpaid(): boolean {
        return this.payableEntry.merchantProfile != null
            && this.payableEntry.merchantProfile.maxOverpayment != null
            && this.payableEntry.merchantProfile.maxOverpayment > 0;
    }

    /**
     * Selects the whole number in the input field so the user can type a new number after checking pay and having the amount prefill.
     *
     * @param {*} $event
     *
     * @memberOf PayableEntryComponent
     */
    selectAllInputContent(event: any) {
        if (this.payableEntry.payToItem) {
            event.target.select();
        }
    }

    /**
     * Sets up the error messages based on the merchant profile settings. Must be tolerant of the merchantProfile not being set.
     *
     * @memberof PayableEntryComponent
     */
    setErrorMessages(): void {
        if (!!this.payableEntry.merchantProfile && !this.merchantProfileLoaded) {
            this.paymentAmountErrorMessage = this.invalidAmountError;
            this.merchantProfileLoaded = true;
        } else if (!this.merchantProfileLoaded) {
            this.paymentAmountErrorMessage = this.invalidAmountError;
        }

        if (this.promptPayEnabled()) {
            this.promptPayAvailableMessage = this.promptPayAvailableMessage.replace(
                '!PROMPTPAYDATE!', this.datePipe.transform(this.payableEntry.promptPayDueDate, 'MMMM d'));
            this.promptPayAvailableMessage = this.promptPayAvailableMessage.replace(
                '!PROMPTPAYAMOUNT!', this.currencyPipe.transform(this.payableEntry.promptPayAmount, 'USD', 'symbol'));
            this.promptPayUnavailableMessage = this.promptPayUnavailableMessage.replace(
                '!PROMPTPAYDATE!', this.datePipe.transform(this.payableEntry.promptPayDueDate, 'MMMM d'));
            this.promptPayUnavailableMessage = this.promptPayUnavailableMessage.replace(
                '!PROMPTPAYAMOUNT!', this.currencyPipe.transform(this.payableEntry.promptPayAmount, 'USD', 'symbol'));
        }
    }

    /**
     * If an overpayment has put a paymentAmount into the floating child item,
     * then we will show it.
     *
     * @returns {boolean}
     *
     * @memberof PayableEntryComponent
     */
    showChildItem(): boolean {
        return this.payableEntry.id !== FloatingChildItem.id || this.payableEntry.paymentAmount > 0;
    }

    isFloatingBucket(): boolean {
        return this.payableEntry.id === FloatingChildItem.id;
    }

    isGroupType(): boolean {
        return this.payableEntry.itemType === PayableEntryType.accountBalanceGroup;
    }

    /**
     * Used by the front end to determine whether to show statement-specific elements
     *
     * @returns {boolean}
     *
     * @memberof PayableEntryComponent
     */
    isStatementType(): boolean {
        return this.payableEntry.itemType === PayableEntryType.statement;
    }

    /**
     * This only returns true if prompt pay is enabled and they haven't missed the date.
     *
     * @returns {boolean}
     * @memberof PayableEntryComponent
     */
    promptPayIsPossible(): boolean {
        if (!this.promptPayCalculated) {
            const todaysDate = new Date();
            todaysDate.setHours(0, 0, 0, 0);
            const promptPayDueDate = this.payableEntry.promptPayDueDate;
            this.promptPayCalculated = true;
            this.promptPayAvailable = this.payableEntry.promptPayAmount != null
                && this.payableEntry.promptPayAmount > 0
                && this.payableEntry.promptPayDueDate != null
                && todaysDate <= promptPayDueDate;
        }

        return this.promptPayAvailable;
    }

    /**
     * Regardless of whether they've missed the due date or not, if they are/were allowed to prompt pay, we trigger things.
     *
     * @returns {boolean}
     * @memberof PayableEntryComponent
     */
    promptPayEnabled(): boolean {
        return this.payableEntry.promptPayAmount != null && this.payableEntry.promptPayDueDate != null;
    }

    /**
     * Method to create bucket description,
     * originally from BasePaymentBucketComponent
     */
    private computeChildItemChargeDetailsDescription(): void {
        const parts: string[] = [];
        if (!!this.payableEntry) {
            if (this.payableEntry.accountNumber) {
                parts.push(this.payableEntry.accountNumber);
            }
            if (!!this.payableEntry.chargeDate
                && this.payableEntry.chargeDate instanceof Date
                && !isNaN(this.payableEntry.chargeDate.getTime())) {
                parts.push(this.datePipe.transform(this.payableEntry.chargeDate, 'MMM d'));
            }
        }
        this.payableEntry.itemDescription = parts.join(' - ');
    }

    documentHasExternalPaymentPlan(): boolean {
        return this.payableEntry.itemType === PayableEntryType.statement
            && this.payableEntry.merchantProfile != null
            && this.payableEntry.merchantProfile.allowExternalPaymentPlans
            && this.payableEntry.externalPaymentPlanAmount > 0;
    }

    private bucketUIToggle(disabled: boolean, showDisclaimer: boolean = true) {
        this.payableEntry.childItems.forEach((x) => x.overpayDisableUI = disabled);
        if (showDisclaimer) {
            this.showBucketAdjustmentDisabledDisclaimer = disabled;
        } else {
            this.showBucketAdjustmentDisabledDisclaimer = false;
        }
    }

    toggleEditableExternalPaymentPlan() {
        this.externalPlanUserPayingDifferentAmount = !this.externalPlanUserPayingDifferentAmount;

        if (this.externalPlanUserPayingDifferentAmount) {
            this.bucketUIToggle(false, false);
            this.clearPayableEntryAndChildItems();
            this.itemCheckedEvent.emit(this.payableEntry);
            this.payableEntry.childItems.forEach(childItem => {
                childItem.overpayDisableUI = false;
                childItem.paymentAmount = null;
                childItem.displayBalance = childItem.payableAmount;
            });
        } else {
            this.bucketUIToggle(true, false);
            this.clearPayableEntryAndChildItems();
            this.itemCheckedEvent.emit(this.payableEntry);
            this.payableEntry.childItems.forEach(childItem => {
                childItem.overpayDisableUI = true;
                childItem.paymentAmount = childItem.bucketInstallmentAmount;
                childItem.displayBalance = childItem.bucketInstallmentAmount;

                if (this.isOneTimePayment) {
                    // Reapply external plan amount
                    childItem.payToItem = true;
                    this.childPaymentAmountChange(true);
                }
            });
        }
    }

    /**
     * Disables this statements buckets based on the passed in boolean
     *
     * @param {boolean} disable
     *
     * @memberof PayableEntryComponent
     */
    enableChildItemRows(disable: boolean) {
        this.payableEntry.childItems.forEach((x) => x.isDisabled = disable);
        this.bucketUIToggle(disable);
        this.showBucketAdjustmentDisabledDisclaimer = false;
    }

    /**
     * Clears this statments input value, checkbox setting, and then does the same for its buckets if it has any
     *
     *
     * @memberof PayableEntryComponent
     */
    clearPayableEntryAndChildItems() {
        this.payableEntry.payToItem = false;
        this.payableEntry.paymentAmount = null;
        if (!!this.payableEntry.childItems) {
            this.payableEntry.childItems.forEach((childItem) => {
                childItem.payToItem = false;
                childItem.paymentAmount = null;
            });
        }
    }

    /**
     * Determines if the date column should be displayed
     *
     * @private
     * @returns boolean
     *
     * @memberof PayableEntryComponent
     */
    showPostDate(): boolean {
        if (this.merchantProfileLoaded) {
            return this.payableEntry.merchantProfile.enableDateColumn && !!this.payableEntry.postDate;
        }
        return true;
    }

    private handleContentItems(content: any) {
        this.autopayWarning = this.componentService.contentService.tryGetContentItem(content, 'payment', 'loggedInPayment', 'paymentPendingPaymentWarning').text;
        this.backToPaymentPlanText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'pageText', 'paymentBackToPaymentText').text;
        this.invalidAmountError = this.componentService.contentService.tryGetContentItem(content, 'home', 'error', 'homePagePaymentAmountError').text;
        this.exceedStatementBalance = this.componentService.contentService.tryGetContentItem(content, 'home', 'payment', 'payableStatementExceedsStatementBalanceText').text;
        this.externalPaymentPlanText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'pageText', 'paymentExternalPaymentPlanText').text;
        this.nonPayableItemText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'oneTimePayment', 'paymentBucketNonPayableItemText').text;
        this.payDifferentAmountText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'pageText', 'paymentPayDifferentAmountText').text;
        this.paymentPlanEligibilityLinkText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'pageText', 'paymentPaymentPlanEligibilityLinkText').text;
        this.paymentPlanWarningText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'loggedInPayment', 'paymentPaymentPlanPendingWarning').text;
        this.promptPayAvailableMessage = this.componentService.contentService.tryGetContentItem(content, 'payment', 'promptpay', 'paymentPromptPayAvailableMessage').text;
        this.promptPayUnavailableMessage = this.componentService.contentService.tryGetContentItem(content, 'payment', 'promptpay', 'paymentPromptPayUnavailableMessage').text;
        this.promptPayBucketDisabledDisclaimer = this.componentService.contentService.tryGetContentItem(content, 'payment', 'promptpay', 'paymentPromptPayBucketDisabledDisclaimer').text;
        // TODO - this may happen at the payment component level:
        // this.promptPayShortPayDisclaimer = this.componentService
        // .contentService.tryGetContentItem(content, 'payment', 'promptpay', 'paymentPromptPayShortPayDisclaimer');
        if (this.isOneTimePayment) {
            this.bucketAdjustmentDisabledDisclaimer = this.componentService.contentService.tryGetContentItem(content, 'payment', 'oneTimePayment', 'paymentBucketAdjustmentDisabledDisclaimer').text;
            this.dueDateText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'oneTimePayment', 'paymentDueDateLabel').text;
            this.viewStatementLinkText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'oneTimePayment', 'paymentViewStatementLinkText').text;
        } else {
            this.bucketAdjustmentDisabledDisclaimer = this.componentService.contentService.tryGetContentItem(content, 'payment', 'loggedInPayment', 'paymentBucketAdjustmentDisabledDisclaimer').text;
            this.dueDateText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'loggedInPayment', 'paymentDueDateLabel').text;
            this.viewStatementLinkText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'loggedInPayment', 'paymentViewStatementLinkText').text;
        }
    }

    checkOverPayment() {
        if (!!!this.paymentAmountMax) {
            this.setPaymentAmountMax();
        }

        // If the baskets are expanded and see if any are overpaying
        if (this.displayChildItems &&
            !!this.payableEntry.childItems && this.payableEntry.childItems.length > 1) {
            this.sumChildItems();
            for (const childPayableEntry of this.payableEntry.childItems) {
                if (childPayableEntry.paymentAmount === 0 ||
                    (childPayableEntry.paymentAmount > childPayableEntry.payableAmount)) {
                    this.emitOverPayment(false);
                    return;
                }
                else {
                    this.emitOverPayment(true);
                }
            }
        }
        else if (!!!this.displayChildItems) {
            if (this.payableEntry.paymentAmount === 0 || (this.payableEntry.paymentAmount > this.paymentAmountMax)) {
                this.emitOverPaymentEvent.emit(false);
                return;
            }
            else {
                this.emitOverPaymentEvent.emit(true);
            }
        }
    }
}
