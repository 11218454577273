import { Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IDomainInfo } from '../../models/domainInfo';
import { IOutage } from '../../models/outage';
import { ComponentService } from '../../services/component/component.service';
import { ConnectMessageService } from '../../services/connectmessage/connectmessage.service';

@Component({
    selector: 'default',
    template: require('./default.component.html'),
    styles: [require('./default.component.css')]
})
export class DefaultComponent implements OnInit, OnDestroy {
    showLoginForm = true;
    showResetForm = false;
    helpLinkText: string;
    passwordResetId: string;
    verificationEmailExpiredMessage: string;
    passwordResetEmailExpiredMessage: string;
    verificationEmailExpired = false;
    passwordResetEmailExpired = false;
    customerLogoImageUrl: string;
    domainInfo: IDomainInfo = null;
    outageNotifications: IOutage[] = [];
    content: any = null;
    errorMessage: string;
    sideBackgroundImage: string;
    letMeIn = false;
    chatLinkText: string;
    isMobile = false;
    wildCardLink: string;
    wildCardLinkText: string;

    triedSideBackgroundImageConnectMessage = false;
    sideBackgroundImagePlaceholder: string;

    @ViewChild('landingLeft', { static: true }) elementView: ElementRef;

    isLandingLeftScrolling: boolean;

    private ngUnsubscribe: Subject<any> = new Subject();

    constructor(
        private componentService: ComponentService,
        private activatedRoute: ActivatedRoute,
        private parentRouter: Router,
        private connectMessageService: ConnectMessageService,
        private ngZone: NgZone
    ) {}

    get isForgotUsernameOrPasswordResetOpen(): boolean {
        return this.componentService.isForgotUsernameOrPasswordResetOpen;
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit(): void {
        this.componentService.scrollPageToTop();
        // store values before clearing session
        this.verificationEmailExpired = this.componentService.storageService.retrieve('verificationemailexpired');
        this.activatedRoute.queryParams
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((params: Params) => {
                this.passwordResetId = params.passwordReset;
                this.passwordResetEmailExpired = (params.expired === 'true');
            });

        if (!!this.passwordResetId && !this.passwordResetEmailExpired) {
            this.showLoginForm = false;
            this.showResetForm = true;
        }

        if (this.componentService.storageService.retrieve('mode') === 'classiclogin') {
            this.showLoginForm = false;
        }
        this.letMeIn = this.componentService.letMeIn();

        this.componentService.domainService.getOutages()
            .then(
                outageList => {
                    if (!!outageList) {
                        this.outageNotifications = outageList;
                        if (outageList.some(x => new Date(x.outageStartDate) < new Date() && x.system === 'MySecureBill Maintenance')) {
                            if (!this.letMeIn) {
                                this.ngZone.run(() => this.parentRouter.navigateByUrl('/outage')).then();
                            }
                        }
                    }
                },
                error => this.errorMessage = (error as any)
            );

        this.componentService.storageService.clearSession();
        this.componentService.storageService.clear('paymentsource');
        this.componentService.domainService.domainInfo$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                domainInfo => {
                    this.domainInfo = domainInfo;
                });
        this.componentService.contentService.content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((content: any) => {
                this.helpLinkText = this.componentService.contentService.tryGetContentItem(
                    content, 'login', 'link', 'loginPageHelpLink').text;

                this.sideBackgroundImage = this.componentService.contentService.tryGetContentItem(content, 'login', 'image', 'loginPageDefaultBackgroundImage').text;
                this.verificationEmailExpiredMessage = this.componentService.contentService.tryGetContentItem(
                    content, 'global', 'error', 'emailVerificationExpiredMessage').text;

                this.passwordResetEmailExpiredMessage = this.componentService.contentService.tryGetContentItem(content, 'global', 'error', 'passwordResetEmailExpiredMessage').text;
                this.customerLogoImageUrl = this.componentService.contentService.tryGetContentItem(content, 'header', 'image', 'headerCustomerLogoImage').text;
                this.sideBackgroundImagePlaceholder = this.componentService.contentService.tryGetContentItem(content, 'login', 'image', 'loginPageDefaultBackgroundImage').text;
                this.chatLinkText = this.componentService.contentService.tryGetContentItem(
                    content, 'login', 'link', 'loginPageChatLink').text;

                this.wildCardLink = this.componentService.contentService.tryGetContentItem(
                    content, 'login', 'link', 'loginPageWildCardLink').text;

                this.wildCardLinkText = this.componentService.contentService.tryGetContentItem(content, 'login', 'linkText', 'loginPageWildCardLinkText').text;

                if (this.triedSideBackgroundImageConnectMessage && !this.sideBackgroundImage) {
                    this.sideBackgroundImage = this.sideBackgroundImagePlaceholder;
                }
            });

        this.connectMessageService.getConnectMessageImageUrl('defaultbackground').then(imageUrl => {
            this.triedSideBackgroundImageConnectMessage = true;
            if (imageUrl && imageUrl.length > 0) {
                this.sideBackgroundImage = imageUrl;
            } else {
                this.sideBackgroundImage = this.sideBackgroundImagePlaceholder;
            }
        });

        // need setTimeout because calling at this point will have values be
        // equal no matter what the screen size is. This fixes that by letting
        // the view render first
        setTimeout(() => this.setIsLandingLeftScrolling());

        this.isMobile = this.componentService.isMobileBrowser();
    }

    showLogin(val: boolean) {
        this.showLoginForm = !this.showLoginForm;
    }

    showReset(val: boolean) {
        this.showResetForm = val;
    }

    showVerificationExpiredMessage(): boolean {
        return this.verificationEmailExpired;
    }

    showPasswordResetExpiredMessage(): boolean {
        return this.passwordResetEmailExpired;
    }

    setIsLandingLeftScrolling(): void {
        this.isLandingLeftScrolling = this.elementView.nativeElement.scrollHeight > this.elementView.nativeElement.clientHeight;
    }

    forgotUsernameAndPasswordResetOpen(): boolean {
        return this.isForgotUsernameOrPasswordResetOpen;
    }
}
