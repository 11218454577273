import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EpicSsoAuth } from '../../models/epic-sso-auth';
import { EpicSsoTokenResponse } from '../../models/epic-sso-token-response';
import { SsoResponse } from '../../models/ssoResponse';
import { ConsumerService } from '../../services/consumer/consumer.service';
import { EpicSsoService } from '../../services/epic-sso/epic-sso.service';
import { LoggingLevel, LoggingService } from '../../services/logging/logging.service';
import { StorageService } from '../../services/storage/storage.service';

@Component({
    selector: 'epic-sso',
    template: `<div><a #authLink href="" target="_self"></a></div>`
})

export class EpicSsoComponent implements OnInit {
    constructor(
        private route: ActivatedRoute,
        private ssoService: EpicSsoService,
        private loggingService: LoggingService,
        private storageService: StorageService,
        private consumerService: ConsumerService,
        private parentRouter: Router,
        private ngZone: NgZone
    ) { }

    @ViewChild('authLink', { static: true }) authLink: ElementRef;

    private static getRedirectUri(): string {
        return encodeURIComponent(`${window.location.origin}/epicSso`);
    }

    async ngOnInit(): Promise<void> {
        const launchToken = this.route.snapshot.queryParams.launch;
        const redirectUri = EpicSsoComponent.getRedirectUri();
        const authCode = this.route.snapshot.queryParams.code;

        if (!!authCode) {
            this.loggingService.log('Epic SSO - EpicSsoComponent.ngOnInit, about to get token from Epic - authcode: ' + authCode
                , LoggingLevel.debug);
            // This is the code that will execute after the launch token is validated
            // and Epic redirects to the redirectUri we provide.
            const epicToken: EpicSsoTokenResponse = await this.ssoService.getTokenWithAuthCode(authCode, redirectUri);
            this.loggingService.log('Epic SSO - EpicSsoComponent.ngOnInit, about to SSO with Epic token', LoggingLevel.debug);
            const ultraToken: SsoResponse = await this.ssoService.sso('');

            this.storageService.store('token', ultraToken.token);

            // done with this connection model now.
            this.storageService.clear('epic-sso-model');

            // Not only does this return a consumer account,
            // it stores the consumer acct in local storage as well.
            // It's the latter behavior we want right now
            // so that it is stored before we navigate to / home.
            await this.consumerService.getConsumerAccount(
                ultraToken.token,
                ultraToken.customerAccountID
            );

            // All relevant data should be set now to clear auth guards.
            this.ngZone.run(() => this.parentRouter.navigateByUrl('/home')).then();
        } else {
            // This is the code that will execute when Ultra is launched from Epic.
            if (!!launchToken) {
                this.loggingService.log('Epic SSO - EpicSsoComponent.ngOnInit, about to send the launch token to the ConsumerAPI',
                    LoggingLevel.debug);
                const authDetails: EpicSsoAuth = await this.ssoService.getEpicConnectionDetails(launchToken, redirectUri);
                this.loggingService.log('Epic SSO - EpicSsoComponent.ngOnInit, response from CAPI', LoggingLevel.debug);

                this.authLink.nativeElement.href = authDetails.authBaseUri + '/' + authDetails.authParamString;
                this.authLink.nativeElement.click();
            } else {
                this.loggingService.log(
                    `No Epic launch token provided when attempting MyChart SSO: ${redirectUri}`,
                    LoggingLevel.error
                );
            }
        }
    }
}
