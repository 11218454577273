/* tslint:disable:triple-equals */
import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AccountBalanceTransactionType, IAccountBalanceHistory } from '../../models/accountbalancehistory';
import { IPaymentPlanBalance } from '../../models/paymentplanbalance';
import { ComponentService } from '../../services/component/component.service';
import { ConsumerService } from '../../services/consumer/consumer.service';
import { PayableService } from '../../services/payable/payable.service';
import { PaymentPlanService } from '../../services/paymentplan/paymentplan.service';

@Component({
    selector: 'balancedetails',
    template: require('./balancedetails.component.html'),
    styles: [require('./balancedetails.component.css')],
})
export class BalanceDetailsComponent implements OnInit, OnDestroy {
    private ngUnsubscribe: Subject<any> = new Subject();

    groupedBalances: IPaymentPlanBalance[] = [];
    groupedAccountBalanceHistory: IGroupedAccountHistory;
    yearList: Set<number>;
    isLoading: boolean;
    accountHistoryLoading: boolean;
    showAccountHistory: boolean;
    isOnPaymentPlan = false;
    headerText: string;
    subheaderText: string;
    paymentButtonLabel: string;
    backButtonLabel: string;
    accountOnPaymentPlanText: string;
    accountTableHeaderText: string;
    balanceTableHeaderText: string;
    notesTableHeaderText: string;
    viewHistoryLinkText: string;
    accountHistoryHeaderText: string;
    accountHistoryHeader: string;
    accountHistorySubheaderText: string;
    accountHistorySubheader: string;
    historyDateTableHeaderText: string;
    historyDescriptionTableHeaderText: string;
    historyChargesTableHeaderText: string;
    historyAdjustmentsTableHeaderText: string;
    historyPaymentsTableHeaderText: string;
    historyNotFoundText: string;
    asOfText = '';
    canMakePayment = false;

    constructor(
        private componentService: ComponentService,
        private consumerService: ConsumerService,
        private paymentPlanService: PaymentPlanService,
        private datePipe: DatePipe,
        private currencyPipe: CurrencyPipe,
        private parentRouter: Router,
        private payableService: PayableService,
        private ngZone: NgZone
    ) { }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit(): void {
        this.loadPaymentPlans();
        this.payableService.canMakePayment().then(can =>
            this.canMakePayment = can);
        this.componentService.contentService.content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((content: any) => {
                this.headerText = this.componentService.contentService.tryGetContentItem(content, 'balanceDetails', 'pageText', 'balanceDetailsHeaderText').text;
                this.subheaderText = this.componentService.contentService.tryGetContentItem(content, 'balanceDetails', 'pageText', 'balanceDetailsSubheaderText').text;
                this.paymentButtonLabel = this.componentService.contentService.tryGetContentItem(content, 'balanceDetails', 'pageText', 'balanceDetailsPaymentButtonText').text;
                this.backButtonLabel = this.componentService.contentService.tryGetContentItem(content, 'balanceDetails', 'pageText', 'balanceDetailsBackButtonText').text;
                this.accountOnPaymentPlanText = this.componentService.contentService.tryGetContentItem(
                    content, 'balanceDetails', 'pageText', 'balanceDetailsOnPaymentPlanText').text;

                this.accountTableHeaderText = this.componentService.contentService.tryGetContentItem(content, 'balanceDetails', 'pageText', 'balanceDetailsAccountTableHeaderText').text;
                this.balanceTableHeaderText = this.componentService.contentService.tryGetContentItem(content, 'balanceDetails', 'pageText', 'balanceDetailsBalanceTableHeaderText').text;
                this.notesTableHeaderText = this.componentService.contentService.tryGetContentItem(content, 'balanceDetails', 'pageText', 'balanceDetailsNotesTableHeaderText').text;
                this.viewHistoryLinkText = this.componentService.contentService.tryGetContentItem(content, 'balanceDetails', 'pageText', 'balanceDetailsViewHistoryLinkText').text;
                this.accountHistoryHeaderText = this.componentService.contentService.tryGetContentItem(
                    content, 'balanceDetails', 'pageText', 'balanceDetailsHistoryHeaderText').text;

                this.accountHistorySubheaderText = this.componentService.contentService.tryGetContentItem(content, 'balanceDetails', 'pageText', 'balanceDetailsHistorySubheaderText').text;
                this.historyDateTableHeaderText = this.componentService.contentService.tryGetContentItem(content, 'balanceDetails', 'pageText', 'balanceDetailsDateTableHeaderText').text;
                this.historyDescriptionTableHeaderText = this.componentService.contentService.tryGetContentItem(content, 'balanceDetails', 'pageText', 'balanceDetailsDescriptionTableHeaderText').text;
                this.historyChargesTableHeaderText = this.componentService.contentService.tryGetContentItem(content, 'balanceDetails', 'pageText', 'balanceDetailsChargesTableHeaderText').text;
                this.historyAdjustmentsTableHeaderText = this.componentService.contentService.tryGetContentItem(content, 'balanceDetails', 'pageText', 'balanceDetailsAdjustmentsTableHeaderText').text;
                this.historyPaymentsTableHeaderText = this.componentService.contentService.tryGetContentItem(content, 'balanceDetails', 'pageText', 'balanceDetailsPaymentsTableHeaderText').text;
                this.historyNotFoundText = this.componentService.contentService.tryGetContentItem(content, 'balanceDetails', 'pageText', 'balanceDetailsHistoryNotFound').text;
                this.asOfText = this.componentService.contentService.tryGetContentItem(content, 'balanceDetails', 'pageText', 'balanceDetailsAsOfText').text;
            });
    }

    loadPaymentPlans(): void {
        this.isLoading = true;
        this.consumerService.getConsumer().then((consumer) => {
            this.paymentPlanService.getBalanceDetails(consumer.accountID).then((balances) => {
                this.subheaderText = this.subheaderText.replace(
                    '!BALANCE!', this.currencyPipe.transform(
                        balances.reduce((sum, val) => sum + val.balance, 0).toString(), 'USD', 'symbol'));
                // get distinct accountNumbers from all balances returned by getBalanceDetails
                // this maps an array of accountNumbers out of balances, then reduces them
                // to a array that only contains distinct accountNumbers - if the x array
                // contains the next y string, just return x, else expand the x array to include y
                const accounts: string[] = balances.map(b => b.accountNumber)
                    .reduce((x: string[], y: string) => x.some(s => s == y) ? x : [...x, y], []);
                accounts.forEach(accountNumber => {
                    // for each distinct accountNumber, sum the balances, get the latest
                    // balanceAsOf date, and determine if at least one balance is on a payment plan
                    this.groupedBalances.push(balances.filter(b => b.accountNumber == accountNumber).reduce((summed, balance) => {
                        summed.balance = summed.balance + balance.balance;
                        summed.balanceAsOf = balance.balanceAsOf > summed.balanceAsOf ? balance.balanceAsOf : summed.balanceAsOf;
                        summed.paymentPlanGUID = this.balanceIsOnPaymentPlan(balance.paymentPlanGUID)
                            ? balance.paymentPlanGUID
                            : summed.paymentPlanGUID;
                        return summed;
                    }));
                });

                this.isLoading = false;
            });
        });
    }

    balanceIsOnPaymentPlan(paymentPlanGUID: string): boolean {
        return !!paymentPlanGUID && paymentPlanGUID !== this.componentService.NULL_GUID;
    }

    isCharge(type: AccountBalanceTransactionType): boolean {
        return type == AccountBalanceTransactionType.charge;
    }

    isAdjustment(type: AccountBalanceTransactionType): boolean {
        return type == AccountBalanceTransactionType.adjustment;
    }

    isPayment(type: AccountBalanceTransactionType): boolean {
        return type == AccountBalanceTransactionType.payment;
    }

    toggleAccountHistory(accountNumber: string): void {

        if (!!accountNumber) {
            // get account balance transaction history
            this.accountHistoryLoading = true;
            this.consumerService.getConsumer().then((consumer) => {
                this.paymentPlanService.getTransactionHistory(consumer.accountID, accountNumber).then((history) => {
                    // onPaymentPlan setting for tblAccountBalanceTransactions
                    this.isOnPaymentPlan = history.some(h => h.onPaymentPlan);
                    // group AccountBalanceTransactions by year into a [key: string]: IAccountBalanceHistory[] object
                    this.groupedAccountBalanceHistory = this.groupHistoryByYear(history);
                    // get list of years from the balance history, so that we can reference the
                    // groupedAccountBalanceHistory by index (e.g. groupedAccountBalanceHistory[year])
                    this.yearList = this.getYearsFromBalanceHistory(history);
                    this.accountHistoryHeader = this.accountHistoryHeaderText.replace('!ACCOUNTNUMBER!', accountNumber);
                    this.accountHistorySubheader = this.accountHistorySubheaderText.replace(
                        '!BALANCEASOF!', this.datePipe.transform(
                            this.groupedBalances.find(g => g.accountNumber == accountNumber).balanceAsOf, 'MM/dd/y'));
                    this.accountHistorySubheader = this.accountHistorySubheader.replace(
                        '!BALANCE!', this.currencyPipe.transform(this.groupedBalances.find(g => g.accountNumber == accountNumber).balance, 'USD', 'symbol'));

                    this.accountHistoryLoading = false;
                });
            });
        }
        this.showAccountHistory = !this.showAccountHistory;
    }

    goHome(): void {
        this.ngZone.run(() => this.parentRouter.navigateByUrl('/home')).then();
    }

    goPayment(): void {
        if (!this.canMakePayment) {
            return;
        }
        this.componentService.redirectToPayment();
    }

    getBalanceDetailNotes(balance: IPaymentPlanBalance): string {
        let notes = '';
        if (balance.isActive && balance.paymentPlanGUID != this.componentService.NULL_GUID) {
            notes = this.accountOnPaymentPlanText;
        }
        // inactiveReason is more important than ineligibleReason
        if (!balance.isAccountPaymentPlanEligible) {
            notes = balance.ineligibleReason;
        }
        if (!balance.isActive) {
            notes = balance.inactiveReason;
        }
        return notes;
    }

    private hasHistory(): boolean {
        return !this.yearList || this.yearList.size > 0;
    }

    private getYearsFromBalanceHistory(history: IAccountBalanceHistory[]) {
        const years = new Set<number>();
        const yearsList = history.map((x) => new Date(x.transactionDate).getFullYear()).forEach((item) => {
            if (!years.has(item)) {
                years.add(item);
            }
        });
        return years;
    }

    /**
     * Takes a collection of IAccountBalanceHistory (directly from tblAccountTransactionHistory)
     * and groups them by year, so that we can reference them by index: groupedAccountBalanceHistory[year]
     *
     * @memberof BalanceDetailsComponent
     */
    private groupHistoryByYear(history: IAccountBalanceHistory[]): IGroupedAccountHistory {
        return history.reduce(function(byYear: IGroupedAccountHistory, item: IAccountBalanceHistory) {
            const itemYear = new Date(item.transactionDate).getFullYear();
            (byYear[itemYear] = byYear[itemYear] || []).push(item);
            return byYear;
        }, {});
    }

}
interface IGroupedAccountHistory {
    [key: string]: IAccountBalanceHistory[];
}
