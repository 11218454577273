import {
    Component,
    ContentChild,
    EventEmitter,
    HostListener,
    Input,
    Output
} from '@angular/core';

import { DropdownStateService } from '../../services/dropdown-state.service';
import { Ng2DropdownButton } from '../button/ng2-dropdown-button';
import { Ng2DropdownMenu } from '../menu/ng2-dropdown-menu';

@Component({
    selector: 'ng2-dropdown',
    template: require('./template.html'),
    providers: [DropdownStateService]
})
export class Ng2Dropdown {
    // get children components
    // Added "static" param for Angular 8 update
    @ContentChild(Ng2DropdownButton, { static: true }) public button: Ng2DropdownButton;
    @ContentChild(Ng2DropdownMenu, { static: false }) public menu: Ng2DropdownMenu;

    @Input() public dynamicUpdate = true;
    @Input() public cssClass = '';

    // outputs
    @Output() public onItemClicked: EventEmitter<string> = new EventEmitter<string>();
    @Output() public onItemSelected: EventEmitter<string> = new EventEmitter<string>();
    @Output() public onShow: EventEmitter<Ng2Dropdown> = new EventEmitter<Ng2Dropdown>();
    @Output() public onHide: EventEmitter<Ng2Dropdown> = new EventEmitter<Ng2Dropdown>();

    constructor(private state: DropdownStateService) { }

    /**
     * @name toggleMenu
     * @desc toggles menu visibility
     */
    public toggleMenu(position = this.button.getPosition()): void {
        this.state.menuState.isVisible ? this.hide() : this.show(position);
    }

    /**
     * - hides dropdown
     * @name hide
     */
    public hide(): void {
        this.menu.hide();
        this.onHide.emit(this);
    }

    /**
     * - shows dropdown
     * @name show
     * @param position
     */
    public show(position = this.button.getPosition()): void {
        this.menu.show();

        // update menu position based on its button's
        this.menu.updatePosition(position);
        this.onShow.emit(this);
    }

    /**
     * @name scrollListener
     */
    @HostListener('window:scroll')
    public scrollListener() {
        if (this.state.menuState.isVisible && this.button && this.dynamicUpdate) {
            this.menu.updatePosition(this.button.getPosition());
        }
    }

    public ngOnInit() {
        this.state.dropdownState.onItemClicked.subscribe((item: any) => {
            this.onItemClicked.emit(item);

            if (item.preventClose) {
                return;
            }

            this.hide.call(this);
        });

        if (this.button) {
            this.button.onMenuToggled.subscribe(() => {
                this.toggleMenu();
            });
        }

        this.state.dropdownState.onItemSelected.subscribe((item: any) => this.onItemSelected.emit(item));
    }
}
