import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IAutopayPayment } from '../../../../models/autopaypayment';
@Component({
    selector: 'autopay-pending-payment',
    template: require('./autopaypendingpayment.component.html'),
    styles: [require('./autopaypendingpayment.component.css')]
})

export class AutopayPendingPaymentComponent implements OnInit {
    @Input() paymentModel: IAutopayPayment;
    @Input() pendingPaymentDateText: string;
    @Input() cancelPaymentButtonText: string;

    @Output() public cancelModalFunction: EventEmitter<IAutopayPayment> = new EventEmitter<IAutopayPayment>();

    constructor(private datePipe: DatePipe, private currencyPipe: CurrencyPipe) { }

    ngOnInit() {
        if (this.pendingPaymentDateText != null && this.paymentModel != null) {
            this.pendingPaymentDateText = this.pendingPaymentDateText.replace(
                '!PAYMENT!', this.currencyPipe.transform(this.paymentModel.amount, 'USD', 'symbol'));
            this.pendingPaymentDateText = this.pendingPaymentDateText.replace(
                '!PAYMENTDATE!', this.datePipe.transform(this.paymentModel.scheduledPaymentDate, 'MM/d/y'));
        }
    }

    cancelClicked(event: any) {
        this.cancelModalFunction.emit(this.paymentModel);
    }
}
