/* tslint:disable:triple-equals */
import { Injectable } from '@angular/core';
import { PaymentMethod, PaymentMethodType } from '../../../models/paymentmethod';
import { ComponentService } from '../../../services/component/component.service';
import { LoggingLevel } from '../../../services/logging/logging.service';

@Injectable()
export class BasePaymentMethod {
    constructor(private compService: ComponentService) {
    }

    /**
     * Compares card expiration month to current month to flag the card as expired.
     *
     * @param {PaymentMethod} paymentMethod
     * @returns
     *
     * @memberOf BasePaymentMethod
     */
    public isExpired(paymentMethod: PaymentMethod): boolean {

        if (paymentMethod == null || paymentMethod.paymentMethodType != PaymentMethodType.credit) {
            return false;
        }

        if (paymentMethod.expirationDate == null) {
            return false;
        }

        if (!this.isValidDate(paymentMethod.expirationDate)) {
            this.compService.loggingService.log('invalid expiration date passed.', LoggingLevel.debug);
            return false;
        }

        const todaysDate = new Date();

        // Takes expiration date, gets the first day of that month and 00:00:00
        const expirationDate = new Date(paymentMethod.expirationDate);
        expirationDate.setDate(1);
        expirationDate.setHours(0, 0, 0, 0);

        // Gets first day of current month at 00:00:00
        const firstDayOfThisMonth = new Date(todaysDate.getFullYear(), todaysDate.getMonth(), 1, 0, 0, 0, 0);

        if (expirationDate < firstDayOfThisMonth) {
            return true;
        }
        return false;
    }

    public isCreditCard(paymentMethod: PaymentMethod) {
        return paymentMethod.paymentMethodType == PaymentMethodType.credit;
    }

    public isOtherCreditCard(paymentMethodType: PaymentMethodType) {
        return paymentMethodType == PaymentMethodType.credit;
    }

    public isBankAccount(paymentMethod: PaymentMethod) {
        return paymentMethod.paymentMethodType == PaymentMethodType.ach;
    }

    public isOtherBankAccount(paymentMethodType: PaymentMethodType) {
        return paymentMethodType == PaymentMethodType.ach;
    }

    public isOther(paymentMethod: PaymentMethod) {
        return !paymentMethod || paymentMethod.paymentMethodType == PaymentMethodType.unknown;
    }

    private isValidDate(date: string) {
        const d = new Date(date);
        return (Object.prototype.toString.call(d) === '[object Date]' && !isNaN(d.getTime()));
    }
}
