import { Component, OnInit } from '@angular/core';
import { ErrorMessage } from '../../models/errormessage';
import { ComponentService } from '../../services/component/component.service';

@Component({
    selector: 'error-page',
    template: require('./error.component.html')
})
export class ErrorPageComponent implements OnInit {
    errorMessage: ErrorMessage;
    hideCustomMemPaymentFooter = false;
    constructor(private componentService: ComponentService) { }

    ngOnInit() {
        // get error from storage
        if (this.componentService.storageService.exists('error')) {
            this.errorMessage = this.componentService.storageService.retrieve('error');
        }

        if (this.componentService.storageService.exists('hideCustomMemPaymentFooter')) {
            this.hideCustomMemPaymentFooter = this.componentService.storageService.retrieve('hideCustomMemPaymentFooter');
            this.componentService.storageService.clear('hideCustomMemPaymentFooter');
        }
    }
}
