import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IconComponent } from './icon.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule
    ],
    declarations: [
        IconComponent
    ],
    exports: [
        IconComponent
    ]
})
export class IconModule {}
