import { Component, Input, OnInit } from '@angular/core';
import { PaymentMethod } from '../../../models/paymentmethod';
import { ComponentService } from '../../../services/component/component.service';
import { BasePaymentMethod } from '../../Base/BasePaymentMethod/basepaymentmethod';

@Component({
    selector: 'small-payment-method-display',
    template: require('./smallpaymentmethoddisplay.component.html'),
    styles: [require('./smallpaymentmethoddisplay.component.css')]
})

export class SmallPaymentMethodDisplayComponent extends BasePaymentMethod implements OnInit {

    constructor(private componentService: ComponentService) {
        super(componentService);
    }

    @Input() paymentMethod: PaymentMethod;
    @Input() align = 'right';
    expirationDateText = 'Exp:';
    expiredText = 'Expired';

    ngOnInit() { }
}
