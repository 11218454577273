import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from '../../app.config';
import { ICreateFutureDatePaymentResponse } from '../../models/createfuturedatepaymentresponse';
import { FutureDatePaymentCreateAgreementRequest } from '../../models/futuredatepaymentcreateagreementrequest';
import { GenericResponse } from '../../models/genericresponse';
import { jsonRequestOptions } from '../../util/HttpClientUtility';
import { ComponentService } from '../component/component.service';
import { LoggingService } from '../logging/logging.service';

@Injectable()
export class FutureDateAccountPaymentService {
    private domain = '';
    private createFutureDatePaymentPath: string;
    private domainUrl = this.config.getConfig('domainInfo');

    constructor(
        private http: HttpClient,
        private config: AppConfig,
        private componentService: ComponentService,
        private loggingService: LoggingService,
    ) {
        this.domain = this.componentService.storageService.getDomain();
        this.createFutureDatePaymentPath = config.getConfig('createFutureDatePaymentPath');
    }

    /**
     * Used to create a future date account payment agreement
     *
     * @param {FutureDatePaymentCreateAgreementRequest} createAgreementRequest The agreement to create
     * @param {string} token The access token from PaymentComponent for the ConsumerAPI
     */
    public async createFutureDateAccountPayment(createAgreementRequest: FutureDatePaymentCreateAgreementRequest, token: string)
        : Promise<ICreateFutureDatePaymentResponse> {
        return this.callCreateFutureDatePayment(this.domain, createAgreementRequest, token);
    }

    /**
     * used to create a future date account payment agreement
     *
     * @param {string} domain
     * @param {FutureDatePaymentCreateAgreementRequest} createAgreementRequest
     * @param {string} token
     * @returns {Promise<ICreateFutureDatePaymentResponse>}
     *
     * @memberof PaymentService
     */
    private async callCreateFutureDatePayment(domain: string,
                                              createAgreementRequest: FutureDatePaymentCreateAgreementRequest,
                                              token: string): Promise<ICreateFutureDatePaymentResponse> {
        const path = this.createFutureDatePaymentPath;
        const body: any = {};
        body.bypass = true;
        body.domain = domain;
        body.path = path;
        body.UrlAccessKey = token;
        // tslint:disable-next-line: forin
        for (const i in createAgreementRequest) {
            body[i] = createAgreementRequest[i];
        }

        return this.http.post(this.domainUrl, { body }, jsonRequestOptions())
            .toPromise()
            .then((response: HttpResponse<ICreateFutureDatePaymentResponse>) => {
                return response.body;
            })
            .catch((response: GenericResponse) => {
                return this.loggingService.handleError(response);
            });
    }
}
