import { HttpClient, HttpHeaders, HttpParameterCodec, HttpParams, HttpResponse, HttpUrlEncodingCodec } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfig } from '../../app.config';
import { GenericResponse, Response } from '../../models/genericresponse';
import { ILogin } from '../../models/login';
import { SsoResponse } from '../../models/ssoresponse';
import { jsonRequestOptions } from '../../util/HttpClientUtility';
import { ComponentService } from '../component/component.service';
import { LoggingService } from '../logging/logging.service';

class UriEncodeEncoder implements HttpParameterCodec {
    encoder = new HttpUrlEncodingCodec();
    decodeKey(k: string): string {
        return this.encoder.decodeKey(k);
    }
    decodeValue(v: string): string {
        return decodeURIComponent(v);
    }
    encodeKey(k: string): string {
        return this.encoder.encodeKey(k);
    }
    encodeValue(v: string): string {
        return encodeURIComponent(v);
    }
}

@Injectable()
export class LoginService {

    constructor(
        private http: HttpClient,
        private config: AppConfig,
        private componentService: ComponentService,
        private loggingService: LoggingService,
        private router: Router
    ) {
        this.setLocation();
        this.getSessionTimeoutPath = this.config.getConfig('getSessionTimeoutPath');
        this.recaptchaPath = this.config.getConfig('recaptchaPath');
        this.ssoVerificationPath = this.config.getConfig('ssoVerificationPath');
        this.impersonatorVerificationPath = this.config.getConfig('impersonatorVerificationPath');
        this.domainUrl = this.config.getConfig('domainInfo');
        this.sessionTimeout$ = new EventEmitter();
        if (config.getEnv('env') === 'Debug') {
            (window as any).logout = () => this.logout().then(() => console.log('logged out'));
        }
    }
    private domainLoginUrl = '';
    private domainLogoffUrl = '';
    private domainMemLoginUrl = '';
    private domainAgentAssistedLoginUrl = '';
    private domainPendingEnrollmentUrl = '';
    private recaptchaPath = '';
    private ssoVerificationPath = '';
    private impersonatorVerificationPath = '';
    private domain = '';
    private getSessionTimeoutPath = '';
    private domainUrl = '';

    /**
     * EventEmitter to notify global subscribers that a session has been timed out
     *
     * @memberof LoginService
     */
    public sessionTimeout$: EventEmitter<boolean>;

    /**
     * Takes a username and password and attempts to login the user via the domain's configured login url
     *
     * @param {string} userName
     * @param {string} password
     * @returns {Promise<ILogin>}
     *
     * @memberof LoginService
     */
    public async login(userName: string, password: string): Promise<ILogin> {
        let params = new HttpParams({encoder: new UriEncodeEncoder()});
        params = params.append('userName', userName);
        params = params.append('password', password);

        return this.http.post(this.domainLoginUrl , params, {observe: 'response', responseType: 'json'})
            .toPromise()
            .then((response: Response<ILogin>) => {
                const resp = response.body;
                // check for "messages" AKA errors on the response
                if (resp.messages && resp.messages.length > 0) {
                    resp.userMessage = this.componentService.handleValidationErrorCode(resp.messages[0]);
                }
                return resp;
            })
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    /**
     * Logs in the user using the passed in MyEasyMatch code and second factor information
     *
     * @param {string} aaIvrCode
     * @param {string} validation
     * @returns {Promise<ILogin>}
     *
     * @memberof LoginService
     */
    public async agentAssistedPaymentLogin(aaGuid: string, validation: string): Promise<ILogin> {
        validation = validation === null ? '' : validation;
        const params = new HttpParams()
            .append('agentAssistedGuid', aaGuid)
            .append('validation', validation);

        return this.http.post(this.domainAgentAssistedLoginUrl, params, { observe: 'response', responseType: 'json' })
            .toPromise()
            .then((response: HttpResponse<ILogin>) => {
                const resp = response.body;
                // check for "messages" AKA errors on the response
                if (resp.messages && resp.messages.length > 0) {
                    resp.userMessage = this.componentService.handleValidationErrorCode(resp.messages[0]);
                }

                return resp;
            })
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    /**
     * Logs in the user using the passed in MyEasyMatch code and second factor information
     *
     * @param {string} memCode
     * @param {string} validation
     * @param {boolean} payment
     * @param {boolean} linkMode
     * @returns {Promise<ILogin>}
     *
     * @memberof LoginService
     */
    public async memLogin(memCode: string, documentId: string, validation: string, payment: boolean,
                          linkMode: boolean, recaptchaToken: string = ''): Promise<ILogin> {
        validation = validation === null ? '' : validation;
        const params = new HttpParams()
            .append('MEMCode', memCode)
            .append('documentId', documentId)
            .append('validation', validation)
            .append('payment', payment.toString())
            .append('linkMode', linkMode.toString())
            .append('recaptchaToken', recaptchaToken.toString());

        return this.http.post(this.domainMemLoginUrl, params, {observe: 'response', responseType: 'json'})
            .toPromise()
            .then((response: HttpResponse<ILogin>) => {
                const resp = response.body;
                // check for "messages" AKA errors on the response
                if (resp.messages && resp.messages.length > 0) {
                    resp.userMessage = this.componentService.handleValidationErrorCode(resp.messages[0]);
                }

                return resp;
            })
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    /**
     * Validate and login based on pendingEnrollment information
     *
     * @param {string} pendingEnrollmentGUID
     * @param {string} secondFactorAuth
     * @returns {Promise<ILogin>}
     *
     * @memberof LoginService
     */
    public async pendingEnrollment(pendingEnrollmentGUID: string, secondFactorAuth: string): Promise<ILogin> {
        const params = new HttpParams()
            .append('pendingEnrollmentGUID', pendingEnrollmentGUID)
            .append('secondFactorAuth', secondFactorAuth);

        return this.http.post(this.domainPendingEnrollmentUrl, params, jsonRequestOptions(new HttpHeaders({
                'Content-Type': 'application/x-www-form-urlencoded',
                Accept: 'application/json',
            })))
            .toPromise()
            .then((response: HttpResponse<ILogin>) => {
                const resp = response.body;
                if (resp.messages && resp.messages.length > 0) {
                    resp.userMessage = this.componentService.handleValidationErrorCode(resp.messages[0]);
                }
                return resp;
            })
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    /**
     * Attempts to log the user out via the logoff URL and clears the local storage of Session protected keys.
     *
     * @returns {Promise<string>}
     *
     * @memberof LoginService
     */
    public async logout(): Promise<string> {
        const parms = new HttpParams()
            .append('token', this.componentService.storageService.retrieve('token'));
        try {
            const body = await this.http.post(this.domainLogoffUrl, parms, {observe: 'response', responseType: 'json'})
            .toPromise()
            .then((response: HttpResponse<string>) => {
                this.componentService.storageService.clearSession();
                return response.body;
            });
            return body;
        } catch (e) {
            this.loggingService.handleError(e);
        } finally {
            this.componentService.storageService.clearSession();
        }
    }

    /**
     * Verify recaptcha token with Google API.
     *
     * @param {string} token
     * @returns {Promise<any>}
     *
     * @memberof LoginService
     */
    public async verifyRecaptcha(token: string): Promise<any> {
        const params = new HttpParams()
            .append('token', token);

        return this.http.post(this.recaptchaPath, params, {observe: 'response', responseType: 'json'})
            .toPromise()
            .then((response: HttpResponse<any>) => response.body)
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    /**
     * Verify a SSO Cipher token with the Consumer API
     *
     * @param ssoCipher
     * @returns {Promise<SsoResponse>}
     *
     * @memberof LoginService
     */
    public async verifySsoCipher(ssoCipher: string, skipCreateConsumer: boolean = false): Promise<SsoResponse> {
        const params = new HttpParams({encoder: new UriEncodeEncoder()})
            .append('cipher', ssoCipher)
            .append('domain', this.domain)
            .append('skipCreateConsumer', skipCreateConsumer.toString());

        return this.http.get(this.ssoVerificationPath + '?' + params.toString(), jsonRequestOptions())
            .toPromise()
            .then((response: Response<SsoResponse>) => {
                return (response.body.data) as SsoResponse;
            })
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    /**
     * Verify a Impersonator Cipher token from MEV with the Consumer API
     *
     * @param cipher
     * @returns {Promise<SsoResponse>}
     *
     * @memberof LoginService
     */
    public async verifyImpersonatorCipher(cipher: string): Promise<SsoResponse> {
        const params = new HttpParams({encoder: new UriEncodeEncoder()})
            .append('cipher', cipher)
            .append('domain', this.domain);

        return this.http.get(this.impersonatorVerificationPath + '?' + params.toString(), jsonRequestOptions())
            .toPromise()
            .then((response: Response<SsoResponse>) => {
                return response.body.data;
            })
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    /**
     * Returns true if the logged in user's session is verifiably valid based on the API
     *
     * @param {string} [token=""]
     * @returns {boolean}
     * @memberof LoginService
     */
    public async isUserSessionValid(token: string = ''): Promise<boolean> {
        let makeApiCall = true;
        const utcDate = new Date().toUTCString();
        let now = new Date(utcDate);
        const sessionTimeoutDateFromStorage = this.componentService.storageService.retrieve('sessiontimeout');
        const lastSessionCheck = this.componentService.storageService.retrieve('lastSessionCheckTimestamp');
        let storageSessionValid = false;

        if (this.componentService.letMeIn()) {
            return true;
        }

        if (lastSessionCheck != null && !this.componentService.outageInStorage()) {
            // if the last check was < 10 seconds ago, don't call the API again.
            const tenSecondsFromLastCheck = new Date(new Date(lastSessionCheck).getTime() + 10000);
            if (now <= tenSecondsFromLastCheck) {
                makeApiCall = false;
            }
            // if the session in storage is already expired, just return false.
            if (sessionTimeoutDateFromStorage != null && now > new Date(sessionTimeoutDateFromStorage)) {
                this.sessionTimeout$.emit(true);
                return false;
            } else if (sessionTimeoutDateFromStorage != null) {
                storageSessionValid = true;
            }
        }
        if (!makeApiCall) {
            return storageSessionValid;
        }
        const data = await this.callGetSessionTimeoutApi(token);
        let valid = true;
        now = new Date();
        if (now > new Date(data)) {
            valid = false;
        } else {
            this.componentService.storageService.store('sessiontimeout', new Date(data).toUTCString());
            this.componentService.storageService.store('lastsessionchecktimestamp', now.toUTCString());
        }
        this.sessionTimeout$.emit(!valid);
        return valid;
    }

    private async callGetSessionTimeoutApi(token: string = ''): Promise<Date> {
        const body: any = {};
        // If there was an error, assume they're timed out.
        const expiredDate = new Date(new Date().getTime() - 60000);

        if (token === '' || token === null || typeof token === 'undefined') {
            token = this.componentService.storageService.retrieve('token');
        }

        body.bypass = true;
        body.path = this.getSessionTimeoutPath;
        body.UrlAccessKey = token;

        return this.http.post(this.domainUrl, { body }, jsonRequestOptions())
            .toPromise()
            .then((response: Response<Date>) => response.body.data)
            .catch(() => expiredDate);
    }

    private setLocation(): void {
        this.domainLoginUrl = location.protocol + '//' + location.host + this.config.getConfig('SSO');
        if (this.config.getConfig('SSO').includes('http:') || this.config.getConfig('SSO').includes('https:')) {
            this.domainLoginUrl = this.config.getConfig('SSO');
        }

        this.domainAgentAssistedLoginUrl = this.domainLoginUrl.substring(0, this.domainLoginUrl.lastIndexOf('/') + 1) + 'ValidateAgentAssistedPayment';
        this.domainMemLoginUrl = this.domainLoginUrl.substring(0, this.domainLoginUrl.lastIndexOf('/') + 1) + 'ValidateMEM';
        this.domainPendingEnrollmentUrl = this.domainLoginUrl.substring(0, this.domainLoginUrl.lastIndexOf('/') + 1) + 'PendingEnrollment';
        this.domainLogoffUrl = location.protocol + '//' + location.host + this.config.getConfig('Logoff');
        if (this.config.getConfig('Logoff').includes('http:') || this.config.getConfig('Logoff').includes('https:')) {
            this.domainLogoffUrl = this.config.getConfig('Logoff');
        }

        this.domain = this.componentService.storageService.getDomain();
    }
}
