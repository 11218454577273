import { AfterViewInit, ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BasePaymentMethod } from '../../components/Base/BasePaymentMethod/basepaymentmethod';
import { ConfirmationModalComponent } from '../../components/Controls/ConfirmationModal/confirmationmodal.component';
import { PaymentSelectionComponent } from '../../components/Controls/PaymentSelection/paymentselection.component';
import { ACHPaymentMethod, ACHPaymentMethodAccountType, ACHPaymentMethodPaymentSource } from '../../models/achpaymentmethod';
import { Address } from '../../models/address';
import { IConsumer } from '../../models/consumer';
import { ConsumerAccount } from '../../models/consumeraccount';
import { CreditCard } from '../../models/creditcard';
import { IDomainInfo } from '../../models/domaininfo';
import { OtherPaymentMethod, PaymentMethod, PaymentMethodType } from '../../models/paymentmethod';
import { PaymentMethodAssignmentSource } from '../../models/paymentmethodassignmentsource';
import { PaymentSelectionModel, PaymentSelectionStateModel } from '../../models/paymentselectionmodel';
import { IRecurringPaymentPlan } from '../../models/recurringpaymentplan';
import { AgentAssistedService } from '../../services/agentassisted/agentassisted.service';
import { ComponentService } from '../../services/component/component.service';
import { ConsumerService } from '../../services/consumer/consumer.service';
import { PaymentPlanService } from '../../services/paymentplan/paymentplan.service';
import { IState } from './../../models/states';

@Component({
    selector: 'mysecurewallet',
    template: require('./mysecurewallet.component.html'),
    styles: [require('./mysecurewallet.component.css')]
})

export class MySecureWalletComponent extends BasePaymentMethod implements AfterViewInit, OnInit, OnDestroy {
    domainInfo: IDomainInfo;
    consumer: IConsumer;
    consumerAccount: ConsumerAccount;
    loading = true;
    submitting = false;
    addingNewPaymentMethod = false;
    editingPaymentMethod = false;
    newPaymentMethod = true;
    paymentMethodValid: boolean;
    showUpdatedWalletTextID: string;
    paymentMethodToUpdate: PaymentMethod;
    isMobile = false;

    header: string;
    subheading: string;
    primaryIndicatorText: string;
    backButtonText: string;
    cancelButtonText: string;
    expirationDateText: string;
    expirationDateFullText: string;
    submitButtonText: string;
    addPaymentMethodText: string;
    firstNameText: string;
    lastNameText: string;
    addressText: string;
    nameErrorText: string;
    nameErrorExists: boolean;
    addressErrorText: string;
    addressErrorExists: boolean;
    cityErrorText: string;
    cityErrorExists: boolean;
    postalCodeErrorText: string;
    postalCodeErrorExists: boolean;
    paymentError: string;
    optionalNicknameText: string;
    nicknamePlaceholderText: string;
    storeWalletErrorText: string;
    genericWalletErrorText: string;
    deleteWalletErrorText: string;
    walletErrorText = '';
    paymentMethodExpiredErrorText: string;
    walletErrorExists = false;
    deletePaymentMethodModalContent: string;
    deletePaymentMethodModalTitle: string;
    confirmationModalDisplay: string;
    manageWalletText: string;
    updatedWalletText: string;
    updatedWalletErrorText: string;

    // Consumer
    firstName = '';
    lastName = '';
    address1 = '';
    address2 = '';
    city = '';
    state = ' ';
    country = '';
    postalCode = '';
    address1Placeholder: string;
    cityPlaceholder: string;
    statePlaceholder: string;
    postalCodePlaceholder: string;

    // Payment method
    creditCardNumber: string;
    cvvNumber: string;
    cardExpirationMonth: string;
    cardExpirationYear: string;
    nickname: string;
    validationMessage: string;
    achAccountNumber: string;
    achRoutingNumber: string;

    // Agent assisted
    isAgentAssisted: boolean;

    paymentMethods: PaymentMethod[] = [];
    otherPaymentMethod: PaymentMethod[] = OtherPaymentMethod;
    stateList: IState[] = [];
    returnUrl: string;

    modelToPassToPayment: PaymentSelectionModel = new PaymentSelectionModel();
    paymentSelectionState: PaymentSelectionStateModel = new PaymentSelectionStateModel();

    @ViewChild('paymentSelectionCmp', { static: false }) paymentSelectionCmp: PaymentSelectionComponent;
    @ViewChild('confirmation', { static: false }) confirmationModal: ConfirmationModalComponent;

    private ngUnsubscribe: Subject<any> = new Subject();

    constructor(
        private agentAssistedService: AgentAssistedService,
        private componentService: ComponentService,
        private consumerService: ConsumerService,
        private changeDetectorRef: ChangeDetectorRef,
        private parentRouter: Router,
        private paymentPlanService: PaymentPlanService,
        private ngZone: NgZone
    ) {
        super(componentService);
    }

    ngOnInit() {
        this.isAgentAssisted = this.componentService.storageService.exists('agentAssistedPaymentEmailGUID');
        this.componentService.scrollPageToTop();
        this.isMobile = this.componentService.isMobileBrowser();
        this.componentService.contentService.content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((content: any) => {
                this.header = this.componentService.contentService.tryGetContentItem(
                    content, 'profile', 'wallet', 'profileWalletHeaderText').text;

                this.subheading = this.componentService.contentService.tryGetContentItem(content, 'profile', 'wallet', 'profileWalletSubHeadingText').text;
                this.primaryIndicatorText = this.componentService.contentService.tryGetContentItem(content, 'profile', 'wallet', 'profileWalletPrimaryIndicatorText').text;
                this.backButtonText = this.componentService.contentService.tryGetContentItem(content, 'profile', 'wallet', 'profileWalletBackButtonText').text;
                this.addPaymentMethodText = this.componentService.contentService.tryGetContentItem(content, 'profile', 'wallet', 'profileWalletAddPaymentMethodText').text;
                this.cancelButtonText = this.componentService.contentService.tryGetContentItem(content, 'profile', 'wallet', 'profileWalletCancelButtonText').text;
                this.submitButtonText = this.componentService.contentService.tryGetContentItem(content, 'profile', 'wallet', 'profileWalletSubmitButtonText').text;
                this.expirationDateText = this.componentService.contentService.tryGetContentItem(content, 'profile', 'wallet', 'profileWalletExpirationDateText').text;
                this.expirationDateFullText = this.componentService.contentService.tryGetContentItem(content, 'profile', 'wallet', 'profileWalletExpirationDateFullText').text;
                this.storeWalletErrorText = this.componentService.contentService.tryGetContentItem(content, 'error', 'wallet', 'profileWalletStoreErrorText').text;
                this.genericWalletErrorText = this.componentService.contentService.tryGetContentItem(content, 'error', 'wallet', 'profileWalletErrorText').text;
                this.deleteWalletErrorText = this.componentService.contentService.tryGetContentItem(content, 'error', 'wallet', 'profileDeleteWalletErrorText').text;
                this.deletePaymentMethodModalContent = this.componentService.contentService.tryGetContentItem(content, 'profile', 'wallet', 'profileWalletDeleteMethodModalContent').text;
                this.deletePaymentMethodModalTitle = this.componentService.contentService.tryGetContentItem(
                    content, 'profile', 'wallet', 'profileWalletDeleteMethodModalTitle').text;

                this.manageWalletText = this.componentService.contentService.tryGetContentItem(content, 'profile', 'wallet', 'profileManageWalletText').text;
                this.updatedWalletText = this.componentService.contentService.tryGetContentItem(content, 'profile', 'wallet', 'profileUpdatedWalletText').text;
                this.updatedWalletErrorText = this.componentService.contentService.tryGetContentItem(content, 'profile', 'wallet', 'profileUpdatedWalletErrorText').text;
                // some of these content items are used in the PaymentMethodManagerComponent, so if changing
                // them here make sure to check the QuickPay/Inline wallet too
            });

        this.componentService.domainService.domainInfo$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                domainInfo => {
                    this.domainInfo = domainInfo;
                    if (!!domainInfo) {
                        this.retrieveWallet();
                    }
                });

        this.returnUrl = this.componentService.storageService.retrieve('returnurl');
        this.componentService.storageService.clear('returnurl');

        this.componentService.domainService.getStates().then(
            stateList => this.stateList = stateList.sort(function(a: IState, b: IState) { return a.code.localeCompare(b.code); }));

        this.modelToPassToPayment.isOneTimePayment = true;
        this.modelToPassToPayment.defaultUpdateAddress = true;
        this.modelToPassToPayment.showPaymentDate = false;
        this.modelToPassToPayment.hideSaveToWalletCheckbox = true;
        this.modelToPassToPayment.lockSaveToWallet = true;
        this.modelToPassToPayment.contentItemCategory = 'profile';
        this.modelToPassToPayment.contentItemSubCategory = 'walletPaymentMethod';
    }

    ngAfterViewInit() {
        this.consumerService.getMerchantProfileForDomain().then(merchantProfile => {
            this.paymentSelectionCmp.setMerchantProfile(merchantProfile);
        });

        if (this.componentService.storageService.exists('agentAssistedPaymentEmailGUID')) {
            this.toggleAddPaymentMethod();
            this.changeDetectorRef.detectChanges();
        }
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    /**
     * Retrieves and inits the consumer, consumer account, and wallet payment methods
     *
     * @public
     *
     * @memberof MySecureWalletComponent
     */
    public retrieveWallet(): void {
        this.loading = true;
        this.componentService.storageService.clear('wallet');
        this.walletErrorExists = false;
        this.consumerService.getConsumer()
            .then(consumer => {
                this.consumer = consumer;
                this.consumerService.getConsumerAccount().then(consumerAccount => {
                    if (!!consumerAccount) {
                        this.consumerAccount = consumerAccount;
                        this.consumerService.getWalletPaymentMethods().then(wallet => {
                            if (!!wallet) {
                                this.paymentMethods = wallet.filter(x => x.tokenGUID !== 'newPaymentMethod');
                                this.doneLoading();
                            }
                        });
                    }
                });
            })
            .catch(err => {
                this.walletErrorText = this.genericWalletErrorText;
                this.walletErrorExists = true;
                this.doneLoading();
            });
    }

    /**
     * Changes the primary payment method to the one selected, calling updatePrimaryWalletPaymentMethod via API
     *
     * @public
     *
     * @memberof MySecureWalletComponent
     */
    onSelectionChange(paymentMethod: PaymentMethod): void {
        this.paymentSelectionCmp.clearPayments();
        this.walletErrorExists = false;
        const previousPrimary: PaymentMethod = this.paymentMethods.find(p => p.primary);

        this.paymentMethods.forEach((m) => {
            if (m.tokenGUID === paymentMethod.tokenGUID) {
                m.primary = true;
            } else {
                m.primary = false;
            }
        });

        this.consumerService.updatePrimaryWalletPaymentMethod(paymentMethod.merchantGUID, paymentMethod.tokenGUID)
            .then() // success
            .catch(err => {
                this.walletErrorText = this.genericWalletErrorText;
                this.walletErrorExists = true;
                this.paymentMethods.forEach((m) => {
                    if (m.tokenGUID === previousPrimary.tokenGUID) {
                        m.primary = true;
                    } else {
                        m.primary = false;
                    }
                });
            });
    }

    /**
     * Submits a completed PaymentMethodDisplayComponent to store a new payment method into the ConsumerAccount wallet
     *
     * @public
     *
     * @memberof MySecureWalletComponent
     */
    submitNewPaymentMethod(): void {
        this.paymentMethodValidation();

        if (this.paymentMethodValid) {
            this.submitting = true;

            const paymentMethod: PaymentSelectionStateModel = this.paymentSelectionCmp.getPaymentSelectionState();

            let credit: CreditCard;
            if (paymentMethod.selectedPaymentMethod.paymentMethodType === PaymentMethodType.credit) {
                credit = new CreditCard();
                credit.CardNumber = paymentMethod.creditCardNumber.replace(/-/g, '');
                credit.ExpirationDate = new Date(paymentMethod.creditCardExpirationMonth + '/01/' + paymentMethod.creditCardExpirationYear);
                credit.CVV = paymentMethod.creditCardCvv;
            }

            let ach: ACHPaymentMethod;
            if (paymentMethod.selectedPaymentMethod.paymentMethodType === PaymentMethodType.ach) {
                ach = new ACHPaymentMethod();
                ach.AccountType = paymentMethod.achAccountType.trim().split(' ')[1] === 'Savings'
                    ? ACHPaymentMethodAccountType.Savings
                    : ACHPaymentMethodAccountType.Checking;
                ach.paymentSource = ACHPaymentMethodPaymentSource.Web;
                ach.bankAccountNumber = paymentMethod.achAccountNumber;
                ach.routingNumber = paymentMethod.achRoutingNumber;
            }

            let address: Address = new Address();
            address = {
                firstName: paymentMethod.firstName,
                lastName: paymentMethod.lastName,
                address1: paymentMethod.address1,
                address2: paymentMethod.address2,
                city: paymentMethod.city,
                stateRegion: paymentMethod.state,
                postalCode: paymentMethod.postalCode,
                country: paymentMethod.country
            };

            const agentAssistedGUID = this.componentService.storageService.retrieve('agentAssistedPaymentEmailGUID');

            if (this.editingPaymentMethod) {
                // only send card number along with UPay methods
                if (this.paymentMethodToUpdate.paymentProcessor.toLowerCase() !== 'upay') {
                    credit.CardNumber = null;
                }
                this.consumerService.updateWalletPaymentMethod(
                    this.paymentMethodToUpdate.tokenGUID,
                    this.paymentMethodToUpdate.merchantGUID,
                    paymentMethod.selectedPaymentMethod.description, address, credit)
                    .then(response => {
                        if (response.success) {
                            this.addingNewPaymentMethod = false;
                            this.retrieveWallet();
                            this.showUpdatedWalletTextID = this.paymentMethodToUpdate.tokenGUID;
                        } else {
                            this.paymentSelectionCmp.step2Valid = false;
                            this.paymentSelectionCmp.stepValidationMessage = response.messages.length > 0
                                ? response.messages[0]
                                : this.updatedWalletErrorText;
                            this.doneLoading();
                        }
                    })
                    .catch(err => {
                        this.paymentSelectionCmp.step2Valid = false;
                        this.paymentSelectionCmp.stepValidationMessage = this.updatedWalletErrorText;
                        this.doneLoading();
                    });
            } else {
                this.consumerService.storeWalletPaymentMethod(
                    this.consumer.defaultMerchantGUID,
                    paymentMethod.newPaymentNickname,
                    this.paymentMethods.length === 0,
                    credit, ach, address)
                    .then(async walletStored => {
                        if (walletStored.success) {
                            if (this.isAgentAssisted) {
                                paymentMethod.selectedPaymentMethod.cardType = (walletStored as any).data.cardBrand;
                                await this.updateAssistedPaymentMethod(
                                    this.consumer.accountID,
                                    (walletStored as any).data.newStoredMethodToken,
                                    agentAssistedGUID,
                                    paymentMethod);
                            }

                            if (this.returnUrl != null) {
                                this.ngZone.run(() => this.parentRouter.navigateByUrl(this.returnUrl)).then();
                            }

                            // toggleAddPaymentMethod() will call retrieveWallet will call doneLoading()
                            this.toggleAddPaymentMethod();
                        } else {
                            this.paymentSelectionCmp.step2Valid = false;
                            this.paymentSelectionCmp.stepValidationMessage = walletStored.messages.length > 0
                                ? walletStored.messages[0]
                                : this.storeWalletErrorText;
                            this.doneLoading();
                        }
                    })
                    .catch(err => {
                        this.paymentSelectionCmp.step2Valid = false;
                        this.paymentSelectionCmp.stepValidationMessage = this.storeWalletErrorText;
                        this.doneLoading();
                    });
            }
        }
    }

    async updateAssistedPaymentMethod(customerAccountID: string, paymentToUpdate: string,
                                      agentAssistedGUID: string, paymentMethod: PaymentSelectionStateModel): Promise<void> {
        const role = this.componentService.storageService.retrieve('agentAssistedRole');

        // Get existing payment plan, then update with new payment method
        if (role.toLowerCase() === 'paymentplanexisting') {
            this.returnUrl = '/paymentplans';
            const response = await this.agentAssistedService.getPaymentPlanByAgentAssistedPayment(agentAssistedGUID);
            if (response !== typeof (undefined) && response) {
                await this.paymentPlanService.setPaymentMethodForPlan(customerAccountID, paymentToUpdate, response);
            } else {
                this.paymentSelectionCmp.stepValidationMessage = this.storeWalletErrorText;
            }
        } else if (role.toLowerCase() === 'autopayexisting') {
            // Get existing autopay agreement, then update with new payment method
            this.returnUrl = '/autopayenrollmentconfirmation';
            const response = await this.agentAssistedService.getAutopayAgreementByAgentAssistedPayment(agentAssistedGUID);
            if (response !== undefined && response) {
                // Get new payment method and update agreement
                this.componentService.storageService.clear('wallet');
                const wallet = await this.consumerService.getWalletPaymentMethods();
                const newStoredPaymentMethod = wallet.filter(x => x.tokenGUID === paymentToUpdate)[0];
                newStoredPaymentMethod.cardType = paymentMethod.selectedPaymentMethod.cardType;
                response.storedPaymentMethod = newStoredPaymentMethod;
                response.paymentMethodAssignmentSource = PaymentMethodAssignmentSource.SelfService;

                await this.consumerService.updateAutoPayAgreement(response);
            } else {
                this.paymentSelectionCmp.stepValidationMessage = this.storeWalletErrorText;
            }
        } else if (role.toLowerCase() === 'recurringexisting' || role.toLowerCase() === 'preserviceexisting') {
            this.returnUrl = '/recurringconfirmation';
            const response =
                await this.agentAssistedService.getRecurringPaymentPlanByAgentAssistedPayment(
                    agentAssistedGUID
                );

            if (response !== undefined && response) {
                // Get new payment method and update agreement
                this.componentService.storageService.clear('wallet');
                const wallet = await this.consumerService.getWalletPaymentMethods();
                const newStoredPaymentMethod = wallet.filter(x => x.tokenGUID === paymentToUpdate)[0];
                newStoredPaymentMethod.cardType = paymentMethod.selectedPaymentMethod.cardType;
                response.storedPaymentMethod = newStoredPaymentMethod;
                response.paymentMethodAssignmentSource = PaymentMethodAssignmentSource.SelfService;

                this.storeRecurringDetailsTerms(response);

                await this.consumerService.updateRecurringPaymentPlan(response);
            } else {
                this.paymentSelectionCmp.stepValidationMessage = this.storeWalletErrorText;
            }
        }
    }

    cancelBtnClick(): void {
        if (this.componentService.storageService.exists('agentAssistedPaymentEmailGUID')) {
            this.ngZone.run(() => {
                this.parentRouter.navigateByUrl('/paymentplans');
            });
        } else {
            this.toggleAddPaymentMethod();
        }
    }

    cancelPaymentMethod(paymentMethod: PaymentMethod): void {
        this.loading = true;
        this.paymentSelectionCmp.clearPayments();
        this.consumerService.cancelWalletPaymentMethod(paymentMethod.merchantGUID, paymentMethod.tokenGUID)
            .then(walletCancelled => {
                this.retrieveWallet(); // retrieveWallet will call doneLoading()
            })
            .catch(err => {
                this.walletErrorText = this.genericWalletErrorText;
                this.walletErrorExists = true;
                this.doneLoading();
            });
    }

    toggleAddPaymentMethod(): void {
        this.addingNewPaymentMethod = !this.addingNewPaymentMethod;
        this.editingPaymentMethod = false;
        this.showUpdatedWalletTextID = '';
        this.paymentSelectionCmp.clearPayments();
        this.walletErrorExists = false;

        if (!this.addingNewPaymentMethod) {
            this.retrieveWallet(); // retrieveWallet will call doneLoading()
            this.paymentSelectionCmp.clearErrorState();
        } else {
            this.paymentSelectionCmp.initializeBillingAddress();
        }
    }

    editPaymentMethod(methodGUID: string): void {
        this.paymentMethodToUpdate = this.paymentMethods.find(p => p.tokenGUID === methodGUID);
        this.showUpdatedWalletTextID = '';
        this.editingPaymentMethod = true;
        this.addingNewPaymentMethod = true;
        this.walletErrorExists = false;

        if (this.paymentMethodToUpdate != null) {
            this.paymentSelectionCmp.setPaymentMethodToUpdate(this.paymentMethodToUpdate);
        }
    }

    paymentMethodValidation(): void {
        this.paymentSelectionCmp.clearErrorState();
        this.paymentMethodValid = this.paymentSelectionCmp.step2Valid = true;
        this.paymentMethodValid = this.paymentSelectionCmp.isValid();
    }

    backLink(): void {
        if (this.returnUrl != null) {
            this.ngZone.run(() => this.parentRouter.navigateByUrl(this.returnUrl)).then();
        } else {
            this.ngZone.run(() => this.parentRouter.navigateByUrl('/profile')).then();
        }
    }

    doneLoading(): void {
        this.loading = false;
        this.submitting = false;
    }

    isSubmitting(): boolean {
        return this.submitting;
    }

    isLoading(): boolean {
        return this.loading;
    }

    deleteMethodWithConfirmation(method: PaymentMethod) {
        this.walletErrorExists = false;

        // Don't allow delete if the wallet is in use for PaymentPlan, Autopay, or Recurring
        if (method.paymentMethodInUse) {
            this.walletErrorText = this.deleteWalletErrorText;
            this.walletErrorExists = true;
            return;
        }

        this.confirmationModalDisplay = this.deletePaymentMethodModalContent.replace('!PAYMENTMETHOD!', method.description);
        this.confirmationModal.openModal(method);
    }

    private storeRecurringDetailsTerms(recurringPayment: IRecurringPaymentPlan): void {
        // Needed for the Recurring payment confirmation page.
        if (recurringPayment && recurringPayment.emailAddress) {
            this.componentService.storageService.store(
                'recurringpaymentconfirmation', recurringPayment.emailAddress
            );
        } else {
            this.consumerService.getConsumer().then(consumer => {
                if (!!consumer) {
                    this.componentService.storageService.store(
                        'recurringEmailAddress', consumer.emailAddress
                    );
                }
            });
        }
    }
}
