/* tslint:disable:triple-equals */
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AppConfig } from '../../app.config';
import { IDomainInfo } from '../../models/domaininfo';
import { GenericResponse, Response } from '../../models/genericresponse';
import { jsonRequestOptions } from '../../util/HttpClientUtility';
import { LoggingLevel, LoggingService } from '../logging/logging.service';
import { StorageLevel, StorageService } from '../storage/storage.service';
import { ICountry } from './../../models/countries';
import { IOutage } from './../../models/outage';
import { IPaymentLocation } from './../../models/paymentlocation';
import { IState } from './../../models/states';

@Injectable()
export class DomainInfoService {
    private domain = '';
    private domainUrl = this.config.getConfig('domainInfo');
    private domainInfoPath = this.config.getConfig('domainInfoPath');
    private domainInfo = new BehaviorSubject<IDomainInfo>(null);
    private stateList = new BehaviorSubject<any>(null);
    private countryList = new BehaviorSubject<any>(null);
    private paymentLocationList = new BehaviorSubject<any>(null);
    private outageList = new BehaviorSubject<any>(null);
    readonly NULL_GUID = '00000000-0000-0000-0000-000000000000';

    constructor(
        private http: HttpClient,
        private config: AppConfig,
        public storage: StorageService,
        private loggingService: LoggingService
    ) {
        this.domain = this.storage.getDomain();
    }

    public domainInfo$ = this.domainInfo.asObservable();

    public async getDomainInfo(): Promise<IDomainInfo> {
        if (this.storage.exists('domaininfo')) {
            const infoFromStorage = this.storage.retrieve('domaininfo');
            this.domainInfo.next(infoFromStorage);
            const response: IDomainInfo = infoFromStorage;
            return Promise.resolve(response);
        } else {
            const data = await this.callDomainInfoApi(this.domain);
            this.storage.store('domaininfo', data, StorageLevel.protected);
            this.domainInfo.next(this.storage.retrieve('domaininfo'));
            return data;
        }
    }

    public async getStates(): Promise<IState[]> {
        if (this.storage.exists('statelist')) {
            const infoFromStorage = this.storage.retrieve('statelist');
            this.stateList.next(infoFromStorage);
            const response: IState[] = infoFromStorage;
            return Promise.resolve(response);
        } else {
            const data = await this.callGetStatesApi();
            this.storage.store('statelist', data, StorageLevel.protected);
            this.stateList.next(this.storage.retrieve('statelist'));
            return data;
        }
    }

    public async getCountries(): Promise<ICountry[]> {
        if (this.storage.exists('countrylist')) {
            const infoFromStorage = this.storage.retrieve('countrylist');
            this.countryList.next(infoFromStorage);
            const response: ICountry[] = infoFromStorage;
            return Promise.resolve(response);
        } else {
            const data = await this.callGetCountriesApi();
            this.storage.store('countrylist', data, StorageLevel.protected);
            this.countryList.next(this.storage.retrieve('countrylist'));
            return data;
        }
    }

    public async getOutages(): Promise<IOutage[]> {
        const data = await this.callGetOutagesApi();
        this.storage.store('outages', data, StorageLevel.session);
        this.outageList.next(this.storage.retrieve('outages'));
        return data;
    }

    public async getLocations(): Promise<IPaymentLocation[]> {
        if (this.storage.exists('paymentlocationlist')) {
            const infoFromStorage = this.storage.retrieve('paymentlocationlist');
            this.paymentLocationList.next(infoFromStorage);
            const response: IPaymentLocation[] = infoFromStorage;
            return Promise.resolve(response);
        } else {
            const data = await this.callGetLocationsApi();
            this.storage.store('paymentlocationlist', data, StorageLevel.session);
            this.paymentLocationList.next(this.storage.retrieve('paymentlocationlist'));
            return data;
        }
    }

    allowEStatements(info: IDomainInfo, accountType: string): boolean {
        switch (info.deliveryMode) {
            case 'Standard':
                return true;
            case 'Paper Only':
            case 'None':
                if (accountType == 'QuickPay') {
                    return info.enableQuickPayEStatements;
                }
        }
        return false;
    }

    allowSmsStatements(info: IDomainInfo, accountType: string): boolean {
        return info.enableSMS
            || (accountType === 'QuickPay'
                && info.enableQuickPaySMS);
    }

    // domain Info
    async callDomainInfoApi(domain: string): Promise<IDomainInfo> {
        const body = { domain, path: this.domainInfoPath };
        return this.http.post(this.domainUrl, { body }, jsonRequestOptions())
            .toPromise()
            .then((response: Response<IDomainInfo[]>) => response.body)
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    // locations dropdown
    async callGetLocationsApi(): Promise<IPaymentLocation[]> {
        const path: string = this.domainUrl + '?relativePath=' + encodeURIComponent('settings/v2/paymentlocations?domain=' + this.domain + '&type=account') + '&requireCredentials=false';
        return this.http.get(path, jsonRequestOptions())
            .toPromise()
            .then((response: Response<IPaymentLocation[]>) => response.body.data)
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    // state dropdown
    async callGetStatesApi(): Promise<IState[]> {
        const path: string = this.domainUrl + '?relativePath=' + encodeURIComponent('settings/v2/states') + '&requireCredentials=false';
        return this.http.get(path, jsonRequestOptions())
            .toPromise()
            .then((response: Response<IState[]>) => response.body.data)
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    // countries dropdown
    async callGetCountriesApi(): Promise<ICountry[]> {
        const path: string = this.domainUrl + '?relativePath=' + encodeURIComponent('settings/v2/countries') + '&requireCredentials=false';
        return this.http.get(path, jsonRequestOptions())
            .toPromise()
            .then((response: Response<ICountry[]>) => response.body.data)
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    async callGetOutagesApi(): Promise<IOutage[]> {
        const path: string = this.domainUrl + '?relativePath=' + encodeURIComponent('settings/v2/outages') + '&requireCredentials=false';

        return this.http.get(path, jsonRequestOptions())
            .toPromise()
            .then((response: Response<IOutage[]>) => response.body.data)
            .catch((response: HttpResponse<string>) => {
                // Can't use the loggingService#HandleError here because we could
                // get an infinite loop of redirecting back to '/'
                this.loggingService.log(response.body, LoggingLevel.error, response);
                throw new Error(response.body);
            });
    }
}
