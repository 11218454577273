import { Injectable, NgZone } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Roles } from '../../models/roles';
import { ComponentService } from '../component/component.service';
import { LoggingLevel } from '../logging/logging.service';

@Injectable()
export class HomeGuard implements CanActivate {

    constructor(private router: Router, private componentService: ComponentService, private ngZone: NgZone) {
    }

    public canActivate() {
        if (this.isLoggedInAndCanGoHome()) {
            return true;
        } else {
            // redirect to / will send them to the redirect page which should do the figuring out of where they should go.
            this.ngZone.run(() => this.router.navigateByUrl('/')).then();
            return false;
        }
    }

    /**
     * A user is technically logged in when a token is present, but they are not allowed to go to /home when they are:
     * 1. Doing a MEM payment
     * 2. In the process of enrolling
     *
     * @private
     * @returns {boolean}
     * @memberof HomeGuard
     */
    private isLoggedInAndCanGoHome(): boolean {
        const canGoHome = !(this.componentService.storageService.exists('onetimepayment') ||
                            this.componentService.storageService.exists('mem') ||
                            this.componentService.storageService.exists('enrollment')) ||
                            this.componentService.hasRole(Roles.StatementsOnly);
        this.componentService.loggingService.log('HomeGuard->isLoggedInAndCanGoHome->' + canGoHome, LoggingLevel.debug);
        return canGoHome;
    }
}
