
export interface IMaterial {
    type: MaterialType;
    materialGUID: string;
    // Not returned from the API - will get set along the way
    pdfUrl: string;
}

export enum MaterialType {
    env_outer,
    env_return,
    insert,
    stock
}
