import { ACHPaymentMethod } from './achpaymentmethod';
import { Address } from './address';
import { CreditCard } from './creditcard';
import { PaymentMethodAssignmentSource } from './paymentmethodassignmentsource';
import { PaymentToken } from './paymenttoken';

export class AutopayEnrollPayment {
    AutopayAgreementID: number;
    AccountID: string;
    Address: Address;
    CreditCardPaymentMethod: CreditCard;
    TokenPaymentMethod: PaymentToken;
    ACHPaymentMethod: ACHPaymentMethod;
    PaymentLocationGUID: string;
    MaxPaymentAmount: number;
    MerchantProfileGUID: string;
    StorePaymentMethod: boolean;
    PaymentMethodNickname: string;
    ConfirmationEmail: string;
    Language: string;
    PaymentSource: string;
    PaymentMethodAssignmentSource: PaymentMethodAssignmentSource;
    AutoPayFixedDate: number;
    Status: string;
}
