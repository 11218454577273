/* tslint:disable:triple-equals */
import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DomainContentItem } from '../../models/domaincontentitems';
import { IDomainInfo } from '../../models/domaininfo';
import { IOutage } from '../../models/outage';
import { ComponentService } from '../../services/component/component.service';
import { LoginService } from '../../services/login/login.service';

@Component({
    selector: 'outage',
    template: require('./outage.component.html'),
    styles: [require('./outage.component.css')]
})


export class OutageComponent implements OnInit, OnDestroy {
    private ngUnsubscribe: Subject<any> = new Subject();
    outageNotifications: IOutage;
    domainInfo: IDomainInfo;
    parentContent: DomainContentItem[];
    internetContent: DomainContentItem[];
    otherContent: DomainContentItem[];
    title: string;
    message: string;
    customerLogoImageUrl: string;

    constructor(
        private componentService: ComponentService,
        private parentRouter: Router,
        private loginService: LoginService,
        private ngZone: NgZone
    ) {
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit() {
        this.componentService.scrollPageToTop();

        this.componentService.domainService.getOutages()
            .then(outageList => {
                if (outageList != null && outageList.length == 0) {
                    this.ngZone.run(() => this.parentRouter.navigateByUrl('/')).then();
                }
                this.outageNotifications = outageList.find(x => x.system == 'MySecureBill Maintenance');
                if (outageList.some(x => new Date(x.outageStartDate) < new Date() && new Date(x.outageEndDate) > new Date() && x.system == 'MySecureBill Maintenance')) {
                    this.title = this.outageNotifications.title;
                    this.message = this.outageNotifications.message;
                }
            });

        this.componentService.contentService.content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((content: { help: any; }) => {
                if (content != null) {
                    this.parentContent = [];
                    this.internetContent = [];
                    this.otherContent = [];
                    this.contactUs(content.help);
                    this.customerLogoImageUrl = this.componentService.contentService.tryGetContentItem(content, 'header', 'image', 'headerCustomerLogoImage').text;
                }
            });
        this.componentService.domainService.domainInfo$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
            domainInfo => {
                this.domainInfo = domainInfo;
            });
    }

    contactUs(contactInfo: any): void {
        //  these are the parents
        // tslint:disable-next-line: forin
        for (const key in contactInfo.contactUs) {
            this.parentContent.push(contactInfo.contactUs[key]);
        }

        // tslint:disable-next-line: forin
        for (const key in contactInfo.contactUsAddress) {
            const contact: DomainContentItem = contactInfo.contactUsAddress[key];
            if (contact.title == '' && contact.text == '') {
                contact.title = '&nbsp;';
                contact.text = '&nbsp;';
            }
            this.otherContent.push(contact);
        }

        // tslint:disable-next-line: forin
        for (const key in contactInfo.contactUsOther) {
            const contact: DomainContentItem = contactInfo.contactUsOther[key];
            if (contact.title == '' && contact.text == '') {
                contact.title = '&nbsp;';
                contact.text = '&nbsp;';
            }
            this.otherContent.push(contact);
        }

        // tslint:disable-next-line: forin
        for (const key in contactInfo.contactUsInternet) {
            const contact: DomainContentItem = contactInfo.contactUsInternet[key];
            if (contact.title != null) {
                if (contact.title.toLowerCase().includes('email') && !contact.text.includes('mailto')) {
                    contact.text = '<a class="site-color" href="mailto:' + contact.text + '">' + contact.text + '</a>';
                } else if (!contact.text.toLowerCase().includes('href') && !contact.text.includes('mailto')) {
                    contact.text = '<a class="site-color" href="http://' + contact.text + '" target="_blank">' + contact.text + '</a>';
                }

                if (contact.title == '' && contact.text == '') {
                    contact.title = '&nbsp;';
                    contact.text = '&nbsp;';
                }
            }
            this.internetContent.push(contact);
        }
    }

    isVisible(flag: string): boolean {
        if (this.domainInfo != null) {
            if (this.domainInfo[flag] != null && this.domainInfo[flag].length > 0) {
                return true;
            }
        }
        return false;
    }

    getLink(flag: string): string {
        if (this.domainInfo != null) {
            if (this.domainInfo[flag] != null && this.domainInfo[flag].length > 0) {
                return this.domainInfo[flag];
            }
        }
        return '';
    }

    getParentsInfo(contactItem: DomainContentItem): DomainContentItem[] {
        const parentId: number = contactItem.parentID;
        let newList: DomainContentItem[] = [];
        const localArray = this.otherContent.filter(x => x.parentID == parentId);
        // tslint:disable-next-line: forin
        for (const key in localArray) {
            newList.push(localArray[key]);
        }

        newList = newList.sort(function(a: DomainContentItem, b: DomainContentItem) { return a.sequence - b.sequence; });

        return newList;
    }

    getInternetInfo(contactItem: DomainContentItem): DomainContentItem[] {
        const parentId: number = contactItem.parentID;
        let newList: DomainContentItem[] = [];
        const localArray = this.internetContent.filter(x => x.parentID == parentId);

        // tslint:disable-next-line: forin
        for (const key in localArray) {
            newList.push(localArray[key]);
        }

        newList = newList.sort(function(a: DomainContentItem, b: DomainContentItem) { return a.sequence - b.sequence; });

        return newList;
    }
}
