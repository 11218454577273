import { Component, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PaymentSelectionComponent } from '../../../components/Controls/PaymentSelection/paymentselection.component';
import { PaymentSelectionModel } from '../../../models/paymentselectionmodel';
import { ComponentService } from '../../../services/component/component.service';
import { LoginService } from '../../../services/login/login.service';
import { PaymentSelectionStateService } from '../../../services/paymentselection/paymentselection.state.service';

@Component({
    selector: 'payment-method-modal',
    template: require('./paymentmethodmodal.component.html'),
    styles: [require('./paymentmethodmodal.component.css')]
})

export class PaymentMethodModalComponent implements OnInit {
    confirmButtonText: string;
    cancelButtonText: string;
    modalTitle: string;

    paymentMethodValid = false;
    submitting = false;
    updatedWalletErrorText: string;
    loading = false;

    modelToPassToPayment: PaymentSelectionModel = {
        amountToPay: 0,
        isOneTimePayment: true,
        enableWallet: true,
        defaultUpdateAddress: true,
        contentItemCategory: 'payment',
        contentItemSubCategory: 'loggedInPayment'
    } as PaymentSelectionModel;

    @ViewChild('modalpaymentselection', { static: false }) paymentSelectionCmp: PaymentSelectionComponent;

    private ngUnsubscribe: Subject<any> = new Subject();

    constructor(
        public stateService: PaymentSelectionStateService,
        private componentService: ComponentService,
        private loginService: LoginService
    ) { }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit() {
        this.componentService.contentService.content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((content: any) => {
                this.confirmButtonText = this.componentService.contentService.tryGetContentItem(content, 'modal', 'button', 'modalSubmitButton').text;
                this.cancelButtonText = this.componentService.contentService.tryGetContentItem(content, 'modal', 'button', 'modalCancelButton').text;
                this.updatedWalletErrorText = this.componentService.contentService.tryGetContentItem(content, 'profile', 'wallet', 'profileUpdatedWalletErrorText').text;
                this.modalTitle = 'Edit Your Payment Method';
            });

        this.loginService.sessionTimeout$.subscribe((timeout: boolean) => {
            if (timeout) {
                this.cancel();
            }
        });
    }

    public confirm() {
        this.stateService.updatePaymentMethod();
    }

    public cancel() {
        this.stateService.exitPaymentMethodModal();
    }

    public isLoading(): boolean {
        return this.submitting;
    }

    public startLoading(): void {
        this.submitting = true;
    }

    public doneLoading(): void {
        this.submitting = false;
    }
}
