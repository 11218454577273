import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ComponentService } from '../../services/component/component.service';

@Component({
    selector: 'profile',
    template: require('./profile.component.html'),
    styles: [require('./profile.component.css')]
})
export class ProfileComponent implements OnInit, OnDestroy {
    @Input() adTitle: string;
    @Input() adText: string;
    @Input() adImageUrl: string;
    @Input() adLinkText: string;
    @Input() adLinkUrl: string;

    private ngUnsubscribe: Subject<any> = new Subject();

    constructor(private componentService: ComponentService, private parentRouter: Router) {
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit() {
        this.componentService.scrollPageToTop();
        this.componentService.contentService.content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((content: any) => {
                this.adTitle = this.componentService.contentService.tryGetContentItem(content, 'ad', 'profile', 'adTitle').text;
                this.adText = this.componentService.contentService.tryGetContentItem(content, 'ad', 'profile', 'adText').text;
                this.adImageUrl = this.componentService.contentService.tryGetContentItem(
                    content, 'ad', 'image', 'profileAdImageUrl').text;
                this.adLinkText = this.componentService.contentService.tryGetContentItem(content, 'ad', 'profile', 'adLinkText').text;
                this.adLinkUrl = this.componentService.contentService.tryGetContentItem(content, 'ad', 'profile', 'adLinkUrl').text;
            });
    }
}
