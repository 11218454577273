import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ComponentService } from '../../../services/component/component.service';

@Component({
    selector: 'delivery-method-widget',
    template: require('./deliverymethodwidget.component.html'),
    styles: [require('./deliverymethodwidget.component.css')]
})
export class DeliveryMethodWidgetComponent implements OnInit, OnDestroy {

    deliveryMethodText: string;
    deliveryMethodHeadlineText: string;
    deliveryMethodLinkText: string;
    deliveryMethodRoute = '/profile';
    // semi-colon delimited list of options:text for Delivery Method (e.g. "Electronic:e-Delivery;Paper:Paper;Both:Electronic & Paper")
    deliveryChoices: string;
    deliveryChoiceBodyText: string;

    @Input() set deliveryMethod(deliveryMethod: string) {
        this.deliveryMethodText = deliveryMethod;
        this.updateDeliveryChoices();
    }

    private ngUnsubscribe: Subject<any> = new Subject();

    constructor(private componentService: ComponentService) {
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit() {
        this.componentService.contentService.content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((content: any) => {
                this.deliveryMethodHeadlineText = this.componentService.contentService.tryGetContentItem(content, 'home', 'profile', 'homePageDeliveryMethodHeadline').text;
                this.deliveryMethodLinkText = this.componentService.contentService.tryGetContentItem(content, 'home', 'profile', 'homePageDeliveryMethodLinkText').text;
                this.deliveryChoices = this.componentService.contentService.tryGetContentItem(content, 'profile', 'pageText', 'profileDeliveryMethodChoices').text;
            });

        this.updateDeliveryChoices();
    }

    private updateDeliveryChoices(): void {
        if (this.deliveryMethodText != null && this.deliveryChoices != null) {
            // choose the correct deliveryChoices string to use from content items
            if (this.deliveryMethodText.toLocaleLowerCase().includes('both')) {
                this.deliveryChoices = this.deliveryChoices.split(';').filter(x => x.includes('&')).join(';');
            } else if (this.deliveryMethodText.toLocaleLowerCase().includes('paper')) {
                this.deliveryChoices = this.deliveryChoices.split(';').filter(x => x.toLocaleLowerCase().includes('paper')).join(';');
            }

            this.deliveryChoiceBodyText = this.deliveryChoices.split(';')[0].split(':')[1];
        }
    }
}
