import { Injectable, NgZone } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { ConsumerAccount } from '../../models/consumeraccount';
import { ComponentService } from '../component/component.service';
import { LoggingLevel } from '../logging/logging.service';

@Injectable()
export class LoginGuard implements CanActivate {

    private token: string;
    private account: ConsumerAccount;

    constructor(private router: Router, private componentService: ComponentService, private ngZone: NgZone) {
    }

    public canActivate(): boolean {
        this.componentService.loggingService.log('LoginGuard canActivate', LoggingLevel.debug);

        if (this.componentService.userIsLoggedIn() && !this.forcePasswordReset()) {
            return true;
        } else {
            this.ngZone.run(() => this.router.navigateByUrl('/')).then();
            return false;
        }
    }

    private forcePasswordReset(): boolean {
        // skip the check if doing a OneTimePayment or user is Impersonator
        if (this.isAgentAssistedRole() || this.isMEMAuthenticated() || this.isNoConsumerRole() || this.isImpersonator()) {
            this.componentService.loggingService.log('LoginGuard->forcePasswordReset->' + false, LoggingLevel.debug);
            return false;
        }
        this.account = this.componentService.storageService.retrieve('consumeraccount');
        if (this.account != null) {
            this.componentService.loggingService.log(
                'LoginGuard->forcePasswordReset->' + this.account.forcePasswordReset, LoggingLevel.debug);
            return this.account.forcePasswordReset;
        } else {
            this.componentService.loggingService.log('LoginGuard->forcePasswordReset->' + true, LoggingLevel.debug);
            this.componentService.storageService.clearSession();
            this.componentService.loggingService.log('LoginGuard->forcePasswordReset: session Cleared', LoggingLevel.debug);
            return true;
        }
    }

    private isMEMAuthenticated(): boolean {
        const isMEMAuthenticated = (this.componentService.storageService.exists('onetimepayment')
            || this.componentService.storageService.exists('mem')
            || this.componentService.storageService.exists('enrollment'));
        this.componentService.loggingService.log('LoginGuard->isMEMAuthenticated->' + isMEMAuthenticated, LoggingLevel.debug);
        return isMEMAuthenticated;
    }

    private isNoConsumerRole(): boolean {
        // TODO: refactor when a role service is implemented
        return this.componentService.storageService.retrieve('noconsumerstatementhistoryrole');
    }

    private isAgentAssistedRole(): boolean {
        // TODO: refactor when a role service is implemented
        return this.componentService.storageService.retrieve('agentassistedrole');
    }

    private isImpersonator(): boolean {
        return this.componentService.storageService.exists('impersonatorroleallowpayments');
    }
}
