import { Component, HostListener, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConsumerContactDetailsModalComponent } from '../../components/Controls/ConsumerContactDetailsModal/consumercontactdetailsmodal.component';
import { PaymentSelectionComponent } from '../../components/Controls/PaymentSelection/paymentselection.component';
import { IAutopayAgreement } from '../../models/autopayagreement';
import { AutopayEnrollPayment } from '../../models/autopayenrollpayment';
import { IDomainInfo } from '../../models/domaininfo';
import { ErrorMessage } from '../../models/errormessage';
import { GuarantorEmailViewModel } from '../../models/guarantoremailviewmodel';
import { IMerchantProfile } from '../../models/imerchantprofile';
import { IPaymentLocation } from '../../models/paymentlocation';
import { PaymentMethod, PaymentMethodType } from '../../models/paymentmethod';
import { PaymentMethodAssignmentSource } from '../../models/paymentmethodassignmentsource';
import { PaymentSelectionModel, PaymentSelectionStateModel } from '../../models/paymentselectionmodel';
import { AgentAssistedService } from '../../services/agentassisted/agentassisted.service';
import { ComponentService } from '../../services/component/component.service';
import { ConsumerService } from '../../services/consumer/consumer.service';
import { ICommunicationPreferences } from './../../models/communicationPreferences';
import { ICommunicationPreferenceChange } from './../../models/communicationPreferenceType';
import { IConsumer } from './../../models/consumer';

@Component({
    selector: 'autopayenrollment',
    template: require('./autopayenrollment.component.html'),
    styles: [require('./autopayenrollment.component.css')]
})
export class AutopayEnrollmentComponent implements OnInit, OnDestroy {
    constructor(
        private agentAssistedService: AgentAssistedService,
        private componentService: ComponentService,
        private consumerService: ConsumerService,
        private parentRouter: Router,
        private ngZone: NgZone
    ) { }

    currentStep = 1;
    finalStep = 3;
    selectedPaymentMethod: PaymentMethod;
    domainInfo: IDomainInfo;
    errorMessage: ErrorMessage;

    canShowEmailError = false;
    merchantLoaded = false;
    consumerLoaded = false;
    locationsLoaded = false;
    isSubmitting = false;
    noPreferenceSelected = false;

    changeEmailText: string;
    requiredText: string;

    step1Valid = true;
    step2Valid = true;
    step3Valid = true;

    step1HeaderText = '';
    step1SubHeadlineText = '';
    step1MobileHeader = '';
    step1PaymentAmountText = '';
    step1PaymentDescriptionText = '';
    step1EmailErrorMessage = '';
    step1MinPaymentErrorMessage = '';
    step1EmailAddressLabelText = '';
    step1PaymentDateLabelText = '';
    step1PaymentDateErrorMessage = '';
    step1LocationLabelText = '';
    step1CancelButtonText = '';
    step1ContinueButtonText = '';

    step2HeaderText = '';
    step2SubHeadlineText = '';
    step2MobileHeader = '';
    step2CancelButtonText = '';
    step2ContinueButtonText = '';

    step3HeaderText = '';
    step3SubHeadlineText = '';
    step3MobileHeader = '';
    step3CancelButtonText = '';
    step3ContinueButtonText = '';
    step3PaymentMethodText = '';
    step3EmailReceiptText = '';
    step3PhoneNumberReceiptText = '';
    step3LocationText = '';
    paymentLocationDescription = '';

    enrollmentStep1Name = '';
    enrollmentStep2Name = '';
    enrollmentStep3Name = '';

    stepList: any[] = [];

    locationList: IPaymentLocation[] = [];
    paymentLocationGUID: string = this.componentService.NULL_GUID;

    /// The day of month for fixed-date autopay. 1 - 28.
    paymentDate: number;
    paymentDateFailedValidation = false;

    emailAddress = '';
    paymentAmount: number;
    paymentAmountErrorsExist = false;
    missingEmailOrPhone = false;

    isAgentAssistedRole = false;
    consumerToken: string;
    agentAssistedGuid: string;
    agentAssistedAutopayAgreement: IAutopayAgreement;
    isAgentAssistedError = false;
    agentAssistedErrorMessage: string;
    autopayStatus: string;

    consumer: IConsumer;
    private merchantProfile: IMerchantProfile;
    private autoPayRouteLocation = '/autopay';
    private homeRouteLocation = '/';
    private ngUnsubscribe: Subject<any> = new Subject();
    modelToPassToPayment: PaymentSelectionModel = new PaymentSelectionModel();

    guarantorEmails: GuarantorEmailViewModel[];

    phoneNumber = '';
    preferences: ICommunicationPreferenceChange[];
    communicationPreferences: ICommunicationPreferences;
    isSms: boolean;

    // Used as param for communication-preference-selection control
    hasAgreements: boolean;

    @ViewChild('paymentSelectionCmp', { static: false }) paymentSelectionCmp: PaymentSelectionComponent;
    @ViewChild('contactDetailsModal', { static: false }) contactDetailsModal: ConsumerContactDetailsModalComponent;

    /**
     * onPopState listens for the popstate event on the window and performs the actions, this handles the browser back button
     * @see https://stackoverflow.com/questions/40381814/how-do-i-detect-user-navigating-back-in-angular2
     * @param {*} event the event
     * @memberof AutopayEnrollmentComponent
     */
    @HostListener('window:popstate', ['$event'])
    onPopState(event: any) {
        // wrap with this because it messes up unit tests
        if (process.env.ENV !== 'test') {
            // needed for onPopState to function completely
            history.pushState(false, 'autopayenrollment', 'autopayenrollment');
        }
        this.prevStep();
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    async ngOnInit() {
        this.componentService.scrollPageToTop();
        this.isAgentAssistedRole = this.checkAgentAssistedRole();
        this.currentStep = this.isAgentAssistedRole ? 2 : 1;
        if (!this.isAgentAssistedRole) {
            this.retrieveAutopayAgreements();
        }
        this.componentService.contentService.content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((content: any) => {
                if (content != null) {
                    this.changeEmailText = this.componentService.contentService.tryGetContentItem(content, 'autopay', 'pageText', 'autopayEmailChangeLinkText').text;
                    this.requiredText = this.componentService.contentService.tryGetContentItem(content, 'autopay', 'pageText', 'autopayRequiredText').text;

                    this.step1HeaderText = this.componentService.contentService.tryGetContentItem(content, 'autopay', 'headline', 'autopayEnrollStep1Header').text;
                    this.step1SubHeadlineText = this.componentService.contentService.tryGetContentItem(content, 'autopay', 'headline', 'autopayEnrollStep1Subheader').text;
                    this.step1MobileHeader = this.componentService.contentService.tryGetContentItem(content, 'autopay', 'headline', 'autopayEnrollStep1MobileHeader').text;
                    this.step1PaymentAmountText = this.componentService.contentService.tryGetContentItem(content, 'autopay', 'pageText', 'autopayEnrollStep1PaymentAmountText').text;
                    this.step1PaymentDescriptionText = this.componentService.contentService.tryGetContentItem(
                        content, 'autopay', 'pageText', 'autopayEnrollStep1PaymentDescriptionText').text;
                    this.step1EmailErrorMessage = this.componentService.contentService.tryGetContentItem(content, 'error', 'autopay', 'autopayEnrollStep1EmailErrorText').text;
                    this.step1MinPaymentErrorMessage = this.componentService.contentService.tryGetContentItem(content, 'error', 'autopay', 'autopayEnrollStep1MinPaymentErrorText').text;
                    this.step1EmailAddressLabelText = this.componentService.contentService.tryGetContentItem(content, 'autopay', 'label', 'autopayEnrollStep1EmailAddressLabelText').text;
                    this.step1LocationLabelText = this.componentService.contentService.tryGetContentItem(content, 'autopay', 'label', 'autopayEnrollStep1LocationLabelText').text;
                    this.step1PaymentDateLabelText = this.componentService.contentService.tryGetContentItem(content, 'autopay', 'label', 'autopayDayofMonthLabel').text;
                    this.step1PaymentDateErrorMessage = this.componentService.contentService.tryGetContentItem(content, 'autopay', 'error', 'autoPayInvalidDayofMonth').text;
                    this.step1CancelButtonText = this.componentService.contentService.tryGetContentItem(content, 'autopay', 'button', 'autopayEnrollStep1CancelButtonText').text;
                    this.step1ContinueButtonText = this.componentService.contentService.tryGetContentItem(content, 'autopay', 'button', 'autopayEnrollStep1ContinueButtonText').text;

                    this.enrollmentStep1Name = this.componentService.contentService.tryGetContentItem(content, 'autopay', 'pageText', 'autopayEnrollStep1Name').text;
                    this.enrollmentStep2Name = this.componentService.contentService.tryGetContentItem(content, 'autopay', 'pageText', 'autopayEnrollStep2Name').text;
                    this.enrollmentStep3Name = this.componentService.contentService.tryGetContentItem(content, 'autopay', 'pageText', 'autopayEnrollStep3Name').text;

                    this.step2CancelButtonText = this.componentService.contentService.tryGetContentItem(content, 'autopay', 'button', 'autopayEnrollStep2CancelButtonText').text;
                    this.step2ContinueButtonText = this.componentService.contentService.tryGetContentItem(content, 'autopay', 'button', 'autopayEnrollStep2ContinueButtonText').text;
                    this.step2HeaderText = this.componentService.contentService.tryGetContentItem(content, 'autopay', 'headline', 'autopayEnrollStep2Header').text;
                    this.step2SubHeadlineText = this.componentService.contentService.tryGetContentItem(content, 'autopay', 'headline', 'autopayEnrollStep2Subheader').text;
                    this.step2MobileHeader = this.componentService.contentService.tryGetContentItem(content, 'autopay', 'headline', 'autopayEnrollStep2MobileHeader').text;
                    this.step3CancelButtonText = this.componentService.contentService.tryGetContentItem(content, 'autopay', 'button', 'autopayEnrollStep3CancelButtonText').text;
                    this.step3ContinueButtonText = this.componentService.contentService.tryGetContentItem(content, 'autopay', 'button', 'autopayEnrollStep3ContinueButtonText').text;
                    this.step3HeaderText = this.componentService.contentService.tryGetContentItem(content, 'autopay', 'headline', 'autopayEnrollStep3Header').text;
                    this.step3SubHeadlineText = this.componentService.contentService.tryGetContentItem(content, 'autopay', 'headline', 'autopayEnrollStep3Subheader').text;
                    this.step3MobileHeader = this.componentService.contentService.tryGetContentItem(content, 'autopay', 'headline', 'autopayEnrollStep3MobileHeader').text;
                    this.step3PaymentMethodText = this.componentService.contentService.tryGetContentItem(content, 'autopay', 'label', 'autopayEnrollStep3PaymentMethodLabelText').text;
                    this.step3EmailReceiptText = this.componentService.contentService.tryGetContentItem(content, 'autopay', 'label', 'autopayEnrollStep3EmailReceiptLabelText').text;
                    this.step3PhoneNumberReceiptText = this.componentService.contentService.tryGetContentItem(content, 'autopay', 'label', 'autoPayEnrollStep3PhoneNumber').text;
                    this.step3LocationText = this.componentService.contentService.tryGetContentItem(content, 'autopay', 'label', 'autopayEnrollStep3LocationLabelText').text;

                    this.stepList = [
                        { stepName: this.enrollmentStep1Name },
                        { stepName: this.enrollmentStep2Name },
                        { stepName: this.enrollmentStep3Name },
                    ];
                }
            });

        this.merchantProfile = await this.consumerService.getMerchantProfileForDomain();
        if (this.merchantProfile && !!this.merchantProfile.autoPayDefaultMaxAmount &&
            this.merchantProfile.autoPayDefaultMaxAmount > 0) {
            this.paymentAmount = this.merchantProfile.autoPayDefaultMaxAmount;
        }
        this.merchantLoaded = true;

        const locationList = await this.consumerService.getAutopayEnrollmentLocations();
        if (locationList.length === 0 && !this.isAgentAssistedRole) {
            this.ngZone.run(() => this.parentRouter.navigateByUrl(this.autoPayRouteLocation)).then();
        } else {
            this.locationList = locationList;

            // These are set for AA below
            if (!this.isAgentAssistedRole) {
                this.paymentLocationGUID = locationList[0].paymentLocationGUID;

                this.setPaymentLocationDescription();
            }

            this.locationsLoaded = true;
        }

        this.consumerLoaded = true;

        await this.populateConsumer();

        this.modelToPassToPayment.contentItemCategory = 'payment';
        this.modelToPassToPayment.contentItemSubCategory = 'loggedInPayment';
        this.modelToPassToPayment.showPaymentDate = false;
        this.modelToPassToPayment.lockSaveToWallet = true;

        this.componentService.domainService.domainInfo$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(domainInfo => {
                this.domainInfo = domainInfo;
                if (this.domainInfo && !this.domainInfo.useConsolidatedCommunication) {
                    this.emailAddress = this.consumer.emailAddress;
                }

                // Display the input for selecting a day of the month and set a default value.
                if (this.domainInfo && this.domainInfo.enableAutoPayDatePicker) {
                    const dayOfMonth = (new Date()).getDate();
                    this.paymentDate = dayOfMonth > 28 ? 1 : dayOfMonth;
                }
            });

        if (this.isAgentAssistedRole) {
            this.agentAssistedGuid = this.componentService.storageService.retrieve('agentAssistedPaymentEmailGUID');
            this.consumerToken = this.componentService.storageService.retrieve('token');
            this.isSms = this.componentService.storageService.retrieve('confirmationMessageType') === 'sms';
            this.agentAssistedService.retrieveAutopayAgreement(
                this.consumerToken,
                this.agentAssistedGuid
            ).then(async response => {
                if (response != null) {
                    this.agentAssistedAutopayAgreement = response;
                    this.paymentAmount = this.agentAssistedAutopayAgreement.maxPaymentAmount;
                    this.paymentLocationGUID = this.agentAssistedAutopayAgreement.paymentLocationGUID;

                    this.paymentLocationDescription = this.agentAssistedAutopayAgreement.paymentLocationDescription;
                }
            }).catch(err => {
                this.isAgentAssistedError = true;
                this.agentAssistedErrorMessage = 'Error occurred retrieving your Autopay Agreement. Please contact Support.';
            });
        }
    }

    private checkAgentAssistedRole() {
        return this.componentService.storageService.exists('agentassistedrole');
    }

    private async populateConsumer(): Promise<void> {
        this.consumer = await this.consumerService.getConsumer();
    }

    isLoading() {
        return !(this.consumerLoaded && this.merchantLoaded && this.locationsLoaded);
    }

    emailInputUpdated() {
        this.canShowEmailError = true;
    }

    prevStep() {
        this.step3Valid = true;
        const paymentState = this.paymentSelectionCmp.getPaymentSelectionState();

        if (this.currentStep === 1) {
            this.ngZone.run(() => this.parentRouter.navigateByUrl(this.autoPayRouteLocation)).then();
        }

        if (this.currentStep > 1) {
            if (this.currentStep === 2 &&
                paymentState.creditCardNumber != null &&
                paymentState.creditCardNumber.length > 0) {
                this.paymentSelectionCmp.prepareCreditCard();
            }

            this.paymentSelectionCmp.checkACH();
            this.paymentSelectionCmp.checkCreditCard();
            this.paymentSelectionCmp.revertFakeSavedPayment();

            this.currentStep--;
            this.componentService.scrollPageToTop();
        }

        this.selectedPaymentMethod = paymentState.selectedPaymentMethod;
        this.paymentSelectionCmp.clearErrorState();
    }

    nextStep(): void {
        if (this.domainInfo.useConsolidatedCommunication) {
            // Consumer has not checked the Email or Text radio button
            // noPreferenceSelected = true so the radio button circles are red
            if (!this.preferences && !this.hasAgreements && !this.isAgentAssistedRole) {
                this.noPreferenceSelected = true;

                return;
            }

            if (this.missingEmailOrPhone) {
                // Consumer has checked a preference that does not yet have
                // a saved value, so an error message should already be
                // displayed - just prevent nextStep() from continuing
                return;
            }

            if (this.isAgentAssistedRole) {
                this.emailAddress = this.consumer.emailAddress;
                this.phoneNumber = this.consumer.phone;
            } else {
                this.isSms = this.preferences[1].newValue;
            }
            if (this.isSms && !this.phoneNumber) {
                // SMS selected, but no phone number is saved
                this.handleValidationMessaging();
                return;
            }
        }

        // Email required, even for UCC = 1 and SMS is selected
        if (!this.emailAddress || !this.paymentAmount || this.paymentAmount < 0.01) {
            this.handleValidationMessaging();

            return;
        }

        if (this.paymentDateFailedValidation) {
            return;
        }

        // wrap with this because it messes up unit tests
        if (process.env.ENV !== 'test') {
            // needed for onPopState to function completely
            history.pushState(false, 'autopayenrollment', 'autopayenrollment');
        }

        if (this.currentStep === 1) {
            this.modelToPassToPayment.amountToPay = this.paymentAmount;
            this.paymentSelectionCmp.setMerchantProfile(this.merchantProfile);
            if (this.currentStepIsValid) {
                this.paymentSelectionCmp.enableWallet();
            }

            // Update the Location description shown on Step 3.
            this.setPaymentLocationDescription();
        }

        if (this.currentStep === 2) {
            if (this.isAgentAssistedRole) {
                this.modelToPassToPayment.amountToPay = this.paymentAmount;
            }
            this.paymentMethodValidation();
        }

        if (this.currentStep < this.finalStep && this.currentStepIsValid(this.currentStep)) {
            this.currentStep++;
            this.componentService.scrollPageToTop();
        } else if (this.currentStep === this.finalStep && this.currentStepIsValid(this.currentStep)) {
            const autoEnrollmentPayment = this.createPayment();
            this.autopayStatus = autoEnrollmentPayment.Status;
            this.isSubmitting = true;
            this.consumerService.enrollInAutoPayAgreement(autoEnrollmentPayment).then(response => {
                // Preferences are only saved when a consumer has NEVER created an AutoPay agreement
                if (!this.isAgentAssistedRole && !this.hasAgreements) {
                    // Communication preferences were chosen in CommunicationPreferenceSelectionComponent
                    // Use the "preferenceForNewAgreement" that was emitted to this form to save those values
                    if (this.domainInfo.useConsolidatedCommunication && response.success) {
                        this.updateCommunicationPreference(this.preferences);
                    }
                }

                this.consumerService.updateWalletPaymentMethodStorage().then(methods => {
                    if (methods != null) {
                        this.isSubmitting = false;
                        if (this.isAgentAssistedRole) {
                            // store information needed for confirmation page
                            if (this.autopayStatus === 'Active') {
                                this.componentService.storageService.store('agentAssistedRole', 'autopayexisting');
                                this.ngZone.run(() => this.parentRouter.navigateByUrl('/autopayenrollmentconfirmation')).then();
                            } else {
                                this.componentService.storageService.store('agentAssistedRole', 'autopayenrollmentconfirmation');
                                this.ngZone.run(() => this.parentRouter.navigateByUrl('/autopayenrollmentconfirmation')).then();
                            }
                        } else {
                            this.ngZone.run(() => this.parentRouter.navigateByUrl('/autopay')).then();
                        }
                    }
                });
            });
        }

        this.selectedPaymentMethod = this.paymentSelectionCmp.getPaymentSelectionState().selectedPaymentMethod;
    }

    private async updateCommunicationPreference(changes: ICommunicationPreferenceChange[]) {
        this.communicationPreferences = await this.consumerService.updateConsumerCommunicationPreference(
            changes[0].customerAccountID, changes
        );
    }

    private handleValidationMessaging() {
        this.paymentAmountErrorsExist = !this.paymentAmount || this.paymentAmount < 0.01;
    }

    /**
     * This is the assembly of the ConsumerPayment to send to the various ConsumerAPI methods for
     * validation and payment.  createPayment stores the payment created on the PaymentComponent to be
     * used throughout the various calls.
     *
     * @memberof AutopayEnrollmentComponent
     */
    public createPayment(): AutopayEnrollPayment {
        const paymentState: PaymentSelectionStateModel = this.paymentSelectionCmp.getPaymentSelectionState();
        const payment: AutopayEnrollPayment = new AutopayEnrollPayment();
        payment.PaymentSource = this.paymentSelectionCmp.getPaymentSource();
        payment.PaymentMethodAssignmentSource = PaymentMethodAssignmentSource.SelfService;
        // must have one of tokenpayment,achpayment or creditcardpayment
        // check for wallet
        if (!!paymentState.selectedPaymentMethod.tokenGUID && paymentState.selectedPaymentMethod.tokenGUID !== 'newPaymentMethod') {
            payment.TokenPaymentMethod = {
                methodToken: paymentState.selectedPaymentMethod.tokenGUID,
                methodType: paymentState.selectedPaymentMethod.paymentMethodType === PaymentMethodType.ach ? 'ACH' : 'Credit',
                tokenDataSource: 'msbAutopay'
            };
        } else {
            // not wallet, is it cc or ach?
            if (paymentState.selectedPaymentMethod.paymentMethodType === PaymentMethodType.ach) {
                payment.ACHPaymentMethod = this.paymentSelectionCmp.getACHPayment();
            } else {
                payment.CreditCardPaymentMethod = this.paymentSelectionCmp.getCreditCardPayment();
            }
        }
        payment.Address = {
            firstName: paymentState.firstName,
            lastName: paymentState.lastName,
            address1: paymentState.address1,
            address2: paymentState.address2,
            city: paymentState.city,
            stateRegion: paymentState.state,
            postalCode: paymentState.postalCode,
            country: null
        };

        payment.MerchantProfileGUID = this.merchantProfile.merchantProfileGUID;
        payment.MaxPaymentAmount = this.paymentAmount;
        payment.PaymentLocationGUID = this.paymentLocationGUID;
        payment.AccountID = this.consumer.accountID;
        payment.StorePaymentMethod = paymentState.saveToWallet;
        payment.PaymentMethodNickname = paymentState.newPaymentNickname;
        payment.ConfirmationEmail = this.emailAddress;
        payment.Language = window.navigator.language;
        payment.AutoPayFixedDate = this.paymentDate;
        payment.Status = (this.agentAssistedAutopayAgreement != null) ? this.agentAssistedAutopayAgreement.status.toString() : null;

        if (this.isAgentAssistedRole) {
            payment.AutopayAgreementID = this.agentAssistedAutopayAgreement.autopayAgreementID;
        }

        return payment;
    }

    onChangeEmailClicked(): void {
        this.contactDetailsModal.openModal();
    }

    onGuarantorEmailsEmitted(guarantorEmails: GuarantorEmailViewModel[]): void {
        if (guarantorEmails.length === 1) {
            this.emailAddress = guarantorEmails[0].emailAddress;
        }
    }

    onEmailUpdated(emailAddress: string): void {
        this.emailAddress = emailAddress;
    }

    // BEGIN UCC = 1 METHODS
    // The next the four methods get information from the
    // AutopayComponent and CommunicationPreferenceSelectionComponent
    // that this component will need to set preferences for
    // UCC = 1 consumers
    onMissingEmailOrPhone(missingEmailOrPhone: boolean): void {
        this.missingEmailOrPhone = missingEmailOrPhone;
    }

    onEmailForNewAgreement(emailAddress: string): void {
        this.emailAddress = emailAddress;
    }

    onPhoneForNewAgreement(phoneNumber: string): void {
        this.phoneNumber = phoneNumber;
    }

    onPreferenceForNewAgreement(changes: ICommunicationPreferenceChange[]): void {
        this.preferences = changes;
    }
    // END UCC = 1 METHODS

    removePaymentAmountValidationMessaging(): void {
        // we'd like for the user to input anything without seeing validation messaging
        // until they choose to move on to the next step
        this.paymentAmountErrorsExist = false;
    }

    validatePaymentDate(): void {
        this.paymentDateFailedValidation = !Number.isInteger(this.paymentDate) || this.paymentDate < 1 || this.paymentDate > 28;
    }

    retrieveAutopayAgreements(): void {
        this.consumerService.getAutopayAgreements()
            .then(agreements => {
                // Used as param for communication-preference-selection control
                this.hasAgreements = (agreements && agreements.length > 0);
            });
    }

    private validatePayment(): void {
        // get the state and use it when creating the payment etc.
        const state = this.paymentSelectionCmp.getPaymentSelectionState();

        // set things correctly from the state to create the payment and post it to where we need etc.
    }

    private currentStepIsValid(step: number): boolean {
        let stepIsValid: boolean;
        switch (step) {
            case 1:
                stepIsValid = this.step1Valid;
                break;

            case 2:
                stepIsValid = this.step2Valid;
                break;

            case 3:
                stepIsValid = this.step3Valid;
                break;
        }
        return stepIsValid;
    }

    private paymentMethodValidation(): void {
        this.step2Valid = this.paymentSelectionCmp.isValid();
    }

    public setPaymentLocationDescription(): void {
        this.paymentLocationDescription = this.selectedLocation();
    }

    private selectedLocation(): string {
        if (this.paymentLocationGUID === this.componentService.NULL_GUID) {
            return null;
        }

        const ploc =
            this.locationList.filter(
                p => p.paymentLocationGUID === this.paymentLocationGUID
            )[0];

        return ploc ? ploc.description : '';
    }
}
