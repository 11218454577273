/* tslint:disable:triple-equals */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DomainContentItem } from '../../models/domaincontentitems';
import { IDomainInfo } from '../../models/domaininfo';
import { HelpItem } from '../../models/helpitem';
import { ComponentService } from '../../services/component/component.service';

@Component({
    selector: 'faq',
    template: require('./faq.component.html'),
    styles: [require('./faq.component.css')]
})
export class FaqComponent implements OnInit, OnDestroy {
    domainInfo: IDomainInfo;
    helpItems: HelpItem[] = [];
    FAQTitle: string;
    parentContactUsItems: DomainContentItem[] = [];
    contactUsItems: DomainContentItem[] = [];

    facebookImage: string;
    linkedInImage: string;
    twitterImage: string;
    youTubeImage: string;
    adLinkUrl: string;
    adLinkText: string;
    adImageUrl: string;
    adText: string;
    adTitle: string;

    private ngUnsubscribe: Subject<any> = new Subject();

    constructor(private componentService: ComponentService, private parentRouter: Router) {
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit() {
        this.componentService.scrollPageToTop();
        this.componentService.contentService.content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((content: any) => {
                if (content != null) {
                    this.FAQTitle = this.componentService.contentService.tryGetContentItem(content, 'help', 'pageText', 'faqTitle').text;
                    this.fillFAQ(content.help.faq);
                    this.buildContactUs(content.help);

                    this.facebookImage = this.componentService.contentService.tryGetContentItem(
                        content, 'help', 'image', 'helpFacebookImageUrl').text;

                    this.linkedInImage = this.componentService.contentService.tryGetContentItem(
                        content, 'help', 'image', 'helpLinkedInImageUrl').text;

                    this.twitterImage = this.componentService.contentService.tryGetContentItem(
                        content, 'help', 'image', 'helpTwitterImageUrl').text;

                    this.youTubeImage = this.componentService.contentService.tryGetContentItem(
                        content, 'help', 'image', 'helpYouTubeImageUrl').text;

                    this.adTitle = this.componentService.contentService.tryGetContentItem(content, 'ad', 'faq', 'adTitle').text;
                    this.adText = this.componentService.contentService.tryGetContentItem(content, 'ad', 'faq', 'adText').text;
                    this.adImageUrl = this.componentService.contentService.tryGetContentItem(content, 'ad', 'image', 'faqAdImageUrl').text;
                    this.adLinkText = this.componentService.contentService.tryGetContentItem(content, 'ad', 'faq', 'adLinkText').text;
                    this.adLinkUrl = this.componentService.contentService.tryGetContentItem(content, 'ad', 'faq', 'adLinkUrl').text;
                }
            });

        this.componentService.domainService.domainInfo$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(domainInfo => {
            this.domainInfo = domainInfo;
        });
    }

    buildContactUs(helpCategory: any): void {
        this.parentContactUsItems = [];
        this.contactUsItems = [];

        //  these are the parent items
        // tslint:disable-next-line: forin
        for (const key in helpCategory.contactUs) {
            this.parentContactUsItems.push(helpCategory.contactUs[key]);
        }
        this.parentContactUsItems = this.parentContactUsItems.sort(
            function(a: DomainContentItem, b: DomainContentItem) { return a.sequence - b.sequence; });
        this.contactUsItems = this.createChildContactUsItemList(helpCategory);
    }

    fillFAQ(faq: any) {
        this.helpItems = [];
        // tslint:disable-next-line: forin
        for (const i in faq) {
            const helpItem = { title: faq[i].title, body: faq[i].text, sequence: faq[i].sequence, hidden: true };
            this.helpItems = this.helpItems.concat(helpItem);
        }
        this.helpItems = this.helpItems.sort(function(a: HelpItem, b: HelpItem) { return a.sequence - b.sequence; });
    }

    /**
     * Take the 3 subcategories of JSON objects and turn them into a DomainContentItem list
     *
     * @param {*} contentItemCategory
     * @returns {DomainContentItem[]}
     * @memberof FaqComponent
     */
    createChildContactUsItemList(contentItemCategory: any): DomainContentItem[] {
        const itemList: DomainContentItem[] = [];
        // tslint:disable-next-line: forin
        for (const key in contentItemCategory.contactUsAddress) {
            const contact: DomainContentItem = contentItemCategory.contactUsAddress[key];
            this.formatContent(contact);
            itemList.push(contact);
        }

        // tslint:disable-next-line: forin
        for (const key in contentItemCategory.contactUsOther) {
            const contact: DomainContentItem = contentItemCategory.contactUsOther[key];
            this.formatContent(contact);
            itemList.push(contact);
        }

        // tslint:disable-next-line: forin
        for (const key in contentItemCategory.contactUsInternet) {
            const contact: DomainContentItem = contentItemCategory.contactUsInternet[key];
            this.formatContent(contact);
            itemList.push(contact);
        }

        return itemList;
    }

    /**
     * Replace empty values with non-breaking space chars and format certain data properly
     *
     * @param {DomainContentItem} item
     * @returns
     * @memberof FaqComponent
     */
    formatContent(item: DomainContentItem) {
        if (item == null) {
            return;
        }
        if (item.subCategory === 'contactUsInternet') {
            if (item.title != null) {
                if (item.title.toLowerCase().includes('email') && !item.text.includes('mailto')) {
                    item.text = '<a class="site-color" href="mailto:' + item.text + '">' + item.text + '</a>';
                } else if (!item.text.toLowerCase().includes('href') && !item.text.includes('mailto')) {
                    item.text = '<a class="site-color" href="http://' + item.text + '" target="_blank">' + item.text + '</a>';
                }
            }
        }

        if (item.title == '' && item.text == '') {
            item.title = '&nbsp;';
            item.text = '&nbsp;';
        }
    }

    getContactUsItemsByParent(contactItem: DomainContentItem): DomainContentItem[] {
        const parentId: number = contactItem.parentID;

        return this.contactUsItems.filter(
            x => x.parentID == parentId).sort(function(a: DomainContentItem, b: DomainContentItem) { return a.sequence - b.sequence; });
    }

    showFaq(sequence: number): void {
        const item = this.helpItems.find(x => x.sequence == sequence);
        item.hidden = !item.hidden;
    }

    isHidden(sequence: number): boolean {
        return this.helpItems.find(x => x.sequence == sequence).hidden;
    }

    isVisible(flag: string): boolean {
        if (this.domainInfo != null) {
            if (this.domainInfo[flag] != null && this.domainInfo[flag].length > 0) {
                return true;
            }
        }
        return false;
    }

    getLink(flag: string): string {
        if (this.domainInfo != null) {
            if (this.domainInfo[flag] != null && this.domainInfo[flag].length > 0) {
                return this.domainInfo[flag];
            }
        }
        return '';
    }

    leftColumnClasses(item: DomainContentItem): string {
        if (item.subCategory === 'contactUsInternet') {
            return item.title != '' ? 'col-xs-3' : 'col-xs-12';
        } else {
            return item.title != '' ? 'col-xs-6' : 'col-xs-12';
        }
    }

    leftColumnContent(item: DomainContentItem): string {
        return item.title !== '' ? item.title : item.text;
    }

    rightColumnClasses(item: DomainContentItem): string {
        return item.subCategory === 'contactUsInternet' ? 'contact-info-links site-color' : '';
    }

    rightColumnContent(item: DomainContentItem): string {
        return item.title !== '' ? item.text : '&nbsp;';
    }
}
