import { Injectable, NgZone } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { ComponentService } from '../component/component.service';
import { DomainInfoService } from '../domainInfo/domaininfo.service';
import { LoggingLevel, LoggingService } from '../logging/logging.service';

@Injectable()
export class AccountPaymentGuard implements CanActivate {
    constructor(private router: Router,
                private componentService: ComponentService,
                private loggingService: LoggingService,
                private domainService: DomainInfoService,
                private ngZone: NgZone) {
    }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
        Promise<boolean> {
        this.loggingService.log(`Navigating to ${document.location.href} from ${this.router.url}`, LoggingLevel.debug);

        const ssoMode: boolean = this.componentService.storageService.retrieve('mode') === 'sso';
        // had to load through domainService because using storageService is not timely enough from a direct link
        const domainInfo = await this.domainService.getDomainInfo();
        if (domainInfo.requireDocumentCode) {
            // redirect to the right page
            if (ssoMode) {
                this.ngZone.run(() => this.router.navigateByUrl('/mempayment'));
            } else {
                this.ngZone.run(() => this.router.navigateByUrl('/default'));
            }
            return false;
        }
        return true;
    }
}
