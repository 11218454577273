import { Component, OnInit } from '@angular/core';
import { setTheme } from 'ngx-bootstrap/utils';
import { ComponentService } from './services/component/component.service';

@Component({
    selector: 'my-app',
    template: require('./app.component.html'),
    styles: [require('./app.component.css')]
})
export class AppComponent implements OnInit {
    constructor(private componentService: ComponentService) {
    }

    ngOnInit(): void {
        this.instantiateServices();
        setTheme('bs3');
    }

    instantiateServices() {
        // don't need to do anything with the responses in this component, just need to load them into cache
        // (these services' get* methods are doing both retrieval and storage)
        this.componentService.storageService.getDomain();
        this.getDomainInfo();
        this.getContentItems();
    }

    getDomainInfo(): void {
        this.componentService.domainService.getDomainInfo()
            .then(() => {
                },
                error => {
                    this.componentService.loggingService.handleError(error as any);
            });
    }

    getStates(): void {
        this.componentService.domainService.getStates()
            .then(() => {
                },
                error => {
                    this.componentService.loggingService.handleError(error as any);
            });
    }

    getContentItems(): void {
        this.componentService.contentService.getContentItems()
            .then(() => {
                },
                error => {
                    this.componentService.loggingService.handleError(error as any);
            });
    }
}
