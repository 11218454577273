/* tslint:disable:triple-equals */
import { CurrencyPipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConsumerAccount } from '../../../models/consumeraccount';
import { IDomainInfo } from '../../../models/domaininfo';
import { IPaymentPlanDetails } from '../../../models/paymentplandetails';
import { ComponentService } from '../../../services/component/component.service';
import { ConsumerService } from '../../../services/consumer/consumer.service';
import { PaymentPlanService } from '../../../services/paymentplan/paymentplan.service';

@Component({
    selector: 'payment-plans-widget',
    template: require('./paymentplanswidget.component.html'),
    styles: [require('./paymentplanswidget.component.css')]
})
export class PaymentPlansWidgetComponent implements OnInit, OnDestroy {
    paymentPlansHeadlineText: string;
    paymentPlansSetupLinkText: string;
    paymentPlansManageLinkText: string;
    paymentPlanDetailTemplate: string;
    domainInfo: IDomainInfo;
    token: string;
    consumerAccount: ConsumerAccount;
    offPlanBalance = 0;

    @Input() paymentPlans: IPaymentPlanDetails[] = [];

    private ngUnsubscribe: Subject<any> = new Subject();

    constructor(
        private componentService: ComponentService,
        private currencyPipe: CurrencyPipe,
        private consumerService: ConsumerService,
        private paymentPlanService: PaymentPlanService
    ) { }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit() {
        // Called after the constructor, initializing input properties, and the first call to ngOnChanges.
        // Add 'implements OnInit' to the class.
        this.componentService.contentService.content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((content: any) => {
                this.paymentPlansHeadlineText = this.componentService.contentService.tryGetContentItem(content, 'home', 'payment', 'homePagePaymentPlansHeadline').text;
                this.paymentPlansSetupLinkText = this.componentService.contentService.tryGetContentItem(content, 'home', 'payment', 'homePagePaymentPlansLink').text;
                this.paymentPlansManageLinkText = this.componentService.contentService.tryGetContentItem(content, 'home', 'payment', 'homePagePaymentPlanManageLink').text;
                this.paymentPlanDetailTemplate = this.componentService.contentService.tryGetContentItem(content, 'home', 'payment', 'homePagePaymentPlanDetailTemplate').text;
            });

        this.componentService.domainService.domainInfo$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(domainInfo => {
                this.domainInfo = domainInfo;
            });

        this.token = this.componentService.storageService.retrieve('token');
        this.consumerService.getConsumer(this.token).then(consumer => {
            this.consumerService.getConsumerAccount().then(account => {
                this.consumerAccount = account;
                this.getBalances();
            });
        });
    }


    /**
     * Get the non-plan balance
     *
     * @memberof PaymentPlansWidgetComponent
     */
    getBalances(): void {
        this.paymentPlanService.getBalanceDetails(this.consumerAccount.customerAccountID).then(balances => {
            if (balances != null) {
                this.offPlanBalance = balances.filter(
                    x => x.paymentPlanGUID == this.componentService.NULL_GUID && x.isPaymentPlanEligible && x.isActive)
                    .map(c => c.balance)
                    .reduce((sum, current) => sum + current, 0);
            }
        });
    }

    private getPaymentPlanDescriptionText(plan: IPaymentPlanDetails) {
        const endDateMoment: moment.Moment = moment(plan.lastDate);
        const expectedPaymentMoment: moment.Moment = moment(plan.expectedPaymentDate);

        return this.paymentPlanDetailTemplate.replace('!SEQID!', plan.sequentialIdentifier.toString())
            .replace('!ENDDATE!', endDateMoment.format('MMMM YYYY'))
            .replace('!EXPECTEDPAYMENTDATE!', expectedPaymentMoment.format('MM/DD/YYYY'))
            .replace('!EXPECTEDPAYMENT!', this.currencyPipe.transform(plan.expectedPaymentAmount, 'USD', 'symbol'))
            .replace('!BALANCE!', this.currencyPipe.transform(plan.balance, 'USD', 'symbol'));
    }

    private isLinkDisabled(): boolean {
        return !!this.domainInfo && !this.domainInfo.enableWallet && !this.domainInfo.paymentPlanOnly
            || (this.paymentPlans.length == 0 ? this.offPlanBalance <= 0 : false);
    }
}
