import { CurrencyPipe } from '@angular/common';
import { Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Address } from '../../../models/address';
import { IConsumer } from '../../../models/consumer';
import { ConsumerAccount } from '../../../models/consumeraccount';
import { CreditCard } from '../../../models/creditcard';
import { PaymentMethod, PaymentMethodType } from '../../../models/paymentmethod';
import { PaymentSelectionModel, PaymentSelectionStateModel } from '../../../models/paymentselectionmodel';
import { ComponentService } from '../../../services/component/component.service';
import { ConsumerService } from '../../../services/consumer/consumer.service';
import { PaymentSelectionStateService } from '../../../services/paymentselection/paymentselection.state.service';
import { BasePaymentMethod } from '../../Base/BasePaymentMethod/basepaymentmethod';
import { PaymentMethodModalComponent } from '../../Controls/PaymentMethodModal/paymentmethodmodal.component';
import { ConfirmationModalComponent } from '../ConfirmationModal/confirmationmodal.component';

@Component({
    selector: 'payment-method-manager',
    template: require('./paymentmethodmanager.component.html'),
    styles: [require('./paymentmethodmanager.component.css')],
})
export class PaymentMethodManagerComponent extends BasePaymentMethod implements OnInit, OnDestroy {
    private ngUnsubscribe: Subject<any> = new Subject();
    consumer: IConsumer;
    consumerAccount: ConsumerAccount;

    @Input() selectedPaymentMethod: PaymentMethod;
    @Input() isOneTimePayment: boolean;
    @Input() paymentMethods: PaymentMethod[] = [];
    @Input() sourceUrl: string;
    @Input() redirectToWalletOnNewPaymentMethod = false;
    @Input() paymentMethodLogoBackgroundSize = 'cover';

    @Output() public paymentMethodChanged: EventEmitter<string> = new EventEmitter<string>();
    @Output() public walletUpdated: EventEmitter<string> = new EventEmitter<string>();

    modalRef: BsModalRef;

    // Content Items
    changePaymentMethodText: string;
    expirationDateText: string;
    expirationDateFullText: string;
    addNewPaymentMethodText: string;
    storeWalletErrorText: string;
    updatedWalletText: string;
    genericWalletErrorText: string;
    deleteWalletErrorText: string;
    deletePaymentMethodModalContent: string;
    deletePaymentMethodModalTitle: string;
    paymentMaxCreditCardAmountWarningText: string;

    confirmationModalDisplay: string;
    walletArrowRotateDegrees = 0;
    walletErrorText = '';
    dropdownSize: string;
    editMode = false;
    backButtonText: string;
    continueButtonText: string;
    paymentMethodValid = false;
    updatedPaymentMethodGUID: string;
    paymentMethodToUpdate: PaymentMethod;
    loading = false;
    walletExpanded = false;
    walletError = false;
    genericErrorExists = false;
    errorWalletGUID: string;

    @ViewChild('confirmation', { static: false }) confirmationModal: ConfirmationModalComponent;

    public config = {
        keyboard: false, // allow them to press Escape key
        backdrop: true, // dim the page with a backdrop
        ignoreBackdropClick: true // Disallow them from clicking the backdrop to dismiss
    };

    modelToPassToPayment: PaymentSelectionModel = {
        amountToPay: 0,
        isOneTimePayment: true,
        enableWallet: true,
        defaultUpdateAddress: false,
        contentItemCategory: 'payment',
        contentItemSubCategory: 'loggedInPayment'
    } as PaymentSelectionModel;

    get isAutoPayMaxPaymentAmountGreaterThanMaxCCPaymentAmount(): boolean {
        return this.componentService.isAutoPayMaxPaymentAmountGreaterThanMaxCCPaymentAmount;
    }

    get maxCCPaymentAmt(): number {
        return this.componentService.maxCCPaymentAmount;
    }

    constructor(
        private modalService: BsModalService,
        public stateService: PaymentSelectionStateService,
        private componentService: ComponentService,
        private consumerService: ConsumerService,
        private parentRouter: Router,
        private ngZone: NgZone,
        private currencyPipe: CurrencyPipe
    ) {
        super(componentService);
    }

    ngOnDestroy(): void {
        this.stateService.shouldSetDefaultMethod = true;
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit(): void {
        this.componentService.contentService.content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((content: any) => {
                this.changePaymentMethodText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'oneTimePayment', 'paymentChangePaymentMethodText').text;
                this.expirationDateText = this.componentService.contentService.tryGetContentItem(content, 'profile', 'wallet', 'profileWalletExpirationDateText').text;
                this.expirationDateFullText = this.componentService.contentService.tryGetContentItem(content, 'profile', 'wallet', 'profileWalletExpirationDateFullText').text;
                this.addNewPaymentMethodText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'loggedInPayment', 'paymentAddNewPaymentMethodText').text;
                this.storeWalletErrorText = this.componentService.contentService.tryGetContentItem(content, 'error', 'wallet', 'profileWalletStoreErrorText').text;
                this.updatedWalletText = this.componentService.contentService.tryGetContentItem(content, 'profile', 'wallet', 'profileUpdatedWalletText').text;
                this.genericWalletErrorText = this.componentService.contentService.tryGetContentItem(content, 'error', 'wallet', 'profileWalletErrorText').text;
                this.deleteWalletErrorText = this.componentService.contentService.tryGetContentItem(content, 'error', 'wallet', 'profileDeleteWalletErrorText').text;
                this.deletePaymentMethodModalContent = this.componentService.contentService.tryGetContentItem(content, 'profile', 'wallet', 'profileWalletDeleteMethodModalContent').text;
                this.deletePaymentMethodModalTitle = this.componentService.contentService.tryGetContentItem(content, 'profile', 'wallet', 'profileWalletDeleteMethodModalTitle').text;
                this.paymentMaxCreditCardAmountWarningText = this.componentService.contentService.tryGetContentItem(content, 'error', 'paymentSelection', 'paymentMaxCreditCardAmountWarningText').text;
            });

        this.stateService.updatePaymentMethod$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(update => {
            if (update) {
                this.submitNewPaymentMethod();
            }
        });

        this.stateService.exitPaymentMethodModal$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(exit => {
            if (exit) {
                if (this.paymentMethodToUpdate != null) {
                    this.changePaymentMethod(this.paymentMethodToUpdate.tokenGUID);
                }
                this.closePaymentMethodModal();
            }
        });
    }

    toggleWallet() {
        this.walletError = false;
        this.genericErrorExists = false;
        this.walletExpanded = !this.walletExpanded;
        this.walletArrowRotateDegrees = Math.abs(this.walletArrowRotateDegrees - 180);
    }

    goToWallet(): void {
        this.componentService.storageService.store('returnurl', this.sourceUrl);
        this.ngZone.run(() => this.parentRouter.navigateByUrl('wallet')).then();
    }

    changePaymentMethod(walletGUID: string): void {
        if (walletGUID === 'newPaymentMethod' && this.redirectToWalletOnNewPaymentMethod) {
            this.goToWallet();
        } else {
            this.walletError = false;
            this.genericErrorExists = false;
            this.selectedPaymentMethod = this.paymentMethods.find(p => p.tokenGUID === walletGUID);
            this.paymentMethodChanged.emit(walletGUID);

            if (this.selectedPaymentMethod.paymentMethodType === 0) {
                this.toggleWallet();
            }

            if (this.selectedPaymentMethod.paymentMethodType === 1) {
                this.paymentMaxCreditCardAmountWarningText =
                    this.paymentMaxCreditCardAmountWarningText.replace(
                        '{0}',
                        this.currencyPipe.transform(this.maxCCPaymentAmt, 'USD', 'symbol', '1.2-2')
                    );
            }
        }
    }

    editPaymentMethod(methodToUpdate: PaymentMethod): void {
        this.changePaymentMethod(methodToUpdate.tokenGUID);
        this.editMode = true;
        this.updatedPaymentMethodGUID = '';
        this.stateService.shouldSetDefaultMethod = false;
        this.openPaymentMethodModal(methodToUpdate);
    }

    cancelPaymentMethod(method: PaymentMethod): void {
        this.consumerService.cancelWalletPaymentMethod(method.merchantGUID, method.tokenGUID)
            .then(walletCancelled => {
                // Remove payment method from component
                const index: number = this.paymentMethods.indexOf(method);
                if (index > -1) {
                    this.paymentMethods.splice(index, 1);
                }
                // Select primary payment method, should always at least have the OtherPaymentMethod in the array
                if (this.paymentMethods && this.paymentMethods.length > 1) {
                    const primary: PaymentMethod = this.paymentMethods.find(p => p.primary);
                    if (primary != null) {
                        this.changePaymentMethod(primary.tokenGUID);
                    } else {
                        this.changePaymentMethod(this.paymentMethods[0].tokenGUID);
                    }
                } else {
                    // Select OtherPaymentMethod
                    this.changePaymentMethod(this.paymentMethods[0].tokenGUID);
                }
            })
            .catch(err => {
                this.walletErrorText = this.genericWalletErrorText;
                this.walletError = true;
                setTimeout(() => this.walletError = false, 5000);
                this.errorWalletGUID = method.tokenGUID;
            });
    }

    openPaymentMethodModal(methodToUpdate: PaymentMethod): void {
        this.paymentMethodToUpdate = methodToUpdate;
        this.modalRef = this.modalService.show(PaymentMethodModalComponent, this.config);
        this.modalRef.content.paymentSelectionCmp.paymentMethodCmp.modalOpen = true;
        this.modalRef.content.paymentSelectionCmp.setPaymentMethodToUpdate(methodToUpdate);
        this.selectedPaymentMethod = methodToUpdate;
    }

    closePaymentMethodModal(): void {
        if (this.modalRef) {
            this.modalRef.hide();
        }
    }

    deleteMethodWithConfirmation(method: PaymentMethod) {
        this.walletError = false;
        this.genericErrorExists = false;
        // Don't allow delete if the wallet is in use for PaymentPlan, Autopay, or Recurring
        if (method.paymentMethodInUse) {
            this.walletErrorText = this.deleteWalletErrorText;
            this.walletError = true;
            setTimeout(() => this.walletError = false, 5000);
            this.errorWalletGUID = method.tokenGUID;
            return;
        }
        this.confirmationModalDisplay = this.deletePaymentMethodModalContent.replace('!PAYMENTMETHOD!', method.description);
        this.confirmationModal.openModal(method);
    }

    walletErrorExists(walletGUID: string): boolean {
        return this.walletError && this.errorWalletGUID === walletGUID;
    }

    /**
     * Retrieves and inits the consumer, consumer account, and wallet payment methods
     *
     * @public
     *
     * @memberof MySecureWalletComponent
     */
    public retrieveWallet(): void {
        this.startLoading();
        this.componentService.storageService.clear('wallet');
        this.genericErrorExists = false;
        this.consumerService.getConsumer().then(consumer => {
            this.consumer = consumer;
            this.consumerService.getConsumerAccount().then(consumerAccount => {
                if (!!consumerAccount) {
                    this.consumerAccount = consumerAccount;
                    this.consumerService.getWalletPaymentMethods().then(wallet => {
                        if (!!wallet) {
                            this.paymentMethods = wallet.filter(x => x.tokenGUID !== 'newPaymentMethod');
                            this.changePaymentMethod(this.paymentMethodToUpdate.tokenGUID);
                            this.walletUpdated.emit(this.selectedPaymentMethod.tokenGUID);
                            this.doneLoading();
                        }
                    });
                }
            });
        }).catch(err => {
            this.walletErrorText = this.genericWalletErrorText;
            this.genericErrorExists = true;
            this.doneLoading();
        });
    }

    private paymentMethodValidation(): void {
        const paymentSelection = this.modalRef.content.paymentSelectionCmp;
        paymentSelection.clearErrorState();
        this.paymentMethodValid = paymentSelection.step2Valid = true;
        this.paymentMethodValid = paymentSelection.isValid();
    }

    /**
     * Submits a completed PaymentMethodDisplayComponent to store a new payment method into the ConsumerAccount wallet
     *
     * @public
     *
     * @memberof MySecureWalletComponent
     */
    submitNewPaymentMethod(): void {
        if (this.modalRef == null || this.modalRef.content == null) {
            return;
        }

        this.paymentMethodValidation();
        this.modalRef.content.startLoading();
        const paymentSelection = this.modalRef.content.paymentSelectionCmp;

        if (this.paymentMethodValid) {

            const paymentMethod: PaymentSelectionStateModel = paymentSelection.getPaymentSelectionState();

            let credit: CreditCard;
            if (paymentMethod.selectedPaymentMethod.paymentMethodType === PaymentMethodType.credit) {
                credit = new CreditCard();
                credit.CardNumber = paymentMethod.creditCardNumber.replace(/-/g, '');
                credit.ExpirationDate = new Date(paymentMethod.creditCardExpirationMonth + '/01/' + paymentMethod.creditCardExpirationYear);
                credit.CVV = paymentMethod.creditCardCvv;
            }

            let address: Address = new Address();
            address = {
                firstName: paymentMethod.firstName,
                lastName: paymentMethod.lastName,
                address1: paymentMethod.address1,
                address2: paymentMethod.address2,
                city: paymentMethod.city,
                stateRegion: paymentMethod.state,
                postalCode: paymentMethod.postalCode,
                country: paymentMethod.country
            };

            // Only send card number along with UPay methods
            if (this.paymentMethodToUpdate.paymentProcessor.toLowerCase() !== 'upay') {
                credit.CardNumber = null;
            }
            this.consumerService.updateWalletPaymentMethod(
                this.paymentMethodToUpdate.tokenGUID, this.paymentMethodToUpdate.merchantGUID,
                paymentMethod.selectedPaymentMethod.description, address, credit).then(response => {
                if (response.success) {
                    this.updatedPaymentMethodGUID = this.paymentMethodToUpdate.tokenGUID;
                    this.retrieveWallet();
                    this.closePaymentMethodModal();
                } else {
                    // Something went wrong API side
                    paymentSelection.step2Valid = false;
                    paymentSelection.stepValidationMessage = this.storeWalletErrorText;
                    this.modalRef.content.doneLoading();
                }
            }).catch(err => {
                // Something went wrong API side, add error for that
                paymentSelection.step2Valid = false;
                paymentSelection.stepValidationMessage = this.storeWalletErrorText;
                this.modalRef.content.doneLoading();
            });
        } else {
            this.modalRef.content.doneLoading();
        }
    }

    autoPayMaxPaymentAmountGreaterThanMaxCCPaymentAmount(): boolean {
        return this.isAutoPayMaxPaymentAmountGreaterThanMaxCCPaymentAmount;
    }

    autoPayMaxPaymentAmount(): number {
        return this.maxCCPaymentAmt;
    }

    startLoading(): void {
        this.loading = true;
    }

    doneLoading(): void {
        this.loading = false;
    }

    isLoading(): boolean {
        return this.loading;
    }
}
