import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IDomainInfo } from '../../models/domainInfo';
import { ComponentService } from '../../services/component/component.service';
import { ConsumerStorageService } from '../../services/indexedDb/consumer-storage.service';

@Component({
    selector: 'autopayenrollmentconfirmation',
    template: require('./autopayenrollmentconfirmation.component.html'),
    styles: [require('./autopayenrollmentconfirmation.component.css')]
})
export class AutopayEnrollmentConfirmationComponent implements OnInit, OnDestroy {
    constructor(
        private componentService: ComponentService,
        private consumerStorageService: ConsumerStorageService
    ) { }

    private ngUnsubscribe: Subject<any> = new Subject();

    userIsLoggedIn = false;
    domainInfo: IDomainInfo;
    loading = false;
    loadingBalance = false;
    consumerToken = '';
    isAgentAssistedPayment = false;

    confirmationHeader: string;
    confirmationSubHeader: string;
    agentAssistedEditHeader: string;
    agentAssistedEditSubheader: string;

    confirmationText: string;

    ngOnInit() {
        this.componentService.scrollPageToTop();

        this.componentService.contentService.content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((content: any) => {
                this.confirmationHeader = this.componentService.contentService.tryGetContentItem(content, 'autopay', 'pageText', 'autoPayDevicelessConfirmationHeader').text;
                this.confirmationSubHeader = this.componentService.contentService.tryGetContentItem(content, 'autopay', 'pageText', 'autoPayDevicelessConfirmationSubheader').text;
                this.agentAssistedEditHeader = this.componentService.contentService.tryGetContentItem(content, 'autopay', 'pageText', 'autoPayDevicelessEditConfirmationHeader').text;
                this.agentAssistedEditSubheader = this.componentService.contentService.tryGetContentItem(content, 'autopay', 'pageText', 'autoPayDevicelessEditConfirmationSubheader').text;
            });

        this.componentService.domainService.domainInfo$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(domainInfo => {
            this.domainInfo = domainInfo;
            if (this.domainInfo != null) {
                this.initializeConfirmationPage();
            }
        });
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();

        // Called once, before the instance is destroyed.
        this.componentService.storageService.clear('autopayenrollmentconfirmation');
    }

    initializeConfirmationPage() {
        if (this.componentService.storageService.exists('agentassistedrole')) {
            this.isAgentAssistedPayment = true;
            this.getConsumer();
        }


    }

    private async getConsumer(): Promise<void> {
        if (this.componentService.userIsLoggedIn()) {
            const consumer = await this.consumerStorageService.getConsumerFromStorage();

            // If editing existing autopay
            if (this.componentService.storageService.retrieve('agentassistedrole') === 'autopayExisting') {
                const message = this.componentService.storageService.retrieve('confirmationmessagetype') === 'email' ?
                    consumer.emailAddress :
                    consumer.phone;
                this.confirmationText = this.agentAssistedEditSubheader.replace('!COMMPREF!', message + '.');
                this.confirmationHeader = this.agentAssistedEditHeader;

            // If creating new autopay
            } else {
                const message = consumer.confirmationMessageType === 'email' ?
                    consumer.emailAddress :
                    consumer.confirmationMessageType === 'email' ? consumer.phone : ' your preferred destination.';
                this.confirmationText = this.confirmationSubHeader.replace('!email or phone number!', message + '.');
            }
        } else {
            this.confirmationText = this.confirmationSubHeader.replace('!email or phone number!', ' your preferred destination.');
        }
}
    }
