/* tslint:disable:triple-equals */
import { Component, EventEmitter, NgZone, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AgreementStatus, IAutopayAgreement } from '../../models/autopayagreement';
import { IDomainInfo } from '../../models/domaininfo';
import { ErrorMessage } from '../../models/errormessage';
import { ComponentService } from '../../services/component/component.service';
import { ConnectMessageService } from '../../services/connectmessage/connectmessage.service';
import { ConsumerService } from '../../services/consumer/consumer.service';

@Component({
    selector: 'autopay',
    template: require('./autopay.component.html'),
    styles: [require('./autopay.component.css')]
})
export class AutopayComponent implements OnInit, OnDestroy {
    agreements: IAutopayAgreement[];
    autopayTitle: string;
    autopayAgreementTitle: string;
    autopaySubheader: string;
    autopayAgreementSubheader: string;
    autopayBody: string;
    autopayEnrollmentLink: string;
    autopayImage: string;
    autopayContentItems: string[] = [];
    manageAutopayText: string;
    canEnrollLocation = false;
    domainInfo: IDomainInfo;
    errorMessage: ErrorMessage;
    homeRouteLocation = '/';

    autopayImagePlaceholder: string;
    triedAutopayImageConnectMessage = false;

    private ngUnsubscribe: Subject<any> = new Subject();

    agreementsLoaded = false;
    locationsLoaded = false;

    // Used as param for communication-preference-selection control
    hasAgreements = false;

    constructor(
        private componentService: ComponentService,
        private consumerService: ConsumerService,
        private parentRouter: Router,
        private connectMessageService: ConnectMessageService,
        private ngZone: NgZone
    ) { }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit() {
        this.componentService.scrollPageToTop();
        this.componentService.contentService.content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((content: any) => {
                if (content != null) {
                    this.fillAutopayContent(content.autopay.featureText);
                    this.autopayTitle = this.componentService.contentService.tryGetContentItem(content, 'autopay', 'pageText', 'autopayTitle').text;
                    this.autopayAgreementTitle = this.componentService.contentService.tryGetContentItem(content, 'autopay', 'pageText', 'autopayAgreementTitle').text;
                    this.autopaySubheader = this.componentService.contentService.tryGetContentItem(content, 'autopay', 'pageText', 'autopaySubheader').text;
                    this.autopayAgreementSubheader = this.componentService.contentService.tryGetContentItem(content, 'autopay', 'pageText', 'autopayAgreementSubheader').text;
                    this.autopayBody = this.componentService.contentService.tryGetContentItem(content, 'autopay', 'pageText', 'autopayBodyText').text;
                    this.autopayEnrollmentLink = this.componentService.contentService.tryGetContentItem(content, 'autopay', 'pageText', 'autopayEnrollmentLink').text;
                    this.autopayImagePlaceholder = this.componentService.contentService.tryGetContentItem(content, 'autopay', 'image', 'autopayBackgroundImage').text;
                    this.manageAutopayText = this.componentService.contentService.tryGetContentItem(content, 'autopay', 'pageText', 'autopayManageAutopayText').text;
                }

                if (this.triedAutopayImageConnectMessage && !this.autopayImage) {
                    this.autopayImage = this.autopayImagePlaceholder;
                }
            });

        this.connectMessageService.getConnectMessageImageUrl('autopaybackground').then(imageUrl => {
            this.triedAutopayImageConnectMessage = true;
            if (imageUrl) {
                this.autopayImage = imageUrl;
            } else {
                this.autopayImage = this.autopayImagePlaceholder;
            }
        });

        this.componentService.domainService.domainInfo$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(domainInfo => {
                this.domainInfo = domainInfo;
        });
        this.retrieveAutopayAgreements();
        this.setNewEnrollmentButton();
    }

    /**
     * This is a callback all the way down through the autopay-agreement-list Component down to the autopay-agreement component.
     *
     * When an autopay agreement is cancelled the event will get emitted all the way back to here where the autopay component
     * has to recheck the New Enrollment button to see if we can now enroll into a new agreement.
     *
     * @param event
     */
    optedOutAgreement(event: boolean): void {
        this.setNewEnrollmentButton();
    }

    retrieveAutopayAgreements(): void {
        this.agreementsLoaded = false;
        this.consumerService.getAutopayAgreements()
            .then(agreements => {
                this.agreements = agreements;
                this.agreementsLoaded = true;

                // Used as param for communication-preference-selection control
                this.hasAgreements = (agreements && agreements.length > 0);
            });
    }

    fillAutopayContent(contentItems: any) {
        this.autopayContentItems = [];
        // tslint:disable-next-line: forin
        for (const i in contentItems) {
            this.autopayContentItems = this.autopayContentItems.concat(contentItems[i].text);
        }
    }

    goToNewEnrollment(): void {
        this.ngZone.run(() => this.parentRouter.navigateByUrl('/autopayenrollment')).then();
    }

    showBackgroundImage(): boolean {
        return !this.isLoading() && (!this.agreements || this.agreements.length == 0);
    }

    isLoading(): boolean {
        return !(this.agreementsLoaded && this.locationsLoaded);
    }

    /**
     * This will get the autopay locations that the user can enroll into. If there are any locations
     * then the button is enabled and the user is allowed to enroll into one of those locations.
     */
    setNewEnrollmentButton(): void {
        this.consumerService.getAutopayEnrollmentLocations().then(locationList => {
            this.canEnrollLocation = locationList.length > 0;
            this.locationsLoaded = true;
        });
    }

    showErrorMessage(): boolean {
        return !this.isLoading() && !!this.agreements
                && this.agreements.filter(agreement => agreement.status == AgreementStatus.active).length == 0
                && !this.domainInfo.enableWallet;
    }
}
