export class Activity {
    activityType: string;
    details: string;
    activityDate: Date;
    link: string;
    iconLibrary: string;
    activityIcon: string;
    activityDownload: boolean;
    // activityDownloadUrl: string;

    constructor() {
        this.activityDate = null;
        this.iconLibrary = 'glyphicon';
        this.activityIcon = '';
        this.details = '';
        this.activityDownload = false;
        // this.activityDownloadUrl = '';
    }
}

export enum ActivityType {
    autoPay = 0,
    autoPayAgreementCancelled = 1,
    autoPayAgreementCreated = 2,
    autoPayPendingPaymentCancelled = 3,
    createConsumerAccount = 4,
    enrollment = 5,
    payment = 6,
    refund = 7,
    void = 8,
    deliveryPreference = 9,
    paymentPlan = 10
}

export const TEST_ACTIVITY: Activity = {
    activityDate: new Date('2017-05-05')
} as Activity;
