import { CurrencyPipe } from '@angular/common';
import { Component, EventEmitter, HostListener, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import moment from 'moment';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PaymentSelectionComponent } from '../../components/Controls/PaymentSelection/paymentselection.component';
import { PayableItemListComponent } from '../../components/PayableItemList/payableitemlist.component';
import { RecaptchaComponent } from '../../components/RecaptchaComponent/recaptcha.component';
import { ConsumerPayment } from '../../models/consumerpayment';
import { ICreateFutureDatePaymentResponse } from '../../models/createfuturedatepaymentresponse';
import { DetailPayment } from '../../models/detailpayment';
import { DocumentPayments } from '../../models/documentpayments';
import { IDomainInfo } from '../../models/domaininfo';
import { FutureDateAccountPaymentConfirmation } from '../../models/futuredateaccountpaymentconfirmation';
import { FutureDateAccountPaymentDetailRequest } from '../../models/futuredateaccountpaymentdetails';
import { FutureDatePaymentCreateAgreementRequest } from '../../models/futuredatepaymentcreateagreementrequest';
import { IMerchantProfile } from '../../models/imerchantprofile';
import { PayableEntry, PayableEntryType } from '../../models/PayableEntry';
import { PayableItem, PayableItemType } from '../../models/payableitem';
import { PayableStatement } from '../../models/payablestatement';
import { FloatingBucket } from '../../models/paymentbucket';
import { PaymentConfirmation } from '../../models/paymentconfirmation';
import { PaymentMethod, PaymentMethodType } from '../../models/paymentmethod';
import { IPaymentPlanBalance } from '../../models/paymentplanbalance';
import { PaymentSelectionModel, PaymentSelectionStateModel } from '../../models/paymentselectionmodel';
import { IPaymentValidationResponse } from '../../models/paymentvalidationresponse';
import { QuickPayEnrollment } from '../../models/quickpayenrollment';
import { BalanceDetailsPayment, RealtimePayment } from '../../models/realtimepayment';
import { ISubmitPaymentResponse } from '../../models/submitpaymentresponse';
import { DateFormatPipe } from '../../pipes/DateFormat/dateformat.pipe';
import { AgentAssistedService } from '../../services/agentassisted/agentassisted.service';
import { ComponentService } from '../../services/component/component.service';
import { ConsumerService } from '../../services/consumer/consumer.service';
import { FutureDateAccountPaymentService } from '../../services/futuredateaccountpayment/futuredateaccountpayment.service';
import { LoggingLevel } from '../../services/logging/logging.service';
import { LoginService } from '../../services/login/login.service';
import { PayableService } from '../../services/payable/payable.service';
import { PaymentService } from '../../services/payment/payment.service';
import { PaymentPlanService } from '../../services/paymentplan/paymentplan.service';
import { StorageLevel } from '../../services/storage/storage.service';
import { IConsumer } from './../../models/consumer';

@Component({
    selector: 'payment',
    template: require('./payment.component.html'),
    styles: [require('./payment.component.css')]
})
export class PaymentComponent implements OnInit, OnDestroy {
    constructor(
        private componentService: ComponentService,
        private agentAssistedService: AgentAssistedService,
        private parentRouter: Router,
        private consumerService: ConsumerService,
        private loginService: LoginService,
        private paymentService: PaymentService,
        private datePipe: DateFormatPipe,
        private currencyPipe: CurrencyPipe,
        private payableService: PayableService,
        private futureDateAccountPaymentService: FutureDateAccountPaymentService,
        private paymentPlanService: PaymentPlanService,
        private ngZone: NgZone
    ) { }

    get quickPayBalanceDetailsWarning(): string {
        if (this.isOneTimePayment
            && this.domainInfo
            && this.domainInfo.enableQuickPayBalanceDetails) {
            return this.oneTimeBalanceDetailsOffPlanSubHeading;
        }
        return '';
    }

    /**
     * If the form on step 3 is ready for validation. This will return
     * `false` until the user checks the checkbox at least once and
     * also has attempted the reCaptcha
     */
    get readyForStep3Validation(): boolean {
        return this.recaptchaAttempted;
    }

    get isFuturePaymentDateValid(): boolean {
        return this.componentService.isFuturePaymentDateValid;
    }

    get payableEntryOrItemBalances(): IPaymentPlanBalance[] {
        return this.componentService.payableEntryOrItemBalances;
    }

    private ngUnsubscribe: Subject<any> = new Subject();
    canMakePayment = true;
    noPayableItems = false;

    paymentSelectionState: PaymentSelectionStateModel = new PaymentSelectionStateModel();
    recaptchaAttempted = false;
    recaptchaErrorMessage = '';
    paymentScheduledDateError = '';
    captchaToken = '';
    recaptchaError = false;
    captchaRequired = false;

    @ViewChild('payableItemListCmp', { static: false }) payableItemListCmp: PayableItemListComponent;
    @ViewChild('paymentSelectionCmp', { static: false }) paymentSelectionCmp: PaymentSelectionComponent;
    @ViewChild('recaptchaCmp', { static: false }) recaptchaCmp: RecaptchaComponent;

    modelToPassToPayment: PaymentSelectionModel = new PaymentSelectionModel();
    loading = true;
    submission = false;
    hasSubmitted = false;
    domainInfo: IDomainInfo;
    merchantProfile: IMerchantProfile;
    currentStep = 1;
    finalStep = 3;
    statementsToPay: PayableStatement[];
    payableItemsToPay: PayableItem[];
    payableEntriesToPay: PayableEntry[];
    amountToPay = 0.00;
    todaysDate = new Date();
    paymentDate: string = moment(this.todaysDate).format('YYYY-MM-DDTHH:mm:ss');
    paymentDateAsDateType: Date;
    receiptEmail = '';
    showReceiptEmail = false;
    receiptEmailError = false;
    paymentAuthorization = false;
    step1Valid = true;
    step2Valid = true;
    step3Valid = true;
    resubmitPayment = true;
    stepValidationMessages: string[] = [];
    isWarningMessage = false;
    detailsOpen = false;
    stopDoubleclick = false;
    otherPaymentMethod: PaymentMethod;
    comment: string;
    showComments = true;
    enableFuturePayment = false;
    isAgentAssistedPayment = false;
    isOneTimePayment = false;
    previousPaymentWarning: string;

    impersonatorRoleAllowPayments = true;
    consumerToken = '';

    currentBalanceDisclaimer: string;
    paymentStep1Name: string;
    paymentStep1Header: string;
    paymentStep1SubHeading: string;
    oneTimeBalanceDetailsSubHeading: string;
    oneTimeBalanceDetailsOffPlanSubHeading: string;
    paymentStep2Name: string;
    paymentStep2Header: string;
    paymentStep2SubHeading: string;
    paymentStep3Name: string;
    paymentStep3Header: string;
    paymentStep3SubHeading: string;
    continueButtonText: string;
    backButtonText: string;
    makePaymentButtonText: string;
    payHeaderText: string;
    amountHeaderText: string;
    detailsText: string;
    clearSelectionsText: string;
    paymentAmountText: string;
    paymentFeeEnabled = false;
    paymentFeesToPay: number;
    totalAmountToPay = 0.00;
    loggedinPaymentFeeText: string;
    loggedinPaymentFeeExtendedText: string;
    oneTimePaymentFeeText: string;
    oneTimePaymentFeeExtendedText: string;
    paymentFeeText: string;
    paymentFeeExtendedText: string;
    paymentDateText: string;
    nickname: string;
    paymentMethodText: string;
    paymentMethodExpiredErrorText: string;
    paymentAuthText: string;
    paymentRevocationText: string;
    paymentRevocationTimeRangeText: string;
    paymentAuthDisclaimerText: string;
    paymentAuthErrorText: string;
    emailReceiptText: string;
    emailReceiptInfoText: string;
    commentsText: string;
    commentsInfoText: string;
    invalidEmailError: string;
    updateLinkText: string;
    updateCardLinkText: string;
    mySecureWalletInfoLink: string;
    saveToWalletText: string;
    optionalNicknameText: string;
    nicknamePlaceholderText: string;
    firstNameText: string;
    lastNameText: string;
    addressText: string;
    maxCCPaymentErrorText: string;
    realtimeBalanceZeroErrorText: string;
    realtimeBalanceExceededErrorText: string;
    paymentAmountRequiredErrorText: string;
    paymentError: string;
    domainDocumentMerchantWarning: string;
    promptPayShortPayDisclaimer: string;
    negativeDocumentErrorText: string;
    warningPaymentWarningText: string;
    documentPaidInFullWarningText: string;
    payorTypeChoices: string; // semi-colon delimited list of Payor Types ex. (self;parent;business)
    payorTypeLabelText: string;
    payorTypeDescriptionText: string;
    payorTypeErrorText: string;
    memCode: string;
    memExtra: string;
    lastStatementStepOneSubHeader: string;
    lastStatementStepOneRecentHeader: string;
    scheduledPaymentStep3Header: string;
    scheduledPaymentStep3SubHeader: string;
    scheduledPaymentAmountText: string;
    scheduledPaymentDateText: string;
    scheduledPaymentStep2NoWalletErrorText: string;
    subHeaderType: 'last-statement' | 'one-time-balance-details' | 'default' | 'blank' = 'blank';
    isSubHeaderSet = false;
    postDatedPaymentAuthText: string;
    postDatedPaymentAuthDisclaimerText: string;
    maxAchAmountWarningErrorText: string;

    // Instantiate this list with some real values, otherwise when you
    // are in a Deviceless flow the banner will not render correctly
    stepList: any[] = ['one', 'two', 'three'];

    payment: ConsumerPayment;

    consumer: IConsumer;
    showWalletNickname = false;
    saveToWallet: boolean;
    updateAddress = false;
    creditCardNumber: string;
    cvvNumber: string;
    cardExpirationMonth: string;
    cardExpirationYear: string;
    achAccountNumber: string;
    achRoutingNumber: string;
    nameErrorText: string;
    nameErrorExists: boolean;
    addressErrorText: string;
    addressErrorExists: boolean;
    cityErrorText: string;
    cityErrorExists: boolean;
    postalCodeErrorText: string;
    postalCodeErrorExists: boolean;
    payorType: string;
    showPayorTypeError = false;
    minimumPaymentErrorMessage: string;
    payInFullErrorMessage: string;
    paymentMethodValid = false;
    futureDatePaymentValid = false;
    isWalletEnabled = false;
    agentAssistedPaymentGuid: string;
    agentAssistedPaymentDetails: BalanceDetailsPayment[];
    agentAssistedDocumentID: number;
    agentAssistedPaymentPlanGUID: string;
    futureDateAccountPaymentDays: number;
    freeFormLabelText = '';
    freeFormInput = '';
    freeFormDescription = '';
    paymentCCFeeSurchargeExplanation = '';

    showFreeForm = false;
    sendFeeInRequest = false;

    selectedPaymentMethod: PaymentMethod;
    companyName: string;
    agentAssistedConsumerPaymentID: number;
    agentAssistedEasyViewUserID: number;
    agentAssistedFuturePaymentID: number;
    public reenableButton = new EventEmitter<boolean>(false);

    /**
     * onPopState listens for the popstate event on the window and performs the actions, this handles the browser back button
     * @see https://stackoverflow.com/questions/40381814/how-do-i-detect-user-navigating-back-in-angular2
     * @param {*} event the event
     * @memberof PaymentComponent
     */
    @HostListener('window:popstate', ['$event'])
    onPopState(event: any) {
        // wrap with this because it messes up unit tests
        if (process.env.ENV !== 'test') {
            // needed for onPopState to function completely
            history.pushState(false, 'payment', 'payment');
        }
        this.prevStep();
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    async ngOnInit() {
        // Outage? We're outta here.
        if (this.componentService.outageInStorage()) {
            this.ngZone.run(() => this.parentRouter.navigateByUrl('/outage')).then();

            return;
        }

        this.consumerToken = this.componentService.storageService.retrieve('token');
        this.componentService.scrollPageToTop();
        this.isOneTimePayment = this.checkOneTimePayment();
        this.modelToPassToPayment.isOneTimePayment = this.isOneTimePayment;
        this.isAgentAssistedPayment = this.checkAgentAssistedPayment();
        this.currentStep = this.isAgentAssistedPayment ? 2 : 1;

        if (this.isAgentAssistedPayment) {
            this.agentAssistedPaymentGuid =
                this.componentService.storageService.retrieve(
                    'agentAssistedPaymentEmailGUID'
                );
        }

        this.modelToPassToPayment.contentItemCategory = 'payment';
        this.modelToPassToPayment.contentItemSubCategory = this.isOneTimePayment ? 'oneTimePayment' : 'loggedInPayment';

        this.componentService.contentService.content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((content: any) => {
                this.handleContentItems(content);
                this.setPaymentRevocationText(content);
            });

        this.memCode = this.componentService.storageService.retrieve('mem');
        this.memExtra = this.componentService.storageService.retrieve('memExtra');

        this.domainInfo = await this.componentService.domainService.getDomainInfo();
        this.modelToPassToPayment.enableWallet = this.domainInfo && this.domainInfo.enableWallet;
        this.modelToPassToPayment.enableQuickPayWallet = this.domainInfo && this.domainInfo.enableQuickPayWallet;
        await this.populateConsumer();

        if (this.componentService.storageService.exists('impersonatorroleallowpayments')) {
            this.impersonatorRoleAllowPayments = this.componentService.storageService.retrieve('impersonatorroleallowpayments');
        }

        this.isWalletEnabled = (!this.isOneTimePayment && this.domainInfo.enableWallet) ||
            (this.isOneTimePayment && this.domainInfo.enableQuickPayWallet);

        if (!this.isOneTimePayment || this.memCode) {
            this.getCaptchaIsRequired();
        }
    }

    // Will disable step1continue button if payableStatement payment
    // amount box is zero or over the maximum payment amount.
    // This is the real time validation compared to the onClick (nextStep) validation
    emitOverPaymentEvent(canMakePayment: boolean) {
        this.canMakePayment = canMakePayment;
    }

    async ngAfterViewInit() {
        if (this.isAgentAssistedPayment) {
            const agentAssistedConsumerPayment =
                await this.agentAssistedService.retrieveAgentAssistedAmountToPay(
                    this.consumerToken,
                    this.agentAssistedPaymentGuid
                );

            this.merchantProfile =
                await this.consumerService.getMerchantProfileByMerchantProfileGUID(
                    agentAssistedConsumerPayment.merchantProfileGUID
                );

            this.paymentSelectionCmp.setMerchantProfile(this.merchantProfile);

            this.agentAssistedDocumentID = agentAssistedConsumerPayment.documentID;
            this.agentAssistedPaymentDetails = agentAssistedConsumerPayment.details;
            this.amountToPay = agentAssistedConsumerPayment.paymentAmount;
            this.modelToPassToPayment.amountToPay = this.amountToPay;
            this.modelToPassToPayment.paymentDate = this.paymentDate;
            this.agentAssistedEasyViewUserID = agentAssistedConsumerPayment.easyViewUserID;
            this.receiptEmail = agentAssistedConsumerPayment.emailAddress;

            if (!!agentAssistedConsumerPayment.consumerPaymentID) {
                this.modelToPassToPayment.enablePaymentDatePicker = false;
                this.agentAssistedConsumerPaymentID = agentAssistedConsumerPayment.consumerPaymentID;
                if (this.componentService.isValidGUID(agentAssistedConsumerPayment.paymentPlanGUID)) {
                    this.agentAssistedPaymentPlanGUID = agentAssistedConsumerPayment.paymentPlanGUID;
                }
            } else if (!!agentAssistedConsumerPayment.futurePaymentID) {
                this.agentAssistedFuturePaymentID = agentAssistedConsumerPayment.futurePaymentID;
                this.modelToPassToPayment.enablePaymentDatePicker = true;
                this.modelToPassToPayment.paymentDate = agentAssistedConsumerPayment.scheduledPaymentDate;
                this.modelToPassToPayment.lockSaveToWallet = true;

                // We need this (Payable Entry(ies)) saved to the service because a
                // Deviceless Payment will not have this information available.
                if (
                    this.domainInfo.enablePayableEntries &&
                    this.domainInfo.groupAccountBalances
                ) {
                    const balances = await this.paymentPlanService.getBalanceDetails(this.consumer.accountID);

                    this.componentService.setPayableEntryOrItemBalances(balances);
                }
            }

            // This is "normally" set inside Step1, but this is Deviceless and we don't go there.
            this.futureDateAccountPaymentDays = this.merchantProfile.futureDateAccountPaymentDays;
            this.paymentScheduledDateError =
                this.paymentScheduledDateError.replace(
                    '!FUTUREDATEPAYMENTLIMIT!',
                    this.getEndDate()
                );
        }
        if (this.isOneTimePayment && this.domainInfo.enableQuickPayBalanceDetails) {
            this.canMakePayment = true;
        }
        else
        {
            if (!this.isOneTimePayment) {
                this.canMakePayment = await this.payableService.canMakePayment();
                this.noPayableItems = !this.canMakePayment;
            }
        }
    }


    ngAfterContentChecked(): void {
        // The reason this is here, and not in ngAfterViewInit,
        // is because this.payableItemListCmp isn't loaded at that
        // point. So this will be continuous re-evaluated until the
        // payableItemList component is available.
        if (!this.isSubHeaderSet
            && this.payableItemListCmp
            && !this.payableItemListCmp.isLoading()) {
            if (this.isOneTimePayment
                && !!this.domainInfo
                && this.domainInfo.enableQuickPayBalanceDetails
                && !!this.payableItemListCmp
                && (!this.payableItemListCmp.noPayableBalancesExist || !this.payableItemListCmp.noPaymentPlansExist)
                && this.payableItemListCmp.hasConsumerAccount) {
                this.subHeaderType = 'one-time-balance-details';
            } else if (!this.isOneTimePayment
                && !!this.domainInfo
                && this.domainInfo.totalBalanceType === 'Last Statement') {
                this.subHeaderType = 'last-statement';
            } else {
                this.subHeaderType = 'default';
            }
            this.isSubHeaderSet = true;
        }
    }

    async getCaptchaIsRequired(): Promise<void> {
        this.captchaRequired = await this.consumerService.isCaptchaRequired();
    }

    private redirect(relativeRoute: string) {
        this.submission = false;
        this.ngZone.run(() => this.parentRouter.navigateByUrl(relativeRoute)).then();
    }

    async handleCaptchaExpired(evt: any) {
        this.captchaToken = '';
        this.recaptchaAttempted = true;
        this.recaptchaError = true;
    }

    async handleCorrectCaptcha(token: string) {
        this.recaptchaAttempted = true;
        this.captchaToken = token;
        this.captchaTokenExists(token)
    }

    async captchaTokenExists(token: string) {
        this.captchaToken = token;
        this.recaptchaAttempted = !!this.captchaToken;

        this.validateStep3();
    }

    nextStep() {
        // Outage? We're outta here.
        if (this.componentService.outageInStorage()) {
            this.ngZone.run(() => this.parentRouter.navigateByUrl('/outage')).then();

            return;
        }

        if (!this.isOneTimePayment || this.memCode) {
            this.getCaptchaIsRequired();
        }

        // Because the continue button is wrapped in a link
        // the click event is assigned to the link and not the inner button
        // this means that the disabled flag is mostly for button styling.
        // This check stops the UI from changing on click if disabled
        this.checkPaymentListInvalid().then(paymentListInvalid => {
            if (!this.canMakePayment || (!this.isAgentAssistedPayment && paymentListInvalid)) {
                return;
            }
        });

        // wrap with this because it messes up unit tests
        if (process.env.ENV !== 'test') {
            // needed for onPopState to function completely
            history.pushState(false, 'payment', 'payment');
        }

        // clear validation messages
        this.stepValidationMessages = [];

        if (this.currentStep === 1) {
            // Set running amount display from step 1 total
            this.amountToPay = this.payableItemListCmp.calculatedSum;

            // when moving step 1 -> step 2, mark any statement with an amount as payToItem = true just to be sure
            this.payableItemListCmp.payableStatementCmpList.filter(
                (y) => y.statement.paymentAmount != null && y.statement.paymentAmount > 0 && y.isDisabled === false).forEach(
                (x) => x.statement.payToItem = true);

            this.statementsToPay = this.payableItemListCmp.payableStatementCmpList.filter(
                (x) => x.statement.payToItem).map((y) => y.statement);

            this.payableItemsToPay = this.payableItemListCmp.payableItemCmpList.filter(
                (x) => x.payableItem.payToItem).map((y) => y.payableItem);

            this.payableEntriesToPay = [];
            this.payableItemListCmp.payableEntryCmpList.filter(
                (x) => x.payableEntry.payToItem).forEach((y) => {
                if (y.payableEntry.itemType === PayableEntryType.accountBalanceGroup) {
                    y.payableEntry.childItems.forEach((z) => {
                        this.payableEntriesToPay.push(z);
                    });
                } else {
                    this.payableEntriesToPay.push(y.payableEntry);
                }
            });

            if (!!this.statementsToPay && this.statementsToPay.length > 0) {
                this.modelToPassToPayment.amountToPay = this.amountToPay;
                this.modelToPassToPayment.paymentDate = this.paymentDate;
                this.paymentSelectionCmp.setMerchantProfile(this.statementsToPay[0].merchantProfile, true);
            } else if (!!this.payableItemsToPay && this.payableItemsToPay.length > 0) {
                this.modelToPassToPayment.amountToPay = this.amountToPay;
                this.modelToPassToPayment.paymentDate = this.paymentDate;
                this.modelToPassToPayment.enablePaymentDatePicker =
                    !this.payableItemsToPay.find(x => x.itemType === PayableItemType.paymentplan) &&
                    this.isWalletEnabled &&
                    !!this.payableItemsToPay[0].merchantProfile.futureDateAccountPaymentDays &&
                    this.payableItemsToPay[0].merchantProfile.futureDateAccountPaymentDays > 0;

                const shouldUpdateWallet = this.payableItemsToPay[0].itemType === PayableItemType.paymentplan
                    || this.payableItemsToPay[0].itemType === PayableItemType.paymentplanbalance;
                this.paymentSelectionCmp.setMerchantProfile(this.payableItemsToPay[0].merchantProfile, shouldUpdateWallet);
            } else if (!!this.payableEntriesToPay && this.payableEntriesToPay.length > 0) {
                this.modelToPassToPayment.amountToPay = this.amountToPay;
                this.modelToPassToPayment.paymentDate = this.paymentDate;
                this.modelToPassToPayment.enablePaymentDatePicker =
                    !this.payableEntriesToPay.find(x => x.itemType === PayableEntryType.paymentplan) &&
                    this.isWalletEnabled &&
                    !!this.payableEntriesToPay[0].merchantProfile.futureDateAccountPaymentDays &&
                    this.payableEntriesToPay[0].merchantProfile.futureDateAccountPaymentDays > 0;

                const shouldUpdateWallet = this.payableEntriesToPay[0].itemType === PayableEntryType.paymentplan
                    || this.payableEntriesToPay[0].itemType === PayableEntryType.paymentplanbalance;
                this.paymentSelectionCmp.setMerchantProfile(this.payableEntriesToPay[0].merchantProfile, shouldUpdateWallet);
            }

            // Subtracting 1 day since we are including "Today" as one of the "Future" days.
            this.futureDateAccountPaymentDays =
                this.paymentSelectionCmp.getMerchantProfile().futureDateAccountPaymentDays - 1;

            this.paymentScheduledDateError =
                this.paymentScheduledDateError.replace(
                    '!FUTUREDATEPAYMENTLIMIT!',
                    this.getEndDate()
                );

            this.paymentAmountValidation();
        }

        if (this.currentStep === 2) {
            if (this.captchaRequired) {
                this.recaptchaCmp.displayRecaptcha();
            }

            this.paymentSelectionState = this.paymentSelectionCmp.getPaymentSelectionState();
            this.paymentDateAsDateType = this.paymentSelectionState.pickerPaymentDate;

            // Check to see if user typed garbage into the date picker.
            if (this.paymentSelectionState.pickerPaymentDate.toString() === 'Invalid Date') {
                this.step2Valid = false;
            } else {
                this.step2Valid = true;
            }

            this.isAchOverMax();
            if (this.step2Valid) {
                if (this.futureDateAccountPaymentDays > 0) {
                    if (this.isPaymentDateInThePastOrTooFarInTheFuture()) {
                        this.step2Valid = false;
                    } else {
                        // This would not otherwise be set where it is below if the
                        // "future" date selected is today.
                        this.paymentDate = moment(this.paymentDateAsDateType).format('YYYY-MM-DDTHH:mm:ss');
                    }
                }
            }

            if (this.step2Valid) {
                if (this.modelToPassToPayment.enablePaymentDatePicker) {
                    this.futureDatePaymentValidation();

                    if (this.futureDatePaymentValid && this.isFuturePaymentDateValid) {
                        this.paymentMethodValidation();
                        this.step2Valid = this.paymentMethodValid;
                    } else {
                        this.step2Valid = false;
                    }
                } else {
                    this.paymentMethodValidation();
                    this.step2Valid = this.paymentMethodValid;
                }
            }

            if (this.step2Valid) {
                // If nothing is selected for payment, and values on any payable items/statements are still $0.00,
                // and you try to move onward, no merchant profile has been set.
                // We should skip trying to set values based on by an MP and instead throw validation messaging.
                if (this.anythingSelectedForPayment()) {
                    this.showReceiptEmail = this.paymentSelectionCmp.getMerchantProfile().enableConfirmationEmails;
                }
                this.getEnableComments();

                this.merchantProfile = this.paymentSelectionCmp.getMerchantProfile();

                if (this.merchantProfile.performBINValidation && this.merchantProfile.feeProfileID > 0) {
                    this.paymentFeeEnabled = true;
                    this.paymentFeeText = this.isOneTimePayment ? this.oneTimePaymentFeeText : this.loggedinPaymentFeeText;

                    this.paymentFeeExtendedText = this.isOneTimePayment ?
                        this.oneTimePaymentFeeExtendedText : this.loggedinPaymentFeeExtendedText;
                }

                if (this.isAgentAssistedPayment) {
                    this.payableItemListCmp.payableStatementCmpList.filter(
                        (y) => y.statement.documentID === this.agentAssistedDocumentID).forEach(
                            (x) => {
                                x.statement.payToItem = true;
                                x.statement.paymentAmount = this.amountToPay;
                                x.distributePaymentAmountToBuckets();
                            }
                        );

                    this.statementsToPay = this.payableItemListCmp.payableStatementCmpList.filter(
                        (x) => x.statement.payToItem).map((y) => y.statement);
                }

                if (this.isFutureDateAccountPayment()) {
                    this.paymentDate = moment(this.paymentDateAsDateType).format('YYYY-MM-DDTHH:mm:ss');

                    // Set scheduled payment content items:
                    this.paymentStep3Header = this.scheduledPaymentStep3Header;
                    this.paymentStep3SubHeading = this.scheduledPaymentStep3SubHeader;
                    this.paymentAmountText = this.scheduledPaymentAmountText;
                    this.paymentDateText = this.scheduledPaymentDateText;
                }

                const paymentState = this.paymentSelectionCmp.getPaymentSelectionState();
                const paymentMethodType = paymentState.selectedPaymentMethod.paymentMethodType;
                const checkBIN = this.checkBIN(paymentMethodType);

                if (checkBIN && this.newCreditCard(paymentState)) {
                    this.sendFeeInRequest = true;

                    this.paymentService.getBinFeeLookup(
                        this.consumer.accountID,
                        paymentState.creditCardNumber,
                        null, // paymentMethodGUID (not needed here)
                        'bd',
                        this.consumerToken,
                        this.amountToPay,
                        this.merchantProfile.merchantProfileGUID
                    ).then(response => {
                        if (response) {
                            this.paymentFeesToPay = response.feeAmount;
                            this.totalAmountToPay = this.amountToPay + this.paymentFeesToPay;

                            this.componentService.storageService.store(
                                'binLookupGuid',
                                response.binLookupGuid,
                                StorageLevel.protected
                            );
                        }
                    });
                } else if (checkBIN && this.savedCreditCard(paymentState.selectedPaymentMethod)) {
                    this.sendFeeInRequest = true;

                    this.paymentService.getBinFeeLookup(
                        this.consumer.accountID,
                        paymentState.creditCardNumber,
                        paymentState.selectedPaymentMethod.tokenGUID,
                        'bd',
                        this.consumerToken,
                        this.amountToPay,
                        this.merchantProfile.merchantProfileGUID
                    ).then(response => {
                        if (response) {
                            this.paymentFeesToPay = response.feeAmount;
                            this.totalAmountToPay = this.amountToPay + this.paymentFeesToPay;

                            this.componentService.storageService.store(
                                'binLookupGuid',
                                response.binLookupGuid,
                                StorageLevel.protected
                            );
                        }
                    });
                } else {
                    this.paymentFeesToPay = .00;
                    this.totalAmountToPay = this.amountToPay;
                }
            }
        }

        if (this.currentStep === 3) {
            this.reviewPaymentValidation();
        }

        if (this.currentStep < this.finalStep && this.currentStepIsValid(this.currentStep)) {
            this.currentStep++;
            this.componentService.scrollPageToTop();
        } else if (this.currentStep === this.finalStep && this.currentStepIsValid(this.currentStep)) {
            // Submit payment and redirect to confirmation page?
            if (this.resubmitPayment) {
                // Sounds good, let's do that
                this.validatePayment();
            } else {
                this.step3Valid = false;
                this.enableMakePaymentButton();

                this.setPageValidationMessage('You must make changes to resubmit payment');
            }
        }

        this.selectedPaymentMethod = this.paymentSelectionCmp.getPaymentSelectionState().selectedPaymentMethod;
    }

    async prevStep() {
        // Outage? We're outta here.
        if (this.componentService.outageInStorage()) {
            this.ngZone.run(() => this.parentRouter.navigateByUrl('/outage')).then();

            return;
        }
        if (this.isAgentAssistedPayment && this.currentStep === 2) {
            return;
        }

        this.payment = null;
        this.paymentAuthorization = false;
        this.step2Valid = true;
        this.step3Valid = true;
        const paymentState = this.paymentSelectionCmp.getPaymentSelectionState();

        this.resubmitPayment = true;
        if (this.currentStep > 1) {
            if (this.currentStep === 2
                && paymentState.selectedPaymentMethod.tokenGUID === 'newPaymentMethod'
                && paymentState.creditCardNumber != null
                && paymentState.creditCardNumber.length > 0) {
                this.paymentSelectionCmp.prepareCreditCard();
            }
            this.paymentSelectionCmp.checkACH();
            this.paymentSelectionCmp.revertFakeSavedPayment();

            this.checkDomainVsDocumentMerchant();

            if (!!this.domainInfo && !this.domainInfo.enableWallet) {
                this.paymentSelectionCmp.disableWallet(false);
            }

            this.currentStep--;
            this.componentService.scrollPageToTop();
        } else {
            if (this.isOneTimePayment) {
                this.componentService.loggingService.log('logging you out', LoggingLevel.debug);
                if (!this.stopDoubleclick) {
                    try {
                        await this.loginService.logout();
                        this.currentStep = -1;
                    } catch (e) {
                        alert(e);
                    } finally {
                        // tslint:disable-next-line: no-unsafe-finally
                        return this.redirect('/mempayment');
                    }
                }
                this.stopDoubleclick = true;
            } else {
                return this.redirect('/home');
            }
        }
    }

    /**
     * Returns true if the selected payment date is in the future.
     */
    private isFutureDateAccountPayment(): boolean {
        const paymentDateMoment = moment(this.paymentDateAsDateType).startOf('day');
        const todaysDateMoment = moment(this.todaysDate).startOf('day');

        return paymentDateMoment.diff(todaysDateMoment, 'days') > 0 ||
            // An agent assisted payment could theoretically have a date of today,
            // and in that case we still want to call it a future date payment:
            (this.isAgentAssistedPayment && !!this.agentAssistedFuturePaymentID);
    }

    /**
     * Returns true if the selected payment date is in the past or too far into the future.
     */
    private isPaymentDateInThePastOrTooFarInTheFuture(): boolean {
        const paymentDateMoment = moment(this.paymentDateAsDateType).startOf('day');
        const todaysDateMoment = moment(this.todaysDate).startOf('day');
        const dateDifference = paymentDateMoment.diff(todaysDateMoment, 'days');

        return dateDifference < 0 || dateDifference > this.futureDateAccountPaymentDays;
    }

    /**
     * The big reason for this app existing.  This sends the payment that was created in createPayment.
     * if you run this without a payment, nothing happens.
     *
     * @returns {void}
     *
     * @memberof PaymentComponent
     */
    private submitPayment(): void {
        this.startSubmission();
        if (!this.payment) {
            // no payment yet?  why did you call submitPayment without validatePayment?
            return;
        } else {
            this.paymentService.submitPayment(this.captchaToken, this.payment, this.consumerToken)
                .then(paymentDone => {
                    this.processPayment(paymentDone);
                })
                .catch(paymentError => {
                    this.processPayment(paymentError);
                });
        }
    }

    /**
     * Performs step3 validation only if the `this.readyForStep3Validation` flag is `true`
     * used in the callbacks for the authorization checkbox and recaptcha events
     */
    private validateStep3() {
        if (this.readyForStep3Validation) {
            this.reviewPaymentValidation();
        }
    }

    private createFutureDatePayment(): void {
        this.startSubmission();
        if (!this.payment) {
            // if they didn't want to call submitPayment without that, we don't want to, either.
            return;
        } else {
            const futureDatePaymentRequest = this.createFutureDatePaymentCreateAgreementRequest();
            this.futureDateAccountPaymentService.createFutureDateAccountPayment(futureDatePaymentRequest, this.consumerToken)
                .then(paymentCreated => {
                    this.processFutureDatePayment(paymentCreated);
                })
                .catch(paymentCreateError => {
                    this.processFutureDatePayment(paymentCreateError);
                });
        }
    }

    /**
     *
     * This method calls the validatePayment with the payment created in createPayment
     *
     * @memberof PaymentComponent
     */
    validatePayment(): void {
        if (!this.hasSubmitted) {
            this.startSubmission();

            if (this.payment == null) {
                this.createPayment();
            } else {
                if (this.resubmitPayment) {
                    if (this.isFutureDateAccountPayment()) {
                        this.createFutureDatePayment();
                    } else {
                        this.submitPayment();
                    }
                }
                return;
            }

            if (!this.payment) {
                // no idea what happened, but it blowed up.
                this.componentService.loggingService.log('Error in validate Payment');
                throw (new Error('An error has occurred while creating the payment'));
            } else {
                if (this.isAgentAssistedPayment) {
                    this.payment.EasyViewUserID = this.agentAssistedEasyViewUserID;
                }

                this.paymentService.validatePayment(this.payment, this.consumerToken)
                    .then(paymentDone => {
                        this.processValidation(paymentDone);
                    })
                    .catch(paymentError => {
                        this.processValidation(paymentError);
                        this.consumerService.isCaptchaRequired().then(captchaRequired => {
                            this.captchaRequired = captchaRequired;
                        });
                    });
            }
        } else {
            const domain = this.componentService.storageService.getDomain();
            this.componentService.loggingService.log('PaymentComponent.validatePayment: stopped a duplicate payment. Domain: ' +
                                                        domain + '; CustomerAccountID: ' + this.consumer.accountID, LoggingLevel.error);
        }
    }

    /**
     * After the validatePayment gets done sending to the server, it comes back and calls processValidation with the response
     *
     *
     * @param {IPaymentValidationResponse} payment the response that comes back from the service
     *
     * @memberof PaymentComponent
     *
     * @see validatePayment
     */
    processValidation(payment: IPaymentValidationResponse) {
        this.isWarningMessage = false;

        if (payment.success && payment.data.length > 0) {
            // Warning
            this.submission = false;
            this.hasSubmitted = false;
            this.step3Valid = false;
            this.enableMakePaymentButton();

            this.consumerService.isCaptchaRequired().then(captchaRequired => {
                this.captchaRequired = captchaRequired;
            });

            let message = new Array<string>();
            for (const valMessage of payment.data) {
                message = message.concat(this.componentService.handleValidationErrorCode(valMessage.message));
            }

            if (message != null) {
                this.setPageValidationMessages(message);
            } else {
                this.setPageValidationMessage('An error has occurred.');
            }

            this.isWarningMessage = true;
        } else if (!payment.success) {
            // Failure
            this.submission = false;
            this.hasSubmitted = false;
            this.step3Valid = false;
            this.enableMakePaymentButton();

            if (!!payment.messages) {
                this.setPageValidationMessages(payment.messages);
            } else {
                this.setPageValidationMessage('An error has occurred.');
            }

            this.consumerService.isCaptchaRequired().then(captchaRequired => {
                this.captchaRequired = captchaRequired;
            });

            this.resubmitPayment = false;
        } else {
            // no warning, submit the payment
            if (this.isFutureDateAccountPayment()) {
                // The payment date is in the future: create a future payment request
                this.createFutureDatePayment();
            } else {
                this.submitPayment();
            }
        }
    }

    /**
     * After createFutureDatePayment gets done, the response from the server calls processPayment with the response info
     *
     * @param {ICreateFutureDatePaymentResponse} payment
     *
     * @memberof PaymentComponent
     */
    processFutureDatePayment(payment: ICreateFutureDatePaymentResponse) {
        this.isWarningMessage = false;
        if (payment.success) {
            const paymentConfirmation: FutureDateAccountPaymentConfirmation =
                new FutureDateAccountPaymentConfirmation(!this.isAgentAssistedPayment);
            paymentConfirmation.paymentAmount = this.amountToPay;
            paymentConfirmation.paymentDate = this.paymentDate;
            paymentConfirmation.confirmationNumber = payment.data.futurePaymentID.toString();

            // if onetimepayment, store information for future user enrollment
            if (this.componentService.storageService.exists('onetimepayment')) {
                const quickPayEnrollment: QuickPayEnrollment = new QuickPayEnrollment();
                quickPayEnrollment.email = this.receiptEmail;
                quickPayEnrollment.firstName = this.consumer.firstName;
                quickPayEnrollment.lastName = this.consumer.lastName;
                quickPayEnrollment.memCode = this.memCode;
                quickPayEnrollment.secondFactorAuth = this.memExtra;
                this.componentService.storageService.store('quickpayenrollment', quickPayEnrollment, StorageLevel.protected);
            }

            // store information needed for confirmation page
            this.componentService.storageService.store('futuredatepaymentconfirmation', paymentConfirmation);

            if (this.isAgentAssistedPayment) {
                this.componentService.storageService.store('agentassistedrole', 'futureconfirmation');
            }

            // clean up any temp payment success
            this.paymentSelectionCmp.clearPayments();
            this.redirect('/futuredateconfirmation');
        } else {
            this.submission = false;
            this.hasSubmitted = false;
            this.resubmitPayment = false;
            this.step3Valid = false;
            this.enableMakePaymentButton();

            if (payment.data && payment.messages != null && payment.messages.length > 0) {
                this.setPageValidationMessages(payment.messages.map((message) => this.componentService.handleValidationErrorCode(message)));
            } else {
                this.setPageValidationMessage(this.paymentError);
            }
        }
    }

    /**
     * After the submitPayment gets done, the response from the server calls processPayment with the response info
     *
     * @param {ISubmitPaymentResponse} payment
     *
     * @memberof PaymentComponent
     */
    processPayment(payment: ISubmitPaymentResponse) {
        this.isWarningMessage = false;
        if (payment.success) {
            const paymentConfirmation: PaymentConfirmation = new PaymentConfirmation();
            paymentConfirmation.accountId = this.consumer.accountID;
            paymentConfirmation.paymentAmount = this.amountToPay;

            if (this.paymentFeeEnabled && this.paymentFeesToPay) {
                paymentConfirmation.paymentFeesToPay = this.paymentFeesToPay;
            }

            paymentConfirmation.paymentDate = this.paymentDate;
            paymentConfirmation.paymentReferenceId = payment.data.referenceID;
            paymentConfirmation.consumerPaymentGUID = payment.data.consumerPaymentGUID;
            paymentConfirmation.confirmationNumber = !!payment.data.loyaleProcessorID ?
                payment.data.loyaleProcessorID : payment.data.processorID;

            // if onetimepayment, store information for future user enrollment
            if (this.componentService.storageService.exists('onetimepayment')) {
                const quickPayEnrollment: QuickPayEnrollment = new QuickPayEnrollment();
                quickPayEnrollment.email = this.receiptEmail;
                quickPayEnrollment.firstName = this.consumer.firstName;
                quickPayEnrollment.lastName = this.consumer.lastName;
                quickPayEnrollment.memCode = this.memCode;
                quickPayEnrollment.secondFactorAuth = this.memExtra;
                this.componentService.storageService.store('quickpayenrollment', quickPayEnrollment, StorageLevel.protected);
            }

            // store information needed for confirmation page
            this.componentService.storageService.store('paymentconfirmation', paymentConfirmation);

            // clean up any temp payment success
            this.paymentSelectionCmp.clearPayments();
            if (this.isAgentAssistedPayment) {
                this.componentService.storageService.store('agentassistedrole', 'paymentconfirmation');
            }

            this.redirect('/confirmation');
        } else {
            this.submission = false;
            this.hasSubmitted = false;
            this.resubmitPayment = false;
            this.step3Valid = false;
            this.enableMakePaymentButton();

            this.consumerService.isCaptchaRequired().then(captchaRequired => {
                this.captchaRequired = captchaRequired;
            });

            if (!!payment.data && !!payment.data.newAgentAssistedConsumerPaymentID) {
                this.agentAssistedConsumerPaymentID = payment.data.newAgentAssistedConsumerPaymentID;
            }

            if (payment.data && payment.messages != null && payment.messages.length > 0) {
                this.setPageValidationMessages(payment.messages.map((message) => this.componentService.handleValidationErrorCode(message)));
            } else {
                this.setPageValidationMessage(this.paymentError);
            }
        }
    }

    /**
     * This is the assembly of the ConsumerPayment to send to the various ConsumerAPI methods for
     * validation and payment.  createPayment stores the payment created on the PaymentComponent to be
     * used throughout the various calls.
     *
     * @memberof PaymentComponent
     */
    public createPayment(): void {
        const paymentState: PaymentSelectionStateModel = this.paymentSelectionCmp.getPaymentSelectionState();
        const payment: ConsumerPayment = new ConsumerPayment();
        if (this.isAgentAssistedPayment) {
            payment.ConsumerPaymentID = this.agentAssistedConsumerPaymentID;
            payment.PaymentPlanGUID = this.agentAssistedPaymentPlanGUID;
        }

        payment.PaymentSource = this.paymentSelectionCmp.getPaymentSource();

        // Must have one of tokenpayment,achpayment or creditcardpayment.
        // Check for wallet.
        if (!!paymentState.selectedPaymentMethod.tokenGUID && paymentState.selectedPaymentMethod.tokenGUID !== 'newPaymentMethod') {
            payment.TokenPaymentMethod = {
                methodToken: paymentState.selectedPaymentMethod.tokenGUID,
                methodType: paymentState.selectedPaymentMethod.paymentMethodType === PaymentMethodType.ach ? 'ACH' : 'Credit',
                tokenDataSource: 'msbPayment'
            };
        } else {
            // No wallet, is it cc or ach?
            if (paymentState.selectedPaymentMethod.paymentMethodType === PaymentMethodType.ach) {
                payment.ACHPaymentMethod = this.paymentSelectionCmp.getACHPayment();
            } else {
                payment.CreditCardPaymentMethod = this.paymentSelectionCmp.getCreditCardPayment();
            }
        }

        payment.Address = {
            firstName: paymentState.firstName,
            lastName: paymentState.lastName,
            address1: paymentState.address1,
            address2: paymentState.address2,
            city: paymentState.city,
            stateRegion: paymentState.state,
            postalCode: paymentState.postalCode,
            country: paymentState.country
        };

        const payableItemsExist = !!this.payableItemsToPay && this.payableItemsToPay.some(
            x => x.itemType === PayableItemType.paymentplan || x.itemType === PayableItemType.paymentplanbalance);
        const payableStatementsExist = !!this.statementsToPay && this.statementsToPay.some(x => x.itemType === PayableItemType.statement);

        if (this.domainInfo.enablePayableEntries && !payableItemsExist && !payableStatementsExist) {
            if (!!this.payableEntriesToPay && this.payableEntriesToPay.some(
                x => x.itemType === PayableEntryType.paymentplan || x.itemType === PayableEntryType.paymentplanbalance)) {
                payment.RealtimeBalanceToPayOn = new RealtimePayment();
                payment.RealtimeBalanceToPayOn.PaidAccounts = [];
                payment.RealtimeBalanceToPayOn.PaymentPlanPaymentAmount = 0;

                const payableItemsNotOnExistingPaymentPlans = this.payableEntriesToPay.filter(
                    x => x.itemType === PayableEntryType.paymentplanbalance);
                payableItemsNotOnExistingPaymentPlans.forEach((item) => {
                    const balanceDetail: BalanceDetailsPayment = {
                        accountNumber: item.itemDescription,
                        amount: ComponentService.toDecimal(item.paymentAmount)
                    };
                    payment.RealtimeBalanceToPayOn.PaidAccounts.push(balanceDetail);
                });

                const payablePlanPayableItem = this.payableEntriesToPay.find(x => x.itemType === PayableEntryType.paymentplan);
                if (payablePlanPayableItem) {
                    payment.RealtimeBalanceToPayOn.PaymentPlanPaymentAmount = ComponentService.toDecimal(
                        payablePlanPayableItem.paymentAmount);
                    payment.PaymentPlanGUID = payablePlanPayableItem.id;
                }
                payment.RealtimeBalanceToPayOn.PaymentAmount = ComponentService.toDecimal(this.amountToPay);
            } else {
                if (this.payableEntriesToPay && this.payableEntriesToPay.some(x => x.itemType !== PayableEntryType.statement)) {
                    payment.RealtimeBalanceToPayOn = new RealtimePayment();
                    payment.RealtimeBalanceToPayOn.PaymentAmount = ComponentService.toDecimal(this.amountToPay);
                }

                // TODO: this is looking ahead to the future; currently this case should never happen:
                if (this.payableEntriesToPay && this.payableEntriesToPay.some(x => x.itemType === PayableEntryType.statement)) {
                    payment.DocumentsToPayOn = new Array<DocumentPayments>();
                    this.payableEntriesToPay.forEach(statement => {
                        let documentToPay: DocumentPayments;
                        let newBucket: DetailPayment;

                        documentToPay = {
                            PaidBuckets: new Array<DetailPayment>(),
                            DocumentID: statement.documentID,
                            PaymentAmount: statement.paymentAmount
                        };
                        statement.childItems.forEach(bucket => {
                            newBucket = {
                                Sequence: bucket.sequence,
                                Amount: bucket.paymentAmount != null ?
                                    ComponentService.toDecimal(bucket.paymentAmount) : 0
                            };
                            documentToPay.PaidBuckets = documentToPay.PaidBuckets.concat(newBucket);
                        });
                        payment.DocumentsToPayOn = payment.DocumentsToPayOn.concat(documentToPay);
                    });
                }
            }
        } else {
            if (this.payableItemsToPay && this.payableItemsToPay.some(
                x => x.itemType === PayableItemType.paymentplan || x.itemType === PayableItemType.paymentplanbalance)) {
                payment.RealtimeBalanceToPayOn = new RealtimePayment();
                payment.RealtimeBalanceToPayOn.PaidAccounts = [];
                payment.RealtimeBalanceToPayOn.PaymentPlanPaymentAmount = 0;

                const payableItemsNotOnExistingPaymentPlans = this.payableItemsToPay.filter(
                    x => x.itemType === PayableItemType.paymentplanbalance);
                payableItemsNotOnExistingPaymentPlans.forEach((item) => {
                    const balanceDetail: BalanceDetailsPayment = {
                        accountNumber: item.itemDescription,
                        amount: ComponentService.toDecimal(item.paymentAmount)
                    };
                    payment.RealtimeBalanceToPayOn.PaidAccounts.push(balanceDetail);
                });

                const payablePlanPayableItem = this.payableItemsToPay.filter(x => x.itemType === PayableItemType.paymentplan)[0];
                if (payablePlanPayableItem) {
                    payment.RealtimeBalanceToPayOn.PaymentPlanPaymentAmount = ComponentService.toDecimal(
                        payablePlanPayableItem.paymentAmount);
                    payment.PaymentPlanGUID = payablePlanPayableItem.id;
                }
                payment.RealtimeBalanceToPayOn.PaymentAmount = ComponentService.toDecimal(this.amountToPay);

            } else {
                if (this.statementsToPay && this.statementsToPay.some(x => x.itemType !== PayableItemType.statement)) {
                    payment.RealtimeBalanceToPayOn = new RealtimePayment();
                    payment.RealtimeBalanceToPayOn.PaymentAmount = ComponentService.toDecimal(this.amountToPay);
                }

                if (this.statementsToPay && this.statementsToPay.some(x => x.itemType === PayableItemType.statement)) {
                    payment.DocumentsToPayOn = new Array<DocumentPayments>();
                    for (const statement of this.statementsToPay) {
                        let documentToPay: DocumentPayments;
                        let newBucket: DetailPayment;

                        documentToPay = {
                            PaidBuckets: new Array<DetailPayment>(),
                            DocumentID: statement.documentID,
                            PaymentAmount: ComponentService.toDecimal(statement.paymentAmount)
                        };
                        for (const bucket of statement.paymentBuckets) {
                            newBucket = {
                                Sequence: bucket.sequence,
                                Amount: bucket.bucketPaymentAmount != null ? ComponentService.toDecimal(bucket.bucketPaymentAmount) : 0
                            };
                            documentToPay.PaidBuckets = documentToPay.PaidBuckets.concat(newBucket);
                        }
                        payment.DocumentsToPayOn = payment.DocumentsToPayOn.concat(documentToPay);
                    }
                }
            }
        }

        payment.CaptchaToken = this.captchaToken;
        payment.AccountID = this.consumer.accountID;
        payment.StorePaymentMethod = paymentState.saveToWallet;
        payment.PaymentComment = this.comment;
        payment.PaymentMethodNickname = paymentState.newPaymentNickname;
        payment.ConfirmationEmail = this.receiptEmail;
        payment.Language = window.navigator.language;
        payment.AccountName = paymentState.firstName + ' ' + paymentState.lastName;
        payment.PayorType = this.payorType;
        payment.smsGUID = this.componentService.storageService.retrieve('smsGUID');

        if (this.isFutureDateAccountPayment()) {
            payment.ScheduledPaymentDate = this.paymentDateAsDateType;
        }

        if (this.freeFormLabelText.length > 0) {
            payment.CustomTextJSON = '{' + '"' + this.freeFormLabelText + '" : "' + this.freeFormInput.trim() + '"}';
        }

        // Only for Agent Assisted Payments on Balances.
        // Document Detail info handled elsewhere.
        if (this.isAgentAssistedPayment && !this.agentAssistedDocumentID) {
            payment.RealtimeBalanceToPayOn = new RealtimePayment();

            // We don't want PaidAccounts for paying on Payment Plans
            if (this.agentAssistedPaymentPlanGUID) {
                payment.RealtimeBalanceToPayOn.PaymentPlanPaymentAmount = this.modelToPassToPayment.amountToPay;
            } else {
                payment.RealtimeBalanceToPayOn.PaidAccounts = this.agentAssistedPaymentDetails;
                payment.RealtimeBalanceToPayOn.PaymentPlanPaymentAmount = 0;
            }

            payment.RealtimeBalanceToPayOn.PaymentAmount = this.modelToPassToPayment.amountToPay;
        }

        if (this.sendFeeInRequest) {
            payment.FeeAmount = this.paymentFeesToPay;
            payment.BINLookupGuid = this.componentService.storageService.retrieve('binLookupGuid');
        }

        this.payment = payment;
    }

    createFutureDatePaymentCreateAgreementRequest(): FutureDatePaymentCreateAgreementRequest {
        const details: FutureDateAccountPaymentDetailRequest[] = [];

        this.payment.RealtimeBalanceToPayOn.PaidAccounts.forEach(account => {
            if (account.amount > 0) {
                let balance = 0;
                let paymentLocationGUID = '';

                // Agent Assisted Future Dated Payments are not going to have payableItemsToPay or payableEntriesToPay.
                if (this.isAgentAssistedPayment && this.isFutureDateAccountPayment()) {
                    let balanceFound: boolean;

                    this.payableEntryOrItemBalances.forEach(b => {
                        if (!balanceFound && b.accountNumber === account.accountNumber) {
                            balance = b.balance;
                            paymentLocationGUID = b.paymentLocationGUID;

                            balanceFound = true;
                        }
                    });
                } else {
                    if (this.payableItemsToPay && this.payableItemsToPay.length > 0) {
                        const item = this.payableItemsToPay.find(x => x.itemDescription === account.accountNumber);
                        balance = item.payableAmount;
                        paymentLocationGUID = item.paymentLocationGUID;
                    } else if (this.payableEntriesToPay && this.payableEntriesToPay.length > 0) {
                        const entry = this.payableEntriesToPay.find(x => x.itemDescription === account.accountNumber);
                        balance = entry.payableAmount;
                        paymentLocationGUID = entry.paymentLocationGUID;
                    }
                }

                const detail = {
                    accountNumber: account.accountNumber,
                    balance,
                    paymentLocationGUID,
                    scheduledPaymentAmount: account.amount
                };

                details.push(detail);
            }
        });

        let consumerPaymentMethodGUID = '';
        if (!!this.payment.TokenPaymentMethod) {
            consumerPaymentMethodGUID = this.payment.TokenPaymentMethod.methodToken;
        }

        const request: FutureDatePaymentCreateAgreementRequest = {
            futurePaymentID: this.agentAssistedFuturePaymentID,
            customerAccountID: this.payment.AccountID,
            merchantProfileGUID: this.paymentSelectionCmp.getMerchantProfile().merchantProfileGUID,
            consumerPaymentMethodGUID,
            emailAddress: this.payment.ConfirmationEmail,
            scheduledPaymentAmount: this.payment.RealtimeBalanceToPayOn.PaymentAmount,
            scheduledPaymentDate: this.payment.ScheduledPaymentDate,
            totalBalance: this.payment.RealtimeBalanceToPayOn.PaymentAmount,
            creditCardPaymentMethod: this.payment.CreditCardPaymentMethod,
            achPaymentMethod: this.payment.ACHPaymentMethod,
            address: this.payment.Address,
            nickname: this.payment.PaymentMethodNickname,
            source: 'MSB Ultra',
            status: 'Pending',
            paymentComment: this.comment,
            futureDateAccountPaymentDetails: details
        };

        return request;
    }

    /**
     * Returns true if current selected payment is ACH (bank account)
     * Intended to be consumed by the template for determining when to display revocation language.
     * @memberof PaymentComponent
     */
    isSelectedPaymentMethodAch(): boolean {
        if (this.paymentSelectionCmp) {
            if (this.paymentSelectionCmp.getPaymentSelectionState() &&
                this.paymentSelectionCmp.getPaymentSelectionState().selectedPaymentMethod) {
                return this.paymentSelectionCmp
                    .getPaymentSelectionState()
                    .selectedPaymentMethod
                    .paymentMethodType === PaymentMethodType.ach;
            }
        }

        return false;
    }

    private checkZeroDollarRealtimeBalance(): boolean {
        if (this.payableItemListCmp.payableStatementCmpList.some((x) => !x.isStatementType())) {
            const currentBalanceRecord = this.payableItemListCmp.payableStatementCmpList.find((x) => !x.isStatementType());
            if (currentBalanceRecord.statement.payableAmount <= 0) {
                this.step1Valid = false;
                this.setPageValidationMessage(this.realtimeBalanceZeroErrorText);
                return false;
            }
        }

        return true;
    }

    private checkTotalAmountVsRealtimeBalance(): boolean {
        if (this.payableItemListCmp.payableStatementCmpList.some((x) => !x.isStatementType())) {
            const currentBalanceRecord = this.payableItemListCmp.payableStatementCmpList.find((x) => !x.isStatementType());
            if (this.amountToPay > currentBalanceRecord.statement.payableAmount) {
                this.step1Valid = false;
                this.setPageValidationMessage(this.realtimeBalanceExceededErrorText);
                return false;
            }
        }
    }

    private checkTotalAmountNotZero(): boolean {
        if (this.amountToPay <= 0) {
            this.step1Valid = false;
            this.setPageValidationMessage(this.paymentAmountRequiredErrorText);
            return false;
        }
    }

    private checkDocumentsNonNegative(): boolean {
        if (this.statementsToPay != null && this.statementsToPay.length > 0) {
            if (this.statementsToPay.some(statement =>
                    statement.payableAmount <= 0 && !statement.merchantProfile.allowNegativeDocumentOverpayments)) {
                this.step1Valid = false;
                this.setPageValidationMessage(this.negativeDocumentErrorText);
                return false;
            }
        }
    }

    private checkDocumentsMinimumPayment(): void {
        const statements = this.payableItemListCmp.payableStatementCmpList
            .filter(x => x.statement.payToItem)
            .map(s => s.statement);

        this.validateStatementsPaymentAmounts(statements);
    }

    private checkAccountMinimumPayment(): void {
        this.merchantProfile = this.paymentSelectionCmp.getMerchantProfile();
        if (this.merchantProfile !== null) {
            let totalPayableAmount = 0.00;
            if (this.payableItemsToPay && this.payableItemsToPay.length > 0) {
                this.payableItemsToPay.forEach(item => {
                    totalPayableAmount += item.maxPayableAmount;
                });
            }
            else if (this.payableEntriesToPay && this.payableEntriesToPay.length > 0) {
                this.payableEntriesToPay.forEach(item => {
                    totalPayableAmount += item.maxPayableAmount;
                });
            }

            if (totalPayableAmount !== 0 && this.payableItemListCmp.calculatedSum < this.merchantProfile.minimumDocumentPaymentAmount &&
                totalPayableAmount !== this.payableItemListCmp.calculatedSum) {
                    this.step1Valid = false;
                    let minimumPaymentErrorMessage;

                    if (totalPayableAmount > this.merchantProfile.minimumDocumentPaymentAmount) {
                        minimumPaymentErrorMessage =
                            this.getMinimumPaymentErrorMessage(this.merchantProfile.minimumDocumentPaymentAmount);
                    } else {
                        minimumPaymentErrorMessage = this.getPayInFullErrorMessage(this.merchantProfile.minimumDocumentPaymentAmount);
                    }
                if (this.stepValidationMessages.length === 0) {
                    this.setPageValidationMessage(minimumPaymentErrorMessage);
                }
                else {
                    this.stepValidationMessages.push(minimumPaymentErrorMessage);
                }
            }
        }
    }

    private validateStatementsPaymentAmounts(statements: PayableStatement[]): void {
        statements.forEach(s => {
            if (s.merchantProfile && s.merchantProfile.minimumDocumentPaymentAmount > 0) {
                if (s.paymentAmount < s.merchantProfile.minimumDocumentPaymentAmount) {
                    if ((s.payableAmount < s.merchantProfile.minimumDocumentPaymentAmount &&
                        s.paymentAmount === s.payableAmount) ||
                        (s.promptPayAmount < s.merchantProfile.minimumDocumentPaymentAmount &&
                            s.paymentAmount === s.promptPayAmount && s.promptPayDueDate > new Date())) {
                        return;
                    } else {
                        this.step1Valid = false;
                        const minimumPaymentErrorMessage =
                            this.getMinimumPaymentErrorMessage(s.merchantProfile.minimumDocumentPaymentAmount);
                        this.setPageValidationMessage(minimumPaymentErrorMessage);
                    }
                }
            }
        });
    }

    private getMinimumPaymentErrorMessage(minimumPayment: number): string {
        return this.minimumPaymentErrorMessage.replace(
            '!MINIMUMPAYMENTAMOUNT!',
            this.currencyPipe.transform(minimumPayment, 'USD', 'symbol')
        );
    }

    private getPayInFullErrorMessage(minimumPayment: number): string {
        return this.payInFullErrorMessage.replace(
            '!MINIMUMPAYMENTAMOUNT!',
            this.currencyPipe.transform(minimumPayment, 'USD', 'symbol')
        );
    }

    private checkPaymentListInvalid(): Promise<boolean> {
        // This function returns true when invalid
        return this.payableItemListCmp.arePayableStatementsInvalid().then(payableStatementsAreInvalid => {
            if (payableStatementsAreInvalid) {
                this.step1Valid = false;
                return true;
            } else {
                return false;
            }
        });
    }

    private checkPromptPayEligibility(): boolean {
        const promptPayStatements = this.payableItemListCmp.payableStatementCmpList.filter(
            (x) => x.promptPayIsPossible()).map((y) => y.statement);

        if (promptPayStatements.length > 0 && !this.isWarningMessage) {
            const promptPayShortPayStatements = promptPayStatements.filter((x) => x.payToItem && x.paymentAmount < x.promptPayAmount);
            if (promptPayShortPayStatements.length > 0) {
                this.step1Valid = false;
                this.isWarningMessage = true;
                this.setPageValidationMessages([]);
                promptPayShortPayStatements.forEach((x) => {
                    let tempMessage = this.promptPayShortPayDisclaimer;
                    tempMessage = tempMessage.replace('!PROMPTPAYDATE!', this.datePipe.transform(x.promptPayDueDate, 'MMMM D'));

                    this.addPageValidationMessage(tempMessage);
                });
                return false;
            }
        }
    }

    private paymentAmountValidation(): void {
        this.step1Valid = true;
        this.checkZeroDollarRealtimeBalance();
        this.checkTotalAmountVsRealtimeBalance();
        this.checkTotalAmountNotZero();
        this.checkDocumentsNonNegative();
        this.checkPaymentListInvalid();
        this.checkPromptPayEligibility();

        this.checkDocumentsMinimumPayment();
        if (this.payableItemsToPay || this.payableEntriesToPay) {
            this.checkAccountMinimumPayment();
        }

        if (!this.checkDomainVsDocumentMerchant()) {  // only enter if  document Merchant Is NOT Different Than Domain Merchant
            if (this.step1Valid && this.isOneTimePayment && !this.domainInfo.enableQuickPayWallet) {
                this.paymentSelectionCmp.disableWallet();
            } else if (this.step1Valid) {
                this.paymentSelectionCmp.enableWallet();
            }
        }

        if (!!this.domainInfo && !this.domainInfo.enableWallet) {
            this.paymentSelectionCmp.disableWallet(false);
        }
    }

    checkDomainVsDocumentMerchant(): boolean {
        if (this.statementsToPay) {
            const vendors = this.statementsToPay.map(s => s.merchantProfile.merchantCredentialVendor);
            const isMultiMID = this.consumerService.isProcessorMultiMID(vendors);

            if (!isMultiMID) {
                const differentGUIDDocs =
                    this.statementsToPay.filter((x) => x.merchantCredentialGUID !== this.consumer.defaultMerchantGUID);

                if (differentGUIDDocs.length > 0) {
                    this.paymentSelectionCmp.disableWallet(true);
                    return true;
                }
            }
        }
        return false;
    }

    getEnableComments(): void {
        if (this.statementsToPay != null
            && this.statementsToPay.length > 0
            && this.statementsToPay[0].merchantProfile) {
            this.showComments =
                this.statementsToPay[0].merchantProfile.enableOneTimePayComment &&
                !this.isAgentAssistedPayment;
        } else {
            this.merchantProfile = this.paymentSelectionCmp.getMerchantProfile();
            if (this.merchantProfile !== null) {
                this.showComments = this.merchantProfile.enableOneTimePayComment && !this.isAgentAssistedPayment;
            } else {
                this.showComments = false;
            }
        }
    }

    updatePreviousPaymentsMessage() {
        this.previousPaymentWarning = null;
        if (this.isOneTimePayment && !this.domainInfo.enableQuickPayBalanceDetails) {
            const doc = this.consumer.documents.filter(d => d.mem === this.memCode)[0];

            if (doc && doc.totalPayments > 0) {
                if (doc.totalPayments >= doc.totalAmount) {
                    this.previousPaymentWarning = this.documentPaidInFullWarningText;
                    this.step1Valid = false;
                } else {
                    this.paymentService.getLastPayment(doc.documentID).then(
                        documentPayment => {
                            this.previousPaymentWarning = this.warningPaymentWarningText
                                .replace(/\!TOTALAMOUNT\!/, doc.totalPayments.toFixed(2).toString())
                                .replace(/\!PAYMENTDATE\!/, moment.utc(documentPayment.lastPaymentDate).format('MM/DD/YYYY'));
                        }
                    );
                }
            }
        }
    }

    private paymentMethodValidation(): void {
        this.paymentMethodValid = this.paymentSelectionCmp.isValid();
    }

    private futureDatePaymentValidation(): void {
        if (this.isFutureDateAccountPayment() &&
            this.paymentSelectionState.selectedPaymentMethod.tokenGUID === 'newPaymentMethod' &&
            !this.paymentSelectionState.saveToWallet) {
            this.addPageValidationMessage(this.scheduledPaymentStep2NoWalletErrorText);
            this.futureDatePaymentValid = false;
        } else {
            this.removePageValidationMessage(this.scheduledPaymentStep2NoWalletErrorText);
            this.futureDatePaymentValid = true;
        }
    }

    reviewPaymentValidation(): void {
        this.recaptchaValidation();
        this.paymentAuthorizationValidation();
        this.payorTypeValidation();
        this.emailInputBlur();

        this.step3Valid = !this.showPayorTypeError &&
            this.paymentAuthorization &&
            !this.receiptEmailError &&
            !(this.captchaRequired && (!this.recaptchaAttempted || !this.captchaToken));

        if (!this.step3Valid) {
            this.enableMakePaymentButton();
        }
    }

    private recaptchaValidation(): void {
        if (this.captchaRequired && (!this.recaptchaAttempted || !this.captchaToken)) {
            this.addPageValidationMessage(this.recaptchaErrorMessage);
            this.recaptchaError = true;
        } else {
            this.recaptchaError = false;
        }
    }

    private paymentAuthorizationValidation(): void {
        if (!this.paymentAuthorization) {
            this.addPageValidationMessage(this.paymentAuthErrorText);
        } else {
            this.removePageValidationMessage(this.paymentAuthErrorText);
        }
    }

    private payorTypeValidation(): void {
        if (!!this.payorTypeChoices && !this.payorType) {
            this.showPayorTypeError = true;
            this.addPageValidationMessage(this.payorTypeErrorText);
        } else {
            this.showPayorTypeError = false;
            this.removePageValidationMessage(this.payorTypeErrorText);
        }
    }

    currentStepIsValid(step: number): boolean {
        let stepIsValid: boolean;
        switch (step) {
            case 1:
                stepIsValid = this.step1Valid;
                break;

            case 2:
                stepIsValid = this.step2Valid;
                break;

            case 3:
                stepIsValid = this.step3Valid;
                break;
        }

        return stepIsValid;
    }

    private emailInputBlur(): void {
        // This is an optional field.  It is only triggered on blank or empty whitespace input,
        // and we don't want to show an error until the user has moved out of the field.
        this.receiptEmail = this.receiptEmail.trim();

        this.receiptEmailError = this.receiptEmail && !this.componentService.emailRegex.test(this.receiptEmail);
    }

    clearEmailError(): void {
        this.receiptEmailError = false;
    }

    internalServerError(): void {
        this.canMakePayment = false;
    }

    clearPageError(): void {
        this.setPageValidationMessages([]);
        this.isWarningMessage = false;
        this.currentStepIsValid(this.currentStep);
    }

    detailsLinkShown() {
        if (this.isAgentAssistedPayment) {
            return false;
        }

        // When the only thing the user is paying is their realtime balance, don't show the details link
        const documentStatements = this.statementsToPay.filter((x) => x.itemType === PayableItemType.statement);
        if (documentStatements.length === 0) {
            return false;
        }

        // None of the documents have buckets, don't show the details link
        if (documentStatements.every(x => x.paymentBuckets.length === 0) || (this.zeroAmountFloaterIsTheOnlyBucket(documentStatements))) {
            return false;
        }

        // Don't show details link if Merchant Profile has it disabled
        if (this.paymentSelectionCmp.getMerchantProfile() && !this.paymentSelectionCmp.getMerchantProfile().showDetailBuckets) {
            return false;
        }

        return true;
    }

    displayLastStatementHeader(): boolean {
        if (!!this.payableItemListCmp && !!this.domainInfo) {
            if (this.domainInfo.totalBalanceType === 'Last Statement') {
                if (!this.domainInfo.enablePayableEntries) {
                    return this.payableItemListCmp.payableItems.length === 0 &&
                        !!this.payableItemListCmp.lastStatement;
                } else {
                    if (!!this.payableItemListCmp.payableEntryCmpList) {
                        return this.payableItemListCmp.payableEntryList.length === 0 &&
                            !!this.payableItemListCmp.lastStatement;
                    }
                }
            }
        }

        return false;
    }

    private zeroAmountFloaterIsTheOnlyBucket(statements: PayableStatement[]) {
        return statements.some(x => x.paymentBuckets.length === 1
            && x.paymentBuckets[0].bucketId === FloatingBucket.bucketId
            && x.paymentBuckets[0].bucketPaymentAmount === 0);
    }

    private checkAgentAssistedPayment() {
        return this.componentService.storageService.exists('agentassistedrole');
    }

    private checkOneTimePayment() {
        return this.componentService.storageService.exists('onetimepayment') || this.componentService.storageService.exists('agentassistedrole');
    }

    toggleDetails() {
        this.detailsOpen = !this.detailsOpen;
    }

    private async populateConsumer() {
        this.consumer = await this.consumerService.getConsumer();
        this.updateHeadersWithConsumerName();
        this.loading = false;
        this.updatePreviousPaymentsMessage();
    }

    private updateHeadersWithConsumerName(): void {
        let replaceText = '';

        if (this.consumer.firstName && this.consumer.firstName.trim() !== '') {
            replaceText = this.consumer.firstName.trim();
        } else if (this.consumer.fullName && this.consumer.fullName.trim() !== '') {
            replaceText = this.consumer.fullName.trim();
        }

        this.paymentStep1Header = this.paymentStep1Header.replace('!NAME!', replaceText);
        this.paymentStep1SubHeading = this.paymentStep1SubHeading.replace('!NAME!', replaceText);
        this.paymentStep2Header = this.paymentStep2Header.replace('!NAME!', replaceText);
        this.paymentStep2SubHeading = this.paymentStep2SubHeading.replace('!NAME!', replaceText);
        this.paymentStep3Header = this.paymentStep3Header.replace('!NAME!', replaceText);
        this.paymentStep3SubHeading = this.paymentStep3SubHeading.replace('!NAME!', replaceText);
    }

    private startSubmission(): void {
        this.submission = true;
        this.hasSubmitted = true;
        setTimeout(this.submissionTimeout, 30000);
    }

    isSubmission(): boolean {
        return this.submission;
    }

    private submissionTimeout(): void {
        this.submission = false;
    }

    isLoading(): boolean {
        return this.loading;
    }

    // The way our "Prevent multiple clicking of Make Payment Button" logic works
    // is to immediately disable this button via the logic in DisableAfterClickDirective.
    // Once this has occurred there is no way to programatically go back to this
    // directive to "undo" this, so we have to manually do so in all the places
    // where we declare there is a violation of our payment "rules".
    // This was required once we decided to only show error messages upon clicking
    // the "Make Payment" button.
    private enableMakePaymentButton(): void {
        // This is also important if there is an outage because
        // if there is an outage continueStep3 will not exist.
        if (document.getElementById('continueStep3')) {
            document.getElementById('continueStep3').removeAttribute('disabled');
        }
    }

    // We're not showing AND hiding this as it is unchecked/checked anymore.
    // We are only hiding it when it is checked and letting the "Make Payment"
    // do the showing if it is needed.
    paymentAuthorizationChecked() {
        this.removePageValidationMessage(this.paymentAuthErrorText);
    }

    // Same deal as paymentAuthorizationChecked() except for Payor Type.
    payorTypeChange() {
        this.removePageValidationMessage(this.payorTypeErrorText);
    }

    /**
     * Helper for updating the array of error messages
     * @param {string} msg - The message you want to add to the array
     */
    private addPageValidationMessage(msg: string) {
        if (this.stepValidationMessages.indexOf(msg) < 0) {
            this.stepValidationMessages = this.stepValidationMessages.concat([msg]);
        }
    }

    /**
     * Helper for updating the array of error messages
     * @param msg - The message you want to remove from the array
     */
    private removePageValidationMessage(msg: string) {
        this.stepValidationMessages = this.stepValidationMessages.filter(m => m !== msg);
    }

    /**
     * Helper for resetting the array of error messages
     * @param {string} msg - The message you want to put in the array
     */
    private setPageValidationMessage(msg: string) {
        this.stepValidationMessages = [msg];
    }

    /**
     * Helper for resetting the array of error messages
     * @param messages The messages you want to put in the array
     */
    private setPageValidationMessages(messages: string[]) {
        this.stepValidationMessages = messages;
    }

    /**
     * Helper for replacing content item placeholders on revocation text.
     * Sets value after finding-and-replacement in order to present the message in the template.
     * @memberof PaymentComponent
     */
    private setPaymentRevocationText(content: any): void {
        if (this.checkOneTimePayment()) {
            this.paymentRevocationText = this.componentService.contentService.tryGetContentItem(
                content, 'payment', 'oneTimePayment', 'paymentRevocationLanguage'
            ).text;
        } else {
            this.paymentRevocationText = this.componentService.contentService.tryGetContentItem(
                content, 'payment', 'loggedInPayment', 'paymentRevocationLanguage'
            ).text;
        }

        this.paymentRevocationText = this.paymentRevocationText.replace(/!CONTACTUSTITLE!/g, this.companyName);
        this.paymentRevocationText = this.paymentRevocationText.replace(/!TIMERANGE!/g, this.paymentRevocationTimeRangeText);
    }

    onPaymentSelectionCheckboxChecked() {
        this.paymentSelectionState = this.paymentSelectionCmp.getPaymentSelectionState();
        if (this.isFutureDateAccountPayment() &&
            this.paymentSelectionState.selectedPaymentMethod.tokenGUID === 'newPaymentMethod' &&
            !this.paymentSelectionState.saveToWallet) {
            this.isWarningMessage = false;
            this.addPageValidationMessage(this.scheduledPaymentStep2NoWalletErrorText);
            this.step2Valid = false;
        } else {
            this.removePageValidationMessage(this.scheduledPaymentStep2NoWalletErrorText);
            // We trust that the step will be validated more deeply when user hits "Continue"
            this.step2Valid = true;
        }
    }

    private handleContentItems(content: any) {
        if (this.checkOneTimePayment() && !this.isAgentAssistedPayment) {
            // use one time payment content items
            this.companyName = this.getHelpCI(content, 'contactUs', 'contactUsTitle');
            this.paymentRevocationTimeRangeText = this.getPaymentCI(content, 'oneTimePayment', 'paymentRevocationLanguageTimeRange');
            this.paymentStep1Name = this.getPaymentCI(content, 'oneTimePayment', 'paymentStep1Name');
            this.paymentStep1Header = this.getPaymentCI(content, 'oneTimePayment', 'paymentStep1Header');
            this.paymentStep1SubHeading = this.getPaymentCI(content, 'oneTimePayment', 'paymentStep1SubHeader');
            this.oneTimeBalanceDetailsSubHeading = this.getPaymentCI(content, 'oneTimeBalancePayment', 'paymentStep1SubHeader');
            this.oneTimeBalanceDetailsOffPlanSubHeading = this.getPaymentCI(content, 'oneTimeBalancePayment', 'offPlanChargesSubHeader');
            this.paymentStep2Name = this.getPaymentCI(content, 'oneTimePayment', 'paymentStep2Name');
            this.paymentStep2Header = this.getPaymentCI(content, 'oneTimePayment', 'paymentStep2Header');
            this.paymentStep2SubHeading = this.getPaymentCI(content, 'oneTimePayment', 'paymentStep2SubHeader');
            this.paymentStep3Name = this.getPaymentCI(content, 'oneTimePayment', 'paymentStep3Name');
            this.paymentStep3Header = this.getPaymentCI(content, 'oneTimePayment', 'paymentStep3Header');
            this.paymentStep3SubHeading = this.getPaymentCI(content, 'oneTimePayment', 'paymentStep3SubHeader');
            this.continueButtonText = this.getPaymentCI(content, 'oneTimePayment', 'paymentContinueButton');
            this.backButtonText = this.getPaymentCI(content, 'oneTimePayment', 'paymentBackButton');
            this.makePaymentButtonText = this.getPaymentCI(content, 'oneTimePayment', 'paymentMakePaymentButton');
            this.payHeaderText = this.getPaymentCI(content, 'oneTimePayment', 'paymentPayHeader');
            this.amountHeaderText = this.getPaymentCI(content, 'oneTimePayment', 'paymentAmountHeader');
            this.detailsText = this.getPaymentCI(content, 'oneTimePayment', 'paymentPaymentDetails');
            this.clearSelectionsText = this.getPaymentCI(content, 'oneTimePayment', 'paymentClearSelectionsButton');
            this.paymentAmountText = this.getPaymentCI(content, 'oneTimePayment', 'paymentPaymentAmount');
            this.oneTimePaymentFeeText = this.getPaymentCI(content, 'oneTimePayment', 'paymentPaymentFeeAmount');
            this.oneTimePaymentFeeExtendedText = this.getPaymentCI(content, 'oneTimePayment', 'paymentPaymentExtendedAmount');
            this.paymentDateText = this.getPaymentCI(content, 'oneTimePayment', 'paymentPaymentDateLabel');
            this.paymentMethodText = this.getPaymentCI(content, 'oneTimePayment', 'paymentPaymentMethod');
            this.paymentMethodExpiredErrorText = this.getErrorCI(content, 'oneTimePayment', 'paymentPaymentMethodExpiredError');
            this.paymentAuthText = this.getPaymentCI(content, 'oneTimePayment', 'paymentPaymentAuthorization');
            this.paymentAuthDisclaimerText = this.getPaymentCI(content, 'oneTimePayment', 'paymentPaymentAuthorizationDisclaimer');
            this.paymentAuthErrorText = this.getErrorCI(content, 'oneTimePayment', 'paymentPaymentAuthorizationError');
            this.emailReceiptText = this.getPaymentCI(content, 'oneTimePayment', 'paymentEmailReceipt');
            this.emailReceiptInfoText = this.getPaymentCI(content, 'oneTimePayment', 'paymentEmailReceiptInfo');
            this.commentsText = this.getPaymentCI(content, 'oneTimePayment', 'paymentCommentsLabel');
            this.commentsInfoText = this.getPaymentCI(content, 'oneTimePayment', 'paymentCommentsInfo');
            this.invalidEmailError = this.getErrorCI(content, 'oneTimePayment', 'paymentInvalidEmailError');
            this.updateLinkText = this.getPaymentCI(content, 'oneTimePayment', 'paymentUpdateLinkText');
            this.updateCardLinkText = this.getPaymentCI(content, 'oneTimePayment', 'paymentUpdateCardLinkText');
            this.mySecureWalletInfoLink = this.getPaymentCI(content, 'oneTimePayment', 'paymentmySecureWalletInformationLink');
            this.saveToWalletText = this.getPaymentCI(content, 'oneTimePayment', 'paymentSaveToWalletText');
            this.optionalNicknameText = this.getPaymentCI(content, 'oneTimePayment', 'paymentOptionalNicknameText');
            this.nicknamePlaceholderText = this.getPaymentCI(content, 'oneTimePayment', 'paymentNicknamePlaceholderText');
            this.firstNameText = this.getPaymentCI(content, 'oneTimePayment', 'paymentFirstNameText');
            this.lastNameText = this.getPaymentCI(content, 'oneTimePayment', 'paymentLastNameText');
            this.addressText = this.getPaymentCI(content, 'oneTimePayment', 'paymentAddressText');
            this.nameErrorText = this.getErrorCI(content, 'oneTimePayment', 'paymentNameErrorText');
            this.addressErrorText = this.getErrorCI(content, 'oneTimePayment', 'paymentAddressErrorText');
            this.cityErrorText = this.getErrorCI(content, 'oneTimePayment', 'paymentCityErrorText');
            this.postalCodeErrorText = this.getErrorCI(content, 'oneTimePayment', 'paymentPostalCodeErrorText');
            this.maxCCPaymentErrorText = this.getPaymentCI(content, 'oneTimePayment', 'paymentMaxCreditCardAmountWarningText');
            this.realtimeBalanceZeroErrorText = this.getErrorCI(content, 'oneTimePayment', 'paymentAmountRealtimeZeroErrorText');
            this.realtimeBalanceExceededErrorText = this.getErrorCI(content, 'oneTimePayment', 'paymentAmountRealtimeExceededErrorText');
            this.paymentAmountRequiredErrorText = this.getErrorCI(content, 'oneTimePayment', 'paymentAmountAmountRequiredErrorText');
            this.paymentError = this.getErrorCI(content, 'payment', 'paymentGenericErrorMessage');
            this.domainDocumentMerchantWarning = this.getPaymentCI(content, 'oneTimePayment', 'paymentDomainvsDocumentmerchantmismatch');
            this.negativeDocumentErrorText = this.getPaymentCI(content, 'oneTimePayment', 'paymentNegativeDocumentErrorText');
            this.warningPaymentWarningText = this.getPaymentCI(content, 'oneTimePayment', 'paymentWarningQuickPaymentMessage');
            this.documentPaidInFullWarningText = this.getPaymentCI(content, 'oneTimePayment', 'paymentOverpaymentWarningQuickPayMessage');
            this.payorTypeChoices = this.getPaymentCI(content, 'oneTimePayment', 'paymentPayorTypeChoices');
            this.payorTypeLabelText = this.getPaymentCI(content, 'oneTimePayment', 'paymentPayorTypeLabelText');
            this.payorTypeDescriptionText = this.getPaymentCI(content, 'oneTimePayment', 'paymentPayorTypeDescriptionText');
            this.payorTypeErrorText = this.getPaymentCI(content, 'error', 'paymentPayorTypeErrorText');
            this.freeFormLabelText = this.getPaymentCI(content, 'oneTimePayment', 'paymentFreeFormField');
            this.freeFormDescription = this.getPaymentCI(content, 'oneTimePayment', 'paymentFreeFormFieldDescription');
            this.paymentCCFeeSurchargeExplanation = this.getPaymentCI(content, 'oneTimePayment', 'paymentCCFeeSurchargeExplanation');
        } else {
            this.companyName = this.getHelpCI(content, 'contactUs', 'contactUsTitle');
            this.paymentRevocationTimeRangeText = this.getPaymentCI(content, 'loggedInPayment', 'paymentRevocationLanguageTimeRange');
            this.currentBalanceDisclaimer = this.getPaymentCI(content, 'loggedInPayment', 'paymentCurrentBalanceDisclaimer');
            this.paymentStep1Name = this.getPaymentCI(content, 'loggedInPayment', 'paymentStep1Name');
            this.paymentStep1Header = this.getPaymentCI(content, 'loggedInPayment', 'paymentStep1Header');
            this.paymentStep1SubHeading = this.getPaymentCI(content, 'loggedInPayment', 'paymentStep1SubHeader');
            this.paymentStep2Name = this.getPaymentCI(content, 'loggedInPayment', 'paymentStep2Name');
            this.paymentStep2Header = this.getPaymentCI(content, 'loggedInPayment', 'paymentStep2Header');
            this.paymentStep2SubHeading = this.getPaymentCI(content, 'loggedInPayment', 'paymentStep2SubHeader');
            this.paymentStep3Name = this.getPaymentCI(content, 'loggedInPayment', 'paymentStep3Name');
            this.paymentStep3Header = this.getPaymentCI(content, 'loggedInPayment', 'paymentStep3Header');
            this.paymentStep3SubHeading = this.getPaymentCI(content, 'loggedInPayment', 'paymentStep3SubHeader');
            this.continueButtonText = this.getPaymentCI(content, 'loggedInPayment', 'paymentContinueButton');
            this.backButtonText = this.getPaymentCI(content, 'loggedInPayment', 'paymentBackButton');
            this.makePaymentButtonText = this.getPaymentCI(content, 'loggedInPayment', 'paymentMakePaymentButton');
            this.payHeaderText = this.getPaymentCI(content, 'loggedInPayment', 'paymentPayHeader');
            this.amountHeaderText = this.getPaymentCI(content, 'loggedInPayment', 'paymentAmountHeader');
            this.detailsText = this.getPaymentCI(content, 'loggedInPayment', 'paymentPaymentDetails');
            this.clearSelectionsText = this.getPaymentCI(content, 'loggedInPayment', 'paymentClearSelectionsButton');
            this.paymentAmountText = this.getPaymentCI(content, 'loggedInPayment', 'paymentPaymentAmount');
            this.paymentDateText = this.getPaymentCI(content, 'loggedInPayment', 'paymentPaymentDateLabel');
            this.paymentMethodText = this.getPaymentCI(content, 'loggedInPayment', 'paymentPaymentMethod');
            this.paymentMethodExpiredErrorText = this.getErrorCI(content, 'loggedInPayment', 'paymentPaymentMethodExpiredError');
            this.paymentAuthText = this.getPaymentCI(content, 'loggedInPayment', 'paymentPaymentAuthorization');
            this.paymentAuthDisclaimerText = this.getPaymentCI(content, 'loggedInPayment', 'paymentPaymentAuthorizationDisclaimer');
            this.paymentAuthErrorText = this.getErrorCI(content, 'loggedInPayment', 'paymentPaymentAuthorizationError');
            this.emailReceiptText = this.getPaymentCI(content, 'loggedInPayment', 'paymentEmailReceipt');
            this.emailReceiptInfoText = this.getPaymentCI(content, 'loggedInPayment', 'paymentEmailReceiptInfo');
            this.commentsText = this.getPaymentCI(content, 'loggedInPayment', 'paymentCommentsLabel');
            this.commentsInfoText = this.getPaymentCI(content, 'loggedInPayment', 'paymentCommentsInfo');
            this.invalidEmailError = this.getErrorCI(content, 'loggedInPayment', 'paymentInvalidEmailError');
            this.updateLinkText = this.getPaymentCI(content, 'loggedInPayment', 'paymentUpdateLinkText');
            this.updateCardLinkText = this.getPaymentCI(content, 'loggedInPayment', 'paymentUpdateCardLinkText');
            this.mySecureWalletInfoLink = this.getPaymentCI(content, 'loggedInPayment', 'paymentmySecureWalletInformationLink');
            this.saveToWalletText = this.getPaymentCI(content, 'loggedInPayment', 'paymentSaveToWalletText');
            this.optionalNicknameText = this.getPaymentCI(content, 'loggedInPayment', 'paymentOptionalNicknameText');
            this.nicknamePlaceholderText = this.getPaymentCI(content, 'loggedInPayment', 'paymentNicknamePlaceholderText');
            this.firstNameText = this.getPaymentCI(content, 'loggedInPayment', 'paymentFirstNameText');
            this.lastNameText = this.getPaymentCI(content, 'loggedInPayment', 'paymentLastNameText');
            this.addressText = this.getPaymentCI(content, 'loggedInPayment', 'paymentAddressText');
            this.nameErrorText = this.getErrorCI(content, 'loggedInPayment', 'paymentNameErrorText');
            this.addressErrorText = this.getErrorCI(content, 'loggedInPayment', 'paymentAddressErrorText');
            this.cityErrorText = this.getErrorCI(content, 'loggedInPayment', 'paymentCityErrorText');
            this.postalCodeErrorText = this.getErrorCI(content, 'loggedInPayment', 'paymentPostalCodeErrorText');
            this.maxCCPaymentErrorText = this.getPaymentCI(content, 'loggedInPayment', 'paymentMaxCreditCardAmountWarningText');
            this.realtimeBalanceZeroErrorText = this.getErrorCI(content, 'loggedInPayment', 'paymentAmountRealtimeZeroErrorText');
            this.realtimeBalanceExceededErrorText = this.getErrorCI(content, 'loggedInPayment', 'paymentAmountRealtimeExceededErrorText');
            this.paymentAmountRequiredErrorText = this.getErrorCI(content, 'loggedInPayment', 'paymentAmountAmountRequiredErrorText');
            this.paymentError = this.getErrorCI(content, 'payment', 'paymentGenericErrorMessage');
            this.domainDocumentMerchantWarning = this.getPaymentCI(content, 'loggedInPayment', 'paymentDomainvsDocumentmerchantmismatch');
            this.negativeDocumentErrorText = this.getPaymentCI(content, 'loggedInPayment', 'paymentNegativeDocumentErrorText');
            this.payorTypeChoices = this.getPaymentCI(content, 'loggedInPayment', 'paymentPayorTypeChoices');
            this.payorTypeLabelText = this.getPaymentCI(content, 'loggedInPayment', 'paymentPayorTypeLabelText');
            this.payorTypeDescriptionText = this.getPaymentCI(content, 'loggedInPayment', 'paymentPayorTypeDescriptionText');
            this.payorTypeErrorText = this.getPaymentCI(content, 'error', 'paymentPayorTypeErrorText');
            this.lastStatementStepOneSubHeader = this.getPaymentCI(content, 'loggedInPayment', 'paymentLastStatementStep1SubHeader');
            this.lastStatementStepOneRecentHeader = this.getPaymentCI(content, 'loggedInPayment', 'paymentLastStatementStep1RecentHeader');
            this.freeFormLabelText = this.getPaymentCI(content,  'loggedInPayment', 'paymentFreeFormField');
            this.freeFormDescription = this.getPaymentCI(content, 'loggedInPayment', 'paymentFreeFormFieldDescription');
            this.loggedinPaymentFeeText = this.getPaymentCI(content, 'loggedInPayment', 'paymentPaymentFeeAmount');
            this.loggedinPaymentFeeExtendedText = this.getPaymentCI(content, 'loggedInPayment', 'paymentPaymentExtendedAmount');
            this.paymentCCFeeSurchargeExplanation = this.getPaymentCI(content, 'loggedInPayment', 'paymentCCFeeSurchargeExplanation');
        }

        this.showFreeForm = !!this.freeFormLabelText;
        this.maxAchAmountWarningErrorText = this.getErrorCI(content, 'oneTimePayment', 'paymentMaxACHAmountWarningText');
        this.promptPayShortPayDisclaimer = this.getPaymentCI(content, 'promptpay', 'paymentPromptPayShortPayDisclaimer');
        this.scheduledPaymentStep3Header = this.getPaymentCI(content, 'scheduled', 'paymentStep3Header');
        this.scheduledPaymentStep3SubHeader = this.getPaymentCI(content, 'scheduled', 'paymentStep3SubHeader');
        this.scheduledPaymentAmountText = this.getPaymentCI(content, 'scheduled', 'paymentPaymentAmount');
        this.scheduledPaymentDateText = this.getPaymentCI(content, 'scheduled', 'paymentPaymentDateLabel');
        this.scheduledPaymentStep2NoWalletErrorText = this.getPaymentCI(content, 'scheduled', 'paymentStep2NoWalletErrorText');
        this.postDatedPaymentAuthText = this.getPaymentCI(content, 'scheduled', 'postDatedPaymentPaymentAuth');
        this.postDatedPaymentAuthDisclaimerText = this.getPaymentCI(content, 'scheduled', 'postDatedPaymentPaymentAuthDisclaimer');
        this.recaptchaErrorMessage = this.getErrorCI(content, 'accountPayment', 'accountPaymentRecaptchaError');
        this.paymentScheduledDateError = this.getErrorCI(content, 'scheduled', 'paymentScheduledDateError');

        this.stepList = [
            { stepName: this.paymentStep1Name },
            { stepName: this.paymentStep2Name },
            { stepName: this.paymentStep3Name }
        ];

        this.minimumPaymentErrorMessage = this.getPaymentCI(content, 'error', 'minimumPaymentWarningText');
        this.payInFullErrorMessage =  this.getPaymentCI(content, 'error', 'payinFullWarningText');
    }

    hasRecaptchaError() {
        return this.recaptchaError;
    }

    private getPaymentCI(content: any, subCategory: string, name: string): string {
        return this.getContentItem(content, 'payment', subCategory, name);
    }

    private getHelpCI(content: any, subCategory: string, name: string): string {
        return this.getContentItem(content, 'help', subCategory, name);
    }

    private getErrorCI(content: any, subCategory: string, name: string): string {
        return this.getContentItem(content, 'error', subCategory, name);
    }

    private getContentItem(content: any, category: string, subCategory: string, name: string): string {
        return this.componentService.contentService.tryGetContentItem(
            content,
            category,
            subCategory,
            name,
        ).text;
    }

    private anythingSelectedForPayment(): boolean {
        return (this.isAgentAssistedPayment ||
            !!this.statementsToPay && this.statementsToPay.length > 0) ||
            (!!this.payableItemsToPay && this.payableItemsToPay.length > 0) ||
            (!!this.payableEntriesToPay && this.payableEntriesToPay.length > 0);
    }

    private getEndDate(): string {
        return moment(moment())
            .add(this.futureDateAccountPaymentDays, 'days')
            .toDate()
            .toLocaleDateString();
    }

    private isAchOverMax(): void {
        const paymentMethod = this.paymentSelectionCmp.getPaymentSelectionState().selectedPaymentMethod;
        this.merchantProfile = this.paymentSelectionCmp.getMerchantProfile();

        const isPaymentMethodOverMax = paymentMethod &&
            paymentMethod.paymentMethodType === PaymentMethodType.ach &&
            this.merchantProfile.maxACHPaymentAmount !== 0 &&
            this.amountToPay > this.merchantProfile.maxACHPaymentAmount;

        const isNewPaymentMethodOverMax = paymentMethod.paymentMethodType === PaymentMethodType.ach &&
        this.merchantProfile.maxACHPaymentAmount !== 0 &&
            this.amountToPay > this.merchantProfile.maxACHPaymentAmount;

        // Set error message if saved ACH payment method above the max or if new ACH payment method above max
        if (isPaymentMethodOverMax || isNewPaymentMethodOverMax) {
            this.maxAchAmountWarningErrorText = this.maxAchAmountWarningErrorText.replace(
                '!MAXACHPAYMENTAMOUNT!',
                this.currencyPipe.transform(this.merchantProfile.maxACHPaymentAmount, 'USD', 'symbol', '1.2-2')
            );

            this.setPageValidationMessage(this.maxAchAmountWarningErrorText);

            this.step2Valid = false;
        }
    }

    checkBIN(paymentMethodType: number): boolean {
        return paymentMethodType === 1 &&
            this.merchantProfile.feeProfileID > 0 &&
            this.merchantProfile.performBINValidation;
    }

    savedCreditCard(pm: PaymentMethod): boolean {
        return (
            pm.tokenGUID !== 'newPaymentMethod' &&
            pm.paymentMethodType === 1 &&
            !!pm.paymentMethodId
        );
    }

    newCreditCard(ps: PaymentSelectionStateModel): boolean {
        return (
            ps.selectedPaymentMethod.tokenGUID === 'newPaymentMethod' &&
            ps.creditCardNumber != null &&
            ps.creditCardNumber.trim().length > 0
        )
    }
}
