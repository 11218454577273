import { Component, OnInit } from '@angular/core';
import {StorageService} from '../../services/storage/storage.service';

@Component({
    selector: 'clear-storage',
    template: require('./clearstorage.component.html'),
    styles: [require('./clearstorage.component.css')]
})

export class ClearStorageComponent implements OnInit {

    constructor(private storageService: StorageService) {

    }

    ngOnInit() {
        this.storageService.clearSession();
        this.storageService.clear('domaininfo');
        this.storageService.clear('content');
        this.storageService.clear('statelist');
    }
}
