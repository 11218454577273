import { PaymentMethod, PaymentMethodType, TEST_PAYMENT_METHOD } from './paymentmethod';

/**
 * This is the model to be passed into the payment selection component to set the
 * state and how it displays the payment selection
 *
 * @export
 * @class PaymentSelectionModel
 */
export class PaymentSelectionModel {
    amountToPay = 0.00;
    monthSpanToPay = 0;
    contentItemCategory = 'CATEGORY';
    contentItemSubCategory = 'SUBCATEGORY';
    defaultUpdateAddress = false;
    enableWallet = true;
    enableQuickPayWallet = false;
    isOneTimePayment = false;
    isAccountPayment = false;
    lockSaveToWallet = false;
    paymentDate: string;
    msbPaymentPlanFutureDateLimit = 30;
    showPaymentDate = true;
    enablePaymentDatePicker = false;
    hideSaveToWalletCheckbox = false;
    selectOtherPaymentMethod = false;
}

/**
 * Used to represent the current state of the payment selection component.
 *
 * @export
 * @class PaymentSelectionStateModel
 */
export class PaymentSelectionStateModel {
    firstName = '';
    lastName = '';
    address1 = '';
    address2 = '';
    city = '';
    state = ' ';
    postalCode = '';
    country = ' ';

    creditCardNumber = '';
    creditCardExpirationMonth = '';
    creditCardExpirationYear = '';
    creditCardCvv = '';

    achAccountNumber = '';
    achRoutingNumber = '';
    achAccountType = '';

    newPayment = false;
    saveToWallet = false;
    newPaymentNickname = '';

    otherPaymentType: PaymentMethodType;

    selectedPaymentMethod: PaymentMethod;

    pickerPaymentDate: Date;
}

export const TEST_PAYMENTSELECTIONSTATE_MODEL: PaymentSelectionStateModel = {
    achAccountNumber: '1234',
    achAccountType: 'Checking',
    achRoutingNumber: '1234',
    creditCardNumber: '',
    creditCardCvv: '',
    creditCardExpirationMonth: '',
    creditCardExpirationYear: '',
    address1: '123 test st',
    address2: '',
    city: 'test city',
    state: 'TS',
    country: 'US',
    postalCode: '12345',
    firstName: 'First',
    lastName: 'Last',
    saveToWallet: false,
    newPayment: false,
    newPaymentNickname: '',
    otherPaymentType: PaymentMethodType.credit,
    pickerPaymentDate: new Date(),
    selectedPaymentMethod: TEST_PAYMENT_METHOD
};

export const TEST_PAYMENTSELECTION_MODEL: PaymentSelectionModel = {
    amountToPay: 100,
    contentItemCategory: 'cat',
    contentItemSubCategory: 'subcat',
    defaultUpdateAddress: false,
    enablePaymentDatePicker: false,
    hideSaveToWalletCheckbox: false,
    enableWallet: true,
    enableQuickPayWallet: false,
    isOneTimePayment: false,
    isAccountPayment: false,
    lockSaveToWallet: false,
    monthSpanToPay: null,
    paymentDate: new Date().toISOString(),
    msbPaymentPlanFutureDateLimit: 0,
    showPaymentDate: true,
    selectOtherPaymentMethod: false
};
