export class FutureDateAccountPaymentConfirmation {
    constructor(enableMakePaymentButton?: boolean, logoClickable?: boolean) {
        this.enableMakePaymentButton = enableMakePaymentButton != null ? enableMakePaymentButton : true;
        this.logoClickable = logoClickable != null ? logoClickable : true;
    }
    confirmationNumber: string;
    paymentDate: string;
    paymentAmount: number;
    enableMakePaymentButton: boolean;
    logoClickable: boolean;
}
