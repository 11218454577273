/* tslint:disable:triple-equals */
import { Component, Input, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'msb-button',
    template: require('./msbbutton.component.html'),
    styles: [require('./msbbutton.component.css')]
})

export class MSBButtonComponent implements OnInit {
    @Input() buttonText: string;
    @Input() buttonImage = '';
    @Input() colorForm: string;
    @Input() buttonLink = '';
    @Input() isDisabled = false;
    @Input() buttonType = 'button';
    @Input() isLoading = false;
    @Input() buttonName = '';
    loaderCSSClass = 'loader';

    constructor(
        private parentRouter: Router,
        private ngZone: NgZone
    ) { }

    ngOnInit() {
        // Invert the loading spinner color for primary-white
        if (this.colorForm == 'primary-white') {
            this.loaderCSSClass = 'loader-white';
        }
    }

    onClick(location: string) {
        if (!this.isDisabled) {
            if (this.buttonLink != null && this.buttonLink != '') {
                if (this.buttonLink.startsWith('/')) {
                    this.ngZone.run(() => this.parentRouter.navigateByUrl(this.buttonLink)).then();
                } else {
                    window.open(this.buttonLink, '_blank');
                }
            }
        }
    }

    showLoading(): boolean {
        return this.isLoading;
    }
}
