import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IDomainInfo } from '../../models/domainInfo';
import { ComponentService } from '../../services/component/component.service';
import { ConsumerService } from '../../services/consumer/consumer.service';

@Component({
    selector: 'recurringconfirmation',
    template: require('./recurringconfirmation.component.html'),
    styles: [require('./recurringconfirmation.component.css')]
})
export class RecurringConfirmationComponent implements OnInit, OnDestroy {
    constructor(
        private componentService: ComponentService,
        private consumerService: ConsumerService
    ) { }

    private ngUnsubscribe: Subject<any> = new Subject();

    domainInfo: IDomainInfo;

    recurringEmailAddress: string;
    recurringConfirmationHeader: string;
    recurringConfirmationSubheader: string;

    ngOnInit() {
        this.componentService.scrollPageToTop();

        const role = this.componentService.storageService.retrieve('agentAssistedRole');

        if (role.toLowerCase() === 'recurringexisting') {
            // Existing Recurring Plan content items.
            this.componentService.contentService.content$
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe((content: any) => {
                    this.recurringConfirmationHeader = this.componentService.contentService.tryGetContentItem(
                        content,
                        'recurring',
                        'pageText',
                        'recurringDevicelessEditConfirmationHeader').text;

                    this.recurringConfirmationSubheader = this.componentService.contentService.tryGetContentItem(
                        content,
                        'recurring',
                        'pageText',
                        'recurringDevicelessEditConfirmationSubheader').text;
                });
        }
        if (role.toLowerCase() === 'recurringnew') {
            // New Recurring Plan content items.
            this.componentService.contentService.content$
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe((content: any) => {
                    this.recurringConfirmationHeader = this.componentService.contentService.tryGetContentItem(
                        content,
                        'recurring',
                        'pageText',
                        'recurringDevicelessConfirmationHeader').text;

                    this.recurringConfirmationSubheader = this.componentService.contentService.tryGetContentItem(
                        content,
                        'recurring',
                        'pageText',
                        'recurringConfirmationSubheader').text;
                });
        }

        if (role.toLowerCase() === 'preservicenew') {
            // Existing Recurring Plan content items.
            this.componentService.contentService.content$
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe((content: any) => {
                    this.recurringConfirmationHeader = this.componentService.contentService.tryGetContentItem(
                        content,
                        'preservice',
                        'pageText',
                        'preServiceDevicelessConfirmationHeader').text;

                    this.recurringConfirmationSubheader = this.componentService.contentService.tryGetContentItem(
                        content,
                        'preservice',
                        'pageText',
                        'preServiceDevicelessConfirmationSubHeader').text;
                });
        }
        if (role.toLowerCase() === 'preserviceexisting') {
            // New Recurring Plan content items.
            this.componentService.contentService.content$
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe((content: any) => {
                    this.recurringConfirmationHeader = this.componentService.contentService.tryGetContentItem(
                        content,
                        'preservice',
                        'pageText',
                        'preServiceDevicelessEditConfirmationHeader').text;

                    this.recurringConfirmationSubheader = this.componentService.contentService.tryGetContentItem(
                        content,
                        'preservice',
                        'pageText',
                        'preServiceDevicelessEditConfirmSubHeader').text;
                });
        }

        // recurringEmailAddress is set in RecurringPaymentComponent or MySecureWalletComponent
        if (this.componentService.storageService.exists('recurringEmailAddress')) {
            this.recurringEmailAddress =
                this.componentService.storageService.retrieve(
                    'recurringEmailAddress'
                );

            if (this.recurringEmailAddress) {
                this.recurringConfirmationSubheader =
                    this.recurringConfirmationSubheader.replace(
                        '!EMAILADDRESS!', this.recurringEmailAddress
                    );
            }
        }
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();

        // Called once, before the instance is destroyed.
        this.componentService.storageService.clear(
            'recurringEmailAddress'
        );
    }
}
