import { Injectable } from '@angular/core';

@Injectable()
export class AccountSelectionService {
   _etid: string;

   set etid(value: string) {
      this._etid = value;
   }

   get etid(): string {
       return this._etid;
   }
}
