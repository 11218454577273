/* tslint:disable:triple-equals */
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfig } from '../../app.config';
import { IAccountPaymentResponse } from '../../models/accountpaymentresponse';
import { IBinFeeLookupResponse } from '../../models/binFeeLookupResponse';
import { ConsumerPayment } from '../../models/consumerpayment';
import { ConsumerSurvey } from '../../models/consumersurvey';
import { GenericResponse, Response } from '../../models/genericresponse';
import { IEmailRequest } from '../../models/iemailrequest';
import { IPaymentDocument } from '../../models/paymentdocument';
import { IPaymentValidationResponse } from '../../models/paymentvalidationresponse';
import { ISubmitPaymentResponse } from '../../models/submitpaymentresponse';
import { jsonRequestOptions } from '../../util/HttpClientUtility';
import { ComponentService } from '../component/component.service';
import { LoggingService } from '../logging/logging.service';

@Injectable()
export class PaymentService {
    private domain = '';
    private paymentPath: string;
    private paymentValidationPath: string;
    private paymentSubmissionPath: string;
    private paymentSurveyPath: string;
    private paymentEmailPath: string;
    private getLastPaymentPath: string;
    private paymentBinFeeLookupPath: string;
    private domainUrl = this.config.getConfig('domainInfo');

    submitPaymentError: string;

    constructor(
        private http: HttpClient,
        private config: AppConfig,
        private componentService: ComponentService,
        private loggingService: LoggingService,
        private router: Router
    ) {
        this.domain = this.componentService.storageService.getDomain();
        this.paymentPath = config.getConfig('paymentPath');
        this.paymentValidationPath = config.getConfig('paymentValidationPath');
        this.paymentSubmissionPath = config.getConfig('paymentSubmissionPath');
        this.paymentSurveyPath = config.getConfig('paymentSurveyPath');
        this.paymentEmailPath = config.getConfig('paymentEmailPath');
        this.getLastPaymentPath = config.getConfig('getLastPaymentPath');
        this.paymentBinFeeLookupPath = config.getConfig('paymentBinFeeLookupPath');

        componentService.contentService.content$
            .subscribe((content: any) => {
                this.submitPaymentError = this.componentService.contentService.tryGetContentItem(content, 'payment', 'error', 'paymentSubmitPaymentErrorText').text;
            });
    }

    /**
     * Used to submit the account payment
     *
     * @param {ConsumerPayment} payment
     * @returns {Promise<IAccountPaymentResponse>}
     *
     * @memberof PaymentService
     */
    public async payAccount(payment: ConsumerPayment, captchaToken: string): Promise<IAccountPaymentResponse> {
        return this.callAccountPaymentApi(this.domain, captchaToken, payment);
    }

    /**
     * This method returns the last payment date in a document
     * @param documentID
     */
    public async getLastPayment(documentID: number): Promise<IPaymentDocument> {
        return this.callGetLastPayment(this.domain, documentID);
    }

    /**
     *
     * This is used to validate the payment and return any warning messages, failure or success to the user
     *
     * @param {ConsumerPayment} payment
     * @param {string} token
     * @returns {Promise<IPaymentValidationResponse>}
     *
     * @memberof PaymentService
     */
    public async validatePayment(payment: ConsumerPayment, token: string): Promise<IPaymentValidationResponse> {
        return this.callPaymentValidation(this.domain, payment, token);
    }

    /**
     * used to submit a payment after validation
     *
     * @param {ConsumerPayment} payment
     * @param {string} token
     * @returns {Promise<IPaymentValidationResponse>}
     *
     * @memberof PaymentService
     */
    public async submitPayment(captchaToken: string, payment: ConsumerPayment, token: string): Promise<ISubmitPaymentResponse> {
        return this.callSubmitPayment(this.domain, captchaToken, payment, token);
    }

    /**
     * Submit a survey
     *
     * @param {ConsumerSurvey} survey  - the survey to submit
     * @returns {Promise<boolean>} - success
     * @memberof PaymentService
     */
    public async submitSurvey(survey: ConsumerSurvey): Promise<boolean> {
        return this.callSubmitSurvey(this.domain, survey);
    }

    /**
     * Send an email after a payment is successful
     *
     * @param {IEmailRequest} email - The email request, which is address and referenceId
     * @param {string} token  - the user for authorizing the email
     * @returns {Promise<boolean>}  - useless boolean on success
     * @memberof PaymentService
     */
    public async submitEmail(email: IEmailRequest, token: string): Promise<boolean> {
        return this.callSubmitEmail(this.domain, email, token);
    }

    /**
     *
     * @param customerAccountID
     * @param cardNumber
     * @param paymentMethodGUID
     * @param type
     * @param token
     * @param amount
     * @param merchantProfileGUID
     */
    public async getBinFeeLookup(
        customerAccountID: string,
        cardNumber: string,
        paymentMethodGUID: string,
        type: string,
        token: string,
        amount: number,
        merchantProfileGUID: string
    ): Promise<IBinFeeLookupResponse> {
        return this.callGetBinFeeLookup(customerAccountID, cardNumber, paymentMethodGUID, type, token, amount, merchantProfileGUID);
    }

    /**
     * Used to send the account payment to the server
     *
     * @private
     * @param {string} domain
     * @param {ConsumerPayment} payment
     * @returns {Promise<IAccountPaymentResponse>}
     *
     * @memberof PaymentService
     */
    private async callAccountPaymentApi(domain: string, captchaToken: string, payment: ConsumerPayment): Promise<IAccountPaymentResponse> {
        const path = this.paymentPath;
        const body = {
            domain,
            captchaToken,
            payment,
            path
        };

        return this.http.post(this.domainUrl, { body }, jsonRequestOptions())
            .toPromise()
            .then((response: Response<IAccountPaymentResponse>) => response.body)
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    /**
     *
     * @param customerAccountID
     * @param cardNumber
     * @param paymentMethodGUID
     * @param type
     * @param token
     * @param amount
     * @param merchantProfileGUID
     */
    private async callGetBinFeeLookup(
        customerAccountID: string,
        cardNumber: string,
        paymentMethodGUID: string,
        type: string,
        token: string,
        amount: number,
        merchantProfileGUID: string
    ): Promise<IBinFeeLookupResponse> {
        const path = this.paymentBinFeeLookupPath;
        const body: any = {};
        body.bypass = true;
        body.customerAccountID = customerAccountID;
        body.cardNumber = cardNumber;
        body.paymentMethodGUID = paymentMethodGUID;
        body.type = type;
        body.path = path;
        body.UrlAccessKey = token;
        body.amount = amount;
        body.merchantProfileGUID = merchantProfileGUID;

        return this.http.post(this.domainUrl, { body }, jsonRequestOptions())
            .toPromise()
            .then((response: Response<string>) => response.body.data)
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    /**
     * Get the last payment of a document
     * @param domain
     * @param documentID
     */
    private async callGetLastPayment(domain: string, documentID: number): Promise<IPaymentDocument> {
        const token = this.componentService.storageService.retrieve('token');
        const path: string = this.domainUrl + '?relativePath=' +
            encodeURIComponent(token + '/' + this.getLastPaymentPath + '/' + documentID) + '&requireCredentials=true';

        return this.http.get(path, jsonRequestOptions())
            .toPromise()
            .then((response: Response<IPaymentDocument>) => response.body)
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    /**
     * This is used to send validate request of the payment and return any warning messages, failure or success to the user
     *
     * @param {string} domain
     * @param {ConsumerPayment} payment
     * @param {string} token
     * @returns {Promise<IPaymentValidationResponse>}
     *
     * @memberof PaymentService
     */
    private async callPaymentValidation(domain: string, payment: ConsumerPayment, token: string): Promise<IPaymentValidationResponse> {
        const path = this.paymentValidationPath;
        const body: any = {};
        body.bypass = true;
        body.domain = domain;
        body.path = path;
        body.UrlAccessKey = token;
        // tslint:disable-next-line: forin
        for (const i in payment) {
            body[i] = payment[i];
        }

        return this.http.post(this.domainUrl, { body }, jsonRequestOptions())
            .toPromise()
            .then((response: HttpResponse<IPaymentValidationResponse>) => response.body)
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    /**
     * used to submit a payment to the consumer API
     *
     * @param {string} domain
     * @param {ConsumerPayment} payment
     * @param {string} token
     * @returns {Promise<IPaymentValidationResponse>}
     *
     * @memberof PaymentService
     */
    // tslint:disable-next-line: max-line-length
    private async callSubmitPayment(domain: string, captchaToken: string, payment: ConsumerPayment, token: string): Promise<ISubmitPaymentResponse> {
        const path = this.paymentSubmissionPath;
        const body: any = {};
        body.bypass = true;
        body.captchaToken = captchaToken;
        body.domain = domain;
        body.path = path;
        body.UrlAccessKey = token;
        // tslint:disable-next-line: forin
        for (const i in payment) {
            body[i] = payment[i];
        }

        return this.http.post(this.domainUrl, { body }, jsonRequestOptions())
            .toPromise()
            .then((response: HttpResponse<ISubmitPaymentResponse>) => {
                return response.body;
            })
            .catch((response: GenericResponse) => {
                return this.loggingService.handleError(response);
            });
    }

    private async callSubmitSurvey(domain: string, survey: ConsumerSurvey): Promise<boolean> {
        const path = this.paymentSurveyPath;
        const body: any = {};
        survey.Source = 'MSB Ultra'; // Defaulted value
        survey.Version = 3; // Defaulted value
        body.bypass = true;
        body.domain = domain;
        body.path = path;
        // tslint:disable-next-line: forin
        for (const i in survey) {
            body[i] = survey[i];
        }

        return this.http.post(this.domainUrl, { body }, jsonRequestOptions())
            .toPromise()
            .then((response: GenericResponse) => {
                return response.ok;
            })
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    private async callSubmitEmail(domain: string, email: IEmailRequest, token: string): Promise<boolean> {
        const path = this.paymentEmailPath;
        const body: any = {};
        body.bypass = true;
        body.domain = domain;
        body.path = path;
        body.UrlAccessKey = token;
        // tslint:disable-next-line: forin
        for (const i in email) {
            body[i] = email[i];
        }
        return this.http.post(this.domainUrl, { body }, jsonRequestOptions())
            .toPromise()
            .then((response: HttpResponse<null>) => {
                return response.ok;
            })
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    /**
     * Check for an outage
     *
     * @returns {Promise<boolean>}
     * @memberof PaymentService
     */
    public async checkOutage(): Promise<boolean> {
        const outageList = await this.componentService.domainService.getOutages();

        if (outageList
                .some(x =>
                    new Date(x.outageStartDate) < new Date() &&
                    x.system === 'MySecureBill Maintenance'
                )
        ) {
            return !this.componentService.letMeIn();
        }

        return false;
    }
}
