/* tslint:disable:triple-equals */
import { Component, Input, OnInit } from '@angular/core';
import { StatementHistoryPayment } from '../../../models/statementhistorypayment';
import { ComponentService } from '../../../services/component/component.service';

@Component({
    selector: 'statement-history-payment',
    template: require('./statementhistorypayment.component.html'),
    styles: [require('./statementhistorypayment.component.css'), require('../Statement/statementhistorystatement.component.css')]
})

export class StatementHistoryPaymentComponent implements OnInit {
    @Input() payment: StatementHistoryPayment;
    paymentCheckboxName: string;

    constructor(private componentService: ComponentService) { }

    ngOnInit() {

        this.paymentCheckboxName = 'downloadPayment' + this.payment.consumerPaymentGUID;
    }

    hideAccountChars(): number {
        return this.payment.merchantProfile != null ? this.payment.merchantProfile.hideAccountChars : 0;
    }

    getPaymentAmount(): number {
        let paymentAmount = 0;
        if (this.payment.details != null && this.payment.details.filter(
            (detail) => detail.documentID == this.payment.documentID).length > 0) {
            paymentAmount = this.payment.details.filter(
                (detail) => detail.documentID == this.payment.documentID).reduce((sum, val) => sum + Math.abs(val.amount), 0);
        } else {
            paymentAmount = this.payment.totalAmount;
        }
        return paymentAmount;
    }

    viewPaymentReceipt() {
        if (this.payment.paymentPdfUrl != null && this.payment.paymentPdfUrl != '') {
            window.open(this.payment.paymentPdfUrl, '_blank');
        }
    }
}
