/* tslint:disable:triple-equals */
import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PaymentSelectionComponent } from '../../../components/Controls/PaymentSelection/paymentselection.component';
import { PaymentMethodType } from '../../../models/paymentmethod';
import { PaymentSelectionModel } from '../../../models/paymentselectionmodel';
import { ComponentService } from '../../../services/component/component.service';
import { ModifiedAccountPaymentStateService } from '../../../services/modifiedaccountpayment/modifiedaccountpayment.state.service';
import { PaymentSelectionStateService } from '../../../services/paymentselection/paymentselection.state.service';

@Component({
    selector: 'modified-account-payment',
    template: require('./modifiedaccountpaymentmethod.component.html'),
    styles: [require('./modifiedaccountpaymentmethod.component.css')]
})

export class ModifiedAccountPaymentMethodComponent implements OnInit {
    private ngUnsubscribe: Subject<any> = new Subject();
    headerText: string;
    subHeaderText: string;
    backButtonText: string;
    continueButtonText: string;
    navigatePrviousStep: string;
    navigateNextStep: string;

    modelToPassToPayment: PaymentSelectionModel = {
        amountToPay: 0,
        isOneTimePayment: true,
        enableWallet: false,
        defaultUpdateAddress: true,
        contentItemCategory: 'payment',
        contentItemSubCategory: 'accountPayment'
    } as PaymentSelectionModel;

    @ViewChild('paymentSelectionCmp', { static: true }) paymentSelectionCmp: PaymentSelectionComponent;

    // public stateService so we can use it in the UI directly
    constructor(
        public stateService: ModifiedAccountPaymentStateService,
        private paymentSelectionStateService: PaymentSelectionStateService,
        private componentService: ComponentService,
        private parentRouter: Router,
        private ngZone: NgZone
    ) { }

    ngOnInit() {
        this.componentService.contentService.content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((content: any) => {
                this.headerText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'accountPayment', 'paymentStep2Header').text;
                this.subHeaderText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'accountPayment', 'paymentStep2SubHeader').text; this.continueButtonText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'accountPayment', 'paymentContinueButton').text;
                this.backButtonText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'accountPayment', 'paymentBackButton').text;
            });

        this.stateService.currentStep = 2;
        this.modelToPassToPayment.amountToPay = this.stateService.paymentAmount;
        this.populateNavigationSteps();
    }

    async ngAfterViewInit() {
        if (!this.stateService.amountStepValid) {
            this.ngZone.run(() => this.parentRouter.navigateByUrl(this.navigatePrviousStep)).then();
        }

        this.stateService.forceValidateMethodStep$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(update => {
            if (update) {
                this.stateService.methodStepValid = this.methodEntryIsValid();
            }
        });

        if (this.stateService.merchantProfile) {
            this.paymentSelectionCmp.setMerchantProfile(this.stateService.merchantProfile, false, false);
        }
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    prevStep() {
        this.ngZone.run(() => this.parentRouter.navigateByUrl(this.navigatePrviousStep)).then();
    }

    nextStep() {
        // validate step, then move on to review page
        this.paymentSelectionCmp.step2Valid = true;
        if (this.paymentSelectionCmp.selectedOtherPaymentType() == PaymentMethodType.credit) {
            this.paymentSelectionCmp.prepareCreditCard();
            this.stateService.paymentMethodType = PaymentMethodType.credit;
        } else if (this.paymentSelectionCmp.selectedOtherPaymentType() == PaymentMethodType.ach) {
            this.paymentSelectionCmp.checkACH();
            this.stateService.paymentMethodType = PaymentMethodType.ach;
        }

        this.stateService.paymentSource = this.paymentSelectionCmp.getPaymentSource();
        this.stateService.checkMethodStepValid();
        if (this.stateService.methodStepValid) {
            this.paymentSelectionStateService.paymentSelectionState = this.paymentSelectionCmp.componentState;
            this.ngZone.run(() => this.parentRouter.navigateByUrl(this.navigateNextStep)).then();
        }
    }

    methodEntryIsValid(): boolean {
        return (this.paymentSelectionCmp && this.paymentSelectionCmp.step2Valid);
    }

    populateNavigationSteps(): void {
        const mode = this.componentService.storageService.retrieve('mode');
        if (mode !== 'fasttrack') {
            this.navigateNextStep = '/careenroll/review';
            this.navigatePrviousStep = '/careenroll/amount';
        } else {
            this.navigateNextStep = '/fasttrackpayment/review';
            this.navigatePrviousStep = '/fasttrackpayment/amount';
        }
    }

}
