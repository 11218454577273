import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as FileSaver from 'file-saver';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppConfig } from '../../app.config';
import { ArchiveZipFile } from '../../models/archivezipfile';
import { IConsumer } from '../../models/consumer';
import { IDocument } from '../../models/document';
import { IDomainInfo } from '../../models/domaininfo';
import { MaterialType } from '../../models/material';
import { IPayment } from '../../models/payment';
import { CreditCardIssuerType, PaymentMethod, PaymentMethodType } from '../../models/paymentmethod';
import { Roles } from '../../models/roles';
import { StatementHistoryPayment } from '../../models/statementhistorypayment';
import { StatementHistoryStatement } from '../../models/statementhistorystatement';
import { ComponentService } from '../../services/component/component.service';
import { ConsumerService } from '../../services/consumer/consumer.service';
import { LoggingLevel } from '../../services/logging/logging.service';
import { PayableService } from '../../services/payable/payable.service';

interface ISortedStatements {
    [key: string]: StatementHistoryStatement[];
}

@Component({
    selector: 'statement-history',
    template: require('./statementhistory.component.html'),
    styles: [require('./statementhistory.component.css')]
})
export class StatementHistoryComponent implements OnInit, OnDestroy {
    constructor(
        private componentService: ComponentService,
        private consumerService: ConsumerService,
        private config: AppConfig,
        private payableSerivce: PayableService,
        private formBuilder: FormBuilder
    ) {}
    private ngUnsubscribe: Subject<any> = new Subject();
    canMakePayment = false;
    isLoaded = false;

    strStartDate = 'startDate';
    strEndDate = 'endDate';

    dateRangeForm: FormGroup;
    dateRangeError: boolean;
    invalidDateErrorText: string;

    consumer: IConsumer;
    yearList: Set<number>;
    statementHistory: StatementHistoryStatement[] = [];
    paymentHistory: StatementHistoryPayment[] = [];
    statementsByYear: ISortedStatements = {};
    statementDisplay: StatementHistoryStatement[];
    paymentButtonText: string;
    statementHistoryHeader: string;
    statementHistorySubHeader: string;
    noHistoryFoundForRangeText: string;
    downloadText: string;
    downloadButtonText: string;
    downloadingText: string;
    downloadErrorText: string;
    downloadWarningTextTemplate: string;
    displayedWarningText: string;
    paymentButtonLink: string;
    domainInfo: IDomainInfo;
    loading = false;
    filesDownloading = false;
    downloadError = false;
    showPartialDownloadWarning = false;
    noConsumerStatementHistoryRole = false;
    showPaidColumn = true;

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit() {
        this.dateRangeForm = this.formBuilder.group({
            startDate: new FormControl(moment(new Date()).subtract(6, 'months').toDate(), Validators.required),
            endDate: new FormControl(new Date(), Validators.required)
        });

        this.componentService.scrollPageToTop();
        this.payableSerivce.canMakePayment().then(can => this.canMakePayment = can);
        this.startLoading();
        this.componentService.contentService.content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((content: any) => {
                this.paymentButtonText = this.componentService.contentService.tryGetContentItem(content, 'home', 'statement', 'homePageStatementMakePaymentButton').text;
                this.statementHistoryHeader = this.componentService.contentService.tryGetContentItem(content, 'statements', 'pageText', 'statementHistoryHeader').text;
                this.statementHistorySubHeader = this.componentService.contentService.tryGetContentItem(content, 'statements', 'pageText', 'statementHistorySubHeader').text;
                this.noHistoryFoundForRangeText = this.componentService.contentService.tryGetContentItem(content, 'statements', 'pageText', 'statementHistoryNoHistoryForRangeText').text;
                this.downloadText = this.componentService.contentService.tryGetContentItem(content, 'statements', 'pageText', 'statementHistoryDownloadButtonText').text;
                this.downloadingText = this.componentService.contentService.tryGetContentItem(content, 'statements', 'pageText', 'statementHistoryDownloadingText').text;
                this.downloadErrorText = this.componentService.contentService.tryGetContentItem(content, 'error', 'statements', 'statementHistoryDownloadErrorText').text;
                this.downloadWarningTextTemplate = this.componentService.contentService.tryGetContentItem(content, 'error', 'statements', 'statementHistoryDownloadWarningText').text;
                this.invalidDateErrorText = this.componentService.contentService.tryGetContentItem(content, 'global', 'error', 'genericInvalidDateSelectedText').text;
                this.downloadButtonText = this.downloadText;
            });

        if (this.componentService.storageService.exists('noconsumerstatementhistoryrole')) {
            this.noConsumerStatementHistoryRole = this.componentService.storageService.retrieve('noconsumerstatementhistoryrole');
            // This triggers header and footer to hide Consumer Account elements
            this.componentService.storageService.store('onetimepayment', true);
        }

        // TO DO: try to use Promesi.All and the call configurePageForRole() once.
        this.consumerService.getConsumer().then((consumer) => {
            this.consumer = consumer;
            this.consumerService.getConsumerPaymentHistory().then((payments) => {
                this.bindStatementAndPaymentViewModels(consumer.documents, payments);
                this.buildHistoryList();
                this.configurePageForRole();
            });
            this.updateHeadersWithConsumerName();

            this.componentService.domainService.domainInfo$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(domainInfo => {
                this.domainInfo = domainInfo;
                this.componentService.getPaymentUrlForDomain().then(url => this.paymentButtonLink = url);
                this.configurePageForRole();
            });
        });
    }

    startDateChange(event: any) {
        // Reset on date change.
        this.dateRangeError = false;

        if (this.dateRangeValid(event.target.value, true)) {
            if (!this.dateRangeError) {
                this.onStatementDateChanged(
                    new Date(event.target.value),
                    new Date(this.dateRangeForm.controls[this.strEndDate].value)
                );
            }
        } else {
            this.dateRangeError = true;
            this.statementDisplay = [];
            this.showStatements();
        }
    }

    endDateChange(event: any) {
        // Reset on date change.
        this.dateRangeError = false;

        // DatePicker is going to return a null value if the User enters an invalid date.
        if (this.dateRangeValid(event.target.value, false)) {
            if (!this.dateRangeError) {
                this.onStatementDateChanged(
                    new Date(this.dateRangeForm.controls[this.strStartDate].value),
                    new Date(event.target.value)
                );
            }
        } else {
            this.dateRangeError = true;
            this.statementDisplay = [];
            this.showStatements();
        }
    }

    dateRangeValid(dateFromUI: any, isStartDate: boolean): boolean {
        // DatePicker is going to return a null value if the User enters a garbage date.
        if (dateFromUI) {
            let startDate = new Date();
            let endDate = new Date();

            if (isStartDate) {
                startDate = new Date(dateFromUI);
                endDate = new Date(this.dateRangeForm.controls[this.strEndDate].value);
            } else {
                startDate = new Date(this.dateRangeForm.controls[this.strStartDate].value);
                endDate = new Date(dateFromUI);
            }

            if (startDate.getTime() > endDate.getTime()) {
                return false;
            }

            return true;
        }
    }

    onStatementDateChanged(startDate: Date, endDate: Date) {
        // Users will not necessarily put the older date in the
        // first  DatePicker, so we need to verify which is older
        startDate = startDate < endDate ? startDate : endDate;
        endDate = startDate < endDate ? endDate : startDate;

        if (this.isLoaded) {
            // There won't be any checkboxes to clear on load
            this.clearCheckboxes();
        }
        this.isLoaded = true;

        this.componentService.loggingService.log(
            'StatementHistoryComponent->DateRangeChanged start->' + startDate,
            LoggingLevel.debug
        );

        this.componentService.loggingService.log(
            'StatementHistoryComponent->DateRangeChanged end->' + endDate,
            LoggingLevel.debug
        );

        this.componentService.loggingService.log(
            'StatementHistoryComponent->DateRangeChanged to->' + startDate + ' to ' + endDate,
            LoggingLevel.debug
        );

        this.startLoading();
        this.buildHistoryList();
    }

    downloadHistory(): void {
        // Don't request more files if they have no items selected or they're downloading currently
        if (this.downloadDisabled() || this.filesDownloading) {
            return;
        }

        this.clearErrorsAndWarnings();
        const dateString = new Date().toISOString().substr(0, 10).replace(/-/g, '');
        let listofStatementGUIDs = this.statementDisplay.filter(
            (s) => s.documentGUID !== this.componentService.NULL_GUID && s.downloadStatement).map((statement) => statement.documentGUID);

        let listofPaymentGUIDs: string[] = [];
        let listofMaterialInsertGUIDs: string[] = [];
        this.statementDisplay.forEach((statement) => {
            if (statement.payments != null && statement.payments.length > 0) {
                statement.payments.forEach((payment) => {
                    if (payment.downloadPayment) {
                        listofPaymentGUIDs.push(payment.consumerPaymentGUID);
                    }
                });
            }
            if (statement.downloadStatement && statement.materials != null && statement.materials.length > 0) {
                const inserts = statement.materials.filter((f) => MaterialType[f.type.toString().toLowerCase()] === MaterialType.insert);
                if (inserts && inserts.length > 0) {
                    inserts.forEach((insert) => {
                        listofMaterialInsertGUIDs.push(insert.materialGUID);
                    });
                }
            }
        });

        listofPaymentGUIDs = this.componentService.removeDuplicatesFromList(listofPaymentGUIDs);
        listofStatementGUIDs = this.componentService.removeDuplicatesFromList(listofStatementGUIDs);
        listofMaterialInsertGUIDs = this.componentService.removeDuplicatesFromList(listofMaterialInsertGUIDs);

        this.componentService.loggingService.log(
            'StatementHistoryComponent->downloadHistory->statementGUIDs', LoggingLevel.debug, listofStatementGUIDs);
        this.componentService.loggingService.log(
            'StatementHistoryComponent->downloadHistory->paymentGUIDs', LoggingLevel.debug, listofPaymentGUIDs);
        this.componentService.loggingService.log(
            'StatementHistoryComponent->downloadHistory->materialInsertGUIDs', LoggingLevel.debug, listofMaterialInsertGUIDs);
        this.startDownloading();

        if (listofPaymentGUIDs.length === 1 && listofStatementGUIDs.length === 0 && listofMaterialInsertGUIDs.length === 0) {
            this.componentService.loggingService.log(
                'StatementHistoryComponent->downloadHistory->callingSingleReceipt', LoggingLevel.debug);

            // Only one payment selected, call that method
            this.componentService.archiveService.getArchiveReceiptPdf(listofPaymentGUIDs[0]).then(response => {
                this.downloadFile(response, 'pdf', dateString + '_Receipt.pdf');
            }).catch(error => {
                this.handleDownloadError(error);
            });
        } else if (listofStatementGUIDs.length === 1 && listofPaymentGUIDs.length === 0 && listofMaterialInsertGUIDs.length === 0) {
            this.componentService.loggingService.log(
                'StatementHistoryComponent->downloadHistory->callingSingleDocument', LoggingLevel.debug);

            // Only one document selected, call that method
            this.componentService.archiveService.getArchiveDocumentPdf(listofStatementGUIDs[0]).then(response => {
                this.downloadFile(response, 'pdf', dateString + '_Statement.pdf');
            }).catch(error => {
                this.handleDownloadError(error);
            });
        } else {
            this.componentService.loggingService.log('StatementHistoryComponent->downloadHistory->callingZip', LoggingLevel.debug);

            // Multiples selected, call history method
            const fileCount = listofStatementGUIDs.length + listofPaymentGUIDs.length + listofMaterialInsertGUIDs.length;
            this.componentService.archiveService.getArchiveHistory(
                listofStatementGUIDs, listofPaymentGUIDs, listofMaterialInsertGUIDs).then(response => {
                this.downloadFile(response, 'zip', dateString + '_Documents.zip', fileCount);
            }).catch(error => {
                this.handleDownloadError(error);
            });
        }
    }

    buildHistoryList(): void {
        const startDate: Date = new Date (this.dateRangeForm.controls[this.strStartDate].value);
        const endDate: Date = new Date (this.dateRangeForm.controls[this.strEndDate].value);
        this.statementDisplay = null;

        let itemsInRange = cloneDeep(this.statementHistory.filter((document) =>
           new Date (document.documentDate).getTime() >= startDate.getTime() &&
           new Date (document.documentDate).getTime() <= endDate.getTime() &&
           document.displayable
        ));

        itemsInRange = itemsInRange.sort((a, b) => ComponentService.sortDateDescending(a.documentDate, b.documentDate));
        this.componentService.loggingService.log('StatementHistoryComponent->buildHistoryList() ' + itemsInRange.length.toString() + ' statements/payments in date range', LoggingLevel.debug);

        this.showStatements(itemsInRange);
        this.doneLoading();
    }

    showStatements(statements: StatementHistoryStatement[] = []) {
        this.statementDisplay = statements;

        if (statements) {
            this.yearList = this.getYearsFromStatements();
            this.statementsByYear = this.groupStatementsByYear(this.statementDisplay);
        }
    }

    noHistoryFound() {
        if (!this.statementDisplay) {
            return true;
        }

        return (this.statementDisplay.length === 0);
    }

    bindStatementAndPaymentViewModels(documents: IDocument[], payments: IPayment[]) {
        this.createStatementHistoryPayments(payments); // populate this.paymentHistory with results from getConsumerPaymentHistory API call
        // loops consumer.documents and links to payments based list consumerPaymentGUIDs in the document
        this.createStatementHistoryStatementsAndLinkToPayments(documents);
        // adds a row to the this.statementHistory list so an unattached payment ('Account' or 'Refund') can be show in its own row
        this.createFakeStatementsForPaymentsAndAddToList();
        this.getMerchantProfilesForAllItems();
    }

    createStatementHistoryStatementsAndLinkToPayments(documents: IDocument[]) {
        const getDocumentUrl: string = this.config.getConfig('documentPdfPath');
        documents.forEach((document: IDocument) => {
            const statement: StatementHistoryStatement = {
                documentID: document.documentID,
                accountNumber: document.accountNumber,
                documentDate: document.documentDate,
                customerLOBName: document.customerLOBName,
                documentGUID: document.documentGUID,
                totalAmount: document.totalAmount,
                totalPayments: document.totalPayments,
                downloadStatement: false,
                payments: null,
                statementPdfUrl: null,
                materials: document.materials,
                merchantProfileGUID: document.merchantProfileGUID,
                merchantProfile: null,
                displayable: this.statementCanBeDisplayed(document)
            };

            statement.statementPdfUrl = getDocumentUrl + '?t='
                + this.componentService.storageService.retrieve('token') + '&did=' + document.documentGUID + '&v=MySecureBill';

            const payments: StatementHistoryPayment[] = [];
            document.consumerPaymentGUIDs.forEach((guid) => {
                const matchedPayment = this.paymentHistory.find(payment => payment.consumerPaymentGUID === guid);
                if (matchedPayment != null) {
                    const paymentToLink = Object.assign(new StatementHistoryPayment(), matchedPayment);
                    paymentToLink.documentID = document.documentID;
                    payments.push(paymentToLink);
                }
            });

            // gets payments that are not in the consumer object in storage
            const unattachedPayments: StatementHistoryPayment[] = this.paymentHistory.filter(
                (payment) => document.consumerPaymentGUIDs.indexOf(payment.consumerPaymentGUID) === -1);
            const accountPaymentsRefunds: StatementHistoryPayment[] = [];

            // loops through unattached payments and links them to statements if the payment has a documentID
            unattachedPayments.forEach((unattachedPayment) => {
                if (unattachedPayment.details
                    && unattachedPayment.details.length > 0
                    && unattachedPayment.details.some(detail => !!detail.documentID && detail.documentID === document.documentID)) {
                    const unattachedPaymentToLink = Object.assign(new StatementHistoryPayment(), unattachedPayment);
                    unattachedPaymentToLink.documentID = document.documentID;
                    payments.push(unattachedPaymentToLink);
                } else {
                    accountPaymentsRefunds.push(unattachedPayment);
                }
            });

            // re-assign this.paymentHistory to remaining unattached payments ('Account' and 'Refund')
            // since all other payments are now linked to statements
            this.paymentHistory = accountPaymentsRefunds;
            statement.payments = payments.sort(
                (a: StatementHistoryPayment, b: StatementHistoryPayment) =>
                    ComponentService.sortDateDescending(a.paymentDate, b.paymentDate));
            this.statementHistory.push(statement);
        });
    }

    createStatementHistoryPayments(payments: IPayment[]) {
        let statementHistoryPayment: StatementHistoryPayment;
        payments.forEach((consumerPayment) => {
            statementHistoryPayment = new StatementHistoryPayment();
            statementHistoryPayment.accountName = consumerPayment.accountName;
            statementHistoryPayment.accountNumber = consumerPayment.accountNumber;
            statementHistoryPayment.consumerPaymentGUID = consumerPayment.consumerPaymentGUID;
            statementHistoryPayment.status = consumerPayment.status;
            statementHistoryPayment.paymentDate = consumerPayment.paymentDate;
            statementHistoryPayment.customerAccountID = consumerPayment.customerAccountID;
            statementHistoryPayment.totalAmount = consumerPayment.totalAmount;
            statementHistoryPayment.source = consumerPayment.source;
            statementHistoryPayment.customerID = consumerPayment.customerID;
            statementHistoryPayment.domainID = consumerPayment.domainID;
            statementHistoryPayment.method = consumerPayment.method;
            statementHistoryPayment.type = consumerPayment.type;
            statementHistoryPayment.cardType = consumerPayment.cardType;
            statementHistoryPayment.paymentMethodLast4 = consumerPayment.paymentMethodLast4;
            statementHistoryPayment.class = consumerPayment.class;
            statementHistoryPayment.details = consumerPayment.details;
            statementHistoryPayment.paymentMethod = this.createPaymentMethod(consumerPayment.type, consumerPayment.cardType);
            statementHistoryPayment.merchantProfileGUID = consumerPayment.merchantProfileGUID;

            const token: string = this.componentService.storageService.retrieve('token');
            const getReceiptUrl: string = this.config.getConfig('receiptPdfPath');
            statementHistoryPayment.paymentPdfUrl = getReceiptUrl + '?t=' + token + '&did=' + consumerPayment.consumerPaymentGUID + '&v=MySecureBill';
            this.paymentHistory.push(statementHistoryPayment);
        });
    }

    createFakeStatementsForPaymentsAndAddToList() {
        let paymentName = '';
        this.paymentHistory.forEach((payment) => {
            if (payment.totalAmount > 0) {
                paymentName = 'Account Payment';
            } else {
                paymentName = 'Refund';
            }
            if (payment.status.toLowerCase() === 'chargeback') {
                paymentName = 'ChargeBack';
            }

            const fakeStatement: StatementHistoryStatement = {
                documentID: null,
                accountNumber: payment.accountNumber,
                documentDate: payment.paymentDate,
                customerLOBName: paymentName,
                documentGUID: null,
                totalAmount: null,
                totalPayments: payment.totalAmount,
                downloadStatement: false,
                statementPdfUrl: null,
                payments: [],
                materials: [],
                merchantProfileGUID: payment.merchantProfileGUID,
                merchantProfile: null,
                displayable: true
            };

            fakeStatement.payments.push(payment);
            this.statementHistory.push(fakeStatement);
        });
    }

    getMerchantProfilesForAllItems() {
        this.statementHistory.forEach(statement => {
            if (statement.merchantProfileGUID != null) {
                this.consumerService.getMerchantProfileByMerchantProfileGUID(statement.merchantProfileGUID).then(merchantProfile => {
                    statement.merchantProfile = merchantProfile;
                });
            }

            statement.payments.forEach(payment => {
                this.consumerService.getMerchantProfileByMerchantProfileGUID(payment.merchantProfileGUID).then(merchantProfile => {
                    payment.merchantProfile = merchantProfile;
                });
            });
        });
    }

    getYearsFromStatements() {
        const years = new Set<number>();
        if (this.statementDisplay) {
            const yearsList = this.statementDisplay.map((x) => new Date(x.documentDate).getFullYear()).forEach((item) => {
                if (!years.has(item)) {
                    years.add(item);
                }
            });

            this.componentService.loggingService.log('yearlist', LoggingLevel.debug, years);
        }

        return years;
    }

    checkAllCheckboxChecked(checked: boolean) {
        this.statementDisplay.forEach((statement) => {
            statement.downloadStatement = checked;
            statement.payments.forEach((payment) => {
                payment.downloadPayment = checked;
            });
        });
    }

    /**
     * Attempts to massage the payment card/ACH data into a payment method object
     *
     * @param {string} paymentType
     * @param {string} cardType
     * @returns {PaymentMethod}
     * @memberof StatementHistoryComponent
     */
    createPaymentMethod(paymentType: string, cardType: string): PaymentMethod {
        const paymentMethod = new PaymentMethod();

        if (paymentType === 'ACH') {
            paymentMethod.paymentMethodType = PaymentMethodType.ach;
            paymentMethod.cardType = CreditCardIssuerType.unknown;
        } else {
            cardType = cardType.toLowerCase();
            if (cardType === 'ax' || cardType === 'american express' || cardType === 'americanexpress') {
                paymentMethod.paymentMethodType = PaymentMethodType.credit;
                paymentMethod.cardType = CreditCardIssuerType.americanexpress;
            } else if (cardType === 'di' || cardType === 'diners club' || cardType === 'dinersclub') {
                paymentMethod.paymentMethodType = PaymentMethodType.credit;
                paymentMethod.cardType = CreditCardIssuerType.dinersclub;
            } else if (cardType === 'ds' || cardType === 'discover') {
                paymentMethod.paymentMethodType = PaymentMethodType.credit;
                paymentMethod.cardType = CreditCardIssuerType.discover;
            } else if (cardType === 'jcb') {
                paymentMethod.paymentMethodType = PaymentMethodType.credit;
                paymentMethod.cardType = CreditCardIssuerType.jcb;
            } else if (cardType === 'mc' || cardType === 'mastercard') {
                paymentMethod.paymentMethodType = PaymentMethodType.credit;
                paymentMethod.cardType = CreditCardIssuerType.mastercard;
            } else if (cardType === 'vi' || cardType === 'visa') {
                paymentMethod.paymentMethodType = PaymentMethodType.credit;
                paymentMethod.cardType = CreditCardIssuerType.visa;
            } else {
                paymentMethod.cardType = CreditCardIssuerType.unknown;
                paymentMethod.paymentMethodType = PaymentMethodType.unknown;
            }
        }

        return paymentMethod;
    }

    downloadFile(response: any, fileType: string, fileName: string, fileCount: number = 0) {
        if (fileType === 'pdf') {
            const blob = new Blob([response], { type: 'application/pdf' });
            FileSaver.saveAs(blob, fileName);
            this.componentService.loggingService.log(blob.size + ' bytes file downloaded. File type: ' + blob.type, LoggingLevel.debug);
        } else {
            const archiveZipFile: ArchiveZipFile = response as ArchiveZipFile;
            if (archiveZipFile.isPartialResult) {
                this.displayedWarningText = this.downloadWarningTextTemplate;
                this.displayedWarningText = this.displayedWarningText.replace(
                    '!FAILEDCOUNT!', (archiveZipFile.failedDocumentGUIDs.length
                        + archiveZipFile.failedReceiptGUIDs.length + archiveZipFile.failedMaterialGUIDs.length).toString());
                this.displayedWarningText = this.displayedWarningText.replace('!TOTALCOUNT!', fileCount.toString());
                this.showPartialDownloadWarning = true;
            }

            const blobZipFile = this.componentService.b64ToBlob(archiveZipFile.zipFileBytes, 'application/zip');
            FileSaver.saveAs(blobZipFile, fileName);
            this.componentService.loggingService.log(
                blobZipFile.size + ' bytes file downloaded. File type: ' + blobZipFile.type, LoggingLevel.debug);
            this.componentService.loggingService.log('Archive History Response', LoggingLevel.debug, archiveZipFile);
        }
        this.doneDownloading();
    }

    handleDownloadError(error: any) {
        this.downloadError = true;
        this.componentService.loggingService.handleError(error);
        this.doneDownloading();
    }

    clearErrorsAndWarnings() {
        this.downloadError = false;
        this.showPartialDownloadWarning = false;
        this.displayedWarningText = '';
    }

    private statementCanBeDisplayed(document: IDocument) {
        return !document.isStricken && !document.isSuppressed;
    }

    private groupStatementsByYear(statements: StatementHistoryStatement[]): ISortedStatements {
        return statements.reduce(function(byYear: ISortedStatements, item: StatementHistoryStatement) {
            const itemYear = new Date(item.documentDate).getFullYear();
            (byYear[itemYear] = byYear[itemYear] || []).push(item);
            return byYear;
        }, {});
    }

    private clearCheckboxes() {
        this.statementDisplay.forEach((statement) => {
            statement.downloadStatement = false;

            statement.payments.forEach((payment) => {
                payment.downloadPayment = false;
            });
        });
    }

    private startLoading(): void {
        this.loading = true;
        setTimeout(this.doneLoading, 30000);
    }

    private doneLoading() {
        this.loading = false;
    }

    private isLoading(): boolean {
        return this.loading;
    }

    private isDownloading(): boolean {
        return this.filesDownloading;
    }

    private startDownloading() {
        this.filesDownloading = true;
        this.downloadButtonText = this.downloadingText;
    }

    private doneDownloading() {
        this.filesDownloading = false;
        this.downloadButtonText = this.downloadText;
    }

    private downloadDisabled() {
        // If any statements or payments are checked, the button should be enabled
        return !this.statementDisplay.some(
            (statement) => statement.downloadStatement || statement.payments.some((payment) => payment.downloadPayment));
    }

    public configurePageForRole(): void {
        if (this.domainInfo != null && this.consumer != null) {
            if (this.componentService.hasRole(Roles.StatementsOnly)) {
                this.noConsumerStatementHistoryRole = this.componentService.storageService.retrieve('roles');
                if (!this.domainInfo.enablePayments && (this.domainInfo.externalPaymentUrl.length === 0)) {
                    this.showPaidColumn = false;
                }
            }
        }
    }

    public updateHeadersWithConsumerName(): void {
        let replaceText = '';
        if (this.consumer != null) {
            if (this.consumer.firstName && this.consumer.firstName.trim() !== '') {
                replaceText = this.consumer.firstName.trim();
            } else if (this.consumer.fullName && this.consumer.fullName.trim() !== '') {
                replaceText = this.consumer.fullName.trim();
            }
            this.statementHistorySubHeader = this.statementHistorySubHeader.replace('!NAME!', replaceText);
            this.statementHistoryHeader = this.statementHistoryHeader.replace('!NAME!', replaceText);
        }
    }
}
