import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Activity } from '../../models/activity';
import { ComponentService } from '../../services/component/component.service';
import { ConsumerService } from '../../services/consumer/consumer.service';
import { BaseActivity } from '../Base/BaseActivity/baseactivity';

@Component({
    selector: 'activity-list',
    template: require('./activitylist.component.html'),
    styles: [require('./activitylist.component.css')]
})
export class ActivityListComponent extends BaseActivity implements OnInit, OnDestroy {

    latestActivityText: string;
    showActivityLinkText: string;
    activitiesList: Activity[] = [];

    private ngUnsubscribe: Subject<any> = new Subject();

    constructor(private componentService: ComponentService, private consumerService: ConsumerService) {
        super();
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit() {
        // Called after the constructor, initializing input properties, and the first call to ngOnChanges.
        // Add 'implements OnInit' to the class.
        this.componentService.contentService.content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((content: any) => {
                this.latestActivityText = this.componentService.contentService.tryGetContentItem(content, 'home', 'activityList', 'homePageLatestActivityHeadline').text;
                this.showActivityLinkText = this.componentService.contentService.tryGetContentItem(content, 'home', 'activityList', 'homePageLatestActivityShowAllLink').text;
            });

        this.consumerService.getConsumerActivity(2).then(
            result => {
                result.forEach(al => this.setIcon(al));
                this.activitiesList = result;
                this.doneLoading();
            });
    }
}
