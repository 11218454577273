export interface IAccountBalanceHistory {
    // customerID: number;
    // customerAccountID : string;
    transactionDate: Date;
    transactionType: AccountBalanceTransactionType;
    accountNumber: string;
    amount: number;
    code: string;
    description: string;
    onPaymentPlan: boolean;
}

export enum AccountBalanceTransactionType {
    charge = 1,
    adjustment = 2,
    payment = 3,
    unknown = 0
}
