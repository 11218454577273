export class ConsumerSurvey {
    public AccountID: string;
    public ReferenceID: number; // ConsumerPaymentID
    public ConsumerPaymentGUID: string; // ConsumerPaymentGUID
    public Response: string; // Survey response - 'yes' or 'no'
    public Comment: string;
    public Version: number;
    public Source: string;
    public MobilePayment: boolean;
}
