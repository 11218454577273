import { Injectable } from '@angular/core';
import Dexie from 'dexie';
import { IConsumer, IDbConsumer } from '../../models/consumer';
import { IndexedDbService } from './indexedDb.service';

// Pattern for Dexie library usage: https://github.com/dfahlander/Dexie.js
@Injectable()
export class ConsumerStorageService {
    private consumer: Dexie.Table<IDbConsumer, any>;

    constructor(private indexedDbService: IndexedDbService) {
        this.initializeTable();
    }

    private initializeTable(): void {
        this.consumer = this.indexedDbService.table('consumer');
    }

    async getConsumerFromStorage(): Promise<IDbConsumer> {
        // Defend against case in which this method gets called if we
        // accidentally call this without checking if anything exists yet.
        if (!await this.consumerExistsInStorage()) {
            return null;
        }

        const consumers = await this.consumer.toArray();

        // In flows like one-time-payment success -> confirmation -> /modifiedenrollment,
        // we are hammering the ConsumerAPI for the IConsumer object from multiple different sources.
        // Because of this all happening asynchronously, we are adding multiple copies of the same consumer to local storage
        // at the same time. We just want to operate on the "latest-and-greatest" always.
        // This behavior pre-exists this service.
        if (consumers.length > 1) {
            const sortedIds = consumers
                .map(c => c.consumerID)
                .sort((id1, id2) => id1 > id2 ? 1 : -1);

            const idsExceptLatest = sortedIds.slice(0, sortedIds.length - 1);

            await this.consumer.bulkDelete(idsExceptLatest);
        }

        return consumers[consumers.length - 1];
    }

    async consumerExistsInStorage(): Promise<boolean> {
        return await this.consumer.count() > 0;
    }

    async addConsumerToStorage(consumer: IConsumer): Promise<void> {
        const dbConsumer = consumer as IDbConsumer;
        return await this.consumer.add(dbConsumer);
    }

    async updateConsumer(changes: { [keyPath: string]: any }): Promise<number> {
        const dbConsumer = await this.getConsumerFromStorage();
        return await this.consumer.update(dbConsumer.consumerID, changes);
    }

    async clearConsumerStorage(): Promise<void> {
        return await this.consumer.clear();
    }
}
