/* tslint:disable:triple-equals */
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ConnectMessage, MessageType } from '../../../models/connectmessage';
import { ComponentService } from '../../../services/component/component.service';
import { ConnectMessageService } from '../../../services/connectmessage/connectmessage.service';
import { ConsumerService } from '../../../services/consumer/consumer.service';

@Component({
    selector: 'connect-message',
    template: require('./connectmessage.component.html'),
    styles: [require('./connectmessage.component.css')]
})

export class ConnectMessageComponent implements OnInit, OnDestroy {
    private ngUnsubscribe: Subject<any> = new Subject();
    @Input() align: string;
    @Input() adTitle = '';
    @Input() adText = '';
    @Input() adImageUrl = '/Content/demo-ad-from-joe.jpg';
    @Input() adLinkText = '';
    @Input() adLinkUrl = '';
    @Input() adLocation = '';
    @Input() textOnly = false;

    // textOnly connectMessage will default to not show anything.
    textOnlyDescription: string;
    message: ConnectMessage = null;
    loading = false;

    ngOnInit() {
        // load the connect message
        if (this.adLocation && this.adLocation != 'none') {
            this.loading = true;
            this.connectMessageService.getConnectMessage(this.adLocation).then(message => {
                if (message != null && message.domainConnectMessageID != 0) {
                    this.updateMessage(message);
                } else {
                    this.loading = false;
                }
            }).catch(err => {
                this.loading = false;
            });
        }
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    constructor(
        private consumerService: ConsumerService,
        private componentService: ComponentService,
        private connectMessageService: ConnectMessageService
    ) { }

    updateMessage(message: ConnectMessage) {
        this.loading = false;
        this.message = message;
        if (message && message.domainConnectMessageID != 0) {
            this.adTitle = message.title;
            this.adImageUrl = this.connectMessageService.getImageUrlFromImageID(message.imageID);
            this.adLinkUrl = message.link;
            this.adLinkText = message.linkText;
            this.adText = message.description;
            this.textOnlyDescription = message.description;
            this.textOnly = message.messageType == MessageType.text;
        }
    }

    adClicked(event: any) {
        if (this.isAdClickable() && this.message) {
            this.connectMessageService.sendClickImpression(this.message).then(success => {
                this.loading = false;
            }).catch(err => {
                this.loading = false;
            });
            window.open(this.adLinkUrl, '_blank');
        } else if (this.isAdClickable()) {
            window.open(this.adLinkUrl, '_blank');
        }
    }

    private isAdClickable() {
        return this.adLinkText && this.adLinkText.length > 0;
    }

    private isLoading() {
        return this.loading;
    }
}
